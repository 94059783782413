import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.label`
  &.small {
    ${TextStyles.xSmall}
  }
  &.medium {
    ${TextStyles.small}
  }
  &.large {
    ${TextStyles.medium}
  }
  &:not(.disabled) {
    cursor: pointer;
  }
`;
