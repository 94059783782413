import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../../../BrandCore/constants/heights';

export const MenuContainer = styled.div`
  position: absolute;
  right: 0px;
  top: ${MASTHEAD_HEIGHT - 0.5}px;
  min-width: 272px;
  line-height: 1.2em;
  background-color: ${(props) => props.theme.BaseColors.Neutral['10']};
  ${(props) => props.theme.BoxShadows.Depth15}
  color: ${(props) => props.theme.BaseColors.Neutral['100']};
  z-index: 1;
  border-radius: 0 0 3px 3px;

  .help-support-dropdown-menu {
    min-width: 272px;
    padding-top: 2px;
    ${(props) => props.theme.BoxShadows.Depth15}
    border-radius: 8px 0px 8px 8px;

    input {
      border-radius: 0px 0px 0px 0px;
    }
  }

  .inline-help-dropdown {
    width: 100%;
    min-width: 272px;
    background-color: ${(props) => props.theme.BaseColors.Neutral['10']};
    position: static;
    box-shadow: none;
  }
`;

export default MenuContainer;
