import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import StyledButton from './components/wrapper';
import Tooltip from '../Tooltip';

const IconButton = ({
  domID = '',
  className = '',
  icon,
  size = 'medium',
  tooltipProps = {},
  onClick = () => false,
  disabled = false,
  dataTestId = '',
  fillColor: _fillColor,
  hoverColor: _hoverColor,
  disabledColor: _disabledColor,
  buttonBackground = 'transparent',
  title = null,
  type = 'button',
  ...extraButtonProps
}) => {
  const theme = useContext(ThemeContext);
  const Icon = icon;
  const Wrapper = Object.keys(tooltipProps).length ? Tooltip : Fragment;
  const wrapperProps = Object.keys(tooltipProps).length
    ? { isButtonRoleDisabled: true, ...tooltipProps }
    : {};

  const fillColor = useMemo(
    () => _fillColor || theme.UIPalette.Colors.Content.Secondary,
    [_fillColor, theme],
  );

  const hoverColor = useMemo(
    () => _hoverColor || theme.UIPalette.Colors.Content.Primary,
    [_hoverColor, theme],
  );

  const disabledColor = useMemo(
    () => _disabledColor || theme.UIPalette.Colors.Content.Disabled,
    [_disabledColor, theme],
  );

  const [iconFillColor, setIconFillColor] = useState(fillColor);
  useEffect(() => setIconFillColor(fillColor), [fillColor]);
  const activeStateEventKeyCodes = [13, 32];

  const setHoverColor = () => {
    setIconFillColor(hoverColor);
  };

  const setNeutralColor = () => {
    setIconFillColor(fillColor);
  };

  const setActiveColor = (e) => {
    if (activeStateEventKeyCodes.includes(e.keyCode)) {
      setIconFillColor(hoverColor);
      setHoverColor();
    }
  };

  const removeActiveColor = (e) => {
    if (activeStateEventKeyCodes.includes(e.keyCode)) {
      setIconFillColor(fillColor);
      setNeutralColor();
    }
  };

  return (
    <Wrapper {...wrapperProps}>
      <StyledButton
        id={domID}
        onMouseOver={setHoverColor}
        onFocus={setNeutralColor}
        onMouseOut={setNeutralColor}
        onBlur={setNeutralColor}
        onMouseDown={setHoverColor}
        className={`${size} ${className}`}
        onKeyDown={setActiveColor}
        onKeyUp={removeActiveColor}
        onClick={onClick}
        data-testid={dataTestId}
        disabled={disabled}
        background={buttonBackground}
        type={type}
        {...extraButtonProps}
      >
        {icon && (
          <Icon
            domID={`${domID}-icon`}
            size={size}
            fillColor={disabled ? disabledColor : iconFillColor}
            dataTestId={`${dataTestId}-icon`}
            title={title}
          />
        )}
      </StyledButton>
    </Wrapper>
  );
};

const IconButtonPropTypes = {
  /** Unique identifer for the component */
  domID: PropTypes.string,
  /** gets passed to the button element */
  className: PropTypes.string,
  /** Type of the icon to show. e.g. Cross */
  icon: PropTypes.elementType,
  /** Size of the button. Allowed values are xSmall, small, medium, large */
  size: PropTypes.oneOf(['xSmall', 'small', 'medium', 'large']),
  /** The callback function to fire when user clicks on the button */
  onClick: PropTypes.func,
  /** Test ID to automate the testing */
  dataTestId: PropTypes.string,
  /** Props that are passed to tooltip if we need tooltip */
  // eslint-disable-next-line react/forbid-prop-types
  tooltipProps: PropTypes.object,
  disabled: PropTypes.bool,
  fillColor: PropTypes.string,
  hoverColor: PropTypes.string,
  disabledColor: PropTypes.string,
  buttonBackground: PropTypes.string,
  /** passed to the icon */
  title: PropTypes.string,
  /** Props that gets passed down to the underlying `button` */
  // eslint-disable-next-line react/forbid-prop-types
  extraButtonProps: PropTypes.any,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

IconButton.propTypes = IconButtonPropTypes;

export { IconButton as default, IconButtonPropTypes };
