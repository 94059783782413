export const testDataLinks = {
  "applicationId": "72fa5b1a-1ac7-4ff9-b4a8-8e0a00354d8a",
  "applicationName": "Quality Performance Advisor",
  "source": "lithium",
  "liSearchUrl": "https://uat-community.changehealthcare.com/t5/forums/searchpage/tab/message?filter=location&location=category:Help-Support&collapse_discussion=true",
  "staticContent": [
    {
      "label": "Visit the Help Community",
      "url": "https://uat-community.changehealthcare.com/t5/Quality-Performance-Advisor/ct-p/Quality-Performance-Advisor"
    },
    {
      "label": "Report a Problem",
      "url": "https://uat-community.changehealthcare.com/t5/Report-a-Problem/tkb-p/Report-a-Problem"
    },
    {
      "label": "Contact Change Healthcare",
      "url": "https://uat-community.changehealthcare.com/t5/Knowledge-Base/Contact-Change-Healthcare/ta-p/711"
    }
  ],
  "productContent": [
    {
      "label": "Manage Alerts",
      "url": "https://uat-community.changehealthcare.com/quality-performance-advisor/manage-alerts"
    },
    {
      "label": "How to Find Pay Receipts",
      "url": "https://uat-community.changehealthcare.com/quality-performance-advisor/find-pay-receipts"
    },
    {
      "label": "Rejection Codes",
      "url": "https://uat-community.changehealthcare.com/quality-performance-advisor/rejection-codes"
    },
    {
      "label": "Set Up Filters",
      "url": "https://uat-community.changehealthcare.com/quality-performance-advisor/setup-filters"
    }
  ]
};

export const testDataSearch = [
  {
    "id": "717",
    "label": "BI Rich Text Blog - Search by Subject - Pediatrics - Teaser and Subject match!",
    "teaser": 'Some teaser is here',
    "url": "https://uat-community.changehealthcare.com/t5/BI-Rich-Text-Blog/BI-Rich-Text-Blog-Search-by-Subject-Pediatrics-Teaser-and/ba-p/717",
    "postTime": "2018-11-07T23:03:53.754Z"
  },
  {
    "id": "716",
    "label": "<P>BI Rich Text Blog - Search by&nbsp;Teaser - Pediatrics</P>",
    "teaser": 'Some teaser is here',
    "url": "https://uat-community.changehealthcare.com/t5/BI-Rich-Text-Blog/Sample-Subject/ba-p/716",
    "postTime": "2018-11-07T21:54:43.736Z"
  },
  {
    "id": "715",
    "label": "BI Rich Text Blog - Search by Subject - Pediatrics",
    "teaser": 'Some teaser is here',
    "url": "https://uat-community.changehealthcare.com/t5/BI-Rich-Text-Blog/BI-Rich-Text-Blog-Search-by-Subject-Pediatrics/ba-p/715",
    "postTime": "2018-11-07T21:54:22.679Z"
  }
];
