import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Count from '../../../Atoms/Count';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const FirstLine = styled.div`
  margin-top: 7px;
`;

const CountCell = ({ data = '', cellAlign = 'left' }) => {
  const value = typeof data === 'object' ? data.value : data;
  const domID = typeof data === 'object' ? data.domID : data;
  const secondLine = typeof data === 'object' ? data.secondLine : null;

  return (
    <>
      <FirstLine>
        <Count count={value} size="small" domID={domID} />
      </FirstLine>
      {secondLine && (
        <SecondLineWrapper align={cellAlign}>
          {data.secondLine}
        </SecondLineWrapper>
      )}
    </>
  );
};

CountCell.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      domID: PropTypes.string,
    }),
  ]).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default CountCell;
