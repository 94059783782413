import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Circle50 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle 50'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M5.43807 18.8548L18.8548 5.43807C15.1253 2.0763 9.37387 2.1911 5.78249 5.78249C2.1911 9.37387 2.0763 15.1253 5.43807 18.8548ZM5.07537 19.9246C5.07538 19.9246 5.07538 19.9246 5.07538 19.9246C9.17588 24.0251 15.8241 24.0251 19.9246 19.9246C24.0251 15.8241 24.0251 9.17589 19.9246 5.07538C15.8241 0.974875 9.17588 0.974875 5.07538 5.07538C0.974877 9.17588 0.974877 15.8241 5.07537 19.9246Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle 50'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.93883 11.4994C0.00616022 8.15193 1.15309 3.87151 4.50058 1.93883C7.30542 0.31946 10.7652 0.86217 12.9502 3.04986L3.04986 12.9502C2.62714 12.5269 2.25214 12.0421 1.93883 11.4994ZM3.67299 14.7292C2.63331 14.0619 1.73324 13.143 1.07295 11.9993C-1.13582 8.17363 0.174966 3.28172 4.00067 1.07295C7.51926 -0.958506 11.9397 -0.0128016 14.3452 3.12847C14.9207 3.87609 15.3459 4.70211 15.621 5.56562C15.9863 6.70553 16.0823 7.8908 15.9315 9.03735C15.7814 10.1916 15.3796 11.3194 14.726 12.3319C14.2385 13.0902 13.6171 13.7741 12.8722 14.3447C12.1248 14.9201 11.2991 15.3453 10.4359 15.6205C9.29373 15.9868 8.10599 16.0827 6.95726 15.9308C5.80674 15.7804 4.68267 15.3799 3.67299 14.7292Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle 50'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.46447 9.53553L9.53553 2.46447C8.63071 1.55964 7.38071 1 6 1C3.23858 1 1 3.23858 1 6C1 7.38071 1.55964 8.63071 2.46447 9.53553ZM1.81115 10.2958C0.693797 9.20605 0 7.68405 0 6C0 2.68629 2.68629 0 6 0C7.65685 0 9.15686 0.671568 10.2426 1.75735C10.8198 2.33447 11.2547 2.99989 11.5476 3.71013C11.8319 4.39814 11.9919 5.15056 11.9997 5.93942C12.0001 5.9798 12.0001 6.02019 11.9997 6.06058C11.9845 7.57158 11.4021 9.07794 10.2524 10.2328C10.2459 10.2394 10.2394 10.2459 10.2328 10.2524C9.661 10.8217 9.003 11.2519 8.30092 11.543C7.59235 11.8374 6.81516 12 6 12C4.37034 12 2.89242 11.3503 1.81115 10.2958Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Circle50.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Circle50;
