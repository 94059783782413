import styled from 'styled-components';
import padding from '../../../commonResources/padding';

/**
 * TODO update this to the left padding of detail pane header
 */
// stylelint-disable property-no-vendor-prefix
const ModalLeft = styled.div`
  background: ${({ theme }) => theme.BaseColors.Neutral['0']};
  min-height: 80vh;
  overflow: auto;
  min-width: 265px;
  padding: ${padding.modalLeft};
  border-radius: 12px 0 0 12px;
  -ms-grid-column: 1;
  grid-area: left;
`;

export default ModalLeft;
