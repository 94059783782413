import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import DetailPaneWrapper from './DetailPaneWrapper';

import { DETAIL_PANE_EXPAND_COLLAPSE } from '../../../../BrandCore/constants/timings';
import {
  COLLAPSED_PANE_WIDTH,
  SIDEBAR_WIDTH,
} from '../../../../BrandCore/constants/widths';
import { useLayout } from '../..';

const duration = DETAIL_PANE_EXPAND_COLLAPSE;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
  width: '0',
  overflowX: 'hidden',
  opacity: '1',
};

const ExpandCollapseTransition = ({
  fullExpand,
  onIn,
  onEntered,
  onExited,
  navigationPanePadding,
  children,
  className = '',
  expandWidth = '50%',
}) => {
  const transitionStyles = {
    entering: { width: 0 },
    entered: { width: expandWidth },
  };

  const TWO_COLLAPSED_PANE_WIDTH = 2 * COLLAPSED_PANE_WIDTH;
  const COLLAPSED_PANE_W_SIDEBAR = TWO_COLLAPSED_PANE_WIDTH + SIDEBAR_WIDTH;

  const { isSidebarShown } = useLayout();

  const nodeRef = React.useRef(null);

  const fullTransitionStyles = {
    entering: {
      width: isSidebarShown
        ? `calc(100% - ${COLLAPSED_PANE_W_SIDEBAR}px)`
        : `calc(100% - ${TWO_COLLAPSED_PANE_WIDTH}px)`,
    },
    entered: {
      width: isSidebarShown
        ? `calc(100% - ${COLLAPSED_PANE_W_SIDEBAR}px)`
        : `calc(100% - ${TWO_COLLAPSED_PANE_WIDTH}px)`,
    },
  };
  return fullExpand ? (
    <Transition
      in={onIn}
      timeout={duration}
      onEntered={onEntered}
      onExited={onExited}
      appear
      nodeRef={nodeRef}
    >
      {(state) => (
        <DetailPaneWrapper
          style={{
            ...defaultStyle,
            ...fullTransitionStyles[state],
          }}
          className={`${state} ${className}`}
          isSidebarShown={isSidebarShown}
          navigationPanePadding={navigationPanePadding}
        >
          {children}
        </DetailPaneWrapper>
      )}
    </Transition>
  ) : (
    <Transition
      in={onIn}
      timeout={duration}
      onEntered={onEntered}
      onExited={onExited}
      appear
      nodeRef={nodeRef}
    >
      {(state) => (
        <DetailPaneWrapper
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={`${state} ${className}`}
          navigationPanePadding={navigationPanePadding}
        >
          {children}
        </DetailPaneWrapper>
      )}
    </Transition>
  );
};

ExpandCollapseTransition.propTypes = {
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onEntered: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  navigationPanePadding: PropTypes.number.isRequired,
  fullExpand: PropTypes.bool.isRequired,
  className: PropTypes.string,
  expandWidth: PropTypes.string,
};

export default ExpandCollapseTransition;
