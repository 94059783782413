import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Atoms/Button';
import ButtonGroup from '../../../Molecules/ButtonGroup';

export const TakeoverButtonRow = ({
  dataTestId = null,
  onSave = () => false,
  onCancel = () => false,
  restoreDefaults = () => false,
}) => {
  const testIDs = {
    cancelButton: dataTestId && `${dataTestId}-cancelButton`,
    saveButton: dataTestId && `${dataTestId}-saveButton`,
    restoreDefaults: dataTestId && `${dataTestId}-restoreDefaults`,
  };

  return (
    <ButtonGroup>
      <Button
        dataTestId={testIDs.cancelButton}
        name="Cancel"
        onClick={onCancel}
        buttonType="diminished"
        size="medium"
      />
      <Button
        dataTestId={testIDs.restoreDefaults}
        name="Restore Defaults"
        onClick={restoreDefaults}
        buttonType="deEmphasized"
        size="large"
      />
      <Button
        dataTestId={testIDs.saveButton}
        name="Save Settings"
        onClick={onSave}
        size="large"
        buttonType="emphasized"
      />
    </ButtonGroup>
  );
};

TakeoverButtonRow.propTypes = {
  dataTestId: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  restoreDefaults: PropTypes.func.isRequired,
};

export default TakeoverButtonRow;
