import styled from 'styled-components';
import { borderHelpers } from '../../../BrandCore/shapes/borders';

const grid = {
  columnns: '1fr',
  rows: 'auto 1fr auto',
};
// stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix
export default styled.div`
  grid-area: right;
  -ms-grid-column: 2;
  display: -ms-grid;
  display: grid; /* stylelint-disable-line declaration-block-no-duplicate-properties */
  grid-template-columns: ${grid.columnns};
  -ms-grid-columns: ${grid.columnns};
  grid-template-rows: ${grid.rows};
  -ms-grid-rows: ${grid.rows};
  grid-template-areas:
    'header'
    'body'
    'footer';
  max-height: 80vh;
  ${borderHelpers.borderRadius12};
  ${({ theme }) => theme.BoxShadows.Depth15};
`;
