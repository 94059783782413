import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import NotificationMenuFooterItem from './NotificationMenuFooterItem';
import TextStyles from '../../../../BrandCore/Text/styles';
import IconButton from '../../../../Atoms/IconButton';

const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 14px 24px;
  outline: none;
  border-radius: 0px 0px 8px 8px;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.BaseColors.Neutral['10']};
  justify-content: space-between;
  align-items: center;

  .menu-footer-icon {
    width: 16px;
    height: 16px;
    padding: 2px;
    opacity: ${({ showRightAction }) => (showRightAction ? 1 : 0)};

    &:hover {
      color: ${(props) => props.theme.BaseColors.Neutral['50']};
    }
  }
`;

const DividerWrapper = styled.span`
  margin: 0 6px;
  ${TextStyles.xSmall};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
`;

const NotificationMenuFooter = ({
  items = [],
  rightItem = undefined,
  dataTestId = '',
  className = '',
}) => {
  const [showRightAction, setShowRightAction] = useState(false);

  const theme = useTheme();

  return (
    <FooterWrapper
      className={className}
      data-testid={dataTestId}
      showRightAction={showRightAction}
      onMouseEnter={() => setShowRightAction(true)}
      onMouseLeave={() => setShowRightAction(false)}
    >
      <div>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            <NotificationMenuFooterItem
              {...item}
              onFocus={() => setShowRightAction(true)}
              onBlur={() => setShowRightAction(false)}
            />
            {index + 1 < items.length && <DividerWrapper>•</DividerWrapper>}
          </React.Fragment>
        ))}
      </div>
      {rightItem && (
        <IconButton
          className="menu-footer-icon"
          size="small"
          icon={rightItem.icon}
          hoverColor={theme.BaseColors.NeutralAlt['150']}
          fillColor={theme.BaseColors.Neutral['100']}
          tooltipProps={{
            text: rightItem.label,
            tooltipWidth: 110,

            isButtonRoleDisabled: true,
          }}
          onClick={rightItem.onClick}
          onFocus={() => setShowRightAction(true)}
          onBlur={() => setShowRightAction(false)}
        />
      )}
    </FooterWrapper>
  );
};

NotificationMenuFooter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.elementType,
      iconFillColor: PropTypes.string,
      onClick: PropTypes.func,
      dataTestId: PropTypes.string,
    }),
  ),
  rightItem: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.elementType,
    onClick: PropTypes.func,
  }),
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

export default NotificationMenuFooter;
