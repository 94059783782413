import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../../../BrandCore/constants/heights';

const PopupNotificationWrapper = styled.div`
  position: fixed;
  top: ${({ index = 1 }) =>
    index * (MASTHEAD_HEIGHT + 28) + (index - 1) * 16}px;
  right: 24px;
  z-index: 100;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transition: top ${({ animationTime }) => animationTime}ms;
  -webkit-transition: top ${({ animationTime }) => animationTime}ms;
  opacity: 1;

  animation: slide-in ${({ animationTime }) => animationTime}ms forwards;
  -webkit-animation: slide-in ${({ animationTime }) => animationTime}ms forwards;

  &.out {
    animation: slide-out ${({ animationTime }) => animationTime}ms forwards;
    -webkit-animation: slide-out ${({ animationTime }) => animationTime}ms
      forwards;
  }

  @keyframes slide-in {
    0% {
      -webkit-transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  @keyframes slide-out {
    0% {
      -webkit-transform: translateY(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
      opacity: 0;
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      transform: translateY(0%);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
`;

export default PopupNotificationWrapper;
