import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Cases = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Cases'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#cases-large)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M3 0.500122C2.72386 0.500122 2.5 0.72398 2.5 1.00012V4.00012C2.5 4.27626 2.72386 4.50012 3 4.50012C3.27614 4.50012 3.5 4.27626 3.5 4.00012V1.50012H10.7324L12.584 4.27747C12.6767 4.41657 12.8328 4.50012 13 4.50012H21C21.2761 4.50012 21.5 4.27626 21.5 4.00012C21.5 3.72398 21.2761 3.50012 21 3.50012H13.2676L11.416 0.722772C11.3233 0.583673 11.1672 0.500122 11 0.500122H3ZM1 7.50012C0.723858 7.50012 0.5 7.72398 0.5 8.00012V21.0001C0.5 21.6632 0.763392 22.299 1.23223 22.7679C1.70107 23.2367 2.33696 23.5001 3 23.5001H21C21.663 23.5001 22.2989 23.2367 22.7678 22.7679C23.2366 22.299 23.5 21.6632 23.5 21.0001V8.00012C23.5 7.72398 23.2761 7.50012 23 7.50012H1ZM1.5 21.0001V8.50012H22.5V21.0001C22.5 21.3979 22.342 21.7795 22.0607 22.0608C21.7794 22.3421 21.3978 22.5001 21 22.5001H3C2.60218 22.5001 2.22064 22.3421 1.93934 22.0608C1.65804 21.7795 1.5 21.3979 1.5 21.0001Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="cases-large">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Cases'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V5.5C1 5.77614 1.22386 6 1.5 6C1.77614 6 2 5.77614 2 5.5V1H5.29289L7.14645 2.85355C7.24021 2.94732 7.36739 3 7.5 3H14V5.5C14 5.77614 14.2239 6 14.5 6C14.7761 6 15 5.77614 15 5.5V2.5C15 2.22386 14.7761 2 14.5 2H7.70711L5.85355 0.146447C5.75979 0.0526784 5.63261 0 5.5 0H1.5ZM0.5 7C0.223858 7 0 7.22386 0 7.5V15.5C0 15.7761 0.223858 16 0.5 16H15.5C15.7761 16 16 15.7761 16 15.5V7.5C16 7.22386 15.7761 7 15.5 7H0.5ZM1 15V8H15V15H1Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Cases'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V3.5C1 3.77614 1.22386 4 1.5 4C1.77614 4 2 3.77614 2 3.5V1H4.19098L5.05279 2.72361C5.13748 2.893 5.31061 3 5.5 3H10V3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5V2.5C11 2.22386 10.7761 2 10.5 2H5.80902L4.94721 0.276393C4.86252 0.107001 4.68939 0 4.5 0H1.5ZM0.5 5C0.223858 5 0 5.22386 0 5.5V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10217 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5V5.5C12 5.22386 11.7761 5 11.5 5H0.5ZM1 10.5V6H11V10.5C11 10.6326 10.9473 10.7598 10.8536 10.8536C10.7598 10.9473 10.6326 11 10.5 11H1.5C1.36739 11 1.24022 10.9473 1.14645 10.8536C1.05268 10.7598 1 10.6326 1 10.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Cases.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Cases;
