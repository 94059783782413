import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Menu = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Menu'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.5C1 2.22386 1.22386 2 1.5 2H15.5C15.7761 2 16 2.22386 16 2.5C16 2.77614 15.7761 3 15.5 3H1.5C1.22386 3 1 2.77614 1 2.5ZM1 8.5C1 8.22386 1.22386 8 1.5 8H15.5C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9H1.5C1.22386 9 1 8.77614 1 8.5ZM1.5 14C1.22386 14 1 14.2239 1 14.5C1 14.7761 1.22386 15 1.5 15H15.5C15.7761 15 16 14.7761 16 14.5C16 14.2239 15.7761 14 15.5 14H1.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Menu'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 2H2V10H10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Menu.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Menu;
