import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from '../../commonResources/hooks/useClickAway';
import StandardMenuStyle from './components/StandardMenuStyle';
import HeaderWrapper from './components/HeaderWrapper';
import { SearchInputProps } from '../../Atoms/SearchInput/SearchInput';
import Sections from './components/SectionedDropdown';
import List from './components/List';
import MenuSearch from '../../Atoms/MenuSearch';

export const StandardMenu = ({
  showActiveIndicator = false,
  listItemClassName = '',
  sectionTitleClass = '',
  itemStyles = {},
  className = '',
  domID = null,
  sections = [],
  menuItems = [],
  menuItemNames = [],
  onMenuClick = () => false,
  onClickOut = () => false,
  activeMenuItemIndex: initialActiveMenuItemIndex = null,
  activeSectionIndex: initialSectionIndex = null,
  dataTestId = '',
  children = null,
  maxWidth = '480px',
  searchInputProps = null,
  headerComponent = null,
  footerComponent = null,
  isMulti = false,
  selectionKey = 'id',
  selectedItems: initialSelectedItems = [],
  hideEmptyMessage = false,
}) => {
  const ref = useRef(null);
  useClickAway(ref, (e) => onClickOut(e));

  if (initialActiveMenuItemIndex || initialSectionIndex) {
    console.error(
      'activeMenuItemIndex and activeSectionIndex are deprecated in favor of selectedItemIds',
    );
  }

  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  // For convenience, the current active item is passed into state when onMenuClick prop callback is called.
  // This variable does not otherwise exist in local state.
  const setActiveSectionAndItemPosition = (event, item) => {
    event.persist();
    if (isMulti) {
      const currentItems = [...selectedItems];
      const index = currentItems.indexOf(item[selectionKey]);
      if (index > -1) {
        currentItems.splice(index, 1);
      } else {
        currentItems.push(item[selectionKey]);
      }
      setSelectedItems(currentItems);
      onMenuClick(event, {
        activeItemIds: currentItems,
      });
    } else {
      setSelectedItems([item[selectionKey]]);
      onMenuClick(event, {
        activeItemId: item[selectionKey],
        activeItem: item,
      });
    }
  };

  const classes = {
    footerWrapper: className ? `${className}-footer-wrapper` : '',
    headerWrapper: className ? `${className}-header-wrapper` : '',
  };

  const isUsingSections = !!sections.length;
  return (
    <StandardMenuStyle
      className={className}
      id={domID}
      ref={ref}
      data-testid={dataTestId}
      maxWidth={maxWidth}
      hasSearchBar={headerComponent || searchInputProps}
      hasFooter={footerComponent}
    >
      {(headerComponent || searchInputProps) && (
        <HeaderWrapper
          hideBorder={searchInputProps}
          className={classes.headerWrapper}
        >
          {headerComponent}
          {searchInputProps && <MenuSearch {...searchInputProps} />}
        </HeaderWrapper>
      )}
      {isUsingSections ? (
        <Sections
          sections={sections}
          maxWidth={maxWidth}
          titleClass={sectionTitleClass}
          onMenuClick={setActiveSectionAndItemPosition}
          activeItems={showActiveIndicator && selectedItems}
          isMulti={isMulti}
          itemStyles={itemStyles}
          listItemClassName={listItemClassName}
          dataTestId={dataTestId}
          hideEmptyMessage={hideEmptyMessage}
        />
      ) : (
        <List
          menuItems={
            menuItems.length
              ? menuItems
              : menuItemNames.map((x, i) => ({ label: x, id: i }))
          }
          maxWidth={maxWidth}
          onMenuClick={setActiveSectionAndItemPosition}
          activeItems={showActiveIndicator && selectedItems}
          isMulti={isMulti}
          itemStyles={itemStyles}
          listItemClassName={listItemClassName}
          dataTestId={dataTestId}
          hideEmptyMessage={hideEmptyMessage}
        />
      )}
      {children}
      {footerComponent && (
        <div style={{ marginTop: '8px' }} className={classes.footerWrapper}>
          {footerComponent}
        </div>
      )}
    </StandardMenuStyle>
  );
};

StandardMenu.propTypes = {
  /** config data for lists without sections */
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  /** config data for list without sections in which menu items have additional properties */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),

  /** config data for dropdown list sections.
   * From v1.5 each section must have a title.
   * */
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
        }),
      ),
      PropTypes.shape({
        title: PropTypes.string,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
          }),
        ),
      }),
    ]),
  ),

  /**
   * Runs when list item is clicked
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Object} state.activeItem
   * @prop {Number|String} state.activeItem.id
   * @prop {String} state.activeItem.label
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   */
  onMenuClick: PropTypes.func,
  /* eslint-disable react/no-unused-prop-types */
  /**  (Deprecated) index identifying the active (most recently clicked) menu/list item  */
  activeMenuItemIndex: PropTypes.number,
  /** (Deprecated) index identifying which section the active item is in */
  activeSectionIndex: PropTypes.number,
  /**
   * Classname to attach to the wrapper div
   */
  className: PropTypes.string,
  /**
   * className for individual list/menu item.
   * */
  listItemClassName: PropTypes.string,
  domID: PropTypes.string,
  /** optional styles for the individual list/menu items */
  itemStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    dotColor: PropTypes.string,
  }),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** indicates whether the most recently-clicked menu/list item shows an active dot */
  showActiveIndicator: PropTypes.bool,
  onClickOut: PropTypes.func,
  sectionTitleClass: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.element,
  searchInputProps: PropTypes.shape(SearchInputProps),
  headerComponent: PropTypes.element,
  footerComponent: PropTypes.element,
  isMulti: PropTypes.bool,
  selectionKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedItems: PropTypes.arrayOf(PropTypes.any),
  hideEmptyMessage: PropTypes.bool,
};

export default StandardMenu;
