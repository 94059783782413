import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

// import FadeContainer from './FadeContainer';

import { NAVIGATION_PANE_FADE } from '../../../../BrandCore/constants/timings';

const duration = NAVIGATION_PANE_FADE;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

const FadeTransition = ({
  onExited = () => false,
  onEntered = () => false,
  timeout = 0,
  className = '',
  shouldMountAndUnmount = false,
  onIn,
  children,
}) => {
  const nodeRef = React.useRef(null);

  return (
    <Transition
      in={onIn}
      timeout={timeout || duration}
      onEntered={onEntered}
      onExited={onExited}
      mountOnEnter={shouldMountAndUnmount}
      unmountOnExit={shouldMountAndUnmount}
      appear
      nodeRef={nodeRef}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={`${className} ${state}`}
          ref={nodeRef}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

FadeTransition.propTypes = {
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onExited: PropTypes.func.isRequired,
  onEntered: PropTypes.func.isRequired,
  timeout: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  className: PropTypes.string,
  shouldMountAndUnmount: PropTypes.bool,
};

export default FadeTransition;
