import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import Tooltip from '../Tooltip';
import { useIsTruncated } from '../../commonResources/hooks/useIsTruncated';

import ButtonRow from './components/ButtonRow';
import LabelDecoration from './components/LabelDecoration';
import { Check } from '../../BrandCore/icons/informational';

const NameWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.hasIcon && 'margin-left: 12px'};
  max-width: ${(props) => props.maxWidth};
`;

export const MenuItem = ({
  domID = null,
  className = null,
  name = null,
  isActive = false,
  labelDecoration = null,
  isMulti = false,
  onClick = () => false,
  onMouseEnter = () => false,
  onMouseLeave = () => false,
  dataTestId = null,
  // default styles
  backgroundColor: _backgroundColor = null,
  hoverBackgroundColor: _hoverBackgroundColor = null,
  textColor: _textColor = null,
  maxWidth: maxWidthProp = '480px',
  icon = null,
  disabled = false,
  isFocused = false,
}) => {
  const theme = useTheme();
  const backgroundColor = useMemo(
    () => _backgroundColor || 'transparent',
    [theme, _backgroundColor],
  );

  const hoverBackgroundColor = useMemo(
    () => _hoverBackgroundColor || theme.BaseColors.Neutral['5'],
    [theme, _hoverBackgroundColor],
  );
  const textColor = useMemo(
    () => _textColor || theme.UIPalette.Colors.Content.Primary,
    [theme, _textColor],
  );
  const maxWidth =
    typeof maxWidthProp === 'string' ? maxWidthProp : `${maxWidthProp}px`;
  const nameRef = useRef(null);
  const isNameTruncated = useIsTruncated(nameRef, [
    name,
    maxWidth,
    labelDecoration,
  ]);
  const classes = [className];
  if (isActive) classes.push('selected');
  if (!isMulti) classes.push('single');
  if (disabled) classes.push('disabled');
  if (isFocused) classes.push('focused');

  const Icon = icon;

  const [iconColor, setIconColor] = useState(
    theme.UIPalette.Colors.Content.Placeholder,
  );

  return (
    <div style={{ display: 'block', maxWidth, width: '100%' }}>
      <Tooltip
        tooltipContent={name}
        domID={`${domID}-dropdown-list-item-tooltip`}
        hideTooltip={!isNameTruncated}
        isButtonRoleDisabled
        tooltipPosition="top-center"
        disablePointerEvents
        style={{ width: '100%' }}
      >
        <ButtonRow
          id={domID}
          data-testid={dataTestId && `${dataTestId}-${name}`}
          backgroundColor={backgroundColor}
          hoverBackgroundColor={hoverBackgroundColor}
          textColor={textColor}
          className={classes.join(' ')}
          onClick={(e) => onClick(e)}
          maxWidth={maxWidth}
          isActive={isActive}
          paddingLeft={isMulti && !isActive ? 52 : 24}
          onMouseEnter={(e) => {
            setIconColor(theme.UIPalette.Colors.Content.Secondary);
            onMouseEnter(e);
          }}
          onMouseLeave={(e) => {
            setIconColor(theme.UIPalette.Colors.Content.Placeholder);
            onMouseLeave(e);
          }}
          disabled={disabled}
          isFocused={isFocused}
        >
          <>
            {isActive && isMulti && (
              <Check
                size="medium"
                className="multi-check"
                fillColor={theme.BaseColors.Primary['130']}
              />
            )}
            {icon && (
              <Icon
                size="medium"
                className="icon"
                fillColor={
                  isActive ? theme.BaseColors.Primary['130'] : iconColor
                }
              />
            )}
            <NameWrapper ref={nameRef} hasIcon={icon} maxWidth={maxWidth}>
              {name}
            </NameWrapper>
            {labelDecoration ? (
              <LabelDecoration>{labelDecoration}</LabelDecoration>
            ) : null}
          </>
        </ButtonRow>
      </Tooltip>
    </div>
  );
};

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  dataTestId: PropTypes.string,
  domID: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  isActive: PropTypes.bool,
  labelDecoration: PropTypes.string,
  /** takes click event */
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  backgroundColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Indicates that the menu item is focused or not */
  isFocused: PropTypes.bool,
  /** Indicates that the menu item cannot be clicked */
  disabled: PropTypes.bool,
  /** Indicates that user can select another item */
  isMulti: PropTypes.bool,
  /** Icon to display infront of the Label. This should be a valid react element type. */
  icon: PropTypes.elementType,
};

export default MenuItem;
