import styled from 'styled-components';

export default styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  .bottom-left {
    top: ${(props) => props.buttonHeight}px;
    left: 0;
  }

  .bottom-right {
    top: ${(props) => props.buttonHeight}px;
    right: 0;
  }

  .top-left {
    bottom: ${(props) => props.buttonHeight}px;
    left: 0;
  }

  .top-right {
    bottom: ${(props) => props.buttonHeight}px;
    right: 0;
  }
`;
