import styled from 'styled-components';

export default styled.span`
  display: inline-flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 8px;
  height: 100%;
`;
