import React from 'react';
import PropTypes from 'prop-types';

import AvatarWrapper from './styles/AvatarWrapper';
import UserAvatar from '../../../../BrandCore/Avatar';
import Tooltip from '../../../../Atoms/Tooltip';

export const Avatar = ({
  avatarImg = null,
  avatarToggle = false,
  dataTestId = '',
  domID = null,
  onAvatarClick = () => false,
  customTheme = {},
}) => (
  <Tooltip
    domID="profile-button"
    tooltipContent="Profile"
    tooltipWidth={66}
    tooltipPosition="bottom-center"
    isButtonRoleDisabled
    disablePointerEvents
    style={{ height: '100%' }}
  >
    <AvatarWrapper
      avatarToggle={avatarToggle}
      className={avatarToggle ? 'open' : null}
      data-testid={dataTestId}
      id={domID}
      onClick={onAvatarClick}
      aria-label="User Menu"
      customTheme={customTheme}
    >
      <UserAvatar avatarImg={avatarImg} />
    </AvatarWrapper>
  </Tooltip>
);

Avatar.propTypes = {
  avatarImg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  avatarToggle: PropTypes.bool,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  onAvatarClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
};

export default Avatar;
