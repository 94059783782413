import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Provider = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Provider'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M12.3563 1.02109C12.45 0.992971 12.55 0.992971 12.6437 1.02109L23.6437 4.02109C23.7781 4.06141 23.8894 4.15638 23.9503 4.28278C24.0113 4.40918 24.0164 4.55539 23.9642 4.6857L21 9.59629V13.5V15.5C21 20.1941 17.1941 24 12.5 24C7.80586 24 4 20.1941 4 15.5V13.5V9.59629L1.03576 4.6857C0.983641 4.55539 0.98868 4.40918 1.04965 4.28278C1.11062 4.15638 1.2219 4.06141 1.35633 4.02109L12.3563 1.02109ZM20 9.5V13H5V9.5C5 9.43639 4.98786 9.37336 4.96424 9.3143L2.16725 4.82184L12.5 2.1L22.8327 4.82184L20.0358 9.3143C20.0121 9.37336 20 9.43639 20 9.5ZM5 14V15.5C5 19.6419 8.35814 23 12.5 23C16.6419 23 20 19.6419 20 15.5V14H5ZM12.5 5C12.7761 5 13 5.22386 13 5.5V7.5H15C15.2761 7.5 15.5 7.72386 15.5 8C15.5 8.27614 15.2761 8.5 15 8.5H13V10.5C13 10.7761 12.7761 11 12.5 11C12.2239 11 12 10.7761 12 10.5V8.5H10C9.72386 8.5 9.5 8.27614 9.5 8C9.5 7.72386 9.72386 7.5 10 7.5H12V5.5C12 5.22386 12.2239 5 12.5 5Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Provider'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.81431 0.0357617C7.93351 -0.0119206 8.06649 -0.0119206 8.1857 0.0357617L15.6857 3.03576C15.8256 3.09172 15.9328 3.20779 15.9775 3.35169C16.0222 3.49559 15.9996 3.65198 15.916 3.77735L14 6.65139V8.5L14 8.50171V10C14 11.5913 13.3679 13.1174 12.2426 14.2426C11.1174 15.3679 9.5913 16 8 16C6.4087 16 4.88258 15.3679 3.75736 14.2426C2.63214 13.1174 2 11.5913 2 10V8.5L2 8.49837V6.65139L0.0839774 3.77735C0.000394597 3.65198 -0.0221903 3.49559 0.0225036 3.35169C0.0671975 3.20779 0.174403 3.09172 0.314307 3.03576L7.81431 0.0357617ZM3 10V9H13V10C13 11.3261 12.4732 12.5979 11.5355 13.5355C10.5979 14.4732 9.32608 15 8 15C6.67392 15 5.40215 14.4732 4.46447 13.5355C3.52678 12.5979 3 11.3261 3 10ZM1.25785 3.73538L2.91603 6.22265C2.97078 6.30478 3 6.40129 3 6.5V8H13V6.5C13 6.40129 13.0292 6.30478 13.084 6.22265L14.7422 3.73538L8 1.03852L1.25785 3.73538ZM8 3C8.27614 3 8.5 3.22386 8.5 3.5V4.5H9.5C9.77614 4.5 10 4.72386 10 5C10 5.27614 9.77614 5.5 9.5 5.5H8.5V6.5C8.5 6.77614 8.27614 7 8 7C7.72386 7 7.5 6.77614 7.5 6.5V5.5H6.5C6.22386 5.5 6 5.27614 6 5C6 4.72386 6.22386 4.5 6.5 4.5L7.5 4.5V3.5C7.5 3.22386 7.72386 3 8 3Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Provider'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.81431 0.0357617C5.93351 -0.0119206 6.06649 -0.0119206 6.1857 0.0357617L11.6857 2.03576C11.8256 2.09172 11.9328 2.20779 11.9775 2.35169C12.0222 2.49559 11.9996 2.65198 11.916 2.77735L10 5.65139V8C10 10.2091 8.20914 12 6 12C3.79086 12 2 10.2091 2 8C2 7.99945 2 7.99891 2 7.99836V5.65139L0.0839773 2.77735C0.000394554 2.65198 -0.0221903 2.49559 0.0225036 2.35169C0.0671975 2.20779 0.174403 2.09172 0.314307 2.03576L5.81431 0.0357617ZM3 8C3 9.65685 4.34315 11 6 11C7.65685 11 9 9.65685 9 8H3ZM1.25785 2.73538L2.91603 5.22265C2.97078 5.30478 3 5.40129 3 5.5V7H6H9V5.5C9 5.40129 9.02922 5.30478 9.08398 5.22265L10.7422 2.73538L6 1.03852L1.25785 2.73538ZM6.5 2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5V3.5L4.5 3.5C4.22386 3.5 4 3.72386 4 4C4 4.27614 4.22386 4.5 4.5 4.5H5.5V5.5C5.5 5.77614 5.72386 6 6 6C6.27614 6 6.5 5.77614 6.5 5.5V4.5H7.5C7.77614 4.5 8 4.27614 8 4C8 3.72386 7.77614 3.5 7.5 3.5H6.5V2.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Provider.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Provider;
