import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Badge = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Badge'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 1C1.10218 1 0.720644 1.15804 0.43934 1.43934C0.158035 1.72064 0 2.10218 0 2.5V12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10217 14 1.5 14H14.5C14.8978 14 15.2794 13.842 15.5607 13.5607C15.842 13.2794 16 12.8978 16 12.5V2.5C16 2.10217 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H12.5C12.2239 1 12 1.22386 12 1.5V2C12 2.26522 11.8946 2.51957 11.7071 2.70711C11.5196 2.89464 11.2652 3 11 3C10.7348 3 10.4804 2.89464 10.2929 2.70711C10.1054 2.51957 10 2.26522 10 2V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5V2C6 2.26522 5.89464 2.51957 5.70711 2.70711C5.51957 2.89464 5.26522 3 5 3C4.73478 3 4.48043 2.89464 4.29289 2.70711C4.10536 2.51957 4 2.26522 4 2V1.5C4 1.22386 3.77614 1 3.5 1H1.5ZM1.14645 2.14645C1.24021 2.05268 1.36739 2 1.5 2H3C3 2.53043 3.21071 3.03914 3.58579 3.41421C3.96086 3.78929 4.46957 4 5 4C5.53043 4 6.03914 3.78929 6.41421 3.41421C6.78929 3.03914 7 2.53043 7 2H9C9 2.53043 9.21071 3.03914 9.58579 3.41421C9.96086 3.78929 10.4696 4 11 4C11.5304 4 12.0391 3.78929 12.4142 3.41421C12.7893 3.03914 13 2.53043 13 2H14.5C14.6326 2 14.7598 2.05268 14.8536 2.14645C14.9473 2.24021 15 2.36739 15 2.5V12.5C15 12.6326 14.9473 12.7598 14.8536 12.8536C14.7598 12.9473 14.6326 13 14.5 13H1.5C1.36739 13 1.24022 12.9473 1.14645 12.8536C1.05268 12.7598 1 12.6326 1 12.5V2.5C1 2.36739 1.05268 2.24021 1.14645 2.14645ZM5 8C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8ZM5 9C5.53043 9 6.03914 9.21071 6.41421 9.58579C6.78929 9.96086 7 10.4696 7 11H3C3 10.4696 3.21071 9.96086 3.58579 9.58579C3.96086 9.21071 4.46957 9 5 9ZM8.5 7C8.22386 7 8 7.22386 8 7.5C8 7.77614 8.22386 8 8.5 8H12.5C12.7761 8 13 7.77614 13 7.5C13 7.22386 12.7761 7 12.5 7H8.5ZM9 10.5C9 10.2239 9.22386 10 9.5 10H12.5C12.7761 10 13 10.2239 13 10.5C13 10.7761 12.7761 11 12.5 11H9.5C9.22386 11 9 10.7761 9 10.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Badge'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 3C0.5 2.72386 0.723858 2.5 1 2.5H5C5.27614 2.5 5.5 2.72386 5.5 3C5.5 3.82886 6.17114 4.5 7 4.5C7.82886 4.5 8.5 3.82886 8.5 3C8.5 2.72386 8.72386 2.5 9 2.5H15C15.2761 2.5 15.5 2.72386 15.5 3C15.5 3.82886 16.1711 4.5 17 4.5C17.8289 4.5 18.5 3.82886 18.5 3C18.5 2.72386 18.7239 2.5 19 2.5H23C23.2761 2.5 23.5 2.72386 23.5 3V21C23.5 21.2761 23.2761 21.5 23 21.5H1C0.723858 21.5 0.5 21.2761 0.5 21V3ZM1.5 3.5V20.5H22.5V3.5H19.45C19.2185 4.64139 18.2099 5.5 17 5.5C15.7901 5.5 14.7815 4.64139 14.55 3.5H9.45003C9.21852 4.64139 8.2099 5.5 7 5.5C5.7901 5.5 4.78148 4.64139 4.54997 3.5H1.5ZM14.5 11C14.5 10.7239 14.7239 10.5 15 10.5H19C19.2761 10.5 19.5 10.7239 19.5 11C19.5 11.2761 19.2761 11.5 19 11.5H15C14.7239 11.5 14.5 11.2761 14.5 11ZM14.5 15C14.5 14.7239 14.7239 14.5 15 14.5H19C19.2761 14.5 19.5 14.7239 19.5 15C19.5 15.2761 19.2761 15.5 19 15.5H15C14.7239 15.5 14.5 15.2761 14.5 15ZM7 14C5.343 14 4 15.343 4 17H12C12 15.343 10.657 14 9 14H7ZM10 11C10 12.1046 9.10457 13 8 13C6.89543 13 6 12.1046 6 11C6 9.89543 6.89543 9 8 9C9.10457 9 10 9.89543 10 11Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Badge.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Badge;
