import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TextStyles from '../../../../BrandCore/Text/styles';

const MenuFooterItemWrapper = styled.span`
  ${TextStyles.xSmall};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${(props) => props.theme.BaseColors.Primary['100']};
    border-radius: 5px;
  }

  &:hover {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
  }

  &:active {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
  }
`;

const NotificationMenuFooterItem = ({
  label = '',
  icon: Icon = null,
  iconFillColor: iconFillColorProp,
  onClick = () => false,
  dataTestId = '',
  onFocus = () => false,
  onBlur = () => false,
}) => {
  const theme = useTheme();

  const iconFillColor = useMemo(
    () =>
      iconFillColorProp || theme.BaseColors.Neutral['100'][iconFillColorProp],
  );

  return (
    <MenuFooterItemWrapper
      tabIndex="0"
      onClick={onClick}
      data-testid={dataTestId}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {Icon && (
        <Icon
          size="small"
          fillColor={iconFillColor}
          className="menu-footer-icon"
        />
      )}
      <span>{label}</span>
    </MenuFooterItemWrapper>
  );
};

NotificationMenuFooterItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.elementType,
  iconFillColor: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default NotificationMenuFooterItem;
