import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Caret = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Caret'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.13947 8.13313C2.32544 7.95562 2.62694 7.95562 2.81291 8.13313L12 16.9026L21.1871 8.13313C21.3731 7.95562 21.6746 7.95562 21.8605 8.13313C22.0465 8.31064 22.0465 8.59845 21.8605 8.77596L12.3367 17.8669C12.1508 18.0444 11.8492 18.0444 11.6633 17.8669L2.13947 8.77596C1.95351 8.59845 1.95351 8.31064 2.13947 8.13313Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Caret'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.64645 5.14645C1.84171 4.95118 2.15829 4.95118 2.35355 5.14645L8 10.7929L13.6464 5.14645C13.8417 4.95118 14.1583 4.95118 14.3536 5.14645C14.5488 5.34171 14.5488 5.65829 14.3536 5.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L1.64645 5.85355C1.45118 5.65829 1.45118 5.34171 1.64645 5.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Caret'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.64645 3.64645C1.84171 3.45118 2.15829 3.45118 2.35355 3.64645L6 7.29289L9.64645 3.64645C9.84171 3.45118 10.1583 3.45118 10.3536 3.64645C10.5488 3.84171 10.5488 4.15829 10.3536 4.35355L6.35355 8.35355C6.15829 8.54882 5.84171 8.54882 5.64645 8.35355L1.64645 4.35355C1.45118 4.15829 1.45118 3.84171 1.64645 3.64645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Caret.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Caret;
