import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { ListWrapper, SidebarWrapper } from './components/StyledComponents';
import SidebarItem from './components/SidebarItem';
import BackItem from './components/BackItem';
import CollapseItem from './components/CollapseItem';
import Tooltip from '../../Atoms/Tooltip';
import { Dots, DockTop } from '../../BrandCore/icons/interactive';
import { neutralAlt30 } from '../../BrandCore/colors/themes/base/basePalette';

export const Sidebar = ({
  domID = null,
  className = '',
  dataTestId = null,
  onSidebarItemClick = () => false,
  menuItems = [],
  sections = [],
  selectedMenuItemIds = [],
  isCollapsible = false,
  onCollapse = () => false,
}) => {
  const [visibleSection, setVisibleSection] = useState(null);

  useEffect(() => {
    if (sections.length) {
      let selectedSection = null;
      sections.forEach((section, sectionIndex) => {
        let ids = [];
        if (
          Object.prototype.hasOwnProperty.call(section, 'sectionItems') &&
          section.sectionItems.length > 0
        ) {
          ids = section.sectionItems.map((x) => x.id);
        } else {
          ids = section.map((x) => x.id);
        }
        ids.forEach((id) => {
          if (selectedMenuItemIds.includes(id) && section.title) {
            selectedSection = sectionIndex;
          }
        });
      });
      setVisibleSection(selectedSection);
    }
  }, [selectedMenuItemIds, sections]);

  /**
   * generateSidebarItem
   * ========================
   * creates individual SidebarItem components
   * @param {Object} item config of menu item being generated
   * @param {Number} itemIndex index of item being generated
   * @param {Number} sectionIndex
   * @returns {Element} SidebarItem
   */
  const generateSidebarItem = (item, itemIndex, sectionIndex = null) => {
    const isActive = selectedMenuItemIds.includes(item.id);

    return (
      <SidebarItem
        domID={domID && `${domID}-${item.label}`}
        className={className}
        dataTestId={dataTestId && `${dataTestId}-${item.label}`}
        key={item.label}
        label={item.label}
        icon={item.icon}
        isActive={isActive}
        onClick={(e) => {
          e.persist();
          onSidebarItemClick(e, item, itemIndex, sectionIndex);
        }}
      />
    );
  };

  const getIfSubMenuItemIsSelected = (section) => {
    if (selectedMenuItemIds.includes(section?.id)) {
      return true;
    }

    if (
      !section ||
      !section.sectionItems ||
      !Array.isArray(section.sectionItems) ||
      !section.sectionItems.length
    ) {
      return false;
    }

    return section.sectionItems
      .map((sectionItem) => {
        return getIfSubMenuItemIsSelected(sectionItem);
      })
      .reduce((prev, current) => prev || current, false);
  };

  /**
   * generateSubMenuItem
   * ========================
   * Creates top-level item linking to a subsection
   * @param {object} section
   * @param {number} sectionIndex
   * @returns {Element} SidebarItsem
   */
  const generateSubMenuItem = (section, sectionIndex) => {
    const isActive = getIfSubMenuItemIsSelected(section);
    return (
      <SidebarItem
        domID={domID && `${domID}-${section.title}`}
        className={className}
        dataTestId={dataTestId && `${dataTestId}-${section.title}`}
        key={section.title}
        label={section.title}
        icon={Dots}
        isActive={isActive}
        onClick={() => setVisibleSection(sectionIndex)} // sets new section when clicked
        viewMore
      />
    );
  };

  /**
   * generateSection
   * ========================
   * creates a set of menuItems displayed in the Sidebar
   * @param {object} section
   * @param {number} sectionIndex
   * @returns {[element]} SidebarItem
   */
  const generateSection = (section, sectionIndex) => {
    // if `sectionItems` property exists and has length
    if (
      Object.prototype.hasOwnProperty.call(section, 'sectionItems') &&
      section.sectionItems.length > 0
    ) {
      // and has a title
      if (section.title) {
        // generate ellipsis button to submenu
        return generateSubMenuItem(section, sectionIndex);
      }
      // generate a sidebar section/list of items
      return section.sectionItems.map((item, index) =>
        generateSidebarItem(item, index),
      );
    }

    // else generate a sidebar section/list of items
    return section.map((item, index) =>
      generateSidebarItem(item, index, sectionIndex),
    );
  };

  /**
   * generateSubSection
   * ========================
   * creates a set of menuItems displayed in the Sidebar,
   * including a BackButton at the top of the sidebar
   * @param {object} section
   * @param {number} sectionIndex
   * @returns {[element]} SidebarItem, BackItem
   */
  const generateSubSection = (section, sectionIndex) => {
    const backButton = (
      <BackItem
        domID={domID && `${domID}-backButton`}
        className={className}
        dataTestId={dataTestId && `${dataTestId}-backButton`}
        key="backButton"
        onClick={() => setVisibleSection(null)}
      />
    );

    return [backButton].concat(generateSection(section, sectionIndex));
  };

  /**
   * renderMultiSectionSidebar
   * ========================
   * performs logic to determine which section should be rendered and how it should be rendered
   * @returns {[element]} SidebarItem(s)
   */
  const renderMultiSectionSidebar = () => {
    // If activeSectionIndex is defined, render that section
    if (visibleSection || visibleSection === 0) {
      const activeSection = sections[visibleSection];
      return generateSubSection(activeSection.sectionItems, visibleSection);
    }

    // Otherwise, render all sections (top-level)
    return sections.map((section, sectionIndex) => {
      if (
        Object.prototype.hasOwnProperty.call(section, 'sectionItems') &&
        section.sectionItems.length > 0
      ) {
        return generateSection(section, sectionIndex);
      }
      return generateSection(section, null);
    });
  };

  const isUsingSections = !!sections.length;

  return (
    <SidebarWrapper id={domID} className={className} data-testid={dataTestId}>
      <ListWrapper className={className} isCollapsible={isCollapsible}>
        {isUsingSections
          ? renderMultiSectionSidebar()
          : menuItems.map((item, index) => generateSidebarItem(item, index))}
      </ListWrapper>
      {isCollapsible && (
        <CollapseItem
          domID={domID && `${domID}-collapseButton`}
          label="Collapse"
          className={className}
          data-testid={dataTestId && `${dataTestId}-collapseButton`}
          key="collapseButton"
          onClick={(e) => {
            e.persist();
            onCollapse(e);
          }}
        >
          <Tooltip
            tooltipPosition="right-center"
            tooltipWidth={80}
            tooltipContent="Collapse"
            caretToAnchor={24}
          >
            <div className="icon-wrapper">
              <DockTop
                fillColor={neutralAlt30}
                size="medium"
                className="icon"
              />
            </div>
          </Tooltip>
        </CollapseItem>
      )}
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** callback action which fires when the options in the sidebar are clicked
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   * @prop {Object} state.activeItem
   * @prop {String|Number} state.activeItem.id
   * @prop {String} state.activeItem.label
   */
  onSidebarItemClick: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      /** Icon representing the Product Section. Should be a ui-core icon component, passed as {Icon}, not {<Icon/>} */
      icon: PropTypes.func.isRequired,
      path: PropTypes.string,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          icon: PropTypes.func.isRequired,
          path: PropTypes.string,
        }),
      ),
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            icon: PropTypes.func.isRequired,
            path: PropTypes.string,
          }),
        ),
      }),
    ]),
  ),
  /** Id of the items that are initially selected. This prop replaces initialSelectedMenuSectionIndex and initialSelectedMenuItemIndex since v.9.0 */
  selectedMenuItemIds: PropTypes.arrayOf(PropTypes.any),
  /** if `true`, then 'Collapse' button will show.  Should only be true if user can switch between masthead and sidebar */
  isCollapsible: PropTypes.bool,
  /** callback action which fires when the Collapse button is clicked
   * @param {Event} e
   * @param {[Number]} activeItem format [sectionIndex, itemIndex]
   */
  onCollapse: PropTypes.func,
};

export default Sidebar;
