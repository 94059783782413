import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 12px 0 0;
  }

  .tooltip-wrapper {
    margin: 0 12px 0 0;
  }
`;
