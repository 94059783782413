import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const CircleCheck = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Checked'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 2H21C21.5523 2 22 2.44772 22 3V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V3C2 2.44772 2.44772 2 3 2ZM18.8536 7.85355C19.0488 7.65829 19.0488 7.34171 18.8536 7.14645C18.6583 6.95118 18.3417 6.95118 18.1464 7.14645L9.5 16.7929L5.85355 13.1464C5.65829 12.9512 5.34171 12.9512 5.14645 13.1464C4.95118 13.3417 4.95118 13.6583 5.14645 13.8536L9.14645 17.8536C9.34171 18.0488 9.65829 18.0488 9.85355 17.8536L18.8536 7.85355Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Checked'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM12.8747 4.68505C13.0418 4.50753 13.0418 4.21973 12.8747 4.04222C12.7076 3.86471 12.4368 3.86471 12.2697 4.04222L6.47594 10.9935L3.73031 8.13313C3.56324 7.95562 3.29237 7.95562 3.1253 8.13313C2.95823 8.31064 2.95823 8.59844 3.1253 8.77595L6.17343 11.9578C6.3405 12.1353 6.61137 12.1353 6.77844 11.9578L12.8747 4.68505Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

CircleCheck.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default CircleCheck;
