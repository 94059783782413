import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '../../../BrandCore/Avatar';
import TextWrapper from './styleWrappers/TextWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const AvatarWrapper = styled.div`
  padding-top: 6px;
  ${({ cellAlign }) =>
    cellAlign === 'right' ? 'margin-left: 8px' : 'margin-right: 8px'};
  display: flex;
`;

const FirstLine = styled(TextWrapper)`
  margin-top: 8px;
  ${({ hasSecondLine }) => !hasSecondLine && 'margin-bottom: 8px;'}
`;

const SecondLine = styled(SecondLineWrapper)`
  ${({ align, hasText }) =>
    hasText && align === 'right' && 'margin-right: 32px'};
  ${({ align, hasText }) =>
    hasText && align !== 'right' && 'margin-left: 32px'};
`;

const AvatarCellWrapper = styled.div`
  display: inline-flex;
  ${(props) => props.cellAlign === 'right' && 'flex-direction: row-reverse;'}
`;

const AvatarCell = ({
  data: { text = null, src = null, secondLine = null },
  cellAlign = 'left',
}) => {
  const alignText = cellAlign === 'center' ? 'left' : cellAlign;
  return (
    <>
      <AvatarCellWrapper cellAlign={cellAlign}>
        <AvatarWrapper hasText={!!text} cellAlign={cellAlign}>
          <Avatar variant="xSmall" src={src} />
        </AvatarWrapper>
        {text ? (
          <FirstLine align={alignText} hasSecondLine={!!secondLine}>
            {text}
          </FirstLine>
        ) : null}
      </AvatarCellWrapper>
      {secondLine ? (
        <SecondLine align={alignText} hasText={!!text}>
          {secondLine}
        </SecondLine>
      ) : null}
    </>
  );
};

AvatarCell.propTypes = {
  data: PropTypes.shape({
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.elementType,
    ]),
    /** optional, displayed next to avatar */
    text: PropTypes.string,
    /** optional, displayed below avatar and text */
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default AvatarCell;
