import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Security = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Security'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M22 3.10994L12.5 1L3 3.10994V13.5053C3 16.0234 4.00089 18.4384 5.78249 20.2191C7.56408 21.9997 9.98044 23 12.5 23C15.0196 23 17.4359 21.9997 19.2175 20.2191C20.9991 18.4384 22 16.0234 22 13.5053V3.10994ZM4 13.5053V3.91167L12.5 2.02383L21 3.91167V13.5053C21 15.7584 20.1045 17.9192 18.5104 19.5123C16.9163 21.1055 14.7543 22.0006 12.5 22.0006C10.2457 22.0006 8.08365 21.1055 6.48959 19.5123C4.89553 17.9192 4 15.7584 4 13.5053ZM12.5 7.00888C12.1027 7.01046 11.7221 7.1689 11.4411 7.44972C11.1599 7.73073 11.0014 8.11146 11 8.50884V11.0067H14V8.50887C13.9986 8.11147 13.8401 7.73073 13.5589 7.44972C13.2779 7.1689 12.8973 7.01046 12.5 7.00888ZM10 8.50655V11.0067H8V17.0033H17V11.0067H15L15 8.50804L15 8.50655C14.998 7.84488 14.7342 7.21088 14.266 6.743C13.7979 6.27513 13.1635 6.01141 12.5015 6.00943L12.5 6.50915L12.4985 6.00943C11.8365 6.01141 11.2021 6.27513 10.734 6.743C10.2658 7.21088 10.002 7.84488 10 8.50655ZM9 12.0061V16.0039H16V12.0061H9Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Security'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.0822 0.00680302C8.02778 -0.00226767 7.97222 -0.00226767 7.9178 0.00680302L1.9178 1.0068C1.67671 1.04699 1.5 1.25558 1.5 1.5V9.5C1.5 11.2239 2.18482 12.8772 3.40381 14.0962C4.62279 15.3152 6.27609 16 8 16C9.72391 16 11.3772 15.3152 12.5962 14.0962C13.8152 12.8772 14.5 11.2239 14.5 9.5V1.5C14.5 1.25558 14.3233 1.04699 14.0822 1.0068L8.0822 0.00680302ZM2.5 9.5V1.92356L8 1.0069L13.5 1.92356V9.5C13.5 10.9587 12.9205 12.3576 11.8891 13.3891C10.8576 14.4205 9.45869 15 8 15C6.54131 15 5.14236 14.4205 4.11091 13.3891C3.07946 12.3576 2.5 10.9587 2.5 9.5ZM8 4C7.60218 4 7.22064 4.15804 6.93934 4.43934C6.65804 4.72064 6.5 5.10218 6.5 5.5V7H9.5V5.5C9.5 5.10218 9.34196 4.72064 9.06066 4.43934C8.77936 4.15804 8.39782 4 8 4ZM5.5 5.5V7H5C4.72386 7 4.5 7.22386 4.5 7.5V11.5C4.5 11.7761 4.72386 12 5 12H11C11.2761 12 11.5 11.7761 11.5 11.5V7.5C11.5 7.22386 11.2761 7 11 7H10.5V5.5C10.5 4.83696 10.2366 4.20107 9.76777 3.73223C9.29893 3.26339 8.66304 3 8 3C7.33696 3 6.70107 3.26339 6.23223 3.73223C5.76339 4.20107 5.5 4.83696 5.5 5.5ZM5.5 8H6H10H10.5V11H5.5V8Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Security'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.441649 2.43408C2.39649 2.20437 4.22527 1.35083 5.65685 0L6 0.363661L6.34315 0C7.77473 1.35083 9.60351 2.20437 11.5584 2.43408C11.8102 2.46367 12 2.67709 12 2.93066H11.5C12 2.93066 12 2.9309 12 2.93116L12 2.93181L12 2.93353L12 2.93868L11.9998 2.95568C11.9996 2.96996 11.9992 2.99008 11.9984 3.01573C11.997 3.06701 11.994 3.14043 11.9883 3.23346C11.9769 3.41944 11.9542 3.68432 11.9093 4.00773C11.8196 4.65346 11.6403 5.53868 11.2807 6.49773C10.5605 8.41804 9.10475 10.6603 6.1857 11.8279C6.06649 11.8756 5.93351 11.8756 5.81431 11.8279C2.89525 10.6603 1.43949 8.41804 0.719338 6.49773C0.35968 5.53868 0.180381 4.65346 0.0906918 4.00773C0.0457713 3.68432 0.0231182 3.41944 0.0116844 3.23346C0.00596488 3.14043 0.00304469 3.06701 0.00155392 3.01573C0.000808418 2.99008 0.000419974 2.96996 0.000217915 2.95568L3.317e-05 2.93868L5.63264e-06 2.93353L1.07288e-06 2.93181L2.38419e-07 2.93116C5.96048e-08 2.93091 0.130981 2.61447 0.444254 2.45625L0.441649 2.43408ZM1.02379 3.36161C1.03582 3.50253 1.05393 3.67398 1.08118 3.87015C1.16337 4.46187 1.32782 5.27239 1.65566 6.14659C2.29461 7.85039 3.54229 9.77133 6 10.8226C8.45771 9.77133 9.70539 7.85039 10.3443 6.14659C10.6722 5.27239 10.8366 4.46187 10.9188 3.87015C10.9461 3.67398 10.9642 3.50253 10.9762 3.36161C9.13558 3.05844 7.41365 2.25397 6 1.03667C4.58635 2.25397 2.86442 3.05844 1.02379 3.36161Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Security.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Security;
