import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import TextStyles from '../../../../BrandCore/Text/styles';
import DropdownButton from '../../../../Molecules/DropdownButton';

const ClientSwitcherWrapper = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;

  .client-switcher-dropdown {
    width: 220px;
    top: 38px;
  }

  .client-switcher-btn {
    ${TextStyles.xxSmallCaps};
    color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentLight};
    letter-spacing: 0.5px;
    box-shadow: ${(props) =>
      `0 0 0 1px ${props.theme.BaseColors.Neutral['100']}`};
    width: 155px;
    border: none;

    svg {
      max-width: 12px;
    }

    &:hover {
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.BaseColors.Neutral['50']}`};
      background: ${(props) =>
        props.theme.UIPalette.Colors.Background.Masthead};
      & svg {
        box-shadow: none;
      }
    }

    &:active {
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.BaseColors.Neutral['10']}`};
      background: ${(props) => props.theme.BaseColors.Neutral['10']};
      & svg {
        box-shadow: none;
      }
      color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
      path {
        fill: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
      }
    }

    & svg {
      box-shadow: none;
    }

    &:focus-visible:not(:active):not(.loading) {
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.UIPalette.Colors.Field.Border.Focus}`};
      border: none;
    }

    ${(props) =>
      props.isOpen &&
      css`
        background: ${props.theme.UIPalette.Colors.Content.White};
        color: ${props.theme.UIPalette.Colors.Content.Primary};
        box-shadow: none;

        &:focus:not(:active):not(.loading) {
          box-shadow: none;
          border: none;
        }

        & .dropdown-caret {
          & path {
            fill: ${props.theme.UIPalette.Colors.Content.Primary};
          }
        }
      `}
  }
`;

const ClientSwitcher = ({
  menuItems = [],
  initialSelectedItem = {},
  onClientSwitcherChange = () => {},
}) => {
  const [isOpen, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(
    initialSelectedItem || menuItems[0],
  );

  useEffect(
    () => initialSelectedItem !== null && setSelectedItem(initialSelectedItem),
    [initialSelectedItem],
  );

  return (
    <ClientSwitcherWrapper isOpen={isOpen}>
      <DropdownButton
        name={selectedItem.label}
        menuItems={menuItems}
        buttonClass="client-switcher-btn"
        showActiveIndicator
        size="small"
        onClick={() => setOpen(true)}
        onClickAway={() => setOpen(false)}
        onMenuClick={(...obj) => {
          onClientSwitcherChange(...obj);
          const [, { activeItem }] = obj;
          setSelectedItem(activeItem);
          setOpen(false);
        }}
        orientation="bottom-right"
        selectedItem={selectedItem}
        listClass="client-switcher-dropdown"
        shouldTruncateName
        tooltipStyle={{
          height: '48px',
          alignItems: 'center',
        }}
      />
    </ClientSwitcherWrapper>
  );
};

ClientSwitcher.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  initialSelectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    labelDecoration: PropTypes.string,
    isSecondary: PropTypes.bool,
  }),
  onClientSwitcherChange: PropTypes.func,
};

export default ClientSwitcher;
