import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import clsx from 'clsx';

import Button from '../../Atoms/Button';
import ModalWrapper from './components/ModalWrapper';
import ModalContent from './components/ModalContent';

import { MODAL_ANIMATION } from '../../BrandCore/constants/timings';
import useAnimation from '../../commonResources/hooks/useAnimation';

// list of focusable element types, to be used for auto-focusing in modal when modal renders
// https://bitsofco.de/accessible-modal-dialog/
const focusableQueryArray = [
  'a[href]',
  'area[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  '[tabindex="0"]',
];

const Modal = ({
  domID = null,
  dataTestId = null,
  isOpen: propsIsOpen = false,
  buttonConfig = null,
  onClickOut = undefined,
  onModalToggle = () => false,
  children = null,
  deferBodyControl = false,
  wrapperComponent = null,
  removeFromDOMOnClose = false,
  isAdvanced = false,
  initialFocus = true,
  className = undefined,
  contentClassName = undefined,
}) => {
  const [isOpen, setIsOpen] = useState(propsIsOpen);
  const [isOpenAfterAnimation, setOpenAfterAnimation] = useState(isOpen);

  useEffect(() => setIsOpen(propsIsOpen), [propsIsOpen]);

  const interactiveEls = useRef([]);
  const modalWrapperRef = useRef();
  const lastFocusElRef = useRef(undefined);

  const withAnimationForClickOut = useAnimation(modalWrapperRef);
  const withAnimationForCloseIcon = useAnimation(modalWrapperRef);

  const Wrapper = wrapperComponent || ModalWrapper;

  const classes = [isOpen ? 'active' : '', className];
  const contentClasses = [isOpen ? 'active-content' : '', contentClassName];

  useEffect(() => {
    if (isOpen) {
      setOpenAfterAnimation(isOpen);
    }
  }, [isOpen]);

  const setInteractiveEls = () => {
    if (modalWrapperRef.current) {
      const newInteractiveEls =
        modalWrapperRef.current.querySelectorAll(focusableQueryArray) || [];

      // offsetParent being null will allow detecting cases where an element is invisible or inside an invisible element,
      // as long as the element does not use position: fixed. For them, their visibility has to be checked directly as well.
      interactiveEls.current = [...newInteractiveEls].filter(
        (node) =>
          node.offsetParent && getComputedStyle(node).visibility !== 'hidden',
      );
    }
  };

  useLayoutEffect(() => {
    if (!deferBodyControl) {
      isOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'initial');
    }

    // Remember that the previous tab-element when the modal was reopened
    if (isOpen && initialFocus) {
      setTimeout(() => {
        setInteractiveEls();
        lastFocusElRef.current =
          lastFocusElRef.current || interactiveEls.current[0];
        if (lastFocusElRef.current) {
          lastFocusElRef.current.focus();
        }
      }, MODAL_ANIMATION);
    }

    return () => {
      if (!deferBodyControl) document.body.style.overflow = 'initial';
    };
  }, [deferBodyControl, isOpen, initialFocus]);

  const toggleModal = (e, forceState) => {
    if (typeof e.persist === 'function') e.persist();
    if (!deferBodyControl) {
      if (forceState === true) {
        document.body.style.overflow = 'hidden';
      } else if (forceState === false) {
        document.body.style.overflow = 'auto';
      }
    }
    setIsOpen(forceState);
    onModalToggle(e, {
      isOpen: forceState,
      interactiveEls: interactiveEls.current,
      currentElIndex: interactiveEls.current.findIndex(
        (element) => document.activeElement === element,
      ),
    });
  };

  const handleClickOut = (e) => {
    if (typeof onClickOut === 'function' && isOpen) {
      setIsOpen(false);
      withAnimationForClickOut(() => {
        onClickOut(e);
        setOpenAfterAnimation(isOpen);
      });
    }
    if (isOpen && buttonConfig !== null && e.target.id !== buttonConfig.domID) {
      toggleModal(e, false);
    }
  };

  const handleClose = (closeFn) => {
    setIsOpen(false);
    withAnimationForCloseIcon(() => {
      closeFn();
      setOpenAfterAnimation(isOpen);
    });
  };

  // handle Tab navigation, "KeyUp" will point to the new activeElement, "Keypress" and "keydown" will point to old activeElement.
  const onKeyDown = (e) => {
    const TAB_KEY = 9;

    // manually handle tab navigation because we are confining interaction to the modal
    const handleTabForward = () => {
      // only one or less element
      if (interactiveEls.current.length <= 1) return;

      // loop back at end
      if (
        document.activeElement ===
        interactiveEls.current[interactiveEls.current.length - 1]
      ) {
        interactiveEls.current[0].focus();
        e.preventDefault();
      }
    };

    const handleTabBackward = () => {
      // only one or less element
      if (interactiveEls.current.length <= 1) return;

      // loop back to end from start
      if (document.activeElement === interactiveEls.current[0]) {
        interactiveEls.current[interactiveEls.current.length - 1].focus();
        e.preventDefault();
      }
    };

    if (e.keyCode === TAB_KEY) {
      setInteractiveEls();
      if (!e.shiftKey) {
        handleTabForward();
      } else {
        handleTabBackward();
      }
      lastFocusElRef.current = interactiveEls.current.find(
        (el) => el === document.activeElement,
      );
    }
  };

  const testIDs = {
    button: dataTestId ? `${dataTestId}-button` : null,
    closeButton: dataTestId ? `${dataTestId}-closeButton` : null,
    content: dataTestId ? `${dataTestId}-content` : null,
  };

  const getButtonProps = () => {
    const {
      domID: buttonDomId = null,
      label = null,
      ...otherButtonProps
    } = buttonConfig;
    return {
      domID: buttonDomId,
      dataTestId: dataTestId && `${dataTestId}-button-${label}`,
      onClick: (e) => toggleModal(e, true),
      name: label,
      ...otherButtonProps,
    };
  };

  const ModalPortal = ReactDOM.createPortal(
    <Wrapper
      id={domID}
      data-testid={dataTestId}
      className={clsx(classes)}
      onKeyDown={(e) => onKeyDown(e)}
      ref={modalWrapperRef}
    >
      <ModalContent
        dataTestId={testIDs.content}
        className={clsx(contentClasses)}
        onClickOut={(e) => handleClickOut(e)}
        handleClose={handleClose}
        isAdvanced={isAdvanced}
      >
        {(!removeFromDOMOnClose || isOpenAfterAnimation) && children}
      </ModalContent>
    </Wrapper>,
    document.body,
  );

  return (
    <aside>
      {buttonConfig ? <Button {...getButtonProps()} /> : null}
      {ModalPortal}
    </aside>
  );
};

Modal.propTypes = {
  /**
   * id to apply the wrapper.
   *
   * id can be used in css or javascript to target particular DOM element.
   */
  domID: PropTypes.string,
  /**
   * data-testid attribute used for automated testing.
   */
  dataTestId: PropTypes.string,
  /**
   * Props to pass to the button that toggles the modal.
   *
   * From 1.5 we discourage using this as this keeps internal state.
   */
  buttonConfig: PropTypes.shape({
    dataTestId: PropTypes.string,
    label: PropTypes.string.isRequired,
    domID: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    buttonType: PropTypes.oneOf([
      'standard',
      'destroy',
      'emphasized',
      'emphasizedAlt',
      'deEmphasized',
      'deEmphasizedReversed',
      'diminished',
      'unstyled',
    ]),
  }),
  /**
   * The content to render inside the Modal. Use `ModalHeader`, `ModalBody`, `ModalFooter` to compose
   * your modal content. (`ModalLeft` and `ModalRight` need to be used when you need two pane Modal.)
   */
  children: PropTypes.node.isRequired,

  /** action/event to trigger when modal is opened or closed
   * @param {Event}
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.currentElIndex
   * @prop {NodeList|Array} state.interactiveEls
   * @prop {Boolean} state.isOpen
   */
  onModalToggle: PropTypes.func,
  /**
   * indicates wheather the modal is displayed or hidden.
   *
   * When this is true, Modal is rendered on top of everything in the page.
   *
   * Modal uses z-index: 900 to show on top of pages. If you have other components in your app that have higher
   * z-index they will appear on top of modal.
   */
  isOpen: PropTypes.bool,
  /**
   * event/action to trigger / callback to execute when user clicks outside of white area
   * of Modal.
   */
  onClickOut: PropTypes.func,
  /**
   * Controls whether to deisable the scroll of `body` (This is `html's` body not ModalBody).
   */
  deferBodyControl: PropTypes.bool,
  /** if true, does not render children when Modal is closed */
  removeFromDOMOnClose: PropTypes.bool,
  /**
   * Use a different wrapper (uses black box with opacity by default)
   */
  wrapperComponent: PropTypes.any, //eslint-disable-line

  /**
   * Determines the columns of container
   *
   * When false grid-template-columns is set to 1fr, indicating the layout has 1 column.
   *
   * When true grid-template-columns is set to 265px 1fr. 1st column will be used to show
   * the ModalLeft while 2nd column will be the same column when this is false */
  isAdvanced: PropTypes.bool,
  /** *
   * indicates wheather the modal elements are initially focusable or not
   */
  initialFocus: PropTypes.bool,
  /**
   * Change style using className property
   */
  className: PropTypes.string,
  /**
   * Change style of the content using contentClassName property
   */
  contentClassName: PropTypes.string,
};

export default Modal;
