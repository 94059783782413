import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  &.indeterminate {
    fill: ${(props) => props.theme.BaseColors.White};
    &.disabled: {
      fill: ${(props) => props.theme.BaseColors.Neutral['100']};
    }
  }
`;

const Path = styled.path`
  fill: ${(props) => props.theme.BaseColors.Primary['115']};
  &.disabled {
    fill: ${(props) => props.theme.BaseColors.Neutral['15']};
  }
`;

// eslint-disable-next-line react/prop-types
export default ({ className = '' }) => (
  <Icon
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H7C7.55228 0 8 0.447715 8 1C8 1.55228 7.55228 2 7 2H1C0.447715 2 0 1.55228 0 1Z"
      className={className}
    />
  </Icon>
);
