import React from 'react';
import PropTypes from 'prop-types';

import PageCategory from './components/PageCategory';

export const DetailpageCategory = ({
  domID = null,
  className = '',
  dataTestId = '',
  label = '',
}) => (
  <PageCategory id={domID} className={className} data-testid={dataTestId}>
    {label}
  </PageCategory>
);

DetailpageCategory.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default DetailpageCategory;
