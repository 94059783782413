import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useClickAway } from '../../../../commonResources/hooks/useClickAway';

import Tooltip from '../../../../Atoms/Tooltip';
import Wrapper from './styles/Wrapper';
import MenuContainer from './styles/MenuContainer';
import MenuClickTarget from './styles/MenuClickTarget';
import IconWrapper from './styles/IconWrapper';
import Help from '../../../../BrandCore/icons/informational/Help';

import SearchResults from './components/SearchResults';
import StandardMenu from '../../../../Molecules/StandardMenu';
import MenuFooter from '../../../../Atoms/MenuFooter';

export const HelpSupportMenu = ({
  linkData = null,
  initialIsMenuOpen = false,
  initialSearchValue = '',
  onOpen = () => false,
  onBottomAction = () => false,
  onClose = () => false,
  onOpenClose = () => false,
  onMenuItemClick = () => false,
  searchResults = null,
  showBottomAction = true,
  staticContentConfig = null,
  onFetchSearchResults = () => false,
  customTheme = {},
}) => {
  const supportTheme = useTheme();
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(initialIsMenuOpen);
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  useEffect(() => {
    if (initialIsMenuOpen) setIsMenuOpen(initialIsMenuOpen);
    if (initialSearchValue) setSearchValue(initialSearchValue);
  }, [initialIsMenuOpen, initialSearchValue]);

  const toggleMenu = (event, forceState) => {
    const menuWasOpen = isMenuOpen;
    const menuIsNowOpen = forceState !== undefined ? forceState : !isMenuOpen;

    setIsMenuOpen(menuIsNowOpen);

    if (menuIsNowOpen) {
      onOpen(event, menuIsNowOpen);
      onOpenClose(event, menuIsNowOpen);
    } else if (!menuIsNowOpen && menuWasOpen) {
      onClose(event, menuIsNowOpen);
      onOpenClose(event, menuIsNowOpen);
    }
  };

  useClickAway(ref, () => {
    toggleMenu(event, false);
  });

  const handleMenuItemClick = (event, state) => {
    onMenuItemClick(event, state);
    const win = state.activeItem.path
      ? window.open(state.activeItem.path, '_blank')
      : '';
    if (win) win.focus();
    toggleMenu(event, false);
  };

  const handleSearchBarButtonClick = () => {
    setSearchValue('');
  };

  const handleChange = async (e) => {
    e.persist();
    setSearchValue(e.target.value);
    onFetchSearchResults(e, e.target.value);
  };

  const generateStaticContent = () => {
    const data = Object.entries(staticContentConfig).map((item, index) => {
      const label = ((key) => {
        switch (key) {
          case 'viewHelpDocumentationLink':
            return 'View the Help Documentation';
          case 'reportProblemLink':
            return 'Report a Problem';
          case 'contactChangeHealthcareLink':
          default:
            return 'Contact Change Healthcare';
        }
      })(item[0]);

      return {
        label,
        path: item[1],
        id: index,
      };
    });

    return data;
  };

  const generateMenuItems = () => {
    let formattedStaticContent;
    let formattedProductContent;

    // if connected to community/ciam, use values from api
    if (linkData) {
      const { staticContent = null, productContent = null } = linkData;
      if (productContent) {
        formattedProductContent = productContent.map((item, index) => {
          return {
            label: item.label,
            path: item.url,
            id: index,
            type: 'productContent',
          };
        });
      }

      if (staticContent) {
        formattedStaticContent = staticContent.map((item, index) => {
          return {
            label: item.label,
            path: item.url,
            id: index,
            type: 'staticContent',
          };
        });
      }
    }

    if (staticContentConfig && !linkData)
      formattedStaticContent = generateStaticContent();

    // otherwise use provided staticContent
    return {
      staticContent: formattedStaticContent,
      productContent: formattedProductContent,
    };
  };

  const menuItems = generateMenuItems();

  const { staticContent = [], productContent = [] } = menuItems;
  // const { productContent = [] } = menuItems;

  const sections = [];

  if (!searchValue) {
    sections.push({
      sectionItems: [...staticContent],
    });
  }

  if (productContent.length && !searchValue) {
    sections.push({
      title: 'Based on your view',
      sectionItems: [...productContent],
    });
  }

  return (
    <Wrapper ref={ref}>
      <Tooltip
        domID="help-support-button"
        tooltipContent="Help & Support"
        tooltipWidth={114}
        tooltipPosition="bottom-center"
        isButtonRoleDisabled
        disablePointerEvents
        style={{ height: '100%' }}
      >
        <MenuClickTarget
          customTheme={customTheme}
          data-testid="help-support-menuTarget"
          onClick={(e) => toggleMenu(e, undefined)}
          className={isMenuOpen ? 'open' : null}
        >
          <IconWrapper>
            <Help
              size="medium"
              fillColor={
                isMenuOpen
                  ? supportTheme.UIPalette.Colors.Content.Primary
                  : supportTheme.UIPalette.Colors.Content.PersistentLight
              }
            />
          </IconWrapper>
        </MenuClickTarget>
      </Tooltip>
      {isMenuOpen ? (
        <MenuContainer data-testid="help-support-container">
          {searchValue && (
            <SearchResults searchResults={searchResults} query={searchValue} />
          )}
          <StandardMenu
            maxWidth="272px"
            hideEmptyMessage
            className="help-support-dropdown-menu"
            dataTestId="help-support-dropdown-menu"
            searchInputProps={
              productContent.length
                ? {
                    dataTestId: 'help-support-searchbar',
                    placeholder: 'Search',
                    initialValue: searchValue || '',
                    onChange: handleChange,
                    query: searchValue || '',
                    onIconClick: handleSearchBarButtonClick,
                    onEnterPress: handleSearchBarButtonClick,
                  }
                : null
            }
            onMenuClick={handleMenuItemClick}
            sections={sections}
            footerComponent={
              showBottomAction ? (
                <MenuFooter
                  dataTestId="help-support-bottomAction"
                  onClick={onBottomAction}
                  icon={Help}
                  label={
                    searchValue ? 'Search the Community' : 'More Help Topics'
                  }
                />
              ) : null
            }
          />
        </MenuContainer>
      ) : null}
    </Wrapper>
  );
};

HelpSupportMenu.propTypes = {
  initialIsMenuOpen: PropTypes.bool,
  initialSearchValue: PropTypes.string,
  onOpen: PropTypes.func,
  onBottomAction: PropTypes.func,
  onClose: PropTypes.func,
  onOpenClose: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  onFetchSearchResults: PropTypes.func,
  linkData: PropTypes.shape({
    /** assumes help-support wrapper for data calls */
    applicationId: PropTypes.string,
    /** assumes help-support wrapper for data calls */
    applicationName: PropTypes.string,
    /** assumes help-support wrapper for data calls */
    source: PropTypes.string,
    /** assumes help-support wrapper for data calls */
    liSearchUrl: PropTypes.string,
    /** Top section of divider */
    staticContent: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.url,
      }),
    ),
    /** bottom section of divider */
    productContent: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.url,
      }),
    ),
  }),
  /** search results */
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      teaser: PropTypes.string,
      url: PropTypes.url,
      postTime: PropTypes.string,
    }),
  ),
  showBottomAction: PropTypes.bool,
  /** deprecated in favor of linkData.staticContent */
  staticContentConfig: PropTypes.shape({
    viewHelpDocumentationLink: PropTypes.string,
    reportProblemLink: PropTypes.string,
    contactChangeHealthcareLink: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
};

export default HelpSupportMenu;
