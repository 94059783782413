import Sockette from 'sockette'

// https://gist.github.com/jed/982883
// may not be real enough for a proper uuid, but overkill for this
const id = a =>
  a
    ? (0 | (Math.random() * 16)).toString(16)
    : ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/1|0/g, id)

export const notificationsSocket = ({
  onInitialNotifications = () => {},
  onUpdateNotifications = () => {},
  onUnprocessedNotifications = () => {},
  onMarkNotificationsRead = () => {},
  onopen = e => {},
  onreconnect = e => {},
  onmaximum = e => {},
  onclose = e => {},
  onerror = e => {},
  timeout = 5e3,
  maxAttempts = 10
}) => {
  const { webSocketUrl, userAccessToken } = JSON.parse(
    sessionStorage.getItem('notificationsConfig')
  )

  let activeTransactions = []

  const onmessage = e => {
    const {
      data: notifications,
      type,
      UnprocessedItems,
      message,
      transactionId = null
    } = JSON.parse(e.data)

    if (transactionId) {
      const activeTransaction = activeTransactions.find(
        transaction => transaction.transactionId === transactionId
      )

      if (activeTransaction) {
        activeTransactions = activeTransactions.filter(
          transaction => transaction.transactionId !== transactionId
        )
        // unprocessed items couldn't be processed by the server, reverse them and show error
        if (Array.isArray(UnprocessedItems)) {
          processUnprocessedNotifications(UnprocessedItems)
        }
        // no unprocessed items from server in this case, reverse the stored ones
        if (message === 'Internal server error') {
          processUnprocessedNotifications(activeTransaction.data)
        }
      }
    } else {
      // on connect, we receive the latest 20 notifications
      if (type === 'initialNotifications' && Array.isArray(notifications)) {
        onInitialNotifications(notifications)
      }
      // new notifications
      if (type === 'updateNotifications' && Array.isArray(notifications)) {
        onUpdateNotifications(notifications)
      }
    }
  }

  const socket = new Sockette(
    `${webSocketUrl}?accessToken=${userAccessToken}`,
    {
      timeout,
      maxAttempts,
      onopen,
      onmessage,
      onreconnect,
      onmaximum,
      onclose,
      onerror
    }
  )

  const markAsRead = notifications => {
    if (!(window && window.navigator.onLine)) {
      console.error('not online, cannot send things')
      // TODO: make this actually trigger an error
      return
    }
    const markedAsRead = notifications.map(notification => ({
      ...notification,
      isRead: true
      // isRead: !notification.isRead
    }))
    onMarkNotificationsRead(markedAsRead)
    sendNotifications(markedAsRead)
  }

  const sendNotifications = notifications => {
    const transactionId = id()
    const data = {
      type: 'updateNotifications',
      data: notifications,
      transactionId
    }
    activeTransactions.push(data)
    socket.json(data)
  }

  const processUnprocessedNotifications = notifications => {
    const markedUnread = notifications.map(notification => ({
      ...notification,
      isRead: false
      // TODO: errors and dismissal stuff
      // isDismissed: false,
      // isRead: notification.isRead && notification.isDismissed
      // or in active, just save id, markingRead, and markingDismissed?
    }))
    onUnprocessedNotifications(markedUnread)
    // now this can just merge them in
    // needs an error to be shown as well
  }

  return {
    socket,
    markAsRead
  }
}
