import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Checkbox from '../../../Atoms/Checkbox';
import Radio from '../../../Atoms/Radio';
import GridCell from '../../../Molecules/GridCell';
import { isA11yClick } from '../../../commonResources/helpers/domEvents';
import { mapIterable } from '../../../commonResources/helpers/iterables';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const GridRow = ({
  dataTestId = '',
  isSelected = false,
  isDisabled = false,
  supportSelection = false,
  supportRadioSelection = false,
  onRadioSelect = () => false,
  isRadioChecked = false,
  onClickThrough = null,
  onRowSelect = () => false,
  className = '',
  columns,
  disabledColumns,
  selectionKey,
  record,
  radioName = 'gridRadio',
  focusedItem = null,
}) => (
  <tr
    tabIndex={0}
    data-testid={dataTestId}
    className={className}
    role="row"
    // {...condition && {key: value}} = conditional object properties
    {...(onClickThrough && {
      onClick: (e) => onClickThrough(e, record),
      onKeyPress: (e) => onKeyPress(e, onClickThrough),
      role: 'button',
    })}
  >
    {supportSelection ? (
      <td
        className={clsx(
          'select-checkbox',
          !(!focusedItem || focusedItem === null) && 'hidden',
        )}
        key="select column"
      >
        <Checkbox
          domID={`${record[selectionKey]}-row-select-checkbox`}
          ariaLabel={`${record[selectionKey]} row select checkbox`}
          className="table-row-checkbox"
          onChange={(e) => {
            e.stopPropagation();
            onRowSelect(e);
          }}
          checked={isSelected}
          disabled={isDisabled}
        />
      </td>
    ) : null}
    {supportRadioSelection ? (
      <td
        className={clsx(
          'select-checkbox',
          !(!focusedItem || focusedItem === null) && 'hidden',
        )}
        key="select-column-radio"
      >
        <Radio
          checked={isRadioChecked}
          onChange={(e) => onRadioSelect(e, record[selectionKey])}
          value={record[selectionKey]}
          domID={`${record[selectionKey]}-row-select-radio`}
          disabled={isDisabled}
          ariaLabel={radioName}
        />
      </td>
    ) : null}
    {!supportSelection && !supportRadioSelection && (
      <td style={{ width: 40 }} />
    )}
    {mapIterable(columns.values(), (c) => {
      const data = record[c.dataName];

      return !disabledColumns.has(c.dataName) ? (
        <td
          role="cell"
          key={c.dataName}
          className={c.dataName}
          data-testid={`data-${record[selectionKey]}-${c.dataName}`}
        >
          <GridCell
            // eslint-disable-next-line jsx-a11y/aria-role
            role={null}
            cellType={c.cellType}
            data={data}
            component={c.customComponent ? c.customComponent : null}
            cellAlign={c.align || 'left'}
          />
        </td>
      ) : null;
    })}
  </tr>
);

GridRow.propTypes = {
  dataTestId: PropTypes.string,
  columns: PropTypes.instanceOf(Set).isRequired,
  disabledColumns: PropTypes.instanceOf(Set).isRequired,
  selectionKey: PropTypes.string.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  record: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  supportSelection: PropTypes.bool,
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  className: PropTypes.string,
  supportRadioSelection: PropTypes.bool,
  onRadioSelect: PropTypes.func,
  isRadioChecked: PropTypes.bool,
  radioName: PropTypes.string,
  /**
   * When used in workflow, this prop takes the item that is currently open in detail pane.
   * This is used to highlight the row in the main grid.
   */
  focusedItem: PropTypes.oneOfType([PropTypes.object]),
};

export default GridRow;
