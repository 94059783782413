import React from 'react';
import PropTypes from 'prop-types';
import VerticallyCentered from './styleWrappers/VerticallyCentered';
import DropdownButton, { dropdownButtonPropTypes } from '../../DropdownButton';

const DropdownButtonCell = ({
  data: {
    onClick = () => null,
    onFocus = () => null,
    onBlur = () => null,
    onClickAway = () => null,
    onMenuClick = () => null,
  },
  data = {},
}) => (
  <VerticallyCentered>
    <DropdownButton
      size="small"
      {...data}
      onClick={(e) => onClick(e, data)}
      onFocus={(e) => onFocus(e, data)}
      onBlur={(e) => onBlur(e, data)}
      onClickAway={(e) => onClickAway(e, data)}
      onMenuClick={(e) => onMenuClick(e, data)}
    />
  </VerticallyCentered>
);

DropdownButtonCell.propTypes = {
  data: PropTypes.shape(dropdownButtonPropTypes).isRequired,
};

export default DropdownButtonCell;
