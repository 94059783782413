import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import Input from '../Input';
import Button from '../Button';

import InputButton from '../Input/components/IconWrapper';
import SearchIcon from '../../BrandCore/icons/interactive/Search';
import Clear from '../../BrandCore/icons/interactive/Clear';

const Wrapper = styled.div`
  display: flex;

  .full-width {
    width: 100%;
    position: relative;
  }

  &.medium {
    .search-icon {
      position: absolute;
      top: ${(props) => (props.hasLabel ? 19 : 0) + 9}px;
      left: 9px;
    }

    input {
      ${(props) => !props.isDeliberativeSearch && `padding: 8px 34px 9px 34px`};
    }
  }

  &.small {
    .search-icon {
      position: absolute;
      top: ${(props) => (props.hasLabel ? 19 : 0) + 9}px;
      left: 9px;
    }

    input {
      ${(props) => !props.isDeliberativeSearch && `padding: 7px 28px 6px 28px`};
    }
  }

  &.large {
    .search-icon {
      position: absolute;
      top: ${(props) => (props.hasLabel ? 19 : 0) + 15}px;
      left: 15px;
    }

    input {
      ${(props) =>
        !props.isDeliberativeSearch && `padding: 12px 40px 10px 40px`};
    }
  }
`;

const QuickButton = styled(InputButton)`
  display: flex;
  align-items: center;
  padding: 0 4px;
  right: 6px;
  cursor: pointer;
`;

const DeliberativeButton = styled(Button)`
  margin-left: 4px;
  ${(props) => props.hasError && `margin-top: 20px`};
`;

export const SearchInput = ({
  placeholder = 'Search',
  disabled = false,
  initialValue = '',
  useDeliberativeSearch = false,
  label = null,
  hasError = false,
  errorMessage = null,
  onChange = () => false,
  onButtonClick = () => false,
  onEnterPress = () => false,
  dataTestId = null,
  className = '',
  size = 'medium',
  ...otherProps
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  const testIds = {
    quickSearchButton: `${dataTestId}-quick-button`,
    deliberativeSearchButton: `${dataTestId}-deliberative-button`,
  };

  const handleChange = (e) => {
    e.persist();
    const { value: newValue } = e.target;
    setValue(newValue);
    onChange(e, { value: newValue });
  };

  const handleClearButtonClick = (e) => {
    if (value) {
      setValue('');
      onButtonClick(e, { value: '' });
    }
  };

  const inputSizeToIconSizeMap = {
    small: 'small',
    medium: 'medium',
    large: 'medium',
  };

  return (
    <Wrapper
      className={size}
      hasLabel={!!label}
      isDeliberativeSearch={useDeliberativeSearch}
    >
      <Input
        className={`${className} full-width`}
        paddingRight={28}
        placeholder={placeholder}
        disabled={disabled}
        initialValue={value}
        onChange={handleChange}
        onEnterPress={onEnterPress}
        label={label}
        hasError={hasError}
        errorMessage={errorMessage}
        dataTestId={dataTestId}
        size={size}
        {...otherProps}
      >
        {!useDeliberativeSearch ? (
          <>
            <SearchIcon
              fillColor={
                disabled
                  ? theme.UIPalette.Colors.Content.Disabled
                  : theme.UIPalette.Colors.Content.Secondary
              }
              size={inputSizeToIconSizeMap[size]}
              className="search-icon"
            />
            <QuickButton
              data-testid={testIds.quickSearchButton}
              onClick={handleClearButtonClick}
              hasLabel={label !== null && label !== ''}
              hasError={hasError && errorMessage}
              className={size}
            >
              {value ? (
                <Clear
                  title="clear"
                  size={inputSizeToIconSizeMap[size]}
                  fillColor={
                    disabled
                      ? theme.UIPalette.Colors.Content.Disabled
                      : theme.UIPalette.Colors.Content.Secondary
                  }
                />
              ) : null}
            </QuickButton>
          </>
        ) : null}
      </Input>
      {useDeliberativeSearch ? (
        <DeliberativeButton
          name="Search"
          buttonType="emphasized"
          disabled={disabled}
          onClick={(e) => onButtonClick(e, { value })}
          hasLabel={label !== null && label !== ''}
          hasError={hasError && errorMessage}
          dataTestId={testIds.deliberativeSearchButton}
          size={size}
        />
      ) : null}
    </Wrapper>
  );
};

export const SearchInputProps = {
  /** class to be applied to underlying components */
  className: PropTypes.string,
  /** If false, shows clearable search with icons. If true, shows emphasized "Search" button. See notes tab for more info */
  useDeliberativeSearch: PropTypes.bool,
  /** See Input component for more props. All Input component props will be passed through. */
  initialValue: PropTypes.string,
  /** See Input component for more props. All Input component props will be passed through. */
  placeholder: PropTypes.string,
  /** See Input component for more props. All Input component props will be passed through. */
  disabled: PropTypes.bool,
  /** See Input component for more props. All Input component props will be passed through. */
  label: PropTypes.string,
  /** See Input component for more props. All Input component props will be passed through. */
  hasError: PropTypes.bool,
  /** See Input component for more props. All Input component props will be passed through. */
  errorMessage: PropTypes.string,
  dataTestId: PropTypes.string,
  /** Fires when input value changes.
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onChange: PropTypes.func,
  /** Fires when button is clicked.
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onButtonClick: PropTypes.func,
  /** Fires when the enter button is pressed.
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onEnterPress: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

SearchInput.propTypes = SearchInputProps;

export default SearchInput;
