import { useMemo, useCallback, useState } from 'react';

import {
  detailPaneAnimStates,
  detailPaneAnimStates as DETAIL_ANIMATION_STATES,
  navigationPaneAnimStates,
} from './commonResources/animStates';

const useLayoutContext = () => {
  /** Masthead sidebar state */
  const [showSidebar, setShowSidebar] = useState(false);

  /** Current animation state for detail window */
  const [detailAnimationState, setDetailAnimationState] = useState(
    DETAIL_ANIMATION_STATES.COLLAPSED,
  );
  /**
   * Current animation state for NavigationPane
   */
  const [navigationPaneAnimationState, setNavigationPaneAnimationState] =
    useState(navigationPaneAnimStates.EXPANDED);

  /** Extra properties that can be set for detail pane */
  const [detailProps, setDetailProps] = useState(undefined);

  /**
   * COLLAPSED => EXPAND_COLLAPSE
   * EXPAND_COLLAPSE => FADE_CONTENT
   * FADE_CONTENt => EXPANDED
   */
  const retreatNavigationPaneAnimation = useCallback(() => {
    setNavigationPaneAnimationState(navigationPaneAnimationState - 1);
  }, [navigationPaneAnimationState]);

  /**
   * EXPANDED => FADE_CONTENT
   * FADE_CONTENT => EXPAND_COLLAPSE
   * EXPAND_COLLAPSE =>  COLLAPSED
   */
  const advanceNavigationPaneAnimation = useCallback(() => {
    setNavigationPaneAnimationState(navigationPaneAnimationState + 1);
  }, [navigationPaneAnimationState]);

  const retreatDetailPaneAnimation = useCallback(
    (forceState) => {
      if (forceState) {
        setDetailAnimationState(forceState);
        return;
      }
      setDetailAnimationState(detailAnimationState - 1);
    },
    [detailAnimationState],
  );

  const onNavigationPaneExpandCollapse = useCallback(() => {
    if (navigationPaneAnimationState === navigationPaneAnimStates.EXPANDED) {
      advanceNavigationPaneAnimation();
    } else if (
      navigationPaneAnimationState === navigationPaneAnimStates.COLLAPSED
    ) {
      retreatNavigationPaneAnimation();
      if (detailAnimationState === DETAIL_ANIMATION_STATES.FULL_EXPAND) {
        retreatDetailPaneAnimation();
      }
    }
  }, [
    navigationPaneAnimationState,
    detailAnimationState,
    advanceNavigationPaneAnimation,
    retreatNavigationPaneAnimation,
    retreatDetailPaneAnimation,
  ]);

  const handleNavigationPaneExpandCollapseButtonClick = useCallback(() => {
    const navigationPangeWasOpen =
      navigationPaneAnimationState < navigationPaneAnimStates.COLLAPSED;
    onNavigationPaneExpandCollapse(!navigationPangeWasOpen);
  }, [navigationPaneAnimationState, onNavigationPaneExpandCollapse]);

  const advanceDetailPaneAnimation = useCallback(
    (forceState) => {
      if (forceState) {
        setDetailAnimationState(forceState);
        return;
      }
      setDetailAnimationState(detailAnimationState + 1);
    },
    [detailAnimationState],
  );

  const openDetailPane = useCallback(
    (record) => {
      setDetailProps(record);
      if (detailAnimationState <= DETAIL_ANIMATION_STATES.COLLAPSED) {
        advanceDetailPaneAnimation(DETAIL_ANIMATION_STATES.FADE_CONTENT);
      }
    },
    [detailAnimationState, advanceDetailPaneAnimation],
  );

  const expandCollapseDetailPane = useCallback(() => {
    if (detailAnimationState >= DETAIL_ANIMATION_STATES.FULL_EXPAND) {
      retreatDetailPaneAnimation();
      if (navigationPaneAnimationState < navigationPaneAnimStates.COLLAPSED)
        onNavigationPaneExpandCollapse();
    } else {
      advanceDetailPaneAnimation();
      if (navigationPaneAnimationState < navigationPaneAnimStates.COLLAPSED) {
        onNavigationPaneExpandCollapse();
      }
    }
  }, [
    detailAnimationState,
    navigationPaneAnimationState,
    advanceDetailPaneAnimation,
    onNavigationPaneExpandCollapse,
    retreatDetailPaneAnimation,
  ]);

  const closeDetailPane = useCallback(() => {
    setDetailProps(null);
    setDetailAnimationState(DETAIL_ANIMATION_STATES.COLLAPSED);
  }, []);

  const expandCollapseListPaneButtonConfig = useMemo(
    () => ({
      buttonType:
        navigationPaneAnimationState >= navigationPaneAnimStates.EXPAND_COLLAPSE
          ? 'collapse'
          : 'expand',
      onButtonClick: () => handleNavigationPaneExpandCollapseButtonClick(),
      buttonTooltipPosition: 'bottom-center',
    }),
    [
      navigationPaneAnimationState,
      handleNavigationPaneExpandCollapseButtonClick,
    ],
  );

  const expandCollapseDetailPaneButtonConfig = useMemo(
    () => ({
      buttonType:
        detailAnimationState >= detailPaneAnimStates.FULL_EXPAND
          ? 'collapse'
          : 'expand',
      onButtonClick: () => expandCollapseDetailPane(),
      buttonTooltipPosition: 'bottom-center',
    }),
    [detailAnimationState, expandCollapseDetailPane],
  );

  const closeDetailPaneButtonConfig = useMemo(
    () => ({
      buttonType: 'close',
      onButtonClick: () => closeDetailPane(),
      buttonTooltipPosition: 'bottom-center',
    }),
    [closeDetailPane],
  );

  return {
    isSidebarShown: showSidebar,
    setShowSidebar,
    navigationPaneAnimationState,
    detailAnimationState,
    detailProps,
    setDetailProps,
    openDetailPane,
    expandCollapseDetailPane,
    closeDetailPane,

    onNavigationPaneExpandCollapse,
    retreatNavigationPaneAnimation,
    advanceNavigationPaneAnimation,

    /**
     * Default Layout Control Button Configs.
     */
    expandCollapseListPaneButtonConfig,
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
  };
};

export default useLayoutContext;
