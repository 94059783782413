import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Bookmark = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Bookmark'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.5 2C5.10218 2 4.72064 2.15804 4.43934 2.43934C4.15804 2.72064 4 3.10218 4 3.5V21.6502L12.0144 17.0629C12.1654 16.979 12.349 16.979 12.5 17.0629L20 21.6502V3.5C20 3.10218 19.842 2.72064 19.5607 2.43934C19.2794 2.15804 18.8978 2 18.5 2H5.5ZM3.73223 1.73223C4.20107 1.26339 4.83696 1 5.5 1H18.5C19.163 1 19.7989 1.26339 20.2678 1.73223C20.7366 2.20107 21 2.83696 21 3.5V22.5C21 22.6772 20.9063 22.8411 20.7536 22.9309C20.6009 23.0208 20.412 23.0231 20.2572 22.9371L12.2572 18.072L3.74282 22.9371C3.58796 23.0231 3.39913 23.0208 3.24644 22.9309C3.09375 22.8411 3 22.6772 3 22.5V3.5C3 2.83696 3.26339 2.20107 3.73223 1.73223Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Bookmark'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.73223 0.732233C3.20107 0.263392 3.83696 0 4.5 0H12.5C13.163 0 13.7989 0.263392 14.2678 0.732233C14.7366 1.20107 15 1.83696 15 2.5V15.5C15 15.6733 14.9103 15.8342 14.7629 15.9253C14.6155 16.0164 14.4314 16.0247 14.2764 15.9472L8.5 13.059L2.72361 15.9472C2.56861 16.0247 2.38454 16.0164 2.23713 15.9253C2.08973 15.8342 2 15.6733 2 15.5V2.5C2 1.83696 2.26339 1.20107 2.73223 0.732233ZM4.5 1C4.10218 1 3.72064 1.15804 3.43934 1.43934C3.15804 1.72064 3 2.10218 3 2.5V14.691L8.27639 12.0528C8.41716 11.9824 8.58284 11.9824 8.72361 12.0528L14 14.691V2.5C14 2.10218 13.842 1.72064 13.5607 1.43934C13.2794 1.15804 12.8978 1 12.5 1H4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Bookmark'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.43934 0.43934C1.72064 0.158035 2.10218 0 2.5 0H9.5C9.89783 0 10.2794 0.158035 10.5607 0.43934C10.842 0.720644 11 1.10217 11 1.5V11.5C11 11.6844 10.8985 11.8538 10.7359 11.9408C10.5733 12.0278 10.3761 12.0183 10.2226 11.916L6 9.10093L1.77735 11.916C1.62392 12.0183 1.42665 12.0278 1.26407 11.9408C1.10149 11.8538 1 11.6844 1 11.5V1.5C1 1.10218 1.15804 0.720644 1.43934 0.43934ZM2.5 1C2.36739 1 2.24021 1.05268 2.14645 1.14645C2.05268 1.24021 2 1.36739 2 1.5V10.5657L5.72265 8.08397C5.8906 7.97201 6.1094 7.97201 6.27735 8.08397L10 10.5657V1.5C10 1.36739 9.94732 1.24022 9.85355 1.14645C9.75978 1.05268 9.63261 1 9.5 1H2.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Bookmark.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Bookmark;
