import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CollapsePanelTitle from './CollapsePanelTitle';
import CollapsePanelDetails from './CollapsePanelDetails';
import StyledCollapsePanelTitle from './components/CollapsePanelTitle';

export const CollapsePanel = ({
  children: childrenProp,
  title = null,
  details = null,
  dataTestId = '',
  domID = null,
  isLink = false,
  defaultExpanded = false,
  expanded: expandedProp,
  onChange = () => false,
  wrapTitle = null,
}) => {
  const { current: isControlled } = React.useRef(expandedProp !== undefined);
  const [expandedState, setExpandedState] = useState(defaultExpanded);
  const expanded = isControlled ? expandedProp : expandedState;

  const handleChange = (event) => {
    if (!isControlled) {
      setExpandedState(!expanded);
    }

    if (onChange) {
      onChange(event, !expanded);
    }
  };

  let titleElement;
  let detailsElement;

  if (title !== null) {
    titleElement = (
      <CollapsePanelTitle
        onClick={handleChange}
        isLink={isLink}
        expanded={expanded}
        {...(wrapTitle !== null && { wrapText: wrapTitle })}
      >
        {title}
      </CollapsePanelTitle>
    );

    if (details !== null) {
      detailsElement = (
        <CollapsePanelDetails expanded={expanded}>
          {details}
        </CollapsePanelDetails>
      );
    } else {
      detailsElement = (
        <CollapsePanelDetails expanded={expanded}>
          {childrenProp}
        </CollapsePanelDetails>
      );
    }
  } else {
    const [childTitle, ...childDetails] = React.Children.toArray(childrenProp);

    titleElement = React.cloneElement(childTitle, {
      onClick: handleChange,
      isLink,
      expanded,
      ...(wrapTitle !== null && { wrapText: wrapTitle }),
    });

    const titleDomID = childTitle.props.domID;
    const titleAriaControls = childTitle.props['aria-controls'];

    if (childDetails.length > 0) {
      detailsElement = React.cloneElement(childDetails[0], {
        expanded,
        ariaLabelledBy: titleDomID,
        id: titleAriaControls,
      });
    }
  }

  return (
    <div id={domID} data-testid={dataTestId}>
      <StyledCollapsePanelTitle aria-expanded={expanded}>
        {titleElement}
      </StyledCollapsePanelTitle>
      {detailsElement}
    </div>
  );
};

CollapsePanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  details: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** This prop serves as toggler whether entire are should be clickable or just arrow icon */
  isLink: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  /** (e: event, expanded: bool) => {}
   * The callback function is fired when the content is expanded or collapsed by clicking title. */
  onChange: PropTypes.func,
  domID: PropTypes.string,
  /** if true, the title will wrap instead of truncating. This can also be set using `wrapTitles` on a parent Accordion component, or using `wrapText` on the CollapsePanelTitle */
  wrapTitle: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default CollapsePanel;
