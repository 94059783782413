import * as base from '../base/basePalette';
import blendColors from '../../../../commonResources/colorBlender';

export const primary130 = blendColors(
  `${base.primary130}`,
  `${base.white}`,
  0.53,
);
export const primary115 = blendColors(
  `${base.primary115}`,
  `${base.white}`,
  0.4,
);
export const primary100 = blendColors(
  `${base.primary100}`,
  `${base.white}`,
  0.3,
);
export const primary70 = blendColors(`${base.primary70}`, `${base.white}`, 0.4);
export const primary50 = blendColors(`${base.primary50}`, `${base.white}`, 0.4);
export const primary30 = blendColors(`${base.primary30}`, `${base.white}`, 0.4);
export const primary15 = blendColors(`${base.primary15}`, `${base.white}`, 0.4);
export const primary10 = blendColors(`${base.primary10}`, `${base.white}`, 0.4);

export const primary = {
  130: primary130,
  115: primary115,
  100: primary100,
  70: primary70,
  50: primary50,
  30: primary30,
  15: primary15,
  10: primary10,
};

export const secondary100 = blendColors(
  `${base.secondary100}`,
  `${base.white}`,
  0.4,
);
export const secondary70 = blendColors(
  `${base.secondary70}`,
  `${base.white}`,
  0.4,
);
export const secondary50 = blendColors(
  `${base.secondary50}`,
  `${base.white}`,
  0.4,
);
export const secondary30 = blendColors(
  `${base.secondary30}`,
  `${base.white}`,
  0.4,
);
export const secondary15 = blendColors(
  `${base.secondary15}`,
  `${base.white}`,
  0.4,
);

export const secondary = {
  100: secondary100,
  70: secondary70,
  50: secondary50,
  30: secondary30,
  15: secondary15,
};

export const tertiary100 = blendColors(
  `${base.tertiary100}`,
  `${base.white}`,
  0.4,
);
export const tertiary70 = blendColors(
  `${base.tertiary70}`,
  `${base.white}`,
  0.4,
);
export const tertiary50 = blendColors(
  `${base.tertiary50}`,
  `${base.white}`,
  0.4,
);
export const tertiary30 = blendColors(
  `${base.tertiary30}`,
  `${base.white}`,
  0.4,
);
export const tertiary15 = blendColors(
  `${base.tertiary15}`,
  `${base.white}`,
  0.4,
);

export const tertiary = {
  100: tertiary100,
  70: tertiary70,
  50: tertiary50,
  30: tertiary30,
  15: tertiary15,
};

export const quaternary100 = blendColors(
  `${base.quaternary100}`,
  `${base.white}`,
  0.4,
);
export const quaternary70 = blendColors(
  `${base.quaternary70}`,
  `${base.white}`,
  0.4,
);
export const quaternary50 = blendColors(
  `${base.quaternary50}`,
  `${base.white}`,
  0.4,
);
export const quaternary30 = blendColors(
  `${base.quaternary30}`,
  `${base.white}`,
  0.4,
);
export const quaternary15 = blendColors(
  `${base.quaternary15}`,
  `${base.white}`,
  0.4,
);

export const quaternary = {
  100: quaternary100,
  70: quaternary70,
  50: quaternary50,
  30: quaternary30,
  15: quaternary15,
};

export const negative130 = blendColors(
  `${base.negative130}`,
  `${base.white}`,
  0.12,
);
export const negative100 = blendColors(
  `${base.negative100}`,
  `${base.white}`,
  0.13,
);
export const negative70 = blendColors(
  `${base.negative70}`,
  `${base.white}`,
  0.4,
);
export const negative50 = blendColors(
  `${base.negative50}`,
  `${base.white}`,
  0.4,
);
export const negative30 = blendColors(
  `${base.negative30}`,
  `${base.white}`,
  0.4,
);
export const negative15 = blendColors(
  `${base.negative15}`,
  `${base.white}`,
  0.4,
);

export const negative = {
  130: negative130,
  100: negative100,
  70: negative70,
  50: negative50,
  30: negative30,
  15: negative15,
};

export const caution100 = blendColors(
  `${base.caution100}`,
  `${base.white}`,
  0.4,
);
export const caution70 = blendColors(`${base.caution70}`, `${base.white}`, 0.4);
export const caution50 = blendColors(`${base.caution50}`, `${base.white}`, 0.4);
export const caution30 = blendColors(`${base.caution30}`, `${base.white}`, 0.4);
export const caution15 = blendColors(`${base.caution15}`, `${base.white}`, 0.4);

export const caution = {
  100: caution100,
  70: caution70,
  50: caution50,
  30: caution30,
  15: caution15,
};

export const positive130 = blendColors(
  `${base.positive130}`,
  `${base.white}`,
  0.12,
);
export const positive100 = blendColors(
  `${base.positive100}`,
  `${base.white}`,
  0.4,
);
export const positive70 = blendColors(
  `${base.positive70}`,
  `${base.white}`,
  0.4,
);
export const positive50 = blendColors(
  `${base.positive50}`,
  `${base.white}`,
  0.4,
);
export const positive30 = blendColors(
  `${base.positive30}`,
  `${base.white}`,
  0.4,
);
export const positive15 = blendColors(
  `${base.positive15}`,
  `${base.white}`,
  0.4,
);

export const positive = {
  130: positive130,
  100: positive100,
  70: positive70,
  50: positive50,
  30: positive30,
  15: positive15,
};

export const neutralBaseDarkMode = '#1C202B';

export const neutralAlt100 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.14,
);
export const neutralAlt75 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.2,
);
export const neutralAlt70 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.24,
);
export const neutralAlt50 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.26,
);
export const neutralAlt30 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.7,
);
export const neutralAlt15 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.75,
);

export const neutralAlt = {
  100: neutralAlt100,
  75: neutralAlt75,
  70: neutralAlt70,
  50: neutralAlt50,
  30: neutralAlt30,
  15: neutralAlt15,
};

export const accent130 = blendColors(
  `${base.accent130}`,
  `${base.white}`,
  0.12,
);
export const accent100 = blendColors(`#D31738`, `${base.white}`, 0.13); // the dark palette base100 differs from base palette
export const accent50 = blendColors(`${base.accent50}`, `${base.white}`, 0.4);
export const accent30 = blendColors(`${base.accent30}`, `${base.white}`, 0.4);
export const accent15 = blendColors(`${base.accent15}`, `${base.white}`, 0.4);

export const accent = {
  130: accent130,
  100: accent100,
  50: accent50,
  30: accent30,
  15: accent15,
};

export const neutral170 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.8,
);
export const neutral150 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.7,
);
export const neutral100 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.5,
);
export const neutral70 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.4,
);
export const neutral50 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.35,
);
export const neutral30 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.2,
);
export const neutral15 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.18,
);
export const neutral10 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.12,
);
export const neutral5 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.08,
);
export const neutral0 = blendColors(
  `${neutralBaseDarkMode}`,
  `${base.white}`,
  0.005,
);

export const neutral = {
  170: neutral170,
  150: neutral150,
  100: neutral100,
  70: neutral70,
  50: neutral50,
  30: neutral30,
  15: neutral15,
  10: neutral10,
  5: neutral5,
  0: neutral0,
};

export const white = '#ffffff';
export const black = '#000000';

export default {
  primary,
  secondary,
  tertiary,
  quaternary,
  negative,
  caution,
  positive,
  neutralAlt,
  accent,
  neutral,
  primary130,
  primary115,
  primary100,
  primary70,
  primary50,
  primary30,
  primary15,
  primary10,
  secondary100,
  secondary70,
  secondary50,
  secondary30,
  secondary15,
  tertiary100,
  tertiary70,
  tertiary50,
  tertiary30,
  tertiary15,
  quaternary100,
  quaternary70,
  quaternary50,
  quaternary30,
  quaternary15,
  negative130,
  negative100,
  negative70,
  negative50,
  negative30,
  negative15,
  caution100,
  caution70,
  caution50,
  caution30,
  caution15,
  positive130,
  positive100,
  positive70,
  positive50,
  positive30,
  positive15,
  neutral150,
  neutralAlt100,
  neutralAlt75,
  neutralAlt70,
  neutralAlt50,
  neutralAlt30,
  neutralAlt15,
  accent130,
  accent100,
  accent30,
  neutral100,
  neutral70,
  neutral50,
  neutral30,
  neutral15,
  neutral10,
  neutral5,
  neutral0,
  white,
  black,
};
