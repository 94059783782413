import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const CreditCard = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'CreditCard'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.14645 3.14645C1.24021 3.05268 1.36739 3 1.5 3H14.5C14.6326 3 14.7598 3.05268 14.8536 3.14645C14.9473 3.24021 15 3.36739 15 3.5V6H1V3.5C1 3.36739 1.05268 3.24021 1.14645 3.14645ZM0 6.5V3.5C0 3.10218 0.158035 2.72064 0.43934 2.43934C0.720644 2.15804 1.10218 2 1.5 2H14.5C14.8978 2 15.2794 2.15804 15.5607 2.43934C15.842 2.72064 16 3.10217 16 3.5V6.5V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14H1.5C1.10217 14 0.720644 13.842 0.43934 13.5607C0.158035 13.2794 0 12.8978 0 12.5V6.5ZM15 7V12.5C15 12.6326 14.9473 12.7598 14.8536 12.8536C14.7598 12.9473 14.6326 13 14.5 13H1.5C1.36739 13 1.24022 12.9473 1.14645 12.8536C1.05268 12.7598 1 12.6326 1 12.5V7H15ZM3.5 10C3.22386 10 3 10.2239 3 10.5C3 10.7761 3.22386 11 3.5 11H6.5C6.77614 11 7 10.7761 7 10.5C7 10.2239 6.77614 10 6.5 10H3.5ZM11.5 10C11.2239 10 11 10.2239 11 10.5C11 10.7761 11.2239 11 11.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H11.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Badge'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 5C1.5 4.17114 2.17114 3.5 3 3.5H16H21C21.8289 3.5 22.5 4.17114 22.5 5V6.5H1.5V5ZM0.5 7V5C0.5 3.61886 1.61886 2.5 3 2.5H16H21C22.3811 2.5 23.5 3.61886 23.5 5V7V11V19C23.5 20.3811 22.3811 21.5 21 21.5H3C1.61886 21.5 0.5 20.3811 0.5 19V11V7ZM1.5 10.5V7.5H22.5V10.5H1.5ZM1.5 11.5V19C1.5 19.8289 2.17114 20.5 3 20.5H21C21.8289 20.5 22.5 19.8289 22.5 19V11.5H1.5ZM4.5 16C4.5 15.7239 4.72386 15.5 5 15.5H10C10.2761 15.5 10.5 15.7239 10.5 16C10.5 16.2761 10.2761 16.5 10 16.5H5C4.72386 16.5 4.5 16.2761 4.5 16ZM18 15.5C17.7239 15.5 17.5 15.7239 17.5 16C17.5 16.2761 17.7239 16.5 18 16.5H19C19.2761 16.5 19.5 16.2761 19.5 16C19.5 15.7239 19.2761 15.5 19 15.5H18Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

CreditCard.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default CreditCard;
