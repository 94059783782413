import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import ProgressLinear from './components/progress.linear';
import ProgressCircular from './components/progress.circular';

const progressCircularSizeMap = {
  small: {
    width: 20,
    thickness: 1,
    doneThickness: 2,
    closeIconPosition: 2,
  },
  medium: {
    width: 28,
    thickness: 2,
    doneThickness: 2,
    closeIconPosition: 6,
  },
  large: {
    width: 36,
    thickness: 4,
    doneThickness: 4,
    closeIconPosition: 10,
  },
};

const ProgressIndicator = ({
  domID,
  variant = 'linear',
  progress = 0,
  isStoppable = true,
  onStop = () => false,
  fillColor: _fillColor,
  outerFillColor: _outerFillColor,
  size = 'large',
  dataTestId = '',
}) => {
  const theme = useTheme();
  const fillColor = useMemo(
    () => _fillColor || theme.UIPalette.Colors.Status.Positive,
    [_fillColor, theme],
  );
  const outerFillColor = useMemo(
    () => _outerFillColor || theme.BaseColors.Neutral['15'],
    [_outerFillColor, theme],
  );

  return (
    <>
      {variant === 'circular' ? (
        <ProgressCircular
          domID={domID}
          fillColor={fillColor}
          outerFillColor={outerFillColor}
          isStoppable={isStoppable}
          onStop={onStop}
          progress={progress}
          {...progressCircularSizeMap[size]}
          dataTestId={dataTestId}
        />
      ) : (
        <ProgressLinear
          domID={domID}
          fillColor={fillColor}
          isStoppable={isStoppable}
          onStop={onStop}
          progress={progress}
          dataTestId={dataTestId}
        />
      )}
    </>
  );
};

ProgressIndicator.propTypes = {
  domID: PropTypes.string,
  variant: PropTypes.oneOf(['linear', 'circular']),
  progress: PropTypes.number,
  isStoppable: PropTypes.bool,
  onStop: PropTypes.func,
  outerFillColor: PropTypes.string,
  fillColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  dataTestId: PropTypes.string,
};

export default ProgressIndicator;
