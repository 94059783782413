import styled from 'styled-components';

export default styled.div`
  display: flex;
  min-width: 0;

  .detail-header-tile {
    display: flex;
    flex-direction: column;
    & svg {
      height: 100%;
      align-self: center;
    }

    & .tile-icon {
      margin: 0;
    }
  }
`;
