import styled from 'styled-components';
import { UnstyledButton } from '../../../../commonResources/styles/buttons';
import { MASTHEAD_HEIGHT } from '../../../../BrandCore/constants/heights';
import { COLLAPSED_PANE_WIDTH } from '../../../../BrandCore/constants/widths';

export default styled(UnstyledButton)`
  position: absolute;
  width: ${COLLAPSED_PANE_WIDTH}px;
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);
  outline: none;
  display: block;
  z-index: 1;
  ${({ theme }) => theme.BoxShadows.Depth10};
`;
