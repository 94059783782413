import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: static;
  width: 51px;
  height: 31px;
  left: 12px;
  top: 2.5px;

  .loader,
  .indeterminate-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;

    &.emphasized {
      circle:first-child {
        stroke: ${(props) => props.theme.BaseColors.Primary['50']};
      }
      circle:nth-child(2) {
        stroke: ${(props) =>
          props.theme.UIPalette.Colors.Button.Disabled.Text.Emphasized};
      }
    }

    &.standerd,
    &.deEmphasized {
      circle:first-child {
        stroke: ${(props) => props.theme.BaseColors.Neutral['30']};
      }
    }
    &.standerd {
      circle:nth-child(2) {
        stroke: ${(props) => props.theme.BaseColors.White};
      }
    }
    &.deEmphasized {
      circle:nth-child(2) {
        stroke: ${(props) => props.theme.BaseColors.Neutral['150']};
      }
    }

    &.diminished {
      circle:first-child {
        stroke: ${(props) => props.theme.BaseColors.NeutralAlt['15']};
      }
      circle:nth-child(2) {
        stroke: ${(props) => props.theme.BaseColors.Primary['130']};
      }
    }

    &.destroy {
      circle:first-child {
        stroke: ${(props) => props.theme.BaseColors.Negative['30']};
      }
    }

    &.emphasizedAlt {
      circle:first-child {
        stroke: ${(props) => props.theme.BaseColors.Accent['15']};
      }
    }
    &.destroy,
    &.emphasizedAlt {
      circle:nth-child(2) {
        stroke: ${(props) => props.theme.BaseColors.White};
      }
    }

    &.deEmphasizedReversed {
      circle:first-child {
        stroke: ${(props) =>
          props.theme.UIPalette.Colors.Button.Disabled.Border
            .DeEmphasizedReversed};
      }
      circle:nth-child(2) {
        stroke: ${(props) => props.theme.BaseColors.White};
      }
    }
  }

  &.small {
    height: 28px;
    left: 8px;
    top: 0px;

    svg:not(.check) {
      width: 18px;
      height: 18px;
    }
  }

  &.medium {
    height: 31px;
    left: 12px;
    top: 2.5px;

    svg:not(.check) {
      width: 20px;
      height: 20px;
    }
  }

  &.large {
    height: 35px;
    left: 14px;
    top: 4.5px;

    svg:not(.check) {
      width: 24px;
      height: 24px;
    }
  }
`;
