import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Help = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Help'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.5 2C6.70101 2 2 6.70101 2 12.5C2 18.299 6.70101 23 12.5 23C18.299 23 23 18.299 23 12.5C23 6.70101 18.299 2 12.5 2ZM1 12.5C1 6.14873 6.14873 1 12.5 1C18.8513 1 24 6.14873 24 12.5C24 18.8513 18.8513 24 12.5 24C6.14873 24 1 18.8513 1 12.5ZM12.5 19.5C13.0523 19.5 13.5 19.0523 13.5 18.5C13.5 17.9477 13.0523 17.5 12.5 17.5C11.9477 17.5 11.5 17.9477 11.5 18.5C11.5 19.0523 11.9477 19.5 12.5 19.5ZM10.5551 7.51735C11.4723 7.112 12.5225 6.93609 13.4291 7.0212C14.3481 7.10746 15.0233 7.44786 15.3447 7.96785C16.0303 9.07735 15.6305 10.4605 14.2178 11.6575C12.7268 12.9209 12 13.7464 12 15C12 15.2762 12.2239 15.5 12.5 15.5C12.7761 15.5 13 15.2762 13 15C13 14.2536 13.3552 13.6991 14.8642 12.4205C16.4515 11.0755 17.2597 9.16467 16.1953 7.44218C15.6417 6.54616 14.5984 6.12657 13.5226 6.02558C12.4345 5.92344 11.2137 6.13302 10.1509 6.60268C9.89832 6.7143 9.78405 7.00954 9.89567 7.26212C10.0073 7.51469 10.3025 7.62896 10.5551 7.51735Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Help'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8.00299 13C8.55528 13 9.00299 12.5523 9.00299 12C9.00299 11.4477 8.55528 11 8.00299 11C7.45071 11 7.00299 11.4477 7.00299 12C7.00299 12.5523 7.45071 13 8.00299 13ZM6.83498 4.44825C7.19016 4.12773 7.79512 3.94688 8.3582 4.01357C8.9047 4.0783 9.30691 4.35496 9.44073 4.84402L9.44099 4.84496C9.54253 5.21316 9.49633 5.44764 9.39756 5.64824C9.28506 5.87673 9.09776 6.08188 8.82345 6.38237L8.7909 6.41803C8.51542 6.71995 8.18475 7.09129 7.93001 7.58849C7.67121 8.09362 7.5 8.7084 7.5 9.50005C7.5 9.77619 7.72386 10 8 10C8.27615 10 8.5 9.77619 8.5 9.50005C8.5 8.86269 8.63543 8.40472 8.82 8.04448C9.00863 7.6763 9.25808 7.38964 9.52961 7.09206L9.59257 7.02329C9.83485 6.75909 10.1164 6.45207 10.2947 6.08998C10.5068 5.65932 10.5684 5.17219 10.4051 4.5796C10.1338 3.58898 9.30118 3.11827 8.47581 3.02052C7.66689 2.92471 6.76285 3.16636 6.16503 3.70584C5.96002 3.89085 5.9438 4.20701 6.1288 4.41202C6.31381 4.61703 6.62997 4.63325 6.83498 4.44825Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Help'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.1434 3.4283C5.73561 3.39276 5.26452 3.5257 4.87565 3.9684C4.69341 4.17587 4.37749 4.19632 4.17002 4.01408C3.96255 3.83184 3.9421 3.51592 4.12434 3.30845C4.73547 2.61272 5.5245 2.37057 6.23023 2.43208C6.90864 2.49121 7.56505 2.84083 7.87167 3.39704C8.43775 4.42391 7.99594 5.53619 7.19787 6.29508C6.49901 6.95962 6.40403 7.19535 6.40403 7.5C6.40403 7.77614 6.18017 8 5.90403 8C5.62788 8 5.40403 7.77614 5.40403 7.5C5.40403 6.74524 5.79201 6.25198 6.50878 5.5704C7.12634 4.98317 7.25697 4.35334 6.99592 3.87981C6.88898 3.68581 6.57849 3.46622 6.1434 3.4283ZM6.5 9.5C6.5 9.22386 6.27614 9 6 9C5.72386 9 5.5 9.22386 5.5 9.5C5.5 9.77614 5.72386 10 6 10C6.27614 10 6.5 9.77614 6.5 9.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Help.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Help;
