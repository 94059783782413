import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import StyledContainerWrapper from './components/styles.ContainerWrapper';
import {
  CheckboxIconContainer,
  RadioIconContainer,
} from './components/styles.IconContainer';
import StyledLabelWrapper from './components/styles.LabelWrapper';
import CheckboxCheckedIcon from './components/svg.checkbox.checked';
import RadioCheckedIcon from './components/svg.radio.checked';
import IndeterminateIcon from './components/svg.checkbox.indeterminate';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';
import Tooltip from '../Tooltip';

const CheckboxRadioBase = ({
  ariaLabelledBy = null,
  ariaLabel = null,
  className = '',
  domID = '',
  dataTestId = '',
  checked = false,
  indeterminate = false,
  disabled = false,
  label = null,
  size = 'medium',
  onChange = () => false,
  isRadio = false,
  tooltipWidth = 200,
  tooltipPosition = 'top-center',
  detectTruncated,
}) => {
  const [checkedState, setCheckedState] = useState(checked);
  const [indeterminateState, setIndeterminateState] = useState(indeterminate);
  const [disabledState, setDisabledState] = useState(disabled);
  useEffect(() => setCheckedState(checked), [checked]);
  useEffect(() => setIndeterminateState(indeterminate), [indeterminate]);
  useEffect(() => setDisabledState(disabled), [disabled]);

  const ref = useRef(null);

  const isTruncated = useIsTruncated(ref, [label]);

  useEffect(() => {
    if (detectTruncated) {
      detectTruncated(isTruncated);
    }
  }, [isTruncated]);

  const handleChange = (e) => {
    e.preventDefault();
    if (!disabled) {
      if (!indeterminateState) {
        if ((isRadio && !checkedState) || !isRadio) {
          setCheckedState(!checkedState);
          onChange(e, !checkedState);
        }
      } else {
        onChange(e);
      }
    }
  };

  // determine icon
  let Icon = null;
  if (isRadio) {
    if (checkedState) {
      Icon = RadioCheckedIcon;
    }
  } else if (indeterminateState) {
    Icon = IndeterminateIcon;
  } else if (checkedState) {
    Icon = CheckboxCheckedIcon;
  }

  const IconContainer = isRadio ? RadioIconContainer : CheckboxIconContainer;

  // determine css classes
  const commonClasses = clsx({
    indeterminate: indeterminateState,
    checked: checkedState,
    disabled: disabledState,
  });

  return (
    <StyledContainerWrapper
      data-testid={dataTestId}
      className={clsx(className, commonClasses)}
      id={domID}
      onClick={handleChange}
      onKeyPress={handleChange}
    >
      <IconContainer
        className={clsx(commonClasses, 'icon-container')}
        role={isRadio ? 'radio' : 'checkbox'}
        aria-labelledby={ariaLabelledBy}
        aria-label={ariaLabel}
        tabIndex={0}
      >
        {Icon && <Icon className={clsx(commonClasses)} />}
      </IconContainer>
      {label && (
        <Tooltip
          hideTooltip={!isTruncated}
          tooltipContent={label}
          tooltipWidth={tooltipWidth}
          tooltipPosition={tooltipPosition}
          isButtonRoleDisabled
        >
          <StyledLabelWrapper className={clsx(commonClasses, size)} ref={ref}>
            {label}
          </StyledLabelWrapper>
        </Tooltip>
      )}
    </StyledContainerWrapper>
  );
};

CheckboxRadioBase.propTypes = {
  ariaLabelledBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  /* id applied to container */
  domID: PropTypes.string,
  /* applied to container */
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func,
  isRadio: PropTypes.bool,
  tooltipWidth: PropTypes.number,
  tooltipPosition: PropTypes.string,
  detectTruncated: PropTypes.func,
};

export default CheckboxRadioBase;
