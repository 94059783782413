/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from './Section';

const SectionsWrapper = styled.div`
  .section {
    display: block;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const Sections = ({ sections = [], ...otherProps }) => {
  return (
    <SectionsWrapper>
      {sections.map((section, sectionIndex) => (
        <Section
          key={sectionIndex}
          section={section}
          sectionIndex={sectionIndex}
          {...otherProps}
        />
      ))}
    </SectionsWrapper>
  );
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.any),
};

export default Sections;
