import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Pen = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pen'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M18.8536 2.14645C18.6583 1.95118 18.3417 1.95118 18.1465 2.14645L15.1481 5.14479C15.1476 5.14534 15.147 5.14589 15.1464 5.14645C15.1459 5.147 15.1453 5.14755 15.1448 5.14811L3.14645 17.1464C3.07665 17.2162 3.02908 17.3051 3.00972 17.4019L2.00972 22.4019C1.97693 22.5659 2.02824 22.7353 2.14645 22.8536C2.26467 22.9718 2.43414 23.0231 2.59807 22.9903L7.59807 21.9903C7.69486 21.9709 7.78376 21.9234 7.85356 21.8536L19.8536 9.85355L22.8536 6.85355C23.0488 6.65829 23.0488 6.34171 22.8536 6.14645L18.8536 2.14645ZM19.5 8.7929L21.7929 6.5L18.5 3.20711L16.2071 5.5L19.5 8.7929ZM15.5 6.20711L18.7929 9.5L7.2535 21.0394L3.13739 21.8626L3.96061 17.7465L15.5 6.20711Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pen'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.3536 0.646447C12.1583 0.451184 11.8417 0.451184 11.6465 0.646447L9.64839 2.64451C9.64774 2.64516 9.64709 2.6458 9.64645 2.64645C9.6458 2.64709 9.64515 2.64774 9.64451 2.64839L1.64646 10.6464C1.58238 10.7105 1.53692 10.7908 1.51494 10.8787L0.514939 14.8787C0.472342 15.0491 0.522267 15.2294 0.646457 15.3536C0.770647 15.4777 0.950891 15.5277 1.12128 15.4851L5.12128 14.4851C5.20919 14.4631 5.28948 14.4176 5.35356 14.3536L13.3536 6.35355L15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.3536 0.646447ZM13 5.2929L14.2929 4L12 1.70711L10.7071 3.00001L13 5.2929ZM10 3.70711L12.2929 6.00001L4.74439 13.5485L1.68719 14.3128L2.45149 11.2556L10 3.70711Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pen'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.14646 0.146447C9.34172 -0.0488155 9.6583 -0.0488155 9.85357 0.146447L11.8536 2.14645C12.0488 2.34171 12.0488 2.65829 11.8536 2.85355L4.85357 9.85355C4.79868 9.90844 4.73177 9.94979 4.65813 9.97434L1.65813 10.9743C1.47846 11.0342 1.28038 10.9875 1.14646 10.8536C1.01254 10.7196 0.965783 10.5216 1.02567 10.3419L2.02567 7.34189C2.05022 7.26825 2.09157 7.20133 2.14646 7.14645L9.14646 0.146447ZM2.93703 7.77009L2.29058 9.70943L4.22992 9.06298L10.7929 2.5L9.50001 1.20711L2.93703 7.77009Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Pen.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Pen;
