import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.div`
  flex: 1;
  align-self: center;
  ${TextStyles.small};
  display: flex;
  flex-direction: column;
`;
