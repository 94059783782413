import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  lessTransparentNeutral100,
  transparentNeutral100,
} from '../../commonResources/internalColors';
import * as Modal from '../Modal';
import Button from '../../Atoms/Button';
import ModalWrapper from '../Modal/components/ModalWrapper';
import ButtonGroup from '../../Molecules/ButtonGroup';
import { Container, Row, Col } from '../../Layouts';

export const TIME_OUT_MODAL_TITLE = 'Your session will end soon';

const TimeOutModalWrapper = styled(ModalWrapper)`
  background-color: ${lessTransparentNeutral100};
  @supports (backdrop-filter: blur(5px)) {
    background-color: ${transparentNeutral100};
    &.active {
      backdrop-filter: blur(5px);
    }
  }
  > div,
  .active-content {
    padding: 0;
  }
`;

const BodyWrapper = styled.div`
  color: ${({ theme }) => theme.BaseColors.Neutral['100']};
`;

const TimeOutModal = ({
  isOpen = false,
  minutesUntilSignOut = null,
  secondsUntilSignOut = null,
  onSignOut = () => false,
  onStaySignedIn = () => false,
  disableSignOutButton = false,
  disableStaySignedInButton = false,
  domID = null,
  dataTestId = '',
}) => {
  const getTime = (minutes, seconds) => {
    if (seconds) {
      return `${seconds} second${seconds > 1 ? 's' : ''}`;
    }

    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return `0 second`;
  };

  const signOutMessage = `You're session is about to expire. For your security,
  you will be logged out in about ${getTime(
    minutesUntilSignOut,
    secondsUntilSignOut,
  )}`;

  const testIds = {
    stayButton: `${dataTestId}-stay-signed-in`,
    signOutButton: `${dataTestId}-sign-out`,
    header: `${dataTestId}-header`,
    body: `${dataTestId}-body`,
    footer: `${dataTestId}-footer`,
  };

  return (
    <Modal.Modal
      domID={domID}
      isOpen={isOpen}
      showDefaultClose={false}
      wrapperComponent={TimeOutModalWrapper}
      initialFocus={false}
    >
      <Modal.ModalHeader
        dataTestId={testIds.header}
        title={TIME_OUT_MODAL_TITLE}
      />

      <Modal.ModalBody data-testid={testIds.body}>
        <Container>
          <Row>
            <Col>
              <BodyWrapper>{signOutMessage}</BodyWrapper>
            </Col>
          </Row>
        </Container>
      </Modal.ModalBody>
      <Modal.ModalFooter data-testid={testIds.footer}>
        <ButtonGroup>
          <Button
            name="Sign Out"
            buttonType="diminished"
            disabled={disableSignOutButton}
            onClick={onSignOut}
            size="medium"
            domID={`${domID}-sign-out`}
            dataTestId={testIds.signOutButton}
          />
          <Button
            name="Stay Signed In"
            buttonType="emphasized"
            disabled={disableStaySignedInButton}
            onClick={onStaySignedIn}
            size="large"
            domID={`${domID}-stay-signed-in`}
            dataTestId={testIds.stayButton}
          />
        </ButtonGroup>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

TimeOutModal.propTypes = {
  /**
   * id attribute for modal.
   *
   * id can be used in css or javascript to traget a particular elememnt in DOM.
   */
  domID: PropTypes.string,
  /**
   * data-testid attribute for automated tests.
   */
  dataTestId: PropTypes.string,
  /**
   * Controls whether the modal is shown.
   */
  isOpen: PropTypes.bool,
  /**
   * Callback to trigger when the "sign out" button is clicked
   * */
  onSignOut: PropTypes.func.isRequired,
  /**
   * Indicates whether "sign out" button is disabled.
   *
   * Set to true while logging out to prevent multiple clicks.
   *
   * We recommend adding some throttling technique to properly prevent this as button can be enabled from dev tools.
   * */
  disableSignOutButton: PropTypes.bool,
  /**
   * Callback to trigger when the "stay signed in" button is clicked
   * */
  onStaySignedIn: PropTypes.func.isRequired,
  /**
   * Indicates whether "stay signed in" button is disabled.
   *
   * Set to true to prevent multiple clicks.
   *
   * We recommend adding some throttling technique to properly prevent this as button can be enabled from dev tools.
   * */
  disableStaySignedInButton: PropTypes.bool,
  /**
   * Number of minutes to display in modal message. When its below 60 second use secondsUntilSignOut prop.
   * */
  minutesUntilSignOut: PropTypes.number.isRequired,
  /**
   * Number of seconds until the user gets logged out. Use seconds only to show time that is less than 60s.
   *
   */
  secondsUntilSignOut: PropTypes.string,
};

export default TimeOutModal;
