import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Unlocked = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Unlocked'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M12.6051 1H12.5024C11.058 0.986303 9.66715 1.54649 8.63543 2.55758C7.6031 3.56927 7.01482 4.94954 7 6.39488V11H3.5C3.22386 11 3 11.2239 3 11.5V23.5C3 23.7761 3.22386 24 3.5 24H21.5C21.7761 24 22 23.7761 22 23.5V11.5C22 11.2239 21.7761 11 21.5 11H8V6.40263C8.01275 5.22335 8.49301 4.0973 9.33536 3.2718C10.1783 2.44571 11.3148 1.98826 12.495 2H12.5974C13.7766 2.01275 14.9027 2.49301 15.7282 3.33536C16.5543 4.17831 17.0118 5.31484 17 6.49503C16.9973 6.77116 17.2189 6.99723 17.495 6.99998C17.7712 7.00272 17.9972 6.78111 18 6.50498C18.0144 5.05964 17.4541 3.66776 16.4424 2.63543C15.4307 1.6031 14.0505 1.01482 12.6051 1ZM7.50099 12C7.50066 12 7.50033 12 7.5 12C7.49967 12 7.49934 12 7.49901 12H4V23H21V12H7.50099ZM12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19C13.3284 19 14 18.3284 14 17.5C14 16.6716 13.3284 16 12.5 16ZM10 17.5C10 16.1193 11.1193 15 12.5 15C13.8807 15 15 16.1193 15 17.5C15 18.8807 13.8807 20 12.5 20C11.1193 20 10 18.8807 10 17.5Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Unlocked'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1C6.34314 1 5 2.34314 5 4V6H13.5C14.3281 6 15 6.67186 15 7.5V14.5C15 15.3281 14.3281 16 13.5 16H2.5C1.67186 16 1 15.3281 1 14.5V7.5C1 6.67186 1.67186 6 2.5 6H4V4C4 1.79086 5.79086 0 8 0C9.81319 0 11.3429 1.20516 11.8343 2.85748C11.913 3.12217 11.7622 3.40055 11.4975 3.47926C11.2328 3.55797 10.9545 3.40721 10.8757 3.14252C10.5071 1.90284 9.35881 1 8 1ZM2.5 7H4.5H13.5C13.7759 7 14 7.22414 14 7.5V14.5C14 14.7759 13.7759 15 13.5 15H2.5C2.22414 15 2 14.7759 2 14.5V7.5C2 7.22414 2.22414 7 2.5 7ZM7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11ZM8 9C6.89543 9 6 9.89543 6 11C6 12.1046 6.89543 13 8 13C9.10457 13 10 12.1046 10 11C10 9.89543 9.10457 9 8 9Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Unlocked'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.58579 1.58579C4.96086 1.21071 5.46957 1 6 1C6.26264 1 6.52272 1.05173 6.76537 1.15224C7.00802 1.25275 7.2285 1.40007 7.41421 1.58579C7.59993 1.7715 7.74725 1.99198 7.84776 2.23463C8.12132 2.89507 9.15432 2.23463 8.77164 1.85195C8.62087 1.48797 8.3999 1.15726 8.12132 0.87868C7.84274 0.600104 7.51203 0.379125 7.14805 0.228361C6.78407 0.0775973 6.39397 0 6 0C5.20435 0 4.44129 0.31607 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V5H1.5C1.22386 5 1 5.22386 1 5.5V11.5C1 11.7761 1.22386 12 1.5 12H10.5C10.7761 12 11 11.7761 11 11.5V5.5C11 5.22386 10.7761 5 10.5 5H4V3C4 2.46957 4.21071 1.96086 4.58579 1.58579ZM3.5 6H2V11H10V6H3.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Unlocked.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Unlocked;
