import React from 'react';
import PropTypes from 'prop-types';
import Heading from './components/Heading';

export const GroupTitle = ({
  domID = '',
  className = '',
  dataTestId = '',
  title = '',
}) => (
  <Heading id={domID} className={className} data-testid={dataTestId}>
    {title}
  </Heading>
);

GroupTitle.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** The displayed text/title */
  title: PropTypes.string.isRequired,
};

export default GroupTitle;
