import styled, { css } from 'styled-components';
import padding from '../../../commonResources/padding';

const sticky = css`
  position: sticky;
  top: 0;
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.Depth10};
  z-index: 10;
  overflow: visible;
`;

export const ContainerDiv = styled.div`
  padding: ${padding.detailPaneHeader.top}px ${padding.detailPaneHeader.left}px;
  padding-bottom: ${padding.detailPaneHeader.bottom}px;
  border-bottom: ${({ theme, showLine }) =>
    showLine && `1px solid ${theme.BaseColors.Neutral['15']}`};
  ${(props) => props.sticky && sticky};
`;

export default {
  ContainerDiv,
};
