export const getDOMElementTotalWidth = (el) => {
  if (!el) {
    return 0;
  }
  const elStyle = window.getComputedStyle(el);
  const elWidth =
    el.offsetWidth +
    parseInt(elStyle.marginLeft, 10) +
    parseInt(elStyle.marginRight, 10);

  return elWidth;
};

export const detectWrap = (className, maxWidth) => {
  let totalWidth = 0;
  const items = document.querySelectorAll(className);
  for (let i = 0; i < items.length; i += 1) {
    const currItem = items[i].getBoundingClientRect();
    totalWidth += currItem.width;
    if (totalWidth > maxWidth) {
      return i;
    }
  }

  return -1;
};

export default { getDOMElementTotalWidth };
