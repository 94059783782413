import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Close = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          aria-label={title !== null ? title : 'Close'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.85355 3.14645C3.65829 2.95118 3.34171 2.95118 3.14645 3.14645C2.95118 3.34171 2.95118 3.65829 3.14645 3.85355L11.2929 12L3.14645 20.1464C2.95118 20.3417 2.95118 20.6583 3.14645 20.8536C3.34171 21.0488 3.65829 21.0488 3.85355 20.8536L12 12.7071L20.1464 20.8536C20.3417 21.0488 20.6583 21.0488 20.8536 20.8536C21.0488 20.6583 21.0488 20.3417 20.8536 20.1464L12.7071 12L20.8536 3.85355C21.0488 3.65829 21.0488 3.34171 20.8536 3.14645C20.6583 2.95118 20.3417 2.95118 20.1464 3.14645L12 11.2929L3.85355 3.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Close'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13.8536 2.85355C14.0488 2.65829 14.0488 2.34171 13.8536 2.14645C13.6583 1.95118 13.3417 1.95118 13.1464 2.14645L8 7.29289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L7.29289 8L2.14645 13.1464C1.95118 13.3417 1.95118 13.6583 2.14645 13.8536C2.34171 14.0488 2.65829 14.0488 2.85355 13.8536L8 8.70711L13.1464 13.8536C13.3417 14.0488 13.6583 14.0488 13.8536 13.8536C14.0488 13.6583 14.0488 13.3417 13.8536 13.1464L8.70711 8L13.8536 2.85355Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Close'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L5.29289 6L2.14645 9.14645C1.95118 9.34171 1.95118 9.65829 2.14645 9.85355C2.34171 10.0488 2.65829 10.0488 2.85355 9.85355L6 6.70711L9.14645 9.85355C9.34171 10.0488 9.65829 10.0488 9.85355 9.85355C10.0488 9.65829 10.0488 9.34171 9.85355 9.14645L6.70711 6L9.85355 2.85355C10.0488 2.65829 10.0488 2.34171 9.85355 2.14645C9.65829 1.95118 9.34171 1.95118 9.14645 2.14645L6 5.29289L2.85355 2.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'xSmall':
      return (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Close'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.85355 1.14645C1.65829 0.951184 1.34171 0.951184 1.14645 1.14645C0.951184 1.34171 0.951184 1.65829 1.14645 1.85355L3.29289 4L1.14645 6.14645C0.951184 6.34171 0.951184 6.65829 1.14645 6.85355C1.34171 7.04882 1.65829 7.04882 1.85355 6.85355L4 4.70711L6.14645 6.85355C6.34171 7.04882 6.65829 7.04882 6.85355 6.85355C7.04882 6.65829 7.04882 6.34171 6.85355 6.14645L4.70711 4L6.85355 1.85355C7.04882 1.65829 7.04882 1.34171 6.85355 1.14645C6.65829 0.951185 6.34171 0.951185 6.14645 1.14645L4 3.29289L1.85355 1.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Close.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Close;
