import * as base from '../base/basePalette';

export const primary10 = '#F1EFFD';
export const primary15 = '#EAE8FC';
export const primary30 = '#D5D0F9';
export const primary50 = '#B9AFF6';
export const primary70 = '#9A8DF2';
export const primary100 = '#6E5BEC';
export const primary115 = '#472FE6';
export const primary130 = '#2C16BB';

export const primary = {
  10: primary10,
  15: primary15,
  30: primary30,
  50: primary50,
  70: primary70,
  100: primary100,
  115: primary115,
  130: primary130,
};

export const secondary = { ...base.secondary };
export const tertiary = { ...base.tertiary };
export const quaternary = { ...base.quaternary };
export const negative = { ...base.negative };
export const caution = { ...base.caution };
export const positive = { ...base.positive };

export const neutralAlt15 = '#BBEFF2';
export const neutralAlt30 = '#94E6EB';
export const neutralAlt50 = '#5EDAE0';
export const neutralAlt70 = '#24B5BD';
export const neutralAlt75 = '#21A5AC';
export const neutralAlt100 = '#1C8F95';

export const neutralAlt = {
  15: neutralAlt15,
  30: neutralAlt30,
  50: neutralAlt50,
  70: neutralAlt70,
  75: neutralAlt75,
  100: neutralAlt100,
};

export const accent = { ...base.accent };

export const neutral0 = '#F7F6F7';
export const neutral5 = '#F4F3F5';
export const neutral10 = '#EFEDF1';
export const neutral15 = '#E5E2E8';
export const neutral30 = '#D3CED7';
export const neutral50 = '#B2AABA';
export const neutral70 = '#9A92A2';
export const neutral100 = '#766D7F';
export const neutral150 = '#1F0A33';
export const neutral170 = '#1F0A33';

export const neutral = {
  0: neutral0,
  5: neutral5,
  10: neutral10,
  15: neutral15,
  30: neutral30,
  50: neutral50,
  70: neutral70,
  100: neutral100,
  150: neutral150,
  170: neutral170,
};

export const white = '#fff';
export const black = '#000';

export default {
  primary,
  secondary,
  tertiary,
  quaternary,
  negative,
  caution,
  positive,
  neutralAlt,
  accent,
  neutral,

  primary10,
  primary15,
  primary30,
  primary50,
  primary70,
  primary100,
  primary115,
  primary130,

  neutralAlt15,
  neutralAlt30,
  neutralAlt50,
  neutralAlt70,
  neutralAlt75,
  neutralAlt100,

  neutral0,
  neutral5,
  neutral10,
  neutral15,
  neutral30,
  neutral50,
  neutral70,
  neutral100,
  neutral150,
  neutral170,
};
