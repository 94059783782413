import styled from 'styled-components';
import { UnstyledButton } from '../../../commonResources/styles/buttons';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled(UnstyledButton)`
  ${TextStyles.small};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  outline: none;

  &.active {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
  }

  &.disabled {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Disabled};
  }

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
`;
