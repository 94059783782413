import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Reload = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Reload'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M22.3672 1.70454C22.6408 1.74165 22.8326 1.99355 22.7955 2.26719L21.9955 8.16719C21.9584 8.4404 21.7072 8.63206 21.4339 8.59562L15.4339 7.79562C15.1602 7.75913 14.9679 7.50765 15.0044 7.23393C15.0409 6.96021 15.2924 6.7679 15.5661 6.8044L20.5418 7.46782C18.8998 4.76087 15.9035 3 12.5 3C7.27614 3 3 7.27614 3 12.5C3 12.7761 2.77614 13 2.5 13C2.22386 13 2 12.7761 2 12.5C2 6.72386 6.72386 2 12.5 2C16.12 2 19.3356 3.8078 21.1948 6.62973L21.8045 2.13283C21.8416 1.85919 22.0935 1.66744 22.3672 1.70454ZM23 12.5C23 12.2239 22.7761 12 22.5 12C22.2239 12 22 12.2239 22 12.5C22 17.7239 17.7239 22 12.5 22C9.09648 22 6.10022 20.2391 4.45823 17.5322L9.43392 18.1956C9.70764 18.2321 9.95912 18.0398 9.99562 17.7661C10.0321 17.4924 9.8398 17.2409 9.56608 17.2044L3.56608 16.4044C3.29279 16.368 3.04158 16.5596 3.00454 16.8328L2.20454 22.7328C2.16743 23.0065 2.35918 23.2584 2.63282 23.2955C2.90646 23.3326 3.15837 23.1408 3.19547 22.8672L3.80522 18.3703C5.66436 21.1922 8.88003 23 12.5 23C18.2761 23 23 18.2761 23 12.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Reload'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 7.5C2 3.91014 4.91014 1 8.5 1C10.6385 1 12.4838 2.17997 13.6444 4H10.5C10.2239 4 10 4.22386 10 4.5C10 4.77614 10.2239 5 10.5 5H14.4899C14.5312 5.00087 14.573 4.9966 14.6143 4.98688C14.6729 4.97318 14.7275 4.94918 14.7761 4.9169C14.9007 4.8346 14.9785 4.70316 14.9962 4.5618C14.9992 4.53779 15.0005 4.5135 15 4.48912V0.5C15 0.223858 14.7761 0 14.5 0C14.2239 0 14 0.223858 14 0.5V2.77699C12.675 1.09864 10.7463 0 8.5 0C4.35786 0 1 3.35786 1 7.5C1 7.77614 1.22386 8 1.5 8C1.77614 8 2 7.77614 2 7.5ZM15.5 8C15.7761 8 16 8.22386 16 8.5C16 12.6421 12.6421 16 8.49999 16C6.25372 16 4.32498 14.9014 3 13.223V15.5C3 15.7761 2.77614 16 2.5 16C2.22386 16 2 15.7761 2 15.5V11.5114C1.99941 11.4848 2.00096 11.4583 2.00457 11.4321C2.01393 11.3632 2.03733 11.2987 2.0718 11.2417C2.10921 11.1796 2.16048 11.125 2.22386 11.0831C2.27211 11.0511 2.32625 11.0272 2.38429 11.0135C2.42602 11.0035 2.46827 10.9991 2.51009 11H6.5C6.77614 11 7 11.2239 7 11.5C7 11.7761 6.77614 12 6.5 12H3.35562C4.51621 13.82 6.36144 15 8.49999 15C12.0898 15 15 12.0899 15 8.5C15 8.22386 15.2238 8 15.5 8Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Reload'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.48441 1.02613C6.39858 0.931476 7.32116 1.0905 8.15081 1.48574C8.50902 1.65638 8.98662 2.08116 9.45798 2.63112C9.84764 3.08576 10.1971 3.58342 10.4336 4.00143H8.5C8.22386 4.00143 8 4.22518 8 4.50119C8 4.7772 8.22386 5.00095 8.5 5.00095H11.5C11.7761 5.00095 12 4.7772 12 4.50119V1.50261C12 1.2266 11.7761 1.00285 11.5 1.00285C11.2239 1.00285 11 1.2266 11 1.50261V3.01577C10.7706 2.66948 10.5014 2.31224 10.2174 1.98085C9.72489 1.4062 9.13546 0.847565 8.58105 0.583453C7.58548 0.109172 6.47838 -0.0816593 5.38137 0.0319214C4.28437 0.145502 3.23991 0.559101 2.36273 1.22728C1.48555 1.89546 0.809599 2.79237 0.409126 3.81948C0.308853 4.07665 0.436144 4.36637 0.693438 4.4666C0.950731 4.56682 1.2406 4.43959 1.34087 4.18242C1.6746 3.3265 2.23789 2.57908 2.96887 2.02226C3.69985 1.46544 4.57024 1.12078 5.48441 1.02613ZM11.5909 8.18052C11.6911 7.92335 11.5638 7.63363 11.3066 7.5334C11.0493 7.43318 10.7594 7.56041 10.6591 7.81758C10.3254 8.6735 9.7621 9.42092 9.03112 9.97774C8.30014 10.5346 7.42975 10.8792 6.51558 10.9739C5.60141 11.0685 4.67883 10.9095 3.84918 10.5143C3.46265 10.3301 2.9871 9.96659 2.52835 9.52606C2.17627 9.18796 1.85457 8.8243 1.61076 8.49881H3.5C3.77614 8.49881 4 8.27506 4 7.99905C4 7.72304 3.77614 7.49929 3.5 7.49929H0.5C0.223858 7.49929 0 7.72304 0 7.99905V10.9976C0 11.2736 0.223858 11.4974 0.5 11.4974C0.776142 11.4974 1 11.2736 1 10.9976V9.34095C1.24653 9.64491 1.53374 9.95702 1.83554 10.2468C2.32757 10.7193 2.89286 11.1659 3.41894 11.4165C4.41452 11.8908 5.52162 12.0817 6.61862 11.9681C7.71562 11.8545 8.76008 11.4409 9.63726 10.7727C10.5144 10.1045 11.1904 9.20763 11.5909 8.18052Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Reload.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Reload;
