import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.span`
  display: inline-flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${TextStyles.xxSmallCaps};
  color: ${(props) => props.color};
  padding-left: 1rem;

  .tooltip {
    margin-top: 13px;
  }
`;
