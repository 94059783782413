import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MicroModal from '../../../Molecules/MicroModal';
import Tooltip from '../../Tooltip';
import HelpIcon from '../../../BrandCore/icons/informational/Help';
import TextStyles from '../../../BrandCore/Text/styles';
import IconButton from '../../IconButton';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelWrapper = styled.label`
  display: block;
  margin-bottom: 3px;
  ${TextStyles.xSmall};
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
`;

const HelpContainer = styled.div`
  margin-left: 6px;
`;

const Label = ({ tooltipProps, micromodalProps, children, ...other }) => {
  return (
    <LabelContainer>
      <LabelWrapper {...other}>{children}</LabelWrapper>
      {(tooltipProps || micromodalProps) && (
        <HelpContainer>
          {tooltipProps && (
            <Tooltip {...tooltipProps}>
              <HelpIcon size="small" />
            </Tooltip>
          )}
          {micromodalProps && !tooltipProps && (
            <MicroModal
              {...micromodalProps}
              anchorComponent={IconButton}
              anchorProps={{ size: 'small', icon: HelpIcon }}
            >
              {micromodalProps.children}
            </MicroModal>
          )}
        </HelpContainer>
      )}
    </LabelContainer>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  tooltipProps: PropTypes.shape(Tooltip.propTypes),
  micromodalProps: PropTypes.shape(MicroModal.propTypes),
  _validateHelpProps: (props, propName, componentName) => {
    if (props.tooltipProps && props.micromodalProps) {
      return new Error(
        `Invalid props combo ${componentName}: tooltipProps and micromodalProps: Only one of these expected`,
      );
    }
    return undefined;
  },
};

export default Label;
