import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

const Down = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor: _fillColor = null,
  title = null,
  size = 'large',
  onClick = () => false,
}) => {
  const theme = useTheme();

  const fillColor = useMemo(
    () => _fillColor || theme.BaseColors.Neutral['100'],
    [_fillColor, theme],
  );

  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Down'}
          id={domID}
          data-testid={dataTestId}
          onClick={onClick}
        >
          <path
            // eslint-disable-next-line max-len
            d="M12.3603 17.6256C12.1636 17.83 11.8364 17.83 11.6397 17.6256L4.15447 9.84669C3.84881 9.52903 4.07393 9 4.51476 9L19.4852 9C19.9261 9 20.1512 9.52904 19.8455 9.84669L12.3603 17.6256Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Down'}
          id={domID}
          data-testid={dataTestId}
          onClick={onClick}
        >
          <path
            // eslint-disable-next-line max-len
            d="M8.36727 11.6024C8.16931 11.8167 7.83068 11.8167 7.63272 11.6024L2.84708 6.33926C2.55126 6.01902 2.77839 5.49999 3.21436 5.49999L12.7856 5.49999C13.2216 5.49999 13.4487 6.01902 13.1529 6.33926L8.36727 11.6024Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Down'}
          id={domID}
          data-testid={dataTestId}
          onClick={onClick}
        >
          <path
            // eslint-disable-next-line max-len
            d="M6.20933 8.90327C6.10201 9.03224 5.89799 9.03224 5.79067 8.90327L2.05925 4.41875C1.91883 4.24999 2.0438 4 2.26858 4L9.73142 4C9.9562 4 10.0812 4.24999 9.94075 4.41875L6.20933 8.90327Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Down.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default Down;
