import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  ${TextStyles.small};
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: 36px;
  height: ${(props) => (props.isTextWrapping ? 'auto' : '36px')};
  overflow: visible;
  text-overflow: ${(props) => (props.isTextWrapping ? 'ellipsis' : 'hidden')};
  ${(props) => (props.isTextWrapping ? 'line-height: 1.45em;' : null)};
  ${(props) => (props.isEditing ? `cursor: grab` : '')};
  min-height: 36px;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.BaseColors.Neutral['10']};
  }

  &:active,
  &.pressing {
    background: ${({ theme }) => theme.BaseColors.Neutral['15']};
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.selected {
    background: ${({ selectedBackground }) => selectedBackground};
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
    box-shadow: inset 2px 0 0 0 ${({ borderLeft }) => borderLeft};

    &:focus-visible {
      box-shadow: inset 2px 0 0 0 ${({ borderLeft }) => borderLeft},
        inset 0 0 0 1px
          ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    }
  }
`;
