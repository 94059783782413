import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Collapse = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Collapse'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M21.5 10C21.7761 10 22 9.77614 22 9.5C22 9.22386 21.7761 9 21.5 9H17.5C16.6716 9 16 8.32843 16 7.5V3.5C16 3.22386 15.7761 3 15.5 3C15.2239 3 15 3.22386 15 3.5V7.5C15 8.88071 16.1193 10 17.5 10H21.5Z"
            fill={fillColor}
          />
          <path
            // eslint-disable-next-line max-len
            d="M9 7.50003C9 8.32846 8.32843 9.00003 7.5 9.00003L3.5 9.00003C3.22386 9.00003 3 9.22389 3 9.50003C3 9.77618 3.22386 10 3.5 10L7.5 10C8.88071 10 10 8.88074 10 7.50003L10 3.50003C10 3.22389 9.77614 3.00003 9.5 3.00003C9.22386 3.00003 9 3.22389 9 3.50003L9 7.50003Z"
            fill={fillColor}
          />
          <path
            // eslint-disable-next-line max-len
            d="M15.5 22C15.2239 22 15 21.7762 15 21.5V17.5C15 16.1193 16.1193 15 17.5 15H21.5C21.7761 15 22 15.2239 22 15.5C22 15.7762 21.7761 16 21.5 16H17.5C16.6716 16 16 16.6716 16 17.5V21.5C16 21.7762 15.7761 22 15.5 22Z"
            fill={fillColor}
          />
          <path
            // eslint-disable-next-line max-len
            d="M9 21.5C9 21.7762 9.22386 22 9.5 22C9.77614 22 10 21.7762 10 21.5V17.5C10 16.1193 8.88071 15 7.5 15H3.5C3.22386 15 3 15.2239 3 15.5C3 15.7762 3.22386 16 3.5 16H7.5C8.32843 16 9 16.6716 9 17.5V21.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Collapse'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.5 2C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2H2.5ZM1 2.5C1 1.67157 1.67157 1 2.5 1H13.5C14.3284 1 15 1.67157 15 2.5V13.5C15 14.3284 14.3284 15 13.5 15H2.5C1.67157 15 1 14.3284 1 13.5V2.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Collapse'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 2H2V10H10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Collapse.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Collapse;
