// adapted from https://usehooks.com/useOnScreen
import { useEffect } from 'react';
import useStateIfMounted from './useStateIfMounted';

export const useIntersectionObserver = ({
  ref = {},
  rootMargin = '0px',
  threshold = 1,
  root = null,
  effectDependencies = [],
} = {}) => {
  const [currentEntry, setCurrentEntry] = useStateIfMounted({});
  const [viewportEdgesCrossed, setViewportEdgesCrossed] = useStateIfMounted({});

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window.IntersectionObserver !== undefined) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const { boundingClientRect = {}, rootBounds = {} } = entry;

          setCurrentEntry(entry);
          setViewportEdgesCrossed({
            top: boundingClientRect?.top <= rootBounds?.top,
            bottom: boundingClientRect?.bottom >= rootBounds?.bottom,
            left: boundingClientRect?.left <= rootBounds?.left,
            right: boundingClientRect?.right >= rootBounds?.right,
          });
        },
        {
          root,
          rootMargin,
          threshold,
        },
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current && observer.unobserve(ref.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, effectDependencies);

  return {
    currentEntry,
    viewportEdgesCrossed,
  };
};

// TODO: Once we know the ui-core version number this will go out in, we should note as of when the polyfill is included.
export const intersectionObserverNotes = `This uses the IntersectionObserver API, which does not
 exist in Internet Explorer 11. If you want this feature to work in Internet Explorer 11, you will
 need to use a [polyfill](https://www.npmjs.com/package/intersection-observer). This polyfill is
 now included in \`cra-boilerplate\` by default.`;

export default useIntersectionObserver;
