import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const PriorityHigh = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Priority High'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.35355 1.64645C8.15829 1.45118 7.84171 1.45118 7.64645 1.64645L1.64645 7.64645C1.45118 7.84171 1.45118 8.15829 1.64645 8.35355C1.84171 8.54882 2.15829 8.54882 2.35355 8.35355L8 2.70711L13.6464 8.35355C13.8417 8.54882 14.1583 8.54882 14.3536 8.35355C14.5488 8.15829 14.5488 7.84171 14.3536 7.64645L8.35355 1.64645ZM14.3536 13.6464L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645L1.64645 13.6464C1.45118 13.8417 1.45118 14.1583 1.64645 14.3536C1.84171 14.5488 2.15829 14.5488 2.35355 14.3536L8 8.70711L13.6464 14.3536C13.8417 14.5488 14.1583 14.5488 14.3536 14.3536C14.5488 14.1583 14.5488 13.8417 14.3536 13.6464Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Priority High'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.35355 1.14645C6.15829 0.951184 5.84171 0.951184 5.64645 1.14645L1.14645 5.64645C0.951184 5.84171 0.951184 6.15829 1.14645 6.35355C1.34171 6.54882 1.65829 6.54882 1.85355 6.35355L6 2.20711L10.1464 6.35355C10.3417 6.54882 10.6583 6.54882 10.8536 6.35355C11.0488 6.15829 11.0488 5.84171 10.8536 5.64645L6.35355 1.14645ZM10.8536 10.1464L6.35355 5.64645C6.15829 5.45118 5.84171 5.45118 5.64645 5.64645L1.14645 10.1464C0.951184 10.3417 0.951184 10.6583 1.14645 10.8536C1.34171 11.0488 1.65829 11.0488 1.85355 10.8536L6 6.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

PriorityHigh.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default PriorityHigh;
