import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import MenuToggle from '../MenuToggle';
import StandardMenu from '../../../../Molecules/StandardMenu';
import MenuWrapper from './MenuWrapper';
import { DockLeft } from '../../../../BrandCore/icons/interactive';
import MenuFooter from '../../../../Atoms/MenuFooter';

export const Menu = ({
  menuLabel = null,
  onOpen = () => false,
  onClose = () => false,
  onOpenClose = () => false,
  onMenuItemClick = () => false,
  menuItems = [],
  sections = [],
  selectedMenuItemIds = [],
  initialIsMenuOpen = false,
  isExpandable = false,
  onExpand = () => false,
  customTheme = {},
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(initialIsMenuOpen);

  const menuRef = useRef(null);

  useEffect(() => {
    if (initialIsMenuOpen) setIsMenuOpen(initialIsMenuOpen);
  }, [initialIsMenuOpen]);

  const toggleMenu = (event, forceState) => {
    const menuWasOpen = isMenuOpen;
    const menuIsNowOpen = forceState !== undefined ? forceState : !isMenuOpen;

    setIsMenuOpen(menuIsNowOpen);

    if (menuIsNowOpen) {
      onOpen(event, menuIsNowOpen);
      onOpenClose(event, menuIsNowOpen);
    } else if (!menuIsNowOpen && menuWasOpen) {
      onClose(event, menuIsNowOpen);
      onOpenClose(event, menuIsNowOpen);
    }
  };

  const handleExpandClick = (e) => {
    toggleMenu(e, false);
    onExpand(e);
  };

  const onClickOut = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      toggleMenu(event, false);
    }
  };

  /**
   * fires appropriate action depending on which item is clicked and if CIAM integration is complete
   * @param {NativeEvent} event
   * @param {object} dropdownState contains activeItem, activeMenuItemIndex, and activeSectionIndex
   */
  const handleMenuItemClick = (event, dropdownState) => {
    const updatedState = {
      ...dropdownState,
      isMenuOpen: false,
    };

    toggleMenu(event, false);
    onMenuItemClick(event, updatedState);
  };

  return (
    <MenuWrapper
      customTheme={customTheme}
      role="navigation"
      aria-label={menuLabel}
      ref={menuRef}
    >
      <MenuToggle
        customTheme={customTheme}
        dataTestId="nav-menu-toggle-button"
        label={menuLabel}
        isMenuOpen={isMenuOpen}
        maxWidth="15rem"
        domID="mast-head-menu-toggle"
        onClick={(e) => {
          toggleMenu(e);
        }}
      />
      {isMenuOpen ? (
        <StandardMenu
          dataTestId="nav-menu-dropdown"
          menuItems={menuItems}
          sections={sections}
          onMenuClick={(e, dropdownState) => {
            handleMenuItemClick(e, dropdownState);
          }}
          onClickOut={(e) => onClickOut(e)}
          className="inline-menu-dropdown"
          sectionTitleClass="inline-menu-dropdown-header"
          selectedItems={selectedMenuItemIds}
          maxWidth="280px"
          showActiveIndicator
          footerComponent={
            isExpandable ? (
              <MenuFooter
                className="menu-expand-footer"
                dataTestId="nav-menu-dropdown-Expand"
                label="Expand"
                onClick={(e) => handleExpandClick(e)}
                icon={DockLeft}
              />
            ) : null
          }
        />
      ) : null}
    </MenuWrapper>
  );
};

Menu.propTypes = {
  menuLabel: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpenClose: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      icon: PropTypes.func,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          icon: PropTypes.func,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
        }),
      ),
      PropTypes.shape({
        title: PropTypes.string,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            /** should be a ui-core icon component */
            icon: PropTypes.func,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
          }),
        ),
      }),
    ]),
  ),

  selectedMenuItemIds: PropTypes.arrayOf(PropTypes.any),
  initialIsMenuOpen: PropTypes.bool,
  isExpandable: PropTypes.bool,
  onExpand: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
};

export default Menu;
