import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  &.checked {
    fill: ${(props) => props.theme.BaseColors.White};

    &.disabled {
      fill: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
    }
  }
`;

export default (props) => (
  <Icon
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.86217 0.146447C8.04594 0.341709 8.04594 0.658291 7.86217 0.853553L3.15629 6.85355C2.97251 7.04882 2.67455 7.04882 2.49077 6.85355L0.137832 4.35355C-0.045944 4.15829 -0.045944 3.84171 0.137832 3.64645C0.321608 3.45118 0.619568 3.45118 0.803345 3.64645L2.82353 5.79289L7.19665 0.146447C7.38043 -0.0488155 7.67839 -0.0488155 7.86217 0.146447Z"
    />
  </Icon>
);
