import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const SearchFilled = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Search Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.5589 18 14.424 17.1704 15.7793 15.8271C15.7867 15.8187 15.7944 15.8105 15.8025 15.8025C15.8105 15.7944 15.8187 15.7867 15.8271 15.7793C17.1704 14.424 18 12.5589 18 10.5C18 6.35786 14.6421 3 10.5 3ZM10.5 19C12.6673 19 14.6451 18.1889 16.1465 16.8536L22.1465 22.8536C22.3417 23.0488 22.6583 23.0488 22.8536 22.8536C23.0488 22.6583 23.0488 22.3417 22.8536 22.1465L16.8536 16.1465C18.1889 14.6451 19 12.6673 19 10.5C19 5.80558 15.1944 2 10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19ZM10.5 16C13.5376 16 16 13.5376 16 10.5C16 7.46243 13.5376 5 10.5 5C7.46243 5 5 7.46243 5 10.5C5 13.5376 7.46243 16 10.5 16Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Search Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13C9.00835 13 10.3749 12.3928 11.3685 11.4096C11.3749 11.4024 11.3816 11.3953 11.3884 11.3885C11.3953 11.3816 11.4024 11.3749 11.4095 11.3685C12.3928 10.3749 13 9.00835 13 7.5C13 4.46243 10.5376 2 7.5 2ZM7.5 14C9.11495 14 10.5923 13.411 11.7291 12.4362L15.1464 15.8536C15.3417 16.0488 15.6583 16.0488 15.8536 15.8536C16.0488 15.6583 16.0488 15.3417 15.8536 15.1465L12.4362 11.7291C13.411 10.5923 14 9.11495 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14ZM7.5 12C9.98528 12 12 9.98528 12 7.5C12 5.01472 9.98528 3 7.5 3C5.01472 3 3 5.01472 3 7.5C3 9.98528 5.01472 12 7.5 12Z"
            fill={fillColor}
          />
        </svg>
      );

    default:
      return <div>invalid size property</div>;
  }
};

SearchFilled.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default SearchFilled;
