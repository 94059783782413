import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

const NotificationBody = styled.div`
  margin-top: 4px;
  margin-left: 30px;

  .description {
    ${TextStyles.small};
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export default NotificationBody;
