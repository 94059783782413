import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const List = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'List'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 4.5C2 3.67157 2.67157 3 3.5 3C4.32843 3 5 3.67157 5 4.5C5 5.32843 4.32843 6 3.5 6C2.67157 6 2 5.32843 2 4.5ZM3.5 2C2.11929 2 1 3.11929 1 4.5C1 5.88071 2.11929 7 3.5 7C4.88071 7 6 5.88071 6 4.5C6 3.11929 4.88071 2 3.5 2ZM2 12.5C2 11.6716 2.67157 11 3.5 11C4.32843 11 5 11.6716 5 12.5C5 13.3284 4.32843 14 3.5 14C2.67157 14 2 13.3284 2 12.5ZM3.5 10C2.11929 10 1 11.1193 1 12.5C1 13.8807 2.11929 15 3.5 15C4.88071 15 6 13.8807 6 12.5C6 11.1193 4.88071 10 3.5 10ZM3.5 19C2.67157 19 2 19.6716 2 20.5C2 21.3284 2.67157 22 3.5 22C4.32843 22 5 21.3284 5 20.5C5 19.6716 4.32843 19 3.5 19ZM1 20.5C1 19.1193 2.11929 18 3.5 18C4.88071 18 6 19.1193 6 20.5C6 21.8807 4.88071 23 3.5 23C2.11929 23 1 21.8807 1 20.5ZM9.5 4H9V5H9.5H23.5H24V4H23.5H9.5ZM9 12H9.5H23.5H24V13H23.5H9.5H9V12ZM9.5 20H9V21H9.5H23.5H24V20H23.5H9.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'List'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 3C1 2.44772 1.44772 2 2 2C2.55228 2 3 2.44772 3 3C3 3.55228 2.55228 4 2 4C1.44772 4 1 3.55228 1 3ZM2 1C0.89543 1 0 1.89543 0 3C0 4.10457 0.89543 5 2 5C3.10457 5 4 4.10457 4 3C4 1.89543 3.10457 1 2 1ZM6.5 3C6.22386 3 6 3.22386 6 3.5C6 3.77614 6.22386 4 6.5 4H15.5C15.7761 4 16 3.77614 16 3.5C16 3.22386 15.7761 3 15.5 3H6.5ZM6.5 8C6.22386 8 6 8.22386 6 8.5C6 8.77614 6.22386 9 6.5 9H15.5C15.7761 9 16 8.77614 16 8.5C16 8.22386 15.7761 8 15.5 8H6.5ZM6 13.5C6 13.2239 6.22386 13 6.5 13H15.5C15.7761 13 16 13.2239 16 13.5C16 13.7761 15.7761 14 15.5 14H6.5C6.22386 14 6 13.7761 6 13.5ZM2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7ZM0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8ZM1 13C1 12.4477 1.44772 12 2 12C2.55228 12 3 12.4477 3 13C3 13.5523 2.55228 14 2 14C1.44772 14 1 13.5523 1 13ZM2 11C0.89543 11 0 11.8954 0 13C0 14.1046 0.89543 15 2 15C3.10457 15 4 14.1046 4 13C4 11.8954 3.10457 11 2 11Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'List'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 2C1.22386 2 1 2.22386 1 2.5C1 2.77614 1.22386 3 1.5 3C1.77614 3 2 2.77614 2 2.5C2 2.22386 1.77614 2 1.5 2ZM0 2.5C0 1.67157 0.671573 1 1.5 1C2.32843 1 3 1.67157 3 2.5C3 3.32843 2.32843 4 1.5 4C0.671573 4 0 3.32843 0 2.5ZM4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3H11.5C11.7761 3 12 2.77614 12 2.5C12 2.22386 11.7761 2 11.5 2H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H11.5C11.7761 11 12 10.7761 12 10.5C12 10.2239 11.7761 10 11.5 10H4.5ZM1 6.5C1 6.22386 1.22386 6 1.5 6C1.77614 6 2 6.22386 2 6.5C2 6.77614 1.77614 7 1.5 7C1.22386 7 1 6.77614 1 6.5ZM1.5 5C0.671573 5 0 5.67157 0 6.5C0 7.32843 0.671573 8 1.5 8C2.32843 8 3 7.32843 3 6.5C3 5.67157 2.32843 5 1.5 5ZM1.5 10C1.22386 10 1 10.2239 1 10.5C1 10.7761 1.22386 11 1.5 11C1.77614 11 2 10.7761 2 10.5C2 10.2239 1.77614 10 1.5 10ZM0 10.5C0 9.67157 0.671573 9 1.5 9C2.32843 9 3 9.67157 3 10.5C3 11.3284 2.32843 12 1.5 12C0.671573 12 0 11.3284 0 10.5ZM4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H11.5C11.7761 7 12 6.77614 12 6.5C12 6.22386 11.7761 6 11.5 6H4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

List.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default List;
