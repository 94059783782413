const getElapsedTime = (dateTime) => {
  let dateTimeObject;

  if (typeof dateTime === 'number') {
    dateTimeObject = new Date(dateTime);
  }

  if (typeof dateTime === 'string') {
    dateTimeObject = new Date(dateTime);
  }

  if (dateTime instanceof Date) {
    dateTimeObject = dateTime;
  }

  if (dateTimeObject instanceof Date && !isNaN(dateTimeObject)) {
    const diff = Math.abs(new Date(Date.now()) - dateTimeObject);
    const diffMinutes = Math.floor(diff / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMore = Math.floor(diffDays / 30);

    if (diffMore) {
      return dateTimeObject.toLocaleDateString();
    }

    if (diffDays) {
      return `${diffDays}d ago`;
    }

    if (diffHours) {
      return `${diffHours}h ago`;
    }

    if (diffMinutes) {
      return `${diffMinutes}m ago`;
    }

    return `Just Now`;
  }

  return undefined;
};

export default getElapsedTime;
