import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Home = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Home'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 1.35394L23.3166 10.613L22.6834 11.387L12 2.646L1.31665 11.387L0.683411 10.613L12 1.35394ZM9.5 16.5H14.5V22.5H19.5V12.5H20.5V23.5H3.5V12.5H4.5V22.5H9.5V16.5ZM10.5 22.5H13.5V17.5H10.5V22.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Home'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.34116 0.134472C8.14906 -0.0448241 7.85095 -0.0448241 7.65884 0.134472L0.158843 7.13447C-0.0430326 7.32289 -0.0539428 7.63928 0.134474 7.84116C0.322891 8.04303 0.639286 8.05394 0.841161 7.86553L8 1.18394L15.1588 7.86553C15.3607 8.05394 15.6771 8.04303 15.8655 7.84116C16.0539 7.63928 16.043 7.32289 15.8412 7.13447L8.34116 0.134472ZM3 8.5C3 8.22386 2.77614 8 2.5 8C2.22386 8 2 8.22386 2 8.5V15.5C2 15.7761 2.22386 16 2.5 16H6.5C6.77614 16 7 15.7761 7 15.5V12H9V15.5C9 15.7761 9.22386 16 9.5 16H13.5C13.7761 16 14 15.7761 14 15.5V8.5C14 8.22386 13.7761 8 13.5 8C13.2239 8 13 8.22386 13 8.5V15H10V11.5C10 11.2239 9.77614 11 9.5 11H6.5C6.22386 11 6 11.2239 6 11.5V15H3V8.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Home'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.70592 0.095632C5.88124 -0.0318773 6.11877 -0.0318773 6.29409 0.095632L11.7941 4.09563C12.0174 4.25805 12.0668 4.57076 11.9044 4.79409C11.742 5.01741 11.4292 5.06679 11.2059 4.90437L6 1.11825L0.794091 4.90437C0.570764 5.06679 0.258056 5.01741 0.0956367 4.79409C-0.0667824 4.57076 -0.0174074 4.25805 0.205919 4.09563L5.70592 0.095632ZM1.5 6C1.77614 6 2 6.22386 2 6.5V11H5V8H7V11H10V6.5C10 6.22386 10.2239 6 10.5 6C10.7761 6 11 6.22386 11 6.5V11.5C11 11.7761 10.7761 12 10.5 12H1.5C1.22386 12 1 11.7761 1 11.5V6.5C1 6.22386 1.22386 6 1.5 6Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Home.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Home;
