import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Boxes = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Boxes'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1V10C0.5 10.2761 0.723858 10.5 1 10.5H10C10.2761 10.5 10.5 10.2761 10.5 10V1C10.5 0.723858 10.2761 0.5 10 0.5H1ZM1.5 9.5V1.5H9.5V9.5H1.5ZM23 10.5C23.2761 10.5 23.5 10.2761 23.5 10V1C23.5 0.723858 23.2761 0.5 23 0.5H14C13.7239 0.5 13.5 0.723858 13.5 1V10C13.5 10.2761 13.7239 10.5 14 10.5H23ZM22.5 1.5V9.5H14.5V1.5H22.5ZM0.5 14C0.5 13.7239 0.723858 13.5 1 13.5H10C10.2761 13.5 10.5 13.7239 10.5 14V23C10.5 23.2761 10.2761 23.5 10 23.5H1C0.723858 23.5 0.5 23.2761 0.5 23V14ZM1.5 14.5V22.5H9.5V14.5H1.5ZM23 23.5C23.2761 23.5 23.5 23.2761 23.5 23V14C23.5 13.7239 23.2761 13.5 23 13.5H14C13.7239 13.5 13.5 13.7239 13.5 14V23C13.5 23.2761 13.7239 23.5 14 23.5H23ZM22.5 14.5V22.5H14.5V14.5H22.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Boxes'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 1.5C1 1.22386 1.22386 1 1.5 1H5.5C5.77614 1 6 1.22386 6 1.5V5.5C6 5.77614 5.77614 6 5.5 6H1.5C1.22386 6 1 5.77614 1 5.5V1.5ZM1.5 0C0.671573 0 0 0.671573 0 1.5V5.5C0 6.32843 0.671573 7 1.5 7H5.5C6.32843 7 7 6.32843 7 5.5V1.5C7 0.671573 6.32843 0 5.5 0H1.5ZM10 1.5C10 1.22386 10.2239 1 10.5 1H14.5C14.7761 1 15 1.22386 15 1.5V5.5C15 5.77614 14.7761 6 14.5 6H10.5C10.2239 6 10 5.77614 10 5.5V1.5ZM10.5 0C9.67157 0 9 0.671573 9 1.5V5.5C9 6.32843 9.67157 7 10.5 7H14.5C15.3284 7 16 6.32843 16 5.5V1.5C16 0.671573 15.3284 0 14.5 0H10.5ZM1.5 10C1.22386 10 1 10.2239 1 10.5V14.5C1 14.7761 1.22386 15 1.5 15H5.5C5.77614 15 6 14.7761 6 14.5V10.5C6 10.2239 5.77614 10 5.5 10H1.5ZM0 10.5C0 9.67157 0.671573 9 1.5 9H5.5C6.32843 9 7 9.67157 7 10.5V14.5C7 15.3284 6.32843 16 5.5 16H1.5C0.671573 16 0 15.3284 0 14.5V10.5ZM10 10.5C10 10.2239 10.2239 10 10.5 10H14.5C14.7761 10 15 10.2239 15 10.5V14.5C15 14.7761 14.7761 15 14.5 15H10.5C10.2239 15 10 14.7761 10 14.5V10.5ZM10.5 9C9.67157 9 9 9.67157 9 10.5V14.5C9 15.3284 9.67157 16 10.5 16H14.5C15.3284 16 16 15.3284 16 14.5V10.5C16 9.67157 15.3284 9 14.5 9H10.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Boxes'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 0C0.223858 0 0 0.223858 0 0.5V4.5C0 4.77614 0.223858 5 0.5 5H4.5C4.77614 5 5 4.77614 5 4.5V0.5C5 0.223858 4.77614 0 4.5 0H0.5ZM1 4V1H4V4H1ZM7.5 0C7.22386 0 7 0.223858 7 0.5V4.5C7 4.77614 7.22386 5 7.5 5H11.5C11.7761 5 12 4.77614 12 4.5V0.5C12 0.223858 11.7761 0 11.5 0H7.5ZM8 4V1H11V4H8ZM0 7.5C0 7.22386 0.223858 7 0.5 7H4.5C4.77614 7 5 7.22386 5 7.5V11.5C5 11.7761 4.77614 12 4.5 12H0.5C0.223858 12 0 11.7761 0 11.5V7.5ZM1 8V11H4V8H1ZM7.5 7C7.22386 7 7 7.22386 7 7.5V11.5C7 11.7761 7.22386 12 7.5 12H11.5C11.7761 12 12 11.7761 12 11.5V7.5C12 7.22386 11.7761 7 11.5 7H7.5ZM8 11V8H11V11H8Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Boxes.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Boxes;
