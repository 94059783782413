import React from 'react';
import PropTypes from 'prop-types';

import GroupTitle from '../../../Atoms/GroupTitle';
import { ArrowWrapper, GroupTitleButton } from './StyledComponents';
import IconButton from '../../../Atoms/IconButton';
import { Caret, CaretRight } from '../../../BrandCore/icons/interactive';

export const TitleButton = ({
  domID = '',
  className = '',
  dataTestId = '',
  title = '',
  onClick = () => false,
  isCollapsed = false,
  parentID = '',
  size = 'small',
}) => {
  const domIDs = {
    title: domID ? `${domID}-title` : null,
  };
  const buttonTestID = dataTestId ? `${dataTestId}-button` : null;
  const arrowTestID = dataTestId ? `${dataTestId}-arrow` : null;

  const onKeyPressHandler = (e) => {
    e.preventDefault(); // prevents default scrolling of page
    if (e.target.nodeName === 'DIV' && [13, 32].includes(e.which)) {
      onClick(e);
    }
  };

  return (
    <GroupTitleButton
      tabIndex={0}
      id={domID}
      data-testid={buttonTestID}
      className={className}
      onClick={onClick}
      isCollapsed={isCollapsed}
      onKeyPress={onKeyPressHandler}
      aria-controls={parentID}
      role="menuitem"
    >
      <ArrowWrapper>
        <IconButton
          icon={isCollapsed ? CaretRight : Caret}
          dataTestId={arrowTestID}
          domID={`${domID}-arrow`}
          size={size}
          style={{ padding: 0 }}
        />
      </ArrowWrapper>
      <GroupTitle domID={domIDs.title} dataTestId={dataTestId} title={title} />
    </GroupTitleButton>
  );
};

TitleButton.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** The displayed text/title */
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  /** size of icon to use */
  size: PropTypes.oneOf(['small', 'medium']),
  isCollapsed: PropTypes.bool,
  /** domID of `Group` component, used for `aria-controls` prop in toggleButton */
  parentID: PropTypes.string,
};

export default TitleButton;
