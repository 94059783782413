import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const SquareDisabled = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Disabled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 4.5C2 3.11929 3.11929 2 4.5 2H20.5C21.8807 2 23 3.11929 23 4.5V20.5C23 21.8807 21.8807 23 20.5 23H4.5C3.11929 23 2 21.8807 2 20.5V4.5ZM4.5 3C3.67157 3 3 3.67157 3 4.5V20.5C3 21.3284 3.67157 22 4.5 22H20.5C21.3284 22 22 21.3284 22 20.5V4.5C22 3.67157 21.3284 3 20.5 3H4.5ZM16.8536 8.14645C17.0488 8.34171 17.0488 8.65829 16.8536 8.85355L13.2071 12.5L16.8536 16.1464C17.0488 16.3417 17.0488 16.6583 16.8536 16.8536C16.6583 17.0488 16.3417 17.0488 16.1464 16.8536L12.5 13.2071L8.85355 16.8536C8.65829 17.0488 8.34171 17.0488 8.14645 16.8536C7.95118 16.6583 7.95118 16.3417 8.14645 16.1464L11.7929 12.5L8.14645 8.85355C7.95118 8.65829 7.95118 8.34171 8.14645 8.14645C8.34171 7.95118 8.65829 7.95118 8.85355 8.14645L12.5 11.7929L16.1464 8.14645C16.3417 7.95118 16.6583 7.95118 16.8536 8.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Disabled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 2.5C2 2.22414 2.22414 2 2.5 2H14.5C14.7759 2 15 2.22414 15 2.5V14.5C15 14.7759 14.7759 15 14.5 15H2.5C2.22414 15 2 14.7759 2 14.5V2.5ZM2.5 1C1.67186 1 1 1.67186 1 2.5V14.5C1 15.3281 1.67186 16 2.5 16H14.5C15.3281 16 16 15.3281 16 14.5V2.5C16 1.67186 15.3281 1 14.5 1H2.5ZM11.4145 5.67147C11.6098 5.86673 11.6098 6.18332 11.4145 6.37858L9.2931 8.50002L11.4145 10.6215C11.6098 10.8167 11.6098 11.1333 11.4145 11.3286C11.2193 11.5238 10.9027 11.5238 10.7074 11.3286L8.58599 9.20713L6.46455 11.3286C6.26928 11.5238 5.9527 11.5238 5.75744 11.3286C5.56218 11.1333 5.56218 10.8167 5.75744 10.6215L7.87889 8.50002L5.75744 6.37858C5.56218 6.18332 5.56218 5.86673 5.75744 5.67147C5.9527 5.47621 6.26928 5.47621 6.46455 5.67147L8.58599 7.79292L10.7074 5.67147C10.9027 5.47621 11.2193 5.47621 11.4145 5.67147Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Disabled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 1C1.22386 1 1 1.22386 1 1.5V10.5C1 10.7761 1.22386 11 1.5 11H10.5C10.7761 11 11 10.7761 11 10.5V1.5C11 1.22386 10.7761 1 10.5 1H1.5ZM0 1.5C0 0.671573 0.671573 0 1.5 0H10.5C11.3284 0 12 0.671573 12 1.5V10.5C12 11.3284 11.3284 12 10.5 12H1.5C0.671573 12 0 11.3284 0 10.5V1.5ZM8.47455 3.52548C8.66981 3.72074 8.66981 4.03732 8.47455 4.23258L6.70711 6.00003L8.47455 7.76748C8.66981 7.96274 8.66981 8.27932 8.47455 8.47458C8.27929 8.66984 7.96271 8.66984 7.76745 8.47458L6 6.70714L4.23255 8.47458C4.03729 8.66984 3.72071 8.66984 3.52544 8.47458C3.33018 8.27932 3.33018 7.96274 3.52544 7.76748L5.29289 6.00003L3.52544 4.23258C3.33018 4.03732 3.33018 3.72074 3.52544 3.52548C3.72071 3.33021 4.03729 3.33021 4.23255 3.52548L6 5.29292L7.76745 3.52548C7.96271 3.33021 8.27929 3.33021 8.47455 3.52548Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

SquareDisabled.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default SquareDisabled;
