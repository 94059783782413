import styled from 'styled-components';

export default styled.section`
  .header {
    margin: 0 48px 6px 70px;
    margin-top: 16px;

    .group-header-chip {
      margin-left: 12px;
    }

    .header-children {
      margin-left: 12px;
    }
  }
`;
