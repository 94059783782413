import styled from 'styled-components';

import TextStyles from '../../BrandCore/Text/styles';
import Borders from '../../BrandCore/shapes/borders';

const GridColumnHeaderWrapperDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const GridColumnHeaderWrapper = styled.div`
  position: relative;
  .tooltip-wrapper {
    &:focus-visible:not(:active) {
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      ${(props) => props.theme.UIPalette.BoxShadows.General.LineBelow}
      outline: none;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    > svg {
      vertical-align: middle;
      margin: 0 10px;
      min-width: 12px;
      min-height: 12px;
    }
  }

  &.align-left {
    justify-content: flex-start;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    justify-content: flex-end;
  }

  ${TextStyles.xSmall};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  border: none;
  background-color: transparent;
  display: flex;
  ${(props) => !props.withToolTip && 'align-items: center;'};
  padding: ${(props) => (props.withToolTip ? '0' : '0 8px')};
  min-height: 35px;
  width: 100%;
  ${Borders.borderRadius4};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &.sorted {
    ${TextStyles.xSmallBold};
  }

  &.sortable {
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
    }
    &:active {
      background-color: ${(props) => props.theme.BaseColors.Neutral['0']};
      outline: none;
    }
  }

  .grid-column-header-text {
    width: ${(props) =>
      props.headerWidth ? `${props.headerWidth}px` : '100%'};
    &.align-left {
      text-align: left;
    }
    &.align-center {
      text-align: center;
    }
    &.align-right {
      text-align: right;
    }
  }

  .spacer {
    width: 33px;
    visibility: hidden;
  }

  &:focus-visible:not(:active) {
    outline: none;
    ${(props) => props.theme.UIPalette.BoxShadows.General.LineBelow}
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  > svg {
    vertical-align: middle;
    margin: 0 10px;
  }
  .grid-column-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const GridColumnHeaderChildWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export default GridColumnHeaderWrapper;

export { GridColumnHeaderChildWrapper, GridColumnHeaderWrapperDiv };
