import styled from 'styled-components';
import borders from '../../../BrandCore/shapes/borders';

/**
 * TODO this should extend FormFooter to avaoid duplication.
 */
// stylelint-disable property-no-vendor-prefix
const ModalFooter = styled.div`
  grid-area: footer;
  -ms-grid-row: 3;
  padding: 26px 48px;
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.showLine &&
    `border: 1px solid ${({ theme }) => theme.BaseColors.Neutral['10']};
    ${borders.onlyTop}`};
  border-left: none;
  border-bottom: none;
`;

export default ModalFooter;
