import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonGroup from '../ButtonGroup';

const FormFooterContainer = styled(ButtonGroup)`
  width: 100%;
  height: auto;
  border-top: 1px solid ${({ theme }) => theme.BaseColors.Neutral['15']};
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FormFooter = ({
  children = null,
  style = {},
  dataTestId = null,
}) => {
  return (
    <FormFooterContainer data-testid={dataTestId} style={style}>
      {children}
    </FormFooterContainer>
  );
};

FormFooter.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default FormFooter;
