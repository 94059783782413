import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Transfer = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Transfer'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.85355 4.14645C7.04882 4.34171 7.04882 4.65829 6.85355 4.85355L3.70711 8H20.5C20.7761 8 21 8.22386 21 8.5C21 8.77614 20.7761 9 20.5 9H3.70711L6.85355 12.1464C7.04882 12.3417 7.04882 12.6583 6.85355 12.8536C6.65829 13.0488 6.34171 13.0488 6.14645 12.8536L2.14645 8.85355C1.95118 8.65829 1.95118 8.34171 2.14645 8.14645L6.14645 4.14645C6.34171 3.95118 6.65829 3.95118 6.85355 4.14645ZM17.1464 12.1464C17.3417 11.9512 17.6583 11.9512 17.8536 12.1464L21.8536 16.1464C22.0488 16.3417 22.0488 16.6583 21.8536 16.8536L17.8536 20.8536C17.6583 21.0488 17.3417 21.0488 17.1464 20.8536C16.9512 20.6583 16.9512 20.3417 17.1464 20.1464L20.2929 17H3.5C3.22386 17 3 16.7761 3 16.5C3 16.2239 3.22386 16 3.5 16H20.2929L17.1464 12.8536C16.9512 12.6583 16.9512 12.3417 17.1464 12.1464Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Transfer'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.1464 0.146447C11.3417 -0.0488155 11.6583 -0.0488155 11.8536 0.146447L15.8536 4.14645C16.0488 4.34171 16.0488 4.65829 15.8536 4.85355L11.8536 8.85355C11.6583 9.04882 11.3417 9.04882 11.1464 8.85355C10.9512 8.65829 10.9512 8.34171 11.1464 8.14645L14.2929 5H0.5C0.223858 5 3.72529e-09 4.77614 3.72529e-09 4.5C3.72529e-09 4.22386 0.223858 4 0.5 4H14.2929L11.1464 0.853553C10.9512 0.658291 10.9512 0.341709 11.1464 0.146447ZM4.85355 7.14645C5.04882 7.34171 5.04882 7.65829 4.85355 7.85355L1.70711 11H15.5C15.7761 11 16 11.2239 16 11.5C16 11.7761 15.7761 12 15.5 12H1.70711L4.85355 15.1464C5.04882 15.3417 5.04882 15.6583 4.85355 15.8536C4.65829 16.0488 4.34171 16.0488 4.14645 15.8536L0.146447 11.8536C-0.0488155 11.6583 -0.0488155 11.3417 0.146447 11.1464L4.14645 7.14645C4.34171 6.95118 4.65829 6.95118 4.85355 7.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Transfer'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.35355 0.646447C3.54882 0.841709 3.54882 1.15829 3.35355 1.35355L1.70711 3H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H1.70711L3.35355 5.64645C3.54882 5.84171 3.54882 6.15829 3.35355 6.35355C3.15829 6.54882 2.84171 6.54882 2.64645 6.35355L0.146447 3.85355C-0.0488155 3.65829 -0.0488155 3.34171 0.146447 3.14645L2.64645 0.646447C2.84171 0.451184 3.15829 0.451184 3.35355 0.646447ZM9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L10.2929 8H0.5C0.223858 8 9.31323e-09 8.22386 9.31323e-09 8.5C9.31323e-09 8.77614 0.223858 9 0.5 9H10.2929L8.64645 10.6464C8.45118 10.8417 8.45118 11.1583 8.64645 11.3536C8.84171 11.5488 9.15829 11.5488 9.35355 11.3536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645L9.35355 5.64645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Transfer.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Transfer;
