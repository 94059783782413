import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Building = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Building'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 1.5C6 1.22386 6.22386 1 6.5 1H18.5C18.7761 1 19 1.22386 19 1.5V6.5C19 6.77614 18.7761 7 18.5 7C18.2239 7 18 6.77614 18 6.5V2H7V9.5C7 9.77614 6.77614 10 6.5 10C6.22386 10 6 9.77614 6 9.5V1.5ZM11 23V12.5C11 12.2239 10.7761 12 10.5 12H1.5C1.22386 12 1 12.2239 1 12.5V23.5C1 23.7761 1.22386 24 1.5 24H10.5H14.5H23.5C23.7761 24 24 23.7761 24 23.5V9.5C24 9.22386 23.7761 9 23.5 9H14.5C14.2239 9 14 9.22386 14 9.5V23H11ZM2 23V13H10V23H2ZM18.5 13C18.2239 13 18 13.2239 18 13.5C18 13.7761 18.2239 14 18.5 14H19.5C19.7761 14 20 13.7761 20 13.5C20 13.2239 19.7761 13 19.5 13H18.5ZM15 10V23H23V10H15ZM18 16.5C18 16.2239 18.2239 16 18.5 16H19.5C19.7761 16 20 16.2239 20 16.5C20 16.7761 19.7761 17 19.5 17H18.5C18.2239 17 18 16.7761 18 16.5ZM18.5 19C18.2239 19 18 19.2239 18 19.5C18 19.7761 18.2239 20 18.5 20H19.5C19.7761 20 20 19.7761 20 19.5C20 19.2239 19.7761 19 19.5 19H18.5ZM5 19.5C5 19.2239 5.22386 19 5.5 19H6.5C6.77614 19 7 19.2239 7 19.5C7 19.7761 6.77614 20 6.5 20H5.5C5.22386 20 5 19.7761 5 19.5ZM5.5 16C5.22386 16 5 16.2239 5 16.5C5 16.7761 5.22386 17 5.5 17H6.5C6.77614 17 7 16.7761 7 16.5C7 16.2239 6.77614 16 6.5 16H5.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Building'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.5 0C3.22386 0 3 0.223858 3 0.5V7.5C3 7.77614 3.22386 8 3.5 8C3.77614 8 4 7.77614 4 7.5V1H12V5.5C12 5.77614 12.2239 6 12.5 6C12.7761 6 13 5.77614 13 5.5V0.5C13 0.223858 12.7761 0 12.5 0H3.5ZM0.5 9C0.223858 9 0 9.22386 0 9.5V15.5C0 15.7761 0.223858 16 0.5 16H6.5H9.5H15.5C15.7761 16 16 15.7761 16 15.5V7.5C16 7.22386 15.7761 7 15.5 7H9.5C9.22386 7 9 7.22386 9 7.5V15H7V9.5C7 9.22386 6.77614 9 6.5 9H0.5ZM1 15V10H6V15H1ZM10 8V15H15V8H10ZM2.5 12C2.22386 12 2 12.2239 2 12.5C2 12.7761 2.22386 13 2.5 13H4.5C4.77614 13 5 12.7761 5 12.5C5 12.2239 4.77614 12 4.5 12H2.5ZM11 10.5C11 10.2239 11.2239 10 11.5 10H13.5C13.7761 10 14 10.2239 14 10.5C14 10.7761 13.7761 11 13.5 11H11.5C11.2239 11 11 10.7761 11 10.5ZM11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13H13.5C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12H11.5ZM6.5 3C6.22386 3 6 3.22386 6 3.5C6 3.77614 6.22386 4 6.5 4H9.5C9.77614 4 10 3.77614 10 3.5C10 3.22386 9.77614 3 9.5 3H6.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Building'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 0.5C2 0.223858 2.22386 0 2.5 0H9.5C9.77614 0 10 0.223858 10 0.5V3.5C10 3.77614 9.77614 4 9.5 4C9.22386 4 9 3.77614 9 3.5V1H3V5.5C3 5.77614 2.77614 6 2.5 6C2.22386 6 2 5.77614 2 5.5V0.5ZM0 7.5C0 7.22386 0.223858 7 0.5 7H4.5C4.77614 7 5 7.22386 5 7.5V11H7V5.5C7 5.22386 7.22386 5 7.5 5H11.5C11.7761 5 12 5.22386 12 5.5V11.5C12 11.7761 11.7761 12 11.5 12H7.5H4.5H0.5C0.223858 12 0 11.7761 0 11.5V7.5ZM1 8V11H4V8H1ZM8 11V6H11V11H8ZM4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3H7.5C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2H4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Building.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Building;
