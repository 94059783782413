import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';
import paletteSchema from '../utils/validation/themeSchema';
import { BaseTheme } from '../BrandCore/colors/themes/base';
import { DarkTheme } from '../BrandCore/colors/themes/dark';
import { WhiteLabelTheme } from '../BrandCore/colors/themes/whiteLabel';

const themes = {
  // this object will grow as we have additional themes developed and supplied to our users
  base: BaseTheme,
  dark: DarkTheme,
  whiteLabel: WhiteLabelTheme,
};

/**
 * validateTheme - preforms validation on the theme objects
 * @param theme - {object} a theme object, it can be a full theme or a partial override
 * @returns {object} a completed theme
 */
const validateTheme = (theme) => {
  // this will grow in the future but for now we only have to worry about colors
  return paletteSchema.validate(theme);
};
/**
 * mergeTheme - merges two objects
 * @param partialTheme - an object provided by the developer
 * @returns {object} - a theme merged with the base ui palette
 */
const mergeTheme = (partialTheme) => {
  // i hate bringing in the whole object, but the build system cant find it via direct import --ASM
  return _.merge(themes.base, partialTheme);
};
/**
 * digestTheme - determines the flow of the theme logic
 * @param newTheme {object || string} - an object override of the theme or a string with the desired prebuilt theme
 * @returns {Object} - the theme object
 */
const digestTheme = (newTheme) => {
  if (
    !newTheme ||
    (typeof newTheme !== 'string' && typeof newTheme !== 'object')
  ) {
    // general error catch and default to base theme. This also covers if no theme is provided by user
    return themes.base;
  }
  if (typeof newTheme === 'object' && !Array.isArray(newTheme)) {
    // if they pass in a custom theme object validate and set it
    const updatedTheme = validateTheme(newTheme);
    if (!updatedTheme.error) {
      // a perfect match for a theme
      return newTheme;
    }
    // if it's a partial we merge it in
    return mergeTheme(newTheme);
  }
  if (typeof newTheme === 'string' && !!themes[newTheme]) {
    // return a pre-built theme
    return themes[newTheme];
  }

  // default functionality to return the base theme
  return themes.base;
};

// eslint-disable-next-line react/prop-types
const UICLThemeProvider = ({ children, theme }) => {
  const [processedTheme, setProcessedTheme] = useState(themes.base);

  useEffect(() => {
    // build the first theme and then update it if the theme prop changes
    setProcessedTheme(digestTheme(theme));
  }, [theme]);
  // this implementation can be accessed within styled components as ${(props) => props.theme.path.to.desiredValue};
  return <ThemeProvider theme={processedTheme}>{children}</ThemeProvider>;
};

export default UICLThemeProvider;
