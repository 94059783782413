import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  display: flex;
  padding: 9px 24px 9px 24px;
  ${TextStyles.small};
  .standard-menu-empty-text {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
    margin-left: 12px;
  }
`;
