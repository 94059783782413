import styled from 'styled-components';
import padding from '../../../commonResources/padding';

export default styled.div`
  display: flex;
  align-items: center;
  padding: 29px ${padding.navigationPaneHeader.left}px 13px;
  .title-icon {
    margin-right: 13px;
  }
`;
