import { useState, useEffect } from 'react';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    let timeoutId = null;

    function handleResize() {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 150);
    }

    window && window.addEventListener('resize', handleResize);

    handleResize();

    return () => window && window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export default useWindowSize;
