import React from 'react';
import styled from 'styled-components';

import cleanText from '../../../../../commonResources/helpers/cleanText';
import {
  white,
  neutral100,
} from '../../../../../BrandCore/colors/themes/base/basePalette';

const Label = styled.span`
  font-size: 13px;
  width: 100%;
  line-height: 17px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${neutral100};
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const SubLabel = styled.span`
  font-size: 11px;
  width: 100%;
  line-height: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${neutral100};
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const UnorderedList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  width: 100%;
  height: 54px;
  padding: 11px 20px 0 20px;

  &:hover {
    background-color: ${white};
    cursor: pointer;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
`;

const emboldenText = (text, query) => {
  const regexQuery = new RegExp(query, 'ig');
  return text.replace(regexQuery, '<b>$&</b>');
};

const createSearchResult = (result, query) => {
  const { label, teaser, url, id } = result;

  const labelText = emboldenText(cleanText(label), query);
  const teaserText = teaser && emboldenText(cleanText(teaser), query);

  return (
    <ListItem key={id}>
      <Anchor href={url} target="_blank">
        <Label dangerouslySetInnerHTML={{ __html: labelText }} />
        {teaserText && (
          <SubLabel dangerouslySetInnerHTML={{ __html: teaserText }} />
        )}
      </Anchor>
    </ListItem>
  );
};

const createSearchResultsGroup = (props) => {
  return (
    props.searchResults &&
    props.searchResults.map((result) => {
      return createSearchResult(result, props.query);
    })
  );
};

export const SearchResults = (props) => {
  return <UnorderedList>{createSearchResultsGroup(props)}</UnorderedList>;
};

export default SearchResults;
