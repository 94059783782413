import styled from 'styled-components';
import { UnstyledButton } from '../../../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  padding: 0.5em 1.125rem 0.5em 0;
  height: 100%;
  overflow: visible;

  &:focus-visible {
    outline: none;
    box-shadow: ${(props) =>
      `inset 0 0 0 1px ${props.theme.UIPalette.Colors.Field.Border.Focus}`};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
