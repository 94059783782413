import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '../IconButton';
import TextInput from './components/TextInput';
import Wrapper from './components/Wrapper';
import IconWrapper from './components/IconWrapper';
import InputLabel from './components/InputLabel';
import ErrorMessage from './components/ErrorMessage';

import { useFocus } from '../../commonResources/hooks/useFocus';
import { useHover } from '../../commonResources/hooks/useHover';
import { useKeyboard } from '../../commonResources/hooks/useKeyboard';
import InputValidationIcon, {
  INPUT_TO_ICON_SIZES_MAP,
} from './components/InputValidationIcon';

export const Input = React.forwardRef(
  (
    {
      domID = null,
      autoComplete = null,
      className = '',
      dataTestId = '',
      initialValue = '',
      maxLength = null,
      label = null,
      labelProps = null,
      placeholder = '',
      onIconClick = () => {},
      icon: Icon = null,
      iconDomID = null,
      paddingRight = 8,
      type = 'text',
      size = 'medium',
      hasError = false,
      errorMessage = null,
      disabled = false,
      mask = null,
      alwaysShowMask = false,
      maskChar = '',
      onClick = () => false,
      onFocus = () => false,
      onBlur = () => false,
      onChange = () => false,
      onKeyDown = () => false,
      onKeyPress = () => false,
      onKeyUp = () => false,
      onMouseOut = () => false,
      onMouseOver = () => false,
      onEnterPress = () => false,
      initialValidationState = '',
      regex = '',
      name = null,
      // eslint-disable-next-line react/prop-types
      children = null,
      ...otherProps
    },
    ref,
  ) => {
    const [, hoverBinding] = useHover(onMouseOver, onMouseOut);
    const [isFocused, focusBinding] = useFocus(onFocus, onBlur);

    const re = new RegExp(regex);

    const [validationState, setValidationState] = useState('');

    useEffect(() => {
      setValidationState(initialValidationState);
    }, [initialValidationState]);

    const handleOnChange = (e, { value }) => {
      if (regex) {
        const isValid = re.test(value);
        setValidationState(isValid ? 'valid' : 'invalid');
      }
      onChange && onChange(e, { value });
    };

    const keyboardBinding = useKeyboard({
      initialValue,
      onChange: handleOnChange,
      onKeyDown,
      onKeyPress,
      onEnterPress,
      onKeyUp,
    });

    if (label && !domID) {
      console.warn(
        'Please enter valid "domID" prop into Input component for accessibility.',
      );
    }

    const wrapperClasses = clsx(className, {
      disabled,
      'has-error': validationState === 'invalid' || hasError,
    });

    const testIDs = {
      label: dataTestId ? `${dataTestId}-label` : '',
      button: dataTestId ? `${dataTestId}-button` : '',
      validationIcon: dataTestId ? `${dataTestId}-Icon` : '',
      errorMessage: dataTestId ? `${dataTestId}-errorMessage` : '',
    };

    const inputClasses = clsx(size, {
      'inline-validation': regex || initialValidationState,
      focused: isFocused,
      'has-error': validationState === 'invalid' || hasError,
    });

    return (
      <Wrapper className={wrapperClasses}>
        {label ? (
          <InputLabel
            {...labelProps}
            htmlFor={domID}
            data-testid={testIDs.label}
          >
            {label}
          </InputLabel>
        ) : null}
        {regex || validationState ? (
          <InputValidationIcon
            size={size}
            dataTestId={testIDs.validationIcon}
            validationState={validationState}
          />
        ) : null}
        <TextInput
          ref={ref}
          autoComplete={autoComplete}
          id={domID}
          className={inputClasses}
          data-testid={dataTestId}
          name={name}
          type={type}
          placeholder={placeholder}
          paddingright={paddingRight}
          maxLength={maxLength}
          disabled={disabled}
          onClick={onClick}
          mask={mask}
          alwaysShowMask={alwaysShowMask}
          maskChar={maskChar}
          {...hoverBinding}
          {...focusBinding}
          {...keyboardBinding}
          {...otherProps}
        />
        {children}
        {Icon ? (
          <IconWrapper
            id={`${iconDomID}-button`}
            onClick={onIconClick}
            data-testid={testIDs.button}
            hasLabel={label !== null && label !== ''}
            className={size}
            size={size}
            hasError={
              validationState === 'invalid' || (hasError && errorMessage)
            }
          >
            <IconButton
              icon={Icon}
              domID={iconDomID}
              size={INPUT_TO_ICON_SIZES_MAP[size]}
            />
          </IconWrapper>
        ) : null}
        {validationState === 'invalid' || (hasError && errorMessage) ? (
          <ErrorMessage data-testid={testIDs.errorMessage}>
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </Wrapper>
    );
  },
);

export const InputPropTypes = {
  /**
   *  id attribute for input
   *
   *  The id attribute is used to point to a specific style declaration in a style sheet.
   *  It is also used by JavaScript to access and manipulate the element with the specific id.
   *
   *  NOTE: When label prop is not empty, this prop must be present.
   */
  domID: PropTypes.string,
  /**
   * Input uses a wrapper div to position items in absolute position.
   * This class name is applied to the wrapper div.
   */
  className: PropTypes.string,
  /**
   * data-testid tag for automated testing. When passed each elements in input gets following ids
   * - label: dataTestId-label
   * - button: dataTestId + "-button"
   * - validationIcon: dataTestId-Icon`
   * - errorMessage: dataTestId-errorMessage`,
   */
  dataTestId: PropTypes.string,
  /**
   * Input component stores the value in its internal state.
   * Use this prop if you want to overide the internal state you can use this prop.
   */
  initialValue: PropTypes.string,
  /**
   * name specifies the name of an <input> element.
   */
  name: PropTypes.string,
  /**
   * Specifies the maximum number of characters allowed in an <input> element
   *
   * Keyboard press is ignored after user types maximum number of characters
   */
  maxLength: PropTypes.number,
  /**
   * Label for input.
   * Proper use of labels with input will benefit:
   *  - Screen reader users (will read out loud the label, when the user is focused on the element)
   */
  label: PropTypes.string,
  /**
   * Label Props
   * Currently supported:
   * labelProps: {
   *   tooltipProps,
   *   micromodalProps
   * }
   */
  // eslint-disable-next-line react/forbid-foreign-prop-types
  labelProps: PropTypes.shape(InputLabel.propTypes),
  /**
   * The placeholder attribute specifies a short hint that describes the expected value of an input field (e.g. a sample value or a short description of the expected format).
   *
   * The short hint is displayed in the input field before the user enters a value.
   *
   */

  placeholder: PropTypes.string,
  /**
   * A valid ui-core icon element type.
   *
   * Example:
   *  import { CircleCheck } from "ui-core/dist/CircleCheck"
   *
   *  <Input {...otherProps} icon={CircleCheck} />
   */
  icon: PropTypes.elementType,
  /**
   * An unique id for icon element.
   * The id attribute is used to point to a specific style declaration in a style sheet.
   * It is also used by JavaScript to access and manipulate the element with the specific id.
   */
  iconDomID: PropTypes.string,
  /**
   * A callback function that gets executed when user clicks on icon.
   * This function recieves following arguments
   *  @args {SyntheticEvent} e
   *
   * Use this prop prop works only when icon is prop is used
   */
  onIconClick: PropTypes.func,
  /**
   * Prop that is used to set right padding on the wrapper div.
   *
   * This is useful when using `children` prop.
   */
  paddingRight: PropTypes.number,
  /**
   * The type attribute specifies the type of <input> element to display.
   *
   * If the type attribute is not specified, the default type is "text".
   */
  type: PropTypes.string,
  /**
   * Indicates whether the input entered by user has any error. This can async error.
   */
  hasError: PropTypes.bool,
  /**
   * Specifies that <input> element should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Error message to show when user enters an invalid value for the input
   */
  errorMessage: PropTypes.string,
  /**
   * To prevent Chrome autoComplete, set this property to 'off'
   * */
  autoComplete: PropTypes.string,
  /**
   * Fired on input field click, takes click event.
   * */
  onClick: PropTypes.func,
  /** Fires when input value changes.
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onChange: PropTypes.func,
  /** (e, true) => {} */
  onMouseOver: PropTypes.func,
  /** (e, false) => {} */
  onMouseOut: PropTypes.func,
  /** (e, true) => {} */
  onFocus: PropTypes.func,
  /** (e, false) => {} */
  onBlur: PropTypes.func,

  // keyboard
  /**
   * A callback function that gets executed when user enters enter on <input>
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onEnterPress: PropTypes.func,
  /** A callback function to execute when the key is pressed.
   *
   * The keydown event occurs when the key is pressed, followed immediately by the keypress event.
   * Then the keyup event is generated when the key is released.
   *
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onKeyDown: PropTypes.func,
  /**
   * A callback function to execute immediatly after key is pressed.
   *
   * The keydown event occurs when the key is pressed, followed immediately by the keypress event.
   *  Then the keyup event is generated when the key is released.
   *
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onKeyPress: PropTypes.func,
  /**
   * A callback function to execute when key is released.
   *
   * @param {SyntheticEvent} e
   * @param {object} state - { value: string }
   */
  onKeyUp: PropTypes.func,

  /**
   * Regular Expression Pattern that can be used for inline validations.
   *
   * Shows proper icons based on the user input
   * */
  regex: PropTypes.string,
  mask: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  maskChar: PropTypes.string,
  /**
   * Validation state for inline validations.
   *
   * This prop can be used to show proper icon when user enters invalid value.
   */
  initialValidationState: PropTypes.oneOf([
    'initial',
    'validating',
    'invalid',
    'valid',
  ]),
  size: PropTypes.oneOf(['small', 'large', 'medium']),
  /**
   * React node to display inside input.
   */
  children: PropTypes.node,
};

Input.propTypes = InputPropTypes;

export default Input;
