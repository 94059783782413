import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Link = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Link'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 7.5C2 5.56714 3.56714 4 5.5 4H14.5C16.4329 4 18 5.56714 18 7.5V11.5C18 13.4329 16.4329 15 14.5 15H10V16H14.5C16.9851 16 19 13.9851 19 11.5V7.5C19 5.01486 16.9851 3 14.5 3H5.5C3.01486 3 1 5.01486 1 7.5V11.5C1 12.8444 1.59016 14.052 2.52425 14.876L2.89922 15.2067L3.56072 14.4568L3.18575 14.126C2.45784 13.484 2 12.5456 2 11.5V7.5ZM7 13.5C7 11.5671 8.56714 10 10.5 10H15V9H10.5C8.01486 9 6 11.0149 6 13.5V17.5C6 19.9851 8.01486 22 10.5 22H19.5C21.9851 22 24 19.9851 24 17.5V13.5C24 12.1556 23.4098 10.948 22.4758 10.124L22.1008 9.79328L21.4393 10.5432L21.8142 10.874C22.5422 11.516 23 12.4544 23 13.5V17.5C23 19.4329 21.4329 21 19.5 21H10.5C8.56714 21 7 19.4329 7 17.5V13.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Link'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H10C10.2652 3 10.5196 3.10536 10.7071 3.29289C10.8946 3.48043 11 3.73478 11 4V9C11 9.26522 10.8946 9.51957 10.7071 9.70711C10.5196 9.89464 10.2652 10 10 10H6.5C6.22386 10 6 10.2239 6 10.5C6 10.7761 6.22386 11 6.5 11H10C10.5304 11 11.0391 10.7893 11.4142 10.4142C11.7893 10.0391 12 9.53043 12 9V4C12 3.46957 11.7893 2.96086 11.4142 2.58579C11.0391 2.21071 10.5304 2 10 2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11H2.5C2.77614 11 3 10.7761 3 10.5C3 10.2239 2.77614 10 2.5 10H2C1.73478 10 1.48043 9.89464 1.29289 9.70711C1.10536 9.51957 1 9.26522 1 9V4C1 3.73478 1.10536 3.48043 1.29289 3.29289ZM5.29289 7.29289C5.48043 7.10536 5.73478 7 6 7H9.5C9.77614 7 10 6.77614 10 6.5C10 6.22386 9.77614 6 9.5 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H14C14.5304 15 15.0391 14.7893 15.4142 14.4142C15.7893 14.0391 16 13.5304 16 13V8C16 7.46957 15.7893 6.96086 15.4142 6.58579C15.0391 6.21071 14.5304 6 14 6H13.5C13.2239 6 13 6.22386 13 6.5C13 6.77614 13.2239 7 13.5 7H14C14.2652 7 14.5196 7.10536 14.7071 7.29289C14.8946 7.48043 15 7.73478 15 8V13C15 13.2652 14.8946 13.5196 14.7071 13.7071C14.5196 13.8946 14.2652 14 14 14H6C5.73478 14 5.48043 13.8946 5.29289 13.7071C5.10536 13.5196 5 13.2652 5 13V8C5 7.73478 5.10536 7.48043 5.29289 7.29289Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Link'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.14645 2.14645C1.24021 2.05268 1.36739 2 1.5 2H7.5C7.63261 2 7.75979 2.05268 7.85355 2.14645C7.94732 2.24021 8 2.36739 8 2.5V6.5C8 6.63261 7.94732 6.75978 7.85355 6.85355C7.75978 6.94732 7.63261 7 7.5 7H5.5C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H7.5C7.89783 8 8.27936 7.84196 8.56066 7.56066C8.84196 7.27936 9 6.89783 9 6.5V2.5C9 2.10217 8.84196 1.72064 8.56066 1.43934C8.27936 1.15804 7.89782 1 7.5 1H1.5C1.10218 1 0.720644 1.15804 0.43934 1.43934C0.158035 1.72064 0 2.10218 0 2.5V6.5C0 6.89782 0.158035 7.27936 0.43934 7.56066C0.720644 7.84196 1.10217 8 1.5 8C1.77614 8 2 7.77614 2 7.5C2 7.22386 1.77614 7 1.5 7C1.36739 7 1.24022 6.94732 1.14645 6.85355C1.05268 6.75978 1 6.63261 1 6.5V2.5C1 2.36739 1.05268 2.24021 1.14645 2.14645ZM4.14645 5.14645C4.24021 5.05268 4.36739 5 4.5 5H6.5C6.77614 5 7 4.77614 7 4.5C7 4.22386 6.77614 4 6.5 4H4.5C4.10218 4 3.72064 4.15804 3.43934 4.43934C3.15804 4.72064 3 5.10218 3 5.5V9.5C3 9.89783 3.15804 10.2794 3.43934 10.5607C3.72064 10.842 4.10218 11 4.5 11H10.5C10.8978 11 11.2794 10.842 11.5607 10.5607C11.842 10.2794 12 9.89783 12 9.5V5.5C12 5.10217 11.842 4.72064 11.5607 4.43934C11.2794 4.15804 10.8978 4 10.5 4C10.2239 4 10 4.22386 10 4.5C10 4.77614 10.2239 5 10.5 5C10.6326 5 10.7598 5.05268 10.8536 5.14645C10.9473 5.24022 11 5.36739 11 5.5V9.5C11 9.63261 10.9473 9.75978 10.8536 9.85355C10.7598 9.94732 10.6326 10 10.5 10H4.5C4.36739 10 4.24021 9.94732 4.14645 9.85355C4.05268 9.75978 4 9.63261 4 9.5V5.5C4 5.36739 4.05268 5.24021 4.14645 5.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Link.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Link;
