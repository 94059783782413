import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Dashboard = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Dashboard'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 0C0.223858 0 0 0.223858 0 0.5V8.5C0 8.77614 0.223858 9 0.5 9H6.5C6.77614 9 7 8.77614 7 8.5V0.5C7 0.223858 6.77614 0 6.5 0H0.5ZM1 8V1H6V8H1ZM0.5 11C0.223858 11 0 11.2239 0 11.5V15.5C0 15.7761 0.223858 16 0.5 16H6.5C6.77614 16 7 15.7761 7 15.5V11.5C7 11.2239 6.77614 11 6.5 11H0.5ZM1 15V12H6V15H1ZM9 0.5C9 0.223858 9.22386 0 9.5 0H15.5C15.7761 0 16 0.223858 16 0.5V5.5C16 5.77614 15.7761 6 15.5 6H9.5C9.22386 6 9 5.77614 9 5.5V0.5ZM10 1V5H15V1H10ZM9.5 8C9.22386 8 9 8.22386 9 8.5V15.5C9 15.7761 9.22386 16 9.5 16H15.5C15.7761 16 16 15.7761 16 15.5V8.5C16 8.22386 15.7761 8 15.5 8H9.5ZM15 15H10V9H15V15Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Dashboard'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 0.5H0.5V1V12.7333V13.2333H1H9.8H10.3V12.7333V1V0.5H9.8H1ZM1.5 12.2333V1.5H9.3V12.2333H1.5ZM1 16.6334H0.5V17.1334V23V23.5H1H9.8H10.3V23V17.1334V16.6334H9.8H1ZM1.5 22.5V17.6334H9.3V22.5H1.5ZM13.7 0.5H14.2H23H23.5V1V8.33333V8.83333H23H14.2H13.7V8.33333V1V0.5ZM14.7 1.5V7.83333H22.5V1.5H14.7ZM14.2 12.2333H13.7V12.7333V23V23.5H14.2H23H23.5V23V12.7333V12.2333H23H14.2ZM14.7 22.5V13.2333H22.5V22.5H14.7Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Dashboard.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Dashboard;
