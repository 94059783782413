import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ChartOut = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Chart Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 0C1.89543 0 1 0.895429 1 2L1 14C1 15.1046 1.89543 16 3 16H7.5C7.77614 16 8 15.7761 8 15.5C8 15.2239 7.77614 15 7.5 15H3C2.44772 15 2 14.5523 2 14V2C2 1.44772 2.44772 1 3 1H13C13.5523 1 14 1.44772 14 2V5.5C14 5.77614 14.2239 6 14.5 6C14.7761 6 15 5.77614 15 5.5V2C15 0.895431 14.1046 0 13 0H3ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H11.5C11.7761 5 12 4.77614 12 4.5C12 4.22386 11.7761 4 11.5 4H4.5ZM4 7.5C4 7.22386 4.22386 7 4.5 7H8.5C8.77614 7 9 7.22386 9 7.5C9 7.77614 8.77614 8 8.5 8H4.5C4.22386 8 4 7.77614 4 7.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H6.5C6.77614 11 7 10.7761 7 10.5C7 10.2239 6.77614 10 6.5 10H4.5ZM9 11.5C9 11.2239 9.22386 11 9.5 11H13.2929L12.1464 9.85355C11.9512 9.65829 11.9512 9.34171 12.1464 9.14645C12.3417 8.95118 12.6583 8.95118 12.8536 9.14645L14.8536 11.1464C15.0488 11.3417 15.0488 11.6583 14.8536 11.8536L12.8536 13.8536C12.6583 14.0488 12.3417 14.0488 12.1464 13.8536C11.9512 13.6583 11.9512 13.3417 12.1464 13.1464L13.2929 12H9.5C9.22386 12 9 11.7761 9 11.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Chart Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V11.5C1 11.7761 1.22386 12 1.5 12H6.5C6.77614 12 7 11.7761 7 11.5C7 11.2239 6.77614 11 6.5 11H2V1H10V3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5V0.5C11 0.223858 10.7761 0 10.5 0H1.5ZM3.5 4C3.22386 4 3 4.22386 3 4.5C3 4.77614 3.22386 5 3.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H3.5ZM9.14645 10.1464C8.95118 10.3417 8.95118 10.6583 9.14645 10.8536C9.34171 11.0488 9.65829 11.0488 9.85355 10.8536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645L9.85355 6.14645C9.65829 5.95118 9.34171 5.95118 9.14645 6.14645C8.95118 6.34171 8.95118 6.65829 9.14645 6.85355L10.7929 8.5L9.14645 10.1464ZM3 7.5C3 7.22386 3.22386 7 3.5 7H6.5C6.77614 7 7 7.22386 7 7.5C7 7.77614 6.77614 8 6.5 8H3.5C3.22386 8 3 7.77614 3 7.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ChartOut.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ChartOut;
