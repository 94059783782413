import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal/Modal';
import ModalHeader from '../../../../Modal/components/ModalHeader';
import ModalFooter from '../../../../Modal/components/ModalFooter';
import ModalBody from '../../../../Modal/components/ModalBody';
import ButtonGroup from '../../../../../Molecules/ButtonGroup';
import Button from '../../../../../Atoms/Button';
import { Container } from '../../../../../Layouts/contentGrid/contentGrid';
import Text from '../../../../../BrandCore/Text/Text';

const NotificationModal = ({
  notification = undefined,
  removeFromDOMOnClose = true,
  onCloseClick = () => false,
  onOkClick = () => false,
}) => {
  const { title = '', description } = notification || {};
  return (
    <Modal
      dataTestId="test-notification-modal"
      isOpen={!!notification}
      removeFromDOMOnClose={removeFromDOMOnClose}
    >
      <ModalHeader
        title={title}
        dataTestId="test-notification-modal-header"
        onClose={onCloseClick}
      />
      <ModalBody>
        <Container>
          <Text variant="medium">{description}</Text>
        </Container>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            size="large"
            buttonType="emphasized"
            name="OK"
            onClick={onOkClick}
          />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

NotificationModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  notification: PropTypes.object,
  removeFromDOMOnClose: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onOkClick: PropTypes.func,
};

export default NotificationModal;
