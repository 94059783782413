import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import NameSearchDropdown from '../../Patterns/SaveSearch/components/NameSearchDropdown';

import Wrapper from './components/Wrapper';
import TextWrapper from './components/TextWrapper';
import Count from '../../Atoms/Count';
import Tooltip from '../../Atoms/Tooltip';
import DragRow from '../../BrandCore/icons/interactive/DragRow';
import Popout from '../../BrandCore/icons/interactive/PopOut';
import IconButton from '../../Atoms/IconButton';
import StandardMenu from '../StandardMenu';
import Dots from '../../BrandCore/icons/interactive/Dots';
import Home from '../../BrandCore/icons/interactive/Home';
import padding from '../../commonResources/padding';

const hasNumber = (c) => /\d/.test(c);
export const SingleSelectItem = ({
  className,
  style,
  count = null,
  tooltipText = null,
  tooltipWidth = 100,
  disabled = false,
  domID = null,
  paddingLeft = padding.navigationPaneHeader.left,
  isSelected = false,
  text = null,
  onClick = () => false,
  dataTestId = '',
  wrapText = false,
  overflowTooltipPosition = 'bottom-center',
  overflowTooltipWidth = 200,
  isEditing = false,
  isCurrentHome = true,
  onHomeChange = () => false,
  isDragging = false,
  disableReOrder = true,
  menuItems = null,
  onMenuClick = () => false,
  showSaveDropdown = false,
  onSave = () => false,
  onCancel = () => false,
  saveSearchTitleInitialValue = '',
  icon: Icon = null,
  isPopout = false,
  onPopoutClick = () => false,
  selectedBackground: _selectedBackground = null,
  borderLeft: _borderLeft = null,
}) => {
  const isValidCount = hasNumber(count);
  const theme = useTheme();
  const selectedBackground = useMemo(
    () => _selectedBackground || theme.UIPalette.Colors.Background.Selected,
    [theme, _selectedBackground],
  );
  const borderLeft = useMemo(
    () => _borderLeft || theme.UIPalette.Colors.Filter.Border.Selected,
    [theme, _borderLeft],
  );

  const testIDs = {
    wrapper: dataTestId ? `${dataTestId}-wrapper-single-select-item` : '',
    count: dataTestId ? `${dataTestId}-count` : '',
    text: dataTestId ? `${dataTestId}-text` : '',
    reorder: dataTestId ? `${dataTestId}-reorder` : '',
    tooltip: dataTestId ? `${dataTestId}-tooltip` : '',
    home: dataTestId ? `${dataTestId}-home` : '',
    overflowTooltip: dataTestId ? `${dataTestId}-overflow-tooltip` : '',
    dots: dataTestId ? `${dataTestId}-dots` : '',
    dropdownMenu: dataTestId ? `${dataTestId}-dropdownMenu` : '',
  };

  const domIDs = {
    wrapper: domID ? `${domID}-wrapper-single-select-item` : '',
    count: domID ? `${domID}-count` : '',
    text: domID ? `${domID}-text` : '',
    reorder: domID ? `${domID}-reorder` : '',
    tooltip: domID ? `${domID}-tooltip` : '',
    home: domID ? `${domID}-home` : '',
    overflowTooltip: domID ? `${domID}-overflow-tooltip` : '',
    dots: domID ? `${domID}-dots` : '',
    dropdownMenu: domID ? `${domID}-dropdownMenu` : '',
  };

  const textRef = useRef(null);
  const [isAllTextVisible, setIsAllTextVisible] = useState(true);
  const [isTextWrapping, setIsTextWrapping] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [itemPressed, setItemPressed] = useState(false);

  const isEllipsisActive = (element) => {
    // scrollWidth and offsetWidth can be slightly off in IE, so I'm
    // giving this 1px of wiggle room. Otherwise it almost always returns true.
    return element.offsetWidth < element.scrollWidth - 2;
  };

  // check whether the text is truncated and enable the tooltip if it is
  useEffect(() => {
    if (textRef.current) {
      setIsAllTextVisible(!isEllipsisActive(textRef.current));
    } else {
      setIsAllTextVisible(true);
    }
  }, [text, wrapText]);

  // check whether the text is actually wrapping and adjust line-height
  useEffect(() => {
    if (textRef.current && wrapText) {
      setIsTextWrapping(textRef.current.offsetHeight > 30);
    } else {
      setIsTextWrapping(false);
    }
  }, [text, wrapText]);

  useEffect(() => {
    if (isDragging) {
      setIsAllTextVisible(true);
    } else if (textRef.current) {
      setIsAllTextVisible(!isEllipsisActive(textRef.current));
    } else {
      setIsAllTextVisible(true);
    }
  }, [isDragging]);

  const onKeyUp = (e) => {
    if ([13, 32].includes(e.which)) {
      setItemPressed(false);
    }
  };

  const onKeyDown = (e) => {
    if (e.target.nodeName === 'DIV' && [13, 32].includes(e.which)) {
      e.preventDefault();
      setItemPressed(true);
      onClick(e);
      e.stopPropagation();
    }
  };

  const withoutOverflowTooltip = () => (
    <Wrapper
      className={clsx({
        className,
        selected: isSelected,
        pressing: itemPressed,
      })}
      id={domID}
      data-testid={testIDs.wrapper}
      disabled={disabled}
      onClick={(e) =>
        !isEditing && !disabled && (isPopout ? onPopoutClick(e) : onClick(e))
      }
      paddingLeft={paddingLeft}
      isEditing={!disableReOrder && isEditing}
      isTextWrapping={isTextWrapping}
      style={{ ...style }}
      role="menuitem"
      tabIndex={0}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      selectedBackground={selectedBackground}
      borderLeft={borderLeft}
    >
      <span style={{ display: 'flex', flex: 1, width: '100%' }}>
        {isEditing && (
          <IconButton
            size="small"
            icon={DragRow}
            onClick={onHomeChange}
            disabled={disableReOrder}
            dataTestId={testIDs.reorder}
            domID={domIDs.reorder}
            style={{ marginRight: '8px' }}
          />
        )}
        {Icon && (
          <div style={{ margin: '1.5px 10px 0px 0px' }}>
            <Icon size="small" />
          </div>
        )}
        <Tooltip
          tooltipPosition={overflowTooltipPosition}
          tooltipContent={text}
          tooltipWidth={overflowTooltipWidth}
          domID={testIDs.overflowTooltip}
          hideTooltip={isAllTextVisible}
          isButtonRoleDisabled
          style={{ width: '100%' }}
        >
          <TextWrapper
            ref={textRef}
            data-testid={testIDs.text}
            id={domIDs.text}
            wrapText={wrapText}
          >
            {text}
            {isPopout && (
              <span style={{ marginLeft: '8px' }}>
                <Popout
                  size="small"
                  fillColor={theme.UIPalette.Colors.Content.Secondary}
                />
              </span>
            )}
          </TextWrapper>
        </Tooltip>
      </span>

      {isValidCount && !isEditing && (
        <>
          {tooltipText ? (
            <Tooltip
              tooltipPosition="left-center"
              tooltipContent={tooltipText}
              tooltipWidth={tooltipWidth}
              domID={domIDs.tooltip}
              isButtonRoleDisabled
              style={{ marginLeft: '10px', maxWidth: '20px' }}
            >
              <Count
                size="small"
                count={count}
                data-testid={testIDs.count}
                domID={domIDs.count}
              />
            </Tooltip>
          ) : (
            <Count
              size="small"
              data-testid={testIDs.count}
              domID={domIDs.count}
              count={count}
            />
          )}
        </>
      )}
      {isEditing && isCurrentHome && (
        <IconButton
          domID={domIDs.home}
          dataTestId={testIDs.home}
          size="small"
          icon={Home}
          onClick={onHomeChange}
          disabled={isCurrentHome}
          tooltipProps={{
            tooltipPosition: 'left-center',
            tooltipContent: isCurrentHome ? 'Current home' : 'Set Home',
            tooltipWidth: 100,
            isButtonRoleDisabled: true,
          }}
        />
      )}
      {menuItems && isEditing && (
        <div style={{ marginLeft: '8px' }}>
          <IconButton
            size="small"
            className="sub-menu-button"
            icon={Dots}
            dataTestId={testIDs.dots}
            domID={domIDs.dots}
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(true);
            }}
            tooltipProps={{
              tooltipPosition: 'left-center',
              tooltipContent: 'Options',
              tooltipWidth: 75,
              isButtonRoleDisabled: true,
            }}
          />
          {isMenuOpen && isEditing && (
            <StandardMenu
              dataTestId={testIDs.dropdownMenu}
              domID={domIDs.dropdownMenu}
              menuItems={menuItems}
              onMenuClick={(e, state) => {
                setIsMenuOpen(false);
                onMenuClick(e, state, domID);
              }}
              onClickOut={() => setIsMenuOpen(false)}
            />
          )}
          {showSaveDropdown && isEditing && (
            <NameSearchDropdown
              index={domID.split('|')[1]}
              onCancel={onCancel}
              onSave={onSave}
              initialValue={saveSearchTitleInitialValue}
              initialIsOpen
              orientation="right"
              label="Rename Your Search"
              anchorComponent={() => <div style={{ height: '16px' }} />}
            />
          )}
        </div>
      )}
    </Wrapper>
  );

  // For now text overflow tooltip is hidden when its in hidden mode for overflow text
  // this is because when tooltip is too big, the drag preview might include
  // the sorrounding items because of the width and height xeof tooltip
  if (isAllTextVisible || isEditing) return withoutOverflowTooltip();

  return <>{withoutOverflowTooltip()}</>;
};

export const SingleSelectItemPropTypes = {
  text: PropTypes.node.isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  /** takes click event as parameter */
  onClick: PropTypes.func.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipText: PropTypes.string,
  tooltipWidth: PropTypes.number,
  disabled: PropTypes.bool,
  domID: PropTypes.string,
  paddingLeft: PropTypes.number,
  dataTestId: PropTypes.string,
  /** if true, the title wraps instead of truncating if too long */
  wrapText: PropTypes.bool,
  overflowTooltipWidth: PropTypes.number,
  overflowTooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  isEditing: PropTypes.bool,
  isCurrentHome: PropTypes.bool,
  /** Indicates whether selected Item is currentlt being dragged or not. When its dragged,
   * text wrapping tooltip wont be visible */
  isDragging: PropTypes.bool,
  disableReOrder: PropTypes.bool,
  onHomeChange: () => false,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  onMenuClick: PropTypes.func,
  /** OPT-IN prop for save-search pattern only - set to true if SSI is part of save-search pattern */
  // underSavedSearches: PropTypes.bool, TODO find what this was used for
  /** OPT-IN prop for save-search pattern only - whether single select item shows saved search rename dropdown */
  showSaveDropdown: PropTypes.bool,
  /** OPT-IN prop for save-search pattern only - callback for saving rename save search dialog */
  onSave: PropTypes.func,
  /** OPT-IN prop for save-search pattern only - callback for canceling rename save search dialog */
  onCancel: PropTypes.func,
  /** OPT-IN prop for save-search pattern only - Use this to populate rename dialog with save search previous name */
  saveSearchTitleInitialValue: PropTypes.string,
  /** Valid React element type. eg icon={CircleCheck}. Should be a ui-core icon component */
  icon: PropTypes.elementType,
  /** to be passed as true, if SingleSelectItem opens another link in new browser window. */
  isPopout: PropTypes.bool,
  /** If isPopout is passed as true, then onPopoutClick will be called on clicking of this SingleSelectItem */
  onPopoutClick: PropTypes.func,
  /**
   * Represent background color for selected item default is primary30
   */
  selectedBackground: PropTypes.string,
  /**
   * Represent border left color for selected item default is primary100
   */
  borderLeft: PropTypes.string,
};

SingleSelectItem.propTypes = SingleSelectItemPropTypes;

export default SingleSelectItem;
