import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  display: flex;
  align-items: center;

  .list-pane-header-icon {
    margin-right: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${TextStyles.large};
    margin: 0 12px 0 0;
  }
`;
