import styled from 'styled-components';
import { UnstyledButton } from '../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  position: fixed;
  left: 0;
  top: 0;
  width: 40px;
  height: 100vh;
  display: none;

  &:focus {
    background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
    border: 1px solid ${({ theme }) => theme.BaseColors.Primary['100']};
    box-sizing: border-box;
    outline: none;
  }

  &.active {
    display: block;
  }

  &.sidebar-visible {
    left: 70px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.BaseColors.Neutral['10']};
  }

  &:active {
    background-color: ${({ theme }) => theme.BaseColors.Neutral['15']};
  }
`;
