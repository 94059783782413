import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Hospital = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Hospital'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M16.5 2.5L12.5 0.5V4.5L16.5 2.5ZM5.5 7C5.10218 7 4.72064 7.15804 4.43934 7.43934C4.15804 7.72064 4 8.10218 4 8.5V20.5C4 20.7761 3.77614 21 3.5 21C3.22386 21 3 20.7761 3 20.5V8.5C3 7.83696 3.26339 7.20107 3.73223 6.73223C4.20107 6.26339 4.83696 6 5.5 6H19.5C20.163 6 20.7989 6.26339 21.2678 6.73223C21.7366 7.20107 22 7.83696 22 8.5V20.5C22 20.7761 21.7761 21 21.5 21C21.2239 21 21 20.7761 21 20.5V8.5C21 8.10218 20.842 7.72064 20.5607 7.43934C20.2794 7.15804 19.8978 7 19.5 7H5.5ZM1 23.5C1 23.2239 1.22386 23 1.5 23H23.5C23.7761 23 24 23.2239 24 23.5C24 23.7761 23.7761 24 23.5 24H1.5C1.22386 24 1 23.7761 1 23.5ZM9.5 14C9.22386 14 9 14.2239 9 14.5V20.5C9 20.7761 9.22386 21 9.5 21C9.77614 21 10 20.7761 10 20.5V15H15V20.5C15 20.7761 15.2239 21 15.5 21C15.7761 21 16 20.7761 16 20.5V14.5C16 14.2239 15.7761 14 15.5 14H9.5ZM13.5 11.5V9.5H11.5V11.5H13.5ZM7.5 9.5H9.5V11.5H7.5V9.5ZM17.5 11.5V9.5H15.5V11.5H17.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Hospital'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 0L11 1.5L8 3V0ZM0 15.5C0 15.2239 0.223858 15 0.5 15H15.5C15.7761 15 16 15.2239 16 15.5C16 15.7761 15.7761 16 15.5 16H0.5C0.223858 16 0 15.7761 0 15.5ZM2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6V13.5C14 13.7761 14.2239 14 14.5 14C14.7761 14 15 13.7761 15 13.5V6C15 5.46957 14.7893 4.96086 14.4142 4.58579C14.0391 4.21071 13.5304 4 13 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V13.5C1 13.7761 1.22386 14 1.5 14C1.77614 14 2 13.7761 2 13.5V6C2 5.73478 2.10536 5.48043 2.29289 5.29289ZM6 9.5C6 9.22386 6.22386 9 6.5 9H9.5C9.77614 9 10 9.22386 10 9.5V13.5C10 13.7761 9.77614 14 9.5 14C9.22386 14 9 13.7761 9 13.5V10H7V13.5C7 13.7761 6.77614 14 6.5 14C6.22386 14 6 13.7761 6 13.5V9.5ZM7.5 8V7H8.5V8H7.5ZM6 7H5V8H6V7ZM10 7H11V8H10V7Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Hospital'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V9.5C1 9.77614 0.776142 10 0.5 10C0.223858 10 0 9.77614 0 9.5V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H10C10.5304 1 11.0391 1.21071 11.4142 1.58579C11.7893 1.96086 12 2.46957 12 3V9.5C12 9.77614 11.7761 10 11.5 10C11.2239 10 11 9.77614 11 9.5V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2H2ZM0 11.5C0 11.2239 0.223858 11 0.5 11H11.5C11.7761 11 12 11.2239 12 11.5C12 11.7761 11.7761 12 11.5 12H0.5C0.223858 12 0 11.7761 0 11.5ZM4.5 5C4.22386 5 4 5.22386 4 5.5V9.5C4 9.77614 4.22386 10 4.5 10C4.77614 10 5 9.77614 5 9.5V6H7V9.5C7 9.77614 7.22386 10 7.5 10C7.77614 10 8 9.77614 8 9.5V5.5C8 5.22386 7.77614 5 7.5 5H4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Hospital.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Hospital;
