import { makeNotificationHeaders } from './makeNotificationHeaders'

export const markNotificationRead = async (
  notification,
  onSuccess,
  onFailure
) => {
  const { restUrl } = JSON.parse(sessionStorage.getItem('notificationsConfig'))
  try {
    const response = await fetch(restUrl, {
      headers: makeNotificationHeaders(),
      method: 'POST',
      body: JSON.stringify({
        notifications: [
          {
            ...notification,
            isRead: true
          }
        ]
      })
    })
    const json = await response.json()
    return onSuccess(json)
  } catch (e) {
    return onFailure(e)
  }
}
