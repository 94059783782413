import React from 'react';
import { useTheme } from 'styled-components';
import EmptyMessageWrapper from './EmptyMessageWrapper';
import { Warning } from '../../../BrandCore/icons/informational';

const EmptyMessage = () => {
  const { BaseColors } = useTheme();
  return (
    <EmptyMessageWrapper>
      <Warning size="medium" fillColor={BaseColors.Neutral['50']} />
      <span className="standard-menu-empty-text">There are no results</span>
    </EmptyMessageWrapper>
  );
};

export default EmptyMessage;
