import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { mapIterable } from '../../../commonResources/helpers/iterables';

const buildRowCells = (column, iterationStep, props) => {
  const { supportSelection, emptyGridMessage, columns, theme } = props;
  // mapIterable starts with iterationStep 1
  if (supportSelection && iterationStep === 1)
    return <td key={column.dataName} />;

  return (supportSelection && iterationStep === 2) ||
    (!supportSelection && iterationStep === 1) ? (
    <td
      key={column.dataName}
      style={{ verticalAlign: 'middle' }}
      colSpan={`${columns.size}`}
    >
      <span
        style={{ color: theme.BaseColors.Neutral['100'], fontStyle: 'italic' }}
      >
        {emptyGridMessage || 'No records to display at this time.'}
      </span>
    </td>
  ) : null;
};

buildRowCells.propTypes = {
  columns: PropTypes.instanceOf(Set),
  supportSelection: PropTypes.bool,
  emptyGridMessage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,
};

export const EmptyState = (props) => {
  const {
    dataTestId = null,
    supportSelection,
    disabledColumns,
    columns,
  } = props;
  const theme = useTheme();
  const themedProps = {
    ...props,
    theme,
  };
  // add extra fake columns based on config, one for (optional) support selection
  // and one for settings
  const formattedColumns = new Set(
    supportSelection
      ? [{ dataName: 'select' }, ...columns.values(), { dataName: 'settings' }]
      : [...columns.values(), { dataName: 'settings' }],
  );

  // Delete any disabled columns from our formattedColumns set
  formattedColumns.forEach((column) => {
    if (disabledColumns.has(column.dataName)) formattedColumns.delete(column);
  });

  return (
    <tr className="empty-row" data-testid={dataTestId}>
      {mapIterable(formattedColumns.values(), buildRowCells, themedProps)}
    </tr>
  );
};

EmptyState.propTypes = {
  dataTestId: PropTypes.string,
  columns: PropTypes.instanceOf(Set).isRequired,
  disabledColumns: PropTypes.instanceOf(Set).isRequired,
  supportSelection: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  emptyGridMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default EmptyState;
