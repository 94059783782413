import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Health = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Health'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1.5C8 1.22386 8.22386 1 8.5 1H16.5C16.7761 1 17 1.22386 17 1.5V8H23.5C23.7761 8 24 8.22386 24 8.5V16.5C24 16.7761 23.7761 17 23.5 17H17V23.5C17 23.7761 16.7761 24 16.5 24H8.5C8.22386 24 8 23.7761 8 23.5V17H1.5C1.22386 17 1 16.7761 1 16.5V8.5C1 8.22386 1.22386 8 1.5 8H8V1.5ZM9 2V8.5C9 8.77614 8.77614 9 8.5 9H2V16H8.5C8.77614 16 9 16.2239 9 16.5V23H16V16.5C16 16.2239 16.2239 16 16.5 16H23V9H16.5C16.2239 9 16 8.77614 16 8.5V2H9Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Health'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5 0.5C5 0.223858 5.22386 0 5.5 0H10.5C10.7761 0 11 0.223858 11 0.5V5H15.5C15.7761 5 16 5.22386 16 5.5V10.5C16 10.7761 15.7761 11 15.5 11H11V15.5C11 15.7761 10.7761 16 10.5 16H5.5C5.22386 16 5 15.7761 5 15.5V11H0.5C0.223858 11 0 10.7761 0 10.5V5.5C0 5.22386 0.223858 5 0.5 5H5V0.5ZM6 1V5.5C6 5.77614 5.77614 6 5.5 6H1V10H5.5C5.77614 10 6 10.2239 6 10.5V15H10V10.5C10 10.2239 10.2239 10 10.5 10H15V6H10.5C10.2239 6 10 5.77614 10 5.5V1H6Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Health'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4 0.5C4 0.223858 4.22386 0 4.5 0H7.5C7.77614 0 8 0.223858 8 0.5V4H11.5C11.7761 4 12 4.22386 12 4.5V7.5C12 7.77614 11.7761 8 11.5 8H8V11.5C8 11.7761 7.77614 12 7.5 12H4.5C4.22386 12 4 11.7761 4 11.5V8H0.5C0.223858 8 0 7.77614 0 7.5V4.5C0 4.22386 0.223858 4 0.5 4H4V0.5ZM5 1V4.5C5 4.77614 4.77614 5 4.5 5H1V7H4.5C4.77614 7 5 7.22386 5 7.5V11H7V7.5C7 7.22386 7.22386 7 7.5 7H11V5H7.5C7.22386 5 7 4.77614 7 4.5V1H5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Health.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Health;
