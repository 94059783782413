import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Checkbox from '../../Atoms/Checkbox';
import CheckboxGroupContainer from './components/GroupContainer';
import CheckboxGroupError from './components/GroupError';
import CheckboxGroupLabel from './components/GroupLabel';

const CheckboxGroup = ({
  disabled = false,
  hasError = false,
  dataTestId = '',
  errorMessage = '',
  size = 'medium',
  orientation = 'horizontal',
  label = '',
  items = [],
  onChange = () => false,
}) => {
  return (
    <>
      {label && (
        <CheckboxGroupLabel
          className={clsx({
            error: hasError,
          })}
        >
          {label}
        </CheckboxGroupLabel>
      )}
      <CheckboxGroupContainer
        className={orientation}
        data-testid={`${dataTestId}-checkboxes-container`}
      >
        {items.map((item) => (
          <div key={item.id} className="checkbox-wrapper">
            <Checkbox
              indeterminate={item.indeterminate}
              size={size}
              checked={item.checked}
              disabled={disabled ? true : item.disabled || false}
              label={item.label}
              dataTestId={`${dataTestId}-${item.id}`}
              ariaLabelledBy={item.ariaLabelledBy}
              ariaLabel={item.ariaLabel || item.label}
              onChange={(e, checked) => {
                onChange({
                  item,
                  id: item.id,
                  checked,
                });
              }}
            />
          </div>
        ))}
      </CheckboxGroupContainer>
      {hasError && <CheckboxGroupError>{errorMessage}</CheckboxGroupError>}
    </>
  );
};

CheckboxGroup.propTypes = {
  /** whether all checkboxes under this CheckboxGroup is rendered disabled */
  disabled: PropTypes.bool,
  /** whether this Checkboxgroup is rendered has error. usually clubbed with errorMessage prop */
  hasError: PropTypes.bool,
  /** the error message */
  errorMessage: PropTypes.string,
  /** size of CheckboxGroup */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Orientation of CheckboxGroup */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** label for Checkbox group */
  label: PropTypes.string,
  dataTestId: PropTypes.string,
  /** function to handle event */
  onChange: PropTypes.func,
  /** array of objects where each object can have,
   * -> id: unique identifier for each checkbox
   * -> checked: whether this item is checked on render
   * -> disabled: whether this item is checked on render
   * -> label: label for this Checkbox item
   * -> ariaLabelledBy: a11y compatiblity for this Checkbox
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** unique identifier for each checkbox */
      id: PropTypes.string,
      /** whether this item is checked on render */
      checked: PropTypes.bool,
      /** whether this item is checked on render */
      disabled: PropTypes.bool,
      /** label for this Checkbox item */
      label: PropTypes.string,
      /** a11y compatiblity - id of element that serves purpose of label */
      ariaLabelledBy: PropTypes.string,
      /** a11y compatiblity - label text */
      ariaLabel: PropTypes.string,
      /** indeterminate */

      indeterminate: PropTypes.bool,
    }),
  ),
};

export default CheckboxGroup;
