import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const PopOut = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pop Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4 3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5V19.5C3 20.8807 4.11929 22 5.5 22H21.5C21.7761 22 22 21.7761 22 21.5C22 21.2239 21.7761 21 21.5 21H5.5C4.67157 21 4 20.3284 4 19.5V3.5ZM21.8536 3.14645C21.9015 3.19438 21.9377 3.24964 21.9621 3.30861C21.9862 3.36685 21.9988 3.42873 21.9999 3.49077L22 3.50098V10.5C22 10.7761 21.7761 11 21.5 11C21.2239 11 21 10.7761 21 10.5V4.70711L11.8536 13.8536C11.6583 14.0488 11.3417 14.0488 11.1464 13.8536C10.9512 13.6583 10.9512 13.3417 11.1464 13.1464L20.2929 4H14.5C14.2239 4 14 3.77614 14 3.5C14 3.22386 14.2239 3 14.5 3H21.4999H21.5C21.5678 3 21.6324 3.01349 21.6914 3.03794C21.7504 3.06234 21.8056 3.09851 21.8536 3.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pop Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 1.5C2 1.22386 1.77614 1 1.5 1C1.22386 1 1 1.22386 1 1.5V13.5C1 14.3281 1.67186 15 2.5 15H14.5C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14H2.5C2.22414 14 2 13.7759 2 13.5V1.5ZM14.8536 1.14645C14.9015 1.19439 14.9377 1.24964 14.9621 1.30861C14.9861 1.36669 14.9996 1.4303 15 1.497L15 1.5V1.50049V6.5C15 6.77614 14.7761 7 14.5 7C14.2239 7 14 6.77614 14 6.5V2.70711L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L13.2929 2H9.5C9.22386 2 9 1.77614 9 1.5C9 1.22386 9.22386 1 9.5 1H14.4999H14.5C14.5678 1 14.6324 1.01349 14.6914 1.03794C14.7504 1.06234 14.8056 1.09851 14.8536 1.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Pop Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.4621 1.80861C10.4377 1.74964 10.4015 1.69439 10.3536 1.64645C10.3056 1.59851 10.2504 1.56234 10.1914 1.53794C10.1324 1.51349 10.0678 1.5 10 1.5H9.99991H6C5.72386 1.5 5.5 1.72386 5.5 2C5.5 2.27614 5.72386 2.5 6 2.5H8.79289L4.64645 6.64645C4.45118 6.84171 4.45118 7.15829 4.64645 7.35355C4.84171 7.54882 5.15829 7.54882 5.35355 7.35355L9.5 3.20711V6C9.5 6.27614 9.72386 6.5 10 6.5C10.2761 6.5 10.5 6.27614 10.5 6V2.00049V2C10.5 1.999 10.5 1.998 10.5 1.997C10.4996 1.9303 10.4861 1.86669 10.4621 1.80861ZM1 1.5C1.27614 1.5 1.5 1.72386 1.5 2V10C1.5 10.1326 1.55268 10.2598 1.64645 10.3536C1.74022 10.4473 1.86739 10.5 2 10.5H10C10.2761 10.5 10.5 10.7239 10.5 11C10.5 11.2761 10.2761 11.5 10 11.5H2C1.60217 11.5 1.22064 11.342 0.93934 11.0607C0.658035 10.7794 0.5 10.3978 0.5 10V2C0.5 1.72386 0.723858 1.5 1 1.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

PopOut.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default PopOut;
