import React from 'react';
import PropTypes from 'prop-types';

import ClickableLogoWrapper from './components/ClickableLogoWrapper';
import logoImg from './images/logo.svg';

export const Logo = ({
  domID = null,
  dataTestId = '',
  className = null,
  width = 72,
  height = 24,
  logoURI = logoImg,
  redirectPath = '',
}) => (
  <ClickableLogoWrapper
    href={redirectPath || '/'}
    id={domID}
    data-testid={dataTestId}
    className={className}
  >
    <img
      src={logoURI || logoImg}
      alt="Change Healthcare"
      width={width}
      height={height}
    />
  </ClickableLogoWrapper>
);

Logo.propTypes = {
  className: PropTypes.string,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** height of logo in pixels */
  height: PropTypes.number,
  /** width of logo in pixels */
  width: PropTypes.number,
  /** path which user is redirected to when clicking on the logo */
  redirectPath: PropTypes.string,
  /** logo image path */
  logoURI: PropTypes.string,
};

export default Logo;
