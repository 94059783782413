import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Square0 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.5 3C2.5 2.72386 2.72386 2.5 3 2.5H21C21.2761 2.5 21.5 2.72386 21.5 3V21C21.5 21.2761 21.2761 21.5 21 21.5H3C2.72386 21.5 2.5 21.2761 2.5 21V3ZM3.5 3.5V20.5H20.5V3.5H3.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.5 2C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2H2.5ZM1 2.5C1 1.67157 1.67157 1 2.5 1H13.5C14.3284 1 15 1.67157 15 2.5V13.5C15 14.3284 14.3284 15 13.5 15H2.5C1.67157 15 1 14.3284 1 13.5V2.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 2H2V10H10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Square0.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Square0;
