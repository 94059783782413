import styled from 'styled-components';
import { UnstyledButton } from '../../../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  height: 100%;
  padding: 16px 36px;
  background-color: ${(props) =>
    props.isOpen
      ? props.theme.UIPalette.Colors.Background.StandardMenu
      : props.theme.BaseColors.NeutralAlt['75']};

  &:hover {
    background-color: ${(props) => props.customTheme.hoverBackground};
  }

  &:active {
    background-color: ${(props) => props.customTheme.activeBackground};
    path {
      fill: ${(props) => props.customTheme.activeColor};
    }
  }

  &:focus-visible {
    background-color: ${(props) => props.customTheme.focusBackground};
    outline: none;
    box-shadow: ${(props) =>
      `inset 0 0 0 1px ${props.theme.UIPalette.Colors.Field.Border.Focus}`};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
