import React from 'react';
import PropTypes from 'prop-types';

import Button from './components/Button';
import MenuToggle from './components/MenuToggle';
import MenuToggleWrapper from './components/MenuToggleWrapper';
import SplitSpace from './components/SplitSpace';
import ChildrenWrapper from './components/ChildrenWrapper';
import ListWrapper from './components/ListWrapper';
import Separator from './components/Separator';

import Down from '../../BrandCore/icons/interactive/Down';
import ClickAwayWrapper from '../../commonResources/classes/clickAwayWrapper';
import { VisuallyHiddenText } from '../../commonResources/styles/a11y';
import heights from '../../BrandCore/constants/heights';

import StandardMenu from '../StandardMenu';

const INITIAL_STATE = {
  isMenuOpen: false,
  leftButtonFocused: false,
  rightButtonFocused: false,
  activeMenuItemIndex: null,
  activeSectionIndex: null,
};

const BUTTON_HEIGHT_MAP = {
  small: heights.input.small,
  medium: heights.input.medium,
  large: heights.input.large,
};

export class SplitDropdown extends ClickAwayWrapper {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onClickOut(e) {
    const { onClickOut = () => false } = this.props;
    if (this.state.isMenuOpen === true) {
      this.onToggleMenu(e, false, onClickOut);
    }
  }

  onToggleMenu(e, forceState, callback) {
    const { onToggleMenu = () => false } = this.props;
    if (typeof e.persist === 'function') e.persist();
    this.setState(
      {
        isMenuOpen:
          forceState !== undefined ? forceState : !this.state.isMenuOpen,
      },
      () => {
        onToggleMenu(e, this.state);
        if (callback) callback(e, this.state);
      },
    );
  }

  onSplitLeftButtonClick(e) {
    const { onClick = () => false } = this.props;
    this.onToggleMenu(e, false, onClick);
  }

  onLeftButtonFocus(forceState) {
    this.setState({ leftButtonFocused: forceState });
  }

  onRightButtonFocus(forceState) {
    this.setState({ rightButtonFocused: forceState });
  }

  handleListItemClick(e, state) {
    e.persist();
    const { onMenuClick = () => false } = this.props;
    this.setState(
      {
        isMenuOpen: false,
        activeMenuItemIndex: state.activeMenuItemIndex,
        activeSectionIndex: state.activeSectionIndex,
      },
      () => {
        onMenuClick(e, { ...this.state, activeItem: state.activeItem });
      },
    );
  }

  renderChildrenOrList() {
    const {
      // Button props
      size = 'small',

      // List props
      listID = null,
      listClass = '',
      orientation = 'bottom-left',
      menuItemNames = null,
      menuItems = [],
      sections = [],
      showActiveIndicator = false,
      children = null,
      dataTestId = null,
    } = this.props;

    if (children) {
      return <ChildrenWrapper>{children}</ChildrenWrapper>;
    }
    return (
      <ListWrapper buttonHeight={BUTTON_HEIGHT_MAP[size]}>
        <StandardMenu
          domID={listID}
          className={`${orientation} ${listClass}`}
          menuItemNames={menuItemNames}
          menuItems={menuItems}
          sections={sections}
          onMenuClick={(e, state) => this.handleListItemClick(e, state)}
          onClickOut={(e) => e.preventDefault()}
          showActiveIndicator={showActiveIndicator}
          activeMenuItemIndex={this.state.activeMenuItemIndex}
          activeSectionIndex={this.state.activeSectionIndex}
          dataTestId={dataTestId && `${dataTestId}-standardMenu`}
        />
      </ListWrapper>
    );
  }

  render() {
    const {
      // Button props
      domID = null,
      // className is a deprecated prop
      className = '',
      altTextForToggle = null,
      buttonType = 'standard',
      size = 'small',
      disabled = false,
      name = '',
      dataTestId = null,
    } = this.props;

    const classes = [className];
    if (this.state.isMenuOpen) classes.push('open');
    let containerClass = buttonType;
    if (this.state.leftButtonFocused) {
      containerClass += ' left-focus';
    } else if (this.state.rightButtonFocused) {
      containerClass += ' right-focus';
    }

    if (disabled) {
      containerClass += ' disabled';
    }

    return (
      <SplitSpace
        data-testid={dataTestId}
        id={domID}
        className={`${containerClass} ${size}`}
      >
        <Button
          buttonType={buttonType}
          className={`${classes.join(' ')} split-main-button`}
          name={name}
          onClick={(e) => this.onSplitLeftButtonClick(e)}
          size={size}
          disabled={disabled}
          onFocus={() => this.onLeftButtonFocus(true)}
          onBlur={() => this.onLeftButtonFocus(false)}
          zIndex={this.state.isMenuOpen ? '1' : '2'}
          dataTestId={dataTestId && `${dataTestId}-button-${name}`}
        />
        <MenuToggleWrapper zIndex={this.state.leftButtonFocused ? '1' : '2'}>
          <MenuToggle
            dataTestId={dataTestId && `${dataTestId}-button-toggle`}
            buttonType={buttonType}
            className={`${classes.join(' ')} arrow-button`}
            onClick={(e) => this.onToggleMenu(e)}
            size={size}
            disabled={disabled}
            onFocus={() => this.onRightButtonFocus(true)}
            onBlur={() => this.onRightButtonFocus(false)}
            zIndex={this.state.isMenuOpen ? '1' : 'auto'}
          >
            <>
              <Separator className="separator" />
              <div className="dropdown-caret">
                <VisuallyHiddenText>{altTextForToggle}</VisuallyHiddenText>
                <Down size="small" />
              </div>
            </>
          </MenuToggle>
        </MenuToggleWrapper>
        {this.state.isMenuOpen ? this.renderChildrenOrList() : null}
      </SplitSpace>
    );
  }
}

export const splitDropdownPropTypes = {
  // Button props
  name: PropTypes.node.isRequired,
  /** fires when button is clicked
   *  @param {SyntheticEvent} e
   *  @param {Object} state ('state' name only for JSDoc object structure)
   *  @param {Number} state.activeMenuItemIndex
   *  @param {Number} state.activeSectionIndex
   *  @param {Boolean} state.isMenuOpen
   *  @param {Boolean} state.leftButtonFocused
   *  @param {Boolean} state.rightButtonFocused
   */
  onClick: PropTypes.func.isRequired,
  /** fires when dropdown is opened or closed. see onClick for parameters */
  onToggleMenu: PropTypes.func,
  /** see onClick for parameters */
  onClickOut: PropTypes.func,
  /** fires when a dropdown item is clicked
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Object} state.activeItem
   * @prop {Number} state.activeItem.id
   * @prop {String} state.activeItem.label
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   * @prop {Boolean} state.leftButtonFocused
   * @prop {Boolean} state.rightButtonFocused
   */
  onMenuClick: PropTypes.func,
  altTextForToggle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'standard',
    'destroy',
    'emphasized',
    'emphasizedAlt',
    'deEmphasized',
    'deEmphasizedReversed',
    'diminished',
    'unstyled',
  ]),
  domID: PropTypes.string,
  className: PropTypes.string,

  // List props
  listID: PropTypes.string,
  listClass: PropTypes.string,
  orientation: PropTypes.oneOf([
    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
  ]),
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string.isRequired,
        path: PropTypes.string,
        labelDecoration: PropTypes.string,
        isSecondary: PropTypes.bool,
      }),
    ),
  ),
  showActiveIndicator: PropTypes.bool,
  children: PropTypes.node,
};

SplitDropdown.propTypes = splitDropdownPropTypes;

export default SplitDropdown;
