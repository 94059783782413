import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Products = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Products'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.2428 1.06304C12.0918 0.979148 11.9082 0.979148 11.7572 1.06304L2.75718 6.06304C2.59845 6.15123 2.5 6.31854 2.5 6.50012V17.5001C2.5 17.6817 2.59845 17.849 2.75718 17.9372L11.7572 22.9372C11.9082 23.0211 12.0918 23.0211 12.2428 22.9372L21.2428 17.9372C21.4016 17.849 21.5 17.6817 21.5 17.5001V6.50012C21.5 6.31854 21.4016 6.15123 21.2428 6.06304L12.2428 1.06304ZM3.5 7.40414V17.2059L11.5 21.6503V21.6407V11.796L3.5 7.40414ZM12.5 21.6504L20.5 17.2059V7.38729L12.5 11.7955V21.6407V21.6504ZM20.0041 6.5188L12 2.0721L3.98071 6.52726L11.9995 10.9295L20.0041 6.5188Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Products'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#products-medium)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M8.24986 0.0669072C8.09523 -0.0223024 7.90477 -0.0223024 7.75014 0.0669072L1.25751 3.81266C1.18064 3.85518 1.114 3.91851 1.0669 4.00015C1.01978 4.08181 0.998318 4.17124 1 4.2591V11.75C1 11.9287 1.09536 12.0938 1.25014 12.1831L7.74214 15.9285C7.81743 15.9739 7.90566 16 7.99999 16C8.09417 16 8.18227 15.974 8.25747 15.9287L14.7499 12.1831C14.9046 12.0938 15 11.9287 15 11.75V4.25825C15.0014 4.17844 14.9837 4.09736 14.945 4.022C14.9378 4.00794 14.9299 3.99422 14.9214 3.98091C14.8753 3.90864 14.8134 3.85198 14.7428 3.81286L8.24986 0.0669072ZM13.4994 4.25L8 1.07724L2.50054 4.25001L7.99999 7.42276L13.4994 4.25ZM2 5.11572V11.4612L7.49999 14.6343V8.28879L2 5.11572ZM8.49999 14.6343L14 11.4612V5.11571L8.49999 8.28879V14.6343Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="products-medium">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Products'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#report-products)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M6.24282 0.0629214C6.09181 -0.0209738 5.90819 -0.0209738 5.75718 0.0629214L1.264 2.55913C1.18207 2.60289 1.11138 2.66995 1.06292 2.75718C1.01854 2.83707 0.998368 2.92387 1 3.00911V9C1 9.18158 1.09845 9.34889 1.25718 9.43708L5.74923 11.9327C5.82242 11.9752 5.90738 11.9996 5.99804 12M6.02071 11.9996C6.10432 11.9962 6.18263 11.9722 6.25075 11.9327L10.7428 9.43708C10.9016 9.34889 11 9.18158 11 9V3.00905C11.0016 2.92383 10.9815 2.83705 10.9371 2.75718C10.9367 2.75657 10.9364 2.75595 10.9361 2.75534C10.8876 2.66902 10.8174 2.6026 10.736 2.55915L6.24282 0.0629214M9.47044 3L6 1.07198L2.52956 3L6 4.92802L9.47044 3ZM10 3.84976L6.5 5.7942V10.6502L10 8.7058V3.84976ZM2 3.84976V8.7058L5.5 10.6502V5.7942L2 3.84976ZM6.02071 11.9996C6.01381 11.9999 6.0069 12 6 12Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="report-products">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Products.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Products;
