import styled from 'styled-components';
import StackingContext from '../../../commonResources/stackingContext';

export default styled.div`
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Background.StandardMenu};
  ${({ theme }) => theme.BoxShadows.Depth15};
  padding: 12px 0;
  padding-top: ${(props) => (props.hasSearchBar ? 0 : 12)}px;
  padding-bottom: ${(props) => (props.hasFooter ? 0 : 12)}px;
  border-radius: 8px;
  display: block;
  position: absolute;
  white-space: nowrap;
  color: ${({ theme }) => theme.BaseColors.Neutral['100']};
  ${StackingContext.dropdownMain};
  max-width: ${(props) => props.maxWidth};

  &.small,
  &.medium,
  &.large {
    min-width: 0px;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
  }

  &.open-direction {
    right: 0px;
    padding-top: 0.5rem;
  }
`;
