import styled from 'styled-components';
import { borderHelpers } from '../../../BrandCore/shapes/borders';

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.BoxShadows.Depth5};
  ${borderHelpers.borderRadius8};
  padding: 16px;
  background-color: ${({ theme }) => theme.UIPalette.Colors.Background.Depth5};
  cursor: pointer;
  width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;

  &:hover {
    ${(props) => props.theme.BoxShadows.Depth10};
  }
`;

export default NotificationWrapper;
