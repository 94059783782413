import WhiteLabelUIPalette from './WhiteLabelUIPalette';
import {
  primary,
  secondary,
  tertiary,
  quaternary,
  negative,
  caution,
  positive,
  neutralAlt,
  accent,
  neutral,
  white,
  black,
} from './whiteLabelBasePalette';
import { BoxShadows } from '../base/boxShadows';

const WhiteLabelTheme = {
  BaseColors: {
    Primary: primary,
    Secondary: secondary,
    Tertiary: tertiary,
    Quaternary: quaternary,
    Negative: negative,
    Caution: caution,
    Positive: positive,
    NeutralAlt: neutralAlt,
    Accent: accent,
    Neutral: neutral,
    White: white,
    TrueWhite: '#FFF',
    Black: black,
  },
  UIPalette: WhiteLabelUIPalette,
  BoxShadows,
};

// eslint-disable-next-line import/prefer-default-export
export { WhiteLabelTheme };
