import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Copy = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Copy'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.5 2C2.67157 2 2 2.67157 2 3.5V15.5C2 16.3284 2.67157 17 3.5 17H8V21.5C8 22.3284 8.67157 23 9.5 23H21.5C22.3284 23 23 22.3284 23 21.5V9.5C23 8.67157 22.3284 8 21.5 8H17V3.5C17 2.67157 16.3284 2 15.5 2H3.5ZM16 8.5V3.5C16 3.22386 15.7761 3 15.5 3H3.5C3.22386 3 3 3.22386 3 3.5V15.5C3 15.7761 3.22386 16 3.5 16H8.5H15.5C15.7761 16 16 15.7761 16 15.5V8.5ZM9 17H15.5C16.3284 17 17 16.3284 17 15.5V9H21.5C21.7761 9 22 9.22386 22 9.5V21.5C22 21.7761 21.7761 22 21.5 22H9.5C9.22386 22 9 21.7761 9 21.5V17Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Copy'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C0.671573 0 0 0.671572 0 1.5V10.5C0 11.3284 0.671572 12 1.5 12H10.5C11.3284 12 12 11.3284 12 10.5V1.5C12 0.671573 11.3284 0 10.5 0H1.5ZM1 1.5C1 1.22386 1.22386 1 1.5 1H10.5C10.7761 1 11 1.22386 11 1.5V10.5C11 10.7761 10.7761 11 10.5 11H1.5C1.22386 11 1 10.7761 1 10.5V1.5ZM13.5 4C13.2239 4 13 4.22386 13 4.5C13 4.77614 13.2239 5 13.5 5H14.5C14.7761 5 15 5.22386 15 5.5V14.5C15 14.7761 14.7761 15 14.5 15H5.5C5.22386 15 5 14.7761 5 14.5V13.5C5 13.2239 4.77614 13 4.5 13C4.22386 13 4 13.2239 4 13.5V14.5C4 15.3284 4.67157 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4H13.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Copy'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C0.671573 0 0 0.671573 0 1.5V7.5C0 8.32843 0.671573 9 1.5 9H3V10.5C3 11.3284 3.67157 12 4.5 12H10.5C11.3284 12 12 11.3284 12 10.5V4.5C12 3.67157 11.3284 3 10.5 3H9V1.5C9 0.671573 8.32843 0 7.5 0H1.5ZM8 3.5V1.5C8 1.22386 7.77614 1 7.5 1H1.5C1.22386 1 1 1.22386 1 1.5V7.5C1 7.77614 1.22386 8 1.5 8H3.5H7.5C7.77614 8 8 7.77614 8 7.5V3.5ZM4 9H7.5C8.32843 9 9 8.32843 9 7.5V4H10.5C10.7761 4 11 4.22386 11 4.5V10.5C11 10.7761 10.7761 11 10.5 11H4.5C4.22386 11 4 10.7761 4 10.5V9Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Copy.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Copy;
