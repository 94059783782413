import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const MainGrid = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Main Grid'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.96667 4C1.40528 4 1 4.42279 1 4.88462V20.1154C1 20.5772 1.40528 21 1.96667 21H5V4H1.96667ZM6 4V21H21.0333C21.5947 21 22 20.5772 22 20.1154V4.88462C22 4.42279 21.5947 4 21.0333 4H6ZM1.96667 22H5.5H21.0333C22.092 22 23 21.183 23 20.1154V4.88462C23 3.81704 22.092 3 21.0333 3H5.5H1.96667C0.908017 3 0 3.81704 0 4.88462V20.1154C0 21.183 0.908017 22 1.96667 22ZM10 12C9.72386 12 9.5 12.2239 9.5 12.5C9.5 12.7761 9.72386 13 10 13H18C18.2761 13 18.5 12.7761 18.5 12.5C18.5 12.2239 18.2761 12 18 12H10ZM9.5 8.5C9.5 8.22386 9.72386 8 10 8H18C18.2761 8 18.5 8.22386 18.5 8.5C18.5 8.77614 18.2761 9 18 9H10C9.72386 9 9.5 8.77614 9.5 8.5ZM10 16C9.72386 16 9.5 16.2239 9.5 16.5C9.5 16.7761 9.72386 17 10 17H18C18.2761 17 18.5 16.7761 18.5 16.5C18.5 16.2239 18.2761 16 18 16H10Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Main Grid'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 2C1.22386 2 1 2.22386 1 2.5V13.5C1 13.7761 1.22386 14 1.5 14H4V2H1.5ZM5 2V14H14.5C14.7761 14 15 13.7761 15 13.5V2.5C15 2.22386 14.7761 2 14.5 2H5ZM1.5 15H4.5H14.5C15.3284 15 16 14.3284 16 13.5V2.5C16 1.67157 15.3284 1 14.5 1H4.5H1.5C0.671573 1 0 1.67157 0 2.5V13.5C0 14.3284 0.671573 15 1.5 15ZM8 7.5C7.72386 7.5 7.5 7.72386 7.5 8C7.5 8.27614 7.72386 8.5 8 8.5H12C12.2761 8.5 12.5 8.27614 12.5 8C12.5 7.72386 12.2761 7.5 12 7.5H8ZM7.5 5C7.5 4.72386 7.72386 4.5 8 4.5H12C12.2761 4.5 12.5 4.72386 12.5 5C12.5 5.27614 12.2761 5.5 12 5.5H8C7.72386 5.5 7.5 5.27614 7.5 5ZM8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5H12C12.2761 11.5 12.5 11.2761 12.5 11C12.5 10.7239 12.2761 10.5 12 10.5H8Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Main Grid'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.19231C1 2.11283 1.07696 2 1.23333 2H3V10H1.23333C1.07696 10 1 9.88717 1 9.80769V2.19231ZM4 10V2H10.7667C10.923 2 11 2.11283 11 2.19231V9.80769C11 9.88717 10.923 10 10.7667 10H4ZM3.5 11H1.23333C0.579692 11 0 10.4929 0 9.80769V2.19231C0 1.50708 0.579692 1 1.23333 1H3.5H10.7667C11.4203 1 12 1.50708 12 2.19231V9.80769C12 10.4929 11.4203 11 10.7667 11H3.5ZM5.5 7.5C5.5 7.22386 5.72386 7 6 7H9C9.27614 7 9.5 7.22386 9.5 7.5C9.5 7.77614 9.27614 8 9 8H6C5.72386 8 5.5 7.77614 5.5 7.5ZM6 4C5.72386 4 5.5 4.22386 5.5 4.5C5.5 4.77614 5.72386 5 6 5H9C9.27614 5 9.5 4.77614 9.5 4.5C9.5 4.22386 9.27614 4 9 4H6Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

MainGrid.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default MainGrid;
