import styled from 'styled-components';

export default styled.span`
  background-color: ${(props) => props.theme.BaseColors.NeutralAlt['75']};
  .inline-menu-dropdown {
    width: 280px;
    background-color: ${(props) => props.theme.BaseColors.White};
    max-height: 80vh;
    overflow-y: auto;
    ${(props) => props.theme.BoxShadows.Depth15}
    border-radius: 0px 8px 8px 8px;
  }

  .menu-expand-footer {
    color: ${(props) => props.customTheme.openColor};
    display: flex;
    align-items: center;
    .dock-left-icon {
      margin-right: 10px;
    }
  }
`;
