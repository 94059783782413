import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectDropdown, {
  selectDropdownV2PropTypes,
} from '../../../Atoms/SelectDropdown';
import VerticallyCentered from './styleWrappers/VerticallyCentered';

const SelectDropdownWrapper = styled(VerticallyCentered)`
  width: ${(props) => props.width}px;
  > * {
    width: 100%;
  }
`;

const SelectDropdownCell = ({ data: { width = 130, ...otherData } }) => (
  <SelectDropdownWrapper width={width}>
    <SelectDropdown {...otherData} size="small" />
  </SelectDropdownWrapper>
);

SelectDropdownCell.propTypes = {
  data: PropTypes.shape({
    ...selectDropdownV2PropTypes,
    width: PropTypes.number,
  }).isRequired,
};

export default SelectDropdownCell;
