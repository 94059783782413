import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GridContainer from 'react-grid-system/build/grid/Container';
import GridRow from 'react-grid-system/build/grid/Row';
import GridCol from 'react-grid-system/build/grid/Col';
import GridHidden from 'react-grid-system/build/utilities/Hidden';
import GridVisible from 'react-grid-system/build/utilities/Visible';
import GridScreenClassRender from 'react-grid-system/build/utilities/ScreenClassRender';
import GridScreenClassProvider from 'react-grid-system/build/context/ScreenClassProvider';
import padding from '../../commonResources/padding';

const RowWithGutter = ({ children, ...props }) => (
  <GridRow
    gutterWidth={
      // basically we're getting rems here
      1.25 * parseFloat(getComputedStyle(document.documentElement).fontSize)
    }
    {...props}
  >
    {children}
  </GridRow>
);
RowWithGutter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

const RowToExport = styled(RowWithGutter)`
  margin: 0;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const ContainerToExport = ({
  children,
  style,
  leftPadding = padding.detailPaneHeader.left,
  ...props
}) => (
  <GridContainer
    style={{
      padding: `20px ${leftPadding}px`,
      maxWidth: '100%',
      ...style,
    }}
    {...props}
  >
    {children}
  </GridContainer>
);
ContainerToExport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * Content Grid should have different padding based on where its used.
   *
   * By default padding is set to `padding.detailPaneHeader.left` which is the padding
   * that should be used when this component is in Detail Pane.
   *
   * Use `padding.listPaneHedaer.left` or `padding.navigationPaneHeader.left` if you are using this
   * component in ListPane or Navigation respectively.
   */
  leftPadding: PropTypes.number,
};

export const Row = RowToExport;
export const Container = ContainerToExport;
export const Col = GridCol;
export const Hidden = GridHidden;
export const ScreenClassRender = GridScreenClassRender;
export const Visible = GridVisible;
export const ScreenClassProvider = GridScreenClassProvider;

export default {
  Container,
  Row,
  Col,
  Hidden,
  ScreenClassRender,
  Visible,
  ScreenClassProvider,
};
