import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  /** By default apply styles of medium **/
  > * {
    margin: 0 12px 12px 0;
  }

  > *.small {
    margin: 0 8px 8px 0;
  }
  > *.medium {
    margin: 0 12px 12px 0;
  }
  > *.large {
    margin: 0 16px 16px 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

export default ButtonGroup;
