import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import borders from '../../../BrandCore/shapes/borders';

export default styled.div`
  overflow: auto;
  border-bottom: 1px solid ${(props) => props.theme.BaseColors.Neutral['10']};

  &.fixed-header {
    max-height: ${(props) => props.maxHeight};
  }

  .sortable {
    position: relative;
  }
  .settings-trigger {
    width: 20px;
  }

  .configurable {
    tr {
      th:last-child {
        .sortable {
          padding-right: 24px !important;
        }
      }
      td:last-child {
        padding-right: 24px !important;
      }
    }
  }

  &.nested {
    > table {
      th button.sortable {
        border-radius: 0;
      }
      th:first-child button.sortable {
        border-top-left-radius: 8px;
      }
      th:last-child button.sortable {
        border-top-right-radius: 8px;
      }
    }
  }

  .settings-trigger {
    margin-right: unset;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    ${TextStyles.small};

    thead {
      background: ${(props) => props.theme.UIPalette.Colors.Background.Depth5};
      ${(props) => props.theme.UIPalette.BoxShadows.General.LineBelow}

      th.selection-column {
        :focus-visible {
          ${borders.borderRadius4};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          outline: none;
          box-shadow: inset 0 0 0 1px
            ${(props) => props.theme.BaseColors.Primary['100']};
        }

        &:focus-within {
          .table-select-all-checkbox {
            visibility: visible;
          }
          box-sizing: border-box;
        }
      }

      &:hover th.selection-column > * {
        visibility: visible;
      }

      &:hover th.selection-column.hidden > * {
        visibility: hidden;
      }

      th.selection-column.hidden > * {
        visibility: hidden;
      }

      th {
        background: ${(props) =>
          props.theme.UIPalette.Colors.Background.Depth5};
        padding: 0;

        &.selection-column {
          padding-left: 17px;
          width: 40px;
        }

        &.selection-column.radio {
          border-right: 0;
        }

        .select-all-toggle {
          border: none;
        }

        &:first-child:not(.selection-column) > * {
          padding-left: 24px;
        }
        &:last-child > * {
          position: relative;
        }
      }
    }

    tbody tr {
      &.emboldened {
        font-weight: 600;
      }

      &:nth-child(even) {
        background-color: ${(props) => props.theme.BaseColors.Neutral['0']};
      }
      &:nth-child(odd) {
        background-color: ${(props) => props.theme.BaseColors.White};
      }

      /* clickable rows */
      &[role='button'] {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      &.selectable,
      &[role='button'] {
        &:hover:not(.selected) {
          background-color: ${(props) => props.theme.BaseColors.Neutral['10']};
          td.select-checkbox > * {
            visibility: visible;
          }

          td.select-checkbox.hidden > * {
            visibility: hidden;
          }
        }

        &:active {
          background-color: ${(props) => props.theme.BaseColors.Neutral['15']};
          outline: none;
        }

        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 1px
            ${(props) => props.theme.BaseColors.Primary['100']};
          td.select-checkbox > * {
            visibility: visible;
          }

          td.select-checkbox.hidden > * {
            visibility: hidden;
          }
        }
        &:focus-within {
          td.select-checkbox > * {
            visibility: visible;
          }
          box-sizing: border-box;
        }
      }

      .select-checkbox > * {
        visibility: hidden;
      }

      &.selected {
        background-color: ${(props) => props.theme.BaseColors.Primary['30']};

        > td div {
          color: ${(props) => props.theme.BaseColors.Primary['115']};
        }

        .select-checkbox > * {
          visibility: visible;
        }

        .select-checkbox.hidden > * {
          visibility: hidden;
        }
      }

      > td {
        height: 36px;
        white-space: initial;
        vertical-align: top;
        padding: 0px 8px;

        &:last-child {
          padding-right: 24px;
        }
        &:first-child:not(.select-checkbox) {
          padding-left: 24px;
        }
      }
    }

    .table-wrapper .checkbox-group {
      margin-bottom: 1em;
    }

    .select-checkbox {
      padding: 11px 5px 0px 17px;
      width: 37px;
    }
  }

  .align-left {
    .cell-component-wrapper > * {
      align-self: flex-start;
    }
  }
  .align-center {
    .cell-component-wrapper > * {
      align-self: center;
    }
  }
  .align-right {
    flex-direction: row-reverse;
    justify-content: unset !important;

    .cell-component-wrapper > * {
      align-self: flex-end;
    }
  }
`;
