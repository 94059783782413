import styled from 'styled-components';

const CheckboxGroupContainer = styled.div`
  display: flex;
  &.horizontal {
    flex-direction: row;
    .checkbox-wrapper {
      margin-right: 16px;
    }
  }
  &.vertical {
    align-items: start;
    flex-direction: column;
    .checkbox-wrapper {
      margin-bottom: 6px;
    }
  }
`;

export default CheckboxGroupContainer;
