import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import borders from '../../../BrandCore/shapes/borders';
import heights from '../../../BrandCore/constants/heights';

export default styled.div`
  position: relative;

  .selectDropdown__control {
    ${(props) => props.theme.UIPalette.BoxShadows.Field.Outer}
    ${(props) => props.theme.UIPalette.BoxShadows.Field.Default}
  }
  .selectDropdown__container,
  .selectDropdown__control {
    ${borders.borderRadius4};
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Field.Background.Default};
  }

  .selectDropdown__indicators svg {
    height: 12px;
    width: 12px;
  }

  &.small {
    .selectDropdown__placeholder {
      ${TextStyles.xSmall};
      color: ${(props) => props.theme.UIPalette.Colors.Content.Placeholder};
      top: 46%;
    }

    .selectDropdown__container,
    .selectDropdown__control {
      height: ${heights.input.small}px;
      ${borders.borderRadius4};
    }
    .selectDropdown__value-container,
    .selectDropdown__indicators {
      height: ${heights.input.small}px;
    }

    .selectDropdown__single-value {
      top: 46%;
      ${TextStyles.xSmall};
    }

    .selectDropdown__clear-indicator {
      display: block;
    }

    .selectDropdown__indicator {
      display: block;
    }

    .selectDropdown__clear-indicator svg {
      height: 12px;
      width: 12px;
    }

    .selectDropdown__indicator-separator {
      height: 16px;
    }
  }

  &.medium {
    .selectDropdown__placeholder {
      ${TextStyles.small};
      color: ${(props) => props.theme.UIPalette.Colors.Content.Placeholder};
    }

    .selectDropdown__container,
    .selectDropdown__control {
      height: ${heights.input.medium}px;
      ${borders.borderRadius6};
    }
    .selectDropdown__value-container,
    .selectDropdown__indicators {
      height: ${heights.input.medium}px;
    }

    .selectDropdown__clear-indicator svg {
      height: 16px;
      width: 16px;
    }

    .selectDropdown__indicator-separator {
      height: 24px;
      margin: 6px 0;
    }

    .selectDropdown__single-value {
      ${TextStyles.small};
    }
  }

  &.large {
    .selectDropdown__placeholder {
      ${TextStyles.medium};
      color: ${(props) => props.theme.UIPalette.Colors.Content.Placeholder};
    }

    .selectDropdown__container,
    .selectDropdown__control {
      height: ${heights.input.large}px;
      ${borders.borderRadius8};
    }
    .selectDropdown__value-container,
    .selectDropdown__indicators {
      height: ${heights.input.large}px;
    }

    .selectDropdown__clear-indicator svg {
      height: 16px;
      width: 16px;
    }

    .selectDropdown__indicator-separator {
      height: 24px;
      margin: 9px 0;
    }

    .selectDropdown__single-value {
      ${TextStyles.medium};
    }
  }

  &:hover {
    .selectDropdown__control {
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Hover}
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Outer}
    }
  }

  &.focused {
    .selectDropdown__control {
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Focus}
    }
  }

  &.opened {
    .selectDropdown__control {
      background: ${(props) => props.theme.BaseColors.Neutral['5']};
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Outer}
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Default}
    }
  }

  .selectDropdown__control:active {
    background: ${(props) => props.theme.BaseColors.Neutral['5']};
    ${(props) => props.theme.UIPalette.BoxShadows.Field.Outer};
    ${(props) => props.theme.UIPalette.BoxShadows.Field.Hover};
  }

  &.disabled {
    .selectDropdown__control {
      background: ${(props) => props.theme.BaseColors.Neutral['5']};
      box-shadow: none !important;
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Disabled};
    }
    .selectDropdown__dropdown-indicator path {
      fill: ${(props) => props.theme.UIPalette.Colors.Content.Disabled};
    }
  }

  &.has-error {
    label {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Error};
    }

    .selectDropdown__control {
      border: 1px solid ${(props) => props.theme.UIPalette.Colors.Content.Error};
      box-sizing: border-box;
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Outer}
    }

    .error-message {
      margin-top: 3px;
    }
  }
`;
