import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.span`
  display: inline-block;
  margin-left: 0.8em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  border: 1px solid ${(props) => props.theme.BaseColors.Neutral['70']};
  line-height: 1.8em;
  ${TextStyles.xxSmall};
  ${TextStyles.bold};
  text-transform: uppercase;
`;
