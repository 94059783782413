import styled, { css } from 'styled-components';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useClickAway } from '../../../commonResources/hooks/useClickAway';
import { MODAL_ANIMATION } from '../../../BrandCore/constants/timings';
import Borders from '../../../BrandCore/shapes/borders';

const grid = {
  advancedColumns: '265px 1fr',
  advancedRows: '1fr',
  columnns: '1fr',
  rows: 'auto 1fr auto',
};

// stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix
const advanced = css`
  grid-template-columns: ${grid.advancedColumns};
  -ms-grid-columns: ${grid.advancedColumns};

  grid-template-rows: ${grid.advancedRows};
  -ms-grid-rows: ${grid.advancedRows};

  grid-template-areas: 'left right';
`;

const ModalContentStyle = styled.div`
  display: grid;
  display: -ms-grid; /* stylelint-disable-line declaration-block-no-duplicate-properties */
  grid-template-columns: ${grid.columnns};
  -ms-grid-columns: ${grid.columnns};

  grid-template-rows: ${grid.rows};
  -ms-grid-rows: ${grid.rows};

  grid-template-areas:
    'header'
    'body'
    'footer';
  ${(props) => props.isAdvanced && advanced};

  position: relative;
  width: ${(props) => (props.isAdvanced ? 1065 : 800)}px;
  margin: 5em auto;
  background-color: ${({ theme }) => theme.UIPalette.Colors.Background.Depth15};
  max-height: 80vh;
  height: 80vh;
  @supports (display: grid) {
    height: auto;
  }
  ${(props) => props.theme.BoxShadows.Depth15}
  ${Borders.borderRadius12};
  transform-style: preserve-3d;
  transition: transform ${MODAL_ANIMATION}ms;
  transform: translateX(0px) translateY(0px) translateZ(0px);

  &.active-content {
    transform: translateX(0px) translateY(15px) translateZ(0px);
  }
`;

export const ModalContent = ({
  dataTestId = null,
  onClickOut = () => false,
  handleClose = () => false,
  children = [],
  ...rest
}) => {
  const ref = useRef(null);
  useClickAway(ref, (e) => {
    onClickOut(e);
  });

  return (
    <ModalContentStyle data-testid={dataTestId} ref={ref} {...rest}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.type.displayName === 'ModalHeader') {
            return React.cloneElement(child, { handleClose });
          }
        }
        return child;
      })}
    </ModalContentStyle>
  );
};

ModalContent.propTypes = {
  dataTestId: PropTypes.string,
  onClickOut: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ModalContent;
