import PropTypes from 'prop-types';
import React from 'react';
/* Atoms */
import Button from '../../../Atoms/Button';
import ButtonGroup from '../../../Molecules/ButtonGroup';

const ProfileFooter = ({
  onCancel = () => false,
  onSave = () => false,
  isFormValid = false,
  handleChangePassword = () => false,
}) => {
  return (
    <>
      <ButtonGroup>
        <Button
          name="Cancel"
          buttonType="diminished"
          onClick={onCancel}
          size="medium"
        />
        <Button
          size="large"
          name="Change Password"
          buttonType="deEmphasized"
          onClick={handleChangePassword}
        />
        <Button
          size="large"
          name="Save"
          buttonType="emphasized"
          disabled={!isFormValid}
          onClick={onSave}
        />
      </ButtonGroup>
      <ButtonGroup />
    </>
  );
};

ProfileFooter.propTypes = {
  isFormValid: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  handleChangePassword: PropTypes.func,
};

export default ProfileFooter;
