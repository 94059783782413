import { makeNotificationHeaders } from './makeNotificationHeaders'

export const getNotifications = async (onSuccess, onFailure) => {
  const { restUrl } = JSON.parse(sessionStorage.getItem('notificationsConfig'))
  try {
    const response = await fetch(restUrl, {
      headers: makeNotificationHeaders()
    })
    const sessionKey = await response.headers.get('X-Li-Session-Key')
    sessionStorage.setItem('notificationsSessionKey', sessionKey)
    const json = await response.json()
    return onSuccess(json)
  } catch (e) {
    return onFailure(e)
  }
}
