import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbarsComponent from './OverlayScrollbar';

import StyledNavigationPane from './NavigationPane';

import { NAVIGATION_PANE_WIDTH } from '../../../BrandCore/constants/widths';
import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';
import { NAVIGATION_PANE_EXPAND_COLLAPSE } from '../../../BrandCore/constants/timings';

const ScrollPositioner = styled.div`
  width: ${NAVIGATION_PANE_WIDTH}px;
  pointer-events: none;
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);

  .os-scrollbar {
    pointer-events: auto;
  }

  .os-scrollbar-vertical {
    z-index: 3;
    ${(props) => props.collapsed && 'display: none;'}
  }
`;

const NavigationPaneContent = styled.div`
  padding-left: ${(props) => props.paddingLeft};
  width: calc(${NAVIGATION_PANE_WIDTH}px - 1.25rem);
  pointer-events: auto;
`;

const duration = NAVIGATION_PANE_EXPAND_COLLAPSE;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
  width: '40px',
};

const transitionStyles = {
  entering: { width: `${NAVIGATION_PANE_WIDTH}px` },
  entered: { width: `${NAVIGATION_PANE_WIDTH}px` },
};

const ExpandCollapseTransition = ({
  onIn,
  children,
  onEntered,
  onExited,
  isSidebarVisible = false,
  hideScroller = false,
  dataTestId = '',
  className = '',
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const nodeRef = React.useRef(null);

  return (
    <Transition
      in={onIn}
      timeout={duration}
      onEntered={onEntered}
      onExited={onExited}
      nodeRef={nodeRef}
    >
      {(state) => (
        <StyledNavigationPane
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={`${state} ${className}`}
          isSidebarVisible={isSidebarVisible}
          data-testid={`${dataTestId}-container`}
        >
          {/* custom scrollbar and wrappers for allowing horizontal overflow */}
          <ScrollPositioner collapsed={hideScroller}>
            <div style={{ pointerEvents: isHovering ? 'auto' : 'none' }}>
              <OverlayScrollbarsComponent
                options={{
                  clipAlways: false,
                  overflowBehavior: {
                    y: 'scroll',
                    x: 'visible-scroll',
                  },
                }}
              >
                <div
                  style={{
                    width: `${NAVIGATION_PANE_WIDTH}px`,
                    height: `calc(100vh - ${MASTHEAD_HEIGHT}px)`,
                    overflow: 'visible',
                    pointerEvents: 'none',
                  }}
                >
                  <NavigationPaneContent
                    data-testid={`${dataTestId}-navigation-pane-content`}
                    onMouseLeave={() => {
                      setIsHovering(false);
                    }}
                    onMouseEnter={() => {
                      setIsHovering(true);
                    }}
                  >
                    {children}
                  </NavigationPaneContent>
                </div>
              </OverlayScrollbarsComponent>
            </div>
          </ScrollPositioner>
        </StyledNavigationPane>
      )}
    </Transition>
  );
};

ExpandCollapseTransition.propTypes = {
  isSidebarVisible: PropTypes.bool,
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onEntered: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  hideScroller: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

export default ExpandCollapseTransition;
