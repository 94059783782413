import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const Link = styled.a`
  ${TextStyles.smallLink};
  text-align: ${(props) => props.align || 'left'};
`;

const FirstLine = styled.div`
  margin-top: 7px;
`;

const LinkCell = ({
  data: {
    href = '',
    target = '_self',
    domID = '',
    text = '',
    secondLine = null,
  },
  cellAlign = 'left',
}) => (
  <>
    <FirstLine>
      <Link target={target} href={href} id={domID} align={cellAlign}>
        {text}
      </Link>
    </FirstLine>
    {secondLine && (
      <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
    )}
  </>
);

LinkCell.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    domID: PropTypes.string,
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default LinkCell;
