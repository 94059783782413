import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

const CollapsedTitle = styled.div`
  ${TextStyles.xSmallBold}
  line-height: 110%;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding: 10px 0 24px 0;
`;

export default CollapsedTitle;
