import styled from 'styled-components';

export default styled.div`
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .tooltip-wrapper {
    margin-left: 4px;
  }
`;
