import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Message = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Message'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 10.5C1 5.13443 6.2227 1 12.5 1C18.7773 1 24 5.13443 24 10.5C24 12.7787 22.9987 14.8348 21.424 16.4794L21.9967 21.4427C22.017 21.6182 21.9431 21.7913 21.8025 21.8981C21.6618 22.005 21.4752 22.0297 21.3116 21.9631L15.5723 19.6285C14.5691 19.908 13.57 20 12.5 20C6.2227 20 1 15.8656 1 10.5ZM12.5 2C6.5773 2 2 5.86557 2 10.5C2 15.1344 6.5773 19 12.5 19C13.5691 19 14.5174 18.9028 15.4563 18.6211C15.5656 18.5883 15.6827 18.5939 15.7884 18.6369L20.9065 20.7188L20.4033 16.3573C20.3858 16.2057 20.4385 16.0544 20.5464 15.9464C22.0739 14.419 23 12.5401 23 10.5C23 5.86557 18.4227 2 12.5 2Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Message'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 0C3.65144 0 0 3.06907 0 7C0 10.9309 3.65144 14 8 14C8.49413 14 8.97723 13.9598 9.44625 13.8847L13.2629 15.9402C13.4179 16.0237 13.6053 16.0195 13.7564 15.9293C13.9075 15.839 14 15.676 14 15.5V11.6214C15.233 10.3977 16 8.78485 16 7C16 3.06907 12.3486 0 8 0ZM1 7C1 3.75093 4.06456 1 8 1C11.9354 1 15 3.75093 15 7C15 8.54796 14.309 9.9667 13.1583 11.044C13.0573 11.1385 13 11.2707 13 11.409V14.6628L9.76908 12.9228C9.66955 12.8692 9.55479 12.8509 9.44352 12.8709C8.97685 12.9548 8.49446 13 8 13C4.06456 13 1 10.2491 1 7ZM4 5.5C4 5.22386 4.22386 5 4.5 5H11.5C11.7761 5 12 5.22386 12 5.5C12 5.77614 11.7761 6 11.5 6H4.5C4.22386 6 4 5.77614 4 5.5ZM4 8.5C4 8.22386 4.22386 8 4.5 8H11.5C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H4.5C4.22386 9 4 8.77614 4 8.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Message'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0 5.267C0 2.29306 2.75645 0 6 0C9.24356 0 12 2.29306 12 5.267H11.5L11.9999 5.27849C11.9842 5.96039 11.8279 6.63171 11.5409 7.25043C11.2861 7.79958 10.9331 8.29681 10.5 8.71792V11.5C10.5 11.6764 10.407 11.8398 10.2554 11.9299C10.1037 12.02 9.91577 12.0235 9.76084 11.9391L7.03497 10.4544C6.69256 10.5067 6.34661 10.533 6.00007 10.533H6C2.75659 10.533 0 8.24106 0 5.267ZM11 5.26125C10.9964 2.97163 8.82808 1 6 1C3.16955 1 1 2.97494 1 5.267C1 7.55892 3.16938 9.53297 5.99993 9.533M11 5.26125C10.9867 5.80358 10.8621 6.33742 10.6337 6.82956C10.4046 7.32344 10.076 7.76471 9.66844 8.12574C9.56132 8.22064 9.5 8.35689 9.5 8.5V10.6583L7.36216 9.49391C7.26223 9.43948 7.14672 9.42076 7.03472 9.44086C6.69322 9.50211 6.34695 9.53295 6 9.533"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Message.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Message;
