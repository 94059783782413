import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const UserFilled = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.5 1C9.46286 1 7 3.46286 7 6.5V7.5C7 10.5371 9.46286 13 12.5 13C15.5371 13 18 10.5371 18 7.5V6.5C18 3.46286 15.5371 1 12.5 1ZM12.5 16C9.85188 16 7.21809 16.5467 5.30263 17.0696C3.34574 17.6025 2 19.3849 2 21.408V23.5C2 23.7761 2.22386 24 2.5 24H22.5C22.7761 24 23 23.7761 23 23.5V21.408C23 19.385 21.6542 17.6026 19.6974 17.0696C17.7819 16.5467 15.1481 16 12.5 16Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0ZM6.5 10C5.04131 10 3.64236 10.5795 2.61091 11.6109C1.57946 12.6424 1 14.0413 1 15.5C1 15.6326 1.05268 15.7598 1.14645 15.8536C1.24021 15.9473 1.36739 16 1.5 16H14.5C14.7761 16 15 15.7761 15 15.5C15 14.7777 14.8577 14.0625 14.5813 13.3952C14.3049 12.728 13.8998 12.1216 13.3891 11.6109C12.8784 11.1002 12.272 10.6951 11.6048 10.4187C10.9375 10.1423 10.2223 10 9.5 10H6.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0ZM6 7C4.37328 7 3.13414 7.50897 2.29347 8.19919C1.47165 8.87393 1 9.75368 1 10.5V11.5C1 11.7761 1.22386 12 1.5 12H10.5C10.7761 12 11 11.7761 11 11.5V10.5C11 9.75593 10.5281 8.87628 9.70672 8.20122C8.86622 7.51043 7.62707 7 6 7Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

UserFilled.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default UserFilled;
