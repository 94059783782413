import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chip from '../../../Atoms/Chip';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const FirstLine = styled.div`
  margin-top: 7px;
`;

const ChipCell = ({ data = {}, cellAlign = 'left' }) => {
  const { secondLine = null, ...restOfData } = data;
  return (
    <>
      <FirstLine>
        <Chip {...restOfData} />
      </FirstLine>
      {secondLine && (
        <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
      )}
    </>
  );
};

ChipCell.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'neutral',
      'positive',
      'negative',
      'caution',
      'light',
      'dark',
    ]),
    domID: PropTypes.string,
    className: PropTypes.string,
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default ChipCell;
