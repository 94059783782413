import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import { CountInner, CountOuter } from './components/CountStyle';

const Count = ({
  domID = '',
  className = '',
  dataTestId = '',
  count = '',
  size = 'medium',
  tooltipWrapperStyle = {},
}) => {
  const countString = count.toString();
  const truncate = countString.length > 24;
  const countToShow = truncate ? `${countString.slice(0, 24)}…` : countString;

  return (
    <Tooltip
      tooltipContent={countString}
      domID={`${domID}-count-tooltip`}
      dataTestId={`${dataTestId}-count-tooltip`}
      hideTooltip={!truncate}
      isButtonRoleDisabled
      style={{ display: 'inline-flex', ...tooltipWrapperStyle }}
    >
      {countString && (
        <CountOuter className={`${size} ${className}`}>
          <CountInner
            id={domID}
            data-testid={dataTestId || null}
            className={size}
            tabIndex={truncate ? 0 : -1}
          >
            {countToShow}
          </CountInner>
        </CountOuter>
      )}
    </Tooltip>
  );
};

Count.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipWrapperStyle: PropTypes.object,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** number/text shown in the pill */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** pre-defined size variants */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Count;
