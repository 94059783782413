import styled from 'styled-components';

export default styled.span`
  position: relative;
  .help-support-footer-button {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
    display: flex;
    align-items: center;
    padding: 2px 5px;
    &:focus {
      outline: none;
      border-radius: 5px;
      box-shadow: ${(props) =>
        `inset 0 0 0 1px ${props.theme.UIPalette.Colors.Field.Border.Focus}`};
    }
    .more-results-icon {
      margin-right: 10px;
    }
  }
`;
