import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useLayoutContext from './useLayoutContext';
import NotificationProvider from './NotificationBanner/NotificationProvider';

export const LayoutContext = createContext({});

const LayoutProvider = ({ children }) => {
  const layoutContextValue = useLayoutContext();

  return (
    <NotificationProvider>
      <LayoutContext.Provider value={layoutContextValue}>
        {children}
      </LayoutContext.Provider>
    </NotificationProvider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
};

export default LayoutProvider;
