import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../../BrandCore/constants/heights';
import stackingContext from '../../../../commonResources/stackingContext';

export default styled.div`
  position: fixed;
  top: ${MASTHEAD_HEIGHT}px;
  ${stackingContext.navigationPane};
  right: 0;
  height: calc(100% - ${MASTHEAD_HEIGHT}px);
  background-color: ${({ theme }) => theme.UIPalette.Colors.Background.Depth15};
  ${({ theme }) => theme.BoxShadows.Depth15};

  &.detail-pane-header {
    padding-bottom: 0;
  }
`;
