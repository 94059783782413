import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';
import {
  NAVIGATION_PANE_WIDTH,
  SIDEBAR_WIDTH,
} from '../../../BrandCore/constants/widths';
import stackingContext from '../../../commonResources/stackingContext';

export default styled.div`
  /* Navigation Pane specific  */
  position: fixed;
  top: ${MASTHEAD_HEIGHT}px;
  left: ${(props) => (props.isSidebarVisible ? `${SIDEBAR_WIDTH}px` : 0)};
  bottom: 0;
  display: inline-block;
  width: ${NAVIGATION_PANE_WIDTH}px;

  &.exited,
  &.entering {
    padding: 1.9rem 8px;
  }

  /* general collapse pane  */
  background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
  ${stackingContext.navigationPane};
  &::before {
    height: calc(100vh - 48px);
    pointer-events: none;
    position: absolute;
    right: -10px;
    width: 10px;
  }
`;
