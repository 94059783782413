import React from 'react';
import styled, { useTheme } from 'styled-components';
import { components } from 'react-select';
import Clear from '../../../BrandCore/icons/interactive/Clear';

const StyledClear = styled(Clear)`
  width: 16px;

  &:hover path {
    fill: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  }

  &:active path {
    fill: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  }
`;

export const ClearIndicator = (props) => {
  const theme = useTheme();

  return (
    <components.ClearIndicator {...props}>
      <StyledClear fillColor={theme.UIPalette.Colors.Content.Secondary} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
