import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Mail = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Mail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 5.5C2 4.67157 2.67157 4 3.5 4H20.5C21.3284 4 22 4.67157 22 5.5V18.5C22 19.3284 21.3284 20 20.5 20H3.5C2.67157 20 2 19.3284 2 18.5V5.5ZM3.5 3C2.11929 3 1 4.11929 1 5.5V18.5C1 19.8807 2.11929 21 3.5 21H20.5C21.8807 21 23 19.8807 23 18.5V5.5C23 4.11929 21.8807 3 20.5 3H3.5ZM4.91046 7.14645C4.70218 6.95118 4.36449 6.95118 4.15621 7.14645C3.94793 7.34171 3.94793 7.65829 4.15621 7.85355L11.6229 14.8536C11.8312 15.0488 12.1688 15.0488 12.3771 14.8536L19.8438 7.85355C20.0521 7.65829 20.0521 7.34171 19.8438 7.14645C19.6355 6.95118 19.2978 6.95118 19.0895 7.14645L12 13.7929L4.91046 7.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Mail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.5C1 2.22414 1.22414 2 1.5 2H14.5C14.7759 2 15 2.22414 15 2.5V13.5C15 13.7759 14.7759 14 14.5 14H1.5C1.22414 14 1 13.7759 1 13.5V2.5ZM1.5 1C0.671858 1 0 1.67186 0 2.5V13.5C0 14.3281 0.671858 15 1.5 15H14.5C15.3281 15 16 14.3281 16 13.5V2.5C16 1.67186 15.3281 1 14.5 1H1.5ZM2.81662 4.11304C2.6029 3.93817 2.28788 3.96967 2.11302 4.1834C1.93816 4.39712 1.96966 4.71213 2.18338 4.88699L7.68338 9.38699C7.86756 9.53769 8.13244 9.53769 8.31662 9.38699L13.8166 4.88699C14.0303 4.71213 14.0618 4.39712 13.887 4.1834C13.7121 3.96967 13.3971 3.93817 13.1834 4.11304L8 8.35398L2.81662 4.11304Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Mail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.5C1 2.22386 1.22386 2 1.5 2H10.5C10.7761 2 11 2.22386 11 2.5V9.5C11 9.77614 10.7761 10 10.5 10H1.5C1.22386 10 1 9.77614 1 9.5V2.5ZM1.5 1C0.671573 1 0 1.67157 0 2.5V9.5C0 10.3284 0.671573 11 1.5 11H10.5C11.3284 11 12 10.3284 12 9.5V2.5C12 1.67157 11.3284 1 10.5 1H1.5ZM2.8254 3.12038C2.61574 2.94067 2.30009 2.96495 2.12038 3.17461C1.94067 3.38428 1.96495 3.69993 2.17461 3.87964L5.67461 6.87964C5.86186 7.04013 6.13816 7.04013 6.3254 6.87964L9.82541 3.87964C10.0351 3.69993 10.0593 3.38428 9.87964 3.17461C9.69993 2.96495 9.38428 2.94067 9.17461 3.12038L6.00001 5.84147L2.8254 3.12038Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Mail.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Mail;
