import styled from 'styled-components';
import { borderHelpers } from '../../../BrandCore/shapes/borders';

export default styled.div`
  display: inline-flex;
  position: relative;

  &:not(.disabled):not(.diminished) {
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.SplitDropdownButton.BoxShadow};
  }

  button:focus-visible {
    .separator {
      background-color: transparent;
    }
  }

  &.small {
    ${borderHelpers.borderRadius8};
  }

  &.medium {
    ${borderHelpers.borderRadius10};
  }

  &.large {
    ${borderHelpers.borderRadius12};
  }

  .split-main-button {
    box-shadow: none;
    &:not(:focus-visible) {
      border-right-color: transparent;
    }

    &.small {
      border-radius: 8px 0 0 8px;
    }

    &.medium {
      border-radius: 10px 0 0 10px;
    }

    &.large {
      border-radius: 12px 0 0 12px;
    }
  }

  .arrow-button {
    z-index: 2;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    &:not(:focus-visible) {
      border-left-color: transparent;
    }

    &.small {
      border-radius: 0 8px 8px 0;
    }

    &.medium {
      border-radius: 0 10px 10px 0;
    }

    &.large {
      border-radius: 0 12px 12px 0;
    }
  }
`;
