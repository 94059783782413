import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { getDOMElementTotalWidth } from '../../commonResources/helpers/styleHelpers';

import Tab from '../../Atoms/Tab';
import DropdownButton from '../DropdownButton';
import useWindowSize from '../../commonResources/hooks/useWindowSize';

import TabsWrapper, {
  MoreDropdownWrapper,
  TabsChildrenWrapper,
  TabsSwitcherWrapper,
} from './styles/TabsWrapper';

export const Tabs = ({
  initialTab = -1,
  onTabSelect = () => false,
  domID = null,
  dataTestId = '',
  children = null,
  tabs = null,
  size = 'medium',
  delayWidthCalc = 0,
}) => {
  const [selected, setSelected] = useState(initialTab);
  useEffect(() => {
    if (initialTab >= 0) {
      setSelected(initialTab);
    } else {
      setSelected(tabs.findIndex((t) => !t.isDisabled));
    }
  }, [initialTab]);

  const [overflowIndex, setOverflowIndex] = useState(-1);
  const [moreMenuItems, setMoreMenuItems] = useState([]);
  const [tabsToShow, setTabsToShow] = useState(tabs);

  const windowSize = useWindowSize();

  const tabsWrapperRef = useRef(null);
  const tabsSwitcherWrapperRef = useRef(null);
  const tabsChildrenWrapperRef = useRef(null);

  const tabRefs = useMemo(() => tabs.map(() => React.createRef()), [tabs]);

  const setSelectedTab = (e, index) => {
    e && e.persist();
    setSelected(index);
    onTabSelect(e, { selectedTabIndex: index });
  };

  useEffect(() => {
    if (overflowIndex !== -1) {
      setMoreMenuItems(
        tabs
          .slice(overflowIndex)
          .map((t, i) => ({ label: t.label, id: i, disabled: t.isDisabled })),
      );
      setTabsToShow(tabs.slice(0, overflowIndex));
    } else {
      setMoreMenuItems([]);
      setTabsToShow(tabs);
    }
  }, [overflowIndex, tabs]);

  const timeOutId = useRef(null);

  useEffect(() => {
    setOverflowIndex(-1);

    clearTimeout(timeOutId.current);

    timeOutId.current = setTimeout(() => {
      const tabsWrapperWidth = getDOMElementTotalWidth(tabsWrapperRef.current);
      const tabsChildrenWrapperWidth = getDOMElementTotalWidth(
        tabsChildrenWrapperRef.current,
      );
      const availableWidth = tabsWrapperWidth - tabsChildrenWrapperWidth;

      let sum = 0;
      for (
        let tabRefIndex = 0;
        tabRefIndex < tabRefs.length;
        tabRefIndex += 1
      ) {
        const currentTab = tabRefs[tabRefIndex].current;
        if (currentTab != null) {
          sum += getDOMElementTotalWidth(currentTab);
        }

        if (sum > availableWidth) {
          setOverflowIndex(tabRefIndex - 1);
          break;
        }
      }
    }, delayWidthCalc);
  }, [windowSize, tabRefs]);

  return (
    <TabsWrapper id={domID} data-testid={dataTestId} ref={tabsWrapperRef}>
      <TabsSwitcherWrapper ref={tabsSwitcherWrapperRef}>
        {tabsToShow.map((tab, index) => (
          <Tab
            key={tab.domID || index}
            tooltipContent={tab.tooltipContent}
            tooltipWidth={tab.tooltipWidth}
            ref={tabRefs[index]}
            label={tab.label}
            count={tab.count}
            size={size}
            isDisabled={tab.isDisabled}
            isSelected={selected === index}
            onSelect={(e) => setSelectedTab(e, index)}
            domID={tab.domID}
            data-testid={tab.dataTestId || `${dataTestId}-${index}`}
          />
        ))}
        {overflowIndex !== -1 && (
          <MoreDropdownWrapper
            className={clsx({
              selected: selected >= overflowIndex,
            })}
          >
            <DropdownButton
              showActiveIndicator={selected >= overflowIndex}
              domID={`more-${domID}`}
              buttonClass={`more-btn more-btn-${size} ${
                selected >= overflowIndex ? 'selected' : ''
              }`}
              buttonType="unstyled"
              size={size}
              name="More"
              listClass={`more-list more-list-${size}`}
              menuItems={moreMenuItems}
              shouldShowDots
              onMenuClick={(e, { activeItem }) => {
                setSelectedTab(null, +activeItem.id + overflowIndex);
              }}
            />
          </MoreDropdownWrapper>
        )}
      </TabsSwitcherWrapper>
      <TabsChildrenWrapper ref={tabsChildrenWrapperRef}>
        {children}
      </TabsChildrenWrapper>
    </TabsWrapper>
  );
};

Tabs.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  /** Array of shape {label: (string|node).isRequired, tooltipContent: string|node, tooltipWidth: number, domID: string.isRequired} */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      /** Content of the tooltip to show */
      tooltipContent: PropTypes.node,
      tooltipWidth: PropTypes.number,
      domID: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** sets which tab is selected */
  initialTab: PropTypes.number,
  /** fires when a tab is selected
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.selectedTabIndex
   */
  onTabSelect: PropTypes.func,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** children will be displayed on the right end of the Tabs component  */
  children: PropTypes.element,
  /** If there are more tabs than will fit in the available width, they are hidden in a dropdown.
   * In some situations where the container width is animated, such as a DetailPane in PageLayout,
   * it may be necessary to delay the width calculation to wait for the animation to finish.
   * This prop accepts a number of milliseconds by which the calculation should be delayed. */
  delayWidthCalc: PropTypes.number,
};

export default Tabs;
