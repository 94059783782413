import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Next = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Next'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.13313 20.8605C7.95562 20.6746 7.95562 20.3731 8.13313 20.1871L15.9026 12L8.13313 3.81291C7.95562 3.62694 7.95562 3.32544 8.13313 3.13947C8.31064 2.95351 8.59845 2.95351 8.77596 3.13947L16.8669 11.6633C17.0444 11.8492 17.0444 12.1508 16.8669 12.3367L8.77596 20.8605C8.59845 21.0465 8.31064 21.0465 8.13313 20.8605Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Next'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.17992 0.106986C3.39206 -0.056197 3.70734 -0.0297398 3.88412 0.16608L8.88412 5.70453C9.03864 5.87569 9.03864 6.12431 8.88412 6.29547L3.88412 11.8339C3.70734 12.0297 3.39206 12.0562 3.17992 11.893C2.96778 11.7298 2.93912 11.4388 3.1159 11.243L7.84916 6L3.1159 0.757018C2.93912 0.561199 2.96778 0.270169 3.17992 0.106986Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Next.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Next;
