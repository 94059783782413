import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import {
  BackItemButton,
  BackItemWrapper,
  StyledCaret,
} from './StyledComponents';
import Tooltip from '../../../Atoms/Tooltip';

export const BackItem = ({
  domID = null,
  className = '',
  dataTestId = null,
  isActive = false,
  onClick = () => false,
}) => {
  const theme = useContext(ThemeContext);

  const [iconColor, setIconColor] = useState(theme.BaseColors.NeutralAlt['15']);

  useEffect(() => {
    if (isActive) setIconColor(theme.UIPalette.Colors.Content.White);
    if (!isActive) setIconColor(theme.BaseColors.NeutralAlt['15']);
  }, [isActive]);

  const domIDs = {
    icon: domID && `${domID}-icon`,
    label: domID && `${domID}-label`,
  };

  const classes = [className];
  if (isActive) classes.push('selected');
  return (
    <BackItemWrapper>
      <BackItemButton
        id={domID}
        className={classes.join(' ')}
        data-testid={dataTestId}
        onClick={(e) => onClick(e)}
        onMouseEnter={() => setIconColor(theme.UIPalette.Colors.Content.White)}
        onMouseLeave={() =>
          !isActive && setIconColor(theme.BaseColors.NeutralAlt['15'])
        }
      >
        <Tooltip
          tooltipPosition="right-center"
          tooltipWidth={80}
          tooltipContent="Go Back"
          caretToAnchor={24}
          isButtonRoleDisabled
        >
          <div className="icon-wrapper">
            <StyledCaret
              domId={domIDs.icon}
              fillColor={iconColor}
              width="34px"
            />
          </div>
        </Tooltip>
      </BackItemButton>
    </BackItemWrapper>
  );
};

BackItem.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** if `true`, the item is the currently selected menu item */
  isActive: PropTypes.bool,
  /** takes the event as a parameter */
  onClick: PropTypes.func,
};

export default BackItem;
