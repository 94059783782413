import React from 'react';
import PropTypes from 'prop-types';
import ErrorPageWrapper from './ErrorPageWrapper';
import Card from '../../../Organisms/Card';
import ErrorTitle from './ErrorTitle';

const ErrorPage = ({
  domID = '',
  title = '',
  message = '',
  dataTestId = '',
  footer = null,
  className,
  background = 'notFound',
}) => {
  const testIDs = {
    title: dataTestId ? `${dataTestId}-title` : '',
    message: dataTestId ? `${dataTestId}-message` : '',
    footer: dataTestId ? `${dataTestId}-footer` : '',
  };

  return (
    <ErrorPageWrapper
      id={domID}
      data-testid={dataTestId}
      className={className}
      background={background}
    >
      <div className="container">
        <Card
          dataTestId={`${dataTestId}-card`}
          cardHeader={() => (
            <ErrorTitle data-testid={testIDs.title}>{title}</ErrorTitle>
          )}
          cardBody={() => (
            <div className="body-text" data-testid={testIDs.message}>
              {message}
            </div>
          )}
          cardFooter={() =>
            footer && <div data-testid={testIDs.footer}>{footer()}</div>
          }
        />
      </div>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  /**
   * id attribute for the outermost div
   */
  domID: PropTypes.string,
  /** Explanation of the error */
  message: PropTypes.string.isRequired,
  /** Class name that can be used to override the default styles of the page */
  className: PropTypes.string,
  /** Title for the error page e.g. Under Maintenance */
  title: PropTypes.string.isRequired,
  /** This prop adds data-testid to the `ErrorPage`
   * component which can be useful during automated testing */
  dataTestId: PropTypes.string,
  /** This prop add footer buttions optionally to the `ErrorPage` */
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * The background svg to display
   */
  background: PropTypes.oneOf(['notFound', 'underMaintenance', 'loggedOut']),
};
