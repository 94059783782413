import styled from 'styled-components';

export default styled.a`
  display: inline-block;
  padding: 0.75rem 1.25rem;

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
