import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Upsell = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Upsell'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.5 3C12.7761 3 13 3.22386 13 3.5V8H17.5C17.7761 8 18 8.22386 18 8.5C18 8.77614 17.7761 9 17.5 9H13V13.5C13 13.7761 12.7761 14 12.5 14C12.2239 14 12 13.7761 12 13.5V9H7.5C7.22386 9 7 8.77614 7 8.5C7 8.22386 7.22386 8 7.5 8H12V3.5C12 3.22386 12.2239 3 12.5 3ZM1.5 15C1.77614 15 2 15.2239 2 15.5V19.5C2 20.3284 2.67157 21 3.5 21H21.5C22.3284 21 23 20.3284 23 19.5V15.5C23 15.2239 23.2239 15 23.5 15C23.7761 15 24 15.2239 24 15.5V19.5C24 20.8807 22.8807 22 21.5 22H3.5C2.11929 22 1 20.8807 1 19.5V15.5C1 15.2239 1.22386 15 1.5 15Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Upsell'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V6H12C12.2761 6 12.5 6.22386 12.5 6.5C12.5 6.77614 12.2761 7 12 7H8.5V10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5V7H4C3.72386 7 3.5 6.77614 3.5 6.5C3.5 6.22386 3.72386 6 4 6H7.5V2.5C7.5 2.22386 7.72386 2 8 2ZM0.5 11C0.776142 11 1 11.2239 1 11.5V14.5C1 14.7761 1.22386 15 1.5 15H14.5C14.7761 15 15 14.7761 15 14.5V11.5C15 11.2239 15.2239 11 15.5 11C15.7761 11 16 11.2239 16 11.5V14.5C16 15.3284 15.3284 16 14.5 16H1.5C0.671573 16 0 15.3284 0 14.5V11.5C0 11.2239 0.223858 11 0.5 11Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Upsell'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.5 2C6.5 1.72386 6.27614 1.5 6 1.5C5.72386 1.5 5.5 1.72386 5.5 2V4.5H3C2.72386 4.5 2.5 4.72386 2.5 5C2.5 5.27614 2.72386 5.5 3 5.5H5.5V8C5.5 8.27614 5.72386 8.5 6 8.5C6.27614 8.5 6.5 8.27614 6.5 8V5.5H9C9.27614 5.5 9.5 5.27614 9.5 5C9.5 4.72386 9.27614 4.5 9 4.5H6.5V2ZM0.5 7C0.776142 7 1 7.22386 1 7.5V10.5C1 10.6326 1.05268 10.7598 1.14645 10.8536C1.24022 10.9473 1.36739 11 1.5 11H10.5C10.6326 11 10.7598 10.9473 10.8536 10.8536C10.9473 10.7598 11 10.6326 11 10.5V7.5C11 7.22386 11.2239 7 11.5 7C11.7761 7 12 7.22386 12 7.5V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10217 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V7.5C0 7.22386 0.223858 7 0.5 7Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Upsell.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Upsell;
