import React from 'react';
import EmptyWrapper from './styleWrappers/EmptyWrapper';
import VerticallyCentered from './styleWrappers/VerticallyCentered';

const EmptyCell = () => (
  <VerticallyCentered>
    <EmptyWrapper>Empty</EmptyWrapper>
  </VerticallyCentered>
);

export default EmptyCell;
