import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Search = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Search'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.5589 17.1704 14.424 15.8271 15.7793C15.8187 15.7867 15.8105 15.7944 15.8025 15.8025C15.7944 15.8105 15.7867 15.8187 15.7793 15.8271C14.424 17.1704 12.5589 18 10.5 18C6.35786 18 3 14.6421 3 10.5ZM16.1465 16.8536C14.6451 18.1889 12.6673 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.6673 18.1889 14.6451 16.8536 16.1465L22.8536 22.1465C23.0488 22.3417 23.0488 22.6583 22.8536 22.8536C22.6583 23.0488 22.3417 23.0488 22.1465 22.8536L16.1465 16.8536Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Search'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5C13 9.00835 12.3928 10.3749 11.4095 11.3685C11.4024 11.3749 11.3953 11.3816 11.3884 11.3885C11.3816 11.3953 11.3749 11.4024 11.3685 11.4096C10.3749 12.3928 9.00835 13 7.5 13C4.46243 13 2 10.5376 2 7.5ZM11.7291 12.4362C10.5923 13.411 9.11495 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5C14 9.11495 13.411 10.5923 12.4362 11.7291L15.8536 15.1465C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L11.7291 12.4362Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Search'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 5.5C2 3.567 3.567 2 5.5 2C7.433 2 9 3.567 9 5.5C9 6.45819 8.61495 7.32645 7.99115 7.95849C7.98549 7.96365 7.97992 7.96898 7.97445 7.97445C7.96898 7.97992 7.96365 7.98549 7.95849 7.99115C7.32645 8.61495 6.45819 9 5.5 9C3.567 9 2 7.433 2 5.5ZM8.30884 9.01595C7.53901 9.63176 6.56251 10 5.5 10C3.01472 10 1 7.98528 1 5.5C1 3.01472 3.01472 1 5.5 1C7.98528 1 10 3.01472 10 5.5C10 6.56251 9.63176 7.53901 9.01595 8.30884L11.8536 11.1465C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536C11.6583 12.0488 11.3417 12.0488 11.1465 11.8536L8.30884 9.01595Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Search.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Search;
