import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './components/Wrapper';
import Loader from '../../commonResources/internalIcons/Loader';

export const LoadingIndicator = ({
  domID = null,
  className = null,
  length = '50px',
  dataTestId = '',
}) => {
  return (
    <Wrapper
      className={`${className}`}
      length={length}
      data-testid={dataTestId}
    >
      <Loader className={`${className} rotating`} domID={domID} />
    </Wrapper>
  );
};

LoadingIndicator.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  length: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default LoadingIndicator;
