import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const SquarePlus = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Plus'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 4.5C2 3.11929 3.11929 2 4.5 2H20.5C21.8807 2 23 3.11929 23 4.5V20.5C23 21.8807 21.8807 23 20.5 23H4.5C3.11929 23 2 21.8807 2 20.5V4.5ZM4.5 3C3.67157 3 3 3.67157 3 4.5V20.5C3 21.3284 3.67157 22 4.5 22H20.5C21.3284 22 22 21.3284 22 20.5V4.5C22 3.67157 21.3284 3 20.5 3H4.5ZM12.5 7C12.7761 7 13 7.22386 13 7.5V12H17.5C17.7761 12 18 12.2239 18 12.5C18 12.7761 17.7761 13 17.5 13H13V17.5C13 17.7761 12.7761 18 12.5 18C12.2239 18 12 17.7761 12 17.5V13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H12V7.5C12 7.22386 12.2239 7 12.5 7Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Plus'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 2.5C2 2.22414 2.22414 2 2.5 2H14.5C14.7759 2 15 2.22414 15 2.5V14.5C15 14.7759 14.7759 15 14.5 15H2.5C2.22414 15 2 14.7759 2 14.5V2.5ZM2.5 1C1.67186 1 1 1.67186 1 2.5V14.5C1 15.3281 1.67186 16 2.5 16H14.5C15.3281 16 16 15.3281 16 14.5V2.5C16 1.67186 15.3281 1 14.5 1H2.5ZM8.5 5C8.77614 5 9 5.22386 9 5.5V8H11.5C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H9V11.5C9 11.7761 8.77614 12 8.5 12C8.22386 12 8 11.7761 8 11.5V9H5.5C5.22386 9 5 8.77614 5 8.5C5 8.22386 5.22386 8 5.5 8H8V5.5C8 5.22386 8.22386 5 8.5 5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Square Plus'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 1.5C1 1.22386 1.22386 1 1.5 1H10.5C10.7761 1 11 1.22386 11 1.5V10.5C11 10.7761 10.7761 11 10.5 11H1.5C1.22386 11 1 10.7761 1 10.5V1.5ZM1.5 0C0.671573 0 0 0.671573 0 1.5V10.5C0 11.3284 0.671573 12 1.5 12H10.5C11.3284 12 12 11.3284 12 10.5V1.5C12 0.671573 11.3284 0 10.5 0H1.5ZM6 2.5C6.27614 2.5 6.5 2.72386 6.5 3V5.5H9C9.27614 5.5 9.5 5.72386 9.5 6C9.5 6.27614 9.27614 6.5 9 6.5H6.5V9C6.5 9.27614 6.27614 9.5 6 9.5C5.72386 9.5 5.5 9.27614 5.5 9V6.5H3C2.72386 6.5 2.5 6.27614 2.5 6C2.5 5.72386 2.72386 5.5 3 5.5H5.5V3C5.5 2.72386 5.72386 2.5 6 2.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

SquarePlus.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default SquarePlus;
