import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Title from './components/title';
import Down from '../../BrandCore/icons/interactive/Down';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Dots from '../../BrandCore/icons/interactive/Dots';
import StandardMenu from '../../Molecules/StandardMenu';
import DragRow from '../../BrandCore/icons/interactive/DragRow';
import ClickableWrapper from './components/clickableWrapper';
import padding from '../../commonResources/padding';

export const SingleSelectGroupOpenClose = ({
  onClick = () => false,
  domID = null,
  paddingLeft = padding.navigationPaneHeader.left,
  isExpanded = false,
  title = '',
  wrapText = false,
  overflowTooltipPosition = 'right-center',
  overflowTooltipWidth = 200,
  dataTestId = '',
  menuItems = null,
  onMenuClick = () => false,
  isCreateGroupSubFolder = false,
  isEditing = false,
  onHoverReorder = () => false,
  keyForHoverReorder = '',
  className = '',
  selectItem = () => false,
  isLink = false,
  icon: Icon = null,
}) => {
  const theme = useTheme();
  const titleRef = useRef(null);
  const [isAllTextVisible, setIsAllTextVisible] = useState(true);
  const [isTextWrapping, setIsTextWrapping] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isEllipsisActive = (element) => {
    // scrollWidth and offsetWidth can be slightly off in IE, so I'm
    // giving this 1px of wiggle room. Otherwise it almost always returns true.
    return element.offsetWidth < element.scrollWidth - 1;
  };

  // check whether the text is truncated and enable the tooltip if it is
  useEffect(() => {
    if (titleRef.current) {
      setIsAllTextVisible(!isEllipsisActive(titleRef.current));
    } else {
      setIsAllTextVisible(true);
    }
  }, [title, wrapText]);

  // check whether the text is actually wrapping and adjust line-height
  useEffect(() => {
    if (titleRef.current && wrapText) {
      setIsTextWrapping(titleRef.current.offsetHeight > 30);
    } else {
      setIsTextWrapping(false);
    }
  }, [title, wrapText]);

  const testIDs = {
    wrapper: dataTestId ? `${dataTestId}-wrapper-single-select-item` : '',
    title: dataTestId ? `${dataTestId}-title` : '',
    overflowTooltip: dataTestId ? `${dataTestId}-overflow-tooltip` : '',
    downArrow: dataTestId ? `${dataTestId}-arrow` : '',
  };

  const withoutOverflowTooltip = () => (
    <ClickableWrapper
      id={domID}
      role="menuitem"
      data-testid={testIDs.wrapper}
      paddingLeft={paddingLeft}
      isTextWrapping={isTextWrapping}
      isExpanded={isExpanded}
      tabIndex={0}
      onMouseEnter={() => isEditing && onHoverReorder(keyForHoverReorder)}
      onMouseLeave={() => isEditing && onHoverReorder('')}
      isEditing={isEditing}
      className={className}
      onClick={isLink ? selectItem : () => false}
      isLink={isLink}
      onKeyUp={(e) => {
        if ([13, 32].indexOf(e.which) !== -1) {
          onClick();
          e.stopPropagation();
        }
      }}
    >
      <span style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Down
          className={isExpanded ? 'arrow expanded' : 'arrow'}
          size="small"
          dataTestId={testIDs.downArrow}
          fillColor={
            isExpanded
              ? theme.UIPalette.Colors.Content.Primary
              : theme.UIPalette.Colors.Content.Secondary
          }
          onClick={(e) => onClick(e)}
        />
        {isEditing && (
          <IconButton
            size="small"
            icon={DragRow}
            title="reorder"
            className="reorder"
            domID={`${domID}-reorder`}
            dataTestId={testIDs.reorder}
            style={{ marginRight: '8px' }}
          />
        )}
        {Icon && (
          <div style={{ margin: '1.5px 10px 0px 0px' }}>
            <Icon size="small" />
          </div>
        )}
        <Title
          ref={titleRef}
          wrapText={wrapText}
          isAllTextVisible={isAllTextVisible}
          isTextWrapping={isTextWrapping}
          data-testid={testIDs.title}
        >
          {title}
        </Title>
      </span>
      {menuItems && isCreateGroupSubFolder && isEditing && (
        <div>
          <IconButton
            size="small"
            className="sub-menu-button"
            icon={Dots}
            dataTestId={testIDs.dots}
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(true);
            }}
            tooltipProps={{
              tooltipPosition: 'left-center',
              tooltipContent: 'Options',
              tooltipWidth: 75,
              isButtonRoleDisabled: true,
            }}
          />
          {isMenuOpen && (
            <StandardMenu
              menuItems={menuItems}
              onMenuClick={(e, state) => {
                onMenuClick(e, state, domID);
                setIsMenuOpen(false);
              }}
              onClickOut={() => setIsMenuOpen(false)}
            />
          )}
        </div>
      )}
    </ClickableWrapper>
  );

  if (isAllTextVisible) return withoutOverflowTooltip();

  return (
    <Tooltip
      tooltipPosition={overflowTooltipPosition}
      tooltipWidth={overflowTooltipWidth}
      text={title}
      domID={testIDs.overflowTooltip}
      isButtonRoleDisabled
      style={{ overflow: 'hidden', width: '100%' }}
    >
      {withoutOverflowTooltip()}
    </Tooltip>
  );
};

SingleSelectGroupOpenClose.propTypes = {
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  /** takes the click event as a parameter */
  onClick: PropTypes.func,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  paddingLeft: PropTypes.number,
  /** if true, the title wraps instead of truncating if too long */
  wrapText: PropTypes.bool,
  overflowTooltipWidth: PropTypes.number,
  overflowTooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  onMenuClick: PropTypes.func,
  isCreateGroupSubFolder: PropTypes.bool,
  isEditing: PropTypes.bool,
  onHoverReorder: PropTypes.func,
  selectItem: PropTypes.func,
  keyForHoverReorder: PropTypes.string,
  className: PropTypes.string,
  /**
   * Indicates whether the group label is clickable and should fire selectItem callback
   */
  isLink: PropTypes.bool,
  /** Valid React element type. eg icon={CircleCheck}. Should be a ui-core icon component */
  icon: PropTypes.elementType,
};

export default SingleSelectGroupOpenClose;
