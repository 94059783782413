import styled from 'styled-components';

export default styled.div`
  padding-bottom: 0.3em;
  > button {
    margin-left: 0;
    margin-right: 0;

    > span {
      display: flex;
      align-items: center;
    }
  }
`;
