import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationHeaderLeft = styled.div`
  display: flex;
  width: calc(100% - 52px);
  max-width: calc(100% - 52px);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  span {
    overflow: hidden;
    align-self: center;
  }

  .title {
    align-self: center;
    margin-left: ${({ existsIcon }) => (existsIcon ? 10 : 30)}px;
    ${TextStyles.smallBold};
    color: ${({ isRead, theme }) =>
      isRead
        ? theme.UIPalette.Colors.Content.Secondary
        : theme.UIPalette.Colors.Content.Primary};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date-time {
    align-self: center;
    margin-left: 8px;
    ${TextStyles.xSmall};
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};

    white-space: nowrap;
  }
`;

export const NotificationHeaderRight = styled.div`
  display: flex;
`;

export const NotificationHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${({ showActions }) => (showActions ? 1 : 0)};

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
`;
