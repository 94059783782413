import blendColors from '../../../../commonResources/colorBlender';
import {
  white,
  neutralAlt30,
  primary15,
  primary30,
  primary50,
  primary70,
  primary100,
  primary115,
  primary130,
  neutral5,
  neutral10,
  neutral15,
  neutral30,
  neutral100,
  neutral150,
  neutral170,
} from './whiteLabelBasePalette';
import {
  positive130,
  caution100,
  negative50,
  negative70,
  negative130,
  accent30,
  accent50,
  accent100,
  accent130,
} from '../base/basePalette';
import { brightBlue130 } from '../../datavizPalette';

const standardButtonColor = '#341157';
const buttonFocusedBoxShadow =
  '0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(154, 146, 162, 0.3)';

const WhiteLabelUIPalette = {
  Colors: {
    Background: {
      Modal: '#766D7F66',
      Masthead: standardButtonColor,
      Selected: primary30,
      ProductSwitcher: '#67628A99',
      Tooltip: standardButtonColor,
      Depth2: white,
      Depth5: white,
      Depth10: white,
      Depth15: white,
      Breadcrumb: neutral5,
      StandardMenu: white,
      ToggleOn: standardButtonColor,
      Default: white,
    },
    Content: {
      Primary: standardButtonColor,
      Secondary: '#766D7F',
      Placeholder: '#9A92A2',
      TabDisabled: neutralAlt30,
      Disabled: neutral30,
      Error: '#BF1616',
      White: white,
      PersistentDark: standardButtonColor,
      PersistentLight: white,
      Masthead: standardButtonColor,
    },
    Selected: {
      Tab: white,
      TabUnselected: white,
      MenuItem: white,
    },
    Status: {
      Positive: positive130,
      Caution: caution100,
      Negative: negative130,
      Neutral: neutral100,
      Light: neutral30,
      Dark: standardButtonColor,
    },
    Field: {
      Background: {
        Default: white,
        Disabled: neutral5,
      },
      Border: {
        Default: neutral100,
        Hover: '#5C5563',
        Focus: primary115,
        Disabled: neutral15,
        Error: negative130,
      },
    },
    Button: {
      Background: {
        Standard: standardButtonColor,
      },
      Border: {
        Standard: neutral150,
        EmphasizedAlt: accent130,
      },
      Text: {
        Standard: white,
        Emphasized: white,
      },
      Hover: {
        Background: {
          Emphasized: primary70,
          Standard: neutral150,
          DeEmphasized: neutral10,
          Diminished: primary130,
          Destroy: '#A10B0B',
          EmphasizedAlt: accent130,
          DeEmphasizedReversed: '#FFFFFF05',
        },
        Border: {
          Standard: standardButtonColor,
          EmphasizedAlt: accent130,
        },
        Text: {
          Emphasized: white,
          Diminished: primary130,
        },
      },
      Active: {
        Border: {
          EmphasizedAlt: accent130,
        },
      },
      Focus: {
        Border: {
          Emphasized: primary115,
          Standard: neutral150,
          DeEmphasized: neutral150,
          Destroy: negative130,
          EmphasizedAlt: accent100,
          DeEmphasizedReversed: white,
          CheckboxFocus: primary115,
          RadioFocus: white,
        },
        BoxShadow: {
          Standard: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${neutral170},
            ${buttonFocusedBoxShadow}
          `,
          Emphasized: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${primary115},
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasized: `
            0px 0px 0px 1px ${white}, 
            0px 0px 0px 2px ${neutral150},
            ${buttonFocusedBoxShadow}
          `,
          Destroy: `
            0px 0px 0px 1px ${white}, 
            0px 0px 0px 2px #A10B0B,
           ${buttonFocusedBoxShadow}
          `,
          EmphasizedAlt: `
            0px 0px 0px 1px ${white}, 
            0px 0px 0px 2px ${accent130},
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasizedReversed: `
            0px 0px 0px 1px ${standardButtonColor},
            0px 0px 0px 2px ${white},
            ${buttonFocusedBoxShadow}
          `,
        },
      },
      Disabled: {
        Background: {
          Emphasized: primary15,
          Standard: neutral15,
          Destroy: negative50,
          EmphasizedAlt: accent30,
          DeEmphasizedReversed: standardButtonColor,
        },
        Border: {
          Emphasized: '#EAE7FC',
          Standard: neutral30,
          Destroy: negative70,
          EmphasizedAlt: accent50,
          DeEmphasizedReversed: neutral100,
        },
        Text: {
          Emphasized: blendColors('#6C57EA', white, 0.25),
          Standard: white,
          Diminished: primary50,
          Destroy: white,
          EmphasizedAlt: white,
          DeEmphasizedReversed: neutral100,
        },
      },
    },
    SplitDropdownButton: {
      Border: {
        Emphasized: primary100,
        Standard: standardButtonColor,
        Destroy: negative130,
        EmphasizedAlt: accent100,
      },
      Disabled: {
        Border: {
          Emphasized: white,
          Standard: white,
          Destroy: negative50,
          EmphasizedAlt: accent50,
        },
      },
    },
    // start colors not on figma chart
    // I'm not sure where we source these values, it was on the original palette so I left it.
    Filter: {
      Border: {
        Selected: `${primary115}`,
        Focus: `${primary115}`,
      },
      Background: {
        Hover: `${neutral10}`,
        Active: `${neutral15}`,
        Selected: `${primary30}`,
        HomeButton: `${neutral100}`,
        HomeButtonDisabled: `${neutral150}`,
      },
      BoxShadow: {
        Selection: `box-shadow: inset 2px 0px 0px ${primary115};`,
      },
    },
    MenuItem: {
      Icon: `${brightBlue130}`,
      Checked: `${brightBlue130}`,
      Background: {
        Default: `${neutral100}`,
      },
    },
    // end colors not on main figma chart
  },
  BoxShadows: {
    Field: {
      Inner: 'inset 0px 2px 6px rgba(154, 146, 162, 0.16);',
      Outer: '0px 2px 6px rgba(154, 146, 162, 0.12);',
      Default: `border: 1px solid ${neutral100}`,
      Disabled: `border: 1px solid ${neutral15}`,
      Hover: 'border: 1px solid #5C5563',
      Focus: `border: 1px solid ${primary115}`,
      Error: `border: 1px solid ${negative130}`,
    },
    Selection: {
      TabSelected: 'box-shadow: 0px 2px 0px #472FE6;',
      TabUnselected: 'box-shadow: 0px 2px 0px #EAEEF4;',
      MenuItem: 'box-shadow: inset 2px 0px 0px #472FE6;',
    },
    General: {
      LineAbove: 'box-shadow: 0px -1px 0px #DEE4EC;',
      LineBelow: 'box-shadow: 0px 1px 0px #DEE4EC;',
      MicroModal:
        // eslint-disable-next-line max-len
        `box-shadow: 0px -1px 2px 1px rgba(52, 17, 87, 0.08), 0px 6px 16px 2px rgba(154, 146, 162, 0.25);
        filter: drop-shadow(0px 1px 6px rgba(52, 17, 87, 0.08));`,
      HelperModule:
        // eslint-disable-next-line max-len
        'box-shadow: 0px 1px 4px rgba(17, 80, 244, 0.12), 0px 10px 32px rgba(154, 146, 162, 0.35), inset 0px 4px 0px #A84EE5;',
      HelperModuleCustom: ({
        borderColor,
      }) => `box-shadow: 0px 1px 4px rgba(17, 80, 244, 0.12),
        0px 10px 32px rgba(166, 175, 197, 0.35),
        ${`inset 0px 4px 0px ${borderColor || '#A84EE5'}`}`,
    },
  },
};

export default WhiteLabelUIPalette;
