import React from 'react';
import PropTypes from 'prop-types';

import Count from '../Count';

const DetailpageCount = ({
  count = '',
  domID = '',
  className = '',
  dataTestId = '',
}) => {
  return (
    <Count
      domID={domID}
      className={className}
      dataTestId={dataTestId}
      count={count}
      size="medium"
    />
  );
};

DetailpageCount.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DetailpageCount;
