import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const LogOut = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Log Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.68198 11.9246C8.87724 12.1199 9.19383 12.1199 9.38909 11.9246C9.58435 11.7294 9.58435 11.4128 9.38909 11.2175L6.70711 8.53553H15.3995C15.6756 8.53553 15.8995 8.31168 15.8995 8.03553C15.8995 7.75939 15.6756 7.53553 15.3995 7.53553H6.70711L9.38909 4.85355C9.58435 4.65829 9.58435 4.34171 9.38909 4.14645C9.19382 3.95118 8.87724 3.95118 8.68198 4.14645L5.14645 7.68198C5.09851 7.72992 5.06234 7.78517 5.03794 7.84414C5.01349 7.9031 5 7.96774 5 8.03553C5 8.10333 5.01349 8.16797 5.03794 8.22693C5.06198 8.28503 5.09744 8.33952 5.14433 8.38696M8.68198 11.9246L5.14694 8.38958L8.68198 11.9246Z"
            fill={fillColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 1C0.776142 1 1 1.22386 1 1.5L0.999999 15.5C0.999999 15.7761 0.776142 16 0.499999 16C0.223857 16 -6.45886e-07 15.7761 -6.33815e-07 15.5L-2.18557e-08 1.5C-9.78513e-09 1.22386 0.223858 1 0.5 1Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Log Out'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 2H2V10H10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

LogOut.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default LogOut;
