import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const HeartFilled = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M2.90545 3.90345C5.44371 1.36519 9.5593 1.36519 12.0976 3.90345C12.2403 4.04614 12.3747 4.19415 12.501 4.3465C12.6271 4.19439 12.7614 4.04648 12.9045 3.90345C15.4427 1.36519 19.5583 1.36519 22.0966 3.90345C24.6348 6.44171 24.6348 10.5573 22.0966 13.0956L12.5 22.6922L2.90545 13.0956C0.367189 10.5573 0.367189 6.44171 2.90545 3.90345Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.31744 2.31746C3.07461 0.560287 5.92399 0.560196 7.68128 2.31719C7.79829 2.43382 7.9039 2.55531 8.00014 2.67955C8.09604 2.55577 8.20115 2.43475 8.31744 2.31846C10.0747 0.561196 12.9243 0.561196 14.6815 2.31846C16.4398 4.07576 16.4398 6.92443 14.6814 8.68168L14.6771 8.68598L8.34911 14.8579C8.15492 15.0474 7.84512 15.0474 7.65091 14.858L1.31741 8.68159C-0.439848 6.92433 -0.43982 4.07472 1.31744 2.31746Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart Filled'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M10.6398 1.90173C10.3744 1.6172 10.0533 1.39031 9.69653 1.23518C9.33972 1.08005 8.95482 1 8.56575 1C8.17669 1 7.79178 1.08005 7.43498 1.23518C7.07818 1.39031 6.75711 1.6172 6.49175 1.90173C6.29457 2.1158 6.1289 2.35689 5.99975 2.61772C5.87054 2.35669 5.70452 2.11556 5.50675 1.90173C5.24124 1.61746 4.92012 1.39081 4.56334 1.23585C4.20656 1.08089 3.82173 1.00093 3.43275 1.00093C3.04377 1.00093 2.65895 1.08089 2.30217 1.23585C1.94539 1.39081 1.62427 1.61746 1.35875 1.90173C0.806968 2.49175 0.5 3.26939 0.5 4.07722C0.5 4.88506 0.806968 5.6627 1.35875 6.25272L5.99975 11.0017L10.6398 6.25373C11.1929 5.66421 11.5008 4.88614 11.5008 4.07773C11.5008 3.26931 11.1929 2.49124 10.6398 1.90173Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

HeartFilled.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default HeartFilled;
