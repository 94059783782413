import React from 'react';
import PropTypes from 'prop-types';
import MenuSectionHeader from '../../../Atoms/MenuSectionHeader';
import List from './List';

const Section = ({
  section = null,
  sectionIndex,
  dataTestId = '',
  titleClass = '',
  activeItems = [],
  maxWidth = '',
  onMenuClick = () => false,
  isMulti = false,
  itemStyles = {},
  listItemClassName = '',
  selectionKey = '',
  hideEmptyMessage = false,
}) => {
  const hasTitle = !Array.isArray(section);

  const sectionItems = hasTitle ? section.sectionItems : section;
  const title = hasTitle ? section.title : '';

  const titleTestId = dataTestId
    ? `${dataTestId}-section-title-${sectionIndex}`
    : null;

  if (sectionItems.length) {
    return (
      <span className="section" key={sectionIndex}>
        {title ? (
          <MenuSectionHeader
            dataTestId={titleTestId}
            title={title}
            className={titleClass}
          />
        ) : null}
        <List
          dataTestId={dataTestId}
          menuItems={sectionItems}
          activeItems={activeItems}
          maxWidth={maxWidth}
          onMenuClick={onMenuClick}
          isMulti={isMulti}
          itemStyles={itemStyles}
          listItemClassName={listItemClassName}
          selectionKey={selectionKey}
          hideEmptyMessage={hideEmptyMessage}
        />
      </span>
    );
  }
  return null;
};

Section.propTypes = {
  sectionIndex: PropTypes.number,
  /** config data for dropdown list sections */
  section: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string.isRequired,
        path: PropTypes.string,
        labelDecoration: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      title: PropTypes.string,
      sectionItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
        }),
      ),
    }),
  ]),

  /**
   * Runs when list item is clicked
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Object} state.activeItem
   * @prop {Number|String} state.activeItem.id
   * @prop {String} state.activeItem.label
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   */
  onMenuClick: PropTypes.func,
  /* eslint-disable react/no-unused-prop-types */
  /** index identifying the active (most recently clicked) menu/list item */
  activeMenuItemIndex: PropTypes.number,
  /** index identifying which section the active item is in */
  activeSectionIndex: PropTypes.number,
  itemStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    dotColor: PropTypes.string,
  }),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleClass: PropTypes.string,
  listItemClassName: PropTypes.string,
  dataTestId: PropTypes.string,
  isMulti: PropTypes.bool,
  selectionKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.bool,
  ]),
  hideEmptyMessage: PropTypes.bool,
};

export default Section;
