import { DROP_DOWN_MAIN } from '../../../commonResources/stackingContext';

export const multiSelectStyles = ({
  openUpward,
  isPortal,
  size,
  menuWidth = '100%',
  theme,
}) => ({
  container: (provided) => ({
    ...provided,
    minHeight: size === 'small' ? '28px' : '32px',
    maxWidth: '100%',
  }),
  control: (provided, state) => {
    const assignBorderColor = () => {
      if (state.isFocused) {
        return theme.UIPalette.Colors.Field.Border.Focus;
      }
      if (state.isDisabled) {
        return theme.UIPalette.BoxShadows.Field.Disabled;
      }
      return theme.UIPalette.Colors.Field.Border.Default;
    };

    return {
      ...provided,
      borderColor: assignBorderColor(),
      backgroundColor: theme.UIPalette.Colors.Background.StandardMenu,
      boxShadow: 'none',
      color: theme.UIPalette.Colors.Content.Primary,
      minHeight: size === 'small' ? '28px' : '32px',
      fontSize: '13px',
      lineHeight: '1.4em',
      textOverflow: 'ellipsis',
      '&:hover': {
        borderColor: state.isFocused
          ? theme.UIPalette.Colors.Field.Border.Hover
          : theme.UIPalette.Colors.Field.Border.Default,
      },
    };
  },
  input: (provided) => ({
    ...provided,
    color: theme.UIPalette.Colors.Content.Secondary,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.UIPalette.Colors.Content.Placeholder,
    fontSize: '13px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: size === 'small' ? '28px' : '30px',
    padding: '0 2px 0 8px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: size === 'small' ? '28px' : '30px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 9px',
    paddingLeft: '5px',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.UIPalette.Colors.Background.StandardMenu,
    margin: 0,
    zIndex: DROP_DOWN_MAIN,
    boxShadow: theme.BoxShadows.Depth15.substring(
      // cutting 'box-shadow:' from value for boxShadow style prop
      11,
      theme.BoxShadows.Depth15.length,
    ),
    ...(openUpward && {
      top: 'auto',
      bottom: isPortal ? 'calc(100% + 32px)' : '100%',
    }),
    width: menuWidth,
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: 'hidden',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 7px',
    paddingRight: '5px',
  }),
});

export default multiSelectStyles;
