import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextStyles from './styles';

const TextWrapper = styled.div`
  ${(props) => TextStyles[props.variant]};
`;

const Text = ({
  variant = 'medium',
  component = 'div',
  color = 'primary',
  children,
  componentProps = {},
  className = '',
  singleLine = false,
}) => (
  <TextWrapper
    color={color}
    singleLine={singleLine}
    variant={variant}
    as={component}
    className={className}
    {...componentProps}
  >
    {children}
  </TextWrapper>
);

Text.propTypes = {
  /** Type of styles to be applied to the component's text */
  variant: PropTypes.oneOf([
    'xxxLarge',
    'xxLarge',
    'xLarge',
    'large',
    'medium',
    'small',
    'xSmall',
    'xxSmall',
    'mediumBold',
    'smallBold',
    'xSmallBold',
    'xSmallCaps',
    'xxSmallCaps',
    'mediumLink',
    'smallLink',
    'xSmallLink',
    'xxSmallLink',
  ]).isRequired,

  /** The component used for the root node. Either a string to use a HTML element or a component. */
  component: PropTypes.elementType,
  /** The content of the component. */
  children: PropTypes.node,
  /** When custom react component is passed as `component` prop, object passed as this prop gets passed down to that custom component.  */
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.object,
  /** The color of the component. It supports three colors: primary, secondary, error. */
  color: PropTypes.oneOf(['primary', 'secondary', 'error']),
  className: PropTypes.string,
  /** When there is no horizontal space available text adds ellipsis */
  singleLine: PropTypes.bool,
};

export default Text;
