import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Warning from '../../../BrandCore/icons/informational/Warning';
// import CircleDashed from '../../../BrandCore/icons/informational/CircleDashed';
import CircleCheck from '../../../BrandCore/icons/informational/CircleCheck';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { Circle0 } from '../../../BrandCore/icons/informational';

/**
 * Mapping of input size to icon size
 */
export const INPUT_TO_ICON_SIZES_MAP = {
  small: 'small',
  medium: 'medium',
  large: 'medium',
};

const getIcon = ({ theme, validationState, size, dataTestId }) => {
  switch (validationState) {
    case 'validating': {
      return (
        <div className="inline-validation-loader">
          <LoadingIndicator
            dataTestId={`${dataTestId}-loading-indicator`}
            length="20px"
          />
        </div>
      );
    }
    case 'valid': {
      return (
        <CircleCheck
          dataTestId={`${dataTestId}-valid-svg`}
          className="inline-validation-icon"
          fillColor={theme.BaseColors.Positive['130']}
          title="Value entered is valid"
          size={INPUT_TO_ICON_SIZES_MAP[size]}
        />
      );
    }
    case 'invalid': {
      return (
        <Warning
          dataTestId={`${dataTestId}-warning-svg`}
          className="inline-validation-icon"
          fillColor={theme.BaseColors.Negative['130']}
          title="Value entered is invalid"
          size={INPUT_TO_ICON_SIZES_MAP[size]}
        />
      );
    }
    default: {
      return (
        <Circle0
          className="inline-validation-icon"
          fillColor={theme.BaseColors.Neutral['30']}
          title="This field needs validation"
          size={INPUT_TO_ICON_SIZES_MAP[size]}
        />
      );
    }
  }
};

const InputValidationIcon = ({
  validationState,
  dataTestId,
  size = 'medium',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Wrapper data-testid={`${dataTestId}-${validationState}`} className={size}>
      {getIcon({ validationState, size, dataTestId, theme })}
    </Wrapper>
  );
};

InputValidationIcon.propTypes = {
  validationState: PropTypes.string,
  dataTestId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default InputValidationIcon;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-left: 10px;
  &.large {
    height: 44px;
  }

  &.small {
    height: 28px;
  }
`;
