import styled from 'styled-components';
import { neutral150 } from '../../../BrandCore/colors/themes/base/basePalette';
import TextStyles from '../../../BrandCore/Text/styles';

const ErrorTitle = styled.h1`
  ${TextStyles.xLarge};
  color: ${neutral150};
  margin: 0;
`;

export default ErrorTitle;
