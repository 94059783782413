export const primary130 = '#0E75A5';
export const primary115 = '#1595D0';
export const primary100 = '#4CC6FF';
export const primary70 = '#82D8FF';
export const primary50 = '#A6E4FF';
export const primary30 = '#CAF0FF';
export const primary15 = '#E5F8FF';
export const primary10 = '#EDF9FF';

export const primary = {
  130: primary130,
  115: primary115,
  100: primary100,
  70: primary70,
  50: primary50,
  30: primary30,
  15: primary15,
  10: primary10,
};

export const secondary100 = '#46EAA7';
export const secondary70 = '#7FF0C2';
export const secondary50 = '#A5F4D4';
export const secondary30 = '#CBF8E6';
export const secondary15 = '#E5FCF3';

export const secondary = {
  100: secondary100,
  70: secondary70,
  50: secondary50,
  30: secondary30,
  15: secondary15,
};

export const tertiary100 = '#8400D9';
export const tertiary70 = '#A84EE5';
export const tertiary50 = '#C082ED';
export const tertiary30 = '#D8B6F5';
export const tertiary15 = '#ECDBFA';

export const tertiary = {
  100: tertiary100,
  70: tertiary70,
  50: tertiary50,
  30: tertiary30,
  15: tertiary15,
};

export const quaternary100 = '#EA40AC';
export const quaternary70 = '#F079C4';
export const quaternary50 = '#F49FD4';
export const quaternary30 = '#F8C5E4';
export const quaternary15 = '#FCE2F2';

export const quaternary = {
  100: quaternary100,
  70: quaternary70,
  50: quaternary50,
  30: quaternary30,
  15: quaternary15,
};

export const negative130 = '#BF1616';
export const negative100 = '#D63E3E';
export const negative70 = '#E47979';
export const negative50 = '#EC9F9F';
export const negative30 = '#F4C5C5';
export const negative15 = '#FAE2E2';

export const negative = {
  130: negative130,
  100: negative100,
  70: negative70,
  50: negative50,
  30: negative30,
  15: negative15,
};

export const caution100 = '#FFC107';
export const caution70 = '#FFD352';
export const caution50 = '#FFDF84';
export const caution30 = '#FFEBB6';
export const caution15 = '#FFF5DB';

export const caution = {
  100: caution100,
  70: caution70,
  50: caution50,
  30: caution30,
  15: caution15,
};

export const positive130 = '#358600';
export const positive100 = '#44AD00';
export const positive70 = '#7DC54E';
export const positive50 = '#A3D582';
export const positive30 = '#C9E5B6';
export const positive15 = '#E4F2DB';

export const positive = {
  130: positive130,
  100: positive100,
  70: positive70,
  50: positive50,
  30: positive30,
  15: positive15,
};

export const neutralAlt100 = '#2E5A83';
export const neutralAlt75 = '#3B6892';
export const neutralAlt70 = '#47729A';
export const neutralAlt50 = '#829FBC';
export const neutralAlt30 = '#AABFD5';
export const neutralAlt15 = '#C6D7E8';

export const neutralAlt = {
  100: neutralAlt100,
  75: neutralAlt75,
  70: neutralAlt70,
  50: neutralAlt50,
  30: neutralAlt30,
  15: neutralAlt15,
};

export const accent130 = '#B80928';
export const accent100 = '#E90C32';
export const accent50 = '#FF899D';
export const accent30 = '#F9B8C4';
export const accent15 = '#FDD3DA';

export const accent = {
  130: accent130,
  100: accent100,
  50: accent50,
  30: accent30,
  15: accent15,
};

export const neutral170 = '#0B0B41';
export const neutral150 = '#0F0F59';
export const neutral100 = '#626D8A';
export const neutral70 = '#8891AA';
export const neutral50 = '#A5ACBF';
export const neutral30 = '#CACFDC';
export const neutral15 = '#DEE4EC';
export const neutral10 = '#EAEEF4';
export const neutral5 = '#F0F3F8';
export const neutral0 = '#F4F6F9';

export const neutral = {
  170: neutral170,
  150: neutral150,
  100: neutral100,
  70: neutral70,
  50: neutral50,
  30: neutral30,
  15: neutral15,
  10: neutral10,
  5: neutral5,
  0: neutral0,
};

export const white = '#fff';
export const black = '#000';

export default {
  primary,
  secondary,
  tertiary,
  quaternary,
  negative,
  caution,
  positive,
  neutralAlt,
  accent,
  neutral,
  primary130,
  primary115,
  primary100,
  primary70,
  primary50,
  primary30,
  primary15,
  primary10,
  secondary100,
  secondary70,
  secondary50,
  secondary30,
  secondary15,
  tertiary100,
  tertiary70,
  tertiary50,
  tertiary30,
  tertiary15,
  quaternary100,
  quaternary70,
  quaternary50,
  quaternary30,
  quaternary15,
  negative130,
  negative100,
  negative70,
  negative50,
  negative30,
  negative15,
  caution100,
  caution70,
  caution50,
  caution30,
  caution15,
  positive130,
  positive100,
  positive70,
  positive50,
  positive30,
  positive15,
  neutral150,
  neutralAlt100,
  neutralAlt75,
  neutralAlt70,
  neutralAlt50,
  neutralAlt30,
  neutralAlt15,
  accent130,
  accent100,
  accent30,
  neutral100,
  neutral70,
  neutral50,
  neutral30,
  neutral15,
  neutral10,
  neutral5,
  neutral0,
  white,
  black,
};

/* Colors that are no longer supported in design language v1.5 */
// const deprecatedColors = {
//   // removed
//   brightBlue150: '#076592',
//   grey20: '#D3D5DC',
//   grey75: '#737D85',
//   chBlue50: '#9E9EC4',
//   chBlue70: '#3F437A',
//   negative150: '#A10B0B',
//   digitalBlue40: '#B3D9EC',

//   // replaced
//   grey5: '#F2F3F4',
//   grey10: '#EBECED',
//   grey15: '#E1E2E6',
//   grey30: '#C3C5CD',
//   grey50: '#9BA1A9',
//   grey70: '#737D85',
//   grey100: '#37474F',
//   chBlue100: '#0F0F59',
//   chRed100: '#F42A4D',

//   // removed but still using internally with new "neutral" name
//   transparentGrey10: 'rgba(55,71,79,0.12)',
//   transparentGrey20: 'rgba(55,71,79,0.2)',
//   transparentGrey30: 'rgba(55,71,79,0.3)',
//   transparentGrey50: 'rgba(55,71,79,0.5)',
//   transparentGrey100: 'rgba(55,71,79,0.9)',
//   lessTransparentGrey100: 'rgba(55,71,79,0.97)',
// };
