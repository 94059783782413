const defaults = {
  restUrl: 'https://products-api-test.chhc.io/uicore/notifications',
  webSocketUrl: 'wss://dw3c9316dc.execute-api.us-east-1.amazonaws.com/test'
}

export const initializeNotifications = config => {
  sessionStorage.setItem(
    'notificationsConfig',
    JSON.stringify({
      ...defaults,
      ...config
    })
  )
}
