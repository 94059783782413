import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.span`
  position: relative;
  margin-right: auto;
  ${TextStyles.xxSmallCaps};

  .inline-quality-dropdown {
    ${TextStyles.xxSmallCaps};
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Background
        .Masthead} !important; /* stylelint-disable-line declaration-no-important */
    color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentLight};
    min-width: 150px;
    white-space: pre;
    left: 0;
    ${(props) => props.theme.BoxShadows.Depth15}
    border-radius: 0px 0px 8px 8px;
    button {
      ${TextStyles.xxSmallCaps};
      background-color: ${(props) =>
        props.theme.UIPalette.Colors.Background
          .Masthead} !important; /* stylelint-disable-line declaration-no-important */
      color: ${(props) =>
        props.theme.UIPalette.Colors.Content
          .PersistentLight} !important; /* stylelint-disable-line declaration-no-important */

      &.selected.single {
        background-color: ${(props) =>
          props.theme.UIPalette.Colors.Background
            .ProductSwitcher} !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .explore-button {
      color: ${(props) =>
        props.theme.BaseColors.Primary[
          '100'
        ]}; !important; /* stylelint-disable-line declaration-no-important */
      box-shadow: ${(props) =>
        `inset 0 1px 0 0 ${props.theme.BaseColors.Neutral['100']}`};
      &:focus {
        border: none;
        box-shadow: inset 0 0 0 1px ${(props) =>
          props.theme.UIPalette.Colors.Field.Border.Focus};
      }
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
