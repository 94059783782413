import React, { useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Caret from '../../BrandCore/icons/interactive/Caret';
import CaretUp from '../../BrandCore/icons/interactive/CaretUp';
import GridColumnHeaderWrapper, {
  GridColumnHeaderChildWrapper,
  GridColumnHeaderWrapperDiv,
} from './ColumnHeader.styles';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';
import Tooltip from '../Tooltip';

const GridColumnHeader = ({
  sortable = false,
  onSort,
  isSorted = false,
  sortingDirection = null,
  text = '',
  align = 'left',
  children = null,
  tooltipWidth = 150,
  tooltipPosition = 'top-center',
  headerWidth = 150,
  domID = null,
  isHeaderTruncated = false,
}) => {
  const theme = useContext(ThemeContext);
  const ref = useRef();
  const truncated = useIsTruncated(ref, [text]);

  const SortedIcon = () =>
    sortingDirection < 0 ? (
      <Caret size="small" fillColor={theme.UIPalette.Colors.Content.Primary} />
    ) : (
      <CaretUp
        size="small"
        fillColor={theme.UIPalette.Colors.Content.Primary}
      />
    );

  if (!isHeaderTruncated) {
    return (
      <GridColumnHeaderWrapperDiv>
        <GridColumnHeaderWrapper
          as={sortable ? 'button' : 'div'}
          className={clsx({
            'align-center': align === 'center',
            'align-left': align === 'left',
            'align-right': align === 'right',
            sortable,
            sorted: isSorted,
          })}
          onClick={sortable ? onSort : () => false}
        >
          {text}
          {/* eslint-disable-next-line no-nested-ternary */}
          {isSorted ? (
            <SortedIcon />
          ) : (
            // We need the contents of the header item to be the same whether
            // or not it is sorted, so that the widths don't change on sort.
            <div className="spacer" />
          )}
        </GridColumnHeaderWrapper>

        <GridColumnHeaderChildWrapper>{children}</GridColumnHeaderChildWrapper>
      </GridColumnHeaderWrapperDiv>
    );
  }

  return (
    <GridColumnHeaderWrapperDiv>
      <GridColumnHeaderWrapper
        withToolTip
        headerWidth={headerWidth}
        align={align}
        className={clsx({
          sortable,
          sorted: isSorted,
        })}
      >
        <Tooltip
          tooltipContent={text}
          domID={`${domID}-column-header-tooltip`}
          hideTooltip={!truncated}
          tooltipPosition={tooltipPosition}
          tooltipWidth={tooltipWidth}
          onClick={onSort}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSort(event);
            }
          }}
          style={{
            minWidth: `${headerWidth}px`,
            display: 'inline-flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '0 8px',
          }}
        >
          <span
            ref={ref}
            className={clsx('grid-column-header-text', {
              'align-center': align === 'center',
              'align-left': align === 'left',
              'align-right': align === 'right',
            })}
          >
            {text}
          </span>
          {isSorted ? (
            <SortedIcon />
          ) : (
            // We need the contents of the header item to be the same whether
            // or not it is sorted, so that the widths don't change on sort.
            <div className="spacer" />
          )}
        </Tooltip>
      </GridColumnHeaderWrapper>

      <GridColumnHeaderChildWrapper>{children}</GridColumnHeaderChildWrapper>
    </GridColumnHeaderWrapperDiv>
  );
};

GridColumnHeader.propTypes = {
  sortable: PropTypes.bool,
  isHeaderTruncated: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  isSorted: PropTypes.bool,
  sortingDirection: PropTypes.number,
  text: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node,
  headerWidth: PropTypes.number,
  domID: PropTypes.string,
  tooltipWidth: PropTypes.number,
  tooltipPosition: PropTypes.string,
};

export default GridColumnHeader;
