import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Transition from 'react-transition-group/Transition';
import MicroModalComponent from './components/microModal';
import Arrow from './components/arrow';
import Button from '../../Atoms/Button';
import { useClickAway } from '../../commonResources/hooks/useClickAway';
import { MICRO_MODAL_ANIMATION } from '../../BrandCore/constants/timings';

const getPosition = (ref, direction) => {
  const portalWrapper =
    ref && ref.current ? ref.current.getBoundingClientRect() : {};
  switch (direction) {
    case 'top':
      return {
        top: portalWrapper.top,
        left: portalWrapper.left + portalWrapper.width / 2,
      };
    case 'right':
      return {
        top: portalWrapper.top + portalWrapper.height / 2,
        left: portalWrapper.left + portalWrapper.width,
      };
    case 'bottom':
      return {
        top: portalWrapper.top + portalWrapper.height,
        left: portalWrapper.left + portalWrapper.width / 2,
      };
    case 'left':
      return {
        top: portalWrapper.top + portalWrapper.height / 2,
        left: portalWrapper.left,
      };

    default:
      return {};
  }
};

export const MicroModal = ({
  domID = null,
  dataTestId = '',
  trigger = 'click',
  anchorComponent: Anchor = Button,
  anchorProps = {},
  initialIsOpen = false,
  children = null,
  orientation = 'top',
  onClick = () => false,
  onMouseOver = () => false,
  onMouseOut = () => false,
  onClickAway = () => false,
  caretToAnchor = 8,
  addInViewPort = false,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [portalPosition, setPortalPosition] = useState({});
  const nodeRef = React.useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) setPortalPosition(getPosition(ref, orientation));
  }, [ref]);

  useEffect(() => {
    setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  const handleClick = (event, wasOpen) => {
    if (trigger === 'click') {
      setIsOpen(!wasOpen);
      onClick(event, { isOpen: !wasOpen });
    }
  };

  const handleMouseOver = (event) => {
    if (trigger === 'hover') {
      setIsOpen(true);
      onMouseOver(event, { isOpen: true });
    }
  };

  const handleMouseOut = (event) => {
    if (trigger === 'hover') {
      setIsOpen(false);
      onMouseOut(event, { isOpen: false });
    }
  };

  const onClickOut = () => {
    setIsOpen(false);
    onClickAway();
  };

  useClickAway(ref, () => onClickOut());

  const marginMap = {
    bottom: `${caretToAnchor + 4}px 0 0 0`,
    left: `0 ${caretToAnchor + 4}px 0 0`,
    top: `0 0 ${caretToAnchor + 4}px 0`,
    right: `0 0 0 ${caretToAnchor + 4}px`,
  };

  const defaultStyle = {
    transition: `opacity ${200}ms, margin ${200}ms`,
    margin: '0 0 0 0',
    opacity: 0,
    visibility: 'hidden',
  };

  const transitionStyles = {
    // nested so we can use square bracket notation with a variable to access it
    entered: {
      opacity: 1,
      margin: `${marginMap[orientation]}`,
      visibility: 'visible',
    },
  };

  const Modal = () => {
    if (!isOpen) return null;

    return (
      <Transition in={isOpen} timeout={MICRO_MODAL_ANIMATION} nodeRef={nodeRef}>
        {(state) => (
          <MicroModalComponent
            className={`microModal ${orientation}`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="container">{children}</div>
            <Arrow className={`arrow ${orientation}`} />
          </MicroModalComponent>
        )}
      </Transition>
    );
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      id={domID}
      ref={ref}
      data-testid={dataTestId}
    >
      <Anchor
        dataTestId={`${dataTestId}-button`}
        onClick={(e) => handleClick(e, isOpen)}
        onMouseOver={(e) => handleMouseOver(e)}
        onFocus={(e) => handleMouseOver(e)}
        onMouseOut={(e) => handleMouseOut(e)}
        onBlur={(e) => handleMouseOut(e)}
        {...anchorProps}
      />
      {addInViewPort && isOpen ? (
        ReactDOM.createPortal(
          isOpen ? (
            <div
              style={{
                zIndex: 100,
                position: 'absolute',
                ...portalPosition,
              }}
            >
              <Modal />
            </div>
          ) : null,
          document.body,
        )
      ) : (
        <Modal />
      )}
    </div>
  );
};

MicroModal.propTypes = {
  /** By default, clicking the anchorComponent will toggle the modal. Hover can be used instead. */
  trigger: PropTypes.oneOf(['click', 'hover']),
  /** Component used as a trigger */
  anchorComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /* eslint-disable react/forbid-prop-types */
  /** Additional props to be passed down to anchorComponent */
  anchorProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  children: PropTypes.node,
  /** distance in px from anchor component to the caret on the modal */
  caretToAnchor: PropTypes.number,
  initialIsOpen: PropTypes.bool,
  orientation: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClickAway: PropTypes.func,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** Adding Modal in to view port default value is false */
  addInViewPort: PropTypes.bool,
};

export default MicroModal;
