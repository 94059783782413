import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextWrapper from './styleWrappers/TextWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const IconWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  ${({ cellAlign }) =>
    cellAlign === 'right' ? 'margin-left: 8px' : 'margin-right: 8px'};
  svg {
    flex-shrink: 0;
  }
`;

const FirstLine = styled(TextWrapper)`
  margin-top: 8px;
  ${({ hasSecondLine }) => !hasSecondLine && 'margin-bottom: 8px;'}
`;

const SecondLine = styled(SecondLineWrapper)`
  ${({ align, hasText }) =>
    hasText && align === 'right' && 'margin-right: 20px'};
  ${({ align, hasText }) =>
    hasText && align !== 'right' && 'margin-left: 20px'};
`;

const IconCellWrapper = styled.div`
  display: inline-flex;
  ${(props) => props.cellAlign === 'right' && 'flex-direction: row-reverse;'}
`;

const IconCell = ({
  data: {
    icon: IconComponent = null,
    iconProps = {},
    text = '',
    secondLine = null,
  },
  cellAlign = 'left',
}) => {
  const alignText = cellAlign === 'center' ? 'left' : cellAlign;
  return (
    <>
      <IconCellWrapper cellAlign={cellAlign}>
        <IconWrapper hasText={!!text} cellAlign={cellAlign}>
          {IconComponent && <IconComponent size="small" {...iconProps} />}
        </IconWrapper>
        {text ? (
          <FirstLine align={alignText} hasSecondLine={!!secondLine}>
            {text}
          </FirstLine>
        ) : null}
      </IconCellWrapper>
      {secondLine ? (
        <SecondLine align={alignText} hasText={!!text}>
          {secondLine}
        </SecondLine>
      ) : null}
    </>
  );
};

IconCell.propTypes = {
  data: PropTypes.shape({
    /** This should be a ui-core icon component, passed as `IconName`, not `<IconName/>` */
    icon: PropTypes.func.isRequired,
    /** props to be passed to the icon */
    iconProps: PropTypes.shape({
      domID: PropTypes.string,
      className: PropTypes.string,
      dataTestId: PropTypes.string,
      fillColor: PropTypes.string,
      title: PropTypes.string,
      size: PropTypes.string,
    }),
    /** optional, displayed next to icon */
    text: PropTypes.string,
    /** optional, displayed below icon and text */
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default IconCell;
