import React from 'react';
import PropTypes from 'prop-types';
import { useLayout } from './index';
import NavigationPane from '../../Organisms/NavigationPane/NavigationPane';
import { navigationPaneAnimStates } from './commonResources/animStates';

const LayoutNavigationPane = ({
  children,
  dataTestId = '',
  className = '',
}) => {
  const {
    navigationPaneAnimationState,
    onNavigationPaneExpandCollapse,
    advanceNavigationPaneAnimation,
    retreatNavigationPaneAnimation,
    isSidebarShown,
  } = useLayout();

  return (
    <NavigationPane
      animStates={navigationPaneAnimStates}
      animState={navigationPaneAnimationState}
      onExpandButtonClicked={onNavigationPaneExpandCollapse}
      advanceAnimation={advanceNavigationPaneAnimation}
      retreatAnimation={retreatNavigationPaneAnimation}
      isSidebarVisible={isSidebarShown}
      dataTestId={dataTestId}
      className={className}
    >
      {children}
    </NavigationPane>
  );
};

LayoutNavigationPane.propTypes = {
  /**
   * dataTestId passed down to underlying Navigation Pane
   */
  dataTestId: PropTypes.string,
  /**
   * Content to render inside of Navigation Pane
   */
  children: PropTypes.node,

  className: PropTypes.string,
};

export default LayoutNavigationPane;
