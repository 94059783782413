import React from 'react';
import PropTypes from 'prop-types';
import { useLayout, withLayout } from './index';
import FadeTransition from './Panes/common/FadeTransition';
import DetailPane from './Panes/DetailPane/DetailPane';
import LayoutNavigationPane from './NavigationPane';
import MainContentExpandCollapseTransition from './Panes/ListPane/MainContentExpandCollapseTransition';
import {
  detailPaneAnimStates,
  navigationPaneAnimStates,
} from './commonResources/animStates';
import Masthead from '../../Organisms/Masthead/Masthead';
import {
  COLLAPSED_PANE_WIDTH,
  NAVIGATION_PANE_WIDTH,
  SIDEBAR_WIDTH,
} from '../../BrandCore/constants/widths';
import NotificationBanner from '../../Molecules/NotificationBanner/NotificationBanner';
import { useNotificationBanner } from './NotificationBanner/useNotificationBanner';
import MainContentCollapsedButton from './Panes/ListPane/MainContentCollapsedButton';

const PageLayout = ({
  mastheadProps = {},
  navigationPaneContent = null,
  listPaneContent = null,
  detailPaneContent = null,
  dataTestId = '',
  className = '',
  detailPaneExpandWidth,
}) => {
  const {
    navigationPaneAnimationState,
    detailAnimationState,
    expandCollapseDetailPane,
    isSidebarShown,
    setShowSidebar,
  } = useLayout();

  const determineNavigationPanePadding = () => {
    let padding =
      navigationPaneAnimationState >= navigationPaneAnimStates.EXPAND_COLLAPSE
        ? COLLAPSED_PANE_WIDTH
        : NAVIGATION_PANE_WIDTH;
    if (isSidebarShown) {
      padding += SIDEBAR_WIDTH;
    }
    return padding;
  };

  const navigationPanePadding = determineNavigationPanePadding();

  const { message = {}, notify } = useNotificationBanner();

  const testIDs = {
    masthead: dataTestId ? `${dataTestId}-masthead` : '',
    navigationPane: dataTestId ? `${dataTestId}-navigation-pane` : '',
    listPane: dataTestId ? `${dataTestId}-list-pane` : '',
  };

  const classNames = {
    navigationPane: className ? `${className}-navigation-pane` : '',
    listPane: className ? `${className}-list-pane` : '',
    detailPane: className ? `${className}-detail-pane` : '',
  };

  return (
    <>
      {message.text && (
        <NotificationBanner
          autoDismiss
          onDismiss={() => notify('')}
          {...message}
        />
      )}
      <Masthead
        initialShowSidebar={isSidebarShown}
        {...mastheadProps}
        onSidebarCollapse={() => {
          setShowSidebar(false);
        }}
        onMenuExpand={() => {
          setShowSidebar(true);
        }}
        dataTestId={testIDs.masthead}
      />
      <LayoutNavigationPane
        dataTestId={testIDs.navigationPane}
        className={classNames.navigationPane}
      >
        {navigationPaneContent}
      </LayoutNavigationPane>
      <MainContentExpandCollapseTransition
        navigationPanePadding={navigationPanePadding}
        onIn={detailAnimationState >= detailPaneAnimStates.EXPANDED}
        fullExpand={detailAnimationState >= detailPaneAnimStates.FULL_EXPAND}
        dataTestId={testIDs.listPane}
        className={classNames.listPane}
        width={`calc(100% - ${detailPaneExpandWidth} - ${navigationPanePadding}px)`}
      >
        <FadeTransition
          onIn={detailAnimationState >= detailPaneAnimStates.FULL_EXPAND}
          onExited={() => false}
          onEntered={() => false}
          timeout={{
            enter: 200,
            exit: 0,
          }}
          shouldMountAndUnmount
        >
          <MainContentCollapsedButton
            onClick={() => expandCollapseDetailPane()}
          />
        </FadeTransition>
        <FadeTransition
          onIn={detailAnimationState < detailPaneAnimStates.FULL_EXPAND}
          onExited={() => false}
          onEntered={() => false}
          timeout={{ enter: 0, exit: 0 }}
        >
          <main>{listPaneContent && listPaneContent}</main>
        </FadeTransition>
        <DetailPane
          navigationPanePadding={navigationPanePadding}
          className={classNames.detailPane}
          expandWidth={detailPaneExpandWidth}
        >
          {detailPaneContent && detailPaneContent}
        </DetailPane>
      </MainContentExpandCollapseTransition>
    </>
  );
};

PageLayout.propTypes = {
  /**
   * Test Id for different containers/boxes
   */
  dataTestId: PropTypes.string,
  /**
   * Class name to add to different containers/boxes:
   * Navigation pane will get class name of `<class-passed-as-prop>-navigation-pane`
   * List Pane will get class name of `<class-passed-as-prop>-list-pane`
   * Detail Pane will get class name of `<class-passed-as-prop>-detail-pane`
   *
   * For example if you pass `my-page` as className prop, navigation pane will have class name of `my-page-navigation-pane`
   */
  className: PropTypes.string,
  /**
   * Content to render in navigation pane.
   */
  navigationPaneContent: PropTypes.node,
  /**
   * Content to render in List Pane
   */
  listPaneContent: PropTypes.node,
  /**
   * Content to render in detail pane. If null this layout works as Discovery
   */
  detailPaneContent: PropTypes.node,
  /**
   * Props that Masthead takes. See Masthead for details.
   */
  // eslint-disable-next-line react/forbid-prop-types
  mastheadProps: PropTypes.object,
  /**
   * Expand width for detail pane
   */
  detailPaneExpandWidth: PropTypes.string,
};

const PageLayoutExport = withLayout(PageLayout);

export { PageLayoutExport as PageLayout };

export default PageLayoutExport;
