import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { useClickAway } from '../../../../commonResources/hooks/useClickAway';

import Tooltip from '../../../../Atoms/Tooltip';
import MenuContainer from './styles/MenuContainer';
import MenuClickTarget from './styles/MenuClickTarget';
import Bell from '../../../../BrandCore/icons/interactive/Bell';
import Text from '../../../../BrandCore/Text/Text';

import Notification from '../../../../Molecules/Notification';
import NotificationBadge from './styles/NotificationBadge';
import MenuSectionHeader from '../../../../Atoms/MenuSectionHeader';
import NotificationMenuFooter from './NotificationMenuFooter';
import PopupNotification from './PopupNotification/PopupNotification';
import NotificationModal from './NotificationModal/NotificationModal';
import { Cog } from '../../../../BrandCore/icons';

const Wrapper = styled.span`
  position: relative;
`;

const ScrollableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const List = styled.div`
  padding: 0 24px;
  > div:last-child {
    margin-bottom: 0px;
  }
`;

const NotificationsList = styled(List)`
  margin-bottom: 24px;

  > div {
    margin-bottom: 16px;
  }
`;

const NoNotificationsWrapper = styled.div`
  padding-top: 19px;
  padding-bottom: 8px;
`;

export const NotificationsMenu = ({
  onOpenClose = () => false,
  notifications = [],
  markAllAsRead = () => false,
  archiveAll = () => false,
  markAsRead = () => false,
  archive = () => false,
  viewArchive = () => false,
  onItemClick = () => false,
  onPopupNotificationClick = () => false,
  markAsReadPopupNotification = () => false,
  archivePopupNotification = () => false,
  popupNotifications = [],
  popupNotificationTime = undefined,
  popupNotificationAnimationTime = undefined,
  popupNotificationContainerElement = undefined,
  onPopupNotificationFinish = () => false,
  customTheme = {},
  popupNotificationWrapperClassName = undefined,
  popupNotificationWrapperStyle = undefined,
  notificationModalRemoveFromDOMOnClose = true,
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notificationModal, setNotificationModal] = useState();

  const toggleMenu = (forceState = null) => {
    const menuWasOpen = isMenuOpen;
    const menuIsNowOpen = forceState !== null ? forceState : !menuWasOpen;
    const newState = { isMenuOpen: menuIsNowOpen };
    setIsMenuOpen(menuIsNowOpen);
    onOpenClose(newState);
  };

  const ref = useRef(null);
  useClickAway(ref, () => {
    if (!notificationModal) {
      toggleMenu(false);
    }
  });

  const count = useMemo(
    () =>
      notifications.reduce(
        (prevValue, notification) =>
          notification.isRead ? prevValue : prevValue + 1,
        0,
      ),
    [notifications],
  );

  const onNotificationClick = (notificationId) => {
    setNotificationModal(
      notifications.find((notification) => notification.id === notificationId),
    );
    markAsRead(notificationId);
    onItemClick(notificationId);
  };

  const onPopupNotificationClickHandler = (notification) => {
    setNotificationModal(notification);
    markAsReadPopupNotification(notification.id);
    onPopupNotificationClick(notification);
  };

  const onModalClickHandler = () => {
    setNotificationModal();
  };

  const onNotificationSettingsClickHandler = () => {};

  return (
    <>
      {[...popupNotifications].reverse().map((popupNotificationItem, index) => {
        return (
          <PopupNotification
            key={popupNotificationItem.id}
            popupNotification={popupNotificationItem}
            durationTime={popupNotificationTime}
            animationTime={popupNotificationAnimationTime}
            containerElement={popupNotificationContainerElement}
            onFinish={onPopupNotificationFinish}
            onClick={onPopupNotificationClickHandler}
            onArchive={archivePopupNotification}
            onMarkAsRead={markAsReadPopupNotification}
            className={popupNotificationWrapperClassName}
            style={popupNotificationWrapperStyle}
            index={index + 1}
          />
        );
      })}
      <NotificationModal
        notification={notificationModal}
        onOkClick={onModalClickHandler}
        onCloseClick={onModalClickHandler}
        removeFromDOMOnClose={notificationModalRemoveFromDOMOnClose}
      />
      <Wrapper ref={ref}>
        <Tooltip
          domID="notifications-button"
          tooltipContent="Notifications"
          tooltipWidth={100}
          tooltipPosition="bottom-center"
          isButtonRoleDisabled
          disablePointerEvents
          style={{ height: '100%' }}
        >
          <MenuClickTarget
            customTheme={customTheme}
            onClick={() => toggleMenu(!isMenuOpen)}
            className={isMenuOpen ? 'open' : null}
          >
            <Bell
              size="medium"
              fillColor={
                isMenuOpen
                  ? theme.UIPalette.Colors.Content.Primary
                  : theme.UIPalette.Colors.Content.PersistentLight
              }
            />
            {count > 0 ? (
              <NotificationBadge className="notification-badge">
                {count > 99 ? '99+' : count}
              </NotificationBadge>
            ) : null}
          </MenuClickTarget>
        </Tooltip>
        {isMenuOpen ? (
          <MenuContainer data-testid="notifications-menu">
            <ScrollableContainer>
              <MenuSectionHeader title="notifications" />
              <NotificationsList>
                {notifications.length ? (
                  notifications.map((notification) => (
                    <Notification
                      key={notification.id}
                      {...notification}
                      onMarkAsReadClick={markAsRead}
                      onArchiveClick={archive}
                      onClick={onNotificationClick}
                    />
                  ))
                ) : (
                  <NoNotificationsWrapper>
                    <Text variant="mediumBold">You have no notifications</Text>
                  </NoNotificationsWrapper>
                )}
              </NotificationsList>
            </ScrollableContainer>
            <NotificationMenuFooter
              dataTestId="notification-actions"
              items={
                notifications.length
                  ? [
                      {
                        id: '1',
                        onClick: markAllAsRead,
                        label: 'Mark all as read',
                        dataTestId: 'mark-all-as-read',
                      },
                      {
                        id: '2',
                        onClick: archiveAll,
                        label: 'Archive all',
                        dataTestId: 'archive-all',
                      },
                    ]
                  : [
                      {
                        id: '1',
                        onClick: markAllAsRead,
                        label: 'Mark all as read',
                        dataTestId: 'mark-all-as-read',
                      },
                      {
                        id: '2',
                        onClick: viewArchive,
                        label: 'View Archive',
                        dataTestId: 'view-archive',
                      },
                    ]
              }
              rightItem={{
                label: 'Notification Center',
                onClick: onNotificationSettingsClickHandler,
                icon: Cog,
              }}
            />
          </MenuContainer>
        ) : null}
      </Wrapper>
    </>
  );
};

NotificationsMenu.propTypes = {
  onOpenClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  announcements: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array,
  markAsRead: PropTypes.func,
  markAllAsRead: PropTypes.func,
  archive: PropTypes.func,
  archiveAll: PropTypes.func,
  viewArchive: PropTypes.func,
  onItemClick: PropTypes.func,
  onPopupNotificationClick: PropTypes.func,
  markAsReadPopupNotification: PropTypes.func,
  archivePopupNotification: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  popupNotifications: PropTypes.array,
  popupNotificationTime: PropTypes.number,
  popupNotificationAnimationTime: PropTypes.number,
  popupNotificationContainerElement: PropTypes.element,
  onPopupNotificationFinish: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
  popupNotificationWrapperClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  popupNotificationWrapperStyle: PropTypes.object,
  notificationModalRemoveFromDOMOnClose: PropTypes.bool,
};

export default NotificationsMenu;
