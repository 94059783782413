import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

export const Loader = ({
  className = null,
  title = null,
  domID = null,
  width = 20,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      className={className}
      id={domID}
      width={`${width}px`}
      height={`${width}px`}
      viewBox="-6 -6 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke={theme.BaseColors.Neutral['30']}
      role={title === '' ? 'presentation' : 'img'}
      {...(title === '' && { alt: '' })}
      aria-label={title !== null ? title : 'loader'}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="4">
          <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            transform="rotate(285.765 18 18)"
          />
        </g>
      </g>
    </svg>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  /** if not passed, icon has an aria-label of the icon name.
   * If an empty string is passed, icon is given alt="" and role="presentation", making it decorative.
   * if any string is passed, it is given to aria-label.
   */
  title: PropTypes.string,
  domID: PropTypes.string,
  width: PropTypes.number,
};

export default Loader;
