import React from 'react';
import { components } from 'react-select';

export const Menu = (props) => {
  // eslint-disable-next-line react/prop-types
  const { selectProps: { id = '' } = {} } = props;
  return (
    <div id={`${id}-menu`}>
      <components.Menu {...props} />
    </div>
  );
};

export default Menu;
