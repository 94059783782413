import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Modules = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Modules'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#solution-large)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M1 0.500122H0.5V1.00012V15.0001V15.5001H1H8.5V23.0001V23.5001H9H23H23.5V23.0001V9.00012V8.50012H23H15.5V1.00012V0.500122H15H1ZM14.5 8.50012V1.50012H1.5V14.5001H8.5V9.00012V8.50012H9H14.5ZM9.5 14.5001V9.50012H14.5V14.5001H9.5ZM9.5 15.5001H15H15.5V15.0001V9.50012H22.5V22.5001H9.5V15.5001Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="solution-large">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Modules'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 0.5H0.5V1V9.90908V10.4091H1H5.59091V15V15.5H6.09091H15H15.5V15V6.09091V5.59091H15H10.4091V1V0.5H9.90908H1ZM9.40908 5.59091V1.5H1.5V9.40908H5.59091V6.09091V5.59091H6.09091H9.40908ZM6.59091 9.40908V6.59091H9.40908V9.40908H6.59091ZM6.59091 10.4091H9.90908H10.4091V9.90908V6.59091H14.5V14.5H6.59091V10.4091Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Modules'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 0.5H0.5V1V7.36363V7.86363H1H4.13637V11V11.5H4.63637H11H11.5V11V4.63637V4.13637H11H7.86363V1V0.5H7.36363H1ZM6.86363 4.13637V1.5H1.5V6.86363H4.13637V4.63637V4.13637H4.63637H6.86363ZM5.13637 6.86363V5.13637H6.86363V6.86363H5.13637ZM5.13637 7.86363H7.36363H7.86363V7.36363V5.13637H10.5V10.5H5.13637V7.86363Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Modules.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Modules;
