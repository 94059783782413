import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextStyles from '../../BrandCore/Text/styles';

const SectionHeader = styled.span`
  display: block;
  ${TextStyles.xxSmallCaps};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  padding: 4px 24px 10px 24px;
  letter-spacing: 0.5px;
  text-align: left;
  white-space: nowrap;
  position: relative;
  border: none;
  width: 100%;
  outline: none;
`;

const MenuSectionHeader = ({
  domID = '',
  dataTestId = '',
  title = '',
  className = '',
}) => (
  <SectionHeader id={domID} data-testid={dataTestId} className={className}>
    {title}
  </SectionHeader>
);

MenuSectionHeader.propTypes = {
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  /** descriptive title of section of dropdown list items */
  title: PropTypes.string,
};

export default MenuSectionHeader;
