import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Wrapper from '../Input/components/Wrapper';
import Label from '../Input/components/InputLabel';
import TextInput from '../Input/components/TextInput';
import ErrorMessage from '../Input/components/ErrorMessage';

import LabelWrapper from './components/LabelWrapper';

import { useFocus } from '../../commonResources/hooks/useFocus';
import { useHover } from '../../commonResources/hooks/useHover';
import { useKeyboard } from '../../commonResources/hooks/useKeyboard';

const StyledInput = styled(TextInput)`
  resize: ${(props) => props.resize || 'none'};
  height: 80px;
  &.large,
  &.small {
    height: 80px;
  }
`;

export const TextArea = React.forwardRef(
  (
    {
      className = '',
      label = null,
      labelProps = {},
      name = null,
      hasError = false,
      errorMessage = null,

      initialValue = '',

      domID = null,
      dataTestId = '',
      disabled = false,
      placeholder = null,
      maxLength = null,
      autoFocus = false,
      readOnly = false,
      hasCounter = false,

      onClick = () => false,
      onFocus = () => false,
      onBlur = () => false,
      onMouseOver = () => false,
      onMouseOut = () => false,
      onKeyDown = () => false,
      onChange = () => false,
      onKeyUp = () => false,
      onKeyPress = () => false,
      onEnterPress = () => false,

      size = 'medium',
      resize = 'none',
    },
    ref,
  ) => {
    const [, hoverBinding] = useHover(onMouseOver, onMouseOut);
    const [isFocused, focusBinding] = useFocus(onFocus, onBlur);
    const keyboardBinding = useKeyboard({
      initialValue,
      onChange,
      onKeyDown,
      onKeyPress,
      onEnterPress,
      onKeyUp,
    });

    if (label && !domID) {
      console.warn(
        'Please enter valid "domID" prop into TextArea component for accessibility.',
      );
    }

    const wrapperClasses = [`${className}`];
    if (hasError) wrapperClasses.push('has-error');
    if (disabled) wrapperClasses.push('disabled');

    const textAreaClasses = [`${className} ${size}`];
    if (isFocused) textAreaClasses.push('focused');
    if (hasError) textAreaClasses.push('has-error');

    const remainingCharacters = maxLength - keyboardBinding.value.length;

    return (
      <Wrapper className={wrapperClasses.join(' ')}>
        {label || (maxLength && hasCounter) ? (
          <LabelWrapper>
            {label ? (
              <Label htmlFor={domID} className={className} {...labelProps}>
                {label}
              </Label>
            ) : null}
            {maxLength && hasCounter ? (
              <Label
                as="span"
                className={className}
                data-testid={`${dataTestId}-character-counter`}
              >
                {remainingCharacters}
              </Label>
            ) : null}
          </LabelWrapper>
        ) : null}
        <StyledInput
          ref={ref}
          as="textarea"
          id={domID}
          data-testid={dataTestId}
          className={textAreaClasses.join(' ')}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
          onClick={onClick}
          resize={resize}
          {...hoverBinding}
          {...focusBinding}
          {...keyboardBinding}
        />
        {hasError && errorMessage ? (
          <ErrorMessage className={className}>{errorMessage}</ErrorMessage>
        ) : null}
      </Wrapper>
    );
  },
);

TextArea.propTypes = {
  /** Attached to wrapping div */
  className: PropTypes.string,
  name: PropTypes.string,
  /** If passed, label is displayed. Requires domID also for accessibility. */
  label: PropTypes.string,
  /**
   * Label Props
   * Currently supported:
   * labelProps: {
   *   tooltipProps,
   *   micromodalProps
   * }
   */
  labelProps: PropTypes.shape(Label.propTypes),
  hasError: PropTypes.bool,
  /** error message to display below text area if hasError also passed and true. */
  errorMessage: PropTypes.string,
  /** passing or changing this prop will update the text area value to match. */
  initialValue: PropTypes.string,

  /* Attributes */
  /** attached to text area and used in label "for" attribute for accessibility. */
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** maximum number of characters the user can enter. */
  maxLength: PropTypes.number,
  /** automatically focuses the text area */
  autoFocus: PropTypes.bool,
  /** if true, user cannot edit the value. */
  disabled: PropTypes.bool,
  /** text to display in empty text area. */
  placeholder: PropTypes.string,
  /** if true, user cannot edit the value */
  readOnly: PropTypes.bool,
  /** if true and maxLength is also passed, number of remaining characters allowed is displayed. */
  hasCounter: PropTypes.bool,

  /* Event Callbacks */
  /**
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.value
   */
  onChange: PropTypes.func,
  /** takes parameters: (event, true) */
  onMouseOver: PropTypes.func,
  /** takes parameters: (event, false) */
  onMouseOut: PropTypes.func,
  /** takes parameters: (event, true) */
  onFocus: PropTypes.func,
  /** takes parameters: (event, false) */
  onBlur: PropTypes.func,
  /**
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.value
   */
  onKeyDown: PropTypes.func,
  /**
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.value
   */
  onKeyUp: PropTypes.func,
  /**
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.value
   */
  onEnterPress: PropTypes.func,
  /**
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.value
   */
  onKeyPress: PropTypes.func,
  /** takes the click event */
  onClick:
    PropTypes.func /** size alters textarea's padding to better fit into the space allotted (https://jira.healthcareit.net/browse/UICL-1522) */,
  size: PropTypes.oneOf(['small', 'large', 'medium']),
  /** Allows user to resize the text area. */
  resize: PropTypes.oneOf(['none', 'vertical', 'horizontal', 'both']),
};

export default TextArea;
