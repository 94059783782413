import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem; /* 15rem minus 1.25rem padding on each side */
  ${TextStyles.xSmall};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
`;
