import React from 'react';
import PropTypes from 'prop-types';

import CheckboxRadioBase from '../CheckboxRadioBase';

const Checkbox = (props) => <CheckboxRadioBase {...props} />;

Checkbox.propTypes = {
  /** a11y compatiblity - id of element that serves purpose of label */
  ariaLabelledBy: PropTypes.string,
  /** a11y compatiblity - label text */
  ariaLabel: PropTypes.string,
  dataTestId: PropTypes.string,
  /** whether this Checkbox is checked on render or not */
  checked: PropTypes.bool,
  /** whether this Checkbox is in indeterminate checked state */
  indeterminate: PropTypes.bool,
  /** whether this Checkbox is rendered disabled */
  disabled: PropTypes.bool,
  /** label of Checkbox */
  label: PropTypes.string,
  /** size of Checkbox label and just to be super clear, not checkbox itself! */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** width of Tooltip for truncated text */
  tooltipWidth: PropTypes.number,
  /** position of Tooltip for truncated text */
  tooltipPosition: PropTypes.string,
  /** pass a function to handle change event */
  onChange: PropTypes.func,
};

export default Checkbox;
