import { useEffect, useState } from 'react';

const useAnimation = (ref, initalValue = false) => {
  const [toggle, setToggle] = useState(initalValue);
  const [handlerObj, setHandler] = useState({ handlerFn: () => {} });

  useEffect(() => {
    if (toggle && ref?.current) {
      const onCloseAnimation = () => {
        handlerObj.handlerFn();
        ref.current.removeEventListener(
          'transitionend',
          onCloseAnimation,
          false,
        );
        setToggle(false);
      };
      ref.current.addEventListener('transitionend', onCloseAnimation, false);
    }
  }, [ref, toggle]);

  return (handlerFn = () => {}) => {
    setHandler({ handlerFn });
    setToggle(true);
  };
};

export default useAnimation;
