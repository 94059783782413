import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.h3`
  ${TextStyles.large};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
`;
