import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Bell = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Bell'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#bell-large)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M12.5 1C10.5109 1 8.60322 1.79018 7.1967 3.1967C5.79018 4.60322 5 6.51088 5 8.5V11.5C5 12.9676 4.34645 13.8766 3.62508 14.6942C3.53406 14.7973 3.43865 14.9024 3.34198 15.0089C3.06822 15.3103 2.78442 15.6228 2.56188 15.9344C2.24772 16.3742 2 16.8816 2 17.5C2 18.1366 2.36885 18.6665 2.89643 19.0796C3.42409 19.4928 4.16609 19.8372 5.06473 20.1152C6.86712 20.6727 9.42155 21 12.5 21C15.5785 21 18.1329 20.6727 19.9353 20.1152C20.8339 19.8372 21.5759 19.4928 22.1036 19.0796C22.6311 18.6665 23 18.1366 23 17.5C23 16.8816 22.7523 16.3742 22.4381 15.9344C22.2156 15.6228 21.9318 15.3103 21.658 15.0089L21.658 15.0088L21.6578 15.0086C21.5612 14.9022 21.4659 14.7973 21.3749 14.6942C20.6535 13.8766 20 12.9676 20 11.5V8.5C20 6.51088 19.2098 4.60322 17.8033 3.1967C16.3968 1.79018 14.4891 1 12.5 1ZM7.90381 3.90381C9.12279 2.68482 10.7761 2 12.5 2C14.2239 2 15.8772 2.68482 17.0962 3.90381C18.3152 5.12279 19 6.77609 19 8.5V11.5C19 13.3324 19.8465 14.4734 20.6251 15.3558C20.7408 15.487 20.8512 15.6085 20.9559 15.7239L20.956 15.724C21.2143 16.0085 21.4384 16.2553 21.6244 16.5156C21.8727 16.8633 22 17.1684 22 17.5C22 17.7134 21.8814 17.9835 21.4871 18.2923C21.0928 18.601 20.4786 18.9003 19.6397 19.1598C17.9671 19.6773 15.5215 20 12.5 20C9.47845 20 7.03288 19.6773 5.36027 19.1598C4.52141 18.9003 3.90716 18.601 3.51295 18.2923C3.11865 17.9835 3 17.7134 3 17.5C3 17.1684 3.12728 16.8633 3.37562 16.5156C3.56159 16.2553 3.78569 16.0085 4.04401 15.724C4.14877 15.6086 4.25916 15.487 4.37492 15.3558C5.15355 14.4734 6 13.3324 6 11.5V8.5C6 6.77609 6.68482 5.12279 7.90381 3.90381ZM9.64499 22.4C10.539 22.466 11.489 22.5 12.5 22.5C13.511 22.5 14.461 22.466 15.355 22.4C15.1644 23.008 14.7847 23.5393 14.2712 23.9164C13.7577 24.2936 13.1371 24.497 12.5 24.497C11.8628 24.497 11.2423 24.2936 10.7288 23.9164C10.2153 23.5393 9.83558 23.008 9.64499 22.4Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="bell-large">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Bell'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#bell-medium)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M8 0C5.23886 0 3 2.23886 3 5V5.296C3 6.54209 2.33692 7.69846 1.25933 8.31623L1.25906 8.31638C0.585829 8.70288 0 9.25797 0 10C0 10.5641 0.343376 11.0219 0.779916 11.3659C1.22208 11.7144 1.83091 12.0063 2.53865 12.2422C3.95936 12.7157 5.89023 13 8 13C10.1098 13 12.0406 12.7157 13.4614 12.2422C14.1691 12.0063 14.7779 11.7144 15.2201 11.3659C15.6566 11.0219 16 10.5641 16 10C16 9.25797 15.4142 8.70288 14.7409 8.31638L14.7404 8.31607C13.6632 7.69942 13 6.54229 13 5.296V5C13 2.23886 10.7611 0 8 0ZM4 5C4 2.79114 5.79114 1 8 1C10.2089 1 12 2.79114 12 5V5.296C12 6.8955 12.8505 8.3862 14.2431 9.18362C14.8518 9.53312 15 9.83003 15 10C15 10.1264 14.9236 10.3264 14.6011 10.5805C14.2842 10.8303 13.7947 11.077 13.1451 11.2935C11.8514 11.7248 10.0322 12 8 12C5.96777 12 4.14864 11.7248 2.85485 11.2935C2.20534 11.077 1.7158 10.8303 1.3989 10.5805C1.07637 10.3264 1 10.1264 1 10C1 9.83003 1.14817 9.53312 1.75694 9.18362C3.14919 8.38534 4 6.8958 4 5.296V5ZM10.7501 14.2003C10.8608 13.9473 10.7453 13.6525 10.4923 13.5419C10.2393 13.4313 9.94448 13.5467 9.83387 13.7997C9.52456 14.5073 8.81917 15 8.00001 15C7.18108 15 6.47624 14.5075 6.16588 13.8001C6.05492 13.5472 5.75998 13.4322 5.50711 13.5431C5.25424 13.6541 5.13919 13.949 5.25015 14.2019C5.71378 15.2586 6.76894 16 8.00001 16C9.23085 16 10.2875 15.2587 10.7501 14.2003Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="bell-medium">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Bell.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Bell;
