import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Search from '../../BrandCore/icons/interactive/Search';
import Clear from '../../BrandCore/icons/interactive/Clear';
import Input from '../Input';
import borders from '../../BrandCore/shapes/borders';
import { InputPropTypes } from '../Input/Input';

const Wrapper = styled.div`
  .search-icon {
    position: absolute;
    top: 14px;
    left: 23px;
  }
  input {
    background-color: transparent;
    border-bottom: 1px solid ${(props) => props.theme.BaseColors.Neutral['15']};
    font-size: 13px !important; /* stylelint-disable-line declaration-no-important */
    border-radius: 8px 8px 0px 0px !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: none;
    padding-left: 46px !important; /* stylelint-disable-line declaration-no-important */
    ${borders.onlyBottom};
    &:hover,
    &:hover:not(:disabled):not(:focus):not(.has-error) {
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Hover};
      ${borders.onlyBottom};
    }
    &:active,
    &:active:not(:disabled):not(.has-error) {
      background-color: ${(props) => props.theme.BaseColors.Neutral['5']};
      ${(props) => props.theme.UIPalette.BoxShadows.Field.Hover}
      ${borders.onlyBottom};
    }
    &:focus {
      border: 1px solid
        ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
      ${borders.onlyBottom};
      box-shadow: none;
    }
    ::placeholder {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Placeholder};
    }
  }
`;

const MenuSearch = ({
  initialValue = '',
  onSearch = () => false,
  onChange = () => false,
  onIconClick = () => false,
  ...otherProps
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  const handleChange = (e) => {
    e.persist();
    const { value: newValue } = e.target;
    setValue(newValue);
    onChange(e, { value: newValue });
  };

  const handleIconClick = (e) => {
    if (value) {
      setValue('');
      onIconClick(e, { value: '' });
    }
  };

  return (
    <Wrapper>
      <Input
        icon={value ? Clear : null}
        initialValue={value}
        onChange={handleChange}
        onEnterPress={onSearch}
        onIconClick={handleIconClick}
        size="large"
        placeholder="Search"
        paddingRight={42}
        {...otherProps}
      >
        <Search
          size="medium"
          className="search-icon"
          fillColor={theme.UIPalette.Colors.Content.Secondary}
        />
      </Input>
    </Wrapper>
  );
};

MenuSearch.propTypes = {
  ...InputPropTypes,
  initialValue: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default MenuSearch;
