import { initializeNotifications } from './initialize'
import { getNotifications } from './getNotifications'
import { markNotificationRead } from './markNotificationRead'
import { markAllNotificationsRead } from './markAllNotificationsRead'
import { notificationsSocket } from './webSockets'

module.exports = {
  initializeNotifications,
  getNotifications,
  markNotificationRead,
  markAllNotificationsRead,
  notificationsSocket
}
