import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import Notification from '../../../../../Molecules/Notification';
import PopupNotificationWrapper from './PopupNotificationWrapper';

const PopupNotification = ({
  popupNotification = undefined,
  durationTime = 3000,
  animationTime = 300,
  onFinish = () => false,
  onClick = () => false,
  onMarkAsRead = () => false,
  onArchive = () => false,
  containerElement = document.body,
  className = undefined,
  index = 1,
  style = {},
}) => {
  const [showPopupNotification, setShowPopupNotification] = useState(false);
  const [isAnimationTime, setIsAnimationTime] = useState(false);
  const [notificationTimer, setNotificationTimer] = useState();

  useEffect(() => {
    if (popupNotification) {
      if (!notificationTimer && !isAnimationTime) {
        setShowPopupNotification(true);

        const timer = setTimeout(() => {
          setIsAnimationTime(true);
        }, durationTime + animationTime);

        setNotificationTimer(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupNotification]);

  useEffect(() => {
    if (isAnimationTime) {
      setTimeout(() => {
        notificationTimer && clearTimeout(notificationTimer);
        setNotificationTimer();
        onFinish(popupNotification);
        setIsAnimationTime(false);
        setShowPopupNotification(false);
      }, animationTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationTime]);

  const onClickHandler = () => {
    notificationTimer && clearTimeout(notificationTimer);
    const newCurrentNotification = { ...popupNotification, isRead: true };
    setNotificationTimer();

    setIsAnimationTime(true);

    onClick(newCurrentNotification);
  };

  const onMarkAsReadHandler = () => {
    notificationTimer && clearTimeout(notificationTimer);
    const newCurrentNotification = { ...popupNotification, isRead: true };
    setNotificationTimer();

    setIsAnimationTime(true);

    onMarkAsRead(newCurrentNotification);
  };

  const onArchiveHandler = () => {
    notificationTimer && clearTimeout(notificationTimer);
    const newCurrentNotification = { ...popupNotification, isArchived: true };
    setNotificationTimer();

    setIsAnimationTime(true);

    onArchive(newCurrentNotification);
  };

  if (!showPopupNotification) {
    return null;
  }

  return createPortal(
    <PopupNotificationWrapper
      className={clsx(className, isAnimationTime && 'out')}
      style={style}
      animationTime={animationTime}
      index={index}
    >
      <Notification
        {...popupNotification}
        onClick={onClickHandler}
        onMarkAsReadClick={onMarkAsReadHandler}
        onArchiveClick={onArchiveHandler}
      />
    </PopupNotificationWrapper>,
    containerElement,
  );
};

PopupNotification.propTypes = {
  popupNotification: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.elementType,
  }),
  durationTime: PropTypes.number,
  animationTime: PropTypes.number,
  onFinish: PropTypes.func,
  onClick: PropTypes.func,
  onMarkAsRead: PropTypes.func,
  onArchive: PropTypes.func,
  containerElement: PropTypes.element,
  index: PropTypes.number,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default PopupNotification;
