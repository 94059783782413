import React from 'react';
import PropTypes from 'prop-types';

import DetailpageCategory from '../../Atoms/DetailpageCategory/DetailpageCategory';
import DetailpageTitle from '../../Atoms/DetailpageTitle/DetailpageTitle';
import DetailpageDescription from '../../Atoms/DetailpageDescription/DetailpageDescription';
import LayoutControl from '../../Atoms/LayoutControl';
import DetailpageCount from '../../Atoms/DetailpageCount';
import Tile from '../../Atoms/Tile';

import DetailPaneHeaderWrapper from './components/DetailPaneHeaderWrapper';
import TitleCountWrapper from './components/TitleCountWrapper';
import DetailPaneHeaderLeft from './components/DetailPaneHeaderLeft';
import DetailPaneHeaderRight from './components/DetailPaneHeaderRight';
import Chip from '../../Atoms/Chip';
import { TileProps } from '../../Atoms/Tile/Tile';
import { ContainerDiv } from './components/StyledComponents';
import { ChipPropTypes } from '../../Atoms/Chip/Chip';
import DetailPaneHeaderLeftWrapper from './components/DetailPaneHeaderLeftWrapper';

const DetailPaneHeader = ({
  domID = null,
  className = '',
  dataTestId = '',
  title = '',
  description = null,
  category = null,
  count = null,
  marginBottom = 0,
  layoutControls = [],
  tileConfig = null,
  chipOptions = null,
  children = null,
  headerTag = 'h2',
  showLine = false,
  sticky = false,
}) => {
  const domIDs = {
    category: domID && `${domID}-category`,
    count: domID && `${domID}-count`,
    description: domID && `${domID}-description`,
    layoutControls: domID && `${domID}-layoutControl`,
    title: domID && `${domID}-title`,
    chip: domID && `${domID}-chip`,
  };

  const testIDs = {
    category: dataTestId && `${dataTestId}-category`,
    count: dataTestId && `${dataTestId}-count`,
    description: dataTestId && `${dataTestId}-description`,
    layoutControls: dataTestId && `${dataTestId}-layoutControl`,
    title: dataTestId && `${dataTestId}-title`,
    chip: dataTestId && `${dataTestId}-chip`,
  };

  const minMarginBottom = children ? 36 : 0;

  return (
    <ContainerDiv showLine={showLine} sticky={sticky}>
      <DetailPaneHeaderWrapper
        id={domID}
        data-testid={dataTestId}
        className={className}
        marginBottom={Math.max(marginBottom, minMarginBottom)}
      >
        <DetailPaneHeaderLeft>
          {tileConfig && (
            <Tile
              {...tileConfig}
              className={`${tileConfig.className} detail-header-tile`}
            />
          )}
          <DetailPaneHeaderLeftWrapper tileConfig={!!tileConfig}>
            {category && (
              <DetailpageCategory
                domID={domIDs.category}
                dataTestId={testIDs.category}
                label={category}
              />
            )}

            <TitleCountWrapper>
              {title && (
                <DetailpageTitle
                  domID={domIDs.title}
                  dataTestId={testIDs.title}
                  title={title}
                  as={headerTag}
                />
              )}
              {(count || count === 0) && (
                <DetailpageCount
                  domID={domIDs.count}
                  dataTestId={testIDs.count}
                  count={count}
                />
              )}
              {chipOptions && (
                <Chip
                  {...chipOptions}
                  dataTestId={testIDs.chip}
                  domID={domIDs.chip}
                  className="detail-pane-header-chip"
                />
              )}
            </TitleCountWrapper>
            {description && (
              <DetailpageDescription
                domID={domIDs.description}
                dataTestId={testIDs.description}
                description={description}
              />
            )}
          </DetailPaneHeaderLeftWrapper>
        </DetailPaneHeaderLeft>
        {layoutControls.length ? (
          <DetailPaneHeaderRight data-testid={testIDs.layoutControls}>
            {layoutControls.map((layoutControl) => {
              return (
                <LayoutControl
                  className="layout-controls-button-wrapper"
                  domID={
                    domID &&
                    `${domIDs.layoutControls}-${layoutControl.buttonType}`
                  }
                  dataTestId={
                    dataTestId &&
                    `${testIDs.layoutControls}-${layoutControl.buttonType}`
                  }
                  key={`layout-control-${layoutControl.buttonType}`}
                  buttonType={layoutControl.buttonType}
                  tooltipPosition={layoutControl.buttonTooltipPosition}
                  onClick={layoutControl.onButtonClick}
                  {...layoutControl}
                />
              );
            })}
          </DetailPaneHeaderRight>
        ) : null}
      </DetailPaneHeaderWrapper>
      {children}
    </ContainerDiv>
  );
};

export const DetailPaneHeaderPropTypes = {
  /**
   *  id attribute for detail header parent div. Components used internally get following
   * derived id:
   *
   * category: domID + "-category",
   * count: domID + "-count",
   * description: domID + "-description,
   * layoutControls: domID + "-layoutControl",
   * title: domID + "-title",
   *
   *  The id attribute is used to point to a specific style declaration in a style sheet.
   *  It is also used by JavaScript to access and manipulate the element with the specific id.
   *
   *
   */
  domID: PropTypes.string,
  /**
   * Classname that is attached to the container div.
   */
  className: PropTypes.string,
  /**
   * data-testid attribute for automated testing added for automated testing.
   *
   * The container div gets the passed data-testid. Components used internally get following
   * derived data-testids:
   *
   * category: dataTestId + "-category",
   * count: dataTestId + "-count",
   * description: dataTestId + "-description,
   * layoutControls: dataTestId + "-layoutControl",
   * title: dataTestId + "-title",
   * */
  dataTestId: PropTypes.string,
  /**
   *
   * Primary title/heading text
   *
   * Internally `<h2>` tag is used.
   *  */
  title: PropTypes.string.isRequired,
  /** Smaller heading which appears above the title */
  category: PropTypes.string,
  /** Description which appears underneath the title */
  description: PropTypes.string,
  /** Optional config for a Tile component embeded  within the title. See Tile component. */
  tileConfig: PropTypes.shape({ ...TileProps }),
  /** Content of a Count component appearing to the right of the title */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Space (in pixels) beneath the Detail Header's main wrapper.
   *
   * If children is passed, this will be used to adjust space after header and before the children.
   * */
  marginBottom: PropTypes.number,
  /** Array of Layout Control component's (ex. the close button) config objects.
   *
   * Each object looks like:
   * @typedef {Object} layoutControl
   * @prop {String} layoutControl.buttonType
   * @prop {String} layoutControl.buttonTooltipPosition
   * @prop {Function} layoutControl.onButtonClick - receives the click event
   */
  layoutControls: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.string,
      buttonTooltipPosition: PropTypes.string,
      onButtonClick: PropTypes.func,
    }),
  ),
  /** domID of container component, used for `aria-controls` prop in toggleButton */
  parentID: PropTypes.string,
  /**
   * Chip can be used in detail header. (See chip component for more details.)
   *
   * The props that the chip takes can be configured using chipOptions.
   */
  chipOptions: PropTypes.shape({ ...ChipPropTypes }),
  children: PropTypes.node,
  /**
   * This component internally renders header(h1, h2, h3, h4, h5, h6) tag.
   *
   * Change the default tag of h2 using this prop. Note that the font-size will not change based on this.
   */
  headerTag: PropTypes.string,
  /**
   * Adds 1px border at the bottom of the wrapper div
   */
  showLine: PropTypes.bool,

  /**
   * Makes detail header stick to the top when user scrolls.
   */
  sticky: PropTypes.bool,
};

DetailPaneHeader.propTypes = DetailPaneHeaderPropTypes;

export default DetailPaneHeader;
