import React from 'react';
import PropTypes from 'prop-types';

import AvatarImage from './components/avatarImage';
import User from '../icons/informational/User';
import IconWrapper from './components/IconWrapper';

const supportedVariants = {
  xxSmall: 16,
  xSmall: 24,
  small: 32,
  medium: 40,
  large: 64,
  xLarge: 128,
};

const variantToUserIconSizeMap = {
  xxSmall: 'xSmall',
  xSmall: 'small',
  small: 'medium',
  medium: 'medium',
  large: 'large',
  xLarge: 'large',
};

export const Avatar = ({
  avatarImg = null,
  className = null,
  domID = null,
  dataTestId = '',
  variant = 'xSmall',
}) => (
  <div id={domID} data-testid={dataTestId} className={className}>
    <IconWrapper size={supportedVariants[variant]}>
      {avatarImg ? (
        <AvatarImage
          src={avatarImg}
          width={supportedVariants[variant]}
          role="presentation"
        />
      ) : (
        <User size={variantToUserIconSizeMap[variant]} />
      )}
    </IconWrapper>
  </div>
);

Avatar.propTypes = {
  avatarImg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.elementType,
  ]),
  className: PropTypes.string,
  domID: PropTypes.string,
  variant: PropTypes.oneOf([
    'xLarge',
    'large',
    'medium',
    'small',
    'xSmall',
    'xxSmall',
  ]),
  dataTestId: PropTypes.string,
};

export default Avatar;
