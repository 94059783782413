import React from 'react';
import Input from '../../../Atoms/Input';
import { Container, Row } from '../../../Layouts/contentGrid/contentGrid';

const ChangePassword = () => {
  return (
    <Container>
      <Row>
        <Input
          domID="currentpassword-input"
          className="text-input"
          label="Confirm Current Password"
        />
      </Row>
      <Row>
        <Input
          domID="newpassword-input"
          className="text-input"
          label="New Password"
        />
      </Row>
    </Container>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
