import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Triangle100 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M12.874 2.47725C12.7798 2.33084 12.6504 2.21043 12.4977 2.12706C12.345 2.04369 12.1739 2 12 2C11.8261 2 11.655 2.04369 11.5023 2.12706C11.3496 2.21043 11.2202 2.33084 11.126 2.47725L1.12549 20.5121C1.04108 20.6646 0.997855 20.8365 1.00008 21.0109C1.00231 21.1852 1.04991 21.356 1.13819 21.5063C1.22647 21.6565 1.35236 21.7811 1.50343 21.8677C1.65449 21.9543 1.82549 21.9999 1.99953 22H22.0005C22.1745 21.9999 22.3455 21.9543 22.4966 21.8677C22.6476 21.7811 22.7735 21.6565 22.8618 21.5063C22.9501 21.356 22.9977 21.1852 22.9999 21.0109C23.0021 20.8365 22.9589 20.6646 22.8745 20.5121L12.874 2.47725Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M8.86401 1.46318C8.76909 1.32068 8.64058 1.20386 8.48987 1.12306C8.33916 1.04227 8.1709 1 8.00001 1C7.82911 1 7.66085 1.04227 7.51014 1.12306C7.35943 1.20386 7.23092 1.32068 7.13601 1.46318L0.136005 13.4959C0.0483222 13.6482 0.0021186 13.8209 0.00202955 13.9968C0.00194049 14.1726 0.0479691 14.3454 0.135498 14.4978C0.223026 14.6501 0.348977 14.7767 0.500715 14.8649C0.652452 14.9531 0.82464 14.9996 1.00001 15H15C15.1754 14.9996 15.3476 14.9531 15.4993 14.8649C15.651 14.7767 15.777 14.6501 15.8645 14.4978C15.952 14.3454 15.9981 14.1726 15.998 13.9968C15.9979 13.8209 15.9517 13.6482 15.864 13.4959L8.86401 1.46318Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M6.64801 1.09738C6.57682 0.990508 6.48044 0.902891 6.36741 0.842297C6.25437 0.781702 6.12818 0.75 6.00001 0.75C5.87184 0.75 5.74564 0.781702 5.63261 0.842297C5.51958 0.902891 5.4232 0.990508 5.35201 1.09738L0.102008 10.1219C0.0362455 10.2361 0.00159277 10.3657 0.00152598 10.4976C0.00145918 10.6295 0.0359807 10.759 0.101627 10.8733C0.167274 10.9876 0.261737 11.0826 0.37554 11.1487C0.489343 11.2148 0.618484 11.2497 0.750008 11.25H11.25C11.3815 11.2497 11.5107 11.2148 11.6245 11.1487C11.7383 11.0826 11.8327 10.9876 11.8984 10.8733C11.964 10.759 11.9986 10.6295 11.9985 10.4976C11.9984 10.3657 11.9638 10.2361 11.898 10.1219L6.64801 1.09738Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Triangle100.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Triangle100;
