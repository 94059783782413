/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';

import Wrapper from './components/Wrapper';
import Calendar from '../../BrandCore/icons/interactive/Calendar';
import Previous from '../../BrandCore/icons/interactive/Previous';
import Next from '../../BrandCore/icons/interactive/Next';
import Clear from '../../BrandCore/icons/interactive/Clear';
import InputLabel from '../../Atoms/Input/components/InputLabel';
import ErrorMessage from '../../Atoms/Input/components/ErrorMessage';
import ButtonGroup from './ButtonGroup';

export const DatePicker = ({
  initialSelection = null,
  initialIsOpen = false,
  disabled = false,
  domID = null,
  dataTestId = '',
  label = null,
  labelProps = {},
  buttonBarChildren = null,
  className = null,
  hasError = false,
  errorMessage = 'Please pick a valid date',
  openDirection = 'down',
  numberOfMonths = 1,
  showCalendarIcon = true,
  size = 'medium',
  onDateChange = () => false,
  onFocusChange = () => false,
  onInputChange = () => false,
  ...otherProps
}) => {
  const { UIPalette } = useTheme();
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  useEffect(() => setIsOpen(initialIsOpen), [initialIsOpen]);

  const [currentSelection, setCurrentSelection] = useState(initialSelection);
  useEffect(() => setCurrentSelection(initialSelection), [initialSelection]);

  const [hasErrorInternal, setHasErrorInternal] = useState(hasError);
  const [errorMessageInternal, setErrorMessageInternal] =
    useState(errorMessage);

  useEffect(() => setHasErrorInternal(hasError), [hasError]);
  useEffect(() => setErrorMessageInternal(errorMessage), [errorMessage]);

  const wrapperClasses = `
    ${className}
    ${hasErrorInternal ? 'has-error' : ''}
    ${disabled ? 'disabled' : ''}
    ${openDirection === 'up' ? 'openUp' : ''}
    ${isOpen && 'opened'}
  `;

  const isValidDate = (dateString = '') => {
    const regex = /((0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1])\/(\d\d\d\d))$/;
    // Check whether valid MM/dd/yyyy Date Format.
    if (dateString.length === 0 || regex.test(dateString)) {
      const [month, day, year] = dateString.split('/');
      const numberOfDaysInThisMonth = new Date(year, month, 0).getDate();
      if (parseInt(day, 10) > numberOfDaysInThisMonth) {
        return false;
      }
      return true;
    }
    return false;
  };

  const wrapperRef = useRef(null);
  const onInputChangeCallback = useCallback(onInputChange, [onInputChange]);
  useEffect(() => {
    let input = null;

    const listener = (e) => {
      const month = /^(((0)[0-9])|((1)[0-2]))$/;
      const monthAndDay = /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])$/;
      // TODO: account for month lengths? (30, 31, 28) but then we get into leap years :(

      // only allow numeral keypresses in input and set max length to 10
      if (e.which < 48 || e.which > 57 || input.value.length === 10)
        e.preventDefault();

      const valueWithCurrentKeyPress = `${input.value}${e.key}`;

      // if we have the month digits, add a slash
      if (valueWithCurrentKeyPress.match(month)) {
        e.preventDefault();
        input.value = `${valueWithCurrentKeyPress}/`;
      }

      // if we have the month and day digits, add a slash
      if (valueWithCurrentKeyPress.match(monthAndDay)) {
        e.preventDefault();
        input.value = `${valueWithCurrentKeyPress}/`;
      }

      // don't allow invalid months
      // only allow 0 and 1 as the first character
      if (input.value === '' && e.which !== 48 && e.which !== 49) {
        e.preventDefault();
      }
      // if typing the second digit wouldn't match the month regex, don't allow it
      if (input.value.length === 1 && !valueWithCurrentKeyPress.match(month)) {
        e.preventDefault();
      }

      // if 'MM' add slash before current character, validate for month
      if (input.value.length === 2) {
        e.preventDefault();
        // if we have 'MM/', only allow 0-3
        if (e.which >= 48 && e.which <= 51) {
          input.value = `${input.value}/${e.key}`;
        }
      }

      // if 'MM/DD' add slash before current character
      if (input.value.length === 5) {
        e.preventDefault();
        input.value = `${input.value}/${e.key}`;
      }

      // don't allow invalid days
      // if we have 'MM/', only allow 0-3
      if (input.value.length === 3 && (e.which < 48 || e.which > 51)) {
        e.preventDefault();
      }
      // if we have 'MM/D', only allow characters that match the regex
      if (
        input.value.length === 4 &&
        !valueWithCurrentKeyPress.match(monthAndDay)
      ) {
        e.preventDefault();
      }
    };

    const onChangeListener = () => {
      onInputChangeCallback(input.value);
      // if hasError is set to True by user that must take precedence
      // even when our validation check passes.
      setHasErrorInternal(hasError || !isValidDate(input.value));
    };

    if (wrapperRef.current !== null) {
      input = wrapperRef.current.querySelector('input');
      input.addEventListener('keypress', listener);
      input.addEventListener('keyup', onChangeListener);
    }

    return () => {
      input.removeEventListener('keypress', listener);
      input.removeEventListener('keyup', onChangeListener);
    };
  }, [wrapperRef, onInputChangeCallback, hasError]);

  const handleDateChange = (date) => {
    setCurrentSelection(date);
    onDateChange(date);

    if (date !== null) {
      onInputChange(date.format('MM/DD/YYYY'));
      setHasErrorInternal(hasError); // clear error set internally
    }

    if (currentSelection !== null && date == null) {
      // when user clicks on clear button
      onInputChange('');
    }

    // if we didn't have a valid date but now we do, we want to blur the input
    if (
      currentSelection === null &&
      date !== null &&
      wrapperRef.current !== null
    ) {
      wrapperRef.current.querySelector('input').blur();
    }
  };

  const handleFocusChange = ({ focused }) => {
    setIsOpen(focused);
    onFocusChange(focused);
  };

  return (
    <Wrapper
      className={wrapperClasses}
      numberOfMonths={numberOfMonths}
      showCalendarIcon={showCalendarIcon}
      size={size}
      zIndex={isOpen ? 10 : 1}
      ref={wrapperRef}
      data-testid={dataTestId}
    >
      {label ? (
        <InputLabel htmlFor={domID} {...labelProps}>
          {label}
        </InputLabel>
      ) : null}
      <SingleDatePicker
        customInputIcon={
          showCalendarIcon ? (
            <Calendar
              dataTestId={`${dataTestId}-calendar-icon`}
              domID={domID ? `${domID}-calendar-icon` : 'calendar-icon'}
              title="Pick a date"
              size={size === 'small' ? 'small' : 'medium'}
              fillColor={
                disabled
                  ? UIPalette.Colors.Content.Disabled
                  : UIPalette.Colors.Content.Secondary
              }
            />
          ) : null
        }
        inputIconPosition="after"
        date={currentSelection}
        daySize={36}
        horizontalMargin={0}
        disabled={disabled}
        focused={isOpen}
        hideKeyboardShortcutsPanel
        id={domID}
        navPrev={
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <div tabIndex={0}>
            <Previous
              size="small"
              tabIndex={0}
              fillColor={UIPalette.Colors.Content.Secondary}
            />
          </div>
        }
        navNext={
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <div tabIndex={0}>
            <Next
              size="small"
              tabIndex={0}
              fillColor={UIPalette.Colors.Content.Secondary}
            />
          </div>
        }
        numberOfMonths={numberOfMonths}
        onDateChange={handleDateChange}
        onFocusChange={handleFocusChange}
        placeholder="MM/DD/YYYY"
        weekDayFormat="dd"
        isOutsideRange={() => false}
        renderCalendarInfo={
          buttonBarChildren
            ? () => (
                <ButtonGroup className="date-picker-button-bar">
                  {buttonBarChildren}
                </ButtonGroup>
              )
            : null
        }
        openDirection={openDirection}
        showClearDate
        customCloseIcon={
          <Clear
            dataTestId={`${dataTestId}-clear-button`}
            domID={`${domID}-clear-button`}
            size={size === 'small' ? 'small' : 'medium'}
            title="clear dates"
            fillColor={
              disabled
                ? UIPalette.Colors.Content.Disabled
                : UIPalette.Colors.Content.Secondary
            }
          />
        }
        {...otherProps}
      />
      {hasErrorInternal && errorMessageInternal ? (
        <ErrorMessage data-testid="testIDs.errorMessage">
          {errorMessageInternal}
        </ErrorMessage>
      ) : null}
    </Wrapper>
  );
};

DatePicker.propTypes = {
  dataTestId: PropTypes.string,
  domID: PropTypes.string.isRequired,
  /** disables the DatePicker */
  disabled: PropTypes.bool,
  /** pass or change to open or close the popup */
  initialIsOpen: PropTypes.bool,
  /** pass a moment object to have an initially selected date */
  initialSelection: momentPropTypes.momentObj,
  /** label for input, appears above it */
  label: PropTypes.string,
  /**
   * Label Props
   * Currently supported:
   * labelProps: {
   *   tooltipProps,
   *   micromodalProps
   * }
   */
  labelProps: PropTypes.shape(InputLabel.propTypes),
  className: PropTypes.string,
  /** if true, and errorMessage exists, message is shown and styling changes */
  hasError: PropTypes.bool,
  /** displayed below input if hasError is also true */
  errorMessage: PropTypes.string,
  /** you can pass false to hide the calendar icon in the input */
  showCalendarIcon: PropTypes.bool,
  /** displayed below the calendar in the popup. See with buttons story for more */
  buttonBarChildren: PropTypes.arrayOf(PropTypes.node),
  /** controls whether the popup opens below or above the input */
  openDirection: PropTypes.oneOf(['down', 'up']),
  /** how many months to display in the popup */
  numberOfMonths: PropTypes.number,
  /** receives a date object, or null, on every input keypress, on clicking a date in the popup, and on clicking the clear button. */
  onDateChange: PropTypes.func,
  /** fires when the popup opens or closes, receives a boolean. */
  onFocusChange: PropTypes.func,
  /** fires when the content in the input changes. This handler function provides most recent input value of date in 'MM/DD/YYYY' string format */
  onInputChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default DatePicker;
