import styled from 'styled-components';
import TextStyles from '../../BrandCore/Text/styles';
import datavizPalette from '../../BrandCore/colors/datavizPalette';
import { borderHelpers } from '../../BrandCore/shapes/borders';

export const UnstyledButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
`;

export const StandardButton = styled(UnstyledButton)`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Button.Background.Standard};
  border: 1px solid
    ${({ theme }) => theme.UIPalette.Colors.Button.Border.Standard};
  outline: none;
  ${({ theme }) => theme.BoxShadows.Depth5};

  &:hover {
    background: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.Standard};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Background.Standard};
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.Standard};
  }

  &:active,
  &:disabled,
  &.loading:not(.finished-loading) {
    box-shadow: none;
  }

  &.small {
    height: 28px;
    padding: 0.5px 14px;
    ${borderHelpers.borderRadius8};
    ${TextStyles.xSmallBold};
    line-height: 17px;
  }

  &.medium {
    height: 36px;
    padding: 2.5px 18px;
    ${borderHelpers.borderRadius10};
    ${TextStyles.smallBold};
    line-height: 20px;
  }

  &.large {
    height: 44px;
    padding: 0px 22px;
    ${borderHelpers.borderRadius12};
    ${TextStyles.mediumBold};
    line-height: 24px;
  }

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.UIPalette.Colors.Button.Text.Standard};
  }

  .padded-icon {
    margin-right: 12px;
  }

  &.small .padded-icon {
    margin-right: 10px;
  }

  &.standard {
    .dropdown-caret path {
      fill: ${({ theme }) => theme.UIPalette.Colors.Button.Text.Standard};
    }
  }

  &.emphasized {
    .dropdown-caret path {
      fill: ${({ theme }) => theme.UIPalette.Colors.Button.Text.Emphasized};
    }
  }

  &.deEmphasizedReversed,
  &.emphasizedAlt,
  &.destroy {
    .dropdown-caret path {
      fill: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
    }
  }

  &.deEmphasized {
    .dropdown-caret path {
      fill: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
    }
  }

  &.diminished {
    .dropdown-caret path {
      fill: ${({ theme }) => theme.BaseColors.Primary['130']};
    }
  }

  &.load-type {
    position: relative;
    overflow: hidden;
  }

  &.loading:not(.finished-loading) {
    background-color: ${({ theme }) => theme.BaseColors.Neutral['50']};
    cursor: wait;
    border: 1px solid
      ${({ theme }) => theme.UIPalette.Colors.Button.Disabled.Border.Standard};
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Background.Standard};
    border: 1px solid
      ${({ theme }) => theme.UIPalette.Colors.Button.Disabled.Border.Standard};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Text.Standard};

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Standard};
    }
  }
`;

export const EmphasizedButton = styled(StandardButton)`
  background-color: ${({ theme }) => theme.BaseColors.Primary['100']};
  border-color: ${({ theme }) => theme.BaseColors.Primary['115']};

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Text.Emphasized};
  }

  &:hover {
    background: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.Emphasized};
  }

  &:active {
    background-color: ${({ theme }) => theme.BaseColors.Primary['100']};
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.Emphasized};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Background.Emphasized};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Text.Emphasized};
    border: 1px solid
      ${({ theme }) => theme.UIPalette.Colors.Button.Disabled.Border.Emphasized};
    box-shadow: none;

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Emphasized};
    }
  }
`;

export const DeEmphasizedButton = styled(StandardButton)`
  background-color: ${({ theme }) => theme.BaseColors.White};
  border-color: ${({ theme }) => theme.BaseColors.Neutral['70']};
  ${({ theme }) => theme.BoxShadows.Depth2};

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.DeEmphasized};
  }

  &:active {
    background-color: ${({ theme }) => theme.BaseColors.White};
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.DeEmphasized};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${({ theme }) => theme.BaseColors.White};
    border: 1px solid ${({ theme }) => theme.BaseColors.Neutral['15']};
    color: ${({ theme }) => theme.BaseColors.Neutral['50']};

    .dropdown-caret path {
      fill: ${({ theme }) => theme.BaseColors.Neutral['50']};
    }
  }
`;

export const DeEmphasizedReversedButton = styled(StandardButton)`
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Background.Masthead};
  border-color: ${({ theme }) =>
    theme.UIPalette.Colors.Content.PersistentLight};
  ${({ theme }) => theme.BoxShadows.Depth2};

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
  }

  .dropdown-caret path {
    fill: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.DeEmphasizedReversed};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Background.Masthead};
  }

  &:focus-visible:not(:active):not(.loading) {
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.Border.DeEmphasizedReversed};
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.DeEmphasizedReversed};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Border.DeEmphasizedReversed};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Border.DeEmphasizedReversed};
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Background.DeEmphasizedReversed};

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Border.DeEmphasizedReversed};
    }
  }
`;

export const DiminishedButton = styled(StandardButton)`
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.BaseColors.Primary['130']};
  }

  .dropdown-caret path {
    fill: ${({ theme }) => theme.BaseColors.Primary['130']};
  }

  &:hover {
    background-color: transparent;
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Text.Diminished};
  }

  &:focus-visible:not(:active):not(.loading) {
    border-color: ${({ theme }) => theme.BaseColors.Primary['130']};
    box-shadow: none;
  }

  &:active {
    color: ${({ theme }) => theme.BaseColors.Primary['130']};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Text.Diminished};

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Diminished};
    }
  }
`;

export const DestroyButton = styled(StandardButton)`
  background-color: ${({ theme }) => theme.BaseColors.Negative['130']};
  border-color: ${datavizPalette.digitalRed150};

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
  }

  &:hover {
    background: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.Destroy};
  }

  &:active {
    background-color: ${({ theme }) => theme.BaseColors.Negative['130']};
  }

  &:focus-visible:not(:active):not(.loading) {
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.Border.Destroy};
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.Destroy};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Background.Destroy};
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Border.Destroy};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Text.Destroy};

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Destroy};
    }
  }
`;

export const EmphasizedAltButton = styled(StandardButton)`
  background-color: ${({ theme }) => theme.BaseColors.Accent['100']};
  border-color: ${({ theme }) =>
    theme.UIPalette.Colors.Button.Border.EmphasizedAlt};

  &.small,
  &.medium,
  &.large {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
  }

  &:hover {
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Border.EmphasizedAlt};
    background: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.EmphasizedAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.BaseColors.Accent['100']};
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Active.Border.EmphasizedAlt};
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.BoxShadow.EmphasizedAlt};
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Focus.Border.EmphasizedAlt};
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Background.EmphasizedAlt};
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Border.EmphasizedAlt};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Disabled.Text.EmphasizedAlt};

    .dropdown-caret path {
      fill: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.EmphasizedAlt};
    }
  }
`;

export default {
  UnstyledButton,
  StandardButton,
  EmphasizedButton,
  DeEmphasizedButton,
  DeEmphasizedReversedButton,
  DestroyButton,
  DiminishedButton,
  EmphasizedAltButton,
};
