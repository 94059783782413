import PropTypes from 'prop-types';
import React from 'react';
/* Atoms */
import Input from '../../../Atoms/Input';
import { Col, Container, Row } from '../../../Layouts/contentGrid/contentGrid';

const emptyValidationObject = {
  errorMessage: '',
  isValid: true,
};

const ProfileForm = (props) => {
  const {
    domID = '',
    onUserInput = () => false,
    profileFormValidation: {
      userEmail: userEmailValidation = emptyValidationObject,
      userFirstName: userFirstNameValidation = emptyValidationObject,
      userLastName: userLastNameValidation = emptyValidationObject,
    },
    userEmail = '',
    userFirstName = '',
    userLastName = '',
  } = props;

  return (
    <Container id={domID}>
      <Row>
        <Col>
          <Input
            className="text-input"
            domID="firstname-input"
            errorMessage={userFirstNameValidation.errorMessage}
            hasError={!userFirstNameValidation.isValid}
            initialValue={userFirstName}
            label="FIRST NAME*"
            maxLength={50}
            onBlur={(e) => onUserInput(e)}
          />
        </Col>
        <Col>
          <Input
            className="text-input"
            domID="lastname-input"
            errorMessage={userLastNameValidation.errorMessage}
            hasError={!userLastNameValidation.isValid}
            initialValue={userLastName}
            label="LAST NAME*"
            maxLength={50}
            onBlur={(e) => onUserInput(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            className="text-input email"
            disabled
            domID="email-input"
            errorMessage={userEmailValidation.errorMessage}
            hasError={!userEmailValidation.isValid}
            initialValue={userEmail}
            label="USERNAME/EMAIL*"
            onBlur={(e) => onUserInput(e)}
          />
        </Col>
      </Row>
    </Container>
  );
};

ProfileForm.propTypes = {
  domID: PropTypes.string,
  onUserInput: PropTypes.func,
  profileFormValidation: PropTypes.shape({
    userEmail: PropTypes.shape({
      errorMessage: PropTypes.string,
      isValid: PropTypes.bool,
    }),
    userFirstName: PropTypes.shape({
      errorMessage: PropTypes.string,
      isValid: PropTypes.bool,
    }),
    userLastName: PropTypes.shape({
      errorMessage: PropTypes.string,
      isValid: PropTypes.bool,
    }),
  }),
  userEmail: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
};

export default ProfileForm;
