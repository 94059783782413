import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxGroup from '../../Molecules/CheckboxGroup';
import TakeoverButtonRow from './components/TakeoverButtonRow';
import { MODAL_ANIMATION } from '../../BrandCore/constants/timings';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/components/ModalHeader';
import ModalBody from '../Modal/components/ModalBody';
import ModalFooter from '../Modal/components/ModalFooter';
import { Container } from '../../Layouts/contentGrid/contentGrid';

export const SettingsTakeover = ({
  isOpen = false,
  domID = null,
  dataTestId = null,
  onSave = () => false,
  disabledColumns = null,
  onCancel = () => false,
  defaultColumns = null,
  mandatoryColumns = null,
  columns = null,
  onRestoreDefaults = () => false,
  resetColumnsDataNames = null,
}) => {
  const [disabledColumnsState, setDisabledColumnsState] =
    useState(disabledColumns);
  useEffect(() => setDisabledColumnsState(disabledColumns), [disabledColumns]);

  const restoreDefaults = (e) => {
    const tempDisabledColumns = new Set();

    if (resetColumnsDataNames) {
      columns.forEach((column) => {
        tempDisabledColumns.add(column.dataName);
      });
      resetColumnsDataNames.forEach((name) => {
        tempDisabledColumns.delete(name);
      });
      setDisabledColumnsState(tempDisabledColumns);
    } else if (!defaultColumns) {
      setDisabledColumnsState(tempDisabledColumns);
    } else {
      columns.forEach((column) => {
        tempDisabledColumns.add(column.dataName);
      });
      defaultColumns.forEach((name) => {
        tempDisabledColumns.delete(name);
      });
      setDisabledColumnsState(tempDisabledColumns);
    }

    onRestoreDefaults(e);
  };

  const handleCancel = (e) => {
    onCancel(e, disabledColumns);
    // Need to give time for the modal to fade out before resetting the state
    setTimeout(() => {
      setDisabledColumnsState(disabledColumns);
    }, MODAL_ANIMATION);
  };

  const onColumnSelect = ({
    item: {
      column: { dataName },
    },
  }) => {
    const tempDisabledColumns = new Set(disabledColumnsState);
    disabledColumnsState.has(dataName)
      ? tempDisabledColumns.delete(dataName)
      : tempDisabledColumns.add(dataName);
    setDisabledColumnsState(new Set(tempDisabledColumns));
  };

  return isOpen ? (
    <Modal
      domID={domID}
      isOpen={isOpen}
      onModalToggle={handleCancel}
      dataTestId={dataTestId}
      initialFocus
    >
      <ModalHeader
        title="Column Settings"
        description="Adjust which columns are visible"
        onClose={handleCancel}
      />
      <ModalBody>
        <Container>
          <CheckboxGroup
            onChange={onColumnSelect}
            orientation="vertical"
            dataTestId={`${dataTestId}-checkboxes`}
            items={[...columns]
              .filter((item) => item.text)
              .map((item) => ({
                dataTestId: `${item.dataName}-checkbox`,
                id: `${item.dataName}-checkbox`,
                label: item.text,
                checked: !disabledColumnsState.has(item.dataName),
                column: item,
                disabled:
                  mandatoryColumns && mandatoryColumns.has(item.dataName),
              }))}
          />
        </Container>
      </ModalBody>
      <ModalFooter>
        <TakeoverButtonRow
          dataTestId={dataTestId}
          onSave={(e) => onSave(e, disabledColumnsState)}
          restoreDefaults={restoreDefaults}
          onCancel={handleCancel}
        />
      </ModalFooter>
    </Modal>
  ) : null;
};

SettingsTakeover.propTypes = {
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool,
  columns: PropTypes.instanceOf(Set).isRequired,
  disabledColumns: PropTypes.instanceOf(Set).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRestoreDefaults: PropTypes.func,
  defaultColumns: PropTypes.instanceOf(Set),
  mandatoryColumns: PropTypes.instanceOf(Set),
  resetColumnsDataNames: PropTypes.instanceOf(Set),
};

export default SettingsTakeover;
