import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Heart = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.9037 3.9037C5.44196 1.36543 9.55754 1.36543 12.0958 3.9037C12.2385 4.04639 12.3729 4.19439 12.4992 4.34675C12.6253 4.19463 12.7597 4.04673 12.9027 3.9037C15.441 1.36543 19.5565 1.36543 22.0948 3.9037C24.6331 6.44196 24.6331 10.5575 22.0948 13.0958L12.4982 22.6924L2.9037 13.0958C0.365435 10.5575 0.365435 6.44196 2.9037 3.9037ZM11.3887 4.6108C9.24096 2.46307 5.75854 2.46307 3.6108 4.6108C1.46307 6.75854 1.46307 10.241 3.6108 12.3887L12.4983 21.2781L21.3877 12.3887C23.5354 10.241 23.5354 6.75854 21.3877 4.6108C19.24 2.46307 15.7575 2.46307 13.6098 4.6108C13.3454 4.8752 13.1153 5.16015 12.9159 5.46072L12.4993 6.08852L12.0826 5.46072C11.8832 5.16021 11.6521 4.87424 11.3887 4.6108Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.31744 2.31746C3.07461 0.560287 5.92399 0.560196 7.68128 2.31719C7.79829 2.43382 7.9039 2.55531 8.00014 2.67955C8.09604 2.55577 8.20115 2.43475 8.31744 2.31846C10.0747 0.561196 12.9243 0.561196 14.6815 2.31846C16.4398 4.07576 16.4398 6.92443 14.6814 8.68168L14.6771 8.68598L8.34911 14.8579C8.15492 15.0474 7.84512 15.0474 7.65091 14.858L1.31741 8.68159C-0.439848 6.92433 -0.43982 4.07472 1.31744 2.31746ZM6.97444 3.02457C5.6077 1.65783 3.39129 1.65783 2.02455 3.02457C0.658528 4.39059 0.657811 6.6054 2.0224 7.97231L7.99997 13.8016L13.9766 7.97228C15.3421 6.6054 15.3415 4.3917 13.9746 3.02568C12.6078 1.65894 10.3913 1.65883 9.02455 3.02557C8.78979 3.26032 8.6055 3.53416 8.43925 3.83988C8.35173 4.00082 8.1832 4.10101 8 4.10101C7.8168 4.10101 7.64826 4.00082 7.56074 3.83988C7.39422 3.53365 7.20947 3.25875 6.97444 3.02457Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Heart'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.91053 2.586L9.90844 2.58377C9.73665 2.39957 9.52879 2.25268 9.2978 2.15226C9.06681 2.05183 8.81763 2 8.56575 2C8.31388 2 8.06469 2.05183 7.8337 2.15226C7.60365 2.25228 7.39654 2.39838 7.22515 2.58153C7.09328 2.72514 6.98243 2.88672 6.89591 3.06146C6.72721 3.40217 6.37988 3.61775 5.99969 3.61772C5.61949 3.6177 5.27219 3.40208 5.10353 3.06133C5.01717 2.88685 4.90629 2.72562 4.77428 2.58253C4.60265 2.39927 4.39528 2.25311 4.16497 2.15308C3.9339 2.05272 3.68467 2.00093 3.43275 2.00093C3.18084 2.00093 2.93161 2.05272 2.70054 2.15308C2.46948 2.25343 2.26151 2.40022 2.08955 2.58432C1.71101 2.98909 1.5 3.52303 1.5 4.07722C1.5 4.6281 1.70807 5.15851 2.08234 5.56239L5.99975 9.57096L9.9168 5.56273C10.2921 5.15942 10.5008 4.62886 10.5008 4.07773C10.5008 3.52353 10.2898 2.99013 9.91053 2.586ZM10.6398 6.25373L5.99975 11.0017L1.35875 6.25272C0.806968 5.6627 0.5 4.88506 0.5 4.07722C0.5 3.26939 0.806968 2.49175 1.35875 1.90173C1.62427 1.61746 1.94539 1.39081 2.30217 1.23585C2.65895 1.08089 3.04377 1.00093 3.43275 1.00093C3.82173 1.00093 4.20656 1.08089 4.56334 1.23585C4.85217 1.3613 5.11763 1.53373 5.34914 1.74568C5.4036 1.79554 5.45619 1.84759 5.50675 1.90173C5.70452 2.11556 5.87054 2.35669 5.99975 2.61772C6.1289 2.35689 6.29457 2.1158 6.49175 1.90173C6.54245 1.84736 6.59519 1.7951 6.64982 1.74505C6.88111 1.53312 7.14635 1.36067 7.43498 1.23518C7.79178 1.08005 8.17669 1 8.56575 1C8.95482 1 9.33972 1.08005 9.69653 1.23518C10.0533 1.39031 10.3744 1.6172 10.6398 1.90173C11.1929 2.49124 11.5008 3.26931 11.5008 4.07773C11.5008 4.88614 11.1929 5.66421 10.6398 6.25373Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Heart.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Heart;
