import styled from 'styled-components';

// stylelint-disable property-no-vendor-prefix
const ModalBody = styled.div`
  -ms-grid-row: 2;
  grid-area: body;
  overflow: auto;
`;

export default ModalBody;
