import React, { useState, useEffect, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ClearIcon from '../../../BrandCore/icons/interactive/Clear';

const Svg = styled.svg`
  transform: rotate(-90deg);
  g {
    visibility: hidden;
  }
  &:hover {
    path {
      fill: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
    }

    g {
      visibility: visible;
    }
  }
`;
const Circle = styled.circle`
  fill: none;
  stroke-width: ${(props) => props.thickness};
`;

const ProgressCircular = ({
  domID,
  progress,
  isStoppable,
  onStop,
  width = 36,
  thickness = 4,
  doneThickness = 4,
  fillColor: _fillColor = null,
  outerFillColor: _outerFillColor = null,
  dataTestId = '',
  indeterminate = false,
  closeIconPosition = 10,
}) => {
  const theme = useTheme();
  const fillColor = useMemo(
    () => _fillColor || theme.UIPalette.Colors.Status.Positive,
    [theme, _fillColor],
  );
  const outerFillColor = useMemo(
    () => _outerFillColor || theme.BaseColors.Neutral['15'],
    [theme, _outerFillColor],
  );

  const circumference = 2 * Math.PI * ((width - doneThickness) / 2);
  const style = {};
  const [indeterminateProgress, setIndeterminateProgress] = useState(270);

  useEffect(() => {
    let progressInterval = null;
    if (indeterminate) {
      progressInterval = setInterval(() => {
        setIndeterminateProgress((progressState) => {
          let progressTotal = progressState + 10;
          if (progressTotal >= 360) {
            progressTotal = 0;
          }
          return progressTotal;
        });
      }, 28);
    }
    return () => clearInterval(progressInterval);
  }, [indeterminate]);

  style.transform = `rotate(${indeterminateProgress}deg)`;

  return (
    <Svg
      id={domID}
      height={width}
      width={width}
      data-testid={dataTestId}
      style={style}
    >
      <Circle
        cx={width / 2}
        cy={width / 2}
        r={(width - thickness) / 2}
        stroke={outerFillColor}
        thickness={thickness}
      />
      <Circle
        cx={width / 2}
        cy={width / 2}
        r={(width - doneThickness) / 2}
        thickness={doneThickness}
        stroke={fillColor}
        strokeDasharray={circumference}
        strokeDashoffset={circumference - (progress / 100) * circumference}
        strokeLinecap="round"
      />
      {isStoppable && progress > 0 && progress < 100 && (
        <g
          onClick={onStop}
          style={{
            transform: `translate(${closeIconPosition}px, ${closeIconPosition}px)`,
            pointerEvents: 'bounding-box',
            cursor: 'pointer',
          }}
        >
          <ClearIcon size="medium" />
        </g>
      )}
    </Svg>
  );
};

ProgressCircular.propTypes = {
  domID: PropTypes.string,
  progress: PropTypes.number,
  isStoppable: PropTypes.bool,
  onStop: PropTypes.func,
  width: PropTypes.number,
  /**
   * Thickness of grey ring - indicates incomplete %
   */
  thickness: PropTypes.number,
  /**
   * Thickness of fillColor ring - indicates complete %
   */
  doneThickness: PropTypes.number,
  fillColor: PropTypes.string,
  outerFillColor: PropTypes.string,
  dataTestId: PropTypes.string,
  indeterminate: PropTypes.bool,
  closeIconPosition: PropTypes.number,
};

export default ProgressCircular;
