import styled from 'styled-components';

export default styled.div`
  position: relative;
  padding: 5px;
  font-size: 6px;
  line-height: 8px;
  width: 100%;
  height: 24px;
  /*border-radius*/
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 20px;
  transition: 0.25s ease-in-out;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.UIPalette.Colors.Field.Border.Disabled
      : props.theme.UIPalette.Colors.Field.Border.Default};
  outline: none;

  &:hover,
  &:active {
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Field.Border.Hover};
  }

  &.disabled {
    &:hover,
    &:active {
      background-color: ${(props) =>
        props.theme.UIPalette.Colors.Field.Border.Disabled};
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 17px;
    text-indent: 20px;
    height: 16px;
    width: 16px;
    /*border-radius*/
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: 4px;
    left: 4px;
    right: auto;
    background: ${(props) => props.theme.BaseColors.White};
    transition: 0.25s ease-in-out;
  }

  &.toggle-right {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.UIPalette.Colors.Field.Border.Disabled
        : props.theme.UIPalette.Colors.Background.ToggleOn};
    &:before {
      left: 22px;
    }
  }
`;
