import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const FAQ = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'FAQ'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 11.0001C0.5 5.63456 5.7227 1.50012 12 1.50012C18.2773 1.50012 23.5 5.63456 23.5 11.0001C23.5 13.2788 22.4987 15.3349 20.924 16.9795L21.4967 21.9428C21.517 22.1183 21.4431 22.2914 21.3025 22.3983C21.1618 22.5051 20.9752 22.5298 20.8116 22.4633L15.0723 20.1286C14.0691 20.4081 13.07 20.5001 12 20.5001C5.7227 20.5001 0.5 16.3657 0.5 11.0001ZM12 2.50012C6.0773 2.50012 1.5 6.36569 1.5 11.0001C1.5 15.6346 6.0773 19.5001 12 19.5001C13.0691 19.5001 14.0174 19.4029 14.9563 19.1212C15.0656 19.0884 15.1827 19.094 15.2884 19.137L20.4065 21.2189L19.9033 16.8574C19.8858 16.7058 19.9385 16.5545 20.0464 16.4466C21.5739 14.9191 22.5 13.0402 22.5 11.0001C22.5 6.36569 17.9227 2.50012 12 2.50012ZM11.3439 16.6646C11.8182 16.6646 12.2027 16.2801 12.2027 15.8058C12.2027 15.3315 11.8182 14.947 11.3439 14.947C10.8696 14.947 10.485 15.3315 10.485 15.8058C10.485 16.2801 10.8696 16.6646 11.3439 16.6646ZM9.7021 6.43808C10.4796 6.0945 11.3694 5.94579 12.1353 6.01769C12.9134 6.09073 13.4674 6.37749 13.727 6.79753C14.2891 7.70715 13.9745 8.85782 12.7736 9.87542C11.4943 10.9593 10.8439 11.6875 10.8439 12.7999V13.2999H11.8439V12.7999C11.8439 12.1947 12.1228 11.7376 13.42 10.6384C14.7956 9.4728 15.5185 7.79447 14.5777 6.27186C14.0857 5.47579 13.1638 5.10984 12.2288 5.02206C11.2815 4.93314 10.2209 5.11552 9.2979 5.52341L8.84056 5.72552L9.24477 6.64019L9.7021 6.43808Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'FAQ'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1.52588e-05C3.65144 1.52588e-05 0 3.06908 0 7.00002C0 10.9309 3.65144 14 8 14C8.49413 14 8.97723 13.9598 9.44625 13.8847L13.2629 15.9402C13.4179 16.0237 13.6053 16.0195 13.7564 15.9293C13.9075 15.839 14 15.676 14 15.5V11.6215C15.233 10.3977 16 8.78487 16 7.00002C16 3.06908 12.3486 1.52588e-05 8 1.52588e-05ZM1 7.00002C1 3.75095 4.06456 1.00002 8 1.00002C11.9354 1.00002 15 3.75095 15 7.00002C15 8.54797 14.309 9.96672 13.1583 11.044C13.0573 11.1385 13 11.2707 13 11.409V14.6628L9.76908 12.9228C9.66955 12.8692 9.55479 12.8509 9.44352 12.8709C8.97685 12.9548 8.49446 13 8 13C4.06456 13 1 10.2491 1 7.00002ZM8.20258 3.49849C7.63478 3.44519 6.97047 3.55561 6.38961 3.81231C6.13703 3.92393 5.84179 3.80966 5.73017 3.55708C5.61855 3.3045 5.73282 3.00926 5.9854 2.89764C6.71179 2.57663 7.54686 2.43254 8.29605 2.50287C9.033 2.57205 9.78428 2.86253 10.1893 3.51798C10.9564 4.75936 10.3534 6.11059 9.27577 7.02372C8.2764 7.87053 8.10501 8.19055 8.10501 8.59719C8.10501 8.87333 7.88116 9.09719 7.60501 9.09719C7.32887 9.09719 7.10501 8.87333 7.10501 8.59719C7.10501 7.68337 7.648 7.09228 8.62931 6.26078C9.53234 5.49561 9.72692 4.67205 9.33862 4.04366C9.16595 3.76423 8.78262 3.55294 8.20258 3.49849ZM7.60498 11.5682C7.96961 11.5682 8.26521 11.2726 8.26521 10.908C8.26521 10.5434 7.96961 10.2478 7.60498 10.2478C7.24035 10.2478 6.94475 10.5434 6.94475 10.908C6.94475 11.2726 7.24035 11.5682 7.60498 11.5682Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'FAQ'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 0C2.75645 0 0 2.29306 0 5.267C0 8.24106 2.75659 10.533 6 10.533H6.00007C6.34661 10.533 6.69256 10.5067 7.03497 10.4544L9.76084 11.9391C9.91577 12.0235 10.1037 12.02 10.2554 11.9299C10.407 11.8398 10.5 11.6764 10.5 11.5V8.71792C10.9331 8.29681 11.2861 7.79958 11.5409 7.25043C11.8279 6.63171 11.9842 5.96039 11.9999 5.27849L11.5 5.267H12C12 2.29306 9.24356 0 6 0ZM6 1C8.82808 1 10.9964 2.97163 11 5.26125L5.99993 9.533C3.16938 9.53297 1 7.55892 1 5.267C1 2.97494 3.16955 1 6 1ZM11 5.26125L6 9.533C6.34695 9.53295 6.69322 9.50211 7.03472 9.44086C7.14672 9.42076 7.26223 9.43948 7.36216 9.49391L9.5 10.6583V8.5C9.5 8.35689 9.56132 8.22064 9.66844 8.12574C10.076 7.76471 10.4046 7.32344 10.6337 6.82956C10.8621 6.33742 10.9867 5.80358 11 5.26125ZM6.04976 3.00851C5.67928 2.97374 5.23962 3.04602 4.85463 3.21616C4.60205 3.32778 4.30681 3.21351 4.19519 2.96093C4.08357 2.70835 4.19784 2.41311 4.45042 2.30149C4.98094 2.06705 5.59135 1.96109 6.14322 2.01289C6.68285 2.06355 7.26412 2.27885 7.58259 2.79423C8.17657 3.75546 7.6931 4.78083 6.91221 5.4425C6.20961 6.03785 6.14526 6.22135 6.14526 6.43015C6.14526 6.70629 5.9214 6.93015 5.64526 6.93015C5.36912 6.93015 5.14526 6.70629 5.14526 6.43015C5.14526 5.71418 5.58121 5.2596 6.26575 4.67956C6.87201 4.16585 6.9471 3.66814 6.73191 3.3199C6.64579 3.18055 6.43246 3.04444 6.04976 3.00851ZM5.64524 8.51086C5.90061 8.51086 6.10762 8.30384 6.10762 8.04847C6.10762 7.79311 5.90061 7.58609 5.64524 7.58609C5.38987 7.58609 5.18286 7.79311 5.18286 8.04847C5.18286 8.30384 5.38987 8.51086 5.64524 8.51086Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

FAQ.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default FAQ;
