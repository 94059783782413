import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Download = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Download'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.5 0C8.77614 0 9 0.223858 9 0.5V10.2929L13.1464 6.14645C13.3417 5.95118 13.6583 5.95118 13.8536 6.14645C14.0488 6.34171 14.0488 6.65829 13.8536 6.85355L8.85355 11.8536C8.75669 11.9504 8.62996 11.9992 8.503 12L8.5 12L8.497 12C8.4303 11.9996 8.36669 11.9861 8.30861 11.9621C8.24964 11.9377 8.19439 11.9015 8.14645 11.8536L3.14645 6.85355C2.95118 6.65829 2.95118 6.34171 3.14645 6.14645C3.34171 5.95118 3.65829 5.95118 3.85355 6.14645L8 10.2929V0.5C8 0.223858 8.22386 0 8.5 0ZM1.5 15C1.22386 15 1 15.2239 1 15.5C1 15.7761 1.22386 16 1.5 16H15.5C15.7761 16 16 15.7761 16 15.5C16 15.2239 15.7761 15 15.5 15H1.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Download'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 0C6.27614 0 6.5 0.223858 6.5 0.5V8.29289L9.64645 5.14645C9.84171 4.95118 10.1583 4.95118 10.3536 5.14645C10.5488 5.34171 10.5488 5.65829 10.3536 5.85355L6.35355 9.85355C6.25669 9.95042 6.12996 9.99923 6.003 9.99999L6 10L5.997 9.99999C5.9303 9.9996 5.86669 9.98615 5.80861 9.96206C5.74964 9.93766 5.69439 9.90149 5.64645 9.85355L1.64645 5.85355C1.45118 5.65829 1.45118 5.34171 1.64645 5.14645C1.84171 4.95118 2.15829 4.95118 2.35355 5.14645L5.5 8.29289V0.5C5.5 0.223858 5.72386 0 6 0ZM0.5 11C0.223858 11 0 11.2239 0 11.5C0 11.7761 0.223858 12 0.5 12H11.5C11.7761 12 12 11.7761 12 11.5C12 11.2239 11.7761 11 11.5 11H0.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Download.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Download;
