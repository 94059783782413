import styled from 'styled-components';
import { borderHelpers } from '../../BrandCore/shapes/borders';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.UIPalette.Colors.Background.Depth5};
  ${({ theme }) => theme.BoxShadows.Depth5};
  ${borderHelpers.borderRadius8};
`;

const CardBodyWrapper = styled.div`
  flex-grow: 1;
`;

export { CardWrapper as default, CardBodyWrapper };
