import styled from 'styled-components';
import { neutral15 } from '../../colors/themes/base/basePalette';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  background-color: ${neutral15};
`;
