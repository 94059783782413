import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem; /* 15rem minus width of avatar */
  ${TextStyles.small};
`;
