// Credits to https://coderwall.com/p/z8uxzw/javascript-color-blender
export function intToHex(num) {
  let hex = Math.round(num).toString(16);
  if (hex.length === 1) hex = `0${hex}`;
  return hex;
}

export function blendColors(
  color1 = '#000000',
  color2 = '#ffffff',
  percentage = 0.5,
) {
  let c1;
  let c2;
  // 1: validate input, make sure we have provided a valid hex
  if (
    (color1.length !== 4 && color1.length !== 7) ||
    (color2.length !== 4 && color2.length !== 7)
  )
    console.warn('colors must be provided as hexes');

  if (percentage > 1 || percentage < 0)
    console.warn('percentage must be between 0 and 1');

  // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
  //      the three character hex is just a representation of the 6 hex where each character is repeated
  //      ie: #060 => #006600 (green)
  if (color1.length === 4) {
    c1 = color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
  } else {
    c1 = color1.substring(1);
  }
  if (color2.length === 4) {
    c2 = color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
  } else {
    c2 = color2.substring(1);
  }

  // 3: we have valid input, convert colors to rgb
  const color1array = [
    parseInt(c1[0] + c1[1], 16),
    parseInt(c1[2] + c1[3], 16),
    parseInt(c1[4] + c1[5], 16),
  ];
  const color2array = [
    parseInt(c2[0] + c2[1], 16),
    parseInt(c2[2] + c2[3], 16),
    parseInt(c2[4] + c2[5], 16),
  ];

  // 4: blend
  let color3 = [
    (1 - percentage) * color1array[0] + percentage * color2array[0],
    (1 - percentage) * color1array[1] + percentage * color2array[1],
    (1 - percentage) * color1array[2] + percentage * color2array[2],
  ];

  // 5: convert to hex
  color3 = `#${intToHex(color3[0])}${intToHex(color3[1])}${intToHex(
    color3[2],
  )}`;

  // return hex
  return color3;
}

export default blendColors;
