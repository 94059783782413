import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextCell from './components/TextCell';
import CountCell from './components/CountCell';
import ChipCell from './components/ChipCell';
import IconCell from './components/IconCell';
import EmptyCell from './components/EmptyCell';
import StoplightTagCell from './components/StoplightTagCell';
import AvatarCell from './components/AvatarCell';
import MultiLineCell from './components/MultiLineCell';
import ButtonCell from './components/ButtonCell';
import DropdownButtonCell from './components/DropdownButtonCell';
import SplitDropdownCell from './components/SplitDropdownCell';
import ToggleCell from './components/ToggleCell';
import SelectDropdownCell from './components/SelectDropdownCell';
import LinkCell from './components/LinkCell';
import CustomCell from './components/CustomCell';
import ProgressCell from './components/ProgressCell';
import StatusCell from './components/StatusCell';

const componentMap = {
  text: TextCell,
  count: CountCell,
  chip: ChipCell,
  icon: IconCell,
  empty: EmptyCell,
  stoplightTag: StoplightTagCell,
  avatar: AvatarCell,
  multiLine: MultiLineCell,
  button: ButtonCell,
  dropdownButton: DropdownButtonCell,
  splitDropdown: SplitDropdownCell,
  toggle: ToggleCell,
  selectDropdown: SelectDropdownCell,
  link: LinkCell,
  custom: CustomCell,
  progress: ProgressCell,
  status: StatusCell,
};

const CellWrapper = styled.div`
  min-height: ${(props) => props.rowHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(props) => props.align};
`;

const translateCellAlignToFlex = (cellAlign) => {
  switch (cellAlign) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
    default:
      return 'flex-start';
  }
};

const GridCell = memo((props) => {
  const {
    cellType = 'text',
    rowHeight = null,
    cellAlign = 'left',
    data = null,
    role = 'cell',
  } = props;
  const CellComponent = componentMap[cellType];
  const lineBasedHeight = data?.secondLine ? 56 : 36;
  const cellHeight = rowHeight !== null ? rowHeight : lineBasedHeight;

  return (
    <CellWrapper
      align={translateCellAlignToFlex(cellAlign)}
      className="cell-component-wrapper"
      rowHeight={cellHeight}
      role={role}
    >
      <CellComponent {...props} />
    </CellWrapper>
  );
});

GridCell.propTypes = {
  /** where to align the content of the cell */
  cellAlign: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Pass as the cellType key in a column in the columns prop of Grid or VirtualGrid
   * to choose the kind of cell that column will use */
  cellType: PropTypes.oneOf([
    'text',
    'button',
    'dropdownButton',
    'splitDropdown',
    'selectDropdown',
    'toggle',
    'link',
    'select',
    'count',
    'chip',
    'icon',
    'empty',
    'stoplightTag',
    'avatar',
    'multiLine',
    'custom',
    'progress',
    'status',
  ]),
  /* eslint-disable react/forbid-prop-types */
  /**
   * The data shape depends on the cell type.
   * Pass the cell data in each record object in records at the key with the name
   * set in the column as dataName */
  data: PropTypes.any,
  /* eslint-enable react/forbid-prop-types */
  /** This is passed by by Grid or VirtualGrid */
  rowHeight: PropTypes.number,
  /* a11y role for this component. It's default value is cell. */
  role: PropTypes.string,
};

export default GridCell;
