import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const DiamondDetail = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond Detail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.25 1.75C5.0776 1.75 4.91737 1.83881 4.826 1.985L1.076 7.985C0.964798 8.16292 0.976508 8.39135 1.10532 8.55697L11.6053 22.057C11.7001 22.1788 11.8457 22.25 12 22.25C12.1543 22.25 12.2999 22.1788 12.3947 22.057L22.8947 8.55697C23.0235 8.39135 23.0352 8.16292 22.924 7.985L19.174 1.985C19.0826 1.83881 18.9224 1.75 18.75 1.75H5.25ZM5.20149 3.27101L2.40212 7.75H7.44098L5.20149 3.27101ZM2.52232 8.75L10.8409 19.4453L7.86996 8.75H2.52232ZM13.1591 19.4453L21.4777 8.75H16.13L13.1591 19.4453ZM21.5979 7.75L18.7985 3.27101L16.559 7.75H21.5979ZM17.941 2.75H12.9021L15.7015 7.22899L17.941 2.75ZM11.0979 2.75H6.05902L8.29851 7.22899L11.0979 2.75ZM14.8479 7.75L12 3.1934L9.15212 7.75H14.8479ZM12 19.8818L8.90782 8.75H15.0922L12 19.8818Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond Detail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.17857 0.585693C3.02341 0.585693 2.8792 0.665622 2.79697 0.797194L0.118398 5.08291C0.0183151 5.24304 0.0288548 5.44862 0.144788 5.59768L7.64479 15.2405C7.73004 15.3502 7.86113 15.4143 8 15.4143C8.13886 15.4143 8.26995 15.3502 8.35521 15.2405L15.8552 5.59768C15.9711 5.44862 15.9817 5.24304 15.8816 5.08291L13.203 0.797194C13.1208 0.665622 12.9766 0.585693 12.8214 0.585693H3.17857ZM3.13491 1.9546L1.31191 4.8714H4.59331L3.13491 1.9546ZM1.42008 5.7714L6.95677 12.89L4.97938 5.7714H1.42008ZM9.04322 12.89L14.5799 5.7714H11.0206L9.04322 12.89ZM14.6881 4.8714L12.8651 1.95461L11.4067 4.8714H14.6881ZM12.0933 1.48569H8.81191L10.6349 4.4025L12.0933 1.48569ZM7.18808 1.48569H3.90669L5.36508 4.40249L7.18808 1.48569ZM9.86665 4.8714L8 1.88475L6.13334 4.8714H9.86665ZM8 13.2829L5.91346 5.7714H10.0865L8 13.2829Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond Detail'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M1.87501 0.625C1.68562 0.625 1.51249 0.732001 1.42779 0.901393L-0.072208 3.90139C-0.161047 4.07907 -0.136277 4.29249 -0.00910508 4.44509L5.6159 11.1951C5.71089 11.3091 5.85162 11.375 6.00001 11.375C6.1484 11.375 6.28912 11.3091 6.38412 11.1951L12.0091 4.44509C12.1363 4.29249 12.1611 4.07907 12.0722 3.90139L10.5722 0.901393C10.4875 0.732001 10.3144 0.625 10.125 0.625H1.87501ZM0.968462 4.05612L2.18402 1.625H9.81599L11.0316 4.05612L6.00001 10.094L0.968462 4.05612ZM2.62501 3.625C2.34886 3.625 2.12501 3.84886 2.12501 4.125C2.12501 4.40114 2.34886 4.625 2.62501 4.625H9.37501C9.65115 4.625 9.87501 4.40114 9.87501 4.125C9.87501 3.84886 9.65115 3.625 9.37501 3.625H2.62501Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

DiamondDetail.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default DiamondDetail;
