import styled from 'styled-components';
import Borders from '../../../BrandCore/shapes/borders';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  position: absolute;
  opacity: 0;
  padding: 0.5rem 1rem;
  ${Borders.borderRadius8};
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.Tooltip};
  ${TextStyles.xSmall};
  color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentLight};
  text-align: center;
  transition: margin 0.3s, opacity 0.3s, visibility 0.3s;

  &.tt-top-left,
  &.tt-top-center,
  &.tt-top-right {
    margin: 10px 0 0 0;
  }

  &.tt-bottom-left,
  &.tt-bottom-center,
  &.tt-bottom-right {
    margin: -10px 0 0 0;
  }

  &.tt-right-up,
  &.tt-right-center,
  &.tt-right-down {
    margin: 0 0 0 -10px;
  }

  &.tt-left-up,
  &.tt-left-center,
  &.tt-left-down {
    margin: 0 0 0 10px;
  }

  ${(props) => (props.state === 'entered' ? `margin: 0 0 0 0 !important;` : '')}
  ${(props) => (props.state === 'entered' ? `display: block;` : '')}
  ${(props) => (props.state === 'entered' ? `height: auto;` : '')}
  ${(props) => (props.state === 'entered' ? `opacity: 1;` : '')}

  /* all the :after stuff is the arrow on the tooltip */
  &:after {
    position: absolute;
    height: 0;
    width: 0;
    content: ' ';
    pointer-events: none;
  }

  &.tt-top-left:after,
  &.tt-top-right:after,
  &.tt-top-center:after {
    top: 100%;
    border-top: ${(props) => props.caretLength}px solid transparent;
    border-bottom: ${(props) => props.caretLength}px solid transparent;
    border-left: ${(props) => props.caretWidth / 2}px solid transparent;
    border-right: ${(props) => props.caretWidth / 2}px solid transparent;
    border-top-color: ${(props) =>
      props.theme.UIPalette.Colors.Background.Tooltip};
  }

  &.tt-top-center:after {
    left: 50%;
    margin-left: -${(props) => props.caretWidth / 2}px;
  }

  &.tt-top-right:after {
    left: ${(props) => props.caretToEdge}px;
  }
  &.tt-top-left:after {
    right: ${(props) => props.caretToEdge}px;
  }

  &.tt-bottom-left:after,
  &.tt-bottom-right:after,
  &.tt-bottom-center:after {
    bottom: 100%;
    border-top: ${(props) => props.caretLength}px solid transparent;
    border-bottom: ${(props) => props.caretLength}px solid transparent;
    border-left: ${(props) => props.caretWidth / 2}px solid transparent;
    border-right: ${(props) => props.caretWidth / 2}px solid transparent;
    border-bottom-color: ${(props) =>
      props.theme.UIPalette.Colors.Background.Tooltip};
  }

  &.tt-bottom-center:after {
    left: 50%;
    margin-left: -${(props) => props.caretWidth / 2}px;
  }

  &.tt-bottom-right:after {
    left: ${(props) => props.caretToEdge}px;
  }
  &.tt-bottom-left:after {
    right: ${(props) => props.caretToEdge}px;
  }

  &.tt-right-down:after,
  &.tt-right-up:after,
  &.tt-right-center:after {
    right: 100%;
    border-top: ${(props) => props.caretWidth / 2}px solid transparent;
    border-bottom: ${(props) => props.caretWidth / 2}px solid transparent;
    border-left: ${(props) => props.caretLength}px solid transparent;
    border-right: ${(props) => props.caretLength}px solid transparent;
    border-right-color: ${(props) =>
      props.theme.UIPalette.Colors.Background.Tooltip};
  }

  &.tt-right-center:after {
    top: 50%;
    margin-top: -${(props) => props.caretWidth / 2}px;
  }

  &.tt-right-up:after {
    bottom: ${(props) => props.caretToEdge}px;
  }
  &.tt-right-down:after {
    top: ${(props) => props.caretToEdge}px;
  }

  &.tt-left-down:after,
  &.tt-left-up:after,
  &.tt-left-center:after {
    left: 100%;
    border-top: ${(props) => props.caretWidth / 2}px solid transparent;
    border-bottom: ${(props) => props.caretWidth / 2}px solid transparent;
    border-left: ${(props) => props.caretLength}px solid transparent;
    border-right: ${(props) => props.caretLength}px solid transparent;
    border-left-color: ${(props) =>
      props.theme.UIPalette.Colors.Background.Tooltip};
  }

  &.tt-left-center:after {
    top: 50%;
    margin-top: -${(props) => props.caretWidth / 2}px;
  }

  &.tt-left-up:after {
    bottom: ${(props) => props.caretToEdge}px;
  }
  &.tt-left-down:after {
    top: ${(props) => props.caretToEdge}px;
  }
`;
