import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import padding from '../../../commonResources/padding';

export default styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 8px ${padding.navigationPaneHeader.left}px 8px
    ${(props) => props.paddingLeft}px;
  width: 100%;
  ${TextStyles.small};
  color: ${(props) =>
    props.isExpanded
      ? props.theme.UIPalette.Colors.Content.Primary
      : props.theme.UIPalette.Colors.Content.Secondary};
  height: ${(props) => (props.isTextWrapping ? 'auto' : '36px')};
  min-height: 36px;
  border: 1px solid transparent;

  > span {
    align-items: center;
  }

  &:hover {
    cursor: ${(props) => (props.isLink ? 'pointer' : 'text')};
    background: ${(props) => props.theme.BaseColors.Neutral['10']};
  }

  &:active {
    background: ${(props) => props.theme.BaseColors.Neutral['15']};
  }

  &:focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.selected {
    background: ${(props) =>
      props.theme.UIPalette.Colors.Filter.Background.Selected};
  }

  .arrow {
    transform-style: preserve-3d;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s,
      transform 300ms;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(-90deg);
    margin-right: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .arrow.expanded {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
`;
