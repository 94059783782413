/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import useInterval from '../../commonResources/hooks/useInterval';

import {
  StandardButton,
  UnstyledButton,
  EmphasizedButton,
  DeEmphasizedButton,
  DeEmphasizedReversedButton,
  DiminishedButton,
  DestroyButton,
  EmphasizedAltButton,
} from '../../commonResources/styles/buttons';
import { useFocus } from '../../commonResources/hooks/useFocus';
import { useHover } from '../../commonResources/hooks/useHover';
import Down from '../../BrandCore/icons/interactive/Down';
import DropdownWrapper from './components/DropdownWrapper';
import ProgressCircular from '../ProgressIndicator/components/progress.circular';
import ButtonContent from './components/ButtonContent';
import CheckIndicator from './components/CheckIndicator';
import { Check } from '../../BrandCore/icons/informational';
import Tooltip from '../Tooltip';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';

const DEFAULT_STYLE_CLASS = 'button';
const buttonComponentMap = {
  unstyled: UnstyledButton,
  standard: StandardButton,
  destroy: DestroyButton,
  emphasized: EmphasizedButton,
  deEmphasized: DeEmphasizedButton,
  deEmphasizedReversed: DeEmphasizedReversedButton,
  diminished: DiminishedButton,
  emphasizedAlt: EmphasizedAltButton,
};

const DELAY_DEFAULT = 99999999;

export const Button = React.forwardRef(
  (
    {
      buttonType = 'standard',
      size = 'medium',
      name = null,
      className = '',
      disabled = false,
      domID = null,
      icon: Icon = null,
      children = null,
      isDropdown = false,
      type = 'button',
      onFocus = () => false,
      onBlur = () => false,
      onClick = () => false,
      onMouseOver = () => false,
      onMouseOut = () => false,
      dataTestId = '',
      shouldLoadResourceOnClick = false,
      progress = null,
      awaitedResourceDidLoad = false,
      onClickAfterResourceLoaded = null,
      indeterminate = false,
      shouldTruncateName = false,
      tooltipStyle = {},
      tooltipProps = {},
      ...otherProps
    },
    ref,
  ) => {
    const theme = useTheme();
    // state vars for loading buttons
    const [loading, setLoading] = useState(false);
    const [delay, setDelay] = useState(DELAY_DEFAULT);
    const [indeterminateProgress, setIndeterminateProgress] = useState(0);

    const tooltipRef = useRef();
    const isNameTruncated = useIsTruncated(tooltipRef, [name]);

    // timer for indeterminate loading button animations
    useInterval(() => {
      if (!shouldLoadResourceOnClick || !isNaN(parseInt(progress))) return;
      if (awaitedResourceDidLoad) {
        setDelay(DELAY_DEFAULT);
        setIndeterminateProgress(0);
        return;
      }
      setIndeterminateProgress(indeterminateProgress === 0 ? 200 : 0);
    }, delay);

    const [initialToolTipShown, setToolTip] = useState(false);

    const ButtonComponent = buttonComponentMap[buttonType];
    const classes = [DEFAULT_STYLE_CLASS, size, buttonType];
    const caretClasses = [size, buttonType, 'dropdown-caret'];

    const decoratedFocus = (event) => {
      setToolTip(true);
      onFocus(event);
    };

    const decoratedBlur = (event) => {
      setToolTip(false);
      onBlur(event);
    };

    const decoratedMouseOver = (event) => {
      setToolTip(true);
      onMouseOver(event);
    };

    const decoratedMouseOut = (event) => {
      setToolTip(false);
      onMouseOut(event);
    };

    const [, focusBinding] = useFocus(decoratedFocus, decoratedBlur);
    const [, hoverBinding] = useHover(decoratedMouseOver, decoratedMouseOut);

    const iconSize = size === 'large' ? 'medium' : 'small';
    const iconClass = name ? 'padded-icon' : 'icon';
    const iconColorMap = {
      unstyled: theme.UIPalette.Colors.Content.Secondary,
      standard: theme.UIPalette.Colors.Button.Text.Standard,
      primary: theme.UIPalette.Colors.Button.Text.Standard,
      destroy: theme.UIPalette.Colors.Content.PersistentLight,
      emphasizedAlt: theme.UIPalette.Colors.Content.PersistentLight,
      deEmphasizedReversed: theme.UIPalette.Colors.Content.PersistentLight,
      emphasized: disabled
        ? theme.UIPalette.Colors.Button.Disabled.Text.Emphasized
        : theme.UIPalette.Colors.Button.Hover.Text.Emphasized,
      deEmphasized: disabled
        ? theme.UIPalette.Colors.Button.Disabled.Text.DeEmphasizedReversed
        : theme.UIPalette.Colors.Content.PersistentDark,
      diminished: disabled
        ? theme.UIPalette.Colors.Button.Disabled.Text.Diminished
        : theme.BaseColors.Primary['130'],
    };

    const checkIconColorMap = {
      standard: theme.UIPalette.Colors.Content.Primary,
      destroy: theme.UIPalette.Colors.Content.Error,
      emphasizedAlt: theme.UIPalette.Colors.Content.Error,
      deEmphasizedReversed: theme.UIPalette.Colors.Content.Primary,
    };

    if (className) classes.push(className);
    if (className) caretClasses.push(className);
    if (shouldLoadResourceOnClick) classes.push('load-type');
    if (shouldLoadResourceOnClick && loading && !awaitedResourceDidLoad)
      classes.push('loading');

    const clickHandler = (e) => {
      if (loading && !awaitedResourceDidLoad) return;

      // start timer for indeterminate loading button
      if (shouldLoadResourceOnClick && isNaN(parseInt(progress))) {
        setIndeterminateProgress(200);
        setDelay(1000);
      }

      // if loading button and button has loaded, fire onClickAfterResourceLoaded
      if (awaitedResourceDidLoad) {
        onClickAfterResourceLoaded(e);
      } else {
        onClick(e);
      }

      if (shouldLoadResourceOnClick && !loading) {
        setLoading(true);
      }
    };

    const dropdownClickHandler = (e) => {
      setToolTip(false);
      onClick(e);
    };

    useEffect(() => {
      if (!shouldLoadResourceOnClick) {
        setLoading(false);
      }
    }, [shouldLoadResourceOnClick]);

    useEffect(() => {
      if (awaitedResourceDidLoad) setLoading(false);
    }, [awaitedResourceDidLoad]);

    // for animating loading bar; explicit if progress prop is provided, otherwise follows indeterminateProgress
    const lineProgress = !isNaN(parseInt(progress))
      ? progress
      : indeterminateProgress;

    let circleWidth = 18;

    if (size === 'medium') {
      circleWidth = 20;
    } else if (size === 'large') {
      circleWidth = 24;
    }

    return !isDropdown ? (
      <ButtonComponent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        id={domID}
        ref={ref}
        className={classes.join(' ')}
        onClick={clickHandler}
        {...hoverBinding}
        {...focusBinding}
        disabled={disabled}
        type={type}
        data-testid={dataTestId}
        {...otherProps}
      >
        <div
          style={{
            position: 'absolute',
            visibility:
              shouldLoadResourceOnClick && loading ? 'visible' : 'hidden',
          }}
        >
          <ButtonContent className={size}>
            {indeterminate ? (
              <div className={`indeterminate-loader ${buttonType}`}>
                <ProgressCircular
                  width={circleWidth}
                  thickness={2}
                  doneThickness={2}
                  progress={indeterminate ? 25 : lineProgress}
                  indeterminate={indeterminate}
                  fillColor={iconColorMap[buttonType]}
                />
              </div>
            ) : (
              <div className={`loader ${buttonType}`}>
                {lineProgress < 100 ? (
                  <ProgressCircular
                    width={circleWidth}
                    thickness={2}
                    doneThickness={2}
                    progress={lineProgress}
                    fillColor={iconColorMap[buttonType]}
                  />
                ) : (
                  <CheckIndicator
                    width={circleWidth}
                    fillColor={iconColorMap[buttonType]}
                  >
                    <Check
                      fillColor={
                        checkIconColorMap[buttonType] ||
                        theme.UIPalette.Colors.Button.Text.Standard
                      }
                      className="check"
                      size={iconSize}
                    />
                  </CheckIndicator>
                )}
              </div>
            )}
          </ButtonContent>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            visibility:
              shouldLoadResourceOnClick && loading ? 'hidden' : 'visible',
          }}
        >
          {Icon && (
            <Icon
              size={iconSize}
              fillColor={iconColorMap[buttonType]}
              className={iconClass}
              dataTestId={`${dataTestId}-icon`}
            />
          )}
          {shouldTruncateName ? (
            <Tooltip
              tooltipContent={name}
              hideTooltip={!isNameTruncated}
              style={{
                display: 'flex',
                ...tooltipStyle,
              }}
              isButtonRoleDisabled
              initialToolTipShown={initialToolTipShown}
              {...tooltipProps}
            >
              <span ref={tooltipRef}>{name}</span>
            </Tooltip>
          ) : (
            name
          )}
        </div>

        {children}
      </ButtonComponent>
    ) : (
      <DropdownWrapper id={`${domID}-dropdown-wrapper`}>
        <ButtonComponent
          id={domID}
          className={classes.join(' ')}
          onClick={dropdownClickHandler}
          {...hoverBinding}
          {...focusBinding}
          disabled={disabled}
          data-testid={dataTestId}
          type={type}
          {...otherProps}
        >
          {Icon && (
            <Icon
              size={iconSize}
              fillColor={iconColorMap[buttonType]}
              className={iconClass}
            />
          )}
          {shouldTruncateName ? (
            <Tooltip
              tooltipContent={name}
              hideTooltip={!isNameTruncated}
              style={{
                display: 'flex',
                ...tooltipStyle,
              }}
              isButtonRoleDisabled
              initialToolTipShown={initialToolTipShown}
            >
              <span ref={tooltipRef}>{name}</span>
            </Tooltip>
          ) : (
            name
          )}
          <Down
            className={caretClasses.join(' ')}
            size="small"
            title={null}
            fillColor={iconColorMap[buttonType]}
          />
        </ButtonComponent>
        {children}
      </DropdownWrapper>
    );
  },
);

Button.propTypes = {
  buttonType: PropTypes.oneOf([
    'standard',
    'destroy',
    'emphasized',
    'emphasizedAlt',
    'deEmphasized',
    'deEmphasizedReversed',
    'diminished',
    'unstyled',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  name: PropTypes.string,

  /** takes the event as a parameter */
  onClick: PropTypes.func,
  /** takes parameters: (event, true) */
  onFocus: PropTypes.func,
  /** takes parameters: (event, false) */
  onBlur: PropTypes.func,
  /** takes parameters: (event, true) */
  onMouseOver: PropTypes.func,
  /** takes parameters: (event, false) */
  onMouseOut: PropTypes.func,

  // Loading Button props
  /** if true, this is a Loading button */
  shouldLoadResourceOnClick: PropTypes.bool,
  /** % progress of loading resource. If left undefined, a default loading animation is shown */
  progress: PropTypes.number,
  /** trigger to tell the button to re-render */
  awaitedResourceDidLoad: PropTypes.bool,
  /** callback to be called when button is pressed after resource has loaded */
  onClickAfterResourceLoaded: PropTypes.func,

  className: PropTypes.string,
  disabled: PropTypes.bool,
  domID: PropTypes.string,
  children: PropTypes.element,
  /** must be a ui-core icon to be sized and styled correctly */
  icon: PropTypes.elementType,
  isDropdown: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  dataTestId: PropTypes.string,
  indeterminate: PropTypes.bool,
  shouldTruncateName: PropTypes.bool,
  /**
   * customize tooltip style
   */
  tooltipStyle: PropTypes.objectOf(PropTypes.string),
  tooltipProps: PropTypes.object,
};

export default Button;
