import React from 'react';
import PropTypes from 'prop-types';
import Label from '../Input/components/InputLabel';
import Row from './components/Row';
import DataValueWrapper from './components/DataValueWrapper';

export const DataItem = ({
  domID = '',
  dataTestId = '',
  className = '',
  label = '',
  content = '',
  size = 'medium',
}) => (
  <div className={className}>
    {label ? <Label htmlFor={domID}>{label}</Label> : null}
    <DataValueWrapper className={size} id={domID} data-testid={dataTestId}>
      {Array.isArray(content) ? (
        content.map((item) => {
          return (
            <Row key={item} className={size}>
              {item}
            </Row>
          );
        })
      ) : (
        <Row className={size}>{content}</Row>
      )}
    </DataValueWrapper>
  </div>
);

DataItem.propTypes = {
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  /** string or array of strings, each representing a separate line of text */
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** label/title text */
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default DataItem;
