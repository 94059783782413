import React from 'react';
import PropTypes from 'prop-types';
import {
  useNotificationBannerContext,
  NotificationContext,
} from './useNotificationBanner';

const NotificationProvider = ({ children }) => {
  const notificationContextValue = useNotificationBannerContext();

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export default NotificationProvider;
