import { css } from 'styled-components';

/**
 * These are the styles that all text elements should have by default
 * Any component that uses this literal can have color prop.
 *
 */
const defaultStyles = css`
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Core Sans C;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.theme.UIPalette?.Colors?.Content?.Primary};

  ${({ singleLine }) =>
    singleLine &&
    `white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`};
`;

const textTransform = {
  uppercase: css`
    text-transform: uppercase;
  `,
};

const bold = css`
  font-weight: 600;
`;

const xxxLarge = css`
  ${defaultStyles};
  font-size: 62px;
  line-height: 120%;
  letter-spacing: -2px;
`;

const xxLarge = css`
  ${defaultStyles};
  font-size: 52px;
  line-height: 120%;
  letter-spacing: -1px;
`;

const xLarge = css`
  ${defaultStyles};
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
`;

const large = css`
  ${defaultStyles};
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
`;

const medium = css`
  ${defaultStyles};
  font-size: 16px;
  line-height: 150%;
`;

const small = css`
  ${defaultStyles};
  font-size: 13px;
  line-height: 150%;
`;

const xSmall = css`
  ${defaultStyles};
  font-size: 11px;
  line-height: 150%;
`;

const xxSmall = css`
  ${defaultStyles};
  font-size: 10px;
  line-height: 150%;
`;

const mediumBold = css`
  ${medium};
  font-weight: 600;
`;

const smallBold = css`
  ${small};
  font-weight: 600;
`;

const xSmallBold = css`
  ${xSmall};
  font-weight: 600;
`;

const xSmallCaps = css`
  ${defaultStyles};
  font-size: 12px;
  line-height: 125%;
  font-weight: 600;
  letter-spacing: 0.08em;
  ${textTransform.uppercase};
`;

const xxSmallCaps = css`
  ${xxSmall};
  font-weight: 600;
  ${textTransform.uppercase};
`;

const mediumLink = css`
  ${medium};
  line-height: 100%;
  text-decoration-line: underline;
`;

const smallLink = css`
  ${small};
  line-height: 100%;
  text-decoration-line: underline;
`;

const xSmallLink = css`
  ${xSmall};
  line-height: 100%;
  text-decoration-line: underline;
`;

const xxSmallLink = css`
  ${xxSmall};
  line-height: 100%;
  text-decoration-line: underline;
`;

export const TextStyles = {
  xxxLarge,
  xxLarge,
  xLarge,
  medium,
  large,
  small,
  xSmall,
  xxSmall,
  mediumBold,
  smallBold,
  xSmallBold,
  xSmallCaps,
  xxSmallCaps,
  mediumLink,
  smallLink,
  xSmallLink,
  xxSmallLink,
  bold,
  textTransform,
};

export default TextStyles;
