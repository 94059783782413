import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../Atoms/Button';
import ModalBody from '../Modal/components/ModalBody';
import ModalFooter from '../Modal/components/ModalFooter';
import ModalHeader from '../Modal/components/ModalHeader';
import Modal from '../Modal/Modal';
import ChangePassword from './components/ChangePassword';
import ProfileFooter from './components/Footer';
/* Child components */
import ProfileForm from './components/ProfileForm';

const emptyValidationObject = {
  errorMessage: '',
  isValid: true,
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStateWithCallback = (initialValue) => {
  const callbackRef = useRef(null);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = (newValue, callback) => {
    callbackRef.current = callback;

    return setValue({ ...value, ...newValue });
  };

  return [value, setValueWithCallback];
};

/* eslint react/prefer-stateless-function: "off", no-useless-constructor: "off" */
const ProfilePortal = ({
  isOpen = false,
  domID = 'profileForm',
  title = 'My Profile',
  userEmail = '',
  userFirstName = '',
  userLastName = '',
  dataTestId = '',
}) => {
  const [state, setState] = useStateWithCallback({
    currentElIndex: null,
    changePasswordModal: false,
    interactiveEls: [],
    isFormValid: false,
    isOpen,
    profileData: {
      userEmail: '',
      userFirstName: '',
      userLastName: '',
    },
    profileFormValidation: {
      userEmail: emptyValidationObject,
      userFirstName: emptyValidationObject,
      userLastName: emptyValidationObject,
    },
  });

  useEffect(() => {
    setState({ isOpen });
  }, [isOpen]);

  const validateForm = () => {
    const { profileFormValidation } = state;

    const inputValidities = Object.values(profileFormValidation).map(
      (entry) => {
        return entry.isValid;
      },
    );

    const isFormValid = inputValidities.every((val) => {
      if (!val) {
        return false;
      }
      return true;
    });

    setState({ isFormValid });
  };

  const changeValidationValues = (inputName, errorMessage, isValid) => {
    setState(
      (prevState) => ({
        profileFormValidation: {
          ...prevState.profileFormValidation,
          [inputName]: {
            errorMessage,
            isValid,
          },
        },
      }),
      () => {
        validateForm();
      },
    );
  };

  const validateField = (input, inputName, value) => {
    switch (inputName) {
      case 'userEmail':
        if (!value) {
          changeValidationValues(inputName, 'Required Field', false);
        } else if (!emailRegex.test(value)) {
          changeValidationValues(inputName, 'Invalid email', false);
        } else {
          changeValidationValues(inputName, '', true);
        }
        break;
      default:
        value
          ? changeValidationValues(inputName, '', true)
          : changeValidationValues(inputName, 'Required Field', false);
        break;
    }
  };

  const updateProfileData = (id, value) => {
    const inputFieldMap = {
      'email-input': 'userEmail',
      'firstname-input': 'userFirstName',
      'lastname-input': 'userLastName',
    };
    const stateName = inputFieldMap[id];

    setState((prevState) => ({
      profileData: { ...prevState.profileData, [stateName]: value },
    }));
  };

  const handleUserInput = (e) => {
    const inputFieldMap = {
      'email-input': 'userEmail',
      'firstname-input': 'userFirstName',
      'lastname-input': 'userLastName',
    };

    const inputName = inputFieldMap[e.target.id];
    const { profileFormValidation } = state;
    const input = profileFormValidation[inputFieldMap[e.target.id]];
    const { id, value } = e.target;

    validateField(input, inputName, value);
    updateProfileData(id, value);
  };

  const handleFormSubmit = (e) => {
    const { profileData } = state;
    const updatedProfileData = profileData;

    e.persist();

    Object.keys(updatedProfileData).forEach((item) => {
      !updatedProfileData[item]
        ? // eslint-disable-next-line react/destructuring-assignment
          (updatedProfileData[item] = item)
        : null;
    });

    setState((prevState) => ({
      ...prevState,
      profileData: {
        ...prevState.profileData,
        ...updatedProfileData,
      },
    }));
  };

  const renderProfileForm = () => {
    const { isFormValid, profileFormValidation } = state;

    return (
      <>
        <ModalHeader
          onClose={() => setState({ isOpen: false })}
          title={title}
        />
        <ModalBody>
          <ProfileForm
            domID={domID}
            onUserInput={(e) => handleUserInput(e)}
            profileFormValidation={profileFormValidation}
            userEmail={userEmail}
            userFirstName={userFirstName}
            userLastName={userLastName}
          />
        </ModalBody>
        <ModalFooter>
          <ProfileFooter
            isFormValid={isFormValid}
            onCancel={() => setState({ isOpen: false })}
            onSave={(e) => handleFormSubmit(e)}
            handleChangePassword={() => setState({ changePasswordModal: true })}
          />
        </ModalFooter>
      </>
    );
  };

  const renderChangePasswordModal = () => {
    return (
      <>
        <ModalHeader
          onClose={() => setState({ isOpen: false })}
          title="Change Password"
        />
        <ModalBody>
          <ChangePassword />
        </ModalBody>
        <ModalFooter>
          <Button name="Button" buttonType="emphasized" size="large" />
        </ModalFooter>
      </>
    );
  };

  const render = () => {
    return (
      <Modal domID={domID} isOpen={state.isOpen} dataTestId={dataTestId}>
        {state.changePasswordModal
          ? renderChangePasswordModal()
          : renderProfileForm()}
      </Modal>
    );
  };

  return render();
};

ProfilePortal.propTypes = {
  /**
   * The component's `id` for testing
   */
  dataTestId: PropTypes.string,
  /**
   * The component's `id` in the DOM
   * */
  domID: PropTypes.string,
  /**
   * Is modal open or closed
   */
  isOpen: PropTypes.bool,
  /**
   * Handles saving changes to the profile
   */
  onProfileSave: PropTypes.func.isRequired,
  /**
   * Text which appears in the modal header
   */
  title: PropTypes.string,
  /**
   * Current user's email address
   */
  userEmail: PropTypes.string.isRequired,
  /**
   * Current user's first name
   */
  userFirstName: PropTypes.string.isRequired,
  /**
   * Current user's last name
   */
  userLastName: PropTypes.string.isRequired,
};

export default ProfilePortal;
