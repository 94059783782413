import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const CircleCheck = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 12.5C2 6.70101 6.70101 2 12.5 2C18.299 2 23 6.70101 23 12.5C23 18.299 18.299 23 12.5 23C6.70101 23 2 18.299 2 12.5ZM12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1ZM18.8536 8.85355C19.0488 8.65829 19.0488 8.34171 18.8536 8.14645C18.6583 7.95118 18.3417 7.95118 18.1464 8.14645L10.5 15.7929L6.85355 12.1464C6.65829 11.9512 6.34171 11.9512 6.14645 12.1464C5.95118 12.3417 5.95118 12.6583 6.14645 12.8536L10.1464 16.8536C10.3417 17.0488 10.6583 17.0488 10.8536 16.8536L18.8536 8.85355Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM11.8622 5.85355C12.0459 5.65829 12.0459 5.34171 11.8622 5.14645C11.6784 4.95118 11.3804 4.95118 11.1967 5.14645L6.82353 10.7929L4.80334 8.64645C4.61957 8.45118 4.32161 8.45118 4.13783 8.64645C3.95406 8.84171 3.95406 9.15829 4.13783 9.35355L6.49077 11.8536C6.67455 12.0488 6.97251 12.0488 7.15629 11.8536L11.8622 5.85355Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM9.39043 3.81235C9.56294 3.59672 9.52798 3.28207 9.31235 3.10957C9.09672 2.93706 8.78207 2.97202 8.60957 3.18766L4.95854 7.75144L3.35355 6.14645C3.15829 5.95119 2.84171 5.95119 2.64645 6.14645C2.45118 6.34171 2.45118 6.65829 2.64645 6.85356L4.64645 8.85356C4.74701 8.95412 4.88565 9.0071 5.02765 8.99924C5.16965 8.99137 5.30159 8.92341 5.39043 8.81235L9.39043 3.81235Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

CircleCheck.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default CircleCheck;
