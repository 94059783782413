import styled from 'styled-components';
import { UnstyledButton } from '../../../../../commonResources/styles/buttons';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled(UnstyledButton)`
  display: inline-block;
  height: 100%;
  padding: 0 1rem;
  ${TextStyles.small};
  color: ${(props) => props.theme.BaseColors.White};

  &.open {
    color: ${(props) => props.theme.BaseColors.Neutral['100']};
    background-color: ${(props) => props.theme.BaseColors.Neutral['10']};
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover:not(.open) {
    background-color: ${(props) => props.theme.BaseColors.NeutralAlt['100']};
    color: ${(props) => props.theme.BaseColors.White};
  }
`;
