import styled from 'styled-components';

import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  display: flex;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  ${TextStyles.xxSmallCaps};
  text-align: center;
  background-color: ${(props) => props.theme.BaseColors.White};
  color: ${(props) => props.theme.UIPalette.Colors.Status.Positive};
  line-height: 1.125rem;
  border: 1px solid ${(props) => props.theme.UIPalette.Colors.Status.Positive};

  &.bad {
    color: ${(props) => props.theme.BaseColors.White};
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Status.Negative};
    border: none;
    box-shadow: none;
    line-height: 1.2rem;
  }
`;
