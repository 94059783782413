import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../../../BrandCore/constants/heights';
import TextStyles from '../../../../../BrandCore/Text/styles';

export const MenuContainer = styled.div`
  position: absolute;
  right: 0px;
  top: ${MASTHEAD_HEIGHT}px;
  padding-top: 14.5px;
  min-width: 272px;
  width: 417px;
  max-width: 417px;
  max-height: calc((100vh - ${MASTHEAD_HEIGHT}px) * 0.9);
  display: flex;
  flex-direction: column;
  line-height: 1.2em;
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.StandardMenu};
  z-index: 1;
  border-radius: 8px 0px 8px 8px;
  ${(props) => props.theme.BoxShadows.Depth15}

  #show-read-notifications {
    ${TextStyles.xSmall};
    color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
    padding-top: 8.5px;
  }
`;

export default MenuContainer;
