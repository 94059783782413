import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Check = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M19.8536 6.14645C20.0488 6.34171 20.0488 6.65829 19.8536 6.85355L9.85355 17.8536C9.65829 18.0488 9.34171 18.0488 9.14645 17.8536L4.14645 12.8536C3.95118 12.6583 3.95118 12.3417 4.14645 12.1464C4.34171 11.9512 4.65829 11.9512 4.85355 12.1464L9.5 16.7929L19.1464 6.14645C19.3417 5.95118 19.6583 5.95118 19.8536 6.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.8622 4.14645C13.0459 4.34171 13.0459 4.65829 12.8622 4.85355L6.15628 12.8536C5.97251 13.0488 5.67455 13.0488 5.49077 12.8536L2.13783 9.35355C1.95406 9.15829 1.95406 8.84171 2.13783 8.64645C2.32161 8.45118 2.61957 8.45118 2.80334 8.64645L5.82353 11.7929L12.1967 4.14645C12.3804 3.95118 12.6784 3.95118 12.8622 4.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Check'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.8123 2.10958C11.028 2.28209 11.0629 2.59674 10.8904 2.81237L4.89043 9.81237C4.80159 9.92342 4.66965 9.99139 4.52765 9.99925C4.38565 10.0071 4.24701 9.95414 4.14645 9.85357L1.14645 6.85357C0.951184 6.65831 0.951184 6.34173 1.14645 6.14647C1.34171 5.9512 1.65829 5.9512 1.85355 6.14647L4.45854 8.75145L10.1096 2.18767C10.2821 1.97204 10.5967 1.93708 10.8123 2.10958Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Check.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Check;
