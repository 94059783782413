import {
  white,
  neutral0,
  neutral5,
  neutral10,
  neutral15,
  neutral30,
  neutral50,
  neutral70,
  neutral100,
  neutral150,
  neutral170,
  neutralAlt30,
  primary70,
  accent30,
  accent50,
  accent130,
  primary30,
  primary115,
  negative50,
  negative70,
  negative130,
  positive130,
  caution100,
} from './basePalette';

import { brightBlue130 } from '../../datavizPalette';

const buttonFocusedBoxShadow =
  '0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(131, 144, 175, 0.3)';

const UIPalette = {
  Colors: {
    Background: {
      Modal: `${neutral100}66`,
      Masthead: `${neutral150}`,
      Selected: `${primary30}`,
      ProductSwitcher: `${neutral100}99`,
      Tooltip: `${neutral150}`,
      Depth2: `${white}`,
      Depth5: `${white}`,
      Depth10: `${white}`,
      Depth15: `${white}`,
      Breadcrumb: `${neutral5}`,
      StandardMenu: `${white}`,
      ToggleOn: `${neutral150}`,
      Default: `${primary30}`,
    },
    Content: {
      Primary: `${neutral150}`,
      Secondary: `${neutral100}`,
      Placeholder: `${neutral70}`,
      TabDisabled: `${neutralAlt30}`,
      Disabled: `${neutral30}`,
      Error: `${negative130}`,
      White: `${white}`,
      PersistentDark: `${neutral150}`,
      PersistentLight: `${white}`,
      Masthead: `${white}`,
    },
    Selected: {
      Tab: `${primary115}`,
      TabUnselected: `${neutral10}`,
      MenuItem: `${primary115}`,
    },
    Status: {
      Positive: `${positive130}`,
      Caution: `${caution100}`,
      Negative: `${negative130}`,
      Neutral: `${neutral100}`,
      Light: `${neutral15}`,
      Dark: `${neutral150}`,
    },
    Field: {
      Background: {
        Default: white,
        Disabled: neutral5,
      },
      Border: {
        Default: `${neutral70}`,
        Hover: '#6A7695',
        Focus: `${primary115}`,
        Disabled: `${neutral15}`,
        Error: `${negative130}`,
      },
    },
    Button: {
      Background: {
        Standard: `${neutral150}`,
      },
      Border: {
        Standard: `${neutral170}`,
        EmphasizedAlt: accent130,
      },
      Text: {
        Standard: `${white}`,
        Emphasized: neutral150,
      },
      Hover: {
        Background: {
          Emphasized: `${primary70}`,
          Standard: `${neutral170}`,
          DeEmphasized: `${neutral0}`,
          Diminished: `#076592`,
          Destroy: `#A10B0B`,
          EmphasizedAlt: `${accent130}`,
          DeEmphasizedReversed: `${white}05`,
        },
        Border: {
          Standard: `${neutral170}`,
          EmphasizedAlt: accent130,
        },
        Text: {
          Emphasized: `${neutral150}`,
          Diminished: `#076592`,
        },
      },
      Active: {
        Border: {
          EmphasizedAlt: accent130,
        },
      },
      Focus: {
        Border: {
          Emphasized: `${primary115}`,
          Standard: `${neutral170}`,
          DeEmphasized: `${neutral70}`,
          Destroy: '#A10B0B',
          EmphasizedAlt: `${accent130}`,
          DeEmphasizedReversed: white,
          CheckboxFocus: `${primary115}`,
          RadioFocus: `${primary115}`,
        },
        BoxShadow: {
          Standard: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${neutral170},
            ${buttonFocusedBoxShadow}
          `,
          Emphasized: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${primary115},
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasized: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${neutral150},
            ${buttonFocusedBoxShadow}
          `,
          Destroy: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${negative130},
            ${buttonFocusedBoxShadow}
          `,
          EmphasizedAlt: `
            0px 0px 0px 1px ${white},
            0px 0px 0px 2px ${accent130},
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasizedReversed: `
            0px 0px 0px 1px ${neutral150},
            0px 0px 0px 2px ${white},
            ${buttonFocusedBoxShadow}
          `,
        },
      },
      Disabled: {
        Background: {
          Emphasized: `${primary30}`,
          Standard: `${neutral50}`,
          Destroy: `${negative50}`,
          EmphasizedAlt: `${accent30}`,
          DeEmphasizedReversed: neutral150,
        },
        Border: {
          Emphasized: '#BEE4F5',
          Standard: `${neutral70}`,
          Destroy: `${negative70}`,
          EmphasizedAlt: `${accent50}`,
          DeEmphasizedReversed: `${neutral100}`,
        },
        Text: {
          Emphasized: '#6EB3D4',
          Standard: `${white}`,
          Diminished: '#AABFD4',
          Destroy: `${white}`,
          EmphasizedAlt: `${white}`,
          DeEmphasizedReversed: `${neutral100}`,
        },
      },
    },
    SplitDropdownButton: {
      Border: {
        Emphasized: `${primary115}`,
        Standard: `${neutral170}`,
        Destroy: '#A10B0B',
        EmphasizedAlt: `${accent130}`,
      },
      Disabled: {
        Border: {
          Emphasized: '#BEE4F5',
          Standard: `${neutral70}`,
          Destroy: `${negative70}`,
          EmphasizedAlt: `${accent50}`,
        },
      },
      BoxShadow: `0px 1px 3px rgba(17, 80, 244, 0.06), 0px 2px 12px rgba(131, 144, 175, 0.16)`,
    },
    Filter: {
      Border: {
        Selected: `${primary115}`,
        Focus: `${primary115}`,
      },
      Background: {
        Hover: `${neutral10}`,
        Active: `${neutral15}`,
        Selected: `${primary30}`,
        HomeButton: `${neutral100}`,
        HomeButtonDisabled: `${neutral150}`,
      },
      BoxShadow: {
        Selection: `box-shadow: inset 2px 0px 0px ${primary115};`,
      },
    },
    MenuItem: {
      Icon: `${brightBlue130}`,
      Checked: `${brightBlue130}`,
      Background: {
        Default: `${neutral5}`, // TODO replace one of the white backgrounds
      },
    },
  },
  BoxShadows: {
    Field: {
      Inner: `box-shadow: 0px 2px 6px 0px #8C94A829 inset;`,
      Outer: `box-shadow: 0px 2px 6px 0px #8C94A81F;`,
      Default: `border: 1px solid ${neutral70};`,
      Disabled: `border: 1px solid ${neutral15};`,
      Hover: `border: 1px solid #6A7695;`,
      Focus: `border: 1px solid ${primary115};`,
      Error: `border: 1px solid ${negative130};`,
    },
    Selection: {
      TabSelected: `box-shadow: 0px 2px 0px 0px ${primary115};`,
      TabUnselected: `box-shadow: 0px 2px 0px 0px ${neutral10};`,
      MenuItem: `box-shadow: 2px 0px 0px 0px ${primary115} inset;`,
    },
    General: {
      LineAbove: `box-shadow: 0px -1px 0px 0px ${neutral15};`,
      LineBelow: `box-shadow: 0px 1px 0px 0px ${neutral15};`,
      MicroModal: `box-shadow: 0px 6px 16px 2px #A6AFC540,
        0px 1px 6px 0px ${neutral150}14,
        0px -1px 2px 1px ${neutral150}14;
        filter: drop-shadow(0px 1px 6px rgba(15, 15, 89, 0.08));`,
      HelperModule: `box-shadow: 0px 1px 4px rgba(17, 80, 244, 0.12),
        0px 10px 32px rgba(166, 175, 197, 0.35),
        inset 0px 4px 0px #A84EE5;`,
      HelperModuleCustom: ({
        borderColor,
      }) => `box-shadow: 0px 1px 4px rgba(17, 80, 244, 0.12),
        0px 10px 32px rgba(166, 175, 197, 0.35),
        ${`inset 0px 4px 0px ${borderColor || '#A84EE5'}`}`,
    },
  },
};

export default UIPalette;
