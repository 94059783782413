export const black = '#000000';
export const white = '#ffffff';

export const neutral20 = '#D3D5DC';

export const transparentNeutral10 = 'rgba(55,71,79,0.12)';
export const transparentNeutral20 = 'rgba(55,71,79,0.2)';
export const transparentNeutral30 = 'rgba(55,71,79,0.3)';
export const transparentNeutral50 = 'rgba(55,71,79,0.5)';
export const transparentNeutral100 = 'rgba(55,71,79,0.9)';
export const lessTransparentNeutral100 = 'rgba(55,71,79,0.97)';
export const modalBackground = 'rgba(98, 109, 138, 0.4)';
