import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles/Wrapper';
import MenuClickTarget from './styles/MenuClickTarget';

export const StandaloneLoginLink = ({
  onStandaloneLoginSelect = () => false,
  dataTestId = '',
}) => {
  return (
    <Wrapper data-testid={dataTestId}>
      <MenuClickTarget onClick={() => onStandaloneLoginSelect()}>
        <span>Login</span>
      </MenuClickTarget>
    </Wrapper>
  );
};

StandaloneLoginLink.propTypes = {
  onStandaloneLoginSelect: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

export default StandaloneLoginLink;
