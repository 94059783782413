import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Share = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Share'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M19 1.5C17.067 1.5 15.5 3.067 15.5 5C15.5 6.933 17.067 8.5 19 8.5C20.933 8.5 22.5 6.933 22.5 5C22.5 3.067 20.933 1.5 19 1.5ZM14.5 5C14.5 2.51472 16.5147 0.5 19 0.5C21.4853 0.5 23.5 2.51472 23.5 5C23.5 7.48528 21.4853 9.5 19 9.5C17.4172 9.5 16.0253 8.68286 15.2231 7.44744L9.2247 10.4467C9.40278 10.9308 9.5 11.4541 9.5 12C9.5 12.5142 9.41376 13.0082 9.25495 13.4685L15.273 16.4775C16.0822 15.2841 17.4495 14.5 19 14.5C21.4853 14.5 23.5 16.5147 23.5 19C23.5 21.4853 21.4853 23.5 19 23.5C16.5147 23.5 14.5 21.4853 14.5 19C14.5 18.4223 14.6089 17.8701 14.8072 17.3626L8.82508 14.3716C8.0312 15.6493 6.61494 16.5 5 16.5C2.51472 16.5 0.5 14.4853 0.5 12C0.5 9.51472 2.51472 7.5 5 7.5C6.58275 7.5 7.97466 8.31713 8.77686 9.55254L14.7753 6.55333C14.5972 6.06917 14.5 5.54594 14.5 5ZM1.5 12C1.5 10.067 3.067 8.5 5 8.5C6.933 8.5 8.5 10.067 8.5 12C8.5 13.933 6.933 15.5 5 15.5C3.067 15.5 1.5 13.933 1.5 12ZM15.5 19C15.5 17.067 17.067 15.5 19 15.5C20.933 15.5 22.5 17.067 22.5 19C22.5 20.933 20.933 22.5 19 22.5C17.067 22.5 15.5 20.933 15.5 19Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Share'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3C14 4.10457 13.1046 5 12 5C11.2955 5 10.6761 4.63579 10.3199 4.08539C10.3152 4.07686 10.3102 4.06841 10.305 4.06006C10.2996 4.0514 10.294 4.04295 10.2881 4.03473C10.1053 3.73285 10 3.37872 10 3ZM9.2261 4.14464C9.08039 3.79192 9 3.40535 9 3C9 1.34315 10.3431 0 12 0C13.6569 0 15 1.34315 15 3C15 4.65685 13.6569 6 12 6C11.1077 6 10.3064 5.61045 9.75689 4.99214L6.77411 6.85588C6.91969 7.20846 7 7.59485 7 8C7 8.40516 6.91968 8.79155 6.77411 9.14414L9.75687 11.0079C10.3064 10.3896 11.1077 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13C9 12.5947 9.08039 12.2081 9.22609 11.8554L6.24347 9.99174C5.69393 10.6103 4.89248 11 4 11C2.34315 11 1 9.65685 1 8C1 6.34315 2.34315 5 4 5C4.89249 5 5.69395 5.38972 6.24348 6.00827L9.2261 4.14464ZM5.68853 6.92768C5.33362 6.36999 4.71002 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10C4.71014 10 5.33384 9.62988 5.68872 9.07203C5.69107 9.06802 5.69349 9.06403 5.69597 9.06006C5.69861 9.05584 5.7013 9.05167 5.70404 9.04756C5.89173 8.7429 6 8.38409 6 8C6 7.61604 5.8918 7.25735 5.70423 6.95275C5.70142 6.94854 5.69867 6.94428 5.69597 6.93996C5.69342 6.93589 5.69095 6.9318 5.68853 6.92768ZM10.305 11.94C10.2996 11.9487 10.2939 11.9572 10.288 11.9654C10.1052 12.2673 10 12.6214 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11C11.2956 11 10.6762 11.3642 10.3199 11.9145C10.3152 11.9231 10.3103 11.9316 10.305 11.94Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Share'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 2.5C8 1.67157 8.67157 1 9.5 1C10.3284 1 11 1.67157 11 2.5C11 3.32843 10.3284 4 9.5 4C8.92725 4 8.42947 3.67899 8.17677 3.20707C8.17179 3.19475 8.16628 3.18253 8.16023 3.17044C8.15406 3.15808 8.14744 3.14609 8.1404 3.13448C8.05032 2.94178 8 2.72676 8 2.5ZM7.08504 3.1489C7.02957 2.94196 7 2.72444 7 2.5C7 1.11929 8.11929 0 9.5 0C10.8807 0 12 1.11929 12 2.5C12 3.88071 10.8807 5 9.5 5C8.70157 5 7.99056 4.62571 7.53286 4.04302L4.91508 5.35153C4.97047 5.55834 5 5.77572 5 6C5 6.22428 4.97047 6.44166 4.91508 6.64846L7.53287 7.95698C7.99057 7.37429 8.70157 7 9.5 7C10.8807 7 12 8.11929 12 9.5C12 10.8807 10.8807 12 9.5 12C8.11929 12 7 10.8807 7 9.5C7 9.27556 7.02958 9.05803 7.08504 8.8511L4.46741 7.54267C4.00973 8.12556 3.29859 8.5 2.5 8.5C1.11929 8.5 0 7.38071 0 6C0 4.61929 1.11929 3.5 2.5 3.5C3.29859 3.5 4.00972 3.87444 4.46741 4.45733L7.08504 3.1489ZM3.82778 5.3015C3.57654 4.8249 3.07621 4.5 2.5 4.5C1.67157 4.5 1 5.17157 1 6C1 6.82843 1.67157 7.5 2.5 7.5C3.0762 7.5 3.57652 7.17511 3.82777 6.69852C3.83178 6.6891 3.83611 6.67973 3.84075 6.67044C3.8455 6.66094 3.85052 6.65165 3.85578 6.64259C3.94826 6.44782 4 6.22995 4 6C4 5.77004 3.94825 5.55216 3.85576 5.35738C3.85051 5.34832 3.8455 5.33905 3.84075 5.32955C3.83612 5.32027 3.83179 5.31092 3.82778 5.3015ZM8.16023 8.82955C8.15406 8.8419 8.14744 8.85389 8.14041 8.8655C8.05032 9.05821 8 9.27323 8 9.5C8 10.3284 8.67157 11 9.5 11C10.3284 11 11 10.3284 11 9.5C11 8.67157 10.3284 8 9.5 8C8.92723 8 8.42944 8.32102 8.17675 8.79296C8.17177 8.80527 8.16627 8.81747 8.16023 8.82955Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Share.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Share;
