import styled from 'styled-components';
import { UnstyledButton } from '../../../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  display: inline-block;
  height: 100%;
  padding: 0 1rem;
  color: ${(props) => props.customTheme.color};

  &:active {
    background-color: ${(props) => props.customTheme.activeBackground};
    path {
      fill: ${(props) => props.customTheme.activeColor};
    }
  }

  &.open {
    color: ${(props) => props.customTheme.openColor};
    background-color: ${(props) => props.customTheme.openBackground};
  }

  }

    outline: none;
  }

  &:hover:not(.open):not(:active) {
    background-color: ${(props) => props.customTheme.hoverBackground};
  }
`;
