import PropTypes from 'prop-types';
import React from 'react';
import CardWrapper, { CardBodyWrapper } from './Card.styles';

const Card = ({
  dataTestId,
  cardHeader: CardHeader,
  cardBody: CardBody,
  cardFooter: CardFooter,
  domID = null,
  children = null,
}) => {
  return (
    <CardWrapper data-testid={`${dataTestId}-wrapper`} id={domID}>
      {CardHeader ? <CardHeader /> : null}
      {CardBody ? (
        <CardBodyWrapper>
          <CardBody />
        </CardBodyWrapper>
      ) : null}
      {children && !CardBody && <CardBodyWrapper>{children}</CardBodyWrapper>}
      {CardFooter ? <CardFooter /> : null}
    </CardWrapper>
  );
};

Card.propTypes = {
  dataTestId: PropTypes.string,

  /**
   * Specify cardHeader component
   */
  cardHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * Specify cardBody component
   */
  cardBody: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * Specify cardFooter component
   */
  cardFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * When children property is specified and cardBody is not specified, it will render children as CardBody
   */
  children: PropTypes.element,
  domID: PropTypes.string,
};

export default Card;
