import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.h4`
  margin: 0;
  ${TextStyles.smallBold};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  position: relative;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
