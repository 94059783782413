import React from 'react';
import PropTypes from 'prop-types';

const CustomCell = ({
  data,
  component: CustomComponent = null,
  cellAlign = 'left',
}) => {
  if (data.sortBy && data.toRender) {
    return (
      <span>
        {CustomComponent ? (
          <CustomComponent {...data} cellAlign={cellAlign} />
        ) : (
          data.toRender
        )}
      </span>
    );
  }
  return (
    <span>
      {CustomComponent ? (
        <CustomComponent {...data} cellAlign={cellAlign} />
      ) : (
        data
      )}
    </span>
  );
};

CustomCell.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.any.isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
  /* eslint-enable react/forbid-prop-types */
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({
      sortBy: PropTypes.string,
      toRender: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    }),
  ]),
};

export default CustomCell;
