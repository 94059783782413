import React from 'react';
import PropTypes from 'prop-types';
import Button from './components/MenuToggleButtonWrapper';
import Menu from '../../../../BrandCore/icons/interactive/Menu';

export const MenuToggle = ({
  label = null,
  dataTestId = '',
  customTheme = {},
  onClick = () => false,
  isMenuOpen = false,
  domID = '',
}) => (
  <Button
    customTheme={customTheme}
    data-testid={dataTestId}
    onClick={onClick}
    isOpen={isMenuOpen}
  >
    <Menu
      domID={`${domID}-svg`}
      fillColor={isMenuOpen ? customTheme.openColor : customTheme.color}
      title={label}
    />
  </Button>
);

MenuToggle.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
  domID: PropTypes.string,
  isMenuOpen: PropTypes.bool,
};

export default MenuToggle;
