import styled, { css } from 'styled-components';

const sticky = css`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.BaseColors.White};
  z-index: 10;
  overflow: visible;
`;

export default styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: ${(props) => props.marginBottom}px;
  ${(props) => props.sticky && sticky};
`;
