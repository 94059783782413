import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  padding: 2.5px 8px 2.5px;
  background-color: ${(props) => props.theme.UIPalette.Colors.Status.Neutral};
  height: 19px;

  &.positive {
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Status.Positive};
  }

  &.negative {
    background-color: ${(props) =>
      props.theme.UIPalette.Colors.Status.Negative};
  }

  &.caution {
    background-color: ${(props) => props.theme.UIPalette.Colors.Status.Caution};
  }

  &.light {
    background-color: ${(props) => props.theme.UIPalette.Colors.Status.Light};
  }

  &.dark {
    background-color: ${(props) => props.theme.UIPalette.Colors.Status.Dark};
  }

  .chip-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    letter-spacing: 0.5px;
    ${TextStyles.xxSmallCaps};
    line-height: 130%;
    color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentLight};
  }

  &.caution,
  &.light {
    .chip-label {
      color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentDark};
    }
  }

  &.positive,
  &.negative {
    .chip-label {
      color: ${(props) => props.theme.BaseColors.White};
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px
        ${(props) => props.theme.UIPalette.Colors.Content.White},
      0 0 0 2px ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
