import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Vision = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Vision'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.5 3C3.07614 3 2 4.07614 2 5.5V14.9995C2.91223 13.7853 4.3644 13 6 13C8.02455 13 9.76805 14.2033 10.5544 15.9337C11.0135 15.365 11.7158 15 12.5 15C13.2842 15 13.9865 15.365 14.4456 15.9337C15.2319 14.2033 16.9755 13 19 13C20.6356 13 22.0878 13.7853 23 14.9995V5.5C23 4.07614 21.9239 3 20.5 3H19.5C19.2239 3 19 2.77614 19 2.5C19 2.22386 19.2239 2 19.5 2H20.5C22.4761 2 24 3.52386 24 5.5V18C24 20.7614 21.7614 23 19 23C16.2386 23 14 20.7614 14 18C14 17.8719 14.0048 17.7449 14.0143 17.6192C14.005 17.581 14 17.5411 14 17.5C14 16.6761 13.3239 16 12.5 16C11.6761 16 11 16.6761 11 17.5C11 17.5411 10.995 17.581 10.9857 17.6192C10.9952 17.7449 11 17.8719 11 18C11 20.7614 8.76142 23 6 23C3.23858 23 1 20.7614 1 18V5.5C1 3.52386 2.52386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5C6 2.77614 5.77614 3 5.5 3H4.5ZM23 18C23 15.7909 21.2091 14 19 14C16.7909 14 15 15.7909 15 18C15 20.2091 16.7909 22 19 22C21.2091 22 23 20.2091 23 18ZM6 22C3.79086 22 2 20.2091 2 18C2 15.7909 3.79086 14 6 14C8.20914 14 10 15.7909 10 18C10 20.2091 8.20914 22 6 22Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Vision'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.5 1C1.67114 1 1 1.67114 1 2.5V10.0505C1.63526 9.40223 2.52066 9 3.5 9C4.68289 9 5.72872 9.58681 6.36227 10.4852C6.83291 10.1768 7.39422 10 8 10C8.60578 10 9.16709 10.1768 9.63772 10.4852C10.2713 9.58681 11.3171 9 12.5 9C13.4793 9 14.3647 9.40223 15 10.0505V2.5C15 1.67114 14.3289 1 13.5 1H12.5C12.2239 1 12 0.776142 12 0.5C12 0.223858 12.2239 0 12.5 0H13.5C14.8811 0 16 1.11886 16 2.5V12.5C16 14.433 14.433 16 12.5 16C10.567 16 9 14.433 9 12.5C9 12.1103 9.06369 11.7355 9.18122 11.3854C8.8513 11.1415 8.4457 11 8 11C7.5543 11 7.1487 11.1415 6.81878 11.3854C6.93631 11.7355 7 12.1103 7 12.5C7 14.433 5.433 16 3.5 16C1.567 16 0 14.433 0 12.5V2.5C0 1.11886 1.11886 0 2.5 0H3.5C3.77614 0 4 0.223858 4 0.5C4 0.776142 3.77614 1 3.5 1H2.5ZM10.2233 11.4657C10.0799 11.7809 10 12.1311 10 12.5C10 13.8807 11.1193 15 12.5 15C13.8807 15 15 13.8807 15 12.5C15 11.1193 13.8807 10 12.5 10C11.499 10 10.6354 10.5883 10.2361 11.4381C10.2321 11.4474 10.2279 11.4566 10.2233 11.4657ZM3.5 10C4.50101 10 5.36461 10.5883 5.76389 11.438C5.76787 11.4474 5.77214 11.4566 5.7767 11.4657C5.92012 11.7809 6 12.1311 6 12.5C6 13.8807 4.88071 15 3.5 15C2.11929 15 1 13.8807 1 12.5C1 11.1193 2.11929 10 3.5 10Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Vision'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.5C1 1.67114 1.67114 1 2.5 1H3.5C3.77614 1 4 0.776142 4 0.5C4 0.223858 3.77614 0 3.5 0H2.5C1.11886 0 0 1.11886 0 2.5V8.5C0 9.88071 1.11929 11 2.5 11C3.82722 11 4.91289 9.96575 4.99501 8.65924C5.20733 8.30391 5.57565 8 5.99999 8C6.42433 8 6.79267 8.30391 7.00499 8.65924C7.08711 9.96575 8.17278 11 9.5 11C10.8807 11 12 9.88071 12 8.5V2.5C12 1.11886 10.8811 0 9.5 0H8.5C8.22386 0 8 0.223858 8 0.5C8 0.776142 8.22386 1 8.5 1H9.5C10.3289 1 11 1.67114 11 2.5V6.49982C10.5822 6.18597 10.0628 6 9.5 6C8.50049 6 7.63798 6.58655 7.23791 7.43422C6.88065 7.16598 6.45072 7 5.99999 7C5.54943 7 5.1194 7.16594 4.76207 7.43417C4.36198 6.58653 3.49949 6 2.5 6C1.9372 6 1.41783 6.18597 1 6.49982V2.5ZM1 8.5C1 9.32843 1.67157 10 2.5 10C3.32843 10 4 9.32843 4 8.5C4 7.67157 3.32843 7 2.5 7C1.67157 7 1 7.67157 1 8.5ZM9.5 10C10.3284 10 11 9.32843 11 8.5C11 7.67157 10.3284 7 9.5 7C8.67157 7 8 7.67157 8 8.5C8 9.32843 8.67157 10 9.5 10Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Vision.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Vision;
