import styled from 'styled-components';
import StackingContext from '../../../commonResources/stackingContext';
import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';

export default styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${MASTHEAD_HEIGHT}px;
  ${StackingContext.masthead};
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.boxShadow && `box-shadow: 0 1px 0 0 #C3C5CD`};
  display: flex;

  .masthead-logo {
    /* To be removed when masthead height changes. */
    padding-bottom: 0px;
    margin-right: ${(props) => (props.currentProductName ? 0 : 'auto')};
  }
`;
