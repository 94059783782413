import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StoplightTag from '../../../Atoms/StoplightTag';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const FirstLine = styled.div`
  margin-top: 7px;
`;

const StoplightTagCell = ({ data = {}, cellAlign = 'left' }) => {
  const { secondLine = null, ...restOfData } = data;
  return (
    <>
      <FirstLine>
        <StoplightTag {...restOfData} />
      </FirstLine>
      {secondLine && (
        <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
      )}
    </>
  );
};

StoplightTagCell.propTypes = {
  data: PropTypes.shape({
    domID: PropTypes.string,
    bad: PropTypes.bool,
    nonCompliant: PropTypes.bool,
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default StoplightTagCell;
