import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ToggleWrapper from './components/ToggleWrapper';
import Button from './components/Button';
import ToggleButton from './components/ToggleButton';
import ToggleGraphic from './components/ToggleGraphic';
import InputLabel from '../Input/components/InputLabel';
import Wrapper from './components/Wrapper';
import { VisuallyHiddenText } from '../../commonResources/styles/a11y';

export const Toggle = ({
  altTextForCenterToggle = '',
  disabled = false,
  labelOnly = false,
  domID = '',
  dataTestId = '',
  initialValue = false,
  label = '',
  leftLabel = '',
  onToggle = () => false,
  onClick = () => false,
  rightLabel = '',
}) => {
  const [isToggled, setIsToggled] = useState(initialValue);
  const [isGraphicActive, setIsGraphicActive] = useState(false);
  useEffect(() => setIsToggled(initialValue), [initialValue]);

  const internalOnToggle = (e, forceState) => {
    if (disabled) {
      return;
    }
    e.persist();
    const toggleValue = forceState !== undefined ? forceState : !isToggled;
    setIsToggled(toggleValue);
    onToggle(e, { isToggled: toggleValue, isGraphicActive: toggleValue });
    onClick(e, { isToggled: toggleValue, isGraphicActive: toggleValue });
  };

  const classes = [
    `${labelOnly ? 'label-only' : ''}`,
    `${disabled ? 'disabled' : ''}`,
  ];

  return (
    <Wrapper data-testid={dataTestId} className={disabled ? 'disabled' : null}>
      {label ? <InputLabel htmlFor={domID}>{label}</InputLabel> : null}
      <ToggleWrapper
        id={domID}
        isGraphicActive={isGraphicActive}
        className={classes.join(' ')}
      >
        {leftLabel ? (
          <Button
            onClick={(e) => internalOnToggle(e, false)}
            className={[
              isToggled ? 'inactive' : 'active',
              disabled && 'disabled',
            ]}
          >
            {leftLabel}
          </Button>
        ) : null}
        <ToggleButton
          onClick={(e) => internalOnToggle(e)}
          onMouseEnter={() => setIsGraphicActive(true)}
          onMouseLeave={() => setIsGraphicActive(false)}
          onFocus={() => setIsGraphicActive(true)}
          onBlur={() => setIsGraphicActive(false)}
        >
          <VisuallyHiddenText>{altTextForCenterToggle}</VisuallyHiddenText>
          <ToggleGraphic
            data-testid={dataTestId ? `${dataTestId}-toggleGraphic` : null}
            className={isToggled ? 'toggle-right' : 'toggle-left'}
            disabled={disabled}
          />
        </ToggleButton>
        {rightLabel ? (
          <Button
            onClick={(e) => internalOnToggle(e, true)}
            className={[
              isToggled ? 'active' : 'inactive',
              disabled && 'disabled',
            ]}
          >
            {rightLabel}
          </Button>
        ) : null}
      </ToggleWrapper>
    </Wrapper>
  );
};

Toggle.propTypes = {
  /** alt/screenreader text for the centered toggle graphic */
  altTextForCenterToggle: PropTypes.string,
  /** disables the toggle component */
  disabled: PropTypes.bool,
  domID: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** the initial value of the toggle component/input */
  initialValue: PropTypes.bool,
  /** the main label describing the purpose of the toggle input */
  label: PropTypes.string,
  /** label which describes the output value when toggled to the left */
  leftLabel: PropTypes.node,
  /** fires when toggle value changes
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Boolean} state.isGraphicActive
   * @prop {Boolean} state.isToggled
   */
  onToggle: PropTypes.func,
  /** fires when toggle value changes
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Boolean} state.isGraphicActive
   * @prop {Boolean} state.isToggled
   */
  onClick: PropTypes.func,
  /** label which describes the output value when toggled to the right */
  rightLabel: PropTypes.node,
  labelOnly: PropTypes.bool,
};

export default Toggle;
