import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.div`
  display: flex;
  align-items: center;
  ${TextStyles.small};
  margin: 20px 24px;

  .user-menu-header-avatar {
    margin-right: 12px;
  }
`;
