import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

// Components
import GridHeader from './components/GridHeader';
import GridRow from './components/GridRow';
import EmptyState from './components/EmptyState';
import SettingsTakeover from '../SettingsTakeover';
import StyleWrapper from './styles/Grid';

import * as helpers from '../../commonResources/helpers/gridHelpers';

const SORT_ASCENDING = 'SORT_ASCENDING';
const SORT_DESCENDING = 'SORT_DESCENDING';

export const noColumnsSelectedMessage = `You don't have any columns selected. Turn columns back on to view your data.`;

export const Grid = (props) => {
  const {
    domID = null,
    className = '',
    dataTestId = null,
    defaultColumnsDataNames = null,
    mandatoryColumnsDataNames = null,
    resetColumnsDataNames = null,
    initialSelectedItemIds = null,
    initialDisabledItemIds = null,
    sortingComparator = null,
    selectAllCheckboxDomID = 'select-all-checkbox',
    settingsTakeoverOnRestoreDefaults = () => false,
    settingsTakeoverOnModalToggle = () => false,
    settingsTakeoverOnSave = () => false,
    isFixedHeader = false,
    maxHeight = '400px',
    rowComponent = null,
    headerComponent = null,
    records: propRecords = null,
    columns = null,
    onSortGridColumn = () => false,
    onSelectAll = () => false,
    onRowSelect = () => false,
    isConfigurable = false,
    supportSelection = false,
    supportRadioSelection = false,
    initialRadioSelection = null,
    onRadioSelect = () => false,
    selectionKey = null,
    initialSortingKey = '',
    focusedItem = null,
    emptyGridMessage = '',
  } = props;

  // Memoize records
  const records = useMemo(
    () =>
      propRecords.map((r, i) => {
        return r.id ? r : { ...r, id: `record${i}` };
      }),
    [propRecords],
  );

  const disabledColumnsForState = new Set([]);
  if (defaultColumnsDataNames) {
    const tempDisabledColumns = new Set(columns);
    tempDisabledColumns.forEach((column) => {
      disabledColumnsForState.add(column.dataName);
    });
    tempDisabledColumns.forEach((column) => {
      if (
        (mandatoryColumnsDataNames &&
          mandatoryColumnsDataNames.has(column.dataName)) ||
        defaultColumnsDataNames.has(column.dataName)
      ) {
        disabledColumnsForState.delete(column.dataName);
      }
    });
  }

  const [sortingKey, setSortingKey] = useState(initialSortingKey);
  useEffect(() => setSortingKey(initialSortingKey), [initialSortingKey]);
  const [disabledColumns, setDisabledColumns] = useState(
    disabledColumnsForState,
  );
  const [isSettingsModalShown, setIsSettingsModalShown] = useState(false);

  // Set initially selected rows
  const [selectedItemIds, setSelectedItemIds] = useState(
    initialSelectedItemIds || new Set([]),
  );
  useEffect(() => {
    if (initialSelectedItemIds !== null)
      setSelectedItemIds(initialSelectedItemIds);
  }, [initialSelectedItemIds]);

  const [selectedRadioItem, setSelectedRadioItem] = useState(null);
  // set initially selected radio item
  useEffect(() => {
    setSelectedRadioItem(initialRadioSelection);
  }, [initialRadioSelection]);

  const onToggleRadioSelect = (e, item) => {
    setSelectedRadioItem(item);
    onRadioSelect(e, item);
  };

  // Set initially disabled rows
  const [disabledItemIds, setDisabledItemIds] = useState(
    initialDisabledItemIds || new Set([]),
  );
  useEffect(() => {
    if (initialDisabledItemIds !== null)
      setDisabledItemIds(initialDisabledItemIds);
  }, [initialDisabledItemIds]);

  const wrapperRef = useRef(null);
  useEffect(() => {
    const currentWrapperRef = wrapperRef.current;
    // https://stackoverflow.com/questions/673153/html-table-with-fixed-headers
    const fixedHeaderListener = () => {
      const translate = `translate(0, ${currentWrapperRef.scrollTop}px)`;
      const allTh = currentWrapperRef.querySelectorAll('thead');
      for (let i = 0; i < allTh.length; i += 1) {
        allTh[i].style.transform = translate;
      }
    };

    if (isFixedHeader) {
      currentWrapperRef.addEventListener('scroll', fixedHeaderListener);
    }

    return () =>
      currentWrapperRef.removeEventListener('scroll', fixedHeaderListener);
  }, [isFixedHeader]);

  const onToggleSettingsModal = (e) => {
    e.stopPropagation();
    setIsSettingsModalShown(!isSettingsModalShown);
    settingsTakeoverOnModalToggle(e);
  };

  const onColumnSettingsChange = (e, tempDisabledColumns) => {
    setDisabledColumns(tempDisabledColumns);
    settingsTakeoverOnSave(e, {
      sortingKey,
      disabledColumns: tempDisabledColumns,
      isSettingsModalShown,
      selectedItemIds,
    });
    onToggleSettingsModal(e);
  };

  const handleSortGridColumn = (dataName, e) => {
    e.persist();
    let direction = SORT_DESCENDING;
    const [oldDataName, oldDirection] = sortingKey.split('|');
    if (dataName === oldDataName) {
      direction =
        oldDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING;
    }

    setSortingKey(`${dataName}|${direction}`);
    onSortGridColumn(e, {
      sortingKey: `${dataName}|${direction}`,
      disabledColumns,
      isSettingsModalShown,
      selectedItemIds,
    });
  };

  const onToggleRowSelect = (e, dataName) => {
    const operation = selectedItemIds.has(dataName) ? 'delete' : 'add';
    const newSet = new Set(selectedItemIds);
    newSet[operation](dataName);

    setSelectedItemIds(newSet);
    onRowSelect(e, {
      sortingKey,
      disabledColumns,
      isSettingsModalShown,
      selectedItemIds: newSet,
    });
  };

  const [disabledSelectedItems, setDisabledSelectedItems] = useState(
    helpers.getDisabledSelectedItems(
      initialSelectedItemIds,
      initialDisabledItemIds,
    ),
  );

  useEffect(() => {
    setDisabledSelectedItems(
      helpers.getDisabledSelectedItems(
        initialSelectedItemIds,
        initialDisabledItemIds,
      ),
    );
  }, [initialSelectedItemIds, initialDisabledItemIds]);

  const areAllRecordsChecked = helpers.areAllRecordsChecked(
    records.length,
    disabledItemIds.size,
    selectedItemIds.size,
    disabledSelectedItems.size,
  );

  const onToggleSelectAll = (e) => {
    const isSelectAllEnabled = areAllRecordsChecked;
    const ids = isSelectAllEnabled
      ? [...disabledSelectedItems]
      : helpers.getIdsForSelectAll(
          records,
          disabledItemIds,
          disabledSelectedItems,
          selectionKey,
        );

    setSelectedItemIds(new Set(ids));
    onSelectAll(e, {
      sortingKey,
      disabledColumns,
      isSettingsModalShown,
      selectedItemIds: new Set(ids),
    });
  };

  const naiveSort = (recordsToSort) => {
    // Sort the records based on the details from the sort column
    const [sortDataName, sortDirectionString] = sortingKey.split('|');
    const sortDirection = sortDirectionString === SORT_ASCENDING ? 1 : -1;
    const sortedRecords =
      sortDataName && sortDirectionString
        ? recordsToSort.sort((a, b) => {
            return a[sortDataName] > b[sortDataName]
              ? sortDirection
              : sortDirection * -1;
          })
        : recordsToSort;

    return sortedRecords;
  };

  const RowElement = rowComponent || GridRow;
  const HeaderElement = headerComponent || GridHeader;
  const [sortDataName, sortDirectionString] = sortingKey.split('|');
  const sortDirection = sortDirectionString === SORT_ASCENDING ? 1 : -1;
  const sortedRecords = sortingComparator
    ? sortingComparator(sortDataName, sortDirectionString, records)
    : naiveSort(records);

  const domIDs = {
    settingsTakeover: domID && `${domID}-settingsTakeover`,
    wrapper: domID && `grid-wrapper-${domID}`,
  };

  const testIDs = {
    header: dataTestId && `${dataTestId}-header`,
    emptyState: dataTestId && `${dataTestId}-emptyState`,
    settingsTakeover: dataTestId && `${dataTestId}-settingsTakeover`,
  };

  const wrapperClasses = isFixedHeader
    ? `${className} fixed-header`
    : className;

  const noColumnsSelected = disabledColumns.size === columns.size;
  return (
    <StyleWrapper
      id={domIDs.wrapper}
      ref={wrapperRef}
      className={wrapperClasses}
      maxHeight={maxHeight}
    >
      <table
        id={domID}
        data-testid={dataTestId}
        className={isConfigurable ? 'configurable' : ''}
      >
        <HeaderElement
          dataTestId={testIDs.header}
          columns={columns}
          disabledColumns={disabledColumns}
          isSelectAllIndeterminate={
            !areAllRecordsChecked && !!selectedItemIds.size
          }
          onSelectAll={(e) => onToggleSelectAll(e)}
          isSelectAllEnabled={areAllRecordsChecked}
          onSortGridColumn={(key, e) => handleSortGridColumn(key, e)}
          isConfigurable={isConfigurable}
          supportSelection={!supportRadioSelection && supportSelection}
          supportRadioSelection={supportRadioSelection}
          sortingDataName={sortDataName}
          sortingDirection={sortDirection}
          onToggleSettingsModal={(e) => onToggleSettingsModal(e)}
          selectAllCheckboxDomID={selectAllCheckboxDomID}
          focusedItem={focusedItem}
        />
        <tbody>
          {noColumnsSelected ? (
            <EmptyState
              dataTestId={testIDs.emptyState}
              columns={columns}
              supportSelection={supportSelection || supportRadioSelection}
              disabledColumns={disabledColumns}
              emptyGridMessage={noColumnsSelectedMessage}
            />
          ) : (
            <>
              {sortedRecords.length > 0 ? (
                sortedRecords.map((r, i) => {
                  const isFocused = focusedItem
                    ? focusedItem[selectionKey] === r[selectionKey]
                    : false;
                  const classes = [];
                  if (r.emboldened) classes.push('emboldened');
                  if (supportSelection || supportRadioSelection)
                    classes.push('selectable');
                  // todo check if we need to remove highlights from checked items when
                  // focused item is passed.
                  if (isFocused) {
                    classes.push('selected');
                  }
                  if (
                    (selectedItemIds.has(r[selectionKey]) ||
                      selectedRadioItem === r[selectionKey]) &&
                    (!focusedItem || focusedItem === null)
                  ) {
                    classes.push('selected');
                  }

                  return (
                    <RowElement
                      {...props}
                      dataTestId={
                        dataTestId
                          ? `${dataTestId}-row-${r[selectionKey]}`
                          : `row-${r[selectionKey]}`
                      }
                      className={classes.join(' ')}
                      disabledColumns={disabledColumns}
                      record={r}
                      key={r.id}
                      rowIndex={i}
                      onRowSelect={(e) => onToggleRowSelect(e, r[selectionKey])}
                      isSelected={selectedItemIds.has(r[selectionKey])}
                      isDisabled={disabledItemIds.has(r[selectionKey])}
                      isFocused={isFocused}
                      supportSelection={
                        !supportRadioSelection && supportSelection
                      }
                      onRadioSelect={(e) =>
                        onToggleRadioSelect(e, r[selectionKey])
                      }
                      isRadioChecked={r[selectionKey] === selectedRadioItem}
                    />
                  );
                })
              ) : (
                <EmptyState
                  dataTestId={testIDs.emptyState}
                  columns={columns}
                  supportSelection={supportSelection || supportRadioSelection}
                  disabledColumns={disabledColumns}
                  emptyGridMessage={emptyGridMessage}
                />
              )}
            </>
          )}
        </tbody>
      </table>
      <SettingsTakeover
        domID={domIDs.settingsTakeover}
        dataTestId={testIDs.settingsTakeover}
        isOpen={isSettingsModalShown}
        onModalToggle={(e) => onToggleSettingsModal(e)}
        columns={columns}
        defaultColumns={defaultColumnsDataNames}
        mandatoryColumns={mandatoryColumnsDataNames}
        resetColumnsDataNames={resetColumnsDataNames}
        disabledColumns={disabledColumns}
        onSave={(e, state) => {
          onColumnSettingsChange(e, state);
        }}
        onCancel={(e) => {
          onToggleSettingsModal(e);
        }}
        onRestoreDefaults={(e) => settingsTakeoverOnRestoreDefaults(e)}
      />
    </StyleWrapper>
  );
};

Grid.propTypes = {
  /** domID for grid container. Use unique ones to avoid bugs when rendering multiple grids. */
  domID: PropTypes.string,
  /** identifier for automated testing */
  dataTestId: PropTypes.string,
  /** className for grid style wrapper */
  className: PropTypes.string,
  /**
   * Set of objects, each like this:
   * { dataName: string, text: string, sortable: boolean, isSorted: 0|1 }
   */
  columns: PropTypes.instanceOf(Set).isRequired,
  /** Array of objects, each has properties whose keys match the dataNames in the columns prop. Each should also have a locally unique id property. ids will be generated if not provided. */
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Property of records to select them by. */
  selectionKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** Set of dataName strings for columns to show by default. All shown if not passed. */
  defaultColumnsDataNames: PropTypes.instanceOf(Set),
  /** Set of dataName strings for mandatory columns. Mandatory columns can't be configure from settins. */
  mandatoryColumnsDataNames: PropTypes.instanceOf(Set),
  /** Set of dataName strings for columns to reset to when user selects "Restore Defaults" in Settings modal */
  resetColumnsDataNames: PropTypes.instanceOf(Set),
  /**
   * When used in workflow, this prop takes the item that is currently open in detail pane.
   * This is used to highlight the row in the main grid.
   */
  focusedItem: PropTypes.oneOfType([PropTypes.object]),
  /** Callback to run when the select all checkbox is clicked.
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.sortingKey
   * @prop {Boolean} state.isSettingsModalShown
   * @prop {Set} state.selectedItemIds
   * @prop {Set} state.disabledColumns
   */
  onSelectAll: PropTypes.func,
  /** Callback to run when the select checkbox for a row is clicked. Receives:
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.sortingKey
   * @prop {Boolean} state.isSettingsModalShown
   * @prop {Set} state.selectedItemIds
   * @prop {Set} state.disabledColumns
   */
  onRowSelect: PropTypes.func,
  /** A set of record keys (based on value of `selectionKey`, ex. id) for which the checkbox should be selected */
  initialSelectedItemIds: PropTypes.instanceOf(Set),
  /** A set of record keys (based on value of `selectionKey`, ex. id) for which the checkbox should be disabled */
  initialDisabledItemIds: PropTypes.instanceOf(Set),
  /** Callback to run when a column is sorted. Receives:
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.sortingKey
   * @prop {Boolean} state.isSettingsModalShown
   * @prop {Set} state.selectedItemIds
   * @prop {Set} state.disabledColumns
   */
  onSortGridColumn: PropTypes.func,
  /** Replaces default sorting function. See Sorting story. Receives:
   * @param {String} columnDataName
   * @param {String} sortDirection ('SORT_ASCENDING' | 'SORT_DESCENDING')
   * @param {Array} records
   */
  sortingComparator: PropTypes.func,
  /** How to sort grid initially, in the form of 'dataName|direction'.
   * - direction is 'SORT_ASCENDING' or 'SORT_DESCENDING' */
  initialSortingKey: PropTypes.string,
  /** domID for the select all checkbox. Use unique ones to avoid bugs when rendering multiple grids. */
  selectAllCheckboxDomID: PropTypes.string,
  /** Callback to run when restoring defaults in config modal, receives event. */
  settingsTakeoverOnRestoreDefaults: PropTypes.func,
  /** Callback to run when config modal is toggled, receives event. */
  settingsTakeoverOnModalToggle: PropTypes.func,
  /** Callback to run when config is saved. Receives:
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.sortingKey
   * @prop {Boolean} state.isSettingsModalShown
   * @prop {Set} state.selectedItemIds
   * @prop {Set} state.disabledColumns
   */
  settingsTakeoverOnSave: PropTypes.func,
  /** If true, header does not scroll with body, but stays fixed at the top. */
  isFixedHeader: PropTypes.bool,
  /** Sets maximum height of grid container if using isFixedHeader */
  maxHeight: PropTypes.string,
  /** Replaces the built in GridRow component. See GridRow source. */
  rowComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Replaces the built in GridHeader component. See GridHeader source. */
  headerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** If true, shows configure button and modal to select displayed columns. */
  isConfigurable: PropTypes.bool,
  /** If true, checkbox column exists. */
  supportSelection: PropTypes.bool,
  /** Replaces default empty grid text. */
  emptyGridMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Can be used when you need to show radio button at each row. Do not pass supportSelection when this is passed to true */
  supportRadioSelection: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialRadioSelection: PropTypes.any,
  /** Action that gets fired when radio item is selected */
  onRadioSelect: PropTypes.func,
  /** Name of the radio input when supportRadioSelection is passed. This is useful when you need multiple grids that support radioSelection. */
  radioName: PropTypes.func,
};

export { GridRow, GridHeader };
export default Grid;
