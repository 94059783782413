import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextWrapper from './styleWrappers/TextWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';
import generateUUID from '../../../commonResources/helpers/generateUUID';

const FirstLine = styled(TextWrapper)`
  margin-top: 10px;
  display: inline-flex;
  > svg:not(:last-child) {
    margin-right: 12px;
  }
`;

const StatusCell = ({
  data: { icons = [], secondLine = null },
  cellAlign = 'left',
}) => (
  <>
    <FirstLine cellAlign={cellAlign}>
      {icons.map(({ icon: Icon, ...iconProps }) => (
        <Icon key={generateUUID()} size="medium" {...iconProps} />
      ))}
    </FirstLine>
    {secondLine ? <SecondLineWrapper>{secondLine}</SecondLineWrapper> : null}
  </>
);

StatusCell.propTypes = {
  data: PropTypes.shape({
    /** icons is an array of objects, each one with an icon plus the props to be passed to that icon */
    icons: PropTypes.arrayOf(
      PropTypes.shape({
        /** These should be ui-core icon components, passed as `IconName`, not `<IconName/>` */
        icon: PropTypes.func.isRequired,
        domID: PropTypes.string,
        className: PropTypes.string,
        dataTestId: PropTypes.string,
        fillColor: PropTypes.string,
        title: PropTypes.string,
        size: PropTypes.string,
      }),
    ),
    /** optional, displayed below icons */
    secondLine: PropTypes.string,
  }),
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default StatusCell;
