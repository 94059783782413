import React from 'react';
import PropTypes from 'prop-types';

import CheckboxRadioBase from '../CheckboxRadioBase';

const Radio = (props) => <CheckboxRadioBase {...props} isRadio />;

Radio.propTypes = {
  /** a11y compatiblity - id of element that serves purpose of label */
  ariaLabelledBy: PropTypes.string,
  /** a11y compatiblity - label text */
  dataTestId: PropTypes.string,
  /** whether this Radio is checked on render or not */
  checked: PropTypes.bool,
  /** whether this Radio is rendered disabled */
  disabled: PropTypes.bool,
  /** label of Radio */
  label: PropTypes.string,
  /** size of Radio label and just to be super clear, not radio itself! */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** pass a function to handle change event */
  onChange: PropTypes.func,
};

export default Radio;
