import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from './components/PageTitle';

export const DetailpageTitle = ({
  domID = null,
  title = '',
  dataTestId = '',
  headerTag = 'h1',
}) => (
  <PageTitle as={headerTag} id={domID} data-testid={dataTestId}>
    {title}
  </PageTitle>
);

DetailpageTitle.propTypes = {
  /**
   *  id attribute for header tag.(h1..h6)
   *
   *  The id attribute is used to point to a specific style declaration in a style sheet.
   *  It is also used by JavaScript to access and manipulate the element with the specific id.
   *
   */
  domID: PropTypes.string,
  /**
   * This component internally renders header(h1, h2, h3, h4, h5, h6) tag.
   *
   * Change the default tag of h1 using this prop. Note that the font-size will not change based on this.
   */
  headerTag: PropTypes.string,
  /**
   * Content of the header tag (h1..h6)
   */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  /**
   * data-testid attribute to attached to the header.
   *
   * Used for automated testing.
   */
  dataTestId: PropTypes.string,
};

export default DetailpageTitle;
