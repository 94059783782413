import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Paperclip = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Paperclip'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={fillColor}
            strokeMiterlimit="10"
            // eslint-disable-next-line max-len
            d="M21.9375 11.4375L13.1375 20.2375C10.6375 22.7375 6.4375 22.7375 3.9375 20.2375C1.4375 17.7375 1.4375 13.5375 3.9375 11.0375L11.7375 3.23751C13.5375 1.43751 16.3375 1.43751 18.1375 3.23751C19.9375 5.03751 19.9375 7.83751 18.1375 9.63751L10.9375 16.7375C9.9375 17.7375 8.3375 17.7375 7.4375 16.7375C6.4375 15.7375 6.4375 14.1375 7.4375 13.2375L13.4375 7.23751"
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Paperclip'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={fillColor}
            strokeMiterlimit="10"
            // eslint-disable-next-line max-len
            d="M8.85674 5.35736L5.85174 8.36236C5.16874 9.04536 5.16874 10.1534 5.85174 10.8374C6.53474 11.5204 7.64274 11.5204 8.32674 10.8374L12.3927 6.77136C13.7597 5.40436 13.7597 3.18836 12.3927 1.82136C11.0257 0.454362 8.80974 0.454362 7.44274 1.82136L3.02374 6.24136C0.973737 8.29136 0.973737 11.6154 3.02374 13.6664C5.07374 15.7164 8.39774 15.7164 10.4487 13.6664L14.5137 9.60036"
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Paperclip'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            // eslint-disable-next-line max-len
            d="M6.65645 3.98011L4.36245 6.27511C4.23833 6.39909 4.13987 6.54633 4.07269 6.70839C4.00551 6.87046 3.97093 7.04417 3.97093 7.21961C3.97093 7.39505 4.00551 7.56876 4.07269 7.73083C4.13987 7.89289 4.23833 8.04012 4.36245 8.16411V8.16411C4.48644 8.28823 4.63367 8.38669 4.79573 8.45387C4.9578 8.52106 5.13152 8.55563 5.30695 8.55563C5.48239 8.55563 5.6561 8.52106 5.81817 8.45387C5.98023 8.38669 6.12747 8.28823 6.25145 8.16411L9.35145 5.06411C9.85237 4.56287 10.1338 3.88324 10.1338 3.17461C10.1338 2.46598 9.85237 1.78635 9.35145 1.28511C9.1034 1.03684 8.80884 0.839887 8.48463 0.705512C8.16042 0.571137 7.81291 0.501973 7.46195 0.501973C7.111 0.501973 6.76348 0.571137 6.43927 0.705512C6.11506 0.839887 5.82051 1.03684 5.57245 1.28511L2.20245 4.65511C1.4508 5.40692 1.02853 6.4265 1.02853 7.48961C1.02853 8.55272 1.4508 9.5723 2.20245 10.3241V10.3241C2.95426 11.0758 3.97384 11.498 5.03695 11.498C6.10006 11.498 7.11964 11.0758 7.87145 10.3241L10.9715 7.22411"
            stroke={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Paperclip.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Paperclip;
