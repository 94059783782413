// requests

import { baseURL, linksEndpoint, searchEndpoint } from './constants';
import { requestHeader } from './headers';
import { testDataLinks, testDataSearch } from '../stubs/data';

export async function fetchLinkData(applicationId, authToken) {
  if (!applicationId || !authToken) return
  if (applicationId === "TEST") {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => { resolve(testDataLinks) }, 1);
    });

    let result = await promise;
    return result;
  }

  const response = await window.fetch(`${baseURL}/${linksEndpoint(applicationId)}`, {
    headers: requestHeader(applicationId, authToken)
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export async function fetchSearchResults(applicationId, authToken, query) {
  if (!applicationId || !authToken) return
  if (query) {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => { resolve(testDataSearch) }, 1);
    });

    let result = await promise;
    return result;
  }

  try {
    const response = await window.fetch(`${baseURL}/${searchEndpoint(applicationId)}/search?source=lithium&q=${query}`, {
      headers: requestHeader(applicationId, authToken)
    });
    return await response.json();
  } catch (e) {
    console.error('error: ', e);
  }
}
