import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MastheadAvatar from '../Avatar';
import { useClickAway } from '../../../../commonResources/hooks/useClickAway';
import { useIsTruncated } from '../../../../commonResources/hooks/useIsTruncated';

import StandaloneLoginLink from '../StandaloneLoginLink';
import Wrapper from './styles/Wrapper';
import MenuContainer from './styles/MenuContainer';
import ProfilePortal from '../../../ProfilePortal';
import UserDropdownHeader from './styles/UserDropdownHeader';
import UserDropdownTitle from './styles/UserDropdownTitle';
import UserName from './styles/UserName';
import UserEmail from './styles/UserEmail';
import Tooltip from '../../../../Atoms/Tooltip/tooltip';
import StandardMenu from '../../../../Molecules/StandardMenu';
import Avatar from '../../../../BrandCore/Avatar';
import { LogOut } from '../../../../BrandCore/icons/interactive';
import MenuFooter from '../../../../Atoms/MenuFooter';

export const UserMenu = ({
  dataTestId = '',
  menuItems = [],
  selectedUserMenuItems = [],
  customMenuItems = [],
  avatarImage = null,
  integrations = {},
  userEmail = '',
  userFirstName = '',
  userLastName = '',
  onLogOutSelect = () => false,
  onMenuItemClick = () => false,
  onProfileSelect = () => false,
  onProfileSave = () => false,
  initialIsMenuOpen = false,
  initialIsProfileOpen = false,
  // controlling menu visibility state
  onOpen = () => false,
  onOpenClose = () => false,
  onClose = () => false,
  isStandaloneLogin = false,
  onStandaloneLoginSelect = () => false,
  customTheme = {},
}) => {
  const ref = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(initialIsMenuOpen);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState();
  const [activeSectionIndex, setActiveSectionIndex] = useState();
  const [isProfileOpen, setIsProfileOpen] = useState(initialIsProfileOpen);
  const [userName, setUserName] = useState(`${userFirstName} ${userLastName}`);
  const [, setProfileData] = useState();

  const toggleMenu = (event, forceState) => {
    const menuWasOpen = isMenuOpen;
    const menuIsNowOpen = forceState !== undefined ? forceState : !isMenuOpen;

    setIsMenuOpen(menuIsNowOpen);
    if (menuIsNowOpen) {
      onOpen(event, isMenuOpen);
      onOpenClose(event, isMenuOpen);
    } else if (!menuIsNowOpen && menuWasOpen) {
      onClose(event, isMenuOpen);
      onOpenClose(event, isMenuOpen);
    }
  };

  useEffect(() => {
    if (initialIsProfileOpen) setIsProfileOpen(initialIsProfileOpen);
    if (initialIsMenuOpen) setIsMenuOpen(initialIsMenuOpen);
    !!userFirstName || !!userLastName
      ? setUserName(`${userFirstName} ${userLastName}`)
      : setUserName('');
  }, [initialIsProfileOpen, initialIsMenuOpen, userFirstName, userLastName]);

  const isNameTruncated = useIsTruncated(nameRef, [userName, isMenuOpen]);
  const isEmailTruncated = useIsTruncated(emailRef, [userEmail, isMenuOpen]);
  useClickAway(ref, () => setIsMenuOpen(false));

  /**
   * fires appropriate action depending on which item is clicked and if CIAM integration is complete
   * @param {NativeEvent} event
   * @param {object} dropdownState contains activeItem, activeMenuItemIndex, and activeSectionIndex
   */
  const handleMenuItemClick = (event, dropdownState) => {
    const {
      activeMenuItemIndex: dropdownActiveItemIndex,
      activeSectionIndex: dropdownActiveSectionIndex,
    } = dropdownState;
    const updatedState = {
      activeMenuItemIndex: dropdownActiveItemIndex,
      activeSectionIndex: dropdownActiveSectionIndex,
      activeItem: dropdownState.activeItem,
      isMenuOpen: false,
    };

    // performs prescribed actions
    if (integrations.ciam) {
      switch (dropdownState.activeItem.label) {
        case 'My Profile':
          // setIsProfileOpen(true); disabled CIAM integration services are set up
          onProfileSelect();
          break;
        default:
          onMenuItemClick(event, updatedState);
          break;
      }
    } else {
      switch (dropdownState.activeItem.label) {
        case 'My Profile':
          onProfileSelect();
          break;
        default:
          onMenuItemClick(event, updatedState);
          break;
      }
    }

    // updates state values
    setActiveMenuItemIndex(dropdownActiveItemIndex);
    setActiveSectionIndex(dropdownActiveSectionIndex);
    setIsMenuOpen(false);
  };

  const toggleProfile = (event, forceState) => {
    setIsProfileOpen(forceState.isProfileOpen);
  };

  const saveProfile = (event, forceState) => {
    setProfileData({ ...forceState.profileData });
    onProfileSave(event, { ...forceState.profileData });
  };

  return isStandaloneLogin && onStandaloneLoginSelect ? (
    <StandaloneLoginLink
      dataTestId="standalone-login-link"
      onStandaloneLoginSelect={onStandaloneLoginSelect}
    />
  ) : (
    <Wrapper ref={ref} role="navigation" aria-label="User Menu">
      <MastheadAvatar
        customTheme={customTheme}
        dataTestId="masthead-avatar"
        avatarToggle={isMenuOpen}
        avatarImg={avatarImage}
        onAvatarClick={(e) => toggleMenu(e, undefined)}
        disabled={!menuItems.length}
      />
      {isMenuOpen ? (
        <MenuContainer data-testid="user-menu-container">
          <StandardMenu
            headerComponent={
              (!!userName || !!userEmail) && (
                <UserDropdownHeader data-testid="user-menu-header">
                  <Avatar
                    variant="medium"
                    className="user-menu-header-avatar"
                  />
                  <UserDropdownTitle>
                    {userName ? (
                      <Tooltip
                        dataTestId={`${dataTestId}-username-tooltip`}
                        tooltipContent={userName}
                        tooltipPosition="top-center"
                        tooltipWidth={225}
                        hideTooltip={!isNameTruncated}
                        disablePointerEvents
                        isButtonRoleDisabled
                      >
                        <UserName data-testid="user-name" ref={nameRef}>
                          {userName}
                        </UserName>
                      </Tooltip>
                    ) : null}
                    <Tooltip
                      dataTestId={`${dataTestId}-email-tooltip`}
                      tooltipContent={userEmail}
                      tooltipPosition="top-center"
                      tooltipWidth={225}
                      hideTooltip={!isEmailTruncated}
                      disablePointerEvents
                      isButtonRoleDisabled
                    >
                      <UserEmail data-testid="user-email" ref={emailRef}>
                        {userEmail}
                      </UserEmail>
                    </Tooltip>
                  </UserDropdownTitle>
                </UserDropdownHeader>
              )
            }
            className="inline-user-dropdown"
            hideEmptyMessage
            menuItems={menuItems}
            selectedItems={selectedUserMenuItems}
            sections={
              customMenuItems.length ? [menuItems, ...customMenuItems] : []
            }
            onMenuClick={(e, state) => handleMenuItemClick(e, state)}
            activeMenuItemIndex={activeMenuItemIndex}
            activeSectionIndex={activeSectionIndex}
            maxWidth="289px"
            showActiveIndicator
            footerComponent={
              <MenuFooter
                label="Logout"
                icon={LogOut}
                dataTestId="user-menu-bottomAction"
                onClick={onLogOutSelect}
              />
            }
          />
        </MenuContainer>
      ) : null}
      {isProfileOpen ? (
        <ProfilePortal
          dataTestId="profile-portal"
          avatarImage={avatarImage}
          isOpen={isProfileOpen}
          onModalToggle={(e, forceState) => toggleProfile(e, forceState)}
          onProfileSave={(e, forceState) => saveProfile(e, forceState)}
          userEmail={userEmail}
          userFirstName={userFirstName}
          userLastName={userLastName}
        />
      ) : null}
    </Wrapper>
  );
};

UserMenu.propTypes = {
  dataTestId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  integrations: PropTypes.object,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userEmail: PropTypes.string,
  avatarImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // Controlling menu visibility state
  initialIsMenuOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpenClose: PropTypes.func,
  // Profile
  onProfileSelect: PropTypes.func,
  initialIsProfileOpen: PropTypes.bool,
  onProfileSave: PropTypes.func,
  // Click Actions
  onLogOutSelect: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  // Potential Menu Items
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
    }),
  ),
  selectedUserMenuItems: PropTypes.arrayOf(PropTypes.any),
  customMenuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
          }),
        ),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
          isSecondary: PropTypes.bool,
        }),
      ),
    ]),
  ),
  // Standalone Login
  isStandaloneLogin: PropTypes.bool,
  onStandaloneLoginSelect: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
};

export default UserMenu;
