import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DetailpageTitle from '../../Atoms/DetailpageTitle/DetailpageTitle';
import LayoutControl from '../../Atoms/LayoutControl';
import DetailpageCount from '../../Atoms/DetailpageCount';

import ListPaneHeaderWrapper from './components/ListPaneHeaderWrapper';
import TitleCountWrapper from './components/TitleCountWrapper';
import ListPaneHeaderLeft from './components/ListPaneHeaderLeft';
import ListPaneHeaderRight from './components/ListPaneHeaderRight';
import ChildrenWrapper from './components/ChildrenWrapper';
import ContainerDiv from './components/ContainerDiv';

const ListPaneHeader = ({
  domID = null,
  className = '',
  dataTestId = '',
  title = '',
  count = null,
  layoutControls = [],
  icon: Icon,
  children = null,
  headerTag = 'h1',
}) => {
  const theme = useTheme();
  const domIDs = {
    icon: domID && `${domID}-icon`,
    count: domID && `${domID}-count`,
    layoutControls: domID && `${domID}-layoutControl`,
    title: domID && `${domID}-title`,
  };

  const testIDs = {
    icon: dataTestId && `${dataTestId}-icon`,
    count: dataTestId && `${dataTestId}-count`,
    layoutControls: dataTestId && `${dataTestId}-layoutControl`,
    title: dataTestId && `${dataTestId}-title`,
  };

  return (
    <ContainerDiv>
      <ListPaneHeaderWrapper
        id={domID}
        data-testid={dataTestId}
        className={className}
        marginBottom={children ? 13 : 20}
      >
        <ListPaneHeaderLeft>
          <TitleCountWrapper>
            {Icon && (
              <Icon
                domID={domIDs.icon}
                dataTestId={testIDs.icon}
                size="medium"
                className="list-pane-header-icon"
                fillColor={theme.BaseColors.Primary['115']}
              />
            )}
            <DetailpageTitle
              domID={domIDs.title}
              dataTestId={testIDs.title}
              title={title}
              headerTag={headerTag}
            />
            {(count || count === 0) && (
              <DetailpageCount
                domID={domIDs.count}
                dataTestId={testIDs.count}
                count={count}
              />
            )}
          </TitleCountWrapper>
        </ListPaneHeaderLeft>
        <ListPaneHeaderRight data-testid={testIDs.layoutControls}>
          {layoutControls.map((layoutControl) => {
            return (
              <LayoutControl
                domID={
                  domID &&
                  `${domIDs.layoutControls}-${layoutControl.buttonType}`
                }
                className="layout-controls-button-wrapper"
                dataTestId={
                  dataTestId &&
                  `${testIDs.layoutControls}-${layoutControl.buttonType}`
                }
                key={`layout-control-${layoutControl.buttonType}`}
                buttonType={layoutControl.buttonType}
                tooltipPosition={layoutControl.buttonTooltipPosition}
                onClick={layoutControl.onButtonClick}
              />
            );
          })}
        </ListPaneHeaderRight>
      </ListPaneHeaderWrapper>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </ContainerDiv>
  );
};

ListPaneHeader.propTypes = {
  /**
   *  id attribute for wrapper div.  Components used internally get data-testid computed as:
   *  icon: domID + "-icon",
   *  count: domID + "-count",
   *  layoutControls: domID + "-layoutControl",
   *  title: domID + "-title",
   *
   *  The id attribute is used to point to a specific style declaration in a style sheet.
   *  It is also used by JavaScript to access and manipulate the element with the specific id.
   *
   */
  domID: PropTypes.string,
  className: PropTypes.string,
  /**
   * data-testid attribute for wrapper div. Components used internally get data-testid computed as:
   * icon: dataTestId + "-icon",
   * count: dataTestId + "-count",
   * layoutControls: dataTestId + "-layoutControl",
   * title: dataTestId + "-title",
   */
  dataTestId: PropTypes.string,
  /**
   *
   * Text for the header(h1) tag
   *  */
  title: PropTypes.string.isRequired,
  /** Content of a Count component that appears right of the title */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Array of Layout Control component (ex. the close button) config objects.
   *
   * Each object looks like:
   * @typedef {Object} layoutControl
   * @prop {String} layoutControl.buttonType
   * @prop {String} layoutControl.buttonTooltipPosition
   * @prop {Function} layoutControl.onButtonClick - receives the click event
   */
  layoutControls: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.string,
      buttonTooltipPosition: PropTypes.string,
      onButtonClick: PropTypes.func,
    }),
  ),
  /** Valid React element type. eg `<ListPaneHeader icon={CircleCheck} />` */
  icon: PropTypes.elementType,
  /**
   * Pass children for component. Basic use case is to pass ButtonGroup
   */
  children: PropTypes.element,
  /**
   * This component internally renders header(h1, h2, h3, h4, h5, h6) tag.
   *
   * Change the default tag of h1 using this prop. Note that the font-size will not change based on this.
   */
  headerTag: PropTypes.string,
};

export default ListPaneHeader;
