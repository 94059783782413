import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import IconButton from '../../Atoms/IconButton';
import Tooltip from '../../Atoms/Tooltip';
import Avatar from '../../BrandCore/Avatar/Avatar';

import NotificationWrapper from './components/NotificationWrapper';
import NotificationBody from './components/NotificationBody';
import {
  NotificationHeader,
  NotificationHeaderActions,
  NotificationHeaderLeft,
  NotificationHeaderRight,
} from './components/NotificationHeader';
import getElapsedTime from './utils/notificationDateTimeUtil';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';
import { Archive, CircleCheck } from '../../BrandCore/icons';

const Notification = ({
  title = '',
  description = '',
  icon: Icon = undefined,
  avatarSrc = undefined,
  dateTime = '',
  isRead = false,
  className = undefined,
  style = {},
  onClick = () => {},
  onArchiveClick = undefined,
  onMarkAsReadClick = undefined,
  dataTestId = '',
  domID = '',
  width = 368,
  uniqueIdentifier = 'id',
  ...otherProps
}) => {
  const theme = useTheme();
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const getIdnValue = () => {
    const validProps = {
      ...otherProps,
      title,
      description,
      dateTime,
      className,
    };
    return validProps[uniqueIdentifier];
  };

  const titleRef = useRef(null);
  const isTitleTruncated = useIsTruncated(titleRef, [title]);

  const dateTimeText = useMemo(() => getElapsedTime(dateTime), [dateTime]);

  const domIDs = useMemo(
    () => ({
      wrapper: domID && `${domID}-wrapper`,
      header: domID && `${domID}-header`,
      headerLeft: domID && `${domID}-header-left`,
      headerRight: domID && `${domID}-header-right`,
      iconContainer: domID && `${domID}-icon-container`,
      icon: domID && `${domID}-icon`,
      titleTooltip: domID && `${domID}-title-tooltip`,
      title: domID && `${domID}-title`,
      dateTime: domID && `${domID}-date-time`,
      headerActions: domID && `${domID}-header-actions`,
      markAsReadIcon: domID && `${domID}-mark-as-read-icon`,
      archiveIcon: domID && `${domID}-archive-icon`,
      markAsReadTooltip: domID && `${domID}-mark-as-read-tooltip`,
      archiveTooltip: domID && `${domID}-archive-tooltip`,
      body: domID && `${domID}-body`,
      description: domID && `${domID}-description`,
    }),
    [domID],
  );
  const dataTestIds = useMemo(
    () => ({
      wrapper: dataTestId && `${dataTestId}-wrapper`,
      header: dataTestId && `${dataTestId}-header`,
      headerLeft: dataTestId && `${dataTestId}-header-left`,
      headerRight: dataTestId && `${dataTestId}-header-right`,
      iconContainer: dataTestId && `${dataTestId}-icon-container`,
      icon: dataTestId && `${dataTestId}-icon`,
      titleTooltip: dataTestId && `${dataTestId}-title-tooltip`,
      title: dataTestId && `${dataTestId}-title`,
      dateTime: dataTestId && `${dataTestId}-date-time`,
      headerActions: dataTestId && `${dataTestId}-header-actions`,
      markAsReadIcon: dataTestId && `${dataTestId}-mark-as-read-icon`,
      archiveIcon: dataTestId && `${dataTestId}-archive-icon`,
      markAsReadTooltip: dataTestId && `${dataTestId}-mark-as-read-tooltip`,
      archiveTooltip: dataTestId && `${dataTestId}-archive-tooltip`,
      body: dataTestId && `${dataTestId}-body`,
      description: dataTestId && `${dataTestId}-description`,
    }),
    [dataTestId],
  );

  const onHeaderEntered = useCallback(() => {
    setIsHeaderHovered(true);
  }, [setIsHeaderHovered]);

  const onHeaderLeaved = useCallback(() => {
    setIsHeaderHovered(false);
  }, [setIsHeaderHovered]);

  const onClickHandler = () => {
    if (typeof onClick === 'function') {
      const idn = getIdnValue();
      onClick(idn);
    }
  };

  const onMarkAsReadClickHandler = (event) => {
    if (typeof event?.stopPropagation === 'function') {
      event.stopPropagation();
    }

    if (typeof onMarkAsReadClick === 'function') {
      const idn = getIdnValue();
      onMarkAsReadClick(idn);
    }
  };

  const onArchiveClickHandler = (event) => {
    if (typeof event?.stopPropagation === 'function') {
      event.stopPropagation();
    }

    if (typeof onArchiveClick === 'function') {
      const idn = getIdnValue();
      onArchiveClick(idn);
    }
  };

  return (
    <NotificationWrapper
      className={className}
      style={style}
      onClick={onClickHandler}
      width={width}
      onMouseEnter={onHeaderEntered}
      onMouseLeave={onHeaderLeaved}
      id={domIDs.wrapper}
      data-testid={dataTestIds.wrapper}
    >
      <NotificationHeader id={domIDs.header} data-testid={dataTestIds.header}>
        <NotificationHeaderLeft
          existsIcon={!!Icon}
          existsDatetime={!!dateTimeText}
          isRead={isRead}
          id={domIDs.headerLeft}
          data-testid={dataTestIds.headerLeft}
        >
          {(!!Icon || !!avatarSrc) && (
            <div
              className="icon"
              id={domIDs.iconContainer}
              data-testid={dataTestIds.iconContainer}
            >
              {Icon ? (
                <Icon
                  size="small"
                  fillColor={
                    isHeaderHovered && !isRead
                      ? theme.UIPalette.Colors.Content.Primary
                      : theme.UIPalette.Colors.Content.Secondary
                  }
                  domID={domIDs.icon}
                  dataTestId={dataTestIds.icon}
                />
              ) : (
                <Avatar variant="xxSmall" avatarImg={avatarSrc} />
              )}
            </div>
          )}
          <Tooltip
            tooltipContent={title}
            hideTooltip={!isTitleTruncated}
            isButtonRoleDisabled
            domID={domIDs.titleTooltip}
            dataTestId={dataTestIds.titleTooltip}
          >
            <div
              className="title"
              ref={titleRef}
              id={domIDs.title}
              data-testid={dataTestIds.title}
            >
              {title}
            </div>
          </Tooltip>
          {!!dateTimeText && (
            <div
              className="date-time"
              id={domIDs.dateTime}
              data-testid={dataTestIds.dateTime}
            >
              {dateTimeText}
            </div>
          )}
        </NotificationHeaderLeft>
        <NotificationHeaderRight
          id={domIDs.headerRight}
          data-testid={dataTestIds.headerRight}
        >
          <NotificationHeaderActions
            showActions={isHeaderHovered || showActions}
            id={domIDs.headerActions}
            data-testid={dataTestIds.headerActions}
          >
            {!isRead && (
              <IconButton
                className="action"
                size="medium"
                icon={CircleCheck}
                hoverColor={theme.UIPalette.Colors.Content.Primary}
                fillColor={theme.UIPalette.Colors.Content.Secondary}
                tooltipProps={{
                  text: 'Mark as read',
                  tooltipWidth: 110,
                  domID: domIDs.markAsReadTooltip,
                  dataTestId: dataTestIds.markAsReadTooltip,
                  isButtonRoleDisabled: true,
                }}
                onClick={onMarkAsReadClickHandler}
                id={domIDs.markAsReadIcon}
                dataTestId={dataTestIds.markAsReadIcon}
                onFocus={() => setShowActions(true)}
                onBlur={() => setShowActions(false)}
              />
            )}
            <IconButton
              className="action"
              size="medium"
              icon={Archive}
              hoverColor={theme.UIPalette.Colors.Content.Primary}
              fillColor={theme.UIPalette.Colors.Content.Secondary}
              tooltipProps={{
                text: 'Archive',
                tooltipWidth: 75,
                domID: domIDs.archiveTooltip,
                dataTestId: dataTestIds.archiveTooltip,
                isButtonRoleDisabled: true,
              }}
              onClick={onArchiveClickHandler}
              id={domIDs.archiveIcon}
              dataTestId={dataTestIds.archiveIcon}
              onFocus={() => setShowActions(true)}
              onBlur={() => setShowActions(false)}
            />
          </NotificationHeaderActions>
        </NotificationHeaderRight>
      </NotificationHeader>
      <NotificationBody id={domIDs.body} data-testid={dataTestIds.body}>
        <div
          className="description"
          id={domIDs.description}
          data-testid={dataTestIds.description}
        >
          {description}
        </div>
      </NotificationBody>
    </NotificationWrapper>
  );
};

Notification.propTypes = {
  /**
   * domID can be used in css or javascript to target a particular element in DOM.
   *
   * Here is the list of all domIDs:
   *
   * wrapper: domID && `${domID}-wrapper`,
   * header: domID && `${domID}-header`,
   * headerLeft: domID && `${domID}-header-left`,
   * headerRight: domID && `${domID}-header-right`,
   * iconContainer: domID && `${domID}-icon-container`,
   * icon: domID && `${domID}-icon`,
   * titleTooltip: domID && `${domID}-title-tooltip`,
   * title: domID && `${domID}-title`,
   * dateTime: domID && `${domID}-date-time`,
   * headerActions: domID && `${domID}-header-actions`,
   * markAsReadIcon: domID && `${domID}-mark-as-read-icon`,
   * archiveIcon: domID && `${domID}-archive-icon`,
   * body: domID && `${domID}-body`,
   * description: domID && `${domID}-description`,
   *
   */
  domID: PropTypes.string,
  /**
   *  ID for automated testing data-testid
   *
   * Here is the list of all dataTestIds:
   *
   * wrapper: dataTestId && `${dataTestId}-wrapper`,
   * header: dataTestId && `${dataTestId}-header`,
   * headerLeft: dataTestId && `${dataTestId}-header-left`,
   * headerRight: dataTestId && `${dataTestId}-header-right`,
   * iconContainer: dataTestId && `${dataTestId}-icon-container`,
   * icon: dataTestId && `${dataTestId}-icon`,
   * titleTooltip: dataTestId && `${dataTestId}-title-tooltip`,
   * title: dataTestId && `${dataTestId}-title`,
   * dateTime: dataTestId && `${dataTestId}-date-time`,
   * headerActions: dataTestId && `${dataTestId}-header-actions`,
   * markAsReadIcon: dataTestId && `${dataTestId}-mark-as-read-icon`,
   * archiveIcon: dataTestId && `${dataTestId}-archive-icon`,
   * body: dataTestId && `${dataTestId}-body`,
   * description: dataTestId && `${dataTestId}-description`,
   */
  dataTestId: PropTypes.string,
  /**
   * this is name of the parameter that is unique for the notification and that will be passed to callback function when they are triggered
   *
   * e.g. uniqueIdentifier = "id"
   * markAsRead(notification[uniqueIdentifier]);
   * that is same as:
   * markAsRead(notification.id);
   */
  uniqueIdentifier: PropTypes.string,
  /**
   * title of the Notification
   */
  title: PropTypes.string,
  /**
   * description of the Notification
   */
  description: PropTypes.string,
  /**
   * icon is displayed in front of the title
   */
  icon: PropTypes.elementType,
  /**
   * avatarSrc is string to the avatar or the object of image
   */
  avatarSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * dateTime is the date & time property for the Notification
   *
   * dateTime can be number in milliseconds or timestamp as a string or Date object
   */
  dateTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * if notification is read already then set it to true
   */
  isRead: PropTypes.bool,
  /**
   * className property that is applied to Notification wrapper container
   */
  className: PropTypes.string,
  /**
   * width of the Notification
   */
  width: PropTypes.number,
  /**
   * style property is applied to Notification wrapper container
   */
  style: PropTypes.objectOf(PropTypes.string),
  /**
   * function that will be triggered once user click Notification itself
   */
  onClick: PropTypes.func,
  /**
   * function that will be triggered once user clicks "Mark as read" icon
   */
  onMarkAsReadClick: PropTypes.func,
  /**
   * function that will be triggered once user clicks "Archive" icon
   */
  onArchiveClick: PropTypes.func,
};

export default Notification;
