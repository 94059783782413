import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const CircleDashed = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Dashed'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M22.3001 14.0492L21.3192 13.8551C21.4377 13.2559 21.5 12.6357 21.5 12C21.5 11.3643 21.4377 10.7441 21.3192 10.1449L22.3001 9.9508C22.4313 10.6136 22.5 11.2988 22.5 12C22.5 12.7012 22.4313 13.3864 22.3001 14.0492ZM20.7314 6.16604L19.9005 6.72235C19.2066 5.68594 18.3141 4.7934 17.2776 4.09953L17.834 3.26856C18.979 4.03515 19.9649 5.021 20.7314 6.16604ZM14.0492 1.69986L13.8551 2.68085C13.2559 2.56231 12.6357 2.5 12 2.5C11.3643 2.5 10.7441 2.56231 10.1449 2.68085L9.9508 1.69986C10.6136 1.56874 11.2988 1.5 12 1.5C12.7012 1.5 13.3864 1.56874 14.0492 1.69986ZM6.16603 3.26856L6.72235 4.09953C5.68594 4.7934 4.7934 5.68594 4.09953 6.72236L3.26856 6.16604C4.03515 5.021 5.021 4.03515 6.16603 3.26856ZM1.69986 9.9508C1.56874 10.6136 1.5 11.2988 1.5 12C1.5 12.7012 1.56874 13.3864 1.69986 14.0492L2.68085 13.8551C2.56231 13.2559 2.5 12.6357 2.5 12C2.5 11.3643 2.56231 10.7441 2.68085 10.1449L1.69986 9.9508ZM3.26856 17.834L4.09953 17.2776C4.7934 18.3141 5.68594 19.2066 6.72236 19.9005L6.16604 20.7314C5.021 19.9649 4.03515 18.979 3.26856 17.834ZM9.9508 22.3001L10.1449 21.3192C10.7441 21.4377 11.3643 21.5 12 21.5C12.6357 21.5 13.2559 21.4377 13.8551 21.3192L14.0492 22.3001C13.3864 22.4313 12.7012 22.5 12 22.5C11.2988 22.5 10.6136 22.4313 9.9508 22.3001ZM17.834 20.7314L17.2776 19.9005C18.3141 19.2066 19.2066 18.3141 19.9005 17.2776L20.7314 17.834C19.9649 18.979 18.979 19.9649 17.834 20.7314Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Dashed'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M15.8477 9.5613L14.8667 9.36723C14.9541 8.92583 15 8.46878 15 8C15 7.53122 14.9541 7.07416 14.8667 6.63277L15.8477 6.4387C15.9476 6.94367 16 7.46572 16 8C16 8.53428 15.9476 9.05633 15.8477 9.5613ZM14.6525 3.55507L13.8216 4.11139C13.3102 3.3476 12.6524 2.68979 11.8886 2.17844L12.4449 1.34748C13.3173 1.93154 14.0685 2.68266 14.6525 3.55507ZM9.5613 0.152272L9.36723 1.13326C8.92583 1.04594 8.46878 1 8 1C7.53122 1 7.07416 1.04594 6.63277 1.13326L6.4387 0.152272C6.94367 0.0523756 7.46572 0 8 0C8.53428 0 9.05633 0.0523755 9.5613 0.152272ZM3.55507 1.34748L4.11139 2.17845C3.3476 2.68979 2.68979 3.3476 2.17844 4.11139L1.34748 3.55508C1.93154 2.68266 2.68266 1.93154 3.55507 1.34748ZM0.152272 6.4387C0.0523755 6.94367 0 7.46572 0 8C0 8.53428 0.0523756 9.05633 0.152272 9.5613L1.13326 9.36723C1.04594 8.92583 1 8.46878 1 8C1 7.53122 1.04594 7.07417 1.13326 6.63277L0.152272 6.4387ZM1.34748 12.4449L2.17845 11.8886C2.68979 12.6524 3.3476 13.3102 4.11139 13.8216L3.55508 14.6525C2.68266 14.0685 1.93154 13.3173 1.34748 12.4449ZM6.4387 15.8477L6.63277 14.8667C7.07417 14.9541 7.53122 15 8 15C8.46878 15 8.92583 14.9541 9.36723 14.8667L9.5613 15.8477C9.05633 15.9476 8.53428 16 8 16C7.46572 16 6.94367 15.9476 6.4387 15.8477ZM12.4449 14.6525L11.8886 13.8216C12.6524 13.3102 13.3102 12.6524 13.8216 11.8886L14.6525 12.4449C14.0685 13.3173 13.3173 14.0685 12.4449 14.6525Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Circle Dashed'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.8858 7.17097L10.9048 6.9769C10.9672 6.66175 11 6.33521 11 6C11 5.66479 10.9672 5.33825 10.9048 5.0231L11.8858 4.82903C11.9607 5.20775 12 5.59929 12 6C12 6.40071 11.9607 6.79225 11.8858 7.17097ZM10.9894 2.66631L10.1584 3.22263C9.79309 2.67694 9.32306 2.2069 8.77737 1.84158L9.33369 1.01061C9.988 1.44866 10.5513 2.012 10.9894 2.66631ZM7.17097 0.114204L6.9769 1.09519C6.66175 1.03285 6.33521 1 6 1C5.66479 1 5.33825 1.03285 5.0231 1.09519L4.82903 0.114204C5.20775 0.0392817 5.59929 0 6 0C6.40071 0 6.79225 0.0392817 7.17097 0.114204ZM2.66631 1.01061L3.22263 1.84158C2.67694 2.20691 2.2069 2.67694 1.84158 3.22263L1.01061 2.66631C1.44866 2.012 2.012 1.44866 2.66631 1.01061ZM0.114204 4.82903C0.0392817 5.20775 0 5.59929 0 6C0 6.40071 0.0392817 6.79225 0.114204 7.17097L1.09519 6.9769C1.03285 6.66175 1 6.33521 1 6C1 5.66479 1.03285 5.33825 1.09519 5.0231L0.114204 4.82903ZM1.01061 9.33369L1.84158 8.77738C2.20691 9.32306 2.67694 9.79309 3.22263 10.1584L2.66631 10.9894C2.012 10.5513 1.44866 9.988 1.01061 9.33369ZM4.82903 11.8858L5.0231 10.9048C5.33825 10.9672 5.66479 11 6 11C6.33521 11 6.66175 10.9672 6.9769 10.9048L7.17097 11.8858C6.79225 11.9607 6.40071 12 6 12C5.59929 12 5.20775 11.9607 4.82903 11.8858ZM9.33369 10.9894L8.77738 10.1584C9.32306 9.79309 9.79309 9.32306 10.1584 8.77737L10.9894 9.33369C10.5513 9.988 9.988 10.5513 9.33369 10.9894Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

CircleDashed.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default CircleDashed;
