import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const User = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 6.5C8 4.01514 10.0151 2 12.5 2C14.9849 2 17 4.01514 17 6.5V7.5C17 9.98486 14.9849 12 12.5 12C10.0151 12 8 9.98486 8 7.5V6.5ZM12.5 1C9.46286 1 7 3.46286 7 6.5V7.5C7 10.5371 9.46286 13 12.5 13C15.5371 13 18 10.5371 18 7.5V6.5C18 3.46286 15.5371 1 12.5 1ZM12.5 16C9.85188 16 7.21809 16.5467 5.30263 17.0696H10.8481C10.8479 17.0696 10.8477 17.0696 10.8474 17.0696L5.30248 17.0696C3.34559 17.6026 2 19.3849 2 21.408V23.5C2 23.7761 2.22386 24 2.5 24H22.5C22.7761 24 23 23.7761 23 23.5V21.408C23 19.385 21.6545 17.6026 19.6977 17.0696L14.1527 17.0696C14.1524 17.0696 14.1521 17.0696 14.1519 17.0696H19.6974C17.7819 16.5467 15.1481 16 12.5 16ZM14.1527 17.0696L10.8474 17.0696C8.89679 17.2285 7.02822 17.6351 5.56567 18.0344L5.56537 18.0344C4.04834 18.4475 3 19.8311 3 21.408V23H22V21.408C22 19.8311 20.9517 18.4475 19.4346 18.0344L19.4343 18.0344C17.9718 17.6351 16.1033 17.2285 14.1527 17.0696ZM14.1519 17.0696H10.8481C11.3947 17.0251 11.9477 17 12.5 17C13.0523 17 13.6053 17.0251 14.1519 17.0696Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4C11 5.65685 9.65685 7 8 7C6.34315 7 5 5.65685 5 4ZM8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0ZM6.5 10C5.04131 10 3.64236 10.5795 2.61091 11.6109C1.57946 12.6424 1 14.0413 1 15.5C1 15.6326 1.05268 15.7598 1.14645 15.8536C1.24021 15.9473 1.36739 16 1.5 16H14.5C14.7761 16 15 15.7761 15 15.5C15 14.7777 14.8577 14.0625 14.5813 13.3952C14.3049 12.728 13.8998 12.1216 13.3891 11.6109C12.8784 11.1002 12.272 10.6951 11.6048 10.4187C10.9375 10.1423 10.2223 10 9.5 10H6.5ZM3.31802 12.318C4.16193 11.4741 5.30653 11 6.5 11H9.5C10.0909 11 10.6761 11.1164 11.2221 11.3425C11.768 11.5687 12.2641 11.9002 12.682 12.318C13.0998 12.7359 13.4313 13.232 13.6575 13.7779C13.8195 14.1692 13.9253 14.5807 13.9721 15H2.02785C2.14069 13.9905 2.59281 13.0432 3.31802 12.318Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3ZM6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0ZM6 7C4.37328 7 3.13414 7.50897 2.29347 8.19919C1.47165 8.87393 1 9.75368 1 10.5V11.5C1 11.7761 1.22386 12 1.5 12H10.5C10.7761 12 11 11.7761 11 11.5V10.5C11 9.75593 10.5281 8.87628 9.70672 8.20122C8.86622 7.51043 7.62707 7 6 7ZM2 10.5C2 10.1413 2.25935 9.52107 2.92803 8.97206C3.57786 8.43853 4.58872 8 6 8C7.41093 8 8.42178 8.43957 9.07178 8.97378C9.74091 9.52372 10 10.1441 10 10.5V11H2V10.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'xSmall':
      return (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'User'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.94999 2.24999C2.94999 1.67009 3.42009 1.19999 3.99999 1.19999C4.57989 1.19999 5.04999 1.67009 5.04999 2.24999C5.04999 2.82989 4.57989 3.29999 3.99999 3.29999C3.42009 3.29999 2.94999 2.82989 2.94999 2.24999ZM3.99999 0.299988C2.92303 0.299988 2.04999 1.17303 2.04999 2.24999C2.04999 3.32694 2.92303 4.19999 3.99999 4.19999C5.07694 4.19999 5.94999 3.32694 5.94999 2.24999C5.94999 1.17303 5.07694 0.299988 3.99999 0.299988ZM3.99999 4.43636C2.90346 4.43636 2.05812 4.74016 1.4757 5.16401C0.914367 5.57251 0.549988 6.13473 0.549988 6.65908V7.24999C0.549988 7.49852 0.75146 7.69999 0.999988 7.69999H6.99999C7.24852 7.69999 7.44999 7.49852 7.44999 7.24999V6.65908C7.44999 6.13598 7.08535 5.57385 6.52445 5.16525C5.94215 4.74105 5.0968 4.43636 3.99999 4.43636ZM1.44999 6.65908C1.44999 6.53048 1.57294 6.20634 2.00527 5.89171C2.41652 5.59244 3.07118 5.33636 3.99999 5.33636C4.92851 5.33636 5.58316 5.59302 5.99452 5.89269C6.42729 6.20795 6.54999 6.53219 6.54999 6.65908V6.79999H1.44999V6.65908Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

User.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default User;
