import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ChipStyle from './components/ChipStyle';
import Tooltip from '../Tooltip';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';

export const Chip = ({
  type = 'neutral',
  domID = null,
  label = '',
  className = '',
  tooltipWidth = 150,
  tooltipPosition = 'top-center',
  dataTestId = '',
}) => {
  const ref = useRef();
  const isTruncated = useIsTruncated(ref, [label]);

  return (
    <Tooltip
      tooltipContent={label}
      domID={`${domID}-chip-tooltip`}
      dataTestId={`${dataTestId}-chip-tooltip`}
      hideTooltip={!isTruncated}
      isButtonRoleDisabled
      disablePointerEvents
      tooltipPosition={tooltipPosition}
      tooltipWidth={tooltipWidth}
      style={{ maxWidth: '100%', display: 'inline-flex' }}
    >
      <ChipStyle
        data-testid={dataTestId}
        className={`chip ${type} ${className}`}
        id={domID}
        tabIndex={isTruncated ? 0 : -1}
      >
        <span className="chip-label" ref={ref}>
          {label}
        </span>
      </ChipStyle>
    </Tooltip>
  );
};

export const ChipPropTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'neutral',
    'positive',
    'negative',
    'caution',
    'light',
    'dark',
  ]),
  domID: PropTypes.string,
  className: PropTypes.string,
  tooltipWidth: PropTypes.number,
  tooltipPosition: PropTypes.string,
};

Chip.propTypes = { ...ChipPropTypes };

export default Chip;
