import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.h1`
  margin: 0 0.6rem 0 0;
  ${TextStyles.xLarge};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
