import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.button`
  padding: 7.5px 24px 8.5px 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-left: ${(props) => props.paddingLeft}px;
  ${TextStyles.small};
  color: ${({ textColor }) => textColor};
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  cursor: pointer;
  width: 100%;
  outline: none;
  overflow: hidden;
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: 36px;

  &.focused,
  &:focus,
  &:focus-visible {
    background-color: ${({ theme }) => theme.BaseColors.White};
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:disabled {
    color: ${({ theme }) => theme.BaseColors.Neutral['100']};
    cursor: default;
  }

  &:hover:enabled {
    background-color: ${({ theme, hoverBackgroundColor }) =>
      hoverBackgroundColor || theme.BaseColors.Neutral['5']};
  }

  &:active:enabled {
    background: ${({ theme }) => theme.BaseColors.Neutral['10']};
  }

  &.selected {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Background.Selected};

    &:hover:enabled {
      background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
    }
  }

  &.selected.single {
    border-left: 2px solid ${({ theme }) => theme.BaseColors.Primary['100']};
    padding-left: 22px; /* total needs to be 24px since 2px is border padding is 22px */
  }

  .multi-check {
    margin-right: 12px;
  }

  svg {
    flex-shrink: 0;
  }
`;
