import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useTooltip from '../../commonResources/hooks/useTooltip';
import useIntersectionObserver from '../../commonResources/hooks/useIntersectionObserver';
import TooltipTransition from './components/TooltipTransition';
import { VisuallyHiddenText } from '../../commonResources/styles/a11y';

export const Tooltip = ({
  domID = null,
  children = null,
  dataTestId = null,
  tooltipContent = null,
  tooltipWidth = 200,
  hideTooltip = false,
  style = null,
  text = null,
  isButtonRoleDisabled = false,
  tooltipPosition: initialTooltipPosition = 'top-center',
  disablePointerEvents = false,
  disableCollisionDetection = false,
  caretToAnchor = 8,
  whiteSpace = 'normal',
  zIndex = 999,
  collisionMargin = 50,
  onClick = () => {},
  onKeyPress = () => {},
  initialToolTipShown = false,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(
    initialTooltipPosition,
  );
  useEffect(
    () => setTooltipPosition(initialTooltipPosition),
    [initialTooltipPosition],
  );

  const caretWidth = 12;
  const caretLength = 4;
  const caretToEdge = 12;

  const wrapperRef = useRef();
  const tooltipRef = useRef();

  const { viewportEdgesCrossed } = useIntersectionObserver({
    ref: wrapperRef,
    rootMargin: `-${collisionMargin}px`,
  });

  const { toggleTooltip, isTooltipShown, offsets } = useTooltip(
    wrapperRef,
    tooltipRef,
    {
      tooltipPosition,
      caretWidth,
      caretLength,
      caretToEdge,
      caretToAnchor,
    },
  );

  useEffect(() => {
    const closeToolTip = () => {
      toggleTooltip(false);
    };

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mouseleave', closeToolTip, false);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener(
          'mouseleave',
          closeToolTip,
          false,
        );
      }
    };
  }, []);

  useEffect(() => {
    toggleTooltip(initialToolTipShown);
  }, [initialToolTipShown]);

  useEffect(() => {
    if (
      window.IntersectionObserver !== undefined &&
      !disableCollisionDetection
    ) {
      const { top, bottom, left, right } = viewportEdgesCrossed;
      let [primary, secondary] = tooltipPosition.split('-');

      if (top === true) {
        if (primary === 'top') primary = 'bottom';
        else if (secondary === 'up') secondary = 'down';
      }
      if (bottom === true) {
        if (primary === 'bottom') primary = 'top';
        else if (secondary === 'down') secondary = 'up';
      }
      if (left === true) {
        if (primary === 'left') primary = 'right';
        else if (secondary === 'left') secondary = 'right';
      }
      if (right === true) {
        if (primary === 'right') primary = 'left';
        else if (secondary === 'right') secondary = 'left';
      }

      if (!disableCollisionDetection)
        setTooltipPosition(`${primary}-${secondary}`);
    }
  }, [tooltipPosition, viewportEdgesCrossed, disableCollisionDetection]);

  const tooltipClasses = ['tooltip', `tt-${tooltipPosition}`];

  if (isTooltipShown) tooltipClasses.push('shown');

  const [positionTop, positionRight, positionBottom, positionLeft] = offsets;

  const testTooltipWidth = (val) => {
    if (!isNaN(val)) {
      return val >= 0;
    }
    return /^\+?(0|[1-9]\d*)$/.test(val);
  };

  const rolesWithHandler = isButtonRoleDisabled
    ? {}
    : { role: 'button', tabIndex: '0', onKeyPress, onClick };

  const safeWidth = testTooltipWidth(tooltipWidth) ? tooltipWidth : 200;

  const TooltipPortal = ReactDOM.createPortal(
    <TooltipTransition
      onIn={isTooltipShown}
      data-testid={dataTestId}
      ref={tooltipRef}
      onFocus={() => toggleTooltip(true)}
      onBlur={() => toggleTooltip(false)}
      className={tooltipClasses.join(' ')}
      caretWidth={caretWidth}
      caretLength={caretLength}
      caretToEdge={caretToEdge}
      caretToAnchor={caretToAnchor}
      style={{
        top: positionTop,
        right: positionRight,
        bottom: positionBottom,
        left: positionLeft,
        width: `${safeWidth}px`,
        overflowWrap: 'break-word',
        zIndex,
        pointerEvents: disablePointerEvents ? 'none' : 'auto',
        whiteSpace,
      }}
      onMouseLeave={() => toggleTooltip(false)}
    >
      {tooltipContent || text}
    </TooltipTransition>,
    document.body,
  );

  return (
    <span
      data-testid={`${dataTestId}-wrapper`}
      id={domID}
      className="tooltip-wrapper"
      onMouseEnter={() => toggleTooltip(true)}
      onFocus={() => toggleTooltip(true)}
      onBlur={() => toggleTooltip(false)}
      ref={wrapperRef}
      style={{
        position: 'relative',
        display: 'inline-block',
        ...style,
      }}
      {...rolesWithHandler}
    >
      {children}
      {!hideTooltip ? (
        <VisuallyHiddenText className={tooltipClasses.join(' ')}>
          {tooltipContent || text}
        </VisuallyHiddenText>
      ) : null}
      {!hideTooltip ? TooltipPortal : null}
    </span>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /* Disables the role='button' added to the wrapper of tooltip.
  This can be used when tooltip is used on the component that already has role='button' e.g. <button> */
  isButtonRoleDisabled: PropTypes.bool,
  // eslint-disable-next-line consistent-return
  tooltipContent: (props) => {
    if (!props.hideTooltip && !props.tooltipContent && !props.text) {
      return new Error(
        "One of props 'tooltipContent' or 'text' was not specified in 'Tooltip'.",
      );
    }
  },
  tooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),

  tooltipWidth: PropTypes.number,
  /* disables the tooltip and doesn't render the visually hidden text content */
  hideTooltip: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line consistent-return
  text: (props) => {
    if (!props.hideTooltip && !props.tooltipContent && !props.text) {
      return new Error(
        "One of props 'tooltipContent' or 'text' was not specified in 'Tooltip'.",
      );
    }
  },
  /** valid css white-space value. default value is 'normal' */
  whiteSpace: PropTypes.string,
  /** default value is 999 */
  zIndex: PropTypes.number,
  /* distance from the wrapped element to the tooltip caret in pixels */
  caretToAnchor: PropTypes.number,
  /* if true, you can't mouse over the tooltip itself, it will hide as soon as the cursor isn't over the wrapped element */
  disablePointerEvents: PropTypes.bool,
  /* if true, the tooltip will not change position to stay within the viewport */
  disableCollisionDetection: PropTypes.bool,
  /* the distance in pixels between the anchor / child element and the viewport edge at which the tooltip will reposition to stay within the viewport. */
  collisionMargin: PropTypes.number,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  initialToolTipShown: PropTypes.bool,
};

export default Tooltip;
