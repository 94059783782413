import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import Borders from '../../../BrandCore/shapes/borders';

export const CountOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${Borders.pillBorderRadius}
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.Depth5};
  ${({ theme }) => theme.BoxShadows.Depth5};

  &.small {
    min-width: 17px;
    height: 17px;
  }

  &.medium {
    min-width: 22px;
    height: 22px;
  }

  &.large {
    min-width: 26px;
    height: 26px;
  }
`;

export const CountInner = styled.span`
  color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};

  &.small {
    padding: 2px 0.5em 1px 0.5em;
    ${TextStyles.xSmallBold};
  }

  &.medium {
    padding: 1px 0.46em;
    ${TextStyles.smallBold};
  }

  &.large {
    padding: 1px 0.45em;
    ${TextStyles.mediumBold};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px
        ${(props) => props.theme.UIPalette.Colors.Content.White},
      0 0 0 2px ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
    ${Borders.pillBorderRadius}
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
