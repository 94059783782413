import { useEffect, useCallback } from 'react';

const API_ENDPOINT =
  'https://metrics.uicl.awsnonprod.healthcareit.net/uicl-analytics';

/**
 * Sanitizes productname to create a uniqueue local storage key for this product
 */
const cleanupProductName = (productName) => {
  if (!productName) return '';
  const cleanedUpProductName = productName.replace(/\s/g, '');
  return cleanedUpProductName.toLowerCase();
};

const useAnalytics = (data, apiEndpoint = API_ENDPOINT) => {
  const requestJson = JSON.stringify(data);
  const { currentProductName } = data || {};
  const sendEvent = useCallback(() => {
    if (typeof fetch !== 'undefined') {
      // if running in browser make api call
      // test environment like jest should not reach this code
      fetch(apiEndpoint, {
        method: 'POST',
        body: requestJson,
        headers: {
          'Content-type': 'application/json',
        },
      }).catch(() => {
        // ignore all the errors that might occur
        // while making an api call
      });
    }
  }, [requestJson, apiEndpoint]);

  useEffect(() => {
    const localStorageKeyForCurrentProduct = `uicl-track-id-${cleanupProductName(
      currentProductName,
    )}`;

    const hasBeenTracked = localStorage.getItem(
      localStorageKeyForCurrentProduct,
    );

    if (!hasBeenTracked && !process.env.DISABLE_UICL_TRACKING) {
      localStorage.setItem(localStorageKeyForCurrentProduct, Date.now());
      try {
        sendEvent();
      } catch {
        // fail without error
      }
    }
  }, [sendEvent, currentProductName]);
};

export default useAnalytics;
