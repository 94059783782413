import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import MainContentWrapper from './MainContentWrapper';

import {
  DETAIL_PANE_EXPAND_COLLAPSE,
  NAVIGATION_PANE_EXPAND_COLLAPSE,
} from '../../../../BrandCore/constants/timings';

const widthDuration = DETAIL_PANE_EXPAND_COLLAPSE;
const paddingDuration = NAVIGATION_PANE_EXPAND_COLLAPSE;

const defaultStyle = {
  transition: `width ${widthDuration}ms ease-in-out, left ${paddingDuration}ms ease-in-out`,
};

const collapseStyles = {
  entering: {
    width: '30px',
    transition: `width ${widthDuration}ms ease-in-out, left ${paddingDuration}ms ease-in-out`,
  },
  entered: { width: '30px' },
  exiting: {
    transition: `width ${widthDuration}ms ease-in, left ${paddingDuration}ms ease-in`,
  },
};

const MainContentExpandCollapseTransition = ({
  fullExpand,
  onIn,
  navigationPanePadding,
  children,
  dataTestId = '',
  className = '',
  width = '50%',
}) => {
  defaultStyle.width = `calc(100% - ${navigationPanePadding}px)`;

  const nodeRef = React.useRef(null);

  const transitionStyles = {
    entering: { width },
    entered: { width },
  };

  return fullExpand ? (
    <Transition in={onIn} timeout={widthDuration} appear nodeRef={nodeRef}>
      {(state) => (
        <MainContentWrapper
          style={{
            ...defaultStyle,
            ...collapseStyles[state],
          }}
          className={`${state} ${className}`}
          navigationPanePadding={navigationPanePadding}
          data-testid={dataTestId}
        >
          {children}
        </MainContentWrapper>
      )}
    </Transition>
  ) : (
    <Transition in={onIn} timeout={widthDuration} appear nodeRef={nodeRef}>
      {(state) => (
        <MainContentWrapper
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={`${state} ${className}`}
          data-testid={dataTestId}
          navigationPanePadding={navigationPanePadding}
        >
          {children}
        </MainContentWrapper>
      )}
    </Transition>
  );
};

MainContentExpandCollapseTransition.propTypes = {
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  navigationPanePadding: PropTypes.number.isRequired,
  fullExpand: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default MainContentExpandCollapseTransition;
