import styled from 'styled-components';
import Button from '../../../Atoms/Button';

export default styled(Button)`
  display: flex;
  align-content: center;
  min-height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: ${(props) => props.zIndex};

  .dropdown-caret {
    display: flex;
    align-content: center;
  }

  &.small {
    .dropdown-caret {
      padding: 1px 8px;
    }
  }

  &.medium {
    .dropdown-caret {
      padding: 3px 12px;
    }
  }

  &.large {
    .dropdown-caret {
      padding: 3px 12px;
    }
  }

  &.emphasized {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Primary['115']};
    }
    &:disabled .separator {
      background: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Border.Emphasized};
    }
  }

  &.standard {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['70']};
    }
    &:disabled .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['30']};
    }
  }

  &.deEmphasized {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['70']};
    }
    &:disabled .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['10']};
    }
  }

  &.diminished {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['70']};
    }
    &:disabled .separator {
      background: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Diminished};
    }
  }

  &.destroy {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Negative['70']};
    }
    &:disabled .separator {
      background: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.Destroy};
    }
  }

  &.emphasizedAlt {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Accent['50']};
    }
    &:disabled .separator {
      background: ${({ theme }) =>
        theme.UIPalette.Colors.Button.Disabled.Text.EmphasizedAlt};
    }
  }

  &.deEmphasizedReversed {
    .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['70']};
    }
    &:disabled .separator {
      background: ${({ theme }) => theme.BaseColors.Neutral['50']};
    }
  }
`;
