import styled from 'styled-components';
import { UnstyledButton } from '../../../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  position: relative;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:active {
    background-color: ${(props) => props.customTheme.activeBackground};
    path {
      fill: ${(props) => props.customTheme.activeColor};
    }
  }

  &.open {
    background-color: ${(props) => props.customTheme.openBackground};
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover:not(.open):not(:active) {
    background-color: ${(props) => props.customTheme.hoverBackground};
  }
`;
