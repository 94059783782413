import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import {
  POSITIVE_NOTIFICATION_TYPE,
  NEGATIVE_NOTIFICATION_TYPE,
  CAUTION_NOTIFICATION_TYPE,
  NEUTRAL_NOTIFICATION_TYPE,
  VALID_NOTIFICATION_TYPES,
  BANNER_ANIMATION_MILLISECONDS,
  DEFAULT_TRANSITION_STYLES,
  ACTIVE_TRANSITION_STYLES,
} from './modules/constants';
import { setUpAutoDismiss } from './modules/helpers';

import IconButton from '../../Atoms/IconButton';
import NotificationWrapper from './components/NotificationWrapper';

import Close from '../../BrandCore/icons/interactive/Close';
import CircleCheck from '../../BrandCore/icons/informational/CircleCheck';
import Warning from '../../BrandCore/icons/informational/Warning';
import Diamond0 from '../../BrandCore/icons/informational/Diamond0';

const ICON_MAP = {
  [POSITIVE_NOTIFICATION_TYPE]: CircleCheck,
  [NEGATIVE_NOTIFICATION_TYPE]: Warning,
  [CAUTION_NOTIFICATION_TYPE]: Diamond0,
  [NEUTRAL_NOTIFICATION_TYPE]: CircleCheck,
};

const StyledIconButton = styled(IconButton)`
  background: transparent !important; /* stylelint-disable-line declaration-no-important */
  &:focus-visible {
    box-shadow: inset 0 0 0 1px
      ${({ type, theme }) => {
        return type === CAUTION_NOTIFICATION_TYPE
          ? theme.UIPalette.Colors.Content.PersistentDark
          : theme.BaseColors.White;
      }};
  }
`;

const NotificationBanner = ({
  domID = '',
  dataTestId = '',
  type = 'positive',
  icon = null,
  text = '',
  autoDismiss = null,
  timer = null,
  onDismiss = () => false,
}) => {
  const [isShown, setIsShown] = useState(!!text);
  const theme = useContext(ThemeContext);

  const close = useCallback(() => {
    setIsShown(false);
    onDismiss();
  }, [onDismiss]);

  /** Sets & starts timer on mount */
  useEffect(() => {
    const timeout = setUpAutoDismiss(autoDismiss, close, !!text, timer, type);

    return function cleanup() {
      if (timeout) clearTimeout(timeout);
    };
  }, [timer, autoDismiss, type, text, close]);

  /** Sets up data-testids */
  const testIDs = {
    icon: dataTestId ? `${dataTestId}-icon` : '',
    closeButton: dataTestId ? `${dataTestId}-close` : '',
  };

  const Icon = icon || ICON_MAP[type];

  const nodeRef = React.useRef(null);

  return (
    <Transition
      in={isShown}
      timeout={BANNER_ANIMATION_MILLISECONDS}
      appear
      nodeRef={nodeRef}
    >
      {(state) => (
        <NotificationWrapper
          className={`notification-wrapper ${type}`}
          id={domID}
          data-testid={dataTestId}
          style={{
            ...DEFAULT_TRANSITION_STYLES,
            ...ACTIVE_TRANSITION_STYLES[state],
          }}
        >
          <span className="notification-text">
            <Icon
              title={type}
              size="medium"
              domID={`${domID}-icon`}
              fillColor={
                type === CAUTION_NOTIFICATION_TYPE
                  ? theme.UIPalette.Colors.Content.PersistentDark
                  : theme.BaseColors.White
              }
            />
            {text}
          </span>
          <StyledIconButton
            onClick={(e) => close(e)}
            className="notification-dismiss"
            dataTestId={testIDs.closeButton}
            fillColor={
              type === CAUTION_NOTIFICATION_TYPE
                ? theme.UIPalette.Colors.Content.PersistentDark
                : theme.BaseColors.White
            }
            hoverColor={
              type === CAUTION_NOTIFICATION_TYPE
                ? theme.UIPalette.Colors.Content.PersistentDark
                : theme.BaseColors.White
            }
            icon={Close}
            size="medium"
          />
        </NotificationWrapper>
      )}
    </Transition>
  );
};

NotificationBanner.propTypes = {
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** determines color & default icon of banner */
  type: PropTypes.oneOf(VALID_NOTIFICATION_TYPES).isRequired,
  /** optionally pass an icon to change the default. This should be a ui-core icon component, passed as `{Icon}`, not `{<Icon/>}` */
  icon: PropTypes.func,
  /** text displayed on notification banner - if not provided, the banner will not appear */
  text: PropTypes.string,
  /** if `true`, banner will dismiss after a set period of time */
  autoDismiss: PropTypes.bool,
  /** time in milliseconds elapsed before timer is auto-dismissed; 2500ms by default */
  timer: PropTypes.number,
  /** Runs after timeout or on manual dismiss. Takes no parameters. */
  onDismiss: PropTypes.func,
};

export default NotificationBanner;
