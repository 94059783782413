import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgressIndicator from '../../../Atoms/ProgressIndicator';
import ProgressWrapper from './styleWrappers/ProgressWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const FirstLine = styled.div`
  margin-top: 10px;
`;

const ProgressCell = ({
  data: { isStoppable = false, progress, secondLine = null } = {},
  cellAlign = 'left',
}) => (
  <>
    <FirstLine>
      <ProgressWrapper>
        <ProgressIndicator
          progress={progress}
          isStoppable={isStoppable}
          align={cellAlign}
        />
      </ProgressWrapper>
    </FirstLine>
    {secondLine && (
      <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
    )}
  </>
);

ProgressCell.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      progress: PropTypes.number,
      isStoppable: PropTypes.bool,
      secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default ProgressCell;
