import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Map = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Map'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M24 2.66286V2L23.3626 2.1821L16.4914 4.14532L8.62127 2.17779L8.49138 2.14532L8.36264 2.1821L1.36264 4.1821L1 4.28571V4.66286V22.6629V23.3257L1.63736 23.1436L8.50862 21.1804L16.3787 23.1479L16.5086 23.1804L16.6374 23.1436L23.6374 21.1436L24 21.04V20.6629V2.66286ZM8 3.32573L2 5.04002V22L8 20.2857V3.32573ZM9 20.2725L16 22.0225V5.05325L9 3.30325V20.2725ZM17 5.04002V22L23 20.2857V3.32573L17 5.04002Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Map'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.30369 0.0400115L0.314305 2.03576C0.124475 2.1117 0 2.29555 0 2.5V15.5C0 15.6659 0.0822994 15.821 0.219686 15.914C0.357072 16.007 0.53165 16.0259 0.685695 15.9642L5.5 14.0385L10.3038 15.9601C10.3614 15.9846 10.4246 15.9987 10.4909 15.9999C10.4939 16 10.4969 16 10.5 16C10.5031 16 10.5061 16 10.5091 15.9999C10.5754 15.9987 10.6386 15.9846 10.6962 15.9601L15.6857 13.9642C15.8755 13.8883 16 13.7045 16 13.5V0.500002C16 0.33409 15.9177 0.178984 15.7803 0.0859687C15.6429 -0.00704625 15.4684 -0.0258541 15.3143 0.035764L10.5 1.96149L5.69631 0.0400115C5.63604 0.0142563 5.56969 0 5.5 0C5.43031 0 5.36396 0.0142563 5.30369 0.0400115ZM11 14.7615L15 13.1615V1.23852L11 2.83852V14.7615ZM10 2.83852L6 1.23852V13.1615L10 14.7615V2.83852ZM5 13.1615V1.23852L1 2.83852V14.7615L5 13.1615Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Map'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 0.489798C12 0.327272 11.9194 0.175331 11.7848 0.0842143C11.6502 -0.00690245 11.4792 -0.0253265 11.3283 0.0350341L7.83673 1.43166L4.35547 0.0391552C4.2973 0.0143089 4.23333 0.000399633 4.16616 1.08444e-05C4.1652 5.26224e-06 4.16423 2.33553e-06 4.16327 2.33553e-06C4.1623 2.33553e-06 4.16133 5.26433e-06 4.16037 1.08452e-05C4.0932 0.000399604 4.02923 0.0143101 3.97105 0.0391585L0.30789 1.50442C0.121935 1.5788 0 1.75891 0 1.95919V11.5102C0 11.6727 0.0806198 11.8247 0.215202 11.9158C0.349785 12.0069 0.5208 12.0253 0.671702 11.965L4.16327 10.5683L7.6445 11.9608C7.70268 11.9857 7.76666 11.9996 7.83384 12C7.8348 12 7.83577 12 7.83673 12C7.8377 12 7.83867 12 7.83963 12C7.90681 11.9996 7.97078 11.9857 8.02896 11.9608L11.6921 10.4956C11.8781 10.4212 12 10.2411 12 10.0408V0.489798ZM8.32653 10.7868L11.0204 9.70921V1.21324L8.32653 2.29079V10.7868ZM7.34694 2.29079L4.65306 1.21324V9.70921L7.34694 10.7868V2.29079ZM3.67347 9.70921V1.21324L0.979592 2.29079V10.7868L3.67347 9.70921Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Map.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Map;
