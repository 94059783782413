import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Flag = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'small',
}) => {
  switch (size) {
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Flag'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#clip0_2981:457)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M9.10148 0.274948C9.02562 0.143592 8.89423 0.0537764 8.7443 0.0307809C8.59437 0.00778547 8.4421 0.054097 8.33036 0.15668C7.54018 0.882106 6.69441 0.876379 5.67334 0.869465L5.67332 0.869465C5.57972 0.868831 5.48464 0.868187 5.388 0.868096C4.83727 0.867575 4.22313 0.89036 3.63674 1.12568C3.02959 1.36933 2.49691 1.8207 2.06032 2.59061C1.97288 2.74479 1.97361 2.93375 2.06224 3.08725L4.50049 7.3105C4.59013 7.46577 4.75606 7.56116 4.93535 7.5605C5.11465 7.55983 5.27986 7.46322 5.36835 7.30728C5.70761 6.70949 6.07547 6.42367 6.4474 6.27226C6.8394 6.11269 7.28224 6.082 7.82037 6.08066C7.91904 6.08041 8.02095 6.08138 8.12563 6.08239C9.11131 6.09181 10.3435 6.1036 11.4411 5.12074C11.6173 4.96289 11.6588 4.7031 11.5405 4.4982L9.10148 0.274948ZM4.97611 6.13429L3.07757 2.84586C3.37435 2.40831 3.69011 2.18177 4.00916 2.05374C4.40239 1.89594 4.84682 1.86758 5.38706 1.8681C5.48245 1.86819 5.58086 1.86939 5.68185 1.87063H5.68186C6.52845 1.881 7.55709 1.89361 8.52134 1.26997L10.4569 4.62156C9.7526 5.08777 8.99957 5.08474 8.11957 5.08121C8.02065 5.08081 7.92012 5.0804 7.81788 5.08066C7.26738 5.08203 6.65516 5.10801 6.07038 5.34606C5.6714 5.50847 5.30471 5.76074 4.97611 6.13429ZM0.978264 2.46125C0.840195 2.22211 0.5344 2.14017 0.295253 2.27824C0.0561054 2.41631 -0.0258345 2.7221 0.112235 2.96125L5.26024 11.878C5.3983 12.1171 5.7041 12.1991 5.94325 12.061C6.18239 11.9229 6.26433 11.6172 6.12626 11.378L0.978264 2.46125Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_2981:457">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Flag.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Flag;
