import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ArrowTopRight = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'small',
}) => {
  switch (size) {
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ArrowTopRight'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.9621 1.30861C10.9377 1.24964 10.9015 1.19439 10.8536 1.14645C10.8056 1.09851 10.7504 1.06234 10.6914 1.03794C10.6324 1.01349 10.5678 1 10.5 1H10.4999H4.5C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H9.29289L1.14645 10.1464C0.951184 10.3417 0.951184 10.6583 1.14645 10.8536C1.34171 11.0488 1.65829 11.0488 1.85355 10.8536L10 2.70711V7.5C10 7.77614 10.2239 8 10.5 8C10.7761 8 11 7.77614 11 7.5V1.50049V1.5C11 1.499 11 1.498 11 1.497C10.9996 1.4303 10.9861 1.36669 10.9621 1.30861Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ArrowTopRight.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ArrowTopRight;
