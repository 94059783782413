import React, { useContext } from 'react';
import LayoutProvider, { LayoutContext } from './LayoutProvider';

export const useLayout = () => useContext(LayoutContext);

export const withLayout = (WrappedComponent) => (props) => {
  return (
    <LayoutProvider>
      <WrappedComponent {...props} />
    </LayoutProvider>
  );
};

export default useLayout;
