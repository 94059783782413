import styled from 'styled-components';
import { TextStyles } from '../../../BrandCore/Text/styles';

export default styled.span`
  display: block;
  color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};

  &.small {
    ${TextStyles.xSmall};
  }

  &.medium {
    ${TextStyles.small};
  }

  &.large {
    ${TextStyles.medium};
  }
`;
