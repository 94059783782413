import React from 'react';
import PropTypes from 'prop-types';

import PageDescription from './components/PageDescription';

export const DetailPageDescription = ({
  domID = null,
  className = '',
  dataTestId = '',
  description = '',
}) => (
  <PageDescription id={domID} className={className} data-testid={dataTestId}>
    {description}
  </PageDescription>
);

DetailPageDescription.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default DetailPageDescription;
