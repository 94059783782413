import styled from 'styled-components';
import Padding from '../../../commonResources/padding';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  min-width: 100px;
  & .tab {
    margin-right: 24px;
  }
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.UIPalette.BoxShadows.General.LineBelow}
  .more-btn {
    padding-left: 0px;
    text-transform: none;
    border: none;
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Content
        .Secondary} !important; /* stylelint-disable-line declaration-no-important */
    &:hover {
      color: ${({ theme }) =>
        theme.UIPalette.Colors.Content
          .Primary} !important; /* stylelint-disable-line declaration-no-important */
    }
    &.more-btn-small {
      ${TextStyles.xSmall}
      &.selected {
        ${TextStyles.xSmallBold}
      }
    }
    &.more-btn-medium {
      ${TextStyles.small}
      &.selected {
        ${TextStyles.smallBold}
      }
    }
  }
`;

export const TabsSwitcherWrapper = styled.span`
  display: flex;
  align-items: center;
  &:first-child {
    margin-left: ${Padding.tabsFirstItemLeftPadding};
  }
`;

export const TabsChildrenWrapper = styled.div`
  margin-left: 48px;
  display: flex;
  align-items: center;
  padding-right: ${Padding.detailPaneHeader.left}px;
`;

export const MoreDropdownWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected,
  &:focus-within {
    ${({ theme }) => theme.BoxShadows.SelectedTabBottom}
  }
  button.more-btn {
    box-shadow: none;
    &:focus {
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
      outline: none;
    }
  }
  .more-list {
    top: 42px;
    button.disabled {
      cursor: not-allowed;
    }
  }
`;
