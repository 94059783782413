import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Truncate from 'react-truncate';

import Wrapper from './styles/Wrapper';
import MenuClickTarget from './styles/MenuClickTarget';
import ProductName from './styles/ProductName';
import ViewCaret from './styles/ViewCaret';
import StandardMenu from '../../../../Molecules/StandardMenu';
import Tooltip from '../../../../Atoms/Tooltip';

import {
  white,
  neutral150,
  primary100,
} from '../../../../BrandCore/colors/themes/base/basePalette';
import Down from '../../../../BrandCore/icons/interactive/Down';
import { Upsell } from '../../../../BrandCore/icons/interactive';
import MenuFooter from '../../../../Atoms/MenuFooter';

export const INITIAL_STATE = {
  isMenuOpen: false,
  activeMenuItemIndex: null,
  activeSectionIndex: null,
};

const VerticalDivider = styled.div`
  border-left: 1px solid ${(props) => props.color};
  height: 1.5rem;
`;

export const ProductMenu = ({
  onMenuItemClick = () => false,
  onExploreProductsClick,
  onOpen = () => false,
  onOpenClose = () => false,
  onClose = () => false,
  currentProductName = null,
  productNameOneLine = false,
  productNameWidth = null,
  menuItemNames = [],
  menuItems = [],
  sections = [],
  dataTestId = '',
  customTheme = {},
  initialSelectedItem = null,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);

  useEffect(() => setSelectedItem(initialSelectedItem), [initialSelectedItem]);

  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const hasContent =
    menuItemNames.length || menuItems.length || sections.length;

  const itemStyles = {
    backgroundColor: neutral150,
    hoverBackgroundColor: neutral150,
    textColor: white,
    dotColor: white,
  };

  const handleMenuItemClick = (event, state) => {
    setIsMenuOpen(false);
    setSelectedItem(state.activeItem);
    onMenuItemClick(event, {
      isMenuOpen: false,
      ...state,
    });
  };

  const toggleMenu = (event, forceState) => {
    const menuWasOpen = isMenuOpen;
    const menuIsNowOpen = forceState !== undefined ? forceState : !isMenuOpen;
    setIsMenuOpen(menuIsNowOpen);
    const newState = {
      isMenuOpen: menuIsNowOpen,
      activeItem: selectedItem,
    };
    if (menuIsNowOpen) {
      onOpen(event.persist(), newState);
      onOpenClose(event.persist(), newState);
    } else if (!menuIsNowOpen && menuWasOpen) {
      onClose(event, newState);
      onOpenClose(event, newState);
    }
  };

  const generateTitleContent = () => {
    const productNameWords = currentProductName.split(' ');

    if (productNameWidth !== null) {
      return (
        <Tooltip
          tooltipContent={currentProductName}
          domID="product-name-tooltip"
          hideTooltip={!isNameTruncated}
          isButtonRoleDisabled
          tooltipPosition="bottom-center"
          disablePointerEvents
        >
          <Truncate
            lines={2}
            width={productNameWidth}
            trimWhitespace
            onTruncate={(isTruncated) => setIsNameTruncated(isTruncated)}
          >
            {currentProductName}
          </Truncate>
        </Tooltip>
      );
    }

    if (productNameWords.length === 1 || productNameOneLine) {
      return <div>{currentProductName}</div>;
    }

    if (productNameWords.length === 2) {
      return (
        <>
          <div>{productNameWords[0]}</div>
          <div>{productNameWords[1]}</div>
        </>
      );
    }

    const middleIndex = Math.ceil(currentProductName.length / 2);
    const firstSpaceOfSecondString = currentProductName
      .substring(middleIndex)
      .indexOf(' ');

    // Very long last word (relative to first words)
    if (firstSpaceOfSecondString === -1) {
      const lastSpaceIndex = currentProductName.lastIndexOf(' ');
      const newFirstWord = currentProductName.substring(0, lastSpaceIndex);
      const newSecondWord = currentProductName.substring(lastSpaceIndex);

      return (
        <>
          <div>{newFirstWord}</div>
          <div>{newSecondWord}</div>
        </>
      );
    }

    const firstSpaceAfterMiddleIndex =
      currentProductName.substring(middleIndex).indexOf(' ') + middleIndex;
    const firstWord = currentProductName.substring(
      0,
      firstSpaceAfterMiddleIndex,
    );
    const secondWord = currentProductName.substring(firstSpaceAfterMiddleIndex);

    return (
      <>
        <div>{firstWord}</div>
        <div>{secondWord}</div>
      </>
    );
  };

  return (
    <Wrapper
      data-testid={`${dataTestId}-wrapper`}
      role="navigation"
      aria-label="Product Menu"
    >
      <MenuClickTarget
        onClick={(e) => toggleMenu(e, undefined)}
        disabled={!hasContent}
        customTheme={customTheme}
      >
        <VerticalDivider color={customTheme.product.dividerColor} />
        <ProductName color={customTheme.color}>
          {generateTitleContent()}
        </ProductName>

        {hasContent ? (
          <ViewCaret>
            <Down
              title="Product switch arrow"
              domID="masthead-product-switcher-icon"
              size="small"
              fillColor={customTheme.color}
            />
          </ViewCaret>
        ) : null}
      </MenuClickTarget>
      {isMenuOpen ? (
        <StandardMenu
          dataTestId={dataTestId}
          menuItemNames={menuItemNames}
          menuItems={menuItems}
          itemStyles={itemStyles}
          sections={sections}
          selectedItems={selectedItem ? [selectedItem] : null}
          onMenuClick={handleMenuItemClick}
          onClickOut={(e) => toggleMenu(e, false)}
          className="inline-quality-dropdown"
          maxWidth="212px"
          {...(onExploreProductsClick && {
            footerComponent: (
              <MenuFooter
                label="Explore"
                icon={Upsell}
                iconFillColor={primary100}
                className="explore-button"
                onClick={onExploreProductsClick}
              />
            ),
          })}
        />
      ) : null}
    </Wrapper>
  );
};

ProductMenu.propTypes = {
  onOpen: PropTypes.func,
  onOpenClose: PropTypes.func,
  onClose: PropTypes.func,
  currentProductName: PropTypes.string,
  productNameOneLine: PropTypes.bool,
  /** if passed, overrides other product name line settings. Instead, text is set to that width, wraps normally, and is truncated with ellipsis and tooltip after two lines. */
  productNameWidth: PropTypes.number,
  onMenuItemClick: PropTypes.func,
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
    }),
  ),
  dataTestId: PropTypes.string,
  initialSelectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    labelDecoration: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string.isRequired,
        path: PropTypes.string,
        labelDecoration: PropTypes.string,
        isSecondary: PropTypes.bool,
      }),
    ),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  customTheme: PropTypes.object,
  /** When this prop is passed footer is displayed in product switcher. When the footer gets clicked this callback gets triggered */
  onExploreProductsClick: PropTypes.func,
};

export default ProductMenu;
