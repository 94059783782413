import styled from 'styled-components';

export default styled.div`
  &.content {
    &.exited {
      position: absolute;
      left: -9999px;
    }
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
  }

  .SingleDatePicker,
  .DateRangePicker {
    z-index: 1 !important;
  }
`;
