import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import TooltipContentWrapper from './TooltipContentWrapper';

const TooltipTransition = React.forwardRef(
  (
    {
      timeout = 300,
      shouldMountAndUnmount = true,
      onIn,
      children,
      ...otherProps
    },
    ref,
  ) => (
    <Transition
      in={onIn}
      timeout={timeout}
      mountOnEnter={shouldMountAndUnmount}
      unmountOnExit={shouldMountAndUnmount}
      nodeRef={ref}
    >
      {(state) => (
        <TooltipContentWrapper state={state} ref={ref} {...otherProps}>
          {children}
        </TooltipContentWrapper>
      )}
    </Transition>
  ),
);

TooltipTransition.propTypes = {
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node,
  timeout: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  className: PropTypes.string,
  shouldMountAndUnmount: PropTypes.bool,
};

export default TooltipTransition;
