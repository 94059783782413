import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../../../BrandCore/constants/heights';
import Chip from '../../../../Atoms/Chip';

const TimestampWrapper = styled.div`
  height: ${MASTHEAD_HEIGHT}px;
  min-width: 0px;
  flex-basis: 0px;
  flex-grow: 1;
  text-align: right;

  #timestamp {
    margin: ${(MASTHEAD_HEIGHT - 20) / 2}px 1rem;
    background-color: ${(props) => props.theme.BaseColors.NeutralAlt['100']};
  }
`;

export const Timestamp = ({ label }) => (
  <TimestampWrapper>
    <Chip label={label} tooltipPosition="bottom-center" domID="timestamp" />
  </TimestampWrapper>
);

Timestamp.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Timestamp;
