import styled, { css } from 'styled-components';
import { UnstyledButton } from '../../../commonResources/styles/buttons';
import TextStyles from '../../../BrandCore/Text/styles';
import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';
import { SIDEBAR_WIDTH } from '../../../BrandCore/constants/widths';
import StackingContext from '../../../commonResources/stackingContext';
import Caret from '../../../BrandCore/icons/interactive/Caret';
import Borders from '../../../BrandCore/shapes/borders';

export const SidebarWrapper = styled.nav`
  position: fixed;
  top: ${MASTHEAD_HEIGHT}px;
  left: 0;
  bottom: 0;
  display: inline-block; /* necessary to maintain item wrapping in IE11 */
  width: ${SIDEBAR_WIDTH}px;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.BaseColors.NeutralAlt['100']};
  ${StackingContext.NavigationPane};
  text-align: center;
  overflow: visible;

  &.exited,
  &.entering {
    padding: 1.9rem 8px;
  }
`;

export const ListWrapper = styled.ul`
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  margin-right: -17px;
  padding: 0;
  /* prevents huge gutters added in IE11 */
  & > li {
    display: inline-block;
    width: 100%;
    font-size: 0;
  }

  max-height: calc(100vh - ${(props) => (props.isCollapsible ? 74 : 20)}px);
  padding-bottom: ${(props) => (props.isCollapsible ? 74 : 20)}px;
`;

export const ItemWrapper = styled(UnstyledButton)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: ${SIDEBAR_WIDTH}px;
  padding: 10px 5px 12px 5px;
  white-space: normal;

  &.tooltip-wrapper {
    &:focus {
      outline: none;
    }
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    ${Borders.borderRadius4};
  }

  svg path {
    fill: ${({ theme }) => theme.BaseColors.NeutralAlt['30']};
  }

  /* prevents button translation in IE11 */
  & > * {
    position: relative;
  }

  &:hover {
    background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['75']};
  }

  &:active,
  &.selected {
    background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['70']};
  }

  &.selected {
    ${({ theme }) => theme.UIPalette.BoxShadows.Selection.MenuItem};
  }

  &:hover,
  &.selected,
  &:focus-visible {
    svg path {
      fill: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
    }

    span {
      color: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['75']};
    outline: 1px solid
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    outline-offset: -2px;
  }

  ${(props) => {
    if (props.viewMore) {
      return css`
        .icon-wrapper {
          background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['75']};
        }

        svg path {
          fill: ${({ theme }) =>
            theme.UIPalette.Colors.Content.PersistentLight};
        }

        &:hover,
        &:focus-visible {
          background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['75']};

          .icon-wrapper {
            background-color: ${({ theme }) =>
              theme.BaseColors.NeutralAlt['70']};
          }
        }

        &.selected {
          box-shadow: none;

          &:not(:hover) {
            background: none;
          }
        }
      `;
    }
    return css``;
  }}
`;

export const ItemLabel = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal !important; /* to overide prop from button span */
  text-align: center;
  ${TextStyles.xxSmall};
  margin-top: 10px;
  color: ${(props) => props.theme.BaseColors.NeutralAlt['15']};
  width: ${SIDEBAR_WIDTH}px; /* ensures width adherence in IE11 */
`;

export const BackItemButton = styled(ItemWrapper)`
  padding: 0;
  width: 36px;
  height: 36px;
  ${Borders.borderRadius4};
  background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['75']};

  svg path {
    fill: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
  }

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.BaseColors.NeutralAlt['70']};
  }
`;

export const BackItemWrapper = styled.li`
  display: flex !important;
  width: 72px !important;
  height: 68px;
  justify-content: center;
  align-items: center;

  & svg {
    height: 16px;
    width: 16px;
  }
`;

export const StyledCaret = styled(Caret)`
  width: 34px;
  height: 34px;
  transform: rotate(90deg);
`;

export default {
  BackItemWrapper,
  ItemLabel,
  ItemWrapper,
  ListWrapper,
  SidebarWrapper,
  StyledCaret,
};
