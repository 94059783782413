import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { ItemLabel, ItemWrapper } from './StyledComponents';
import Health from '../../../BrandCore/icons/informational/Health';
import Tooltip from '../../../Atoms/Tooltip';

export const SidebarItem = ({
  domID = null,
  className = '',
  dataTestId = null,
  label = '',
  icon: IconComponent = Health,
  isActive = false,
  onClick = () => false,
  viewMore = false,
}) => {
  const theme = useTheme();

  const [iconColor, setIconColor] = useState(theme.BaseColors.NeutralAlt['30']);

  useEffect(() => {
    if (isActive) setIconColor(theme.UIPalette.Colors.Content.PersistentLight);
    if (!isActive) setIconColor(theme.BaseColors.NeutralAlt['30']);
  }, [isActive]);

  const domIDs = {
    icon: domID && `${domID}-icon`,
    label: domID && `${domID}-label`,
  };

  const testIDs = {
    icon: dataTestId && `${dataTestId}-icon`,
    label: dataTestId && `${dataTestId}-label`,
  };

  const classes = [className];
  if (isActive) classes.push('selected');

  const renderItemWrapper = (Icon) => {
    return (
      <ItemWrapper
        id={domID}
        className={classes.join(' ')}
        data-testid={dataTestId}
        onClick={(e) => onClick(e)}
        onMouseEnter={() => setIconColor(theme.BaseColors.NeutralAlt['15'])}
        onMouseLeave={() =>
          !isActive && setIconColor(theme.BaseColors.NeutralAlt['30'])
        }
        viewMore={viewMore}
      >
        <Icon />
        {label ? (
          <ItemLabel id={domIDs.label} data-testid={testIDs.label}>
            {label}
          </ItemLabel>
        ) : null}
      </ItemWrapper>
    );
  };

  const renderItemView = () => {
    const labelDiv = document.getElementById(domIDs.label);
    const showTooltip = labelDiv?.offsetHeight < labelDiv?.scrollHeight;

    return (
      <Tooltip
        tooltipPosition="right-center"
        tooltipWidth={120}
        tooltipContent={viewMore ? 'View More' : label}
        caretToAnchor={10}
        hideTooltip={!viewMore && !showTooltip}
        isButtonRoleDisabled
        style={{ width: '100%' }}
      >
        {renderItemWrapper(() => (
          <div className="icon-wrapper">
            <IconComponent domId={domIDs.icon} fillColor={iconColor} />
          </div>
        ))}
      </Tooltip>
    );
  };

  return <li>{renderItemView()}</li>;
};

SidebarItem.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** Title of the Product Section */
  label: PropTypes.string.isRequired,
  /** Icon representing the Product Section. Should be a ui-core icon component */
  icon: PropTypes.func.isRequired,
  /** if `true`, the item is the currently selected menu item */
  isActive: PropTypes.bool,
  /** takes the event as a parameter */
  onClick: PropTypes.func,
  viewMore: PropTypes.bool,
};

export default SidebarItem;
