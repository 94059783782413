import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '../../MenuItem';

const DropdownOption = ({
  innerProps,
  label,
  isDisabled,
  isSelected,
  data: { icon = null },
  isFocused,
}) => (
  <MenuItem
    name={label}
    disabled={isDisabled}
    onClick={innerProps.onClick}
    isActive={isSelected}
    icon={icon}
    isFocused={isFocused}
    maxWidth="100%"
  />
);

DropdownOption.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  innerProps: PropTypes.object,
  data: PropTypes.object,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
};

export default DropdownOption;
