import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ChartEdit = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ChartEdit'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.25 0.5C2.86929 0.5 1.75 1.61929 1.75 3V21C1.75 22.3807 2.86929 23.5 4.25 23.5H4.875H6.75H7.21875C7.49489 23.5 7.71875 23.2761 7.71875 23C7.71875 22.7239 7.49489 22.5 7.21875 22.5H6.75H4.875H4.25C3.42157 22.5 2.75 21.8284 2.75 21V3C2.75 2.17157 3.42157 1.5 4.25 1.5H18.25C19.0784 1.5 19.75 2.17157 19.75 3V6.25V7.15625C19.75 7.43239 19.9739 7.65625 20.25 7.65625C20.5261 7.65625 20.75 7.43239 20.75 7.15625V6.25V3V2.90625C20.75 2.88069 20.7481 2.85557 20.7444 2.83104C20.6575 1.52912 19.5739 0.5 18.25 0.5H4.25ZM19.1036 9.39645C18.9083 9.20118 18.5917 9.20118 18.3964 9.39645L10.3964 17.3964C10.3324 17.4605 10.2869 17.5408 10.2649 17.6287L9.26493 21.6287C9.22233 21.7991 9.27226 21.9794 9.39645 22.1036C9.52064 22.2277 9.70088 22.2777 9.87127 22.2351L13.8713 21.2351C13.9592 21.2131 14.0395 21.1676 14.1036 21.1036L22.1036 13.1036C22.2988 12.9083 22.2988 12.5917 22.1036 12.3964L19.1036 9.39645ZM10.4372 21.0628L11.2015 18.0056L18.75 10.4571L21.0429 12.75L13.4944 20.2985L10.4372 21.0628Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ChartEdit'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 0C1.89544 0 1 0.895429 1 2V14C1 15.1046 1.89544 16 3 16H7.50001C7.77615 16 8.00001 15.7761 8.00001 15.5C8.00001 15.2239 7.77615 15 7.50001 15H3.00001C2.44772 15 2.00001 14.5523 2.00001 14V2C2.00001 1.44772 2.44772 1 3.00001 1H13C13.5523 1 14 1.44772 14 2V5.5C14 5.77614 14.2239 6 14.5 6C14.7761 6 15 5.77614 15 5.5V2C15 0.895431 14.1046 0 13 0H3ZM13.3379 7.08508C13.1427 6.88981 12.8261 6.88981 12.6308 7.08508L8.58916 11.1267C8.52508 11.1908 8.47962 11.2711 8.45764 11.359L7.95243 13.3799C7.90983 13.5502 7.95976 13.7305 8.08395 13.8547C8.20814 13.9789 8.38838 14.0288 8.55877 13.9862L10.5796 13.481C10.6675 13.459 10.7478 13.4136 10.8119 13.3495L14.8536 9.30781C15.0488 9.11255 15.0488 8.79596 14.8536 8.6007L13.3379 7.08508ZM9.12469 12.8139L9.39419 11.7359L12.9844 8.14574L13.7929 8.95425L10.2027 12.5444L9.12469 12.8139Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ChartEdit'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V11.5C1 11.7761 1.22386 12 1.5 12H6.5C6.77614 12 7 11.7761 7 11.5C7 11.2239 6.77614 11 6.5 11H2V1H10V3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5V0.5C11 0.223858 10.7761 0 10.5 0H1.5ZM10.6817 4.89645C10.4864 4.70118 10.1698 4.70118 9.97457 4.89645L7.04488 7.82613C6.99 7.88102 6.94864 7.94793 6.9241 8.02157L6.33816 9.77939C6.27827 9.95905 6.32503 10.1571 6.45895 10.2911C6.59286 10.425 6.79095 10.4717 6.97061 10.4118L8.72843 9.8259C8.80207 9.80136 8.86898 9.76 8.92387 9.70512L11.8536 6.77543C12.0488 6.58017 12.0488 6.26358 11.8536 6.06832L10.6817 4.89645ZM7.60307 9.14693L7.83545 8.44978L10.3281 5.95711L10.7929 6.42188L8.30022 8.91455L7.60307 9.14693Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ChartEdit.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ChartEdit;
