import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.label-only {
    flex-direction: column;
  }

  .inactive {
    color: ${(props) =>
      props.isGraphicActive
        ? props.theme.UIPalette.Colors.Content.Secondary
        : ''};
  }
`;
