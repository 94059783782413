import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import NavigationPaneHeader from '../../Atoms/NavigationPaneHeader/NavigationPaneHeader';
import { NAVIGATION_PANE_FADE } from '../../BrandCore/constants/timings';
import { NAVIGATION_PANE_WIDTH } from '../../BrandCore/constants/widths';
import { warnAbout } from '../../commonResources/helpers/deprecationWarning';
import ExpandButton from './components/ExpandButton';
import CollapsedTitle from './components/CollapsedTitle';
import ExpandCollapseTransition from './components/ExpandCollapseTransition';
import FadeTransition from './components/FadeTransition';

export const NavigationPane = ({
  title = null,
  headerProps = null,
  children = null,
  domID = null,
  animStates = null,
  animState = 0,
  isSidebarVisible = false,
  isTitleSticky = false,
  titleZIndex = 10,
  advanceAnimation = () => false,
  retreatAnimation = () => false,
  onExpandButtonClicked = () => false,
  isEditing = false,
  dataTestId = '',
  className = '',
}) => {
  const { BaseColors } = useTheme();
  const sidebarClassName = isSidebarVisible ? 'sidebar-visible ' : '';
  const collapsedButtonClassName =
    animStates && animState === animStates.COLLAPSED
      ? `${sidebarClassName}active`
      : `${sidebarClassName}`;

  if (title) {
    warnAbout(
      'title prop of NavigationPane will be deprecated in next major release. Plese use headerProps instead.',
    );
  }

  const testIDs = {
    collapsedPane: dataTestId ? `${dataTestId}-collapsed-pane` : '',
    expandedNavigationPane: dataTestId ? `${dataTestId}-expanded-pane` : '',
  };

  return (
    <ExpandCollapseTransition
      id={domID}
      onIn={animStates ? animState < animStates.EXPAND_COLLAPSE : true}
      onExited={() => {
        advanceAnimation();
      }}
      onEntered={() => {
        retreatAnimation();
      }}
      isSidebarVisible={isSidebarVisible}
      collapsed={animStates ? animStates === animStates.EXPAND_COLLAPSE : false}
      hideScroller={animStates ? animState !== animStates.EXPANDED : false}
      dataTestId={dataTestId}
      className={className}
    >
      <FadeTransition
        onIn={animStates ? animState === animStates.COLLAPSED : false}
        onExited={(e) => retreatAnimation(e)}
        timeout={{
          enter: NAVIGATION_PANE_FADE,
          exit: 0,
        }}
      >
        <ExpandButton
          className={collapsedButtonClassName}
          onClick={(e) => onExpandButtonClicked(e)}
          data-test-id={testIDs.collapsedPane}
        >
          <CollapsedTitle>{title}</CollapsedTitle>
          {title && headerProps?.icon && (
            <headerProps.icon
              fillColor={BaseColors.Primary['100']}
              size="small"
            />
          )}
        </ExpandButton>
      </FadeTransition>

      <FadeTransition
        onIn={animStates ? animState === animStates.EXPANDED : true}
        onExited={() => advanceAnimation()}
        className="content"
      >
        <div
          style={{
            marginBottom: isEditing ? 50 : '',
            paddingLeft: 0,
            width: NAVIGATION_PANE_WIDTH,
          }}
          role="navigation"
          aria-label={title}
          data-testid={testIDs.expandedNavigationPane}
        >
          {title || headerProps ? (
            <NavigationPaneHeader
              zIndex={titleZIndex}
              className={isTitleSticky ? 'sticky' : ''}
              title={title}
              {...headerProps}
            />
          ) : null}
          {children}
        </div>
      </FadeTransition>
    </ExpandCollapseTransition>
  );
};

export const NavigationPanePropTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  animStates: PropTypes.shape({
    EXPANDED: PropTypes.number,
    FADE_CONTENT: PropTypes.number,
    EXPAND_COLLAPSE: PropTypes.number,
    COLLAPSED: PropTypes.number,
  }),
  animState: PropTypes.number,
  isSidebarVisible: PropTypes.bool,
  isTitleSticky: PropTypes.bool,
  titleZIndex: PropTypes.number,
  advanceAnimation: PropTypes.func,
  retreatAnimation: PropTypes.func,
  onExpandButtonClicked: PropTypes.func,
  /** If true will add 50px margin at the bottom to allow space for fixed form footer */
  isEditing: PropTypes.bool,
  /** Props that get passed to NavigationPaneHeader component */
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: PropTypes.shape({
    title: PropTypes.string,
    domID: PropTypes.string,
    // eslint-disable-next-line consistent-return
    icon: (props, propName, componentName) => {
      if (props.icon && !props.title) {
        return new Error(
          `if 'Icon' is present then 'title' is required by '${componentName}' component.`,
        );
      }
    },
    /** Padding to add to the  */
    paddingLeft: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    searchInputProps: PropTypes.object,
  }),
};
NavigationPane.propTypes = NavigationPanePropTypes;

export default NavigationPane;
