import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Suites = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Suites'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.27638 1.55291C7.41714 1.48253 7.58283 1.48253 7.72359 1.55291L12.5 3.9411L17.2764 1.55291C17.4171 1.48253 17.5828 1.48253 17.7236 1.55291L22.7191 4.05066C22.8074 4.0936 22.8837 4.16275 22.9351 4.25379C22.9393 4.26122 22.9433 4.2688 22.9472 4.27652C22.9529 4.28782 22.958 4.29924 22.9628 4.31075C22.9894 4.37568 23.0014 4.44346 23 4.51014V11.0001C23 11.1895 22.893 11.3626 22.7236 11.4473L18 13.8091V20.0001C18 20.1895 17.893 20.3626 17.7236 20.4473L12.7326 22.9428C12.6703 22.9756 12.6001 22.9957 12.5257 22.9995C12.5172 22.9999 12.5086 23.0001 12.5 23.0001C12.4902 23.0001 12.4804 22.9998 12.4708 22.9993C12.3976 22.9951 12.3287 22.9751 12.2673 22.9428L7.27638 20.4473C7.10699 20.3626 6.99999 20.1895 6.99999 20.0001V13.8091L2.27638 11.4473C2.10699 11.3626 1.99999 11.1895 1.99999 11.0001V4.51018C1.99845 4.437 2.01301 4.36248 2.04541 4.29187C2.04898 4.28409 2.05274 4.2764 2.0567 4.26882C2.10805 4.17034 2.18783 4.09593 2.2809 4.05065L7.27638 1.55291ZM7.99999 12.6911L12 10.6911V5.30914L7.99999 7.30914V12.6911ZM13 10.6911L17 12.6911V7.30914L13 5.30914V10.6911ZM7.99999 14.3091V19.6911L12 21.6911V16.3091L7.99999 14.3091ZM13 21.6911L17 19.6911V14.3091L13 16.3091V21.6911ZM16.382 13.5001L12.5 11.5591L8.61802 13.5001L12.5 15.4411L16.382 13.5001ZM22 10.6911L18 12.6911V7.30914L22 5.30914V10.6911ZM13.618 4.50012L17.5 6.4411L21.382 4.50012L17.5 2.55914L13.618 4.50012ZM6.99999 7.30914V12.6911L2.99999 10.6911V5.30914L6.99999 7.30914ZM7.49999 2.55914L11.382 4.50012L7.49999 6.4411L3.61802 4.50012L7.49999 2.55914Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Suites'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.44306 0.719458C4.58382 0.649076 4.74951 0.649076 4.89027 0.719458L7.99999 2.27432L11.1097 0.719458C11.2505 0.649076 11.4162 0.649076 11.5569 0.719458L14.8861 2.38405C14.9775 2.42862 15.0561 2.50131 15.1074 2.59732L15.1139 2.60974C15.1185 2.61893 15.1228 2.6282 15.1267 2.63753C15.1553 2.70443 15.1681 2.77451 15.1667 2.84341V7.16667C15.1667 7.35606 15.0597 7.52919 14.8903 7.61388L11.8333 9.14235V13.1667C11.8333 13.3561 11.7263 13.5292 11.5569 13.6139L8.232 15.2764C8.1698 15.309 8.09989 15.3289 8.02572 15.3327C8.0172 15.3331 8.00862 15.3333 7.99999 15.3333C7.99461 15.3333 7.98923 15.3333 7.98385 15.3331C7.9058 15.3306 7.83226 15.3102 7.7672 15.2759L4.44307 13.6139C4.27368 13.5292 4.16667 13.3561 4.16667 13.1667V9.14236L1.10972 7.61388C0.940332 7.52919 0.833331 7.35606 0.833331 7.16667V2.8434C0.831825 2.77159 0.845817 2.6985 0.876957 2.62906C0.881779 2.61829 0.886977 2.60771 0.89254 2.59732C0.94388 2.50132 1.02244 2.42864 1.11384 2.38407L4.44306 0.719458ZM5.16667 8.02432L7.49999 6.85766V3.64237L5.16667 4.80902V8.02432ZM5.16667 9.64236V12.8577L7.49999 14.0243V10.809L5.16667 9.64236ZM8.49999 14.0243L10.8333 12.8577V9.64236L8.49999 10.809V14.0243ZM7.99999 7.72569L5.7847 8.83334L8.00001 9.94099L10.2153 8.83335L7.99999 7.72569ZM10.8333 8.02433V4.80903L8.5 3.64237V6.85766L10.8333 8.02433ZM14.1667 6.85765L11.8333 8.02432V4.80902L14.1667 3.64236V6.85765ZM13.5486 2.83334L11.3333 1.72569L9.11802 2.83334L11.3333 3.94099L13.5486 2.83334ZM4.16667 4.80903V8.02433L1.83333 6.85765V3.64236L4.16667 4.80903ZM4.66666 1.72569L6.88197 2.83334L4.66666 3.94099L2.45136 2.83334L4.66666 1.72569Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Suites'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.29876 0.472511C3.42544 0.409167 3.57456 0.409167 3.70125 0.472511L6 1.62189L8.29876 0.472511C8.42544 0.409167 8.57456 0.409167 8.70125 0.472511L11.1972 1.72047C11.2809 1.76122 11.3527 1.82819 11.399 1.91683L11.4025 1.92376C11.4065 1.93168 11.4102 1.93966 11.4136 1.94769C11.4396 2.00821 11.4513 2.07166 11.45 2.13403V5.375C11.45 5.54545 11.3537 5.70127 11.2012 5.77749L8.95 6.90312V9.875C8.95 10.0455 8.8537 10.2013 8.70125 10.2775L6.20937 11.5234C6.14764 11.5559 6.07742 11.5745 6.00291 11.575C6.00194 11.575 6.00097 11.575 6 11.575C5.99903 11.575 5.99806 11.575 5.99709 11.575C5.92258 11.5745 5.85237 11.5559 5.79063 11.5234L3.29876 10.2775C3.1463 10.2013 3.05 10.0455 3.05 9.875V6.90312L0.798756 5.77749C0.646304 5.70127 0.550002 5.54545 0.550002 5.375V2.13406C0.548622 2.06819 0.561725 2.00113 0.590886 1.93758C0.594096 1.93057 0.597484 1.92366 0.601044 1.91683C0.647256 1.8282 0.719063 1.76123 0.802827 1.72048L3.29876 0.472511ZM3.95 5.89689L5.55 5.09689V2.85312L3.95 3.65312V5.89689ZM6.45 5.09689L8.05 5.89689V3.65312L6.45 2.85312V5.09689ZM3.95 7.35312V9.59689L5.55 10.3969V8.15312L3.95 7.35312ZM6.45 10.3969L8.05 9.59689V7.35312L6.45 8.15312V10.3969ZM7.49377 6.625L6 5.87812L4.50623 6.625L6 7.37189L7.49377 6.625ZM10.55 5.09689L8.95 5.89689V3.65312L10.55 2.85312V5.09689ZM7.00623 2.125L8.5 2.87189L9.99377 2.125L8.5 1.37812L7.00623 2.125ZM3.05 3.65312V5.89689L1.45 5.09689V2.85312L3.05 3.65312ZM3.5 1.37812L4.99377 2.125L3.5 2.87189L2.00623 2.125L3.5 1.37812Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Suites.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Suites;
