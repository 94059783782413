import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Diamond100 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  strokeColor = null,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond 100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M23.126 11.276L12.7251 0.875167C12.3247 0.474682 11.6753 0.474682 11.2749 0.875167L0.874033 11.276C0.473548 11.6765 0.473548 12.3258 0.874033 12.7263L11.2749 23.1271C11.6753 23.5276 12.3247 23.5276 12.7251 23.1271L23.126 12.7263C23.5265 12.3258 23.5265 11.6765 23.126 11.276Z"
            fill={fillColor}
            stroke={strokeColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond 100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M15.3333 7.33333L8.66667 0.666667C8.29848 0.298477 7.70152 0.298477 7.33333 0.666667L0.666667 7.33333C0.298477 7.70152 0.298477 8.29848 0.666667 8.66667L7.33333 15.3333C7.70152 15.7015 8.29848 15.7015 8.66667 15.3333L15.3333 8.66667C15.7015 8.29848 15.7015 7.70152 15.3333 7.33333Z"
            fill={fillColor}
            stroke={strokeColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond100'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M5.29289 0.707111C5.68342 0.316587 6.31658 0.316587 6.70711 0.707111L11.2929 5.29289C11.6834 5.68342 11.6834 6.31658 11.2929 6.70711L6.70711 11.2929C6.31658 11.6834 5.68342 11.6834 5.29289 11.2929L0.707106 6.70711C0.316582 6.31658 0.316583 5.68342 0.707107 5.29289L5.29289 0.707111Z"
            fill={fillColor}
            stroke={strokeColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Diamond100.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Diamond100;
