import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const RadioCheckedSvg = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={theme.BaseColors.Primary['115']} />
    </svg>
  );
};

export default RadioCheckedSvg;
