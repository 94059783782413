export const makeNotificationHeaders = () => {
  const { userAccessToken } = JSON.parse(
    sessionStorage.getItem('notificationsConfig')
  )
  const lithiumSessionKey = sessionStorage.getItem('notificationsSessionKey')

  if (!userAccessToken) return null

  return lithiumSessionKey
    ? {
        Authorization: `Bearer ${userAccessToken}`,
        'X-Li-Session-Key': lithiumSessionKey
      }
    : {
        Authorization: `Bearer ${userAccessToken}`,
        'X-Li-Authorization': 'Basic dWljbC1jb21tdW5pdHktdGVzdDpwYXNzd29yZA=='
      }
}
