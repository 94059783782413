import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TextStyles from '../../BrandCore/Text/styles';
import { UnstyledButton } from '../../commonResources/styles/buttons';

const FooterWrapper = styled(UnstyledButton)`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 14px 24px;
  outline: none;
  border-radius: 0px 0px 8px 8px;
  ${({ theme }) => theme.UIPalette.BoxShadows.General.LineAbove};
  ${TextStyles.xSmall};
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};

  .menu-footer-icon {
    margin-right: 12px;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.BaseColors.Neutral[
        '0'
      ]} !important; /* stylelint-disable-line declaration-no-important */
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.BaseColors.Neutral[
        '5'
      ]} !important; /* stylelint-disable-line declaration-no-important */
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
  }
`;

const MenuFooter = ({
  label = '',
  icon: Icon = null,
  iconFillColor: _iconFillColor = undefined,
  onClick = () => false,
  dataTestId = '',
  className = '',
}) => {
  const theme = useTheme();
  const iconFillColor = useMemo(
    () => _iconFillColor || theme.UIPalette.Colors.Content.Secondary,
    [_iconFillColor, theme],
  );

  return (
    <FooterWrapper
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          size="medium"
          fillColor={iconFillColor}
          className="menu-footer-icon"
        />
      )}
      <span>{label}</span>
    </FooterWrapper>
  );
};

MenuFooter.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.elementType,
  iconFillColor: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

export default MenuFooter;
