import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ThumbsUp = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Thumbs Up'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.25006 0.5C9.02459 0.5 8.82701 0.650911 8.76768 0.868441L5.86816 11.5H1.25006C0.973919 11.5 0.750061 11.7239 0.750061 12V23C0.750061 23.2761 0.973919 23.5 1.25006 23.5H6.25006H18.6761H18.6766C19.512 23.4991 20.3196 23.1994 20.9533 22.6551C21.5871 22.1108 22.0053 21.3577 22.1322 20.532L22.1323 20.532L23.2092 13.532L23.2093 13.532C23.2859 13.0333 23.2539 12.5239 23.1153 12.0387C22.9767 11.5535 22.7348 11.104 22.4063 10.721C22.0777 10.3381 21.6702 10.0307 21.2118 9.81991C20.7533 9.60915 20.2547 9.50002 19.7501 9.5H19.7501H12.7501V4C12.7501 3.07174 12.3813 2.1815 11.7249 1.52513C11.0686 0.868749 10.1783 0.5 9.25006 0.5ZM5.75006 22.5V12.5H1.75006V22.5H5.75006ZM6.75006 12.067L9.62428 1.52816C10.1488 1.60756 10.6381 1.85252 11.0178 2.23223C11.4867 2.70107 11.7501 3.33696 11.7501 4V10C11.7501 10.2761 11.9739 10.5 12.2501 10.5H19.75H19.7501C20.1105 10.5 20.4666 10.578 20.7941 10.7285C21.1216 10.879 21.4126 11.0986 21.6473 11.3722C21.882 11.6457 22.0548 11.9668 22.1538 12.3133C22.2528 12.6599 22.2757 13.0237 22.2209 13.38L22.2209 13.38L21.1439 20.38L21.1439 20.38C21.0532 20.9698 20.7545 21.5077 20.3018 21.8965C19.8491 22.2853 19.2723 22.4994 18.6755 22.5H6.75006V12.067Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Thumbs Up'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#thumbs-up)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M6.49978 0C6.29136 0 6.1048 0.129286 6.03161 0.324438L3.15328 8H0.499779C0.223636 8 -0.000221252 8.22386 -0.000221252 8.5V15.5C-0.000221252 15.7761 0.223636 16 0.499779 16H3.49978H12.1098C13.2321 16 14.2183 15.2515 14.5185 14.1688L14.5186 14.1685L15.9075 9.16886C16.3501 7.57626 15.1525 6 13.4988 6H8.99978V1.5C8.99978 0.671858 8.32792 0 7.49978 0H6.49978ZM2.99978 15V9H0.999779V15H2.99978ZM3.99978 8.59067L6.84628 1H7.49978C7.77564 1 7.99978 1.22414 7.99978 1.5V6.5C7.99978 6.77614 8.22364 7 8.49978 7H13.4988C14.4911 7 15.2095 7.94574 14.944 8.90114L14.944 8.90117L13.555 13.9012L13.5549 13.9015C13.375 14.5506 12.7834 15 12.1098 15H3.99978V8.59067Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="thumbs-up">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Thumbs Up'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.61497 0C4.39976 0 4.20869 0.137715 4.14063 0.341886L2.2546 6H0.614975C0.338833 6 0.114975 6.22386 0.114975 6.5V11.5C0.114975 11.7761 0.338833 12 0.614975 12H2.61497H9.31497H9.31529C9.79653 11.9997 10.2615 11.8259 10.625 11.5104C10.9884 11.195 11.2259 10.7591 11.294 10.2827L11.865 6.28267C11.9055 5.99901 11.8845 5.70996 11.8036 5.43509C11.7227 5.16022 11.5836 4.90595 11.3959 4.6895C11.2081 4.47305 10.976 4.29947 10.7154 4.18051C10.4547 4.06155 10.1715 3.99999 9.88498 4H9.88496H7.11497V2C7.11497 1.46957 6.90426 0.960859 6.52919 0.585786C6.15412 0.210714 5.64541 0 5.11497 0H4.61497ZM2.11497 11V7H1.11497V11H2.11497ZM3.11497 6.58114L4.97535 1H5.11497C5.38019 1 5.63455 1.10536 5.82208 1.29289C6.00962 1.48043 6.11497 1.73478 6.11497 2V4.5C6.11497 4.77614 6.33883 5 6.61497 5H9.88498H9.88499C10.0283 4.99999 10.1699 5.03077 10.3002 5.09025C10.4305 5.14973 10.5466 5.23652 10.6404 5.34475C10.7343 5.45298 10.8038 5.58011 10.8443 5.71755C10.8848 5.85498 10.8952 5.9995 10.875 6.14133L10.304 10.1413L10.304 10.1413C10.27 10.3795 10.1512 10.5975 9.96951 10.7552C9.78778 10.9129 9.55528 10.9998 9.31466 11H3.11497V6.58114Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ThumbsUp.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ThumbsUp;
