import styled from 'styled-components';
import { borderHelpers } from '../../../BrandCore/shapes/borders';

export default styled.button`
  background: ${(props) => props.background || 'transparent'};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  padding: 4px;
  ${borderHelpers.borderRadius6};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px
      ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
  }

  &:disabled {
    cursor: default;
  }

  &:active:not(:disabled) {
    background: ${(props) => props.theme.BaseColors.Neutral['5']};
  }

  &.xSmall,
  &.small {
    padding: 2px;
  }

  &.xSmall {
    ${borderHelpers.borderRadius3};
  }

  &.small {
    ${borderHelpers.borderRadius4};
  }

  &.medium {
    ${borderHelpers.borderRadius6};
  }

  &.large {
    ${borderHelpers.borderRadius8};
  }
`;
