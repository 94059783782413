import React from 'react';
import PropTypes from 'prop-types';

import ExpandCollapseTransition from './ExpandCollapseTransition';
import FadeTransition from '../common/FadeTransition';

import { DETAIL_PANE_FADE } from '../../../../BrandCore/constants/timings';
import { useLayout } from '../..';
import { detailPaneAnimStates } from '../../commonResources/animStates';

export const DetailPane = ({
  navigationPanePadding = 0,
  onEntered = () => false,
  onExited = () => false,
  children = null,
  className = '',
  expandWidth = '',
}) => {
  const { detailAnimationState } = useLayout();

  return (
    <ExpandCollapseTransition
      onIn={
        detailPaneAnimStates
          ? detailAnimationState >= detailPaneAnimStates.EXPANDED
          : false
      }
      fullExpand={detailAnimationState >= detailPaneAnimStates.FULL_EXPAND}
      onEntered={onEntered}
      onExited={onExited}
      navigationPanePadding={navigationPanePadding}
      className={className}
      expandWidth={expandWidth}
    >
      <FadeTransition
        onIn={
          detailPaneAnimStates
            ? detailAnimationState >= detailPaneAnimStates.FADE_CONTENT
            : false
        }
        onExited={() => false}
        onEntered={() => false}
        timeout={{
          enter: DETAIL_PANE_FADE,
          exit: 0,
        }}
      >
        {children}
      </FadeTransition>
    </ExpandCollapseTransition>
  );
};

DetailPane.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navigationPanePadding: PropTypes.number,
  /**
   * Call back function to execute when detail pane closes.
   */
  onExited: PropTypes.func,
  /**
   * Callback function to execute when detail pane opens (Slides from right)
   */
  onEntered: PropTypes.func,
  expandWidth: PropTypes.string,
};

export default DetailPane;
