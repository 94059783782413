import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Acheive = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Archive'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1H8.5H16.5H17V2H16.5H8.5H8V1ZM5.5 5H5V6H5.5H19.5H20V5H19.5H5.5ZM2.5 9H2V9.5V23.5V24H2.5H22.5H23V23.5V9.5V9H22.5H2.5ZM3 23V10H22V23H3ZM9 14.5V14H8V14.5V16.5V17H8.5H16.5H17V16.5V14.5V14H16V14.5V16H9V14.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Archive'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 0.533356C2 0.257213 2.22386 0.0333557 2.5 0.0333557H13.5C13.7761 0.0333557 14 0.257213 14 0.533356C14 0.809498 13.7761 1.03336 13.5 1.03336H2.5C2.22386 1.03336 2 0.809498 2 0.533356ZM4.5 6.43336C4.77614 6.43336 5 6.65721 5 6.93336V8.56669H11V6.93336C11 6.65721 11.2239 6.43336 11.5 6.43336C11.7761 6.43336 12 6.65721 12 6.93336V9.06669C12 9.34283 11.7761 9.56669 11.5 9.56669H4.5C4.22386 9.56669 4 9.34283 4 9.06669V6.93336C4 6.65721 4.22386 6.43336 4.5 6.43336ZM0.5 3.23336C0.223858 3.23336 0 3.45721 0 3.73336V15.4667C0 15.7428 0.223858 15.9667 0.5 15.9667H15.5C15.7761 15.9667 16 15.7428 16 15.4667V3.73336C16 3.45721 15.7761 3.23336 15.5 3.23336H0.5ZM1 14.9667V4.23336H15V14.9667H1Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Acheive'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2 0.5C2 0.223858 2.22386 0 2.5 0H9.5C9.77614 0 10 0.223858 10 0.5C10 0.776142 9.77614 1 9.5 1H2.5C2.22386 1 2 0.776142 2 0.5ZM0 3.5C0 3.22386 0.223858 3 0.5 3H11.5C11.7761 3 12 3.22386 12 3.5V11.5C12 11.7761 11.7761 12 11.5 12H0.5C0.223858 12 0 11.7761 0 11.5V3.5ZM1 4V11H11V4H1ZM4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H7.5C7.77614 7 8 6.77614 8 6.5C8 6.22386 7.77614 6 7.5 6H4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Acheive.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Acheive;
