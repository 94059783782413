import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';
import Toggle from '../../../Atoms/Toggle';

const FirstLine = styled.div`
  margin-top: 6px;
`;

const ToggleCell = ({
  data: { onToggle = () => false },
  data = {},
  cellAlign = 'left',
}) => {
  const { secondLine = null, ...restOfData } = data;
  return (
    <>
      <FirstLine>
        <Toggle {...restOfData} onToggle={(e) => onToggle(e, data)} />
      </FirstLine>
      {secondLine && (
        <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
      )}
    </>
  );
};

ToggleCell.propTypes = {
  data: PropTypes.shape({
    altTextForCenterToggle: PropTypes.string,
    disabled: PropTypes.bool,
    domID: PropTypes.string,
    initialValue: PropTypes.bool,
    label: PropTypes.string,
    leftLabel: PropTypes.node,
    onToggle: PropTypes.func,
    rightLabel: PropTypes.node,
    secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default ToggleCell;
