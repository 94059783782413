import styled from 'styled-components';
import InputLabel from '../../../Atoms/Input/components/InputLabel';

const CheckboxGroupLabel = styled(InputLabel)`
  margin-bottom: 10px;
  &.error {
    color: ${(props) => props.theme.UIPalette.Colors.Content.Error};
  }
`;

export default CheckboxGroupLabel;
