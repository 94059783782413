import styled from 'styled-components';
import Borders from '../../../BrandCore/shapes/borders';

export default styled.div`
  opacity: 1;
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Background.StandardMenu};
  display: block;
  position: absolute;
  ${({ theme }) => theme.UIPalette.BoxShadows.General.MicroModal}
  ${Borders.borderRadius12};

  > .container {
    ${Borders.borderRadius12};
    overflow: auto;
  }

  &.top {
    bottom: 100%;
    transform: translateX(-50%);
    left: 50%;
  }

  &.right {
    left: 100%;
    transform: translateY(-50%);
    top: 50%;
  }

  &.bottom {
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
  }

  &.left {
    right: 100%;
    transform: translateY(-50%);
    top: 50%;
  }
`;
