import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '../../../BrandCore/Avatar';
import Tooltip from '../../../Atoms/Tooltip';
import TextWrapper from './styleWrappers/TextWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';
import { useIsTruncated } from '../../../commonResources/hooks/useIsTruncated';

const Container = styled.div`
  display: flex;
  padding-top: 6px;
  height: 56px;
  align-items: space-between;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  height: 100%;
  justify-content: space-between;
  margin-left: ${(props) => (props.src !== null ? '7px' : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FirstLine = styled(TextWrapper)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.BaseColors.Primary['100']};
    box-sizing: border-box;
  }
`;

const SecondLine = styled(SecondLineWrapper)`
  display: flex;
  flex-direction: row;

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.BaseColors.Primary['100']};
    box-sizing: border-box;
  }
`;

const SecondLineLeft = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Dot = styled.div`
  margin: 0 4px;
`;

const MultiLineCell = ({
  data: {
    firstLine = '',
    secondLineLeft = '',
    secondLineRight = '',
    src = null,
    maxWidth = null,
    tooltipWidth = 150,
  } = {},
  dataTestId = '',
}) => {
  const firstLineRef = useRef(null);
  const isFirstLineTruncated = useIsTruncated(firstLineRef, [firstLine, src]);

  const secondLineRef = useRef(null);
  const isSecondLineTruncated = useIsTruncated(secondLineRef, [
    secondLineLeft,
    secondLineRight,
    src,
  ]);

  const testIds = {
    avatar: dataTestId ? `${dataTestId}-avatar` : '',
    firstLine: dataTestId ? `${dataTestId}-firstLine` : '',
    secondLine: dataTestId ? `${dataTestId}-secondLine` : '',
  };

  return (
    <Container maxWidth={maxWidth} data-testid={dataTestId}>
      {src !== null && (
        <Avatar variant="xSmall" src={src} dataTestId={testIds.avatar} />
      )}
      <TextContainer>
        <Tooltip
          tooltipContent={firstLine}
          hideTooltip={!isFirstLineTruncated}
          isButtonRoleDisabled
          disablePointerEvents
          tooltipPosition="bottom-center"
          tooltipWidth={tooltipWidth}
        >
          <FirstLine
            tabIndex={isFirstLineTruncated ? 0 : -1}
            ref={firstLineRef}
            data-testid={testIds.firstLine}
          >
            {firstLine}
          </FirstLine>
        </Tooltip>
        <Tooltip
          tooltipContent={`${secondLineLeft} • ${secondLineRight}`}
          hideTooltip={!isSecondLineTruncated}
          isButtonRoleDisabled
          disablePointerEvents
          tooltipPosition="bottom-center"
          tooltipWidth={tooltipWidth}
        >
          <SecondLine
            tabIndex={isSecondLineTruncated ? 0 : -1}
            data-testid={testIds.secondLine}
          >
            <SecondLineLeft ref={secondLineRef}>
              {secondLineLeft}
            </SecondLineLeft>
            {secondLineRight && <Dot>•</Dot>}
            {secondLineRight}
          </SecondLine>
        </Tooltip>
      </TextContainer>
    </Container>
  );
};

MultiLineCell.propTypes = {
  data: PropTypes.shape({
    src: PropTypes.oneOfType([
      PropTypes.string,
      // if not a string, then we have a simple component (function)
      PropTypes.func,
    ]),
    firstLine: PropTypes.string.isRequired,
    secondLineLeft: PropTypes.string,
    secondLineRight: PropTypes.string,
    maxWidth: PropTypes.number,
  }).isRequired,
  dataTestId: PropTypes.string,
};

export default MultiLineCell;
