import styled from 'styled-components';

export default styled.div`
  display: flex;
  min-width: 200px;
  > div {
    width: 100%;
  }
  .detail-header-tile {
    flex-shrink: 0;
  }
`;
