import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Warning = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Warning'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.5 3C12.6856 3 12.856 3.10285 12.9425 3.26713L22.9425 22.2671C23.024 22.4221 23.0186 22.6085 22.928 22.7584C22.8375 22.9084 22.6751 23 22.5 23H2.5C2.32486 23 2.16248 22.9084 2.07196 22.7584C1.98144 22.6085 1.97597 22.4221 2.05754 22.2671L12.0575 3.26713C12.144 3.10285 12.3144 3 12.5 3ZM21.6718 22L12.5 4.57355L3.32818 22H21.6718ZM12.5 11C12.7761 11 13 11.2239 13 11.5V15.5C13 15.7761 12.7761 16 12.5 16C12.2239 16 12 15.7761 12 15.5V11.5C12 11.2239 12.2239 11 12.5 11ZM13.5 18.5C13.5 19.0523 13.0523 19.5 12.5 19.5C11.9477 19.5 11.5 19.0523 11.5 18.5C11.5 17.9477 11.9477 17.5 12.5 17.5C13.0523 17.5 13.5 17.9477 13.5 18.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Warning'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.46959 1.26295C8.37656 1.101 8.1962 1 8 1C7.8038 1 7.62344 1.101 7.53042 1.26295L0.0637489 14.2629C-0.0252486 14.4179 -0.0208015 14.6053 0.0754582 14.7564C0.171718 14.9075 0.345615 15 0.533333 15H15.4667C15.6544 15 15.8283 14.9075 15.9245 14.7564C16.0208 14.6053 16.0252 14.4179 15.9363 14.2629L8.46959 1.26295ZM8 2.55463L14.5738 14H1.42625L8 2.55463ZM8 6C8.3125 6 8.5 6.22386 8.5 6.5V10C8.5 10.2761 8.27614 10.5 8 10.5C7.72386 10.5 7.5 10.2761 7.5 10V6.5C7.5 6.22386 7.6875 6 8 6ZM8 13C8.27614 13 8.5 12.7761 8.5 12.5C8.5 12.2239 8.27614 12 8 12C7.72386 12 7.5 12.2239 7.5 12.5C7.5 12.7761 7.72386 13 8 13Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Warning'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 1C6.17869 1 6.3438 1.09536 6.43309 1.25014L11.9331 10.2501C12.0223 10.4048 12.0223 10.5954 11.933 10.7501C11.8436 10.9047 11.6786 11 11.5 11H0.5C0.321396 11 0.156354 10.9047 0.0670274 10.7501C-0.0222995 10.5954 -0.0223453 10.4048 0.0669072 10.2501L5.56691 1.25014C5.6562 1.09536 5.82131 1 6 1ZM10.6343 10L6 2.50056L1.3657 10H10.6343ZM6 9C6.27614 9 6.5 8.77614 6.5 8.5C6.5 8.22386 6.27614 8 6 8C5.72386 8 5.5 8.22386 5.5 8.5C5.5 8.77614 5.72386 9 6 9ZM6.5 5.5C6.5 5.22386 6.28125 5 6 5C5.71875 5 5.5 5.22386 5.5 5.5V7C5.5 7.27614 5.72386 7.5 6 7.5C6.27614 7.5 6.5 7.27614 6.5 7V5.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Warning.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Warning;
