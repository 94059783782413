import { createContext, useContext, useState } from 'react';

export const NotificationContext = createContext({});

export const useNotificationBannerContext = () => {
  const [message, setMessage] = useState({});

  const notify = (text, type, autoDismiss = true, timeout = 0) => {
    if (!text && !message.text) return;
    setTimeout(() => {
      setMessage({
        text,
        type,
        autoDismiss,
        key: Date.now(),
      });
    }, timeout);
  };

  return {
    message,
    notify,
  };
};

export const useNotificationBanner = () => useContext(NotificationContext);

export default useNotificationBanner;
