import styled from 'styled-components';

export default styled.div`
  position: absolute;
  right: 0px;
  min-width: 289px;
  max-width: 289rem;
  font-size: 13px;
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.StandardMenu};
  ${(props) => props.theme.BoxShadows.Depth15}
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  line-height: 1.2em;
  z-index: 1;

  .inline-user-dropdown {
    width: 100%;
    min-width: 257px;
    border-radius: 8px 0px 8px 8px;
    ${(props) => props.theme.BoxShadows.Depth15}
  }

  .user-menu-footer-button {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    .logout-icon {
      margin-right: 10px;
    }

    &:focus {
      outline: none;
      border-radius: 5px;
      box-shadow: ${(props) =>
        `inset 0 0 0 1px ${props.theme.UIPalette.Colors.Field.Border.Focus}`};
    }
  }
`;
