/**
 * areAllRecordsChecked
 * ========================
 * Determines if all records are checked, taking into account initially
 * disabled and selectedItems
 * @param  {Number} recordsLength
 * @param  {Number} disabledItemIdsLength
 * @param  {Number} selectedItemIdsLength
 * @param  {Number} disabledSelectedItemIdsLength
 * @returns {boolean}
 */
export const areAllRecordsChecked = (
  recordsLength,
  disabledItemIdsLength,
  selectedItemIdsLength,
  disabledSelectedItemIdsLength,
) => {
  return (
    recordsLength - disabledItemIdsLength ===
    selectedItemIdsLength - disabledSelectedItemIdsLength
  );
};

/**
 * getDisabledSelectedItems
 * ========================
 * Determines which grid rows are disabled and selected
 * @param  {Set.<string || number>} initialSelectedItemIds passed as prop to component
 * @param  {Set.<string || number>} initialDisabledItemIds passed as prop to component
 * @returns {Set.<string || number>} creates new Set containing selectionKeys of items which are both disabled and selected
 */
export const getDisabledSelectedItems = (
  initialSelectedItemIds,
  initialDisabledItemIds,
) => {
  const result = new Set([]);
  if (initialSelectedItemIds && initialDisabledItemIds)
    initialSelectedItemIds.forEach((value) => {
      if (initialDisabledItemIds.has(value)) result.add(value);
    });
  return result;
};

/**
 * getIdsForSelectAll
 * ========================
 * if all items are not selected, returns array of ids which should be selected
 * @param  {Object[]} records passed as prop to component
 * @param  {Set.<string || number>} disabledItemIds passed as prop to component
 * @param  {Set.<string || number>} disabledSelectedItems passed as prop to component
 * @param  {String} selectionKey passed as prop to component
 * @returns {String[]} containing ids of records which should be selected
 */
export const getIdsForSelectAll = (
  records,
  disabledItemIds,
  disabledSelectedItems,
  selectionKey,
) => {
  return records
    .filter(
      (r) =>
        !disabledItemIds.has(r[selectionKey]) ||
        disabledSelectedItems.has(r[selectionKey]),
    )
    .map((r) => r[selectionKey]);
};

export default {
  areAllRecordsChecked,
  getDisabledSelectedItems,
  getIdsForSelectAll,
};
