/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * There are two ways to use this component.
 *
 * ... As a wrapper component:
 *
 * import ClickAwayWrapper from '../../commonResources/classes/clickAwayWrapper';
 *
 * class ExampleComponent extends React.Component {
 *
 *   onClickOut(e) {
 *     alert('user clicked outside of the component!');
 *   }
 *
 *   render() {
 *     return (
 *       <ClickAwayWrapper onClickOut={this.onClickOut}>
 *         <div>Click outside of me!</div>
 *       </ClickAwayWrapper>
 *     );
 *   }
 * }
 *
 *
 * ... As a base component:
 *
 * import ClickAwayWrapper from '../../commonResources/classes/clickAwayWrapper';
 *
 * class ExampleComponent extends ClickAwayWrapper {
 *
 *   onClickOut(e) {
 *     alert('user clicked outside of the component!');
 *   }
 *
 *   render() {
 *     return (
 *       <div>Click outside of me!</div>
 *     );
 *   }
 * }
 */

class ClickAwayWrapper extends React.Component {
  componentDidMount() {
    const self = this;
    let elTouchIsClick = true;
    let documentTouchIsClick = true;
    const el = ReactDOM.findDOMNode(this);

    self.__documentTouchStarted = function (e) {
      el.removeEventListener('click', self.__elementClicked);
      document.removeEventListener('click', self.__documentClicked);
    };

    self.__documentTouchMoved = function (e) {
      documentTouchIsClick = false;
    };

    self.__documentTouchEnded = function (e) {
      if (documentTouchIsClick) self.__documentClicked(e);
      documentTouchIsClick = true;
    };

    self.__documentClicked = function (e) {
      if ((e.__clickedElements || []).indexOf(el) !== -1) return;

      const clickOutHandler = self.onClickOut || self.props.onClickOut;
      if (!clickOutHandler) {
        return console.warn('onClickOut is not defined.');
      }

      clickOutHandler.call(self, e);
    };

    self.__elementTouchMoved = function (e) {
      elTouchIsClick = false;
    };

    self.__elementTouchEnded = function (e) {
      if (elTouchIsClick) self.__elementClicked(e);
      elTouchIsClick = true;
    };

    self.__elementClicked = function (e) {
      e.__clickedElements = e.__clickedElements || [];
      e.__clickedElements.push(el);
    };

    setTimeout(() => {
      if (self.__unmounted) return;
      self.toggleListeners('addEventListener');
    }, 0);
  }

  componentWillUnmount() {
    this.toggleListeners('removeEventListener');
    this.__unmounted = true;
  }

  toggleListeners(listenerMethod) {
    const el = ReactDOM.findDOMNode(this);

    el[listenerMethod]('touchmove', this.__elementTouchMoved);
    el[listenerMethod]('touchend', this.__elementTouchEnded);
    el[listenerMethod]('click', this.__elementClicked);

    document[listenerMethod]('touchstart', this.__documentTouchStarted);
    document[listenerMethod]('touchmove', this.__documentTouchMoved);
    document[listenerMethod]('touchend', this.__documentTouchEnded);
    document[listenerMethod]('click', this.__documentClicked);
  }

  render() {
    const { children = null } = this.props;
    return Array.isArray(children) ? (
      <div>{children}</div>
    ) : (
      React.Children.only(children)
    );
  }
}

ClickAwayWrapper.propTypes = {
  children: PropTypes.node,
};

export default ClickAwayWrapper;
