import styled from 'styled-components';
import { NAVIGATION_PANE_EXPAND_COLLAPSE } from '../../../../BrandCore/constants/timings';
import { MASTHEAD_HEIGHT } from '../../../../BrandCore/constants/heights';
import stackingContext from '../../../../commonResources/stackingContext';

export default styled.div`
  margin-top: ${MASTHEAD_HEIGHT}px;
  margin-left: ${(props) => props.navigationPanePadding}px;
  transition: padding ${NAVIGATION_PANE_EXPAND_COLLAPSE}ms ease-in-out;
  min-height: calc(100% - ${MASTHEAD_HEIGHT}px);
  background: ${({ theme }) => theme.UIPalette.Colors.Background.Depth15};
  position: absolute;
  ${stackingContext.navigationPane};
  ${({ theme }) => theme.BoxShadows.Depth15};
`;
