import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Calendar = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Calendar'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.5 1C6.77614 1 7 1.22386 7 1.5V3H17V1.5C17 1.22386 17.2239 1 17.5 1C17.7761 1 18 1.22386 18 1.5V3H20.5C21.8807 3 23 4.11929 23 5.5V8.5V20.5C23 21.8807 21.8807 23 20.5 23H3.5C2.11929 23 1 21.8807 1 20.5V8.5V5.5C1 4.11929 2.11929 3 3.5 3H6V1.5C6 1.22386 6.22386 1 6.5 1ZM17 4V4.5C17 4.77614 17.2239 5 17.5 5C17.7761 5 18 4.77614 18 4.5V4H20.5C21.3284 4 22 4.67157 22 5.5V8H2V5.5C2 4.67157 2.67157 4 3.5 4H6V4.5C6 4.77614 6.22386 5 6.5 5C6.77614 5 7 4.77614 7 4.5V4H17ZM22 9V20.5C22 21.3284 21.3284 22 20.5 22H3.5C2.67157 22 2 21.3284 2 20.5V9H22Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Calendar'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.5 0C4.77614 0 5 0.223858 5 0.5V2H11V0.5C11 0.223858 11.2239 0 11.5 0C11.7761 0 12 0.223858 12 0.5V2H14.5C15.3281 2 16 2.67186 16 3.5V5.5V14.5C16 15.3281 15.3281 16 14.5 16H1.5C0.671858 16 0 15.3281 0 14.5V5.5V3.5C0 2.67186 0.671858 2 1.5 2H4V0.5C4 0.223858 4.22386 0 4.5 0ZM11.5 3H4.5H1.5C1.22414 3 1 3.22414 1 3.5V5H15V3.5C15 3.22414 14.7759 3 14.5 3H11.5ZM1 6H15V14.5C15 14.7759 14.7759 15 14.5 15H1.5C1.22414 15 1 14.7759 1 14.5V6Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Calendar'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 0.5C3 0.223858 2.77614 0 2.5 0C2.22386 0 2 0.223858 2 0.5V2H1.5C0.671573 2 0 2.67157 0 3.5V5.5V10.5C0 11.3284 0.671573 12 1.5 12H10.5C11.3284 12 12 11.3284 12 10.5V5.5V3.5C12 2.67157 11.3284 2 10.5 2H10V0.5C10 0.223858 9.77614 0 9.5 0C9.22386 0 9 0.223858 9 0.5V2H3V0.5ZM9.5 3H2.5H1.5C1.22386 3 1 3.22386 1 3.5V5H11V3.5C11 3.22386 10.7761 3 10.5 3H9.5ZM11 6H1V10.5C1 10.7761 1.22386 11 1.5 11H10.5C10.7761 11 11 10.7761 11 10.5V6Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Calendar.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Calendar;
