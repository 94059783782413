import { primary100 } from './basePalette';

export const BoxShadows = {
  Depth2: `box-shadow: 0px 1px 3px rgba(17, 80, 244, 0.06), 0px 2px 12px rgba(154, 146, 162, 0.16);`,
  Depth5: `box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(166, 175, 197, 0.3);`,
  Depth10: `box-shadow: 0px 1px 3px rgba(17, 80, 244, 0.1), 0px 6px 16px rgba(166, 175, 197, 0.25);`,
  Depth15: `box-shadow: 0px 1px 4px rgba(17, 80, 244, 0.12), 0px 10px 32px rgba(166, 175, 197, 0.35);`,
  SelectedTabBottom: `box-shadow: 0px 2px 0px ${primary100};`,
  InputShadow: `box-shadow: inset 0px 2px 6px rgba(140, 148, 168, 0.16);`,
};

export default BoxShadows;
