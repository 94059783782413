import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Book = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Book'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.5 1.75012C4.93478 1.75012 3.47497 2.08811 2.38828 2.67221C1.31884 3.24703 0.5 4.13048 0.5 5.25012V22.2501H1.5C1.5 21.6698 1.93116 21.0532 2.86172 20.553C3.77503 20.0621 5.06522 19.7501 6.5 19.7501C7.93478 19.7501 9.22497 20.0621 10.1383 20.553C11.0688 21.0532 11.5 21.6698 11.5 22.2501H12.5C12.5 21.6698 12.9312 21.0532 13.8617 20.553C14.775 20.0621 16.0652 19.7501 17.5 19.7501C18.9348 19.7501 20.225 20.0621 21.1383 20.553C22.0688 21.0532 22.5 21.6698 22.5 22.2501H23.5V5.25012C23.5 4.13048 22.6812 3.24703 21.6117 2.67221C20.525 2.08811 19.0652 1.75012 17.5 1.75012C15.9348 1.75012 14.475 2.08811 13.3883 2.67221C12.8305 2.97204 12.3408 3.35583 12 3.81331C11.6592 3.35583 11.1695 2.97204 10.6117 2.67221C9.52503 2.08811 8.06522 1.75012 6.5 1.75012ZM2.38828 19.6722C2.06383 19.8466 1.76245 20.0494 1.5 20.2786V5.25012C1.5 4.66976 1.93116 4.05321 2.86172 3.55303C3.77503 3.06213 5.06522 2.75012 6.5 2.75012C7.93478 2.75012 9.22497 3.06213 10.1383 3.55303C11.0688 4.05321 11.5 4.66976 11.5 5.25012V20.2786C11.2376 20.0494 10.9362 19.8466 10.6117 19.6722C9.52503 19.0881 8.06522 18.7501 6.5 18.7501C4.93478 18.7501 3.47497 19.0881 2.38828 19.6722ZM12.5 20.2786C12.7624 20.0494 13.0638 19.8466 13.3883 19.6722C14.475 19.0881 15.9348 18.7501 17.5 18.7501C19.0652 18.7501 20.525 19.0881 21.6117 19.6722C21.9362 19.8466 22.2376 20.0494 22.5 20.2786V5.25012C22.5 4.66976 22.0688 4.05321 21.1383 3.55303C20.225 3.06213 18.9348 2.75012 17.5 2.75012C16.0652 2.75012 14.775 3.06213 13.8617 3.55303C12.9312 4.05321 12.5 4.66976 12.5 5.25012V20.2786Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Book'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            // eslint-disable-next-line max-len
            d="M11.75 1.5C9.679 1.5 8 2.395 8 3.5C8 2.395 6.321 1.5 4.25 1.5C2.179 1.5 0.5 2.395 0.5 3.5V14.5C0.5 13.395 2.179 12.5 4.25 12.5C6.321 12.5 8 13.395 8 14.5C8 13.395 9.679 12.5 11.75 12.5C13.821 12.5 15.5 13.395 15.5 14.5V3.5C15.5 2.395 13.821 1.5 11.75 1.5Z"
            stroke={fillColor}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Book'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            // eslint-disable-next-line max-len
            d="M11.5 10.31C10.8041 9.71438 9.91595 9.39109 9 9.4C8.41812 9.38735 7.84174 9.51496 7.31958 9.77204C6.79741 10.0291 6.3448 10.4081 6 10.877C5.6552 10.4081 5.20259 10.0291 4.68042 9.77204C4.15826 9.51496 3.58188 9.38735 3 9.4C2.08469 9.3897 1.19662 9.71118 0.5 10.305V2.4C1.19733 1.80799 2.08533 1.4883 3 1.5C3.58184 1.4876 4.15813 1.61532 4.68025 1.87238C5.20238 2.12944 5.65502 2.5083 6 2.977C6.34498 2.5083 6.79762 2.12944 7.31975 1.87238C7.84187 1.61532 8.41816 1.4876 9 1.5C9.91467 1.4883 10.8027 1.80799 11.5 2.4V10.31Z"
            stroke={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Book.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Book;
