export const navigationPaneAnimStates = {
  EXPANDED: 0,
  FADE_CONTENT: 1,
  EXPAND_COLLAPSE: 2,
  COLLAPSED: 3,
};

export const detailPaneAnimStates = {
  COLLAPSED: 0,
  EXPANDED: 1,
  FADE_CONTENT: 2,
  FULL_EXPAND: 3,
};

export default {
  navigationPaneAnimStates,
  detailPaneAnimStates,
};
