import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';
import MenuItem from '../../../Atoms/MenuItem';
import EmptyMessage from './EmptyMessage';
import stackingContext from '../../../commonResources/stackingContext';
import { Right } from '../../../BrandCore/icons/interactive';

const ListWrapper = styled.div`
  display: none;
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Background.StandardMenu};
  ${({ theme }) => theme.BoxShadows.Depth15};
  padding: 12px 0;
  border-radius: 8px;
  white-space: nowrap;
  color: ${({ theme }) => theme.BaseColors.Neutral['100']};
  ${stackingContext.dropdownMain};
  max-width: ${(props) => props.maxWidth};

  &.shown {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
  }
`;

const ArrowWrapper = styled.span`
  position: absolute;
  right: -4px;
  margin-right: 10px;
  flex-shrink: 0;
  width: 11px;

  &:focus-visible {
    outline: none;
    border: none;
    /* stylelint-disable-next-line declaration-no-important */
    box-shadow: inset 0px 0px 0px 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus} !important;
    /* stylelint-disable-next-line declaration-no-important */
    border-radius: 4px !important;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    border: none;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const List = (props) => {
  const {
    menuItems = [],
    isMulti = false,
    onMenuClick = () => false,
    dataTestId = '',
    listItemClassName = '',
    maxWidth = '',
    activeItems = [],
    hideEmptyMessage = false,
    flyoutLimit = 1,
  } = props;
  const { BaseColors } = useTheme();

  const [currentSubListItemIndex, setCurrentSubListItemIndex] = useState(null);

  const handleKeyPress = (event, item) => {
    if (event.keyCode === 0 && item.items && flyoutLimit > 0) {
      if (currentSubListItemIndex !== item.id) {
        setCurrentSubListItemIndex(item.id);
      } else {
        setCurrentSubListItemIndex(null);
      }
    }
  };

  return (
    <>
      {menuItems.map((item, itemIndex) => {
        const isActive = activeItems && activeItems.includes(item.id);
        const classes = clsx(listItemClassName, isActive && 'active');
        return (
          <MenuItemWrapper
            key={item.id || itemIndex}
            onMouseLeave={() => {
              setCurrentSubListItemIndex(null);
            }}
          >
            <MenuItem
              dataTestId={dataTestId && `${dataTestId}-MenuItem`}
              isMulti={isMulti}
              domID={`${item.label || item}-${itemIndex}`}
              name={item.label || item}
              className={classes}
              onClick={(e) => {
                onMenuClick(e, item, itemIndex);
                setCurrentSubListItemIndex(null);
              }}
              onMouseEnter={() => {
                item.items && flyoutLimit > 0
                  ? setCurrentSubListItemIndex(item.id)
                  : '';
              }}
              isActive={isActive}
              maxWidth={maxWidth}
              {...item}
            />

            {item.items && flyoutLimit > 0 ? (
              <>
                <ArrowWrapper
                  onKeyPress={(e) => handleKeyPress(e, item)}
                  tabIndex="0"
                >
                  <Right size="small" fillColor={BaseColors.Neutral['150']} />
                </ArrowWrapper>
                <ListWrapper
                  maxWidth={maxWidth}
                  className={item.id === currentSubListItemIndex ? 'shown' : ''}
                >
                  <List
                    {...props}
                    menuItems={item.items}
                    flyoutLimit={flyoutLimit - 1}
                  />
                </ListWrapper>
              </>
            ) : null}
          </MenuItemWrapper>
        );
      })}
      {!menuItems.length && !hideEmptyMessage && <EmptyMessage />}
    </>
  );
};
List.propTypes = {
  /** config data for list without sections in which menu items have additional properties */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  /**
   * Runs when list item is clicked
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Object} state.activeItem
   * @prop {Number|String} state.activeItem.id
   * @prop {String} state.activeItem.label
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   */
  onMenuClick: PropTypes.func,
  /** className for individual list/menu items */
  listItemClassName: PropTypes.string,
  domID: PropTypes.string,
  /** optional styles for the individual list/menu items */
  itemStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    dotColor: PropTypes.string,
  }),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.bool,
  ]),
  isMulti: PropTypes.bool,
  dataTestId: PropTypes.string,
  hideEmptyMessage: PropTypes.bool,
  /**
   * defines flyout options level
   */
  flyoutLimit: PropTypes.number,
};

export default List;
