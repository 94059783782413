import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ToggleIcon = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Toggle'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3 6C3 3.79086 4.79086 2 7 2C9.20914 2 11 3.79086 11 6C11 8.20914 9.20914 10 7 10C4.79086 10 3 8.20914 3 6ZM7 11C4.23858 11 2 8.76142 2 6C2 3.23858 4.23858 1 7 1H18C20.7761 1 23 3.22386 23 6C23 8.77614 20.7761 11 18 11H7ZM10.0005 2H18C20.2239 2 22 3.77614 22 6C22 8.22386 20.2239 10 18 10H10.0005C11.2147 9.08777 12 7.6356 12 6C12 4.3644 11.2147 2.91223 10.0005 2ZM7 15C4.77614 15 3 16.7761 3 19C3 21.2239 4.77614 23 7 23H14.9995C13.7853 22.0878 13 20.6356 13 19C13 17.3644 13.7853 15.9122 14.9995 15H7ZM18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15ZM18 24C20.7614 24 23 21.7614 23 19C23 16.2386 20.7614 14 18 14H7C4.22386 14 2 16.2239 2 19C2 21.7761 4.22386 24 7 24H18Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Toggle'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 8C1 5.79086 2.79086 4 5 4C7.20914 4 9 5.79086 9 8C9 10.2091 7.20914 12 5 12C2.79086 12 1 10.2091 1 8ZM5 13C2.23858 13 0 10.7614 0 8C0 5.23858 2.23858 3 5 3H11C13.7611 3 16 5.23886 16 8C16 10.7611 13.7611 13 11 13H5ZM8.00049 4H11C13.2089 4 15 5.79114 15 8C15 10.2089 13.2089 12 11 12H8.00049C9.21466 11.0878 10 9.6356 10 8C10 6.3644 9.21466 4.91223 8.00049 4Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ToggleIcon.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ToggleIcon;
