import styled from 'styled-components';

export default styled.div`
  &.small {
    margin-top: 5px;
  }

  &.medium {
    margin-top: 7px;
  }

  &.large {
    margin-top: 7px;
  }
`;
