import styled from 'styled-components';
import InputMask from 'react-input-mask';
import Borders from '../../../BrandCore/shapes/borders';
import TextStyles from '../../../BrandCore/Text/styles';
import padding from '../../../commonResources/padding';
import heights from '../../../BrandCore/constants/heights';

const configs = {
  large: {
    /** Height in pixels */
    height: heights.input.large,
    padding: padding.input.large,
  },
  small: {
    height: heights.input.small,
    padding: padding.input.small,
  },
  medium: {
    height: heights.input.medium,
    padding: padding.input.medium,
  },
};

export default styled(InputMask)`
  display: block;
  width: 100%;
  ${Borders.borderRadius6};
  box-sizing: border-box;
  ${TextStyles.small};
  outline: none;
  /** By default apply medium styles **/
  height: ${configs.medium.height}px;
  padding: ${configs.medium.padding};
  padding-right: ${(props) => props.paddingright}px;
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
  background-color: ${({ theme }) =>
    theme.UIPalette.Colors.Field.Background.Default};
  border: 1px solid
    ${({ theme }) => theme.UIPalette.Colors.Field.Border.Default};

  &.large {
    height: ${configs.large.height}px;
    padding: ${configs.large.padding};
    ${Borders.borderRadius8};
    ${TextStyles.medium};
    padding-right: ${(props) => props.paddingright}px;

    &.inline-validation {
      padding-left: 40px;
    }
  }

  &.small {
    height: ${configs.small.height}px;
    padding: ${configs.small.padding};
    ${TextStyles.xSmall};
    ${Borders.borderRadius4};
    padding-right: ${(props) => props.paddingright}px;

    &.inline-validation {
      padding-left: 28px;
    }
  }

  &.inline-validation {
    padding-left: 34px;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.Placeholder};
  }

  &:hover:not(:disabled):not(:focus):not(.has-error) {
    border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Hover};
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Field.Background.Default};
  }

  &:active:not(:disabled):not(.has-error) {
    border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Hover};
    background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
  }

  &:focus {
    border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Field.Background.Default};
  }

  &:disabled {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Field.Background.Disabled};
    border-color: ${({ theme }) =>
      theme.UIPalette.Colors.Field.Border.Disabled};
    box-shadow: none;
  }
`;
