import {
  neutral10,
  neutral15,
  neutral30,
  neutral50,
  neutral70,
  neutral100,
  primary100,
  positive130,
  negative130,
} from '../colors/themes/base/basePalette';

export const borders = {
  neutral10: `border: 1px solid ${neutral10};`,
  neutral15: `border: 1px solid ${neutral15};`,
  neutral30: `border: 1px solid ${neutral30};`,
  neutral50: `border: 1px solid ${neutral50};`,
  neutral70: `border: 1px solid ${neutral70};`,
  neutral100: `border: 1px solid ${neutral100};`,
  primary100: `border: 1px solid ${primary100};`,
  positive130: `border: 1px solid ${positive130};`,
};

export const borderHelpers = {
  onlyBottom: `
    border-top: none;
    border-left: none;
    border-right: none;
  `,
  onlyTop: `
    border-bottom: none;
    border-left: none;
    border-right: none;
  `,
  onlyLeft: `
    border-top: none;
    border-bottom: none;
    border-right: none;
  `,
  onlyRight: `
    border-top: none;
    border-bottom: none;
    border-left: none;
  `,
  thicker: `border-width: 3px;`,
  borderRadius2: `border-radius: 2px;`,
  borderRadius3: `border-radius: 3px;`,
  borderRadius4: `border-radius: 4px;`,
  borderRadius6: `border-radius: 6px;`,
  borderRadius8: `border-radius: 8px;`,
  borderRadius10: `border-radius: 10px;`,
  borderRadius12: `border-radius: 12px;`,
  borderRadius32: `border-radius: 32px;`,
  circularBorderRadius: `border-radius: 50%;`,
  pillBorderRadius: `border-radius: 2em;`,
  fieldRegular: `border: 1px solid ${neutral15};`,
  fieldError: `border: 1px solid ${negative130};`,
  fieldHover: `border: 1px solid ${neutral30};`,
  fieldFocus: `border: 1px solid ${primary100};`,
};

export default { ...borders, ...borderHelpers };
