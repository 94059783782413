import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Trash = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Trash'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.5 1C8.22386 1 8 1.22386 8 1.5V5H1.5C1.22386 5 1 5.22386 1 5.5C1 5.77614 1.22386 6 1.5 6H8.5H16.5H23.5C23.7761 6 24 5.77614 24 5.5C24 5.22386 23.7761 5 23.5 5H17V1.5C17 1.22386 16.7761 1 16.5 1H8.5ZM16 5V2H9V5H16ZM4.5 9C4.77614 9 5 9.22386 5 9.5V21.5C5 21.8978 5.15804 22.2794 5.43934 22.5607C5.72064 22.842 6.10218 23 6.5 23H18.5C18.8978 23 19.2794 22.842 19.5607 22.5607C19.842 22.2794 20 21.8978 20 21.5V9.5C20 9.22386 20.2239 9 20.5 9C20.7761 9 21 9.22386 21 9.5V21.5C21 22.163 20.7366 22.7989 20.2678 23.2678C19.7989 23.7366 19.163 24 18.5 24H6.5C5.83696 24 5.20107 23.7366 4.73223 23.2678C4.26339 22.7989 4 22.163 4 21.5V9.5C4 9.22386 4.22386 9 4.5 9Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Trash'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.5 0C5.22386 0 5 0.223858 5 0.5V3H0.5C0.223858 3 0 3.22386 0 3.5C0 3.77614 0.223858 4 0.5 4H5.5H10.5H15.5C15.7761 4 16 3.77614 16 3.5C16 3.22386 15.7761 3 15.5 3H11V0.5C11 0.223858 10.7761 0 10.5 0H5.5ZM10 3V1H6V3H10ZM2.5 6C2.77614 6 3 6.22386 3 6.5V13.5C3 14.3289 3.67114 15 4.5 15H11.5C12.3289 15 13 14.3289 13 13.5V6.5C13 6.22386 13.2239 6 13.5 6C13.7761 6 14 6.22386 14 6.5V13.5C14 14.8811 12.8811 16 11.5 16H4.5C3.11886 16 2 14.8811 2 13.5V6.5C2 6.22386 2.22386 6 2.5 6Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Trash'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4 0.5C4 0.223858 4.22386 0 4.5 0H7.5C7.77614 0 8 0.223858 8 0.5V2H11.5C11.7761 2 12 2.22386 12 2.5C12 2.77614 11.7761 3 11.5 3H7.5H4.5H0.5C0.223858 3 0 2.77614 0 2.5C0 2.22386 0.223858 2 0.5 2H4V0.5ZM7 1V2H5V1H7ZM3 4.5C3 4.22386 2.77614 4 2.5 4C2.22386 4 2 4.22386 2 4.5V10.5C2 10.8978 2.15804 11.2794 2.43934 11.5607C2.72064 11.842 3.10217 12 3.5 12H8.5C8.89783 12 9.27936 11.842 9.56066 11.5607C9.84196 11.2794 10 10.8978 10 10.5V4.5C10 4.22386 9.77614 4 9.5 4C9.22386 4 9 4.22386 9 4.5V10.5C9 10.6326 8.94732 10.7598 8.85355 10.8536C8.75978 10.9473 8.63261 11 8.5 11H3.5C3.36739 11 3.24021 10.9473 3.14645 10.8536C3.05268 10.7598 3 10.6326 3 10.5V4.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Trash.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Trash;
