import React from 'react';
import PropTypes from 'prop-types';

import StoplightTagNav from './components/StoplightTagNav';

export const StoplightTag = ({
  domID = null,
  bad = false,
  nonCompliant = true,
}) => {
  const styleClass = bad ? 'bad' : 'good';
  return (
    <StoplightTagNav id={domID} className={styleClass}>
      {nonCompliant ? 'N' : 'Y'}
    </StoplightTagNav>
  );
};

StoplightTag.propTypes = {
  domID: PropTypes.string,
  bad: PropTypes.bool,
  nonCompliant: PropTypes.bool,
};

export default StoplightTag;
