import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import FadeContainer from './FadeContainer';

import { NAVIGATION_PANE_FADE } from '../../../BrandCore/constants/timings';

const duration = NAVIGATION_PANE_FADE;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

const FadeTransition = ({
  onIn,
  onExited = () => false,
  onEntered = () => false,
  timeout = 0,
  className = '',
  children,
}) => {
  const nodeRef = React.useRef(null);
  return (
    <Transition
      in={onIn}
      timeout={timeout || duration}
      onEntered={onEntered}
      onExited={onExited}
      nodeRef={nodeRef}
    >
      {(state) => (
        <FadeContainer
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={`${className} ${state}`}
        >
          {children}
        </FadeContainer>
      )}
    </Transition>
  );
};

FadeTransition.propTypes = {
  onIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onExited: PropTypes.func,
  onEntered: PropTypes.func,
  timeout: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  className: PropTypes.string,
};

export default FadeTransition;
