import { primary100 } from '../base/basePalette';

export const BoxShadows = {
  Depth2: `box-shadow: 0px 1px 3px rgba(28, 32, 43, 0.06), 0px 2px 12px rgba(28, 32, 43, 0.5);`,
  Depth5: `box-shadow: 0px -1px 2px rgba(26, 31, 45, 0.2), 0px 1px 2px rgba(26, 31, 45, 0.5),
   0px 2px 8px rgba(22, 29, 47, 0.5);`,
  Depth10: `background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)),
   #1C202B;`,
  Depth15: `box-shadow: 0px 1px 3px rgba(28, 32, 43, 0.5), 0px 6px 16px rgba(28, 32, 43, 0.8);`,
  SelectedTabBottom: `box-shadow: 0px 2px 0px ${primary100};`,
  InputShadow: `box-shadow: inset 0px 2px 6px rgba(28, 32, 43, 0.5);`,
};

export default BoxShadows;
