import styled from 'styled-components';

export default styled.div`
  position: relative;
  /** Styles to apply when input is in error state **/
  &.has-error {
    label {
      color: ${({ theme }) => theme.UIPalette.Colors.Content.Error};
    }
    input,
    textarea {
      border: 1px solid
        ${({ theme }) => theme.UIPalette.Colors.Field.Border.Error};
      margin-bottom: 5px;
    }
  }
`;
