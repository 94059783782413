import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: inline-flex;

  button {
    display: flex;
    align-items: center;
  }

  svg {
    &.small {
      margin-left: 6px;
    }

    &.medium {
      margin-left: 8px;
    }

    &.large {
      margin-left: 8px;
    }
  }
`;
