import styled from 'styled-components';

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background: ${(props) => props.theme.BaseColors.White};
  border: 1px solid
    ${(props) => props.theme.UIPalette.Colors.Field.Border.Default};
  margin-right: 8px;

  &:not(.disabled) {
    &:hover,
    &:active,
    &:not(.checked):focus,
    &:not(.checked):focus-visible {
      ${({ theme }) => theme.BoxShadows.InputShadow}
    }

    &:active {
      background-color: ${(props) => props.theme.BaseColors.Neutral['5']};
    }

    &:focus-visible,
    &:focus {
      border-color: ${(props) =>
        props.theme.UIPalette.Colors.Field.Border.Focus};
    }

    &:not(.checked) {
      &:hover,
      &:active {
        border-color: ${(props) =>
          props.theme.UIPalette.Colors.Field.Border.Hover};
      }

      &:focus-visible,
      &:focus {
        background-color: ${(props) => props.theme.BaseColors.White};
      }
    }

    &.checked {
      &:focus,
      &:focus-visible {
        box-shadow: 0 0 0 1px ${(props) => props.theme.BaseColors.White},
          inset 0px 2px 6px rgba(140, 148, 168, 0.16),
          0 0 0 2px
            ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
      }
    }
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }

  /* checked */
  &.checked {
    border-color: ${(props) => props.theme.BaseColors.Primary['115']};
  }

  /* indeterminate */
  &.indeterminate {
    background-color: ${(props) => props.theme.BaseColors.Primary['10']};
    border-color: ${(props) => props.theme.BaseColors.Primary['115']};
  }

  /* disabled */
  &.disabled {
    background-color: ${(props) => props.theme.BaseColors.Neutral['5']};
    border-color: ${(props) =>
      props.theme.UIPalette.Colors.Field.Border.Disabled};
  }
`;

const CheckboxIconContainer = styled(IconContainer)`
  border-radius: 3px;
  min-width: 14px;
  min-height: 14px;
  margin-top: 4px;

  &.checked:not(.disabled) {
    background-color: ${(props) => props.theme.BaseColors.Primary['115']};
  }
`;

const RadioIconContainer = styled(IconContainer)`
  margin-top: 3px;
  border-radius: 50%;
  min-width: 16px;
  min-height: 16px;

  &.checked {
    &.disabled {
      circle {
        fill: ${(props) => props.theme.UIPalette.Colors.Content.Disabled};
      }
    }
  }
`;

export default { CheckboxIconContainer, RadioIconContainer };
export { CheckboxIconContainer, RadioIconContainer };
