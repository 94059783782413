import React from 'react';
import PropTypes from 'prop-types';
import SingleSelectGroupOpenClose from '../../Atoms/SingleSelectGroupOpenClose';

const CollapsePanelTitle = ({
  children,
  onClick,
  expanded,
  domID = null,
  isLink = false,
  wrapText = false,
}) => (
  <SingleSelectGroupOpenClose
    domID={domID}
    title={children}
    onClick={onClick}
    isLink={isLink}
    selectItem={onClick}
    isExpanded={expanded}
    wrapText={wrapText}
  />
);

CollapsePanelTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** The prop is for CollapsePanel in order for entire area to be clickable not just icon */
  isLink: PropTypes.bool,
  /** The prop is for CollapsePanel to control status, don't use it. */
  onClick: PropTypes.func,
  /** The prop is for CollapsePanel to control status, don't use it. */
  expanded: PropTypes.bool,
  /** if true, the text will wrap instead of truncating. This can also be set using `wrapTitle` on a parent CollapsePanel, or `wrapTitles` on a parent Accordion */
  wrapText: PropTypes.bool,
  domID: PropTypes.string,
};

export default CollapsePanelTitle;
