import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Filter = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Filter'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H23.5C23.7761 4 24 3.77614 24 3.5C24 3.22386 23.7761 3 23.5 3H1.5ZM4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10H20.5C20.7761 10 21 9.77614 21 9.5C21 9.22386 20.7761 9 20.5 9H4.5ZM7 15.5C7 15.2239 7.22386 15 7.5 15H17.5C17.7761 15 18 15.2239 18 15.5C18 15.7761 17.7761 16 17.5 16H7.5C7.22386 16 7 15.7761 7 15.5ZM10.5 21C10.2239 21 10 21.2239 10 21.5C10 21.7761 10.2239 22 10.5 22H14.5C14.7761 22 15 21.7761 15 21.5C15 21.2239 14.7761 21 14.5 21H10.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Filter'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 2C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H0.5ZM2.5 6C2.22386 6 2 6.22386 2 6.5C2 6.77614 2.22386 7 2.5 7H13.5C13.7761 7 14 6.77614 14 6.5C14 6.22386 13.7761 6 13.5 6H2.5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5ZM6.5 14C6.22386 14 6 14.2239 6 14.5C6 14.7761 6.22386 15 6.5 15H9.5C9.77614 15 10 14.7761 10 14.5C10 14.2239 9.77614 14 9.5 14H6.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Filter'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.5 1C0.223858 1 0 1.22386 0 1.5C0 1.77614 0.223858 2 0.5 2H11.5C11.7761 2 12 1.77614 12 1.5C12 1.22386 11.7761 1 11.5 1H0.5ZM5 10C4.72386 10 4.5 10.2239 4.5 10.5C4.5 10.7761 4.72386 11 5 11H7C7.27614 11 7.5 10.7761 7.5 10.5C7.5 10.2239 7.27614 10 7 10H5ZM1.5 4.5C1.5 4.22386 1.72386 4 2 4H10C10.2761 4 10.5 4.22386 10.5 4.5C10.5 4.77614 10.2761 5 10 5H2C1.72386 5 1.5 4.77614 1.5 4.5ZM3.5 7C3.22386 7 3 7.22386 3 7.5C3 7.77614 3.22386 8 3.5 8H8.5C8.77614 8 9 7.77614 9 7.5C9 7.22386 8.77614 7 8.5 7H3.5Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Filter.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Filter;
