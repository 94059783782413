import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ArrowRight = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'small',
}) => {
  switch (size) {
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ArrowRight'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.85355 1.64645C7.65829 1.45118 7.34171 1.45118 7.14645 1.64645C6.95118 1.84171 6.95118 2.15829 7.14645 2.35355L10.2929 5.5H0.5C0.223858 5.5 0 5.72386 0 6C0 6.27614 0.223858 6.5 0.5 6.5H10.2929L7.14645 9.64645C6.95118 9.84171 6.95118 10.1583 7.14645 10.3536C7.34171 10.5488 7.65829 10.5488 7.85355 10.3536L11.8536 6.35355C11.9015 6.30561 11.9377 6.25036 11.9621 6.19139C11.9842 6.13777 11.9967 6.08107 11.9994 6.02399C11.9998 6.016 12 6.008 12 6M11.9621 5.80861C11.9377 5.74964 11.9015 5.69439 11.8536 5.64645L7.85355 1.64645M11.9621 5.80861C11.9861 5.86669 11.9996 5.9303 12 5.997Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ArrowRight.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ArrowRight;
