// stylelint-disable no-descending-specificity
import styled from 'styled-components';
import Borders from '../../../BrandCore/shapes/borders';
import TextStyles from '../../../BrandCore/Text/styles';
import padding from '../../../commonResources/padding';
import heights from '../../../BrandCore/constants/heights';

export default styled.div`
  display: flex;
  flex-direction: column;
  /*
   INPUT
   *****************************
   */
  .SingleDatePicker,
  .DateRangePicker {
    display: initial;
    z-index: ${(props) => props.zIndex};
  }

  .SingleDatePickerInput__showClearDate,
  .DateRangePickerInput__showClearDates {
    padding: 0;
  }

  .SingleDatePickerInput,
  .DateRangePickerInput {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Default};
    background-color: ${({ theme }) => theme.BaseColors.White};
    outline: none;
    height: ${(props) => heights.input[props.size]}px;
    ${(props) => props.size === 'small' && Borders.borderRadius4};
    ${(props) => props.size === 'medium' && Borders.borderRadius6};
    ${(props) => props.size === 'large' && Borders.borderRadius8};
    ${({ theme }) => theme.BoxShadows.InputShadow};

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &:hover:not(:focus-within) {
      border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Hover};
      background-color: ${({ theme }) =>
        theme.UIPalette.Colors.Field.Background.Default};
    }

    &:focus-within {
      border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
      background-color: ${({ theme }) =>
        theme.UIPalette.Colors.Field.Background.Default};
    }

    &:active {
      border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Hover};
      background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
    }

    .SingleDatePickerInput_calendarIcon,
    .DateRangePickerInput_calendarIcon,
    .SingleDatePickerInput_clearDate,
    .DateRangePickerInput_clearDates {
      margin: 0 0 0 0;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      right: unset;
      top: unset;
      transform: none;

      &:active {
        outline: none;
      }

      &:focus:not(:active) {
        outline: none;
        border: none;
      }
    }

    .SingleDatePickerInput_calendarIcon,
    .DateRangePickerInput_calendarIcon {
      margin-right: ${(props) => {
        let withoutDropdown = 9;
        if (props.size === 'small') withoutDropdown = 7;
        if (props.size === 'large') withoutDropdown = 13;
        const adjustment = props.size === 'small' ? 30 : 38;
        const dropdownAdjustment = props.hasDropdown ? adjustment : 0;
        return withoutDropdown + dropdownAdjustment;
      }}px;
    }

    .SingleDatePickerInput_clearDate,
    .DateRangePickerInput_clearDates {
      margin-right: 8px;
    }

    .SingleDatePickerInput_clearDate__hide,
    .DateRangePickerInput_clearDates__hide {
      display: none;
    }

    /* not the actual input element */
    .DateInput {
      height: 100%;
      background: none;
      flex: 1;
      width: unset;

      /* for the date range picker we change the width of the first input based on contents */
      #${(props) => props.domID}-start-date {
        width: ${(props) => {
          const paddings = {
            medium: 2,
            large: 20,
            small: -13,
          };
          // medium inputs have slightly more padding, so we need to make the width wider
          const inputPaddingDifference = paddings[props.size];
          const baseWidth = props.currentStartDate ? 88 : 100;
          return baseWidth + inputPaddingDifference;
        }}px;
      }

      &:first-of-type {
        flex: ${(props) => (props.isDateRangePicker ? 'unset' : 1)};
      }

      #${(props) => props.domID}-end-date {
        padding-left: 4px;
      }

      /* the actual input element */
      .DateInput_input {
        ${TextStyles.small};
        ${(props) => props.size === 'small' && TextStyles.xSmall};
        ${(props) => props.size === 'large' && TextStyles.medium};
        padding: ${(props) => padding.input[props.size]};
        background: none;
        border-bottom: none;
        height: 100%;
        text-overflow: ellipsis;

        &::placeholder {
          color: ${({ theme }) => theme.UIPalette.Colors.Content.Placeholder};
          opacity: 1;
        }

        &::-ms-clear {
          display: none;
        }
      }

      .DateInput_input__focused {
        border-bottom: none;
      }

      .DateInput_input__disabled {
        font-style: normal;
      }
    }

    // line between start and end date
    .DateRangePickerInput_arrow {
      ${TextStyles.small};
      color: ${(props) =>
        props.currentStartDate
          ? props.theme.BaseColors.Neutral['100']
          : props.theme.BaseColors.Neutral['50']};
    }
  }

  .datepicker-select-container {
    margin-top: ${(props) => -heights.input[props.size]}px;
    height: ${(props) => heights.input[props.size]}px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    z-index: 20;

    .datepicker-select {
      height: ${(props) => (props.size === 'small' ? 16 : 24)}px;
      border-left: 1px solid ${({ theme }) => theme.BaseColors.Neutral['30']};
      display: flex;
    }

    .datepicker-select-button {
      padding: ${(props) => (props.size === 'small' ? 2 : 6)}px;
      margin: 0 6px;
      z-index: 30;
      ${Borders.borderRadius4};

      svg {
        margin: 0;
        padding: 0;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px
          ${({ theme }) => theme.UIPalette.Colors.Field.Focus};
      }
    }

    .datepicker-dropdown-list {
      ${(props) => props.size === 'small' && 'top: 22px'};
      ${(props) => props.size === 'medium' && 'top: 30px'};
      ${(props) => props.size === 'large' && 'top: 34px'};
    }
  }

  /* if hasError prop is true */
  &.has-error {
    label,
    .SingleDatePickerInput .DateInput .DateInput_input,
    .DateRangePickerInput .DateInput .DateInput_input {
      color: ${({ theme }) => theme.UIPalette.Colors.Content.Error};
    }

    .SingleDatePickerInput,
    .DateRangePickerInput {
      border-color: ${({ theme }) => theme.UIPalette.Colors.Field.Border.Error};
      ${(props) => !props.hasDropdown && 'margin-bottom: 3px'};

      &:hover {
        border-color: ${({ theme }) =>
          theme.UIPalette.Colors.Field.Border.Error};
      }
    }

    &.focused {
      color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
    }

    .datepicker-dropdown-list {
      margin-top: 1px;
    }

    .datepicker-select-container {
      ${(props) => props.hasDropdown && 'margin-bottom: 3px'};
    }
  }

  &.opened {
    .SingleDatePickerInput,
    .DateRangePickerInput {
      border-color: ${({ theme }) =>
        theme.UIPalette.Colors.Field.Border.Default};
      background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
    }
  }

  /* if disabled prop is true */
  &.disabled {
    .SingleDatePickerInput,
    .DateRangePickerInput {
      background-color: ${({ theme }) => theme.BaseColors.Neutral['5']};
      border-color: ${({ theme }) =>
        theme.UIPalette.Colors.Field.Border.Disabled};

      &:hover {
        border-color: ${({ theme }) =>
          theme.UIPalette.Colors.Field.Border.Disabled};
      }
    }

    input {
      color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};

      &::placeholder {
        color: ${({ theme }) => theme.UIPalette.Colors.Content.Placeholder};
      }
    }
  }

  /*
   CALENDAR
   *****************************
   */

  /* supporting openDirection */
  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    /* we need to use !important in some places here to override the library's styles */
    /* stylelint-disable-next-line declaration-no-important */
    top: ${(props) => heights.input[props.size]}px !important;
    background-color: transparent;
  }

  .SingleDatePicker_picker__directionLeft,
  .DateRangePicker_picker__directionLeft {
    /* we need to use !important in some places here to override the library's styles */
    left: 0px !important; /* stylelint-disable-line declaration-no-important */
    right: unset !important; /* stylelint-disable-line declaration-no-important */
  }

  /* border and shadow for open datepicker */
  .DayPicker {
    padding-bottom: 14px;
    ${({ theme }) => theme.BoxShadows.Depth5};
    ${Borders.borderRadius8};
    overflow: hidden;
  }

  // in order for us to use styles from UIPalette we should override
  // all this CSS Classes below which could be found under the _datepicker.css file from react-dates package
  .DayPicker,
  .DayPicker__horizontal,
  .DayPicker__horizontal,
  .CalendarMonth,
  .CalendarMonthGrid {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Background.StandardMenu};
  }

  /* supporting openDirection */
  &.openUp {
    .SingleDatePicker_picker,
    .DateRangePicker_picker {
      /* we need to use !important in some places here to override the library's styles */
      /* stylelint-disable-next-line declaration-no-important */
      bottom: ${(props) => heights.input[props.size]}px !important;
      top: unset !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  /* to center in smaller total width, was 9px */
  .DayPicker_weekHeaders__horizontal {
    margin-left: 0px;
  }

  /* to center in smaller total width, was 9px */
  .CalendarMonthGrid__horizontal {
    left: 12px;
  }

  .CalendarMonth {
    padding: 0px 12px !important; /* stylelint-disable-line declaration-no-important */
  }

  .CalendarMonth_table {
    margin-top: 13px;
  }

  /* month title */
  .CalendarMonth_caption {
    ${TextStyles.mediumBold};
    padding-top: 26px;
  }

  /* next/previous month arrows */
  .DayPickerNavigation_button {
    position: absolute;
    display: flex;
    top: 28px;

    > div {
      ${Borders.borderRadius4};
      display: flex;
      padding: 4px;

      &:focus {
        outline: none;
      }
    }

    &:first-child {
      left: 31px;
    }

    &:last-child {
      right: ${(props) => (props.numberOfMonths === 1 ? '31px' : '34px')};
    }

    &:focus-within {
      > div:focus-visible {
        box-shadow: 0px 0px 0px 1px
          ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
      }
    }
  }

  .DayPicker_weekHeader {
    top: 69px;
    margin-left: 12px;
    padding: 0px 12px !important; /*stylelint-disable-line declaration-no-important */
    display: flex;
    justify-content: center;
    &:nth-child(2) {
      margin-left: 8px;
    }
  }

  /* individual days in calendar */
  /* ----------------------------------------------- */
  .CalendarDay {
    ${TextStyles.small};
    ${Borders.borderRadius4};
    border: none;
    background: none;
    height: 36px !important; /* stylelint-disable-line declaration-no-important */

    &:hover {
      background: ${({ theme }) => theme.BaseColors.Neutral['5']};
    }

    &:focus:not(:active):not(.CalendarDay__selected):not(.CalendarDay__selected_span) {
      outline: none;
      box-shadow: 0px 0px 0px 1px
        ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    }

    &:active {
      background: ${({ theme }) => theme.BaseColors.Neutral['10']};
    }
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:hover,
  .CalendarDay__blocked_calendar:focus,
  .CalendarDay__blocked_calendar:active {
    color: ${({ theme }) => theme.BaseColors.Neutral['50']};
    background: ${({ theme }) => theme.BaseColors.White};
  }

  /* selected calendar day */
  .CalendarDay__selected,
  .CalendarDay__selected:hover,
  .CalendarDay__selected:active,
  .CalendarDay__selected:focus {
    background-color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Background.Emphasized};
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    color: ${({ theme }) =>
      theme.UIPalette.Colors.Button.Hover.Text.Emphasized};
  }

  .CalendarDay__selected:focus {
    box-shadow: inset 0 0 0 1px
        ${({ theme }) => theme.BaseColors.Primary['115']},
      0 0 0 1px ${({ theme }) => theme.UIPalette.Colors.Background.StandardMenu},
      0px 0px 0px 2px
        ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    outline: none;
    position: absolute;
    padding: 8px 0;
  }

  /* hovered and selected range in Date Range Picker */
  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:focus,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${({ theme }) => theme.BaseColors.Primary['30']};
    color: ${({ theme }) => theme.UIPalette.Colors.Button.Text.Emphasized};
    border-radius: 0;
  }

  .CalendarDay__selected_span:focus {
    box-shadow: inset 0px 0px 0px 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    outline: none;
  }

  .CalendarDay__selected_start {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .CalendarDay__selected_end {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .DateInput_fang {
    display: none;
  }

  .date-picker-button-bar {
    padding: 14px 24px 10px 24px;
    flex-wrap: wrap;
  }
`;
