import React from 'react';
import PropTypes from 'prop-types';
import VerticallyCentered from './styleWrappers/VerticallyCentered';
import Button from '../../../Atoms/Button';

const ButtonCell = ({
  data: { onFocus = () => null, onBlur = () => null, onClick = () => null },
  data = {},
}) => {
  return (
    <VerticallyCentered>
      <Button
        size="small"
        {...data}
        onFocus={(e) => onFocus(e, data)}
        onBlur={(e) => onBlur(e, data)}
        onClick={(e) => onClick(e, data)}
      />
    </VerticallyCentered>
  );
};

ButtonCell.propTypes = {
  data: PropTypes.shape({
    buttonType: PropTypes.oneOf([
      'standard',
      'destroy',
      'emphasized',
      'emphasizedAlt',
      'deEmphasized',
      'deEmphasizedReversed',
      'diminished',
      'unstyled',
    ]),
    name: PropTypes.string,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,

    className: PropTypes.string,
    disabled: PropTypes.bool,
    domID: PropTypes.string,
    children: PropTypes.element,
    isDropdown: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
};

export default ButtonCell;
