import Joi from 'joi';

const paletteSchema = Joi.object({
  UIPalette: Joi.object({
    Colors: Joi.object({
      Background: Joi.object({
        Modal: Joi.string(),
        Masthead: Joi.string(),
        Selected: Joi.string(),
        ProductSwitcher: Joi.string(),
        Tooltip: Joi.string(),
        Depth2: Joi.string(),
        Depth5: Joi.string(),
        Depth10: Joi.string(),
        Depth15: Joi.string(),
        Breadcrumb: Joi.string(),
        StandardMenu: Joi.string(),
        ToggleOn: Joi.string(),
        Default: Joi.string(),
      }),
      Content: Joi.object({
        Primary: Joi.string(),
        Secondary: Joi.string(),
        Placeholder: Joi.string(),
        TabDisabled: Joi.string(),
        Disabled: Joi.string(),
        Error: Joi.string(),
        White: Joi.string(),
        PersistentDark: Joi.string(),
        PersistentLight: Joi.string(),
        Masthead: Joi.string(),
      }),
      Selected: Joi.object({
        Tab: Joi.string(),
        TabUnselected: Joi.string(),
        MenuItem: Joi.string(),
      }),
      Status: Joi.object({
        Positive: Joi.string(),
        Caution: Joi.string(),
        Negative: Joi.string(),
        Neutral: Joi.string(),
        Light: Joi.string(),
        Dark: Joi.string(),
      }),
      Field: Joi.object({
        Background: Joi.object({
          Default: Joi.string(),
          Disabled: Joi.string(),
        }),
        Border: Joi.object({
          Default: Joi.string(),
          Hover: Joi.string(),
          Focus: Joi.string(),
          Disabled: Joi.string(),
          Error: Joi.string(),
        }),
      }),
      Button: Joi.object({
        Background: Joi.object({
          Standard: Joi.string(),
        }),
        Border: Joi.object({
          Standard: Joi.string(),
          EmphasizedAlt: Joi.string(),
        }),
        Text: Joi.object({
          Standard: Joi.string(),
          Emphasized: Joi.string(),
        }),
        Hover: Joi.object({
          Background: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            DeEmphasized: Joi.string(),
            Diminished: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
            DeEmphasizedReversed: Joi.string(),
          }),
          Border: Joi.object({
            Standard: Joi.string(),
            EmphasizedAlt: Joi.string(),
          }),
          Text: Joi.object({
            Emphasized: Joi.string(),
            Diminished: Joi.string(),
          }),
        }),
        Active: Joi.object({
          Border: Joi.object({
            EmphasizedAlt: Joi.string(),
          }),
        }),
        Focus: Joi.object({
          Border: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            DeEmphasized: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
            DeEmphasizedReversed: Joi.string(),
            CheckboxFocus: Joi.string(),
            RadioFocus: Joi.string(),
          }),
          BoxShadow: Joi.object({
            Standard: Joi.string(),
            Emphasized: Joi.string(),
            DeEmphasized: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
            DeEmphasizedReversed: Joi.string(),
          }),
        }),
        Disabled: Joi.object({
          Background: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
          }),
          Border: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
            DeEmphasizedReversed: Joi.string(),
          }),
          Text: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            Diminished: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
            DeEmphasizedReversed: Joi.string(),
          }),
        }),
      }),
      SplitDropdownButton: Joi.object({
        Border: Joi.object({
          Emphasized: Joi.string(),
          Standard: Joi.string(),
          Destroy: Joi.string(),
          EmphasizedAlt: Joi.string(),
        }),
        Disabled: Joi.object({
          Border: Joi.object({
            Emphasized: Joi.string(),
            Standard: Joi.string(),
            Destroy: Joi.string(),
            EmphasizedAlt: Joi.string(),
          }),
        }),
        BoxShadow: Joi.string(),
      }),
      Filter: Joi.object({
        Border: Joi.object({
          Selected: Joi.string(),
          Focus: Joi.string(),
        }),
        Background: Joi.object({
          Hover: Joi.string(),
          Active: Joi.string(),
          Selected: Joi.string(),
          HomeButton: Joi.string(),
          HomeButtonDisabled: Joi.string(),
        }),
        BoxShadow: Joi.object({
          Selection: Joi.string(),
        }),
      }),
      MenuItem: Joi.object({
        Icon: Joi.string(),
        Checked: Joi.string(),
        Background: Joi.object({
          Default: Joi.string(),
        }),
      }),
    }),
    BoxShadows: Joi.object({
      Field: Joi.object({
        Inner: Joi.string(),
        Outer: Joi.string(),
        Default: Joi.string(),
        Disabled: Joi.string(),
        Hover: Joi.string(),
        Focus: Joi.string(),
        Error: Joi.string(),
      }),
      Selection: Joi.object({
        TabSelected: Joi.string(),
        TabUnselected: Joi.string(),
        MenuItem: Joi.string(),
      }),
      General: Joi.object({
        LineAbove: Joi.string(),
        LineBelow: Joi.string(),
        MicroModal: Joi.string(),
        HelperModule: Joi.string(),
        HelperModuleCustom: Joi.function(),
      }),
    }),
  }),
  BaseColors: Joi.object({
    Primary: Joi.object({
      130: Joi.string(),
      115: Joi.string(),
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
      10: Joi.string(),
    }),
    Secondary: Joi.object({
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Tertiary: Joi.object({
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Quaternary: Joi.object({
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Negative: Joi.object({
      130: Joi.string(),
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Caution: Joi.object({
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Positive: Joi.object({
      130: Joi.string(),
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    NeutralAlt: Joi.object({
      100: Joi.string(),
      75: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Accent: Joi.object({
      130: Joi.string(),
      100: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
    }),
    Neutral: Joi.object({
      170: Joi.string(),
      150: Joi.string(),
      100: Joi.string(),
      70: Joi.string(),
      50: Joi.string(),
      30: Joi.string(),
      15: Joi.string(),
      10: Joi.string(),
      5: Joi.string(),
      0: Joi.string(),
    }),
    White: Joi.string(),
    TrueWhite: Joi.string(),
    Black: Joi.string(),
  }),
  BoxShadows: Joi.object({
    Depth2: Joi.string(),
    Depth5: Joi.string(),
    Depth10: Joi.string(),
    Depth15: Joi.string(),
    SelectedTabBottom: Joi.string(),
    InputShadow: Joi.string(),
  }),
});

export default paletteSchema;
