import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useIsTruncated } from '../../commonResources/hooks/useIsTruncated';
import { ternaryFn } from '../../commonResources/helpers/jsHelpers';
import Count from '../Count';
import Tooltip from '../Tooltip/tooltip';

import { TabWrapper, TabLabelWrapper, TabCountWrapper } from './Tab.styles';

const Tab = React.forwardRef(
  (
    {
      tooltipWidth,
      tooltipContent,
      label,
      count = null,
      isSelected = false,
      isDisabled = false,
      size = 'medium',
      onSelect = () => false,
      className = null,
      domID,
      ...otherProps
    },
    ref,
  ) => {
    const labelRef = React.useRef(null);
    const isTruncated = useIsTruncated(labelRef, [tooltipContent, label]);
    const conditionalTooltipContent = ternaryFn(
      isTruncated,
      ternaryFn(tooltipContent, `${label} \n ${tooltipContent}`, label),
      tooltipContent,
    );

    return (
      <TabWrapper
        id={domID}
        ref={ref}
        aria-disabled={isDisabled}
        className={clsx(className, 'tab', `tab-${size}`, {
          selected: isSelected,
          disabled: isDisabled,
        })}
        onClick={isDisabled ? () => false : onSelect}
        {...otherProps}
      >
        <Tooltip
          style={{ padding: '5.5px 0' }}
          tooltipContent={conditionalTooltipContent}
          tooltipPosition="top-center"
          tooltipWidth={tooltipWidth}
          hideTooltip={!conditionalTooltipContent}
          isButtonRoleDisabled
          dataTestId={`${otherProps.dataTestId}-tooltip`}
          domID={`${otherProps.domID}-tooltip`}
          whiteSpace="pre"
        >
          <TabLabelWrapper ref={labelRef}>{label}</TabLabelWrapper>
        </Tooltip>

        {count !== null && (
          <TabCountWrapper>
            <Count
              dataTestId={`${otherProps.dataTestId}-count`}
              domID={`${otherProps.domID}-count`}
              className={clsx({ selected: isSelected })}
              count={count}
              size="small"
            />
          </TabCountWrapper>
        )}
      </TabWrapper>
    );
  },
);

Tab.propTypes = {
  domID: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  onSelect: PropTypes.func,
  className: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipWidth: PropTypes.number,
};

export default Tab;
