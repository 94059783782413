import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

import NotFoundBG from './assets/404BG.svg';
import UnderMaintenanceBG from './assets/UnderMaintenanceBG.svg';
import LoggedOutBG from './assets/LoggedOutBG.svg';

const backgrounds = {
  notFound: NotFoundBG,
  underMaintenance: UnderMaintenanceBG,
  loggedOut: LoggedOutBG,
  undefined: NotFoundBG,
};

const ErrorPageWrapper = styled.div`
  * {
    text-align: center;
  }
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => backgrounds[props.background]});
  background-color: ${({ theme }) => theme.UIPalette.Colors.Background.Depth5};
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 511px;

    > div {
      padding: 48px;
      h1 {
        color: ${({ theme }) => theme.UIPalette.Colors.Content.Primary};
      }
    }

    .body-text {
      ${TextStyles.small};
      color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
      margin-top: 12px;
    }

    .footer {
      margin-top: 32px;
      justify-content: center;
    }
  }
`;

export default ErrorPageWrapper;
