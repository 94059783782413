import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Page = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Page'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.5 1C3.22386 1 3 1.22386 3 1.5V22.5C3 22.7761 3.22386 23 3.5 23H20.5C20.7761 23 21 22.7761 21 22.5V7.5C21 7.36739 20.9473 7.24021 20.8536 7.14645L14.8536 1.14645C14.7598 1.05268 14.6326 1 14.5 1H3.5ZM4 22V2H14V7.49999C14 7.77613 14.2239 7.99999 14.5 7.99999H20V22H4ZM19.2929 6.99999L15 2.70711V6.99999H19.2929Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Page'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V15.5C1 15.7761 1.22386 16 1.5 16H14.5C14.7761 16 15 15.7761 15 15.5V5.5C15 5.36739 14.9473 5.24021 14.8536 5.14645L9.85355 0.146447C9.75979 0.0526784 9.63261 0 9.5 0H1.5ZM2 15V1H9V5.5C9 5.77614 9.22386 6 9.5 6H14V15H2ZM13.2929 5L10 1.70711V5H13.2929Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Page'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 0C1.22386 0 1 0.223858 1 0.5V11.5C1 11.7761 1.22386 12 1.5 12H10.5C10.7761 12 11 11.7761 11 11.5V4.5C11 4.36739 10.9473 4.24021 10.8536 4.14645L6.85355 0.146447C6.75979 0.0526784 6.63261 0 6.5 0H1.5ZM2 11V1H6V4.5C6 4.77614 6.22386 5 6.5 5H10V11H2ZM9.29289 4L7 1.70711V4H9.29289Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Page.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Page;
