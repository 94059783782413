import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const DragRow = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Drag Row'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M16.1464 5.85355C16.3417 6.04882 16.6583 6.04882 16.8536 5.85355C17.0488 5.65829 17.0488 5.34171 16.8536 5.14645L12.8536 1.14645C12.6583 0.951184 12.3417 0.951184 12.1464 1.14645L8.14645 5.14645C7.95118 5.34171 7.95118 5.65829 8.14645 5.85355C8.34171 6.04882 8.65829 6.04882 8.85355 5.85355L12.5 2.20711L16.1464 5.85355ZM0 12C0 11.7239 0.223858 11.5 0.5 11.5H23.5C23.7761 11.5 24 11.7239 24 12C24 12.2761 23.7761 12.5 23.5 12.5H0.5C0.223858 12.5 0 12.2761 0 12ZM8.14645 18.1464C8.34171 17.9512 8.65829 17.9512 8.85355 18.1464L12.5 21.7929L16.1464 18.1464C16.3417 17.9512 16.6583 17.9512 16.8536 18.1464C17.0488 18.3417 17.0488 18.6583 16.8536 18.8536L12.8536 22.8536C12.6583 23.0488 12.3417 23.0488 12.1464 22.8536L8.14645 18.8536C7.95118 18.6583 7.95118 18.3417 8.14645 18.1464Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Drag Row'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.6464 4.35355C10.8417 4.54882 11.1583 4.54882 11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.35355 0.646447C8.15829 0.451184 7.84171 0.451184 7.64645 0.646447L4.64645 3.64645C4.45118 3.84171 4.45118 4.15829 4.64645 4.35355C4.84171 4.54882 5.15829 4.54882 5.35355 4.35355L8 1.70711L10.6464 4.35355ZM0 8C0 7.72386 0.223858 7.5 0.5 7.5H15.5C15.7761 7.5 16 7.72386 16 8C16 8.27614 15.7761 8.5 15.5 8.5H0.5C0.223858 8.5 0 8.27614 0 8ZM4.64645 11.6464C4.84171 11.4512 5.15829 11.4512 5.35355 11.6464L8 14.2929L10.6464 11.6464C10.8417 11.4512 11.1583 11.4512 11.3536 11.6464C11.5488 11.8417 11.5488 12.1583 11.3536 12.3536L8.35355 15.3536C8.15829 15.5488 7.84171 15.5488 7.64645 15.3536L4.64645 12.3536C4.45118 12.1583 4.45118 11.8417 4.64645 11.6464Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Drag Row'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.64645 3.35355C7.84171 3.54882 8.15829 3.54882 8.35355 3.35355C8.54882 3.15829 8.54882 2.84171 8.35355 2.64645L6.35355 0.646447C6.15829 0.451184 5.84171 0.451184 5.64645 0.646447L3.64645 2.64645C3.45118 2.84171 3.45118 3.15829 3.64645 3.35355C3.84171 3.54882 4.15829 3.54882 4.35355 3.35355L6 1.70711L7.64645 3.35355ZM0 6C0 5.72386 0.223858 5.5 0.5 5.5H11.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5H0.5C0.223858 6.5 0 6.27614 0 6ZM3.64645 8.64645C3.84171 8.45118 4.15829 8.45118 4.35355 8.64645L6 10.2929L7.64645 8.64645C7.84171 8.45118 8.15829 8.45118 8.35355 8.64645C8.54882 8.84171 8.54882 9.15829 8.35355 9.35355L6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L3.64645 9.35355C3.45118 9.15829 3.45118 8.84171 3.64645 8.64645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

DragRow.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default DragRow;
