import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const PriorityLow = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Priority Low'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.64645 14.3536C7.84171 14.5488 8.15829 14.5488 8.35355 14.3536L14.3536 8.35355C14.5488 8.15829 14.5488 7.84171 14.3536 7.64645C14.1583 7.45118 13.8417 7.45118 13.6464 7.64645L8 13.2929L2.35355 7.64645C2.15829 7.45118 1.84171 7.45118 1.64645 7.64645C1.45118 7.84171 1.45118 8.15829 1.64645 8.35355L7.64645 14.3536ZM1.64645 2.35355L7.64645 8.35355C7.84171 8.54882 8.15829 8.54882 8.35355 8.35355L14.3536 2.35355C14.5488 2.15829 14.5488 1.84171 14.3536 1.64645C14.1583 1.45118 13.8417 1.45118 13.6464 1.64645L8 7.29289L2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45119 1.84171 1.45119 2.15829 1.64645 2.35355Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Priority Low'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.64645 10.8536C5.84171 11.0488 6.15829 11.0488 6.35355 10.8536L10.8536 6.35355C11.0488 6.15829 11.0488 5.84171 10.8536 5.64645C10.6583 5.45118 10.3417 5.45118 10.1464 5.64645L6 9.79289L1.85355 5.64645C1.65829 5.45118 1.34171 5.45118 1.14645 5.64645C0.951185 5.84171 0.951185 6.15829 1.14645 6.35355L5.64645 10.8536ZM1.14645 1.85355L5.64645 6.35355C5.84171 6.54882 6.15829 6.54882 6.35355 6.35355L10.8536 1.85355C11.0488 1.65829 11.0488 1.34171 10.8536 1.14645C10.6583 0.951184 10.3417 0.951184 10.1464 1.14645L6 5.29289L1.85355 1.14645C1.65829 0.951183 1.34171 0.951183 1.14645 1.14645C0.951185 1.34171 0.951185 1.65829 1.14645 1.85355Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

PriorityLow.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default PriorityLow;
