import styled from 'styled-components';
import TextStyles from '../../../../../BrandCore/Text/styles';

export default styled.span`
  padding: 0px 5px;
  border-radius: 8.5px;
  margin-top: -10px;
  margin-left: -7px;
  margin-right: -9px;
  ${TextStyles.xSmallBold};
  ${({ theme }) => theme.BoxShadows.Depth5};
  color: ${(props) => props.theme.UIPalette.Colors.Content.PersistentLight};
  background-color: ${(props) => props.theme.BaseColors.Accent['100']};
`;
