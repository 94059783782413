import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const LightBulb = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'small',
}) => {
  switch (size) {
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'LightBulb'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.39883 4.4022L9.39883 4.40129C9.39927 3.79711 9.23865 3.20374 8.93351 2.68227C8.62837 2.16081 8.18975 1.73011 7.66281 1.43453C7.13587 1.13894 6.53967 0.989162 5.9356 1.00061C5.33153 1.01206 4.74143 1.18432 4.22607 1.49966C3.71071 1.81499 3.28872 2.262 3.00356 2.79465C2.7184 3.3273 2.58037 3.92634 2.6037 4.53007C2.62703 5.1338 2.81087 5.7204 3.13628 6.22945C3.41912 6.67192 3.80004 7.04191 4.24825 7.31147C4.67624 7.56887 5.00053 8.04671 5.00053 8.62316V9.1073H6.99947V8.62716C6.99947 8.05072 7.32375 7.57288 7.75175 7.31547C8.21897 7.03447 8.6128 6.6445 8.8987 6.17755C9.22586 5.64318 9.39895 5.02877 9.39883 4.4022ZM5.00053 11V10.1073H6.99947V11H5.00053ZM9.7966 2.17723C10.1915 2.85212 10.3994 3.62008 10.3988 4.40202C10.399 5.21293 10.175 6.00811 9.75155 6.6997C9.38154 7.30405 8.87183 7.80875 8.26714 8.17242C8.10611 8.26927 7.99947 8.43925 7.99947 8.62716V11.5C7.99947 11.7761 7.77561 12 7.49947 12H4.50053C4.22439 12 4.00053 11.7761 4.00053 11.5V8.62316C4.00053 8.43526 3.89388 8.26527 3.73286 8.16842C3.15278 7.81955 2.65978 7.34071 2.29372 6.76806C1.87256 6.10923 1.63464 5.35004 1.60445 4.56868C1.57425 3.78732 1.75289 3.01204 2.12195 2.32267C2.49101 1.63331 3.03715 1.05478 3.70414 0.646665C4.37113 0.238551 5.13485 0.0156061 5.91665 0.000789912C6.69845 -0.0140263 7.47007 0.179822 8.15204 0.562371C8.83402 0.94492 9.40169 1.50234 9.7966 2.17723ZM4.96644 3.31861C5.21338 3.07168 5.54829 2.93295 5.89751 2.93295C6.17365 2.93295 6.39751 2.70909 6.39751 2.43295C6.39751 2.15681 6.17365 1.93295 5.89751 1.93295C5.28307 1.93295 4.69381 2.17703 4.25933 2.6115C3.82486 3.04597 3.58078 3.63524 3.58078 4.24968C3.58078 4.52582 3.80464 4.74968 4.08078 4.74968C4.35692 4.74968 4.58078 4.52582 4.58078 4.24968C4.58078 3.90046 4.71951 3.56554 4.96644 3.31861Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

LightBulb.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default LightBulb;
