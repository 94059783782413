import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import NavigationPaneHeaderStyle from './components/NavigationPaneHeaderStyle';
import NavigationPaneHeaderWrapper from './components/NavigationPaneHeaderWrapper';
import SearchInput from '../SearchInput';
import padding from '../../commonResources/padding';
import Tooltip from '../Tooltip';
import useIsTruncated from '../../commonResources/hooks/useIsTruncated';

export const NavigationPaneHeader = ({
  domID = null,
  title = '',
  icon: Icon = null,
  searchInputProps = null,
  paddingLeft = padding.navigationPaneNumber,
  className = '',
}) => {
  const theme = useTheme();
  const ref = useRef(false);
  const isTruncated = useIsTruncated(ref, [title]);

  return (
    <div className={className}>
      <NavigationPaneHeaderWrapper paddingLeft={paddingLeft}>
        {Icon && (
          <span>
            <Icon
              size="medium"
              fillColor={theme.BaseColors.Primary['115']}
              className="title-icon"
            />
          </span>
        )}
        <Tooltip
          tooltipContent={title}
          domID={`${domID}-navigation-pane-title-tooltip`}
          hideTooltip={!isTruncated}
          isButtonRoleDisabled
          style={{ overflow: 'hidden' }}
        >
          <NavigationPaneHeaderStyle id={domID} ref={ref}>
            {title}
          </NavigationPaneHeaderStyle>
        </Tooltip>
      </NavigationPaneHeaderWrapper>
      {searchInputProps && (
        <div style={{ padding: `0 ${paddingLeft}px 8px` }}>
          <SearchInput {...searchInputProps} />
        </div>
      )}
    </div>
  );
};

NavigationPaneHeader.propTypes = {
  title: PropTypes.string.isRequired,
  domID: PropTypes.string,
  // eslint-disable-next-line consistent-return
  icon: (props, propName, componentName) => {
    if (props.icon && !props.title) {
      return new Error(
        `if 'Icon' is present then 'title' is required by '${componentName}' component.`,
      );
    }
  },

  /** Padding to add to the  */
  paddingLeft: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  searchInputProps: PropTypes.object,
  className: PropTypes.string,
};

export default NavigationPaneHeader;
