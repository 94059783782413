import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MicroModal from '../../../Molecules/MicroModal';
import ButtonGroup from '../../../Molecules/ButtonGroup';
import Input from '../../../Atoms/Input';
import Button from '../../../Atoms/Button';

const Wrapper = styled.div`
  width: 280px;
  padding: 20px 24px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 13px;
`;

const NameSearchDropdown = ({
  index = undefined,
  onCancel = () => false,
  onSave = () => false,
  label = 'Name Your Search',
  initialValue = '',
  anchorComponent = undefined,
  anchorProps = {},
  ...otherProps
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const title = useRef('');

  return (
    <MicroModal
      anchorComponent={anchorComponent}
      anchorProps={anchorProps}
      onClickAway={onCancel}
      orientation="bottom"
      caretToAnchor={0}
      {...otherProps}
    >
      <Wrapper>
        <Input
          domID="save-search-input"
          label={label}
          className="save-search-input"
          onChange={(e, { value }) => {
            title.current = value;
            if (value.length > 0) {
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
          }}
          onEnterPress={() => onSave(title.current, index || null)}
          initialValue={initialValue}
        />
        <StyledButtonGroup>
          <Button buttonType="diminished" name="Cancel" onClick={onCancel} />
          <Button
            buttonType="emphasized"
            name="Save"
            onClick={() => onSave(title.current, index || null)}
            dataTestId="save-search-dropdown-button"
            disabled={isDisabled}
          />
        </StyledButtonGroup>
      </Wrapper>
    </MicroModal>
  );
};

NameSearchDropdown.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  index: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /* eslint-disable react/forbid-prop-types */
  anchorProps: PropTypes.object,
  anchorComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default NameSearchDropdown;
