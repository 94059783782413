import React from 'react';
import { components } from 'react-select';
import { useTheme } from 'styled-components';
import Down from '../../../BrandCore/icons/interactive/Down';

export const DropdownIndicator = (props) => {
  const theme = useTheme();

  return (
    <components.DropdownIndicator {...props}>
      <Down size="small" fillColor={theme.UIPalette.Colors.Content.Secondary} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
