import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Previous = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Previous'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M15.8669 3.13947C16.0444 3.32544 16.0444 3.62694 15.8669 3.81291L8.09737 12L15.8669 20.1871C16.0444 20.3731 16.0444 20.6746 15.8669 20.8605C15.6894 21.0465 15.4016 21.0465 15.224 20.8605L7.13313 12.3367C6.95562 12.1508 6.95562 11.8492 7.13313 11.6633L15.224 3.13947C15.4016 2.95351 15.6894 2.95351 15.8669 3.13947Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Previous'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.82008 0.106986C8.60794 -0.056197 8.29266 -0.0297398 8.11588 0.16608L3.11588 5.70453C2.96136 5.87569 2.96136 6.12431 3.11588 6.29547L8.11588 11.8339C8.29266 12.0297 8.60794 12.0562 8.82008 11.893C9.03222 11.7298 9.06088 11.4388 8.8841 11.243L4.15084 6L8.8841 0.757018C9.06088 0.561199 9.03222 0.270169 8.82008 0.106986Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Previous.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Previous;
