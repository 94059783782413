import { makeNotificationHeaders } from './makeNotificationHeaders'

export const markAllNotificationsRead = async (
  notifications,
  onSuccess,
  onFailure
) => {
  const { restUrl } = JSON.parse(sessionStorage.getItem('notificationsConfig'))
  const data = notifications.map(notification => ({
    ...notification,
    isRead: true
  }))
  try {
    const response = await fetch(restUrl, {
      headers: makeNotificationHeaders(),
      method: 'POST',
      body: JSON.stringify({ notifications: data })
    })
    const json = await response.json()
    return onSuccess(json)
  } catch (e) {
    return onFailure(e)
  }
}
