import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import StyledCollapsePanelDetails from './components/CollapsePanelDetails';

export const CollapsePanelDetails = ({
  children,
  expanded,
  ariaLabelledBy,
  id,
}) => {
  const detailsRef = useRef(null);
  const childRef = useRef(null);

  const handleEntering = () => {
    const detailsHeight = childRef.current ? childRef.current.clientHeight : 0;
    const { style } = detailsRef.current;
    style.height = `${detailsHeight}px`;
  };

  const handleEntered = () => {
    const { style } = detailsRef.current;
    style.height = 'auto';
    style.overflow = 'initial';
  };

  const handleExit = () => {
    const detailsHeight = detailsRef.current
      ? detailsRef.current.clientHeight
      : 0;
    const { style } = detailsRef.current;
    style.height = `${detailsHeight}px`;
  };

  const handleExiting = () => {
    setTimeout(() => {
      const { style } = detailsRef.current;
      style.height = '0px';
      style.overflow = 'hidden';
    });
  };
  return (
    <Transition
      in={expanded}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      appear
      timeout={300}
      nodeRef={detailsRef}
    >
      <StyledCollapsePanelDetails
        aria-labelledby={ariaLabelledBy}
        id={id}
        role="region"
        ref={detailsRef}
      >
        <div ref={childRef}>{children}</div>
      </StyledCollapsePanelDetails>
    </Transition>
  );
};

CollapsePanelDetails.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** The prop is for CollapsePanel to control status, don't use it. */
  expanded: PropTypes.bool,
  /** The prop is for CollapsePanel to set, don't use it. */
  ariaLabelledBy: PropTypes.string,
  /** The prop is for CollapsePanel to set, don't use it. */
  id: PropTypes.string,
};

export default CollapsePanelDetails;
