import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ArrowBottomRight = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'small',
}) => {
  switch (size) {
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ArrowBottomRight'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.85355 1.14645C1.65829 0.951184 1.34171 0.951184 1.14645 1.14645C0.951184 1.34171 0.951184 1.65829 1.14645 1.85355L9.29289 10H4.5C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.4995H10.5C10.501 11 10.502 11 10.503 11C10.5697 10.9996 10.6333 10.9861 10.6914 10.9621C10.7504 10.9377 10.8056 10.9015 10.8536 10.8536C10.9015 10.8056 10.9377 10.7504 10.9621 10.6914C10.9842 10.6378 10.9967 10.5811 10.9994 10.524C10.9998 10.516 11 10.508 11 10.5C11 10.4998 11 10.4997 11 10.4995V4.5C11 4.22386 10.7761 4 10.5 4C10.2239 4 10 4.22386 10 4.5V9.29289L1.85355 1.14645Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ArrowBottomRight.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ArrowBottomRight;
