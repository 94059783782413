import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /** By default apply styles of medium **/
  > * {
    margin-right: 12px;
  }

  > *.small {
    margin-right: 8px;
  }
  > *.medium {
    margin-right: 12px;
  }
  > *.large {
    margin-right: 16px;
  }
  > *:last-child {
    margin-right: 0px;
  }
`;

export default ButtonGroup;
