import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import PopOut from '../../BrandCore/icons/interactive/PopOut';
import Expand from '../../BrandCore/icons/interactive/Expand';
import Collapse from '../../BrandCore/icons/interactive/Collapse';
import Close from '../../BrandCore/icons/interactive/Close';
import Next from '../../BrandCore/icons/interactive/Next';
import Previous from '../../BrandCore/icons/interactive/Previous';

export const tooltipTextMap = {
  popout: 'Pop Out',
  expand: 'Expand',
  collapse: 'Collapse',
  close: 'Close',
  next: 'Next',
  previous: 'Previous',
};

const svgMap = {
  popout: PopOut,
  expand: Expand,
  collapse: Collapse,
  close: Close,
  next: Next,
  previous: Previous,
};

const LayoutControl = ({
  domID = null,
  className = '',
  dataTestId = null,
  buttonType = 'popout',
  tooltipPosition = 'top-center',
  hideTooltip = false,
  onClick = () => false,
}) => (
  <IconButton
    icon={svgMap[buttonType]}
    size="large"
    onClick={onClick}
    title={buttonType}
    data-testid={dataTestId}
    className={className}
    tooltipProps={{
      tooltipContent: tooltipTextMap[buttonType],
      tooltipWidth: 80,
      tooltipPosition,
      hideTooltip,
      domID: `${domID}-tooltip`,
      dataTestId: `${dataTestId}-tooltip`,
      isButtonRoleDisabled: true,
    }}
  />
);

LayoutControl.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** type of layout control to be rendered */
  buttonType: PropTypes.oneOf([
    'popout',
    'expand',
    'collapse',
    'close',
    'next',
    'previous',
  ]),
  /** position of tooltip which appears when hovering over layout control */
  tooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  /** if `true`, no tooltip will appear when hovering over the layout control */
  hideTooltip: PropTypes.bool,
  /** takes event */
  onClick: PropTypes.func,
};

export default LayoutControl;
