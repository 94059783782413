import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Atoms/Button';
import StandardMenu from '../StandardMenu';
import ListWrapper from './components/ListWrapper';
import heights from '../../BrandCore/constants/heights';

const BUTTON_HEIGHT_MAP = { ...heights.input };

const BORDER_THICKNESS = 2;

export const DropdownButton = ({
  // Button props
  buttonID = null,
  buttonClass = '',
  name = '',
  buttonType = 'standard',
  size = 'medium',
  disabled = false,
  onFocus = () => false,
  onBlur = () => false,
  onClick = () => false,
  onClickAway = () => false,
  onMenuClick = () => false,
  dataTestId = '',
  type = 'button',

  // List props
  listID = null,
  listClass = '',
  sectionTitleClass = '',
  orientation = 'bottom-left',
  menuItemNames = null,
  menuItems = [],
  sections = [],
  showActiveIndicator = false,
  selectedItem: { id: selectedItemId = null } = {},
  shouldTruncateName = false,
  tooltipStyle = {},
  tooltipProps = {},
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const [selectedItemIds, setSelectedItemIds] = useState([selectedItemId]);

  useEffect(() => setSelectedItemIds([selectedItemId]), [selectedItemId]);

  const handleButtonClick = (e) => {
    e.persist();
    setDropdownIsOpen(!dropdownIsOpen);
    onClick(e, { dropdownIsOpen, selectedItemIds });
  };

  const handleClickAway = (e) => {
    setDropdownIsOpen(false);
    onClickAway(e, { dropdownIsOpen, selectedItemIds });
  };

  // For convenience, the current active item is passed into state when onMenuClick prop callback is called.
  // This variable does not otherwise exist in local state.
  const handleMenuClick = (e, state) => {
    e.persist();
    setDropdownIsOpen(false);
    setSelectedItemIds([state.activeItem.id]);
    onMenuClick(e, {
      dropdownIsOpen: false,
      activeMenuItemIndex: state.activeMenuItemIndex,
      activeSectionIndex: state.activeSectionIndex,
      activeItem: state.activeItem,
    });
  };

  const buttonClassName = `${buttonClass} ${dropdownIsOpen ? 'open' : ''}`;

  return (
    <Button
      domID={buttonID}
      className={buttonClassName}
      buttonType={buttonType}
      size={size}
      name={name}
      onFocus={onFocus}
      onBlur={onBlur}
      dataTestId={dataTestId}
      onClick={handleButtonClick}
      disabled={disabled}
      type={type}
      isDropdown
      shouldTruncateName={shouldTruncateName}
      tooltipStyle={tooltipStyle}
      tooltipProps={tooltipProps}
    >
      {dropdownIsOpen && !disabled ? (
        <ListWrapper buttonHeight={BUTTON_HEIGHT_MAP[size] + BORDER_THICKNESS}>
          <StandardMenu
            dataTestId={dataTestId && `${dataTestId}-standardMenu`}
            domID={listID}
            className={`${orientation} ${listClass}`}
            sectionTitleClass={sectionTitleClass}
            menuItemNames={menuItemNames}
            menuItems={menuItems}
            sections={sections}
            onMenuClick={(e, state) => handleMenuClick(e, state)}
            onClickOut={handleClickAway}
            showActiveIndicator={showActiveIndicator}
            selectedItems={selectedItemIds}
          />
        </ListWrapper>
      ) : null}
    </Button>
  );
};

export const dropdownButtonPropTypes = {
  // Button props
  buttonID: PropTypes.string,
  buttonClass: PropTypes.string,
  dataTestId: PropTypes.string,
  /** the text appearing on the button */
  name: PropTypes.string,
  /** styling of button: see button stories for more information */
  buttonType: PropTypes.oneOf([
    'standard',
    'destroy',
    'emphasized',
    'emphasizedAlt',
    'deEmphasized',
    'deEmphasizedReversed',
    'diminished',
    'unstyled',
  ]),
  /** size of button: see button stories for more information */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),

  // List props
  listID: PropTypes.string,
  listClass: PropTypes.string,
  /** This can be used when the styles of section title need to be altered */
  sectionTitleClass: PropTypes.string,
  /** position of dropdown list popup when button is clicked */
  orientation: PropTypes.oneOf([
    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
  ]),
  /** config data for lists without sections */
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  /** config data for list without sections in which menu items have additional properties */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),

  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    labelDecoration: PropTypes.string,
    isSecondary: PropTypes.bool,
  }),

  /** config data for dropdown list sections */
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
          isSecondary: PropTypes.bool,
        }),
      ),
      PropTypes.shape({
        title: PropTypes.string,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
            isSecondary: PropTypes.bool,
          }),
        ),
      }),
    ]),
  ),

  /**
   * Runs when button is clicked
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.dropdownIsOpen
   */
  onClick: PropTypes.func,

  /**
   * Runs when user clicks outside of dropdown list
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.dropdownIsOpen
   */
  onClickAway: PropTypes.func,

  /**
   * Runs when menu/list item is clicked
   *
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Object} state.activeItem
   * @prop {Number|String} state.activeItem.id
   * @prop {String} state.activeItem.label
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.dropdownIsOpen
   */
  onMenuClick: PropTypes.func,

  /** receives event parameter */
  onFocus: PropTypes.func,
  /** receives event parameter */
  onBlur: PropTypes.func,

  /** indicates whether the most recently-clicked menu/list item shows an active dot */
  showActiveIndicator: PropTypes.bool,
  /** truncate name */
  shouldTruncateName: PropTypes.bool,
  /**
   * customize tooltip style
   */
  tooltipStyle: PropTypes.objectOf(PropTypes.string),
  tooltipProps: PropTypes.object,
};

DropdownButton.propTypes = dropdownButtonPropTypes;

export default DropdownButton;
