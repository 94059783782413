import styled from 'styled-components';
import Borders from '../../../BrandCore/shapes/borders';

export default styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 23px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 4px 8px;
  ${Borders.borderRadius4};
  background-color: ${(props) => props.theme.BaseColors.NeutralAlt['100']};

  .tooltip-wrapper {
    cursor: pointer;

    &:focus-visible {
      outline: 1px solid
        ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
      outline-offset: -2px;
    }
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    ${Borders.borderRadius4};
    background-color: ${(props) => props.theme.BaseColors.NeutralAlt['75']};

    &:hover {
      background-color: ${(props) => props.theme.BaseColors.NeutralAlt['70']};

      svg path {
        fill: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentLight};
      }
    }
  }
`;
