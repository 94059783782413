import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextWrapper from './styleWrappers/TextWrapper';
import SecondLineWrapper from './styleWrappers/SecondLineWrapper';

const FirstLine = styled(TextWrapper)`
  margin-top: 8px;
  ${({ hasSecondLine }) => !hasSecondLine && 'margin-bottom: 8px;'}
`;

const TextCell = ({ data = '', cellAlign = 'left' }) => {
  const firstLine =
    data && typeof data === 'object' ? data.firstLine : data || '';
  const secondLine = data && typeof data === 'object' ? data.secondLine : null;

  return (
    <>
      <FirstLine align={cellAlign} hasSecondLine={!!secondLine}>
        {firstLine}
      </FirstLine>
      {secondLine && (
        <SecondLineWrapper align={cellAlign}>{secondLine}</SecondLineWrapper>
      )}
    </>
  );
};

TextCell.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      firstLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default TextCell;
