import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DetailPaneHeader from '../../../Molecules/DetailPaneHeader';
import { DetailPaneHeaderPropTypes } from '../../../Molecules/DetailPaneHeader/DetailPaneHeader';

// stylelint-disable property-no-vendor-prefix
const ModalDetailPaneHeader = styled(DetailPaneHeader)`
  -ms-grid-row: 1;
  grid-area: header;
  width: 100%;
`;

const ModalHeader = ({ onClose = null, handleClose, ...props }) => {
  const layoutControls = [];

  if (onClose) {
    layoutControls.push({
      buttonType: 'close',
      className: 'modal-close',
      hideTooltip: true,
      onClick: (e) => {
        if (handleClose && typeof handleClose === 'function') {
          handleClose(() => onClose(e, false));
        }
        onClose(e, false);
      },
    });
  }

  return <ModalDetailPaneHeader {...props} layoutControls={layoutControls} />;
};

ModalHeader.displayName = 'ModalHeader';

ModalHeader.propTypes = {
  ...DetailPaneHeaderPropTypes,
  /**
   * When this prop is passed layout control is added to the header to allow user to
   * close the modal by clicking on this button.
   */
  onClose: PropTypes.func,
  /**
   * Decorated function for annimation from ModalContnent Component
   */
  handleClose: PropTypes.func,
};

export default ModalHeader;
