import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const ThumbsDown = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ThumbsDown'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.25007 0.5C0.973928 0.5 0.750071 0.723858 0.750071 1V12C0.750071 12.2761 0.973928 12.5 1.25007 12.5H5.86817L8.76769 23.1316C8.82701 23.3491 9.0246 23.5 9.25007 23.5C10.1783 23.5 11.0686 23.1313 11.7249 22.4749C12.3813 21.8185 12.7501 20.9283 12.7501 20V14.5H19.7501H19.7501C20.2547 14.5 20.7533 14.3908 21.2118 14.1801C21.6703 13.9693 22.0777 13.6619 22.4063 13.279C22.7349 12.896 22.9767 12.4465 23.1153 11.9613C23.2539 11.4761 23.286 10.9667 23.2093 10.468L23.2093 10.468L22.1323 3.468L22.1323 3.46797C22.0053 2.64225 21.5871 1.8892 20.9534 1.34488C20.3196 0.800553 19.512 0.500875 18.6766 0.5H18.6761H6.25007H1.25007ZM5.75007 1.5H1.75007V11.5H5.75007V1.5ZM6.75007 11.933V1.5H18.6758C19.2724 1.50068 19.8492 1.71473 20.3018 2.10348C20.7545 2.49229 21.0532 3.0302 21.1439 3.62L21.1439 3.62003L22.2209 10.62L22.2209 10.62C22.2757 10.9763 22.2528 11.3401 22.1538 11.6867C22.0548 12.0332 21.882 12.3543 21.6473 12.6278C21.4126 12.9014 21.1216 13.121 20.7941 13.2715C20.4666 13.422 20.1105 13.5 19.7501 13.5H19.75H12.2501C11.9739 13.5 11.7501 13.7239 11.7501 14V20C11.7501 20.663 11.4867 21.2989 11.0178 21.7678C10.6381 22.1475 10.1488 22.3924 9.62429 22.4718L6.75007 11.933Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ThumbsDown'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.50022 16C9.70864 16 9.8952 15.8707 9.96838 15.6756L12.8467 8L15.5002 8C15.7764 8 16.0002 7.77614 16.0002 7.5L16.0002 0.5C16.0002 0.223858 15.7764 -1.95703e-08 15.5002 -4.37114e-08L12.5002 -3.0598e-07L3.89022 -1.05869e-06C2.76793 -1.1568e-06 1.78166 0.748461 1.48146 1.83117L1.48138 1.83146L0.09247 6.83114C-0.350057 8.42374 0.847533 10 2.50122 10L7.00022 10L7.00022 14.5C7.00022 15.3281 7.67208 16 8.50022 16L9.50022 16ZM13.0002 1L13.0002 7L15.0002 7L15.0002 1L13.0002 1ZM12.0002 7.40933L9.15372 15L8.50022 15C8.22436 15 8.00022 14.7759 8.00022 14.5L8.00022 9.5C8.00022 9.22386 7.77636 9 7.50022 9L2.50122 9C1.5089 9 0.790495 8.05426 1.05597 7.09886L1.05597 7.09883L2.44498 2.09883L2.44506 2.09853C2.62496 1.4494 3.21661 0.999999 3.89022 0.999999L12.0002 1L12.0002 7.40933Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'ThumbsDown'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#thumbs-down)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M7.38504 12C7.60026 12 7.79132 11.8623 7.85938 11.6581L9.74542 6L11.385 6C11.6612 6 11.885 5.77614 11.885 5.5L11.885 0.5C11.885 0.223858 11.6612 -1.95703e-08 11.385 -4.37114e-08L9.38504 -2.18557e-07L2.68504 -8.0429e-07L2.68473 -8.04317e-07C2.20348 0.000301468 1.73848 0.174118 1.37504 0.489561C1.01159 0.805004 0.774075 1.24092 0.70606 1.71733L0.13506 5.71733C0.0945631 6.00099 0.11549 6.29004 0.196423 6.56491C0.277357 6.83978 0.416407 7.09405 0.604159 7.3105C0.791912 7.52695 1.02398 7.70053 1.28466 7.81949C1.54533 7.93845 1.82851 8.00001 2.11504 8L2.11506 8L4.88504 8L4.88504 10C4.88504 10.5304 5.09575 11.0391 5.47083 11.4142C5.8459 11.7893 6.35461 12 6.88504 12L7.38504 12ZM9.88504 1L9.88504 5L10.885 5L10.885 1L9.88504 1ZM8.88504 5.41886L7.02466 11L6.88504 11C6.61982 11 6.36547 10.8946 6.17793 10.7071C5.9904 10.5196 5.88504 10.2652 5.88504 10L5.88504 7.5C5.88504 7.22386 5.66118 7 5.38504 7L2.11504 7L2.11502 7C1.97175 7 1.83016 6.96923 1.69982 6.90975C1.56948 6.85027 1.45345 6.76347 1.35957 6.65525C1.2657 6.54702 1.19617 6.41989 1.1557 6.28245C1.11524 6.14502 1.10477 6.0005 1.12502 5.85867L1.69602 1.85867L1.69602 1.85866C1.73003 1.62045 1.84879 1.4025 2.03051 1.24478C2.21223 1.08706 2.44473 1.00015 2.68536 0.999999L8.88504 1L8.88504 5.41886Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="thumbs-down">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

ThumbsDown.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default ThumbsDown;
