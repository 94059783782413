import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MASTHEAD_HEIGHT } from '../../BrandCore/constants/heights';

const grid = {
  columns: '1fr',
  rows: 'auto 1fr auto',
};

// stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix
const ContainerWithStickyFooterWrapper = styled.div`
  display: -ms-grid;
  display: grid; /* stylelint-disable-line declaration-block-no-duplicate-properties */
  grid-template-columns: ${grid.columns};
  -ms-grid-columns: ${grid.columns};
  grid-template-rows: ${grid.rows};
  -ms-grid-rows: ${grid.rows};
  width: 100%;
  height: ${(props) => props.height || '100%'};
  grid-template-areas:
    'header'
    'content'
    'footer';
`;

const FooterWrapper = styled.div`
  -ms-grid-row: 3;
  grid-area: footer;
`;

const HeaderWrapper = styled.div`
  -ms-grid-row: 1;
  grid-area: header;
`;

const ContentWrapper = styled.div`
  -ms-grid-row: 2;
  grid-area: content;
  overflow-y: auto;
`;

const StickyLayout = ({
  headerContent = null,
  footerContent = null,
  children = null,
  height = `calc(100vh - ${MASTHEAD_HEIGHT}px)`,
}) => {
  return (
    <ContainerWithStickyFooterWrapper height={height}>
      <HeaderWrapper>{headerContent}</HeaderWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <FooterWrapper>{footerContent}</FooterWrapper>
    </ContainerWithStickyFooterWrapper>
  );
};

StickyLayout.propTypes = {
  /**
   * Content to stick at the top
   */
  headerContent: PropTypes.element,
  /**
   * Content to stick at bottom
   */
  footerContent: PropTypes.element,
  /**
   * Content that needs scrolling
   */
  children: PropTypes.element,
  /**
   * By default this components height is calculated as 100vh - MASTHEAD_HEIGHT.
   * Use this prop to override the default.
   */
  height: PropTypes.string,
};

export default StickyLayout;
