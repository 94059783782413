import UIPalette from './uiPalette';
import {
  primary,
  secondary,
  tertiary,
  quaternary,
  negative,
  caution,
  positive,
  neutralAlt,
  accent,
  neutral,
  white,
  black,
} from './basePalette';
import BoxShadows from './boxShadows';

const BaseTheme = {
  BaseColors: {
    Primary: primary,
    Secondary: secondary,
    Tertiary: tertiary,
    Quaternary: quaternary,
    Negative: negative,
    Caution: caution,
    Positive: positive,
    NeutralAlt: neutralAlt,
    Accent: accent,
    Neutral: neutral,
    White: white,
    TrueWhite: white,
    Black: black,
  },
  UIPalette,
  BoxShadows,
};

// eslint-disable-next-line import/prefer-default-export
export { BaseTheme };
