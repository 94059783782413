import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import GridHeaderAtom from '../../../Atoms/Grid/ColumnHeader';

import SelectAllControl from './SelectAllControl';
import Cog from '../../../BrandCore/icons/interactive/Cog';
import IconButton from '../../../Atoms/IconButton';
import { VisuallyHiddenText } from '../../../commonResources/styles/a11y';

/*
The header expects a Set of column objects with attributes:
dataName [string, required]
text [string, required]
sortable [boolean, required]
isSorted [0|1, optional]

new Set([
  { dataName: 'patientName', text: 'Patient Name', sortable: true, isSorted: false },
  ...
])
*/

export const GridHeader = (props) => {
  const {
    dataTestId = null,
    supportSelection = false,
    columns = new Set([]),
    onToggleSettingsModal = () => false,
    isConfigurable = true,
    supportRadioSelection = false,
    sortingDataName = '',
    sortingDirection = 0,
    disabledColumns = new Set([]),
    onSortGridColumn = () => false,
    focusedItem = null,
  } = props;

  const testIDs = {
    triggerButton: dataTestId ? `${dataTestId}-triggerButton` : null,
    selectAllControl: dataTestId ? `${dataTestId}-selectAllControl` : null,
  };

  const visibleColumns = [...columns.values()].filter(
    (column) => !disabledColumns.has(column.dataName),
  );

  return (
    <thead>
      <tr>
        {supportSelection ? (
          <SelectAllControl
            className={clsx(
              !(!focusedItem || focusedItem === null) && 'hidden',
            )}
            {...props}
            dataTestId={testIDs.selectAllControl}
          />
        ) : null}

        {supportRadioSelection && (!focusedItem || focusedItem === null) ? (
          <th className="selection-column radio" key="select column">
            <VisuallyHiddenText>Select</VisuallyHiddenText>
          </th>
        ) : null}

        {!supportRadioSelection && !supportSelection && (
          <th style={{ width: 40 }}> </th>
        )}

        {visibleColumns.map((column, index) => (
          <th
            className={null}
            key={column.dataName}
            data-testid={`header-${column.dataName}`}
          >
            <div style={{ display: 'flex' }}>
              <GridHeaderAtom
                sortable={column.sortable}
                onSort={(e) => {
                  onSortGridColumn(column.dataName, e);
                }}
                isSorted={sortingDataName === column.dataName}
                sortingDirection={sortingDirection}
                text={column.text}
                align={column.align}
                isHeaderTruncated={column.isHeaderTruncated}
                headerWidth={column.headerWidth}
              />
              {isConfigurable && index === visibleColumns.length - 1 && (
                <IconButton
                  size="small"
                  data-testid={testIDs.triggerButton}
                  onClick={onToggleSettingsModal}
                  title="Column Settings"
                  className="settings-trigger"
                  icon={Cog}
                />
              )}
            </div>
          </th>
        ))}
        {visibleColumns.length === 0 && (
          <th colSpan={columns.length} style={{ float: 'right' }}>
            <div style={{ padding: '10px 2px' }}>
              <IconButton
                size="small"
                data-testid={testIDs.triggerButton}
                onClick={onToggleSettingsModal}
                title="Column Settings"
                className="settings-trigger"
                icon={Cog}
              />
            </div>
          </th>
        )}
      </tr>
    </thead>
  );
};

GridHeader.propTypes = {
  dataTestId: PropTypes.string,
  columns: PropTypes.instanceOf(Set),
  supportSelection: PropTypes.bool,
  supportRadioSelection: PropTypes.bool,
  onToggleSettingsModal: PropTypes.func,
  isConfigurable: PropTypes.bool,
  disabledColumns: PropTypes.instanceOf(Set).isRequired,
  sortingDataName: PropTypes.string,
  sortingDirection: PropTypes.number,
  onSortGridColumn: PropTypes.func,
  /**
   * When used in workflow, this prop takes the item that is currently open in detail pane.
   * This is used to highlight the row in the main grid.
   */
  focusedItem: PropTypes.oneOfType([PropTypes.object]),
};

export default GridHeader;
