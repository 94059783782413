import React from 'react';
import PropTypes from 'prop-types';

import GroupTitle from '../../Atoms/GroupTitle';
import TitleButton from './components/TitleButton';
import Count from '../../Atoms/Count';

import {
  LeftWrapper,
  Wrapper,
  Description,
} from './components/StyledComponents';
import Chip from '../../Atoms/Chip';
import { ChipPropTypes } from '../../Atoms/Chip/Chip';

export const GroupHeader = ({
  domID = '',
  className = '',
  dataTestId = '',
  title = '',
  count = null,
  headerChildren = null,
  isAccordion = false,
  isCollapsed = true,
  onClick = () => false,
  parentID = '',
  chipOptions = {},
  description = '',
}) => {
  const domIDs = {
    count: domID ? `${domID}-count` : null,
    chip: domID ? `${domID}-chip` : null,
    title: domID ? `${domID}-title` : null,
    headerChildren: domID ? `${domID}-headerChildren` : null,
    toggleButton: domID ? `${domID}-toggleButton` : null,
  };

  const testIDs = {
    count: dataTestId ? `${dataTestId}-count` : null,
    chip: dataTestId ? `${dataTestId}-chip` : null,
    title: dataTestId ? `${dataTestId}-title` : null,
    headerChildren: dataTestId ? `${dataTestId}-headerChildren` : null,
  };

  return (
    <Wrapper id={domID} className={className} data-testid={dataTestId}>
      <LeftWrapper role={isAccordion ? 'menu' : ''}>
        {isAccordion ? (
          <TitleButton
            domID={domIDs.toggleButton}
            dataTestId={testIDs.title}
            title={title}
            isCollapsed={isCollapsed}
            onClick={onClick}
            parentID={parentID}
            size="small"
          />
        ) : (
          <GroupTitle
            domID={domIDs.title}
            dataTestId={testIDs.title}
            title={title}
          />
        )}

        {count ? (
          <Count
            domID={domIDs.count}
            count={count}
            size="small"
            className="group-header-count"
            dataTestId={testIDs.count}
            tooltipWrapperStyle={{
              marginLeft: '12px',
              alignSelf: 'center',
            }}
          />
        ) : null}

        {description ? (
          <Description
            id={domIDs.description}
            data-testid={testIDs.description}
            className="group-description"
          >
            {description}
          </Description>
        ) : null}

        {chipOptions.label && (
          <Chip
            {...chipOptions}
            className="group-header-chip"
            dataTestId={testIDs.chip}
            domID={domIDs.chip}
          />
        )}

        {headerChildren && (
          <span
            className="header-children"
            data-testid={testIDs.headerChildren}
            id={domIDs.headerChildren}
          >
            {headerChildren}
          </span>
        )}
      </LeftWrapper>
    </Wrapper>
  );
};

GroupHeader.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  /** ID for automated testing */
  dataTestId: PropTypes.string,
  /** The displayed text/title */
  title: PropTypes.string.isRequired,
  /** Short description of the group */
  description: PropTypes.string,
  /** value shown in `Count` component pill to left of title */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** elements to render to right of title, usually a button */
  headerChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /** if `true`, shows Collapse Arrow component & renders title as button */
  isAccordion: PropTypes.bool,
  /** if `true`, and `isAccordion` is also `true`, the CollapseArrow rotates 270deg */
  isCollapsed: PropTypes.bool,
  /** if `isAccordion` is true, defines the callback action when title/collapse arrow is clicked.  Receives event */
  onClick: PropTypes.func,
  /** domID of `Group` component, used for `aria-controls` prop in toggleButton */
  parentID: PropTypes.string,
  chipOptions: PropTypes.shape({ ...ChipPropTypes, label: PropTypes.string }),
};

export default GroupHeader;
