import styled from 'styled-components';
import StackingContext from '../../../commonResources/stackingContext';
import { MODAL_ANIMATION } from '../../../BrandCore/constants/timings';

export default styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.UIPalette.Colors.Background.Modal};
  ${StackingContext.modal};
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity ${MODAL_ANIMATION}ms, visibility ${MODAL_ANIMATION}ms,
    backdrop-filter ${MODAL_ANIMATION}ms;

  &.active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
`;
