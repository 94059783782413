import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Triangle0 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 2C12.182 2 12.3496 2.09888 12.4376 2.25816L22.9376 21.2582C23.0232 21.413 23.0206 21.6016 22.9307 21.754C22.8407 21.9065 22.677 22 22.5 22H1.5C1.32304 22 1.15926 21.9065 1.06935 21.754C0.979436 21.6016 0.976786 21.413 1.06238 21.2582L11.5624 2.25816C11.6504 2.09888 11.818 2 12 2ZM2.34759 21H21.6524L12 3.53373L2.34759 21Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 1C8.17869 1 8.3438 1.09536 8.43309 1.25014L15.9331 14.2501C16.0223 14.4048 16.0223 14.5954 15.933 14.7501C15.8436 14.9047 15.6786 15 15.5 15H0.5C0.321396 15 0.156354 14.9047 0.0670274 14.7501C-0.0222995 14.5954 -0.0223453 14.4048 0.0669072 14.2501L7.56691 1.25014C7.6562 1.09536 7.82131 1 8 1ZM1.3657 14H14.6343L8 2.50056L1.3657 14Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Triangle 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6 1C6.17869 1 6.3438 1.09536 6.43309 1.25014L11.9331 10.2501C12.0223 10.4048 12.0223 10.5954 11.933 10.7501C11.8436 10.9047 11.6786 11 11.5 11H0.5C0.321396 11 0.156354 10.9047 0.0670274 10.7501C-0.0222995 10.5954 -0.0223453 10.4048 0.0669072 10.2501L5.56691 1.25014C5.6562 1.09536 5.82131 1 6 1ZM1.3657 10H10.6343L6 2.50056L1.3657 10Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Triangle0.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Triangle0;
