import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Globe = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Globe'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.5 1C17.8513 1 23 6.14873 23 12.5C23 18.8513 17.8513 24 11.5 24C5.14873 24 0 18.8513 0 12.5C0 6.14873 5.14873 1 11.5 1ZM1.0117 13C1.22581 17.5671 4.35776 21.3712 8.58547 22.5902C8.04386 22.0208 7.56634 21.3071 7.1637 20.5018C6.18291 18.5402 5.56897 15.9021 5.50546 13H1.0117ZM14.4145 22.5902C18.6422 21.3712 21.7742 17.5671 21.9883 13H17.4945C17.431 15.9021 16.8171 18.5402 15.8363 20.5018C15.4337 21.3071 14.9561 22.0208 14.4145 22.5902ZM21.9883 12C21.7742 7.43286 18.6422 3.62876 14.4145 2.40978C14.9561 2.97915 15.4337 3.69294 15.8363 4.49822C16.8171 6.4598 17.431 9.09793 17.4945 12H21.9883ZM8.58548 2.40978C8.04386 2.97915 7.56634 3.69294 7.1637 4.49822C6.18291 6.4598 5.56897 9.09793 5.50546 12H1.0117C1.22581 7.43286 4.35776 3.62876 8.58548 2.40978ZM8.05813 4.94543C7.15807 6.74554 6.56919 9.22484 6.50571 12H11V2.0555C9.93959 2.29072 8.89538 3.27092 8.05813 4.94543ZM8.05813 20.0546C7.15807 18.2545 6.56919 15.7752 6.50571 13H11V22.9445C9.93959 22.7093 8.89538 21.7291 8.05813 20.0546ZM14.9419 20.0546C14.1046 21.7291 13.0604 22.7093 12 22.9445V13H16.4943C16.4308 15.7752 15.8419 18.2545 14.9419 20.0546ZM12 12H16.4943C16.4308 9.22484 15.8419 6.74554 14.9419 4.94543C14.1046 3.27092 13.0604 2.29072 12 2.0555V12Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Globe'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.34292 2.88239C5.87174 4.06034 5.55597 5.67914 5.50674 7.5H10.4933C10.444 5.67914 10.1283 4.06034 9.65708 2.88239C9.39958 2.23864 9.10547 1.75123 8.80446 1.43294C8.50445 1.11571 8.23243 1 8 1C7.76757 1 7.49555 1.11571 7.19554 1.43294C6.89453 1.75123 6.60042 2.23864 6.34292 2.88239ZM6.05058 1.27503C5.81105 1.63209 5.59878 2.05016 5.41444 2.511C4.88786 3.82746 4.55572 5.57727 4.50639 7.5H1.01758C1.22705 4.53194 3.28691 2.07476 6.05058 1.27503ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM9.94942 1.27504C10.189 1.63209 10.4012 2.05016 10.5856 2.511C11.1121 3.82746 11.4443 5.57727 11.4936 7.5H14.9824C14.7729 4.53194 12.7131 2.07476 9.94942 1.27504ZM14.9824 8.5H11.4936C11.4443 10.4227 11.1121 12.1725 10.5856 13.489C10.4012 13.9498 10.189 14.3679 9.94942 14.725C12.7131 13.9252 14.7729 11.4681 14.9824 8.5ZM8 15C8.23243 15 8.50445 14.8843 8.80446 14.5671C9.10547 14.2488 9.39958 13.7614 9.65708 13.1176C10.1283 11.9397 10.444 10.3209 10.4933 8.5H5.50674C5.55597 10.3209 5.87174 11.9397 6.34292 13.1176C6.60042 13.7614 6.89453 14.2488 7.19554 14.5671C7.49555 14.8843 7.76757 15 8 15ZM6.05058 14.725C5.81105 14.3679 5.59878 13.9498 5.41444 13.489C4.88786 12.1725 4.55572 10.4227 4.50639 8.5H1.01758C1.22705 11.4681 3.28691 13.9252 6.05058 14.725Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Globe'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.02469 5.5C1.21745 3.55855 2.52073 1.94379 4.29032 1.29993C4.20718 1.49507 4.11423 1.74105 4.02211 2.04046C3.78226 2.81999 3.549 3.9582 3.50677 5.5H1.02469ZM5.41755 1.27506L5.58987 1.01658C5.72513 1.0056 5.86191 1 6 1C6.13809 1 6.27487 1.0056 6.41013 1.01658L6.58245 1.27507L6.58601 1.28073C6.59031 1.28768 6.59808 1.30052 6.60885 1.31936C6.63037 1.35702 6.66387 1.41872 6.70549 1.50543C6.78869 1.67877 6.90456 1.9525 7.02211 2.33454C7.23331 3.02096 7.45103 4.06051 7.49283 5.5H4.50717C4.54897 4.06051 4.76668 3.02096 4.97789 2.33454C5.09544 1.9525 5.21131 1.67877 5.29451 1.50543C5.33613 1.41872 5.36963 1.35703 5.39115 1.31936C5.40192 1.30052 5.40969 1.28769 5.41399 1.28073L5.41755 1.27506ZM7.49283 6.5H4.50717C4.54897 7.93949 4.76668 8.97904 4.97789 9.66546C5.09544 10.0475 5.2113 10.3212 5.29451 10.4946C5.33613 10.5813 5.36963 10.643 5.39115 10.6806C5.40192 10.6995 5.40969 10.7123 5.41399 10.7193L5.41755 10.7249L5.58987 10.9834C5.72513 10.9944 5.86191 11 6 11C6.13809 11 6.27487 10.9944 6.41013 10.9834L6.58245 10.7249L6.58601 10.7193C6.59031 10.7123 6.59808 10.6995 6.60885 10.6806C6.63037 10.643 6.66387 10.5813 6.70549 10.4946C6.78869 10.3212 6.90456 10.0475 7.02211 9.66546C7.23331 8.97904 7.45103 7.93949 7.49283 6.5ZM7.70968 10.7001C7.79282 10.5049 7.88577 10.2589 7.97789 9.95954C8.21774 9.18002 8.451 8.0418 8.49323 6.5H10.9753C10.7826 8.44145 9.47927 10.0562 7.70968 10.7001ZM8.49323 5.5C8.451 3.9582 8.21774 2.81998 7.97789 2.04046C7.88577 1.74105 7.79282 1.49507 7.70968 1.29993C9.47927 1.94379 10.7826 3.55855 10.9753 5.5H8.49323ZM1.02469 6.5H3.50677C3.549 8.0418 3.78225 9.18002 4.02211 9.95954C4.11423 10.2589 4.20717 10.5049 4.29032 10.7001C2.52073 10.0562 1.21745 8.44145 1.02469 6.5ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Globe.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Globe;
