import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Dataset = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Dataset'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.7428 0.571254C11.9011 0.476249 12.0989 0.476249 12.2573 0.571254L22.2573 6.57125C22.4079 6.66162 22.5 6.82437 22.5 7C22.5 7.17563 22.4079 7.33838 22.2573 7.42875L12.2573 13.4287C12.0989 13.5238 11.9011 13.5238 11.7428 13.4287L1.74277 7.42875C1.59216 7.33838 1.50001 7.17563 1.50001 7C1.50001 6.82437 1.59216 6.66162 1.74277 6.57125L11.7428 0.571254ZM2.97184 7L12 12.4169L21.0282 7L12 1.5831L2.97184 7ZM1.57127 11.7428C1.71334 11.506 2.02047 11.4292 2.25726 11.5713L12 17.4169L21.7428 11.5713C21.9796 11.4292 22.2867 11.506 22.4288 11.7428C22.5708 11.9795 22.4941 12.2867 22.2573 12.4287L12.2573 18.4287C12.0989 18.5238 11.9011 18.5238 11.7428 18.4287L1.74277 12.4287C1.50598 12.2867 1.42919 11.9795 1.57127 11.7428ZM2.25726 16.5713C2.02047 16.4292 1.71334 16.506 1.57127 16.7428C1.42919 16.9795 1.50598 17.2867 1.74277 17.4287L11.7428 23.4287C11.9011 23.5238 12.0989 23.5238 12.2573 23.4287L22.2573 17.4287C22.4941 17.2867 22.5708 16.9795 22.4288 16.7428C22.2867 16.506 21.9796 16.4292 21.7428 16.5713L12 22.4169L2.25726 16.5713Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Dataset'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.82913 1.5301C7.93951 1.48997 8.0605 1.48997 8.17087 1.5301L15.5042 4.19677C15.7018 4.26862 15.8333 4.45641 15.8333 4.66667C15.8333 4.87692 15.7018 5.06471 15.5042 5.13656L8.17087 7.80323C8.0605 7.84337 7.93951 7.84337 7.82913 7.80323L0.495799 5.13656C0.298204 5.06471 0.166671 4.87692 0.166671 4.66667C0.166671 4.45641 0.298204 4.26862 0.495799 4.19677L7.82913 1.5301ZM2.12976 4.66667L8 6.8013L13.8702 4.66667L8 2.53203L2.12976 4.66667ZM0.196774 8.4958C0.291144 8.23628 0.578025 8.10241 0.837542 8.19677L8 10.8013L15.1625 8.19677C15.422 8.10241 15.7089 8.23628 15.8032 8.4958C15.8976 8.75532 15.7637 9.0422 15.5042 9.13657L8.17087 11.8032C8.0605 11.8434 7.93951 11.8434 7.82913 11.8032L0.495799 9.13657C0.236282 9.0422 0.102404 8.75532 0.196774 8.4958ZM0.837542 12.1968C0.578025 12.1024 0.291144 12.2363 0.196774 12.4958C0.102404 12.7553 0.236282 13.0422 0.495799 13.1366L7.82913 15.8032C7.93951 15.8434 8.0605 15.8434 8.17087 15.8032L15.5042 13.1366C15.7637 13.0422 15.8976 12.7553 15.8032 12.4958C15.7089 12.2363 15.422 12.1024 15.1625 12.1968L8 14.8013L0.837542 12.1968Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Dataset'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#dataset-small)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M5.82913 1.0301C5.93951 0.989966 6.06049 0.989966 6.17087 1.0301L11.6709 3.0301C11.8685 3.10196 12 3.28975 12 3.5C12 3.71025 11.8685 3.89804 11.6709 3.9699L6.17087 5.9699C6.06049 6.01003 5.93951 6.01003 5.82913 5.9699L0.329127 3.9699C0.131533 3.89804 -1.2482e-06 3.71025 -1.2482e-06 3.5C-1.2482e-06 3.28975 0.131533 3.10196 0.329127 3.0301L5.82913 1.0301ZM1.96309 3.5L6 4.96797L10.0369 3.5L6 2.03203L1.96309 3.5ZM0.030102 6.32913C0.124472 6.06961 0.411354 5.93573 0.67087 6.0301L6 7.96797L11.3291 6.0301C11.5886 5.93573 11.8755 6.06961 11.9699 6.32913C12.0643 6.58865 11.9304 6.87553 11.6709 6.9699L6.17087 8.9699C6.06049 9.01003 5.93951 9.01003 5.82913 8.9699L0.329127 6.9699C0.0696104 6.87553 -0.0642677 6.58865 0.030102 6.32913ZM0.67087 9.0301C0.411354 8.93573 0.124472 9.06961 0.030102 9.32913C-0.0642677 9.58864 0.0696104 9.87553 0.329127 9.9699L5.82913 11.9699C5.93951 12.01 6.06049 12.01 6.17087 11.9699L11.6709 9.9699C11.9304 9.87553 12.0643 9.58864 11.9699 9.32913C11.8755 9.06961 11.5886 8.93573 11.3291 9.0301L6 10.968L0.67087 9.0301Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="dataset-small">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Dataset.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Dataset;
