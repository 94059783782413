import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

export default styled.div`
  margin: 0;
  padding: 0;
  flex: 1;
  ${TextStyles.xxSmallCaps};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  letter-spacing: 0.5px;
`;
