import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Location = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Location'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5 9.5C5 4.72399 8.82574 2 12.5 2C16.1743 2 20 4.72399 20 9.5C20 10.6049 19.5436 11.944 18.806 13.3685C18.074 14.7822 17.0923 16.2289 16.1016 17.5354C15.1122 18.8401 14.1217 19.9949 13.3778 20.8239C13.0171 21.2259 12.715 21.5508 12.5 21.7782C12.285 21.5508 11.9829 21.2259 11.6222 20.8239C10.8783 19.9949 9.88777 18.8401 8.89841 17.5354C7.90773 16.2289 6.926 14.7822 6.19401 13.3685C5.45639 11.944 5 10.6049 5 9.5ZM12.1441 22.8512C12.1442 22.8513 12.1443 22.8514 12.5 22.5C12.8557 22.8514 12.8562 22.8509 12.8563 22.8508L12.5 23.2115L12.1441 22.8512ZM12.1441 22.8512L12.5 22.5L12.8563 22.8508L12.8576 22.8494L12.8624 22.8446L12.8804 22.8262L12.9491 22.7556C13.0089 22.6938 13.0961 22.6031 13.2068 22.4861C13.4283 22.2523 13.7438 21.9134 14.1222 21.4917C14.8783 20.6489 15.8878 19.4724 16.8984 18.1396C17.9077 16.8086 18.926 15.3116 19.694 13.8283C20.4564 12.356 21 10.8451 21 9.5C21 4.07601 16.6257 1 12.5 1C8.37426 1 4 4.07601 4 9.5C4 10.8451 4.54361 12.356 5.30599 13.8283C6.074 15.3116 7.09227 16.8086 8.10159 18.1396C9.11223 19.4724 10.1217 20.6489 10.8778 21.4917C11.2562 21.9134 11.5717 22.2523 11.7932 22.4861C11.9039 22.6031 11.9911 22.6938 12.0509 22.7556L12.1196 22.8262L12.1376 22.8446L12.1441 22.8512ZM10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5ZM12.5 6C10.567 6 9 7.567 9 9.5C9 11.433 10.567 13 12.5 13C14.433 13 16 11.433 16 9.5C16 7.567 14.433 6 12.5 6Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Location'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.46447 2.46465C5.40215 1.52697 6.67392 1.00018 8 1.00018C9.32608 1.00018 10.5979 1.52697 11.5355 2.46465C12.4732 3.40233 13 4.6741 13 6.00018C13 8.07495 11.7169 10.3359 10.3517 12.1355C9.67856 13.0228 9.00432 13.7737 8.49805 14.303C8.3036 14.5063 8.13435 14.6764 8 14.8086C7.86565 14.6764 7.6964 14.5063 7.50195 14.303C6.99568 13.7737 6.32144 13.0228 5.64835 12.1355C4.28314 10.3359 3 8.07495 3 6.00018C3 4.6741 3.52678 3.40233 4.46447 2.46465ZM7.66344 15.8699C7.66356 15.8701 7.66366 15.8702 8 15.5002C8.33634 15.8702 8.33679 15.8697 8.33691 15.8696C8.1462 16.043 7.85415 16.0433 7.66344 15.8699ZM7.66344 15.8699L8 15.5002L8.33691 15.8696L8.33799 15.8686L8.34163 15.8653L8.35476 15.8532L8.40352 15.8079C8.44568 15.7684 8.50674 15.7106 8.584 15.6359C8.73848 15.4864 8.95792 15.2689 9.2207 14.9942C9.74568 14.4454 10.4464 13.6651 11.1483 12.7399C12.5331 10.9145 14 8.42542 14 6.00018C14 4.40888 13.3679 2.88276 12.2426 1.75754C11.1174 0.632324 9.5913 0.000183105 8 0.000183105C6.4087 0.000183105 4.88258 0.632324 3.75736 1.75754C2.63214 2.88276 2 4.40888 2 6.00018C2 8.42542 3.46686 10.9145 4.85165 12.7399C5.55356 13.6651 6.25432 14.4454 6.7793 14.9942C7.04208 15.2689 7.26152 15.4864 7.416 15.6359C7.49326 15.7106 7.55432 15.7684 7.59648 15.8079L7.64524 15.8532L7.65837 15.8653L7.66201 15.8686L7.66344 15.8699ZM6 6.00018C6 4.89561 6.89543 4.00018 8 4.00018C9.10457 4.00018 10 4.89561 10 6.00018C10 7.10475 9.10457 8.00018 8 8.00018C6.89543 8.00018 6 7.10475 6 6.00018ZM8 3.00018C6.34315 3.00018 5 4.34333 5 6.00018C5 7.65704 6.34315 9.00018 8 9.00018C9.65685 9.00018 11 7.65704 11 6.00018C11 4.34333 9.65685 3.00018 8 3.00018Z"
            fill={fillColor}
          />
        </svg>
      );

    default:
      return <div>invalid size property</div>;
  }
};

Location.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Location;
