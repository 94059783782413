import styled from 'styled-components';

export default styled.span`
  display: flex;
  align-items: center;

  &:not(.disabled) {
    cursor: pointer;
  }
`;
