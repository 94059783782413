import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import AsyncSelect from 'react-select/async';
import selectStyles from './components/selectStyles';
import useIntersectionObserver from '../../commonResources/hooks/useIntersectionObserver';

import ControlWrapper from './components/ControlWrapper';
import Label from '../Input/components/InputLabel';
import DropdownIndicator from './components/DropdownIndicator';
import Menu from './components/DropdownMenu';
import MenuItem from './components/DropdownOption';
import ClearIndicator from './components/ClearIndicator';
import ErrorMessage from '../Input/components/ErrorMessage';

export const SelectDropdown = React.forwardRef(
  (
    {
      domID = '',
      className = '',
      dataTestId = '',
      name = '',
      label = '',
      labelProps = {},
      options = [],
      initialValue = null,
      onChange = () => false,
      isClearable = true,
      isSearchable = true,
      disabled = false,
      size = 'medium',
      hasError = false,
      errorMessage = '',
      placeholderText = '',
      onBlur = () => false,
      onFocus = () => false,
      isAsync = false,
      cacheOptions = false,
      loadOptions = () => false,
      defaultOptions = false,
      menuPortalTarget = null,
      ariaLabel = null,
      additionalProps = {},
      menuWidth,
      ...otherProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const [currentValue, setCurrentValue] = useState(initialValue);

    // if a new initialValue prop is passed, we will set the value to that new initialValue.
    useEffect(() => {
      if (initialValue) setCurrentValue(initialValue);
    }, [initialValue]);

    const handleOnChange = (option) => {
      setCurrentValue(option);
      onChange(option);
    };

    /** Sets up data-testids */
    const testIDs = {
      errorMessage: dataTestId ? `${dataTestId}-errorMessage` : '',
      label: dataTestId ? `${dataTestId}-label` : '',
    };

    // viewport avoidance
    // --------------------------------------
    const [menuNode, setMenuNode] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openUpward, setOpenUpward] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    /** Sets up classes */
    const wrapperClasses = [];
    if (className) wrapperClasses.push(className);
    if (hasError) wrapperClasses.push('has-error');
    if (disabled) wrapperClasses.push('disabled');
    if (label) wrapperClasses.push('label');
    if (size) wrapperClasses.push(size);
    if (isMenuOpen) wrapperClasses.push('opened');
    if (isFocused) wrapperClasses.push('focused');

    // finding and updating the menu node we will watch for intersections with the viewport
    useEffect(() => {
      const target = menuPortalTarget || document;
      setMenuNode(
        target.querySelector(`#${domID}-select-menu .selectDropdown__menu`),
      );
    }, [isMenuOpen, domID, menuPortalTarget]);

    // setting up the intersection observer
    const { viewportEdgesCrossed } = useIntersectionObserver({
      ref: { current: menuNode },
      effectDependencies: [menuNode],
    });

    // updating the position of the menu based on intersections with the viewport
    useEffect(() => {
      if (window.IntersectionObserver !== undefined) {
        const { top, bottom } = viewportEdgesCrossed;
        if (bottom === true) setOpenUpward(true);
        else if (top === true) setOpenUpward(false);
      }
    }, [viewportEdgesCrossed, openUpward]);
    // --------------------------------------

    return (
      <ControlWrapper
        id={domID}
        data-testid={dataTestId || ''}
        className={`${wrapperClasses.join(' ')} ${openUpward}`}
      >
        {label ? (
          <Label
            {...labelProps}
            htmlFor={`${domID}-select`}
            data-testid={testIDs.label}
          >
            {label}
          </Label>
        ) : null}
        {isAsync ? (
          <AsyncSelect
            ref={ref}
            id={`${domID}-select`}
            name={name}
            classNamePrefix="selectDropdown"
            className="selectDropdown__container"
            data-testid={dataTestId}
            value={currentValue}
            styles={selectStyles({
              openUpward,
              isPortal: menuPortalTarget !== null,
              size,
              menuWidth,
              theme,
            })}
            components={{
              DropdownIndicator,
              ClearIndicator,
              Menu,
              Option: MenuItem,
            }}
            onChange={(option) => handleOnChange(option)}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isDisabled={disabled}
            placeholder={placeholderText}
            defaultValue={initialValue}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            onBlur={(e) => {
              onBlur(e);
              setIsFocused(false);
            }}
            onFocus={(e) => {
              onFocus(e);
              setIsFocused(true);
            }}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            menuPortalTarget={menuPortalTarget}
            menuShouldScrollIntoView={false}
            aria-label={ariaLabel}
            {...additionalProps}
            {...otherProps}
          />
        ) : (
          <Select
            ref={ref}
            id={`${domID}-select`}
            name={name}
            classNamePrefix="selectDropdown"
            className="selectDropdown__container"
            data-testid={dataTestId}
            value={currentValue}
            styles={selectStyles({
              openUpward,
              isPortal: menuPortalTarget !== null,
              size,
              menuWidth,
              theme,
            })}
            components={{
              DropdownIndicator,
              ClearIndicator,
              Menu,
              Option: MenuItem,
            }}
            onChange={handleOnChange}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isDisabled={disabled}
            placeholder={placeholderText}
            defaultValue={initialValue}
            onBlur={(e) => {
              onBlur(e);
              setIsFocused(false);
            }}
            onFocus={(e) => {
              onFocus(e);
              setIsFocused(true);
            }}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            menuPortalTarget={menuPortalTarget}
            menuShouldScrollIntoView={false}
            aria-label={ariaLabel}
            {...additionalProps}
            {...otherProps}
          />
        )}
        {hasError && errorMessage ? (
          <ErrorMessage
            data-testid={testIDs.errorMessage}
            className="error-message"
          >
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </ControlWrapper>
    );
  },
);

export const selectDropdownV2PropTypes = {
  domID: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  /** html input name attribute */
  name: PropTypes.string,
  /** label text appearing above the input */
  label: PropTypes.string,
  /**
   * Label Props
   * Currently supported:
   * labelProps: {
   *   tooltipProps,
   *   micromodalProps
   * }
   */
  // eslint-disable-next-line react/forbid-foreign-prop-types
  labelProps: PropTypes.shape(Label.propTypes),
  /** an array of label/value pairs which determine the menu options.  The `label` is shown in the menu */
  // eslint-disable-next-line consistent-return
  options: (props, propName) => {
    if (
      !props.isAsync &&
      (typeof props[propName] === 'undefined' ||
        !Array.isArray(props[propName]))
    ) {
      return new Error('Options is required!');
    }
  },
  /** options which are selected and shown by default */
  initialValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  /** descriptive text displayed in the input which disappears when the user types into the field */
  placeholderText: PropTypes.string,
  /** fires when options are added/removed from the input.
   * @param {object} state the returned object
   * @prop {string} state.value the selected option
   */
  onChange: PropTypes.func,
  /** if `true`, the user can clear the selected option (ex. if input is not required) */
  isClearable: PropTypes.bool,
  /** if `true`, the user can type in the input to narrow down the options */
  isSearchable: PropTypes.bool,
  /** if `true`, the dropdown is unusable and un-clickable  */
  disabled: PropTypes.bool,
  /** determines size (height & padding) of input */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** if `true`, the dropdown uses error styling */
  hasError: PropTypes.bool,
  /** text shown below input describing the error */
  errorMessage: PropTypes.string,
  /** fires when the user clicks or tabs away. takes parameters: (event, false) */
  onBlur: PropTypes.func,
  /** fires when the user focuses on the input. takes parameters: (event, true) */
  onFocus: PropTypes.func,
  /** if you want to make sure the dropdown will display over the edges of a container that is blocking it,
   * you can pass a higher up node (such as document.body) here, that the dropdown will append to */
  menuPortalTarget: PropTypes.node,
  /** aria-label - html attribute that is required for a11y guidelines compatibility */
  ariaLabel: PropTypes.string,
  /** object containing additional properties passed directly to the `react-select` component */
  additionalProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** if `true`, the dropdown uses Async component to load options */
  isAsync: PropTypes.bool,
  /** if `true`, the async dropdown caches options */
  cacheOptions: PropTypes.bool,
  /** For async dropdown, function that returns a promise, which is the set of options to be used once the promise resolves. */
  loadOptions: PropTypes.func,
  /** For async dropdown, the default set of options to show before the user starts searching. When set to true, the results for loadOptions('') will be autoloaded. */
  defaultOptions: PropTypes.bool,
  /** dropdown options width default is 100% */
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectDropdown.propTypes = selectDropdownV2PropTypes;

export default SelectDropdown;
