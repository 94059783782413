import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Report = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Report'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M23.2071 2.50014L22.8536 2.85369L15.8536 9.85369L15.5 10.2072L15.1465 9.85369L9.50001 4.20724L2.85357 10.8537L2.50001 11.2072L1.79291 10.5001L2.14646 10.1466L9.14646 3.14658L9.50001 2.79303L9.85357 3.14658L15.5 8.79303L22.1465 2.14658L22.5 1.79303L23.2071 2.50014ZM4.00001 19.0001V19.5001V22.5001V23.0001H3.00001V22.5001V19.5001V19.0001H4.00001ZM10 12.5001V12.0001H9.00001V12.5001V22.5001V23.0001H10V22.5001V12.5001ZM16 19.0001V19.5001V22.5001V23.0001H15V22.5001V19.5001V19.0001H16ZM22 12.5001V12.0001H21V12.5001V22.5001V23.0001H22V22.5001V12.5001Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Report'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13.8536 0.853553C14.0488 0.658291 14.0488 0.341709 13.8536 0.146447C13.6583 -0.0488155 13.3417 -0.0488155 13.1464 0.146447L9.5 3.79289L6.85355 1.14645C6.65829 0.951184 6.34171 0.951184 6.14645 1.14645L2.14645 5.14645C1.95118 5.34171 1.95118 5.65829 2.14645 5.85355C2.34171 6.04882 2.65829 6.04882 2.85355 5.85355L6.5 2.20711L9.14645 4.85355C9.34171 5.04882 9.65829 5.04882 9.85355 4.85355L13.8536 0.853553ZM6.5 13C6.77614 13 7 12.7761 7 12.5V5.5C7 5.22386 6.77614 5 6.5 5C6.22386 5 6 5.22386 6 5.5V12.5C6 12.7761 6.22386 13 6.5 13ZM4 9.5C4 9.22386 3.77614 9 3.5 9C3.22386 9 3 9.22386 3 9.5V12.5C3 12.7761 3.22386 13 3.5 13C3.77614 13 4 12.7761 4 12.5V9.5ZM9 12.5V9.5C9 9.22386 9.22386 9 9.5 9C9.77614 9 10 9.22386 10 9.5V12.5C10 12.7761 9.77614 13 9.5 13C9.22386 13 9 12.7761 9 12.5ZM13 5.5C13 5.22386 12.7761 5 12.5 5C12.2239 5 12 5.22386 12 5.5V12.5C12 12.7761 12.2239 13 12.5 13C12.7761 13 13 12.7761 13 12.5V5.5ZM0 14.5C0 14.2239 0.223858 14 0.5 14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H0.5C0.223858 15 0 14.7761 0 14.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Report'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#report-small)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M11.8536 0.853553C12.0488 0.658291 12.0488 0.341709 11.8536 0.146447C11.6583 -0.0488155 11.3417 -0.0488155 11.1464 0.146447L7.5 3.79289L4.85355 1.14645C4.65829 0.951184 4.34171 0.951184 4.14645 1.14645L0.146447 5.14645C-0.0488155 5.34171 -0.0488155 5.65829 0.146447 5.85355C0.341709 6.04882 0.658291 6.04882 0.853553 5.85355L4.5 2.20711L7.14645 4.85355C7.34171 5.04882 7.65829 5.04882 7.85355 4.85355L11.8536 0.853553ZM4 11.5V5.5C4 5.22386 4.22386 5 4.5 5C4.77614 5 5 5.22386 5 5.5V11.5C5 11.7761 4.77614 12 4.5 12C4.22386 12 4 11.7761 4 11.5ZM1.5 9C1.77614 9 2 9.22386 2 9.5V11.5C2 11.7761 1.77614 12 1.5 12C1.22386 12 1 11.7761 1 11.5V9.5C1 9.22386 1.22386 9 1.5 9ZM8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5V11.5C7 11.7761 7.22386 12 7.5 12C7.77614 12 8 11.7761 8 11.5V9.5ZM10.5 5C10.7761 5 11 5.22386 11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="report-small">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Report.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Report;
