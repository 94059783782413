import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GroupHeader from '../../Molecules/GroupHeader';
import ContentContainer from './components/ContentContainer';
import GroupWrapper from './components/GroupWrapper';
import { ChipPropTypes } from '../../Atoms/Chip/Chip';

export const Group = ({
  domID = '',
  className = '',
  dataTestId = '',
  title = '',
  description = '',
  count = null,
  chipOptions = {},
  headerChildren = null,
  isAccordion = false,
  initialIsCollapsed = false,
  onTitleClick = () => false,
  onExpand = () => false,
  onCollapse = () => false,
  children = null,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed);

  useEffect(() => {
    setIsCollapsed(initialIsCollapsed);
  }, [initialIsCollapsed]);

  const domIDs = {
    body: domID ? `${domID}-body` : null,
    header: domID ? `${domID}-header` : null,
    summary: domID ? `${domID}-summary` : null,
  };
  const testIDs = {
    header: dataTestId ? `${dataTestId}-header` : null,
    body: dataTestId ? `${dataTestId}-body` : null,
    summary: dataTestId ? `${dataTestId}-summary` : null,
  };

  const toggleIsCollapsed = (event, forceState) => {
    const wasCollapsed = isCollapsed;
    const isNowCollapsed = forceState !== undefined ? forceState : !isCollapsed;

    onTitleClick(event.persist(), isNowCollapsed);
    setIsCollapsed(isNowCollapsed);

    if (isNowCollapsed) onCollapse(event.persist(), isCollapsed);
    if (!isNowCollapsed && wasCollapsed) onExpand(event.persist(), isCollapsed);
  };

  return (
    <GroupWrapper id={domID} className={className} data-testid={dataTestId}>
      <GroupHeader
        className="header"
        domID={domIDs.header}
        dataTestId={testIDs.header}
        title={title}
        description={description}
        count={count}
        headerChildren={headerChildren}
        isAccordion={isAccordion}
        isCollapsed={isCollapsed}
        onClick={toggleIsCollapsed}
        parentID={domID}
        chipOptions={chipOptions}
      />

      {children && !isCollapsed ? (
        <ContentContainer
          className="body"
          id={domIDs.body}
          data-testid={testIDs.body}
          aria-labelledby={domIDs.header}
          isAccordion
        >
          {children}
        </ContentContainer>
      ) : null}
    </GroupWrapper>
  );
};

Group.propTypes = {
  /**
   * id attribute that is attached to the wrapper div.
   *
   * id can be used to target a particular dom elememnt from css or javascript.
   */
  domID: PropTypes.string,
  /**
   * Class name to attach to the wrapper div.
   */
  className: PropTypes.string,
  /** data-testid attribute for automated testing */
  dataTestId: PropTypes.string,
  /** The displayed text/title */
  title: PropTypes.string.isRequired,
  /** Short description to give additional details about what this group is about. */
  description: PropTypes.string,
  /** value shown in `Count` component pill to left of title */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** elements to render to right of title, usually a button */
  headerChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /** if `true`, shows Collapse Arrow component & renders title as button */
  isAccordion: PropTypes.bool,
  /** if `true`, and `isAccordion` is also `true`, the CollapseArrow rotates 270deg */
  initialIsCollapsed: PropTypes.bool,
  /** if `isAccordion` is true, defines the callback action when title/collapse arrow is clicked.
   * Receives event and boolean */
  onTitleClick: PropTypes.func,
  /** if `isAccordion` is true, defines the callback action when content is collapsed.
   * Receives event and boolean */
  onCollapse: PropTypes.func,
  /** if `isAccordion` is true, defines the callback action when content is expanded.  Receives event and boolean */
  onExpand: PropTypes.func,
  /** Body of the section */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
  /**
   * Chip can be displayed to show information in Group header.
   *
   * Object containing the props of chip. See prop table of Chip component for details.
   */
  chipOptions: PropTypes.shape({ ...ChipPropTypes }),
};

export default Group;
