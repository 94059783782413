import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const PushPin = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'PushPin'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M15.8536 2.14658L15.5 1.79303L14.7929 2.50014L15.1465 2.85369L16.5227 4.22992L4.59433 10.8873L2.85357 9.14658L2.50001 8.79303L1.79291 9.50014L2.14646 9.85369L8.29291 16.0001L2.14646 22.1466L1.79291 22.5001L2.50001 23.2072L2.85357 22.8537L9.00001 16.7072L15.1465 22.8537L15.5 23.2072L16.2071 22.5001L15.8536 22.1466L14.1128 20.4058L20.7702 8.47746L22.1465 9.85369L22.5 10.2072L23.2071 9.50014L22.8536 9.14658L15.8536 2.14658ZM13.3778 19.6708L20.0352 7.74247L17.2577 4.96491L5.32933 11.6223L9.00001 15.293L9.70712 16.0001L13.3778 19.6708Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'PushPin'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#pushpin-medium)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M9.14645 0.146447C9.34171 -0.0488155 9.65829 -0.0488155 9.85355 0.146447L11.4886 1.7815C11.4939 1.78655 11.4991 1.79173 11.5041 1.79704L14.2025 4.49544C14.2081 4.50078 14.2136 4.50624 14.2189 4.5118L15.8536 6.14645C16.0488 6.34171 16.0488 6.65829 15.8536 6.85355C15.6583 7.04882 15.3417 7.04882 15.1464 6.85355L13.972 5.67908L9.47435 13.7672L10.8536 15.1464C11.0488 15.3417 11.0488 15.6583 10.8536 15.8536C10.6583 16.0488 10.3417 16.0488 10.1464 15.8536L8.50802 14.2151C8.50273 14.2101 8.49756 14.2049 8.49252 14.1996L1.80082 7.50793C1.79524 7.50261 1.78978 7.49714 1.78443 7.49153L0.146447 5.85355C-0.0488155 5.65829 -0.0488155 5.34171 0.146447 5.14645C0.341709 4.95118 0.658291 4.95118 0.853553 5.14645L2.23276 6.52565L10.3209 2.02803L9.14645 0.853553C8.95118 0.658291 8.95118 0.341709 9.14645 0.146447ZM11.0562 2.76335L13.2367 4.94376L8.73903 13.0319L2.96808 7.26097L11.0562 2.76335ZM4.35355 12.3536C4.54882 12.1583 4.54882 11.8417 4.35355 11.6464C4.15829 11.4512 3.84171 11.4512 3.64645 11.6464L0.146447 15.1464C-0.0488155 15.3417 -0.0488155 15.6583 0.146447 15.8536C0.341709 16.0488 0.658291 16.0488 0.853553 15.8536L4.35355 12.3536Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="pushpin-medium">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'PushPin'}
          id={domID}
          data-testid={dataTestId}
        >
          <title>{title}</title>
          <g clipPath="url(#PushPin-small)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M6.74847 0.136243C6.94373 -0.0590189 7.26031 -0.0590189 7.45557 0.136243L8.65431 1.33498C8.65988 1.34029 8.66533 1.34575 8.67068 1.35135L10.649 3.32969C10.6543 3.33479 10.6595 3.33999 10.6646 3.34528L11.8637 4.54441C12.059 4.73967 12.059 5.05625 11.8637 5.25151C11.6685 5.44678 11.3519 5.44678 11.1566 5.25151L10.4181 4.51294L7.24776 10.2141L8.19027 11.1567C8.38553 11.3519 8.38553 11.6685 8.19027 11.8638C7.99501 12.059 7.67842 12.059 7.48316 11.8638L6.28182 10.6624C6.27626 10.6571 6.27083 10.6517 6.26555 10.6462L1.3535 5.7341C1.3482 5.72903 1.343 5.72382 1.3379 5.71849L0.136223 4.51682C-0.0590389 4.32156 -0.0590389 4.00497 0.136223 3.80971C0.331485 3.61445 0.648068 3.61445 0.84333 3.80971L1.78583 4.75222L7.48704 1.58192L6.74847 0.84335C6.55321 0.648088 6.55321 0.331505 6.74847 0.136243ZM8.22236 2.31724L9.68274 3.77762L6.51245 9.47883L2.52115 5.48753L8.22236 2.31724ZM3.41476 9.29233C3.61002 9.09707 3.61002 8.78048 3.41476 8.58522C3.2195 8.38996 2.90291 8.38996 2.70765 8.58522L0.136223 11.1567C-0.0590389 11.3519 -0.0590389 11.6685 0.136223 11.8638C0.331485 12.059 0.648068 12.059 0.84333 11.8638L3.41476 9.29233Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="PushPin-small">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

PushPin.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default PushPin;
