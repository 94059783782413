import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '../../../Atoms/Checkbox';

const Wrapper = styled.th`
  > * {
    display: table;
    margin-top: 2px;
  }

  ${(props) =>
    !props.isSelectAllEnabled &&
    !props.isSelectAllIndeterminate &&
    `
      > * {
        visibility: hidden;
        &:focus-visible:not(:active) {
          td.select-checkbox > * {
            visibility: visible;
          }
        }
      }
  `}
`;

export const SelectAllControl = ({
  dataTestId = null,
  onSelectAll = () => false,
  columns = new Set([]),
  disabledColumns = new Set([]),
  isSelectAllEnabled,
  selectAllCheckboxDomID = null,
  isSelectAllIndeterminate,
  className = '',
}) => {
  const noColumnsSelected = disabledColumns.size === columns.size;

  const applicableClassName = noColumnsSelected ? '' : 'selection-column';
  return (
    <Wrapper
      key="select column"
      className={`${className} ${applicableClassName}`}
      selectAllCheckboxDomID={selectAllCheckboxDomID}
      isSelectAllEnabled={isSelectAllEnabled}
      isSelectAllIndeterminate={isSelectAllIndeterminate}
      tabIndex="0"
    >
      {!noColumnsSelected ? (
        <Checkbox
          dataTestId={dataTestId}
          domID={selectAllCheckboxDomID}
          ariaLabel="select all"
          className="table-select-all-checkbox"
          onChange={(e) => onSelectAll(e)}
          checked={isSelectAllEnabled}
          indeterminate={isSelectAllIndeterminate}
        />
      ) : null}
    </Wrapper>
  );
};

SelectAllControl.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  onSelectAll: PropTypes.func.isRequired,
  isSelectAllEnabled: PropTypes.bool.isRequired,
  selectAllCheckboxDomID: PropTypes.string.isRequired,
  isSelectAllIndeterminate: PropTypes.bool.isRequired,
  columns: PropTypes.instanceOf(Set).isRequired,
  disabledColumns: PropTypes.instanceOf(Set).isRequired,
};

export default SelectAllControl;
