import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const PulseChart = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'medium',
}) => {
  switch (size) {
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'PulseChart'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.46875 1.90625C1.46875 1.66463 1.66463 1.46875 1.90625 1.46875H14.0938C14.3354 1.46875 14.5312 1.66463 14.5312 1.90625V14.0938C14.5312 14.3354 14.3354 14.5312 14.0938 14.5312H1.90625C1.66463 14.5312 1.46875 14.3354 1.46875 14.0938V1.90625ZM1.90625 0.46875C1.11234 0.46875 0.46875 1.11234 0.46875 1.90625V14.0938C0.46875 14.8877 1.11234 15.5312 1.90625 15.5312H14.0938C14.8877 15.5312 15.5312 14.8877 15.5312 14.0938V1.90625C15.5312 1.11234 14.8877 0.46875 14.0938 0.46875H1.90625ZM12.6187 5.95625C12.7844 5.73534 12.7397 5.42194 12.5188 5.25625C12.2978 5.09056 11.9844 5.13534 11.8188 5.35625L9.35215 8.64505L6.9473 6.2402C6.84481 6.1377 6.70289 6.08473 6.55831 6.09501C6.41372 6.10528 6.28072 6.17779 6.19375 6.29375L3.38125 10.0437C3.21556 10.2647 3.26034 10.5781 3.48125 10.7437C3.70216 10.9094 4.01556 10.8647 4.18125 10.6438L6.64785 7.35495L9.0527 9.7598C9.15519 9.8623 9.29711 9.91527 9.44169 9.90499C9.58628 9.89472 9.71928 9.82221 9.80625 9.70625L12.6187 5.95625Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'PulseChart'}
          id={domID}
          data-testid={dataTestId}
        >
          <g clipPath="url(#clip0_2981:524)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M1.45827 2.1305C1.45827 2.03538 1.53538 1.95827 1.6305 1.95827H10.3695C10.4646 1.95827 10.5417 2.03538 10.5417 2.1305V10.8695C10.5417 10.9646 10.4646 11.0417 10.3695 11.0417H1.6305C1.53538 11.0417 1.45827 10.9646 1.45827 10.8695V2.1305ZM1.6305 0.958267C0.983093 0.958267 0.458267 1.48309 0.458267 2.1305V10.8695C0.458267 11.5169 0.983093 12.0417 1.6305 12.0417H10.3695C11.0169 12.0417 11.5417 11.5169 11.5417 10.8695V2.1305C11.5417 1.48309 11.0169 0.958267 10.3695 0.958267H1.6305ZM9.42426 5.11864C9.58995 4.89773 9.54518 4.58433 9.32426 4.41864C9.10335 4.25296 8.78995 4.29773 8.62426 4.51864L6.95347 6.74636L5.34443 5.13732C5.24194 5.03483 5.10001 4.98186 4.95543 4.99213C4.81085 5.00241 4.67784 5.07492 4.59088 5.19087L2.57418 7.8798C2.4085 8.10071 2.45327 8.41411 2.67418 8.5798C2.8951 8.74548 3.2085 8.70071 3.37418 8.4798L5.04497 6.25208L6.65402 7.86112C6.75651 7.96361 6.89843 8.01658 7.04301 8.00631C7.1876 7.99603 7.3206 7.92352 7.40757 7.80757L9.42426 5.11864Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_2981:524">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

PulseChart.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default PulseChart;
