import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const NavigationPaneIcon = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Navigation Pane'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.96667 4C1.40528 4 1 4.42279 1 4.88462V20.1154C1 20.5772 1.40528 21 1.96667 21H9V4H1.96667ZM21.0333 4H10V21H21.0333C21.5947 21 22 20.5772 22 20.1154V4.88462C22 4.42279 21.5947 4 21.0333 4ZM1.96667 22H9.5H21.0333C22.092 22 23 21.183 23 20.1154V4.88462C23 3.81704 22.092 3 21.0333 3H9.5H1.96667C0.908017 3 0 3.81704 0 4.88462V20.1154C0 21.183 0.908017 22 1.96667 22ZM3.5 12C3.22386 12 3 12.2239 3 12.5C3 12.7761 3.22386 13 3.5 13H6.5C6.77614 13 7 12.7761 7 12.5C7 12.2239 6.77614 12 6.5 12H3.5ZM3 8.5C3 8.22386 3.22386 8 3.5 8H6.5C6.77614 8 7 8.22386 7 8.5C7 8.77614 6.77614 9 6.5 9H3.5C3.22386 9 3 8.77614 3 8.5ZM3.5 16C3.22386 16 3 16.2239 3 16.5C3 16.7761 3.22386 17 3.5 17H6.5C6.77614 17 7 16.7761 7 16.5C7 16.2239 6.77614 16 6.5 16H3.5Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Navigation Pane'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 2C1.22386 2 1 2.22386 1 2.5V13.5C1 13.7761 1.22386 14 1.5 14H7V2H1.5ZM14.5 2H8V14H14.5C14.7761 14 15 13.7761 15 13.5V2.5C15 2.22386 14.7761 2 14.5 2ZM1.5 15H7.5H14.5C15.3284 15 16 14.3284 16 13.5V2.5C16 1.67157 15.3284 1 14.5 1H7.5H1.5C0.671573 1 0 1.67157 0 2.5V13.5C0 14.3284 0.671573 15 1.5 15ZM3 7.5C2.72386 7.5 2.5 7.72386 2.5 8C2.5 8.27614 2.72386 8.5 3 8.5H5C5.27614 8.5 5.5 8.27614 5.5 8C5.5 7.72386 5.27614 7.5 5 7.5H3ZM2.5 5C2.5 4.72386 2.72386 4.5 3 4.5H5C5.27614 4.5 5.5 4.72386 5.5 5C5.5 5.27614 5.27614 5.5 5 5.5H3C2.72386 5.5 2.5 5.27614 2.5 5ZM3 10.5C2.72386 10.5 2.5 10.7239 2.5 11C2.5 11.2761 2.72386 11.5 3 11.5H5C5.27614 11.5 5.5 11.2761 5.5 11C5.5 10.7239 5.27614 10.5 5 10.5H3Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Navigation Pane'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 2.19231C1 2.11283 1.07696 2 1.23333 2H6V10H1.23333C1.07696 10 1 9.88717 1 9.80769V2.19231ZM7 10V2H10.7667C10.923 2 11 2.11283 11 2.19231V9.80769C11 9.88717 10.923 10 10.7667 10H7ZM6.5 11H1.23333C0.579692 11 0 10.4929 0 9.80769V2.19231C0 1.50708 0.579692 1 1.23333 1H6.5H10.7667C11.4203 1 12 1.50708 12 2.19231V9.80769C12 10.4929 11.4203 11 10.7667 11H6.5ZM2.5 7.5C2.5 7.22386 2.72386 7 3 7H4C4.27614 7 4.5 7.22386 4.5 7.5C4.5 7.77614 4.27614 8 4 8H3C2.72386 8 2.5 7.77614 2.5 7.5ZM3 4C2.72386 4 2.5 4.22386 2.5 4.5C2.5 4.77614 2.72386 5 3 5H4C4.27614 5 4.5 4.77614 4.5 4.5C4.5 4.22386 4.27614 4 4 4H3Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

NavigationPaneIcon.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default NavigationPaneIcon;
