import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import borders from '../../../BrandCore/shapes/borders';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: 0;
  display: flex;
  height: 19px;
  flex-direction: column;
  justify-content: center;
`;

export const GroupTitleButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  &:focus-visible {
    box-shadow: 0 0 0 1px
      ${({ theme }) => theme.UIPalette.Colors.Field.Border.Focus};
    ${borders.borderRadius2};
  }

  svg {
    path {
      fill: ${({ theme, isCollapsed }) =>
        isCollapsed
          ? theme.UIPalette.Colors.Content.Secondary
          : theme.UIPalette.Colors.Content.Primary};
    }
  }
`;

export const Description = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  ${TextStyles.xSmall};
  color: ${({ theme }) => theme.UIPalette.Colors.Content.Secondary};
`;

export default {
  ArrowWrapper,
  GroupTitleButton,
  LeftWrapper,
  Wrapper,
};
