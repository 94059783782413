import styled from 'styled-components';
import { UnstyledButton } from '../../../commonResources/styles/buttons';

export default styled(UnstyledButton)`
  width: 42px;
  text-align: center;
  transition: 0.25s ease-in-out;
  outline: none;
  border: 1px solid transparent;

  &:focus-visible {
    transition: none;
    outline: none;
    border-radius: 24px;
    box-shadow: 0px 0px 0px 1px
        ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus},
      0px 0px 0px 2px ${(props) => props.theme.UIPalette.Colors.Content.White},
      0px 0px 0px 3px
        ${(props) => props.theme.UIPalette.Colors.Field.Border.Focus};
  }
`;
