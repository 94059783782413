import styled from 'styled-components';
import { UnstyledButton } from '../../commonResources/styles/buttons';
import TextStyles from '../../BrandCore/Text/styles';

const TabLabelWrapper = styled.label`
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
`;

const TabWrapper = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  outline: none;

  & label {
    white-space: nowrap;
  }

  &:not(.disabled) {
    cursor: pointer;

    label {
      cursor: pointer;
    }

    &:hover label {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
    }

    span {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
    }
  }

  &:focus(.disabled) {
    ${(props) => props.theme.UIPalette.BoxShadows.Selection.TabSelected};
  }

  &.disabled {
    cursor: not-allowed;

    label {
      cursor: inherit;
    }

    span {
      color: ${(props) => props.theme.UIPalette.Colors.Content.TabDisabled};
    }
  }

  &.selected {
    ${(props) => props.theme.UIPalette.BoxShadows.Selection.TabSelected};

    & label {
      color: ${(props) => props.theme.UIPalette.Colors.Content.Primary};
    }

    & div.selected > span {
      color: ${(props) =>
        props.theme.UIPalette.Colors.Content.Primary} !important;
    }
  }

  &.tab-small {
    padding: 8px 0;
    ${TextStyles.xSmall}
  }

  &.tab-medium {
    ${TextStyles.small}
    padding: 15px 0;
  }
`;

const TabCountWrapper = styled.span`
  display: inline-block;
  & > span {
    padding: 5.5px;
  }
`;

export { TabWrapper, TabLabelWrapper, TabCountWrapper };
