import styled from 'styled-components';
import heights from '../../../BrandCore/constants/heights';

export default styled.div`
  top: 0;
  right: 8px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => heights.input[size]}px;

  ${(props) => {
    if (props.hasLabel) return 'top: 19px;';
    if (props.hasError) return 'top: 0;';
    return 'bottom: 0;';
  }};

  &.large {
    right: 12px;
  }
`;
