import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Tooltip from '../Tooltip';
import { useIsTruncated } from '../../commonResources/hooks/useIsTruncated';
import TextStyles from '../../BrandCore/Text/styles';

const Wrapper = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${(props) =>
    props.theme.UIPalette.Colors.Background.Depth5};
  border-radius: 6px;
  text-align: center;
  position: relative;
  ${(props) => props.theme.BoxShadows.Depth5};

  .tile-icon {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const Label = styled.div`
  ${TextStyles.xxSmallCaps}
  letter-spacing: 0.5px;
  text-align: center;
  height: 22px;
  width: 100%;
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  margin-top: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: ${(props) => props.theme.BaseColors.Neutral['0']};
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tile = ({
  icon: Icon,
  label,
  className,
  domID,
  tooltipWidth = 150,
  tooltipPosition = 'bottom-center',
}) => {
  const theme = useContext(ThemeContext);
  const labelRef = useRef(null);
  const isTruncated = useIsTruncated(labelRef, [label]);

  return (
    <Wrapper className={className} id={domID}>
      <Icon
        className="tile-icon"
        title={label}
        domID={`${domID}-icon`}
        fillColor={theme?.BaseColors?.Primary['115']}
      />
      <Tooltip
        tooltipContent={label}
        domID={`${domID}-tile-tooltip`}
        hideTooltip={!isTruncated}
        isButtonRoleDisabled
        disablePointerEvents
        tooltipPosition={tooltipPosition}
        tooltipWidth={tooltipWidth}
        style={{ maxWidth: '100%', display: 'block' }}
      >
        <Label className="tile-label" ref={labelRef}>
          {label}
        </Label>
      </Tooltip>
    </Wrapper>
  );
};

export const TileProps = {
  /**
   *  id attribute for the wrapper div
   *
   *  The id attribute is used to point to a specific style declaration in a style sheet.
   *  It is also used by JavaScript to access and manipulate the element with the specific id.
   *
   */
  domID: PropTypes.string,
  /** The icon to be rendered in the tile.
   *
   * Width and height will be automatically set to 42px */
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  /** the label to appear under the icon. Should be one word (two at most), and not be wider than width of Tile (62px) */
  label: PropTypes.string.isRequired,
  /**
   * Classname that gets attached to the container div
   */
  className: PropTypes.string,
  /**
   * Position to indicate tooltip's position. See tooltip for details.
   */
  tooltipPosition: PropTypes.string,
  /** Width for the tooltip. See tooltip for details */
  tooltipWidth: PropTypes.number,
};

Tile.propTypes = TileProps;

export default Tile;
