import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Radio from '../../Atoms/Radio';
import RadioGroupContainer from '../CheckboxGroup/components/GroupContainer';
import RadioGroupError from '../CheckboxGroup/components/GroupError';
import RadioGroupLabel from '../CheckboxGroup/components/GroupLabel';

const RadioGroup = ({
  dataTestId = '',
  checkedItem = null,
  disabled = false,
  hasError = false,
  errorMessage = '',
  size = 'medium',
  orientation = 'horizontal',
  label = '',
  items = [],
  onChange = () => false,
}) => {
  const [checkedItemId, setCheckedItemId] = useState(
    checkedItem ? checkedItem.id : null,
  );

  useEffect(() => {
    if (!checkedItem) {
      setCheckedItemId(null);
    }
  }, [checkedItem]);
  return (
    <>
      {label && (
        <RadioGroupLabel
          className={clsx({
            error: hasError,
          })}
        >
          {label}
        </RadioGroupLabel>
      )}
      <RadioGroupContainer className={orientation} data-testid={dataTestId}>
        {items.map((item) => (
          <div key={item.id} className="checkbox-wrapper">
            <Radio
              size={size}
              checked={checkedItemId === item.id}
              disabled={disabled ? true : item.disabled || false}
              label={item.label}
              ariaLabelledBy={item.ariaLabelledBy}
              ariaLabel={item.ariaLabel || item.label}
              dataTestId={`${dataTestId}-${item.id}`}
              onChange={(e, checked) => {
                if (checked) {
                  setCheckedItemId(item.id);
                  onChange({
                    item,
                    id: item.id,
                    checked,
                  });
                }
              }}
            />
          </div>
        ))}
      </RadioGroupContainer>
      {hasError && <RadioGroupError>{errorMessage}</RadioGroupError>}
    </>
  );
};

RadioGroup.propTypes = {
  dataTestId: PropTypes.string,
  /** RadioGroup item to be checked by default. e.g. checkedItem={{id: 'A11234'}} */
  // eslint-disable-next-line react/forbid-prop-types
  checkedItem: PropTypes.object,
  /** whether all radios under this RadioGroup is rendered disabled */
  disabled: PropTypes.bool,
  /** whether this RadioGroup is rendered has error. usually clubbed with errorMessage prop */
  hasError: PropTypes.bool,
  /** the error message */
  errorMessage: PropTypes.string,
  /** size of RadioGroup */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Orientation of RadioGroup */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** label for Checkbox group */
  label: PropTypes.string,
  /** function to handle event */
  onChange: PropTypes.func,
  /** array of objects where each object can have,
   * -> id: unique identifier for each checkbox
   * -> isChecked: whether this item is checked on render
   * -> disabled: whether this item is checked on render
   * -> label: label for this Checkbox item
   * -> ariaLabelledBy: a11y compatiblity for this Checkbox
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** unique identifier for each checkbox */
      id: PropTypes.string,
      /** whether this item is checked on render */
      isChecked: PropTypes.bool,
      /** whether this item is checked on render */
      disabled: PropTypes.bool,
      /** label for this Checkbox item */
      label: PropTypes.string,
      /** a11y compatiblity - id of element that serves purpose of label */
      ariaLabelledBy: PropTypes.string,
      /** a11y compatiblity - label text */
      ariaLabel: PropTypes.string,
    }),
  ),
};

export default RadioGroup;
