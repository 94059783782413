import {
  neutralBaseDarkMode,
  white,
  neutralAlt30,
  negative130 as dNegative130,
  neutral100 as dNeutral100,
  neutral50,
} from './darkBasePalette';
import {
  negative130,
  primary115,
  primary70,
  primary100,
  positive130,
  caution100,
  accent130,
  accent100,
  neutral10,
  neutral15,
  primary30,
  neutral100,
  neutral150,
  neutral5,
} from '../base/basePalette';
import blendColors from '../../../../commonResources/colorBlender';
import { brightBlue130 } from '../../datavizPalette';

export const blendBlack = '#1C202B';
const borderBlendBlack = '#1C2029';
const deEmphasizedBase = '#232632';
const standardButtonBase = '#73BFE3';
const buttonFocusedBoxShadow =
  '0px 1px 2px rgba(15, 18, 26, 0.1), 0px 2px 8px #0F121A';

// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const DarkUIPalette = {
  Colors: {
    Background: {
      Modal: `${blendBlack}66`,
      Masthead: neutralBaseDarkMode,
      Selected: blendColors(blendBlack, white, 0.18),
      ProductSwitcher:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.22)), rgba(28, 32, 43, 0.6)',
      Tooltip: blendColors(blendBlack, white, 0.2),
      Depth2: blendColors(blendBlack, white, 0.16),
      Depth5: blendColors(blendBlack, white, 0.08),
      Depth10: blendColors(blendBlack, white, 0.05),
      Depth15: blendColors(blendBlack, white, 0.05),
      Breadcrumb: blendColors(blendBlack, white, 0.07),
      StandardMenu: blendColors(blendBlack, white, 0.14),
      ToggleOn: blendColors(primary115, white, 0.4),
      Default: blendColors(blendBlack, white, 0.05),
    },
    Content: {
      Primary: `${blendColors(blendBlack, white, 0.9)}`,
      Secondary: `${blendColors(blendBlack, white, 0.75)}`,
      Placeholder: dNeutral100,
      TabDisabled: neutralAlt30,
      Disabled: `${blendColors(blendBlack, white, 0.38)}`,
      Error: blendColors(negative130, white, 0.4),
      White: blendColors(blendBlack, white, 0.01),
      PersistentDark: neutralBaseDarkMode,
      PersistentLight: `${blendColors(blendBlack, white, 0.9)}`,
      Masthead: neutralBaseDarkMode,
    },
    Selected: {
      Tab: blendColors(blendBlack, white, 0.05),
      TabUnselected: blendColors(blendBlack, white, 0.05),
      MenuItem: blendColors(blendBlack, white, 0.1),
    },
    Status: {
      Positive: blendColors(positive130, white, 0.4),
      Caution: blendColors(caution100, white, 0.4),
      Negative: blendColors(negative130, white, 0.4),
      Neutral: neutral50,
      Light: blendColors(blendBlack, white, 0.9),
      Dark: blendColors(blendBlack, white, 0.14),
    },
    Field: {
      Background: {
        Default: blendColors(blendBlack, white, 0.02),
        Disabled: blendColors(blendBlack, white, 0.08),
      },
      Border: {
        Default: blendColors(blendBlack, white, 0.42),
        Hover: blendColors(blendBlack, white, 0.7),
        Focus: blendColors(primary115, white, 0.3),
        Disabled: blendColors(blendBlack, white, 0.16),
        Error: blendColors(negative130, white, 0.4),
      },
    },
    Button: {
      Background: {
        Standard: blendColors(blendBlack, white, 0.05),
      },
      Border: {
        Standard: standardButtonBase,
        EmphasizedAlt: blendColors(accent130, white, 0.13),
      },
      Text: {
        Standard: standardButtonBase,
        Emphasized: blendColors('#1C202B', white, 0.03),
      },
      Hover: {
        Background: {
          Emphasized: primary70,
          Standard: blendColors('#1A1F2D', white, 0.18),
          DeEmphasized: `${deEmphasizedBase}`,
          Diminished: blendColors('#076592', white, 0.1),
          Destroy: negative130,
          EmphasizedAlt: accent130,
          DeEmphasizedReversed: deEmphasizedBase,
        },
        Border: {
          Standard: standardButtonBase,
          EmphasizedAlt: blendColors(accent130, white, 0.12),
        },
        Text: {
          Emphasized: blendColors('#1C202B', white, 0.03),
          Diminished: blendColors('#076592', white, 0.1),
        },
      },
      Active: {
        Border: {
          EmphasizedAlt: blendColors(accent130, white, 0.12),
        },
      },
      Focus: {
        Border: {
          Emphasized: primary115,
          Standard: blendColors(blendBlack, white, 0.05),
          DeEmphasized: `#61646C`,
          Destroy: dNegative130,
          EmphasizedAlt: `#F42A4D`,
          DeEmphasizedReversed: blendColors(blendBlack, white, 0.9),
          CheckboxFocus: white,
          RadioFocus: primary115,
        },
        BoxShadow: {
          Standard: `
            0px 0px 0px 1px ${blendBlack},
            0px 0px 0px 2px ${standardButtonBase},
            ${buttonFocusedBoxShadow}
          `,
          Emphasized: `
            0px 0px 0px 1px ${blendBlack},
            0px 0px 0px 2px ${primary115},
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasized: `
            0px 0px 0px 1px ${blendBlack},
            0px 0px 0px 2px #51545D, 
            ${buttonFocusedBoxShadow}
          `,
          Destroy: `
            0px 0px 0px 1px ${blendBlack},
            0px 0px 0px 2px #BF1616, 
            ${buttonFocusedBoxShadow}
          `,
          EmphasizedAlt: `
            0px 0px 0px 1px ${blendBlack},
            0px 0px 0px 2px #F42A4D,
            ${buttonFocusedBoxShadow}
          `,
          DeEmphasizedReversed: `
            0px 0px 0px 1px ${neutralBaseDarkMode},
            0px 0px 0px 2px ${blendColors(blendBlack, white, 0.9)},
            ${buttonFocusedBoxShadow}
          `,
        },
      },
      Disabled: {
        Background: {
          Emphasized: blendColors(blendBlack, '#94DDFF', 0.1),
          Standard: blendColors(blendBlack, white, 0.05),
          Destroy: blendColors(blendBlack, '#BF1616', 0.2),
          EmphasizedAlt: blendColors(blendBlack, '#E90C32', 0.2),
          DeEmphasizedReversed: blendBlack,
        },
        Border: {
          Emphasized: blendColors(blendBlack, '#94DDFF', 0.15),
          Standard: blendColors(blendBlack, standardButtonBase, 0.3),
          Destroy: blendColors(blendBlack, '#BF1616', 0.36),
          EmphasizedAlt: blendColors(blendBlack, '#E90C32', 0.36),
          DeEmphasizedReversed: blendColors(blendBlack, white, 0.18),
        },
        Text: {
          Emphasized: blendColors(blendBlack, '#94DDFF', 0.4),
          Standard: blendColors(blendBlack, standardButtonBase, 0.5),
          Diminished: neutral50,
          Destroy: blendColors(
            blendColors(blendBlack, '#BF1616', 0.5),
            white,
            0.1,
          ),
          EmphasizedAlt: blendColors(
            blendColors(blendBlack, '#B80928', 0.5),
            white,
            0.1,
          ),
          DeEmphasizedReversed: blendColors(blendBlack, white, 0.18),
        },
      },
    },
    SplitDropdownButton: {
      Border: {
        Emphasized: primary100,
        Standard: blendColors(blendBlack, white, 0.7),
        Destroy: negative130,
        EmphasizedAlt: accent100,
      },
      Disabled: {
        Border: {
          Emphasized: blendColors(blendBlack, white, 0.05),
          Standard: blendColors(blendBlack, white, 0.01),
          Destroy: blendColors(blendBlack, white, 0.05),
          EmphasizedAlt: blendColors(blendBlack, white, 0.05),
        },
      },
    },
    // start colors not on figma chart
    // I'm not sure where we source these values, it was on the original palette so I left it.
    Filter: {
      Border: {
        Selected: `${primary115}`,
        Focus: `${primary115}`,
      },
      Background: {
        Hover: `${neutral10}`,
        Active: `${neutral15}`,
        Selected: `${primary30}`,
        HomeButton: `${neutral100}`,
        HomeButtonDisabled: `${neutral150}`,
      },
      BoxShadow: {
        Selection: `box-shadow: inset 2px 0px 0px ${primary115};`,
      },
    },
    MenuItem: {
      Icon: `${brightBlue130}`,
      Checked: `${brightBlue130}`,
      Background: {
        Default: `${neutral5}`,
      },
    },
    // end colors not on main figma chart
  },
  BoxShadows: {
    Field: {
      Inner: `box-shadow: 0px 2px 6px 0px #1C202B80 inset;`,
      Outer: `box-shadow: 0px 2px 6px 0px #1C202B;`,
      Default: `border: 1px solid ${blendColors(
        borderBlendBlack,
        white,
        0.42,
      )};`,
      Disabled: `border: 1px solid ${blendColors(
        borderBlendBlack,
        white,
        0.16,
      )};`,
      Hover: `border: 1px solid ${blendColors('#1C202B', white, 0.7)};`,
      Focus: `border: 1px solid ${blendColors(primary115, white, 0.3)};`,
      Error: `border: 1px solid ${blendColors(negative130, white, 0.4)};`,
    },
    Selection: {
      TabSelected: `box-shadow: 0px 2px 0px 0px ${blendColors(
        primary115,
        white,
        0.4,
      )};`,
      TabUnselected: `box-shadow: 0px 2px 0px 0px ${neutral10};`,
      MenuItem: `box-shadow: 2px 0px 0px 0px ${blendColors(
        primary115,
        white,
        0.4,
      )} inset;`,
    },
    General: {
      LineAbove: `box-shadow: 0px -1px 0px 0px ${blendColors(
        borderBlendBlack,
        white,
        0.12,
      )};`,
      LineBelow: `box-shadow: 0px 1px 0px 0px ${blendColors(
        borderBlendBlack,
        white,
        0.2,
      )};`,
      MicroModal: `box-shadow: 0px -1px 6px 1px rgba(28, 32, 41, 0.2),
        0px 6px 16px 2px ${borderBlendBlack};
        filter: drop-shadow(0px 1px 6px rgba(28, 32, 41, 0.2));`,
      HelperModule: `box-shadow: 0px 1px 4px rgba(28, 32, 41, 0.12),
        0px 10px 32px ${borderBlendBlack},
        inset 0px 4px 0px #A84EE5;`,
      HelperModuleCustom: ({
        borderColor,
      }) => `box-shadow: 0px 1px 4px rgba(28, 32, 41, 0.12),
        0px 10px 32px ${borderBlendBlack},
        ${`inset 0px 4px 0px ${borderColor || '#A84EE5'}`}`,
    },
  },
};

export default DarkUIPalette;
