import React from 'react';
import PropTypes from 'prop-types';
import VerticallyCentered from './styleWrappers/VerticallyCentered';
import SplitDropdown, { splitDropdownPropTypes } from '../../SplitDropdown';

const SplitDropdownCell = ({
  data: {
    onClick = () => null,
    onToggleMenu = () => null,
    onClickOut = () => null,
    onMenuClick = () => null,
  },
  data = {},
}) => {
  return (
    <VerticallyCentered>
      <SplitDropdown
        {...data}
        onClick={(e) => onClick(e, data)}
        onToggleMenu={(e) => onToggleMenu(e, data)}
        onClickOut={(e) => onClickOut(e, data)}
        onMenuClick={(e) => onMenuClick(e, data)}
      />
    </VerticallyCentered>
  );
};

SplitDropdownCell.propTypes = {
  data: PropTypes.shape(splitDropdownPropTypes).isRequired,
};

export default SplitDropdownCell;
