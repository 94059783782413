import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import ClearIcon from '../../../BrandCore/icons/interactive/Clear';
import TextStyles from '../../../BrandCore/Text/styles';

const ProgressWrapper = styled.span`
  ${TextStyles.xSmallBold};
  color: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
  display: flex;
  svg {
    margin: 0 8px;
    g {
      visibility: hidden;
    }
    &:hover {
      path {
        fill: ${(props) => props.theme.UIPalette.Colors.Content.Secondary};
      }

      g {
        visibility: visible;
      }
    }
  }
`;

const ProgressLinear = ({
  progress,
  isStoppable,
  onStop,
  domID,
  fillColor: _fillColor = null,
  outerFillColor: _outerFillColor = null,
  dataTestId = '',
}) => {
  const theme = useTheme();
  const fillColor = useMemo(
    () => _fillColor || theme.UIPalette.Colors.Status.Positive,
    [theme, _fillColor],
  );
  const outerFillColor = useMemo(
    () => _outerFillColor || theme.BaseColors.Neutral['15'],
    [theme, _outerFillColor],
  );

  return (
    <ProgressWrapper data-testid={dataTestId}>
      <svg
        id={domID}
        width={64 + (isStoppable ? 28 : 0)}
        height="16"
        viewBox={`0 0 ${64 + (isStoppable ? 28 : 0)} 16`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="6" width="64" height="4" rx="2" fill={outerFillColor} />
        <rect
          y="6"
          width={progress * 0.64}
          height="4"
          rx="2"
          fill={fillColor}
        />
        {isStoppable && progress > 0 && (
          <g
            onClick={onStop}
            style={{
              transform: 'translateX(74px)',
              pointerEvents: 'bounding-box',
              cursor: 'pointer',
            }}
          >
            <ClearIcon size="medium" />
          </g>
        )}
      </svg>
      {isStoppable ? null : `${progress}%`}
    </ProgressWrapper>
  );
};

ProgressLinear.propTypes = {
  domID: PropTypes.string,
  progress: PropTypes.number,
  fillColor: PropTypes.string,
  outerFillColor: PropTypes.string,
  isStoppable: PropTypes.bool,
  onStop: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default ProgressLinear;
