import React from 'react';
import PropTypes from 'prop-types';
import { neutral100 } from '../../colors/themes/base/basePalette';

const Diamond0 = ({
  domID = null,
  className = '',
  dataTestId = '',
  fillColor = neutral100,
  title = null,
  size = 'large',
}) => {
  switch (size) {
    case 'large':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 0.357864C12.1326 0.357864 12.2598 0.410542 12.3536 0.50431L23.4957 11.6464C23.6909 11.8417 23.6909 12.1583 23.4957 12.3535L12.3536 23.4957C12.1583 23.6909 11.8417 23.6909 11.6464 23.4957L0.504314 12.3535C0.309052 12.1583 0.309052 11.8417 0.504314 11.6464L11.6464 0.50431C11.7402 0.410542 11.8674 0.357864 12 0.357864ZM12 1.56497L1.56497 12L12 22.435L22.435 12L12 1.56497Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.35355 1.28249C8.15829 1.08723 7.84171 1.08723 7.64645 1.28249L1.28249 7.64645C1.08722 7.84171 1.08722 8.1583 1.28249 8.35356L7.64645 14.7175C7.84171 14.9128 8.15829 14.9128 8.35355 14.7175L14.7175 8.35356C14.9128 8.1583 14.9128 7.84171 14.7175 7.64645L8.35355 1.28249ZM6.93934 0.575385C7.52513 -0.0104016 8.47487 -0.0104016 9.06066 0.575385L15.4246 6.93935C16.0104 7.52513 16.0104 8.47488 15.4246 9.06067L9.06066 15.4246C8.47487 16.0104 7.52513 16.0104 6.93934 15.4246L0.575379 9.06067C-0.0104075 8.47488 -0.0104076 7.52513 0.575379 6.93935L6.93934 0.575385Z"
            fill={fillColor}
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          role={title === '' ? 'presentation' : 'img'}
          {...(title === '' && { alt: '' })}
          aria-label={title !== null ? title : 'Diamond 0'}
          id={domID}
          data-testid={dataTestId}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.58 6L6 1.42L1.42 6L6 10.58L10.58 6ZM6.70711 0.710006C6.31658 0.319482 5.68342 0.319482 5.29289 0.710006L0.71 5.29289C0.319476 5.68342 0.319475 6.31658 0.71 6.70711L5.29289 11.29C5.68342 11.6805 6.31658 11.6805 6.70711 11.29L11.29 6.70711C11.6805 6.31658 11.6805 5.68342 11.29 5.29289C11.29 5.29289 7.09763 1.10053 6.70711 0.710006Z"
            fill={fillColor}
          />
        </svg>
      );
    default:
      return <div>invalid size property</div>;
  }
};

Diamond0.propTypes = {
  domID: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default Diamond0;
