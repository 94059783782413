import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { withHelpSupportMenuRequests } from 'help-support';
import Menu from './components/Menu';
import Sidebar from '../Sidebar';
import Logo from '../../Atoms/Logo';
import NotificationsMenu from './components/NotificationsMenu';
import ProductMenu from './components/ProductMenu';
import HelpSupportMenu from './components/HelpSupportMenu';
import Timestamp from './components/Timestamp';
import UserMenu from './components/UserMenu';
import packageJson from '../../../package.json';

import MastHeadNav from './styles/MastHeadNav';
import useAnalytics from '../../commonResources/hooks/useAnalytics';
import ClientSwitcher from './components/ClientSwitcher';

const propNameToHandleLegacyItemFormat = (items) => {
  if (items && items.length && typeof items[0] === 'string')
    return 'menuItemNames';
  if (!Array.isArray(items[0])) return 'menuItems';
  return 'sections';
};

const generateUserMenu = (integrations, onProfileSelect) => {
  if (integrations.ciam || onProfileSelect) {
    return [{ label: 'My Profile', path: '/', id: 1 }];
  }

  if (onProfileSelect) {
    return [{ label: 'My Profile', path: '/', id: 1 }];
  }

  return [];
};

const generateSupportMenu = ({ community = {} }) => {
  const { support = {} } = community;
  if (support) {
    return withHelpSupportMenuRequests(HelpSupportMenu);
  }
  return HelpSupportMenu;
};

export const Masthead = ({
  domID = null,
  dataTestId = null,
  logoRedirect = '',
  integrations = {},

  /* Navigation Menu */
  navMenuDisplay = 'mastheadOnly',
  navMenuItems = [],
  onNavMenuOpenClose = () => false,
  onNavMenuSelect = () => false,
  initialSelectedMenuItemIds = [],
  onMenuExpand = () => false,
  initialShowSidebar = false,
  onSidebarCollapse = () => false,

  initialClientSwitcherItem = {},
  clientSwitcherMenuItems = [],
  onClientSwitcherChange = () => false,

  /* Product Switcher Menu */
  currentProductName = '',
  productNameOneLine = false,
  productNameWidth = null,
  productMenuItems = [],
  onProductMenuOpenClose = () => false,
  onProductMenuSelect = () => false,
  onExploreProductsClick,

  /* Timestamp Text */
  timestamp = null,

  /* Help & Support Menu */
  supportMenuConfig = {},
  onSupportMenuOpenClose = () => false,
  onSupportMenuSelect = () => false,

  /* Notification Menu */
  showNotifications = false,
  notifications = [],
  popupNotifications = [],
  popupNotificationTime = undefined,
  popupNotificationAnimationTime = undefined,
  onPopupNotificationClick = () => false,
  popupNotificationContainerElement = undefined,
  markAsReadPopupNotification = () => false,
  archivePopupNotification = () => false,
  onPopupNotificationFinish = () => false,
  onNotificationClick = () => false,
  markAsRead = () => false,
  markAllAsRead = () => false,
  archive = () => false,
  archiveAll = () => false,
  viewArchive = () => false,
  onNotificationsMenuOpenClose = () => false,
  popupNotificationWrapperClassName = undefined,
  popupNotificationWrapperStyle = undefined,
  notificationModalRemoveFromDOMOnClose = true,

  /* User/Avatar Menu */
  onAvatarMenuOpenClose = () => false,
  onAvatarMenuSelect = () => false,
  onLogOutSelect = undefined,
  /* User Profile */
  onProfileSave = () => false,
  onProfileSelect = undefined,
  customUserMenuItems = [],
  selectedUserMenuItems = [],
  userEmail = '',
  userFirstName = '',
  userLastName = '',
  avatarImage = '',
  onStandaloneLoginSelect = () => false,
  isStandaloneLogin = false,

  /* Theme / White Label */
  customTheme = 'normal',
  logoSrc = '',
}) => {
  useAnalytics({
    currentProductName,
    url: window.location.href,
    userEmail,
    timestamp: new Date().toUTCString(),
    uiCoreVersion: packageJson.version,
  });

  const theme = useTheme();

  // Legacy item format support - should deprecate
  const safeNavMenuItems = {
    [propNameToHandleLegacyItemFormat(navMenuItems)]: navMenuItems,
  };
  const safeProductMenuItems = {
    [propNameToHandleLegacyItemFormat(productMenuItems)]: productMenuItems,
  };

  // Set Selected Nav Menu items

  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState(
    initialSelectedMenuItemIds,
  );

  // Show Sidebar
  const determineIfShowSidebar = useCallback(() => {
    switch (navMenuDisplay) {
      case 'mastheadOnly':
        return false;
      case 'sidebarOnly':
        return true;
      default:
        return initialShowSidebar;
    }
  }, [initialShowSidebar, navMenuDisplay]);

  const [showSidebar, setShowSidebar] = useState(() =>
    determineIfShowSidebar(),
  );

  useEffect(
    () => setShowSidebar(determineIfShowSidebar()),
    [determineIfShowSidebar, initialShowSidebar, navMenuDisplay],
  );

  // When sidebar item is clicked
  const handleSidebarItemClick = (e, item, itemIndex, sectionIndex = null) => {
    e.persist();
    setSelectedMenuItemIds([item.id]);
    onNavMenuSelect(e, {
      activeItem: item,
      activeMenuItemIndex: itemIndex,
      activeSectionIndex: sectionIndex,
      isMenuOpen: false,
    });
  };

  // Switch Sidebar to Masthead
  const isCollapsible = navMenuDisplay === 'switchable';

  const handleSidebarCollapse = (e) => {
    e.persist();
    setShowSidebar(false);
    onSidebarCollapse(e);
  };

  // Switch Menu to Sidebar
  const handleMenuExpand = (e) => {
    setShowSidebar(true);
    onMenuExpand(e);
  };

  // When Nav Menu item is clicked
  const handleMenuItemClick = (e, dropdownState) => {
    const { activeItemIds, activeItemId } = dropdownState;

    if (activeItemIds) {
      setSelectedMenuItemIds(activeItemIds);
    } else {
      setSelectedMenuItemIds([activeItemId]);
    }
    onNavMenuSelect(e, dropdownState);
  };

  // User Menu
  const generatedUserMenuItems = generateUserMenu(
    integrations,
    onProfileSelect,
    onLogOutSelect,
  );

  // Support Menu
  const { shouldShow: shouldShowHelpSupport } = supportMenuConfig;

  const SupportMenu = generateSupportMenu(integrations);

  // IDs
  const domIDs = {
    navMenu: domID && `${domID}-navMenu`,
    sidebar: domID && `${domID}-sidebar`,
  };

  const testIDs = {
    navMenu: dataTestId && `${dataTestId}-navMenu`,
    sidebar: dataTestId && `${dataTestId}-sidebar`,
  };

  const themeColors = {
    normal: {
      background: theme.UIPalette.Colors.Background.Masthead,
      color: theme.UIPalette.Colors.Content.PersistentLight,
      logoBackground: theme.UIPalette.Colors.Background.Masthead,
      focusRingColor: theme.UIPalette.Colors.Field.Border.Focus,
      menu: {
        background: theme.BaseColors.NeutralAlt['75'],
        color: theme.UIPalette.Colors.Content.PersistentLight,
        hoverBackground: theme.BaseColors.NeutralAlt['70'],
        openBackground: theme.UIPalette.Colors.Background.StandardMenu,
        openColor: theme.UIPalette.Colors.Content.Primary,
        activeBackground: theme.BaseColors.Neutral['10'],
        activeColor: theme.UIPalette.Colors.Content.Primary,
        focusBackground: theme.BaseColors.NeutralAlt['70'],
        focusRingColor: theme.UIPalette.Colors.Field.Border.Focus,
      },
      product: {
        dividerColor: theme.BaseColors.Neutral['15'],
        hoverBackground: theme.UIPalette.Colors.Background.Masthead,
      },
      rightInteractiveItems: {
        color: theme.BaseColors.White,
        background: theme.BaseColors.Neutral['150'],
        hoverBackground: theme.BaseColors.NeutralAlt['75'],
        activeBackground: theme.BaseColors.Neutral['10'],
        activeColor: theme.BaseColors.Neutral['150'],
        openBackground: theme.BaseColors.White,
        openColor: theme.BaseColors.Neutral['150'],
        focusRingColor: theme.BaseColors.Primary['100'],
      },
    },
    white: {
      background: theme.BaseColors.White,
      boxShadow: '0 1px 0 0 #C3C5CD',
      color: theme.BaseColors.Neutral['100'],
      logoBackground: theme.BaseColors.Neutral['10'],
      menu: {
        background: theme.BaseColors.Neutral['10'],
        color: theme.BaseColors.Neutral['100'],
        hoverBackground: theme.BaseColors.Neutral['15'],
        hoverColor: theme.BaseColors.Neutral['100'],
        activeBackground: theme.BaseColors.NeutralAlt['100'],
        activeColor: theme.BaseColors.White,
      },
      product: {
        dividerColor: '#C3C5CD',
      },
      rightInteractiveItems: {
        color: theme.BaseColors.Neutral['100'],
        hoverBackground: theme.BaseColors.Neutral['5'],
        activeBackground: theme.BaseColors.Neutral['10'],
        activeColor: theme.BaseColors.Neutral['100'],
      },
    },
  };

  return (
    <MastHeadNav
      id={domID}
      boxShadow={themeColors[customTheme].boxShadow}
      backgroundColor={themeColors[customTheme].background}
      currentProductName={currentProductName}
    >
      {navMenuItems.length && !showSidebar ? (
        <Menu
          customTheme={themeColors[customTheme].menu}
          domID={domIDs.navMenu}
          dataTestId={testIDs.navMenu}
          menuLabel="MENU"
          onOpenClose={(e) => {
            if (e.persist) e.persist();
            onNavMenuOpenClose(e);
          }}
          onMenuItemClick={handleMenuItemClick}
          selectedMenuItemIds={selectedMenuItemIds}
          isExpandable={isCollapsible}
          onExpand={(e, activeItem) => handleMenuExpand(e, activeItem)}
          {...safeNavMenuItems}
        />
      ) : null}

      {navMenuItems.length && showSidebar ? (
        <Sidebar
          domID={domIDs.sidebar}
          dataTestId={testIDs.sidebar}
          onSidebarItemClick={handleSidebarItemClick}
          // initialSelectedSectionIndex={
          //   selectedMenuSectionIndex === 0 // 0th section is the 'null' section/top level
          //     ? null
          //     : selectedMenuSectionIndex
          // }
          // initialSelectedItemIndex={selectedMenuItemIndex}
          selectedMenuItemIds={selectedMenuItemIds}
          isCollapsible={isCollapsible}
          onCollapse={(e) => handleSidebarCollapse(e)}
          {...safeNavMenuItems}
        />
      ) : null}

      <Logo
        logoURI={customTheme === 'white' ? logoSrc : undefined}
        redirectPath={logoRedirect}
        className="masthead-logo"
      />

      {currentProductName ? (
        <ProductMenu
          customTheme={themeColors[customTheme]}
          dataTestId={`${domID}-productMenu`}
          currentProductName={currentProductName}
          productNameOneLine={productNameOneLine}
          productNameWidth={productNameWidth}
          onOpenClose={onProductMenuOpenClose}
          onMenuItemClick={onProductMenuSelect}
          onExploreProductsClick={onExploreProductsClick}
          {...safeProductMenuItems}
        />
      ) : null}

      {timestamp ? <Timestamp label={timestamp} /> : null}

      {clientSwitcherMenuItems.length ? (
        <ClientSwitcher
          initialSelectedItem={initialClientSwitcherItem}
          menuItems={clientSwitcherMenuItems}
          onClientSwitcherChange={onClientSwitcherChange}
        />
      ) : null}

      {shouldShowHelpSupport && (
        <SupportMenu
          customTheme={themeColors[customTheme].rightInteractiveItems}
          onOpenClose={onSupportMenuOpenClose}
          onMenuItemClick={onSupportMenuSelect}
          applicationName={currentProductName}
          {...supportMenuConfig}
        />
      )}

      {showNotifications ? (
        <NotificationsMenu
          customTheme={themeColors[customTheme].rightInteractiveItems}
          onOpenClose={onNotificationsMenuOpenClose}
          notifications={notifications}
          onItemClick={onNotificationClick}
          markAsRead={markAsRead}
          markAllAsRead={markAllAsRead}
          archive={archive}
          archiveAll={archiveAll}
          viewArchive={viewArchive}
          popupNotifications={popupNotifications}
          popupNotificationTime={popupNotificationTime}
          onPopupNotificationFinish={onPopupNotificationFinish}
          archivePopupNotification={archivePopupNotification}
          markAsReadPopupNotification={markAsReadPopupNotification}
          onPopupNotificationClick={onPopupNotificationClick}
          popupNotificationAnimationTime={popupNotificationAnimationTime}
          popupNotificationContainerElement={popupNotificationContainerElement}
          popupNotificationWrapperClassName={popupNotificationWrapperClassName}
          popupNotificationWrapperStyle={popupNotificationWrapperStyle}
          notificationModalRemoveFromDOMOnClose={
            notificationModalRemoveFromDOMOnClose
          }
        />
      ) : null}

      {generatedUserMenuItems.length ||
      onLogOutSelect ||
      customUserMenuItems.length ||
      userFirstName ||
      userLastName ||
      userEmail ? (
        <UserMenu
          customTheme={themeColors[customTheme].rightInteractiveItems}
          dataTestId="test-usermenu"
          menuItems={generatedUserMenuItems}
          selectedUserMenuItems={selectedUserMenuItems}
          customMenuItems={customUserMenuItems}
          integrations={integrations}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userEmail={userEmail}
          avatarImage={avatarImage}
          onLogOutSelect={onLogOutSelect}
          onMenuItemClick={onAvatarMenuSelect}
          onOpenClose={onAvatarMenuOpenClose}
          onProfileSave={onProfileSave}
          onProfileSelect={onProfileSelect}
          isStandaloneLogin={isStandaloneLogin}
          onStandaloneLoginSelect={onStandaloneLoginSelect}
        />
      ) : null}
    </MastHeadNav>
  );
};

Masthead.propTypes = {
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  /** name of the current product, ex. Quality Studio */
  currentProductName: PropTypes.string,
  /** whether the product name should only take up one line in the masthead */
  productNameOneLine: PropTypes.bool,
  /** if passed, overrides other product name line settings. Instead, text is set to that width, wraps normally, and is truncated with ellipsis and tooltip after two lines. */
  productNameWidth: PropTypes.number,
  /** path which user is redirected to when clicking on the logo */
  logoRedirect: PropTypes.string,
  /** object containing data for integration with CIAM and other services */
  integrations: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  // Navigation
  // -------------------------------------
  /** string determining how nav menu is presented:
   * 'mastheadOnly' - [default] Nav menu is shown only as dropdown menu in the masthead
   * 'sidebarOnly' - Nav menu is shown only as sidebar, hiding the nav menu
   * 'switchable' - User can switch between the dropdown view and the masthead view
   */
  navMenuDisplay: PropTypes.oneOf([
    'mastheadOnly',
    'sidebarOnly',
    'switchable',
  ]),
  /** an array of menu item objects which will act as options in the primary navigation menu (directly to the right of logo) */
  navMenuItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          /** should be a ui-core icon component */
          icon: PropTypes.func,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
        }),
      ]),
    ),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              label: PropTypes.string.isRequired,
              /** should be a ui-core icon component */
              icon: PropTypes.func,
              path: PropTypes.string,
              labelDecoration: PropTypes.string,
            }),
          ]),
        ),
        PropTypes.shape({
          title: PropTypes.string,
          sectionItems: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              label: PropTypes.string.isRequired,
              /** should be a ui-core icon component */
              icon: PropTypes.func,
              path: PropTypes.string,
              labelDecoration: PropTypes.string,
            }),
          ),
        }),
      ]),
    ),
  ]),
  /** callback action which fires when the primary navigation menu is opened/closed. Receives event. */
  onNavMenuOpenClose: PropTypes.func,
  /** callback action which fires when the options in the primary navigation menu are clicked
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   * @prop {Object} state.activeItem
   * @prop {String|Number} state.activeItem.id
   * @prop {String} state.activeItem.label
   */
  onNavMenuSelect: PropTypes.func,
  /** callback action which fires when the Menu's 'Expand' button is clicked
   * @param {Event} e
   * @param {Object} activeItem primary navigation menu's selected item
   * @prop {Number} activeItem.activeMenuItemIndex
   * @prop {Number} activeItem.activeSectionIndex
   */
  onMenuExpand: PropTypes.func,
  /** if `true`, the sidebar is visible and the dropdown menu in the masthead is hidden */
  initialShowSidebar: PropTypes.bool,
  /** callback action which fires when the sidebar's Collapse button is clicked
   * @param {Event} e
   * @param {[Number]} activeItem primary navigation menu's selected item: format [sectionIndex, itemIndex]
   */
  onSidebarCollapse: PropTypes.func,

  // Product Switcher
  // -------------------------------------
  /** an array of menu item objects which will act as options in the product switching menu */
  productMenuItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
        }),
      ]),
    ),
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
          }),
        ]),
      ),
    ),
  ]),
  /** callback action which fires when the product switcher menu (directly to the left of the logo) is opened/closed
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   */
  onProductMenuOpenClose: PropTypes.func,
  /** callback action which fires when the options in the product switcher menu are clicked
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   */
  onProductMenuSelect: PropTypes.func,

  /** displayed to the left of the help & support menu */
  timestamp: PropTypes.string,

  clientSwitcherMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  initialClientSwitcherItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    labelDecoration: PropTypes.string,
    isSecondary: PropTypes.bool,
  }),
  onClientSwitcherChange: PropTypes.func,

  // Help & Support
  // -------------------------------------
  /** configurable help & support menu paths */
  /** support menu config */
  supportMenuConfig: PropTypes.shape({
    shouldShow: PropTypes.bool,
    initialSearchValue: PropTypes.string,
    showBottomAction: PropTypes.bool,
    onBottomAction: PropTypes.func,
    onFetchSearchResults: PropTypes.func,
    linkData: PropTypes.shape({
      applicationId: PropTypes.string,
      applicationName: PropTypes.string,
      source: PropTypes.string,
      liSearchUrl: PropTypes.string,
      /** Top section of divider */
      staticContent: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.url,
        }),
      ),
      /** bottom section of divider */
      productContent: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.url,
        }),
      ),
    }),
    /** search results */
    searchResults: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        teaser: PropTypes.string,
        url: PropTypes.url,
        postTime: PropTypes.string,
      }),
    ),
  }),
  /** callback action which fires when the help & support menu is opened/closed
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Boolean} state.isMenuOpen
   * @prop {String} state.searchValue
   */
  onSupportMenuOpenClose: PropTypes.func,
  /** callback action which fires when the options in the help & support menu are clicked
   * @param {Event} e
   * @param {Object} selectedItem
   * @prop {String | Number} selectedItem.id
   * @prop {String} selectedItem.label text shown in menu
   * @prop {String} selectedItem.path URI associated with the menu item
   */
  onSupportMenuSelect: PropTypes.func,

  // Notifications
  // -------------------------------------
  /** boolean indicating if notifications should be shown */
  showNotifications: PropTypes.bool,
  /** array of notification items */
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      title: PropTypes.string,
      description: PropTypes.string,
      dateTime: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      className: PropTypes.element,
      style: PropTypes.string,
      isRead: PropTypes.bool,
    }),
  ),
  /** runs when "Mark All As Read" is clicked in the Notifications Menu. It receives the click event.  */
  markAllAsRead: PropTypes.func,
  /** Runs when Mark As Read is clicked in a notification
   * receives the data/object for the notification clicked on
   */
  markAsRead: PropTypes.func,
  /** runs when "Archive all" is clicked in the Notifications Menu. It receives the click event.  */
  archiveAll: PropTypes.func,
  /** Runs when Archive is clicked in a notification
   * receives the data/object for the notification clicked on
   */
  archive: PropTypes.func,
  /** Runs when "View Archive" is clicked in a notification menu
   * receives the data/object for the notification clicked on
   */
  viewArchive: PropTypes.func,
  /** Runs when a notification is clicked on
   * receives the data/object for that notification
   */
  onNotificationClick: PropTypes.func,
  /** Runs when notifications menu opens or closes
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Boolean} isMenuOpen
   */
  onNotificationsMenuOpenClose: PropTypes.func,
  /**
   * Runs when popup notification is clicked
   * @prop {Object} notification
   */
  onPopupNotificationClick: PropTypes.func,
  /**
   * Runs when mark as read is click on popup notification
   * @prop {Object} notification
   * @prop {Boolean} notification.isRead = true
   */
  markAsReadPopupNotification: PropTypes.func,
  /**
   * Runs when popup notification is clicked
   * @prop {Object} notification
   * @prop {Boolean} notification.isArchived = true
   */
  archivePopupNotification: PropTypes.func,
  /**
   * Array of Notification items that needs to be displayed as Popup Notification
   */
  popupNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      title: PropTypes.string,
      description: PropTypes.string,
      dateTime: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      className: PropTypes.element,
      style: PropTypes.string,
      isRead: PropTypes.bool,
    }),
  ),
  /**
   * Amount of time that will Popup Notification be visible
   * That time is time without animations
   */
  popupNotificationTime: PropTypes.number,
  /**
   * Time of the animations for showing and hiding notification
   */
  popupNotificationAnimationTime: PropTypes.number,
  /**
   * Parent DOM Element that will contain Popup Notification
   */
  popupNotificationContainerElement: PropTypes.element,
  /**
   * Runs when Popup Notification is finished with displaying on the screen
   */
  onPopupNotificationFinish: PropTypes.func,
  /**
   * Change style of the Popup Notification Wrapper
   */
  popupNotificationWrapperClassName: PropTypes.string,
  /**
   * Change style of the Popup Notification Wrapper
   */
  // eslint-disable-next-line react/forbid-prop-types
  popupNotificationWrapperStyle: PropTypes.object,
  /**
   * Flag for removing Notification Modal from DOM when Modal is closed
   */
  notificationModalRemoveFromDOMOnClose: PropTypes.bool,

  // User Menu and Profile
  // -------------------------------------
  /** callback action which fires when the avatar/user menu is opened/closed
   * @param {Event} e
   * @prop {Boolean} isMenuOpen
   */
  onAvatarMenuOpenClose: PropTypes.func,
  /** Only runs if item clicked in user menu is not "My Profile" or "Log Out".
   * @param {Event} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Number} state.activeMenuItemIndex
   * @prop {Number} state.activeSectionIndex
   * @prop {Boolean} state.isMenuOpen
   * @prop {Object} state.activeItem
   */
  onAvatarMenuSelect: PropTypes.func,
  /** Runs when profile is saved. See onAvatarMenuOpenClose for parameters. */
  onProfileSave: PropTypes.func,
  /** If not using CIAM, this function runs when "My Profile" is selected in user menu.
   * Receives no parameters.
   */
  onProfileSelect: PropTypes.func,
  /** If not using CIAM, this function runs when "Log Out" is selected in user menu.
   * Receives no parameters.
   */
  onLogOutSelect: PropTypes.func,
  /** avatar image path or image object */
  avatarImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** current user's email address */
  userEmail: PropTypes.string,
  /** current user's first name */
  userFirstName: PropTypes.string,
  /** current user's last/second name */
  userLastName: PropTypes.string,
  /** if `true`, when user is not logged in, will render standalone login link in place of user menu */
  isStandaloneLogin: PropTypes.bool,
  /** runs when standalone login link is selected * */
  onStandaloneLoginSelect: PropTypes.func,
  customUserMenuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        sectionItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
          }),
        ),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          path: PropTypes.string,
          labelDecoration: PropTypes.string,
          isSecondary: PropTypes.bool,
        }),
      ),
    ]),
  ),
  selectedUserMenuItems: PropTypes.arrayOf(PropTypes.any),
  /** This prop should not be used unless you get an exception from CHC design team */
  customTheme: PropTypes.oneOf(['normal', 'white']),
  /** This prop should not be used unless you get an exception from CHC design team */
  logoSrc: PropTypes.string,
  /** Id of the items that are initially selected. This prop replaces initialSelectedMenuSectionIndex and initialSelectedMenuItemIndex since v.9.0 */
  initialSelectedMenuItemIds: PropTypes.arrayOf(PropTypes.any),
  /** When this prop is passed footer is displayed in product switcher. When the footer gets clicked this callback gets triggered */
  onExploreProductsClick: PropTypes.func,
};

export default Masthead;
